var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "content" }, [
    _c("div", { staticClass: "container-fluid" }, [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-md-3 services" },
          [
            _c(
              "b-card",
              { staticClass: "cardServicio" },
              [
                _c("template", { slot: "header" }, [
                  _c("h4", { staticClass: "card-title" }, [
                    _vm._v("Constitución de sociedades"),
                  ]),
                ]),
                _c("div", { staticClass: "text-center long" }, [
                  _c("img", {
                    staticClass: "imgCard",
                    attrs: { src: _vm.urlTest },
                  }),
                ]),
                _c("template", { slot: "footer" }, [
                  _c("hr"),
                  _c(
                    "div",
                    { staticClass: "stats" },
                    [
                      _c(
                        "router-link",
                        {
                          attrs: {
                            to: {
                              name: "InformationInDashboard",
                              params: { type: "constitucion-sociedades" },
                            },
                          },
                        },
                        [_vm._v(" Más información ")]
                      ),
                      _c("br"),
                      _c(
                        "router-link",
                        {
                          staticClass: "route",
                          attrs: {
                            to: {
                              path: "/dashboard/procesos/addconstitucion/",
                            },
                          },
                        },
                        [_vm._v(" Iniciar proceso ")]
                      ),
                    ],
                    1
                  ),
                ]),
              ],
              2
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-md-3 services" },
          [
            _c(
              "b-card",
              { staticClass: "cardServicio" },
              [
                _c("template", { slot: "header" }, [
                  _c("h4", { staticClass: "card-title" }, [
                    _vm._v("Notificaciones"),
                  ]),
                ]),
                _c("div", { staticClass: "text-center long" }, [
                  _c("img", {
                    staticClass: "imgCard",
                    attrs: { src: "/img/designed/notifs.PNG", alt: "" },
                  }),
                ]),
                _c("template", { slot: "footer" }, [
                  _c("hr"),
                  _c(
                    "div",
                    { staticClass: "stats" },
                    [
                      _c(
                        "router-link",
                        {
                          attrs: {
                            to: {
                              name: "InformationInDashboard",
                              params: { type: "notificaciones" },
                            },
                          },
                        },
                        [_vm._v(" Más información ")]
                      ),
                      _c("br"),
                      _c(
                        "router-link",
                        {
                          staticClass: "route",
                          attrs: {
                            to: {
                              path: "/dashboard/procesos/addnotificacion/",
                            },
                          },
                        },
                        [_vm._v(" Iniciar proceso ")]
                      ),
                    ],
                    1
                  ),
                ]),
              ],
              2
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-md-3 services" },
          [
            _c(
              "card",
              { staticClass: "cardServicio" },
              [
                _c("template", { slot: "header" }, [
                  _c("h4", { staticClass: "card-title" }, [
                    _vm._v("Copias certificadas"),
                  ]),
                ]),
                _c("div", { staticClass: "text-center long" }, [
                  _c("img", {
                    staticClass: "imgCard",
                    attrs: { src: "/img/designed/cotejoDocs.PNG", alt: "" },
                  }),
                ]),
                _c("template", { slot: "footer" }, [
                  _c("hr"),
                  _c(
                    "div",
                    { staticClass: "stats" },
                    [
                      _c(
                        "router-link",
                        {
                          attrs: {
                            to: {
                              name: "InformationInDashboard",
                              params: { type: "copias-certificadas" },
                            },
                          },
                        },
                        [_vm._v(" Más información ")]
                      ),
                      _c("br"),
                      _c(
                        "router-link",
                        {
                          staticClass: "route",
                          attrs: {
                            to: { path: "/dashboard/procesos/addcotejo/" },
                          },
                        },
                        [_vm._v(" Iniciar proceso ")]
                      ),
                    ],
                    1
                  ),
                ]),
              ],
              2
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-md-3 services" },
          [
            _c(
              "card",
              { staticClass: "cardServicio" },
              [
                _c("template", { slot: "header" }, [
                  _c("h4", { staticClass: "card-title" }, [
                    _vm._v("Ratificación de firmas"),
                  ]),
                ]),
                _c("div", { staticClass: "text-center long" }, [
                  _c("img", {
                    staticClass: "imgCard",
                    attrs: { src: "/img/designed/ratifFirmas.PNG", alt: "" },
                  }),
                ]),
                _c("template", { slot: "footer" }, [
                  _c("hr"),
                  _c(
                    "div",
                    { staticClass: "stats" },
                    [
                      _c(
                        "router-link",
                        {
                          attrs: {
                            to: {
                              name: "InformationInDashboard",
                              params: { type: "ratificacion-firmas" },
                            },
                          },
                        },
                        [_vm._v(" Más información ")]
                      ),
                      _c("br"),
                      _c(
                        "router-link",
                        {
                          staticClass: "route",
                          attrs: {
                            to: {
                              path: "/dashboard/procesos/addratificacion/",
                            },
                          },
                        },
                        [_vm._v(" Iniciar proceso ")]
                      ),
                    ],
                    1
                  ),
                ]),
              ],
              2
            ),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-md-3 services" },
          [
            _c(
              "card",
              { staticClass: "cardServicio" },
              [
                _c("template", { slot: "header" }, [
                  _c("h4", { staticClass: "card-title" }, [
                    _vm._v("Firma Digital de Documentos"),
                  ]),
                ]),
                _c("div", { staticClass: "text-center long" }, [
                  _c("img", {
                    staticClass: "imgCard",
                    attrs: {
                      src: "/img/designed/ratifFirmasDigital.png",
                      alt: "",
                    },
                  }),
                ]),
                _c("template", { slot: "footer" }, [
                  _c("hr"),
                  _c(
                    "div",
                    { staticClass: "stats" },
                    [
                      _c(
                        "router-link",
                        {
                          attrs: {
                            to: {
                              name: "InformationInDashboard",
                              params: { type: "ratificacion-firmas-digital" },
                            },
                          },
                        },
                        [_vm._v(" Más información ")]
                      ),
                      _c("br"),
                      _c(
                        "router-link",
                        {
                          staticClass: "route",
                          attrs: {
                            to: {
                              path: "/dashboard/procesos/addratificaciondigital/",
                            },
                          },
                        },
                        [_vm._v(" Iniciar proceso ")]
                      ),
                    ],
                    1
                  ),
                ]),
              ],
              2
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-md-3 services" },
          [
            _c(
              "card",
              { staticClass: "cardServicio" },
              [
                _c("template", { slot: "header" }, [
                  _c("h4", { staticClass: "card-title" }, [
                    _vm._v("Formalización de actas"),
                  ]),
                ]),
                _c("div", { staticClass: "text-center long" }, [
                  _c("img", {
                    staticClass: "imgCard",
                    attrs: { src: "/img/designed/formActas.PNG", alt: "" },
                  }),
                ]),
                _c("template", { slot: "footer" }, [
                  _c("hr"),
                  _c(
                    "div",
                    { staticClass: "stats" },
                    [
                      _c(
                        "router-link",
                        {
                          attrs: {
                            to: {
                              name: "InformationInDashboard",
                              params: { type: "formalizacion-actas" },
                            },
                          },
                        },
                        [_vm._v(" Más información ")]
                      ),
                      _c("br"),
                      _c(
                        "router-link",
                        {
                          staticClass: "route",
                          attrs: {
                            to: {
                              path: "/dashboard/procesos/addformalizacion/",
                            },
                          },
                        },
                        [_vm._v(" Iniciar proceso ")]
                      ),
                    ],
                    1
                  ),
                ]),
              ],
              2
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-md-3 services" },
          [
            _c(
              "card",
              { staticClass: "cardServicio" },
              [
                _c("template", { slot: "header" }, [
                  _c("h4", { staticClass: "card-title" }, [
                    _vm._v("Fes de hechos"),
                  ]),
                ]),
                _c("div", { staticClass: "text-center long" }, [
                  _c("img", {
                    staticClass: "imgCard",
                    attrs: { src: "/img/designed/fesHechos.PNG", alt: "" },
                  }),
                ]),
                _c("template", { slot: "footer" }, [
                  _c("hr"),
                  _c(
                    "div",
                    { staticClass: "stats" },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "route",
                          attrs: { to: { path: "/dashboard/proximamente" } },
                        },
                        [_vm._v(" Más información ")]
                      ),
                      _c("br"),
                      _c(
                        "router-link",
                        {
                          staticClass: "route",
                          attrs: { to: { path: "/dashboard/proximamente" } },
                        },
                        [_vm._v(" Iniciar proceso ")]
                      ),
                    ],
                    1
                  ),
                ]),
              ],
              2
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-md-3 services" },
          [
            _c(
              "card",
              { staticClass: "cardServicio" },
              [
                _c("template", { slot: "header" }, [
                  _c("h4", { staticClass: "card-title" }, [
                    _vm._v("Avalúos de mejoras"),
                  ]),
                ]),
                _c("div", { staticClass: "text-center long" }, [
                  _c("img", {
                    staticClass: "imgCard",
                    attrs: { src: "/img/designed/avaluosMej.PNG", alt: "" },
                  }),
                ]),
                _c("template", { slot: "footer" }, [
                  _c("hr"),
                  _c(
                    "div",
                    { staticClass: "stats" },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "route",
                          attrs: { to: { path: "/dashboard/proximamente" } },
                        },
                        [_vm._v(" Más información ")]
                      ),
                      _c("br"),
                      _c(
                        "router-link",
                        {
                          staticClass: "route",
                          attrs: { to: { path: "/dashboard/proximamente" } },
                        },
                        [_vm._v(" Iniciar proceso ")]
                      ),
                    ],
                    1
                  ),
                ]),
              ],
              2
            ),
          ],
          1
        ),
        _c("div", { staticClass: "col-md-1" }),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }