var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container" }, [
    _c("h4", { staticClass: "cardD" }, [_vm._v("Cita para firma")]),
    _c(
      "div",
      [
        _c("ValidationProvider", {
          attrs: {
            name: "fecha_firma",
            rules: "required",
            "custom-messages": { required: "El link es obligatorio" },
          },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function ({ errors }) {
                return [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "Link cita Calendly",
                        "label-for": "input-link-cita",
                      },
                    },
                    [
                      _c(
                        "b-input-group",
                        { staticClass: "mt-3" },
                        [
                          _c(
                            "b-input-group-append",
                            [
                              _c(
                                "b-button",
                                {
                                  directives: [
                                    {
                                      name: "b-tooltip",
                                      rawName: "v-b-tooltip.hover",
                                      modifiers: { hover: true },
                                    },
                                  ],
                                  attrs: {
                                    variant: _vm.isTextCopied
                                      ? "success"
                                      : "secondary",
                                    squared: "",
                                    title: "Copiar",
                                  },
                                  on: { click: _vm.copyLinkToClipboard },
                                },
                                [
                                  _vm.isTextCopied
                                    ? _c("b-icon", { attrs: { icon: "check" } })
                                    : _c("b-icon", {
                                        attrs: { icon: "clipboard" },
                                      }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c("b-form-input", {
                            attrs: {
                              id: "input-link-cita",
                              type: "link",
                              placeholder: "Link Calendly",
                            },
                            model: {
                              value: _vm.constitutiva.link_calendario,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.constitutiva,
                                  "link_calendario",
                                  $$v
                                )
                              },
                              expression: "constitutiva.link_calendario",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("span", { staticClass: "cardErrors" }, [
                    _vm._v(_vm._s(errors[0])),
                  ]),
                ]
              },
            },
          ]),
        }),
      ],
      1
    ),
    !_vm.constitutiva.firma_oficina
      ? _c(
          "div",
          { staticClass: "mt-3" },
          [
            _c("ValidationProvider", {
              attrs: {
                name: "direccion_firma",
                rules: "required",
                "custom-messages": {
                  required: "La dirección es obligatoria",
                },
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function ({ errors }) {
                      return [
                        _c("base-input", {
                          attrs: {
                            type: "text",
                            label: "Dirección",
                            placeholder: "Dirección donde será la firma",
                          },
                          model: {
                            value: _vm.constitutiva.direccion_firma,
                            callback: function ($$v) {
                              _vm.$set(_vm.constitutiva, "direccion_firma", $$v)
                            },
                            expression: "constitutiva.direccion_firma",
                          },
                        }),
                        _c("span", { staticClass: "cardErrors" }, [
                          _vm._v(_vm._s(errors[0])),
                        ]),
                      ]
                    },
                  },
                ],
                null,
                false,
                1431740422
              ),
            }),
          ],
          1
        )
      : _vm._e(),
    _c("div", { staticClass: "mt-4" }, [
      _c(
        "div",
        {},
        [
          _c(
            "b-button",
            {
              staticClass: "btn-fill orange-btn bottom2",
              attrs: { type: "submit", size: "m" },
            },
            [_vm._v("Enviar a cliente")]
          ),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "row mt-4 fecha" }, [
      _vm._m(0),
      _c("div", { staticClass: "col-9" }, [
        _vm._v(" " + _vm._s(_vm.fecha_formatted) + " "),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-3" }, [
      _c("label", {}, [_vm._v("Fecha seleccionada")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }