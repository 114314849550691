var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: _vm.id } }, [
    _vm.compareciente.persona == "Persona Física" ||
    _vm.compareciente.persona == "Física"
      ? _c("div", [
          _c("img", { staticClass: "logo", attrs: { src: _vm.urlBanner } }),
          _c("div", { attrs: { id: "seccion_uno" } }, [
            _c("table", { staticClass: "tabla" }, [
              _vm._m(0),
              _c("tr", { staticClass: "firstline" }, [
                _vm.compareciente.estatus_migratorio !== "No aplica"
                  ? _c("div", [
                      _c("td", { staticClass: "title" }, [
                        _vm._v(" Mexicano "),
                      ]),
                      _c("td", { staticClass: "select" }, [
                        _vm.compareciente.estatus_migratorio == "Mexicano"
                          ? _c("h3", { staticClass: "filledcircle" })
                          : _c("h3", { staticClass: "circle" }),
                      ]),
                      _c("td", {
                        staticClass: "space",
                        staticStyle: { width: "5%" },
                      }),
                      _c("td", { staticClass: "title" }, [
                        _vm._v(" Extranjero Visitante "),
                      ]),
                      _c("td", { staticClass: "select" }, [
                        _vm.compareciente.estatus_migratorio == "Extranjero"
                          ? _c("h3", { staticClass: "filledcircle" })
                          : _c("h3", { staticClass: "circle" }),
                      ]),
                    ])
                  : _vm._e(),
                _c("td", {
                  staticClass: "space",
                  staticStyle: { width: "5%" },
                }),
                _c("td", { staticClass: "right-title" }, [_vm._v(" Fecha: ")]),
                _c(
                  "td",
                  { staticClass: "user", staticStyle: { width: "30%" } },
                  [_vm._v(" " + _vm._s(_vm.fecha) + " ")]
                ),
              ]),
              _vm.compareciente.estatus_migratorio !== "No aplica"
                ? _c("tr", [
                    _vm._m(1),
                    _c("td", { staticClass: "select" }, [
                      _vm.compareciente.estatus_migratorio ==
                      "Residente Temporal"
                        ? _c("h3", { staticClass: "filledcircle" })
                        : _c("h3", { staticClass: "circle" }),
                    ]),
                    _c("td", {
                      staticClass: "space",
                      staticStyle: { width: "5%" },
                    }),
                  ])
                : _vm._e(),
            ]),
          ]),
          _c("div", { attrs: { id: "seccion_dos" } }, [
            _c("table", { staticClass: "tabla" }, [
              _vm._m(2),
              _c("tr", { staticClass: "firstline" }, [
                _c(
                  "td",
                  { staticClass: "title", staticStyle: { width: "15%" } },
                  [_vm._v(" Nombre y apellidos: ")]
                ),
                _c(
                  "td",
                  {
                    staticClass: "user",
                    staticStyle: { width: "80%" },
                    attrs: { colspan: "7" },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(_vm.compareciente.nombre) +
                        " " +
                        _vm._s(_vm.compareciente.paterno) +
                        " " +
                        _vm._s(_vm.compareciente.materno) +
                        " "
                    ),
                  ]
                ),
              ]),
              _c("tr", [
                _c(
                  "td",
                  { staticClass: "title", staticStyle: { width: "10%" } },
                  [_vm._v(" Fecha de Nacimiento: ")]
                ),
                _c(
                  "td",
                  {
                    staticClass: "user",
                    staticStyle: { width: "30%" },
                    attrs: { colspan: "1" },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.compareciente.fecha_nacimiento.substr(0, 10)
                        ) +
                        " "
                    ),
                  ]
                ),
                _c(
                  "td",
                  {
                    staticClass: "right-title",
                    staticStyle: { width: "11.5%" },
                  },
                  [_vm._v(" Sexo: ")]
                ),
                _c(
                  "td",
                  {
                    staticClass: "user",
                    staticStyle: { width: "7.5%" },
                    attrs: { colspan: "3" },
                  },
                  [_vm._v(" " + _vm._s(_vm.compareciente.sexo) + " ")]
                ),
                _c(
                  "td",
                  { staticClass: "right-title", staticStyle: { width: "10%" } },
                  [_vm._v(" Alias/AKA: ")]
                ),
                _c(
                  "td",
                  { staticClass: "user", staticStyle: { width: "25%" } },
                  [_vm._v(" " + _vm._s(_vm.compareciente.alias) + " ")]
                ),
              ]),
              _c("tr", [
                _c(
                  "td",
                  { staticClass: "title", staticStyle: { width: "15%" } },
                  [_vm._v(" Lugar de Nacimiento: ")]
                ),
                _c(
                  "td",
                  { staticClass: "title", staticStyle: { width: "7.5%" } },
                  [_vm._v(" Ciudad: ")]
                ),
                _c(
                  "td",
                  { staticClass: "user", staticStyle: { width: "10%" } },
                  [
                    _vm._v(
                      " " + _vm._s(_vm.compareciente.lugar_nacim_ciudad) + " "
                    ),
                  ]
                ),
                _c(
                  "td",
                  {
                    staticClass: "right-title",
                    staticStyle: { width: "7.5%" },
                  },
                  [_vm._v(" Estado: ")]
                ),
                _c(
                  "td",
                  {
                    staticClass: "user",
                    staticStyle: { width: "10%" },
                    attrs: { colspan: "2" },
                  },
                  [
                    _vm._v(
                      " " + _vm._s(_vm.compareciente.lugar_nacim_estado) + " "
                    ),
                  ]
                ),
                _c(
                  "td",
                  { staticClass: "right-title", staticStyle: { width: "10%" } },
                  [_vm._v(" País: ")]
                ),
                _c(
                  "td",
                  { staticClass: "user", staticStyle: { width: "15%" } },
                  [
                    _vm._v(
                      " " + _vm._s(_vm.compareciente.lugar_nacim_pais) + " "
                    ),
                  ]
                ),
              ]),
              _c("tr", [
                _c(
                  "td",
                  { staticClass: "title", staticStyle: { width: "15%" } },
                  [_vm._v(" Nacionalidades: ")]
                ),
                _c(
                  "td",
                  {
                    staticClass: "user",
                    staticStyle: { width: "80%" },
                    attrs: { colspan: "7" },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(_vm.compareciente.nacionalidades_nombres) +
                        " "
                    ),
                  ]
                ),
              ]),
              _c("tr", [
                _c(
                  "td",
                  { staticClass: "title", staticStyle: { width: "15%" } },
                  [_vm._v(" Actividad / Profesión: ")]
                ),
                _c(
                  "td",
                  {
                    staticClass: "user",
                    staticStyle: { width: "30%" },
                    attrs: { colspan: "3" },
                  },
                  [_vm._v(" " + _vm._s(_vm.compareciente.ocupacion) + " ")]
                ),
                _c(
                  "td",
                  {
                    staticClass: "right-title",
                    staticStyle: { width: "15%" },
                    attrs: { colspan: "2" },
                  },
                  [_vm._v(" Giro de negocios: ")]
                ),
                _c(
                  "td",
                  {
                    staticClass: "user",
                    staticStyle: { width: "35%" },
                    attrs: { colspan: "2" },
                  },
                  [_vm._v(" " + _vm._s(_vm.compareciente.giro_negocios) + " ")]
                ),
              ]),
              _c("tr", [
                _c(
                  "td",
                  { staticClass: "title", staticStyle: { width: "15%" } },
                  [_vm._v(" CURP o equivalente: ")]
                ),
                _c(
                  "td",
                  {
                    staticClass: "user",
                    staticStyle: { width: "30%" },
                    attrs: { colspan: "3" },
                  },
                  [_vm._v(" " + _vm._s(_vm.compareciente.curp) + " ")]
                ),
                _c(
                  "td",
                  {
                    staticClass: "right-title",
                    staticStyle: { width: "15%" },
                    attrs: { colspan: "2" },
                  },
                  [_vm._v(" RFC o Tax ID: ")]
                ),
                _c(
                  "td",
                  {
                    staticClass: "user",
                    staticStyle: { width: "35%" },
                    attrs: { colspan: "2" },
                  },
                  [_vm._v(" " + _vm._s(_vm.compareciente.rfc) + " ")]
                ),
              ]),
              _c("tr", [
                _c(
                  "td",
                  { staticClass: "title", staticStyle: { width: "15%" } },
                  [_vm._v(" Identificación: ")]
                ),
                _c(
                  "td",
                  { staticClass: "title", staticStyle: { width: "7.5%" } },
                  [_vm._v(" Tipo: ")]
                ),
                _c(
                  "td",
                  {
                    staticClass: "user",
                    staticStyle: { width: "12.5%" },
                    attrs: { colspan: "2" },
                  },
                  [
                    _vm._v(
                      " " + _vm._s(_vm.compareciente.identificacion_tipo) + " "
                    ),
                  ]
                ),
                _c(
                  "td",
                  {
                    staticClass: "right-title",
                    staticStyle: { width: "15%" },
                    attrs: { colspan: "2" },
                  },
                  [_vm._v(" Autoridad que expide: ")]
                ),
                _c(
                  "td",
                  {
                    staticClass: "user",
                    staticStyle: { width: "35%" },
                    attrs: { colspan: "2" },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(_vm.compareciente.identificacion_autoridad) +
                        " "
                    ),
                  ]
                ),
              ]),
              _c("tr", [
                _c("td", {
                  staticClass: "title",
                  staticStyle: { width: "15%" },
                }),
                _c(
                  "td",
                  { staticClass: "title", staticStyle: { width: "7.5%" } },
                  [_vm._v(" Número: ")]
                ),
                _c(
                  "td",
                  {
                    staticClass: "user",
                    staticStyle: { width: "72.5%" },
                    attrs: { colspan: "6" },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(_vm.compareciente.identificacion_numero) +
                        " "
                    ),
                  ]
                ),
              ]),
              _c("tr", [
                _c(
                  "td",
                  { staticClass: "title", staticStyle: { width: "15%" } },
                  [_vm._v(" Estado Civil: ")]
                ),
                _c(
                  "td",
                  {
                    staticClass: "user",
                    staticStyle: { width: "30%" },
                    attrs: { colspan: "3" },
                  },
                  [_vm._v(" " + _vm._s(_vm.compareciente.estado_civil) + " ")]
                ),
                _c(
                  "td",
                  {
                    staticClass: "right-title",
                    staticStyle: { width: "15%" },
                    attrs: { colspan: "2" },
                  },
                  [_vm._v(" Régimen patrimonial ")]
                ),
                _c(
                  "td",
                  {
                    staticClass: "user",
                    staticStyle: { width: "35%" },
                    attrs: { colspan: "2" },
                  },
                  [
                    _vm._v(
                      " " + _vm._s(_vm.compareciente.regimen_patrimonial) + " "
                    ),
                  ]
                ),
              ]),
              _c("tr", { staticClass: "firstline" }, [
                _c(
                  "td",
                  { staticClass: "title", staticStyle: { width: "15%" } },
                  [_vm._v(" Nombre del Cónyuge: ")]
                ),
                _c(
                  "td",
                  {
                    staticClass: "user",
                    staticStyle: { width: "80%" },
                    attrs: { colspan: "7" },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(_vm.compareciente.identificacion_conyuge) +
                        " "
                    ),
                  ]
                ),
              ]),
            ]),
          ]),
          _c("div", { attrs: { id: "seccion_tres" } }, [
            _c("table", { staticClass: "tabla" }, [
              _vm._m(3),
              true
                ? _c(
                    "div",
                    _vm._l(_vm.compareciente.domicilios, function (domicilio) {
                      return _c("div", { key: domicilio.id }, [
                        domicilio.tipo == "Particular" ||
                        domicilio.tipo == "Particular y Fiscal"
                          ? _c("div", [
                              _c("tr", [
                                _c(
                                  "td",
                                  {
                                    staticClass: "title",
                                    staticStyle: { width: "15%" },
                                  },
                                  [_vm._v(" Calle / Avenida / Vía: ")]
                                ),
                                _c(
                                  "td",
                                  {
                                    staticClass: "user",
                                    staticStyle: { width: "30%" },
                                    attrs: { colspan: "4" },
                                  },
                                  [_vm._v(" " + _vm._s(domicilio.calle) + " ")]
                                ),
                                _c("td", { attrs: { colspan: "1" } }),
                                _c(
                                  "td",
                                  {
                                    staticClass: "maps",
                                    staticStyle: { width: "50%" },
                                    attrs: { rowspan: "8", valign: "center" },
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        width: "95%",
                                        height: "70%",
                                        src: `https://maps.googleapis.com/maps/api/staticmap?center=${domicilio.lat},${domicilio.lng}&zoom=17&scale=1&size=600x300&maptype=roadmap&format=png&visual_refresh=true
                        &markers=color:red%7Alabel:C%7C${domicilio.lat},${domicilio.lng}
                        &key=AIzaSyC1LrDy2Gk5QhV8SjD7k4S6b4zg-wF2YEA`,
                                      },
                                    }),
                                  ]
                                ),
                              ]),
                              _c("tr", [
                                _c(
                                  "td",
                                  {
                                    staticClass: "title",
                                    staticStyle: { width: "15%" },
                                  },
                                  [_vm._v(" Número Exterior: ")]
                                ),
                                _c(
                                  "td",
                                  {
                                    staticClass: "user",
                                    staticStyle: { width: "15%" },
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(domicilio.num_exterior) + " "
                                    ),
                                  ]
                                ),
                                _c(
                                  "td",
                                  {
                                    staticClass: "right-title",
                                    staticStyle: { width: "7.5%" },
                                  },
                                  [_vm._v(" Interior: ")]
                                ),
                                _c(
                                  "td",
                                  {
                                    staticClass: "user",
                                    staticStyle: { width: "7.5%" },
                                    attrs: { colspan: "2" },
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(domicilio.num_interior) + " "
                                    ),
                                  ]
                                ),
                              ]),
                              _c("tr", [
                                _c(
                                  "td",
                                  {
                                    staticClass: "title",
                                    staticStyle: { width: "15%" },
                                  },
                                  [_vm._v(" Alcaldía / Municipio: ")]
                                ),
                                _c(
                                  "td",
                                  {
                                    staticClass: "user",
                                    staticStyle: { width: "30%" },
                                    attrs: { colspan: "4" },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(domicilio.delegacion_municipio) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]),
                              _c("tr", [
                                _c(
                                  "td",
                                  {
                                    staticClass: "title",
                                    staticStyle: { width: "15%" },
                                  },
                                  [_vm._v(" Entidad Federativa: ")]
                                ),
                                _c(
                                  "td",
                                  {
                                    staticClass: "user",
                                    staticStyle: { width: "30%" },
                                    attrs: { colspan: "4" },
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(domicilio.entidad) + " "
                                    ),
                                  ]
                                ),
                              ]),
                              _c("tr", [
                                _c(
                                  "td",
                                  {
                                    staticClass: "title",
                                    staticStyle: { width: "15%" },
                                  },
                                  [_vm._v(" Colonia o Urbanización: ")]
                                ),
                                _c(
                                  "td",
                                  {
                                    staticClass: "user",
                                    staticStyle: { width: "15%" },
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(domicilio.colonia) + " "
                                    ),
                                  ]
                                ),
                                _c(
                                  "td",
                                  {
                                    staticClass: "right-title",
                                    staticStyle: { width: "7.5%" },
                                  },
                                  [_vm._v(" CP: ")]
                                ),
                                _c(
                                  "td",
                                  {
                                    staticClass: "user",
                                    staticStyle: { width: "7.5%" },
                                    attrs: { colspan: "2" },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(domicilio.codigo_postal) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]),
                              _c("tr", [
                                _c(
                                  "td",
                                  {
                                    staticClass: "title",
                                    staticStyle: { width: "15%" },
                                  },
                                  [_vm._v(" Ciudad o Población: ")]
                                ),
                                _c(
                                  "td",
                                  {
                                    staticClass: "user",
                                    staticStyle: { width: "15%" },
                                  },
                                  [_vm._v(" " + _vm._s(domicilio.ciudad) + " ")]
                                ),
                                _c(
                                  "td",
                                  {
                                    staticClass: "right-title",
                                    staticStyle: { width: "7.5%" },
                                  },
                                  [_vm._v(" País: ")]
                                ),
                                _c(
                                  "td",
                                  {
                                    staticClass: "user",
                                    staticStyle: { width: "7.5%" },
                                    attrs: { colspan: "2" },
                                  },
                                  [_vm._v(" " + _vm._s(domicilio.pais) + " ")]
                                ),
                              ]),
                            ])
                          : _vm._e(),
                      ])
                    }),
                    0
                  )
                : _c("tr", { staticClass: "section-empty" }, [
                    _c("td", [_vm._v(" No aplica ")]),
                  ]),
            ]),
            _c("table", { staticClass: "tabla" }, [
              _vm._m(4),
              true
                ? _c(
                    "div",
                    _vm._l(_vm.compareciente.domicilios, function (domicilio) {
                      return _c("div", { key: domicilio.id }, [
                        domicilio.tipo == "Fiscal"
                          ? _c("div", [
                              _c("tr", [
                                _c(
                                  "td",
                                  {
                                    staticClass: "title",
                                    staticStyle: { width: "15%" },
                                  },
                                  [_vm._v(" Calle / Avenida / Vía: ")]
                                ),
                                _c(
                                  "td",
                                  {
                                    staticClass: "user",
                                    staticStyle: { width: "30%" },
                                    attrs: { colspan: "4" },
                                  },
                                  [_vm._v(" " + _vm._s(domicilio.calle) + " ")]
                                ),
                                _c("td", { attrs: { colspan: "1" } }),
                                _c(
                                  "td",
                                  {
                                    staticClass: "maps",
                                    staticStyle: { width: "50%" },
                                    attrs: { rowspan: "8", valign: "center" },
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        width: "95%",
                                        height: "70%",
                                        src: `https://maps.googleapis.com/maps/api/staticmap?center=${domicilio.lat},${domicilio.lng}&zoom=17&scale=1&size=600x300&maptype=roadmap&format=png&visual_refresh=true&markers=color:red%7Alabel:C%7C${domicilio.lat},${domicilio.lng}&key=AIzaSyC1LrDy2Gk5QhV8SjD7k4S6b4zg-wF2YEA`,
                                      },
                                    }),
                                  ]
                                ),
                              ]),
                              _c("tr", [
                                _c(
                                  "td",
                                  {
                                    staticClass: "title",
                                    staticStyle: { width: "15%" },
                                  },
                                  [_vm._v(" Número Exterior: ")]
                                ),
                                _c(
                                  "td",
                                  {
                                    staticClass: "user",
                                    staticStyle: { width: "15%" },
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(domicilio.num_exterior) + " "
                                    ),
                                  ]
                                ),
                                _c(
                                  "td",
                                  {
                                    staticClass: "right-title",
                                    staticStyle: { width: "7.5%" },
                                  },
                                  [_vm._v(" Interior: ")]
                                ),
                                _c(
                                  "td",
                                  {
                                    staticClass: "user",
                                    staticStyle: { width: "7.5%" },
                                    attrs: { colspan: "2" },
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(domicilio.num_interior) + " "
                                    ),
                                  ]
                                ),
                              ]),
                              _c("tr", [
                                _c(
                                  "td",
                                  {
                                    staticClass: "title",
                                    staticStyle: { width: "15%" },
                                  },
                                  [_vm._v(" Alcaldía / Municipio: ")]
                                ),
                                _c(
                                  "td",
                                  {
                                    staticClass: "user",
                                    staticStyle: { width: "30%" },
                                    attrs: { colspan: "4" },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(domicilio.delegacion_municipio) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]),
                              _c("tr", [
                                _c(
                                  "td",
                                  {
                                    staticClass: "title",
                                    staticStyle: { width: "15%" },
                                  },
                                  [_vm._v(" Entidad Federativa: ")]
                                ),
                                _c(
                                  "td",
                                  {
                                    staticClass: "user",
                                    staticStyle: { width: "30%" },
                                    attrs: { colspan: "4" },
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(domicilio.entidad) + " "
                                    ),
                                  ]
                                ),
                              ]),
                              _c("tr", [
                                _c(
                                  "td",
                                  {
                                    staticClass: "title",
                                    staticStyle: { width: "15%" },
                                  },
                                  [_vm._v(" Colonia o Urbanización: ")]
                                ),
                                _c(
                                  "td",
                                  {
                                    staticClass: "user",
                                    staticStyle: { width: "15%" },
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(domicilio.colonia) + " "
                                    ),
                                  ]
                                ),
                                _c(
                                  "td",
                                  {
                                    staticClass: "right-title",
                                    staticStyle: { width: "7.5%" },
                                  },
                                  [_vm._v(" CP: ")]
                                ),
                                _c(
                                  "td",
                                  {
                                    staticClass: "user",
                                    staticStyle: { width: "7.5%" },
                                    attrs: { colspan: "2" },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(domicilio.codigo_postal) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]),
                              _c("tr", [
                                _c(
                                  "td",
                                  {
                                    staticClass: "title",
                                    staticStyle: { width: "15%" },
                                  },
                                  [_vm._v(" Ciudad o Población: ")]
                                ),
                                _c(
                                  "td",
                                  {
                                    staticClass: "user",
                                    staticStyle: { width: "15%" },
                                  },
                                  [_vm._v(" " + _vm._s(domicilio.ciudad) + " ")]
                                ),
                                _c(
                                  "td",
                                  {
                                    staticClass: "right-title",
                                    staticStyle: { width: "7.5%" },
                                  },
                                  [_vm._v(" País: ")]
                                ),
                                _c(
                                  "td",
                                  {
                                    staticClass: "user",
                                    staticStyle: { width: "7.5%" },
                                    attrs: { colspan: "2" },
                                  },
                                  [_vm._v(" " + _vm._s(domicilio.pais) + " ")]
                                ),
                              ]),
                            ])
                          : _vm._e(),
                      ])
                    }),
                    0
                  )
                : _c("div", [_vm._m(5)]),
            ]),
          ]),
          _c("div", { attrs: { id: "seccion_cuatro" } }, [
            _c("table", { staticClass: "tabla" }, [
              _vm._m(6),
              _c("tr", [
                _c(
                  "td",
                  { staticClass: "title", staticStyle: { width: "15%" } },
                  [_vm._v(" Teléfono Fijo: ")]
                ),
                _vm.compareciente.telefonos[0]
                  ? _c(
                      "td",
                      { staticClass: "user", staticStyle: { width: "50%" } },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.compareciente.telefonos[0].clave) +
                            " " +
                            _vm._s(_vm.compareciente.telefonos[0].numero) +
                            " " +
                            _vm._s(_vm.compareciente.telefonos[0].extension) +
                            " "
                        ),
                      ]
                    )
                  : _c(
                      "td",
                      { staticClass: "user", staticStyle: { width: "50%" } },
                      [_vm._v(" No aplica ")]
                    ),
                _c(
                  "td",
                  { staticClass: "right-title", staticStyle: { width: "10%" } },
                  [_vm._v(" Facebook: ")]
                ),
                _c(
                  "td",
                  { staticClass: "user", staticStyle: { width: "25%" } },
                  [_vm._v(" " + _vm._s(_vm.compareciente.facebook) + " ")]
                ),
              ]),
              _c("tr", [
                _c(
                  "td",
                  { staticClass: "title", staticStyle: { width: "15%" } },
                  [_vm._v(" Celular: ")]
                ),
                _c(
                  "td",
                  { staticClass: "user", staticStyle: { width: "50%" } },
                  [
                    _vm._v(
                      " " +
                        _vm._s(_vm.compareciente.telefonos[1].clave) +
                        " " +
                        _vm._s(_vm.compareciente.telefonos[1].numero) +
                        " " +
                        _vm._s(_vm.compareciente.telefonos[1].extension) +
                        " "
                    ),
                  ]
                ),
                _c(
                  "td",
                  { staticClass: "right-title", staticStyle: { width: "10%" } },
                  [_vm._v(" Instagram: ")]
                ),
                _c(
                  "td",
                  { staticClass: "user", staticStyle: { width: "25%" } },
                  [_vm._v(" " + _vm._s(_vm.compareciente.instagram) + " ")]
                ),
              ]),
              _c("tr", [
                _c(
                  "td",
                  { staticClass: "title", staticStyle: { width: "15%" } },
                  [_vm._v(" Correo electrónico: ")]
                ),
                _c(
                  "td",
                  { staticClass: "user", staticStyle: { width: "50%" } },
                  [_vm._v(" " + _vm._s(_vm.compareciente.email) + " ")]
                ),
                _c(
                  "td",
                  { staticClass: "right-title", staticStyle: { width: "15%" } },
                  [_vm._v(" Twitter: ")]
                ),
                _c(
                  "td",
                  { staticClass: "user", staticStyle: { width: "50%" } },
                  [_vm._v(" " + _vm._s(_vm.compareciente.twitter) + " ")]
                ),
              ]),
            ]),
          ]),
          _c("div", { attrs: { id: "seccion_cinco" } }, [
            _c("table", { staticClass: "tabla" }, [
              _vm._m(7),
              false
                ? _c(
                    "div",
                    _vm._l(_vm.compareciente.domicilios, function (domicilio) {
                      return _c("div", { key: domicilio.id }, [
                        domicilio.tipo == "Extranjero"
                          ? _c("div", [
                              domicilio.calle === "No aplica"
                                ? _c("div", [_vm._m(8, true)])
                                : _c("div", [
                                    _c("tr", [
                                      _c(
                                        "td",
                                        {
                                          staticClass: "title",
                                          staticStyle: { width: "15%" },
                                        },
                                        [_vm._v(" Calle / Avenida / Vía: ")]
                                      ),
                                      _c(
                                        "td",
                                        {
                                          staticClass: "user",
                                          staticStyle: { width: "45%" },
                                          attrs: { colspan: "3" },
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(domicilio.calle) + " "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "td",
                                        {
                                          staticClass: "right-title",
                                          staticStyle: { width: "15%" },
                                        },
                                        [_vm._v(" Num. Exterior: ")]
                                      ),
                                      _c(
                                        "td",
                                        {
                                          staticClass: "user",
                                          staticStyle: { width: "8.33%" },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(domicilio.num_exterior) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "td",
                                        {
                                          staticClass: "right-title",
                                          staticStyle: { width: "8.33%" },
                                        },
                                        [_vm._v(" Num. Interior: ")]
                                      ),
                                      _c(
                                        "td",
                                        {
                                          staticClass: "user",
                                          staticStyle: { width: "8.33%" },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(domicilio.num_interior) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]),
                                    _c("tr", [
                                      _c(
                                        "td",
                                        {
                                          staticClass: "title",
                                          staticStyle: { width: "15%" },
                                        },
                                        [_vm._v(" Colonia o Urbanización: ")]
                                      ),
                                      _c(
                                        "td",
                                        {
                                          staticClass: "user",
                                          staticStyle: { width: "45%" },
                                          attrs: { colspan: "3" },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(domicilio.colonia) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "td",
                                        {
                                          staticClass: "right-title",
                                          staticStyle: { width: "15%" },
                                        },
                                        [_vm._v(" Alcaldía / Municipio: ")]
                                      ),
                                      _c(
                                        "td",
                                        {
                                          staticClass: "user",
                                          staticStyle: { width: "30%" },
                                          attrs: { colspan: "3" },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                domicilio.delegacion_municipio
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]),
                                    _c("tr", [
                                      _c(
                                        "td",
                                        {
                                          staticClass: "title",
                                          staticStyle: { width: "15%" },
                                        },
                                        [_vm._v(" Ciudad o Población: ")]
                                      ),
                                      _c(
                                        "td",
                                        {
                                          staticClass: "user",
                                          staticStyle: { width: "15%" },
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(domicilio.ciudad) + " "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "td",
                                        {
                                          staticClass: "right-title",
                                          staticStyle: { width: "10%" },
                                        },
                                        [_vm._v(" Código Postal: ")]
                                      ),
                                      _c(
                                        "td",
                                        {
                                          staticClass: "user",
                                          staticStyle: { width: "10%" },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(domicilio.codigo_postal) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "td",
                                        {
                                          staticClass: "right-title",
                                          staticStyle: { width: "15%" },
                                        },
                                        [_vm._v(" Entidad Federativa: ")]
                                      ),
                                      _c(
                                        "td",
                                        {
                                          staticClass: "user",
                                          staticStyle: { width: "30%" },
                                          attrs: { colspan: "3" },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(domicilio.entidad) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]),
                            ])
                          : _vm._e(),
                      ])
                    }),
                    0
                  )
                : _c("div", [_vm._m(9)]),
            ]),
          ]),
          _c("div", { attrs: { id: "seccion_seis" } }, [
            _c("table", { staticClass: "tabla" }, [
              _vm._m(10),
              _vm.compareciente.representantes.length > 0
                ? _c("div", [
                    _vm.compareciente.representantes[0].nombre === "No aplica"
                      ? _c("div", [_vm._m(11)])
                      : _c("div", [
                          _c("tr", [
                            _c(
                              "td",
                              {
                                staticClass: "title",
                                staticStyle: { width: "15%" },
                              },
                              [_vm._v(" Nombre y apellidos: ")]
                            ),
                            _c(
                              "td",
                              {
                                staticClass: "user",
                                staticStyle: { width: "85%" },
                                attrs: { colspan: "7" },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.compareciente.representantes[0].nombre
                                    ) +
                                    " " +
                                    _vm._s(
                                      _vm.compareciente.representantes[0]
                                        .paterno
                                    ) +
                                    " " +
                                    _vm._s(
                                      _vm.compareciente.representantes[0]
                                        .materno
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ]),
                          _c("tr", [
                            _c(
                              "td",
                              {
                                staticClass: "title",
                                staticStyle: { width: "20%" },
                                attrs: { colspan: "2" },
                              },
                              [_vm._v(" Fecha y Lugar de Nacimiento: ")]
                            ),
                            _c(
                              "td",
                              {
                                staticClass: "user",
                                staticStyle: { width: "40%" },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.compareciente.representantes[0]
                                        .fecha_nacimiento
                                    ) +
                                    " " +
                                    _vm._s(
                                      _vm.compareciente.representantes[0]
                                        .lugar_nacim_ciudad
                                    ) +
                                    " " +
                                    _vm._s(
                                      _vm.compareciente.representantes[0]
                                        .lugar_nacim_estado
                                    ) +
                                    " " +
                                    _vm._s(
                                      _vm.compareciente.representantes[0]
                                        .lugar_nacim_pais
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                            _c(
                              "td",
                              {
                                staticClass: "right-title",
                                staticStyle: { width: "8.33%" },
                              },
                              [_vm._v(" RFC: ")]
                            ),
                            _c(
                              "td",
                              {
                                staticClass: "user",
                                staticStyle: { width: "15%" },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.compareciente.representantes[0].rfc
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ]),
                          _c("tr", [
                            _c(
                              "td",
                              {
                                staticClass: "title",
                                staticStyle: { width: "20%" },
                                attrs: { colspan: "2" },
                              },
                              [_vm._v(" Datos de la escritura de poderes: ")]
                            ),
                            _c(
                              "td",
                              {
                                staticClass: "user",
                                staticStyle: { width: "40%" },
                              },
                              [
                                _vm.compareciente.representantes.length > 0
                                  ? _c("div", [
                                      _vm.compareciente.representantes[0]
                                        .instrumento_numero == "No aplica"
                                        ? _c("span", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.compareciente
                                                    .representantes[0]
                                                    .instrumento_numero
                                                ) +
                                                " "
                                            ),
                                          ])
                                        : _c("span", { staticClass: "info" }, [
                                            _vm._v(
                                              " Constancia del instrumento público número " +
                                                _vm._s(
                                                  _vm.compareciente
                                                    .representantes[0]
                                                    .instrumento_numero
                                                ) +
                                                ", de fecha " +
                                                _vm._s(
                                                  _vm.compareciente
                                                    .representantes[0]
                                                    .instrumento_numero_fecha
                                                ) +
                                                ", otorgada ante la fe del licenciado " +
                                                _vm._s(
                                                  _vm.compareciente
                                                    .representantes[0]
                                                    .instrumento_fedatario_nombre
                                                ) +
                                                ", número " +
                                                _vm._s(
                                                  _vm.compareciente
                                                    .representantes[0]
                                                    .instrumento_fedatario_numero
                                                ) +
                                                " de " +
                                                _vm._s(
                                                  _vm.compareciente
                                                    .representantes[0]
                                                    .instrumento_fedatario_estado
                                                ) +
                                                " "
                                            ),
                                          ]),
                                    ])
                                  : _vm._e(),
                              ]
                            ),
                            _c(
                              "td",
                              {
                                staticClass: "right-title",
                                staticStyle: { width: "8.33%" },
                              },
                              [_vm._v(" CURP: ")]
                            ),
                            _c(
                              "td",
                              {
                                staticClass: "user",
                                staticStyle: { width: "31.66%" },
                                attrs: { colspan: "3" },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.compareciente.representantes[0].curp
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ]),
                          _c("tr", [
                            _c(
                              "td",
                              {
                                staticClass: "title",
                                staticStyle: { width: "10%" },
                              },
                              [_vm._v(" Domicilio: ")]
                            ),
                            _c(
                              "td",
                              {
                                staticClass: "user",
                                staticStyle: { width: "90%" },
                                attrs: { colspan: "6" },
                              },
                              [
                                _vm.compareciente.representantes.length > 0
                                  ? _c("div", [
                                      _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.compareciente
                                                .representantes[0].domicilios[0]
                                                .calle
                                            ) +
                                            " " +
                                            _vm._s(
                                              _vm.compareciente
                                                .representantes[0].domicilios[0]
                                                .num_exterior
                                            ) +
                                            " " +
                                            _vm._s(
                                              _vm.compareciente
                                                .representantes[0].domicilios[0]
                                                .num_interior
                                            ) +
                                            " " +
                                            _vm._s(
                                              _vm.compareciente
                                                .representantes[0].domicilios[0]
                                                .colonia
                                            ) +
                                            " " +
                                            _vm._s(
                                              _vm.compareciente
                                                .representantes[0].domicilios[0]
                                                .delegacion_municipio
                                            ) +
                                            " " +
                                            _vm._s(
                                              _vm.compareciente
                                                .representantes[0].domicilios[0]
                                                .ciudad
                                            ) +
                                            " " +
                                            _vm._s(
                                              _vm.compareciente
                                                .representantes[0].domicilios[0]
                                                .entidad
                                            ) +
                                            " " +
                                            _vm._s(
                                              _vm.compareciente
                                                .representantes[0].domicilios[0]
                                                .pais
                                            ) +
                                            " " +
                                            _vm._s(
                                              _vm.compareciente
                                                .representantes[0].domicilios[0]
                                                .codigo_postal
                                            ) +
                                            " "
                                        ),
                                      ]),
                                    ])
                                  : _vm._e(),
                              ]
                            ),
                          ]),
                        ]),
                  ])
                : _c("div", [_vm._m(12)]),
            ]),
          ]),
          _c("div", { attrs: { id: "seccion_siete" } }, [
            _c(
              "table",
              {
                staticClass: "tabla new-page",
                staticStyle: { "border-collapse": "collapse !important" },
              },
              [
                _vm._m(13),
                false
                  ? _c("div", [
                      _vm._m(14),
                      _c("tr", { staticStyle: { height: "20px" } }, [
                        _c("td", {
                          staticClass: "space",
                          staticStyle: { width: "2%" },
                          attrs: { rowspan: "3" },
                        }),
                        _c(
                          "td",
                          {
                            staticClass: "verifycheck",
                            staticStyle: {
                              width: "3%",
                              "border-style": "dotted",
                            },
                            attrs: { rowspan: "3" },
                          },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.leyenda_beneficiario_1) + " "
                            ),
                          ]
                        ),
                        _c("td", {
                          staticClass: "space",
                          staticStyle: { width: "95%" },
                          attrs: { colspan: "6" },
                        }),
                      ]),
                      _c("tr", [
                        _c("td", {
                          staticClass: "space",
                          staticStyle: { width: "2%" },
                        }),
                        _c(
                          "td",
                          {
                            staticClass: "title",
                            staticStyle: { width: "60%" },
                            attrs: { colspan: "4" },
                          },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.leyenda_beneficiario_2) + " "
                            ),
                          ]
                        ),
                        _c("td", {
                          staticClass: "user",
                          staticStyle: {
                            width: "35%",
                            "background-color": "white !important",
                            "border-bottom": "solid 2px black",
                          },
                          attrs: { colspan: "4" },
                        }),
                      ]),
                      _c("tr", [
                        _c("td", {
                          staticClass: "space",
                          staticStyle: { width: "2%" },
                        }),
                        _c(
                          "td",
                          {
                            staticClass: "title",
                            staticStyle: { width: "60%" },
                            attrs: { colspan: "4" },
                          },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.leyenda_beneficiario_3) + " "
                            ),
                          ]
                        ),
                        _c(
                          "td",
                          {
                            staticStyle: {
                              width: "35%",
                              "vertical-align": "top",
                              "font-size": "10px",
                              "text-align": "center",
                            },
                            attrs: { colspan: "4" },
                          },
                          [_vm._v(" (NOMBRE Y FIRMA) ")]
                        ),
                      ]),
                      _vm._m(15),
                      _vm._m(16),
                      _vm._m(17),
                    ])
                  : _c("div", [_vm._m(18)]),
              ]
            ),
          ]),
          _c("div", { attrs: { id: "seccion_ocho" } }, [
            _c(
              "table",
              {
                staticClass: "tabla",
                staticStyle: { "border-collapse": "collapse !important" },
              },
              [
                _vm._m(19),
                false
                  ? _c("div", [
                      _vm._m(20),
                      _c("tr", { staticStyle: { height: "20px" } }, [
                        _c("td", {
                          staticClass: "space",
                          staticStyle: { width: "2%" },
                          attrs: { rowspan: "3" },
                        }),
                        _c(
                          "td",
                          {
                            staticClass: "verifycheck",
                            staticStyle: {
                              width: "3%",
                              "border-style": "dotted",
                            },
                            attrs: { rowspan: "3" },
                          },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.leyenda_beneficiario_4) + " "
                            ),
                          ]
                        ),
                        _c("td", {
                          staticClass: "space",
                          staticStyle: { width: "95%" },
                          attrs: { colspan: "6" },
                        }),
                      ]),
                      _c("tr", [
                        _c("td", {
                          staticClass: "space",
                          staticStyle: { width: "2%" },
                        }),
                        _c(
                          "td",
                          {
                            staticClass: "title",
                            staticStyle: { width: "60%" },
                            attrs: { colspan: "4" },
                          },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.leyenda_beneficiario_5) + " "
                            ),
                          ]
                        ),
                        _c("td", {
                          staticClass: "user",
                          staticStyle: {
                            width: "35%",
                            "background-color": "white !important",
                            "border-bottom": "solid 2px black",
                          },
                          attrs: { colspan: "6" },
                        }),
                      ]),
                      _c("tr", [
                        _c("td", {
                          staticClass: "space",
                          staticStyle: { width: "2%" },
                        }),
                        _c(
                          "td",
                          {
                            staticClass: "title",
                            staticStyle: { width: "60%" },
                            attrs: { colspan: "4" },
                          },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.leyenda_beneficiario_6) + " "
                            ),
                          ]
                        ),
                        _c(
                          "td",
                          {
                            staticStyle: {
                              width: "35%",
                              "vertical-align": "top",
                              "font-size": "10px",
                              "text-align": "center",
                            },
                            attrs: { colspan: "4" },
                          },
                          [_vm._v(" (NOMBRE Y FIRMA) ")]
                        ),
                      ]),
                      _vm._m(21),
                      _vm._m(22),
                      _vm._m(23),
                      _c("tr", {
                        staticClass: "space",
                        staticStyle: { height: "5px" },
                      }),
                      _vm._m(24),
                      _c("tr", {
                        staticClass: "space",
                        staticStyle: { height: "10px" },
                      }),
                      _vm._m(25),
                      _c("tr", {
                        staticClass: "space",
                        staticStyle: { height: "5px" },
                      }),
                      _vm._m(26),
                      _c("tr", {
                        staticClass: "space",
                        staticStyle: { height: "50px" },
                      }),
                      _vm._m(27),
                      _vm._m(28),
                      _vm._m(29),
                    ])
                  : _c("div", [_vm._m(30)]),
              ]
            ),
          ]),
          false
            ? _c(
                "div",
                { attrs: { id: "seccion_nueve" } },
                _vm._l(_vm.compareciente.documentos, function (doc) {
                  return _c("div", { key: doc.id }, [
                    _c("table", { staticClass: "tabla new-page" }, [
                      _vm._m(31, true),
                      _c("tr", [
                        _c(
                          "td",
                          {
                            staticStyle: {
                              "text-align": "left",
                              "font-size": "14px",
                              "font-weight": "bold",
                            },
                            attrs: { colspan: "8" },
                          },
                          [_vm._v(" " + _vm._s(doc.tipo) + " ")]
                        ),
                      ]),
                      _c("tr", [
                        _c(
                          "td",
                          {
                            staticStyle: {
                              "text-align": "left",
                              "font-size": "14px",
                              "font-weight": "bold",
                            },
                            attrs: { colspan: "8" },
                          },
                          [
                            _c("img", {
                              staticClass: "docs",
                              attrs: {
                                width: "100%",
                                "data-v-0e3bdfbb": "",
                                src: `${doc.documento}?sv=2023-01-03&st=2024-02-09T17%3A04%3A42Z&se=2034-02-11T17%3A04%3A00Z&sr=c&sp=rl&sig=rq9SDEcZMPv%2FbgiTq9FRE66lHZvEAeeiMqytpyiH2sk%3D`,
                              },
                            }),
                          ]
                        ),
                      ]),
                    ]),
                  ])
                }),
                0
              )
            : _vm._e(),
        ])
      : _vm._e(),
    _vm.compareciente.persona == "Persona Moral" ||
    _vm.compareciente.persona == "Moral"
      ? _c("div", [
          _c("img", {
            staticClass: "logo",
            attrs: { alt: "Logo_Correduria_Digital", src: _vm.urlBanner },
          }),
          _c("div", { attrs: { id: "seccion_uno" } }, [
            _c("table", { staticClass: "tabla" }, [
              _vm._m(32),
              _c("tr", { staticClass: "firstline" }, [
                _vm.compareciente.estatus_migratorio !== "No aplica"
                  ? _c("div", [
                      _c("td", { staticClass: "title" }, [
                        _vm._v(" Persona Moral Mexicana "),
                      ]),
                      _c("td", { staticClass: "select" }, [
                        _vm.compareciente.nacionalidades_nombres == "México"
                          ? _c("h3", { staticClass: "filledcircle" })
                          : _c("h3", { staticClass: "circle" }),
                      ]),
                      _c("td", {
                        staticClass: "space",
                        staticStyle: { width: "5%" },
                      }),
                      _c("td", { staticClass: "title" }, [
                        _vm._v(" Persona Moral Extranjera "),
                      ]),
                      _c(
                        "td",
                        { staticClass: "select", staticStyle: { width: "5%" } },
                        [
                          _vm.compareciente.nacionalidades_nombres != "México"
                            ? _c("h3", { staticClass: "filledcircle" })
                            : _c("h3", { staticClass: "circle" }),
                        ]
                      ),
                    ])
                  : _vm._e(),
                _c("td", { staticClass: "space" }),
                _c("td", { staticClass: "right-title" }, [_vm._v(" Fecha: ")]),
                _c(
                  "td",
                  { staticClass: "user", staticStyle: { width: "30%" } },
                  [_vm._v(" " + _vm._s(_vm.fecha) + " ")]
                ),
              ]),
            ]),
          ]),
          _c("div", { attrs: { id: "seccion_dos" } }, [
            _c("table", { staticClass: "tabla" }, [
              _vm._m(33),
              _c("tr", { staticClass: "firstline" }, [
                _c(
                  "td",
                  { staticClass: "title", staticStyle: { width: "15%" } },
                  [_vm._v(" Denominación Social: ")]
                ),
                _c(
                  "td",
                  {
                    staticClass: "user",
                    staticStyle: { width: "80%" },
                    attrs: { colspan: "7" },
                  },
                  [
                    _vm._v(
                      " " + _vm._s(_vm.compareciente.denominacion_social) + " "
                    ),
                  ]
                ),
              ]),
              _c("tr", [
                _c(
                  "td",
                  { staticClass: "title", staticStyle: { width: "15%" } },
                  [_vm._v(" Fecha de Constitución: ")]
                ),
                _c(
                  "td",
                  {
                    staticClass: "user",
                    staticStyle: { width: "30%" },
                    attrs: { colspan: "3" },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.compareciente.fecha_constitucion.substr(0, 10)
                        ) +
                        " "
                    ),
                  ]
                ),
                _c(
                  "td",
                  {
                    staticClass: "right-title",
                    staticStyle: { width: "7.5%" },
                  },
                  [_vm._v(" Nacionalidad: ")]
                ),
                _c(
                  "td",
                  { staticClass: "user", staticStyle: { width: "7.5%" } },
                  [
                    _vm._v(
                      " " +
                        _vm._s(_vm.compareciente.nacionalidades_nombres) +
                        " "
                    ),
                  ]
                ),
                _c(
                  "td",
                  { staticClass: "right-title", staticStyle: { width: "10%" } },
                  [_vm._v(" RFC o Tax ID: ")]
                ),
                _c(
                  "td",
                  { staticClass: "user", staticStyle: { width: "25%" } },
                  [_vm._v(" " + _vm._s(_vm.compareciente.rfc) + " ")]
                ),
              ]),
              _c("tr", [
                _c(
                  "td",
                  { staticClass: "title", staticStyle: { width: "15%" } },
                  [_vm._v(" Actividad o Giro de Negocios: ")]
                ),
                _c(
                  "td",
                  {
                    staticClass: "user",
                    staticStyle: { width: "30%" },
                    attrs: { colspan: "4" },
                  },
                  [_vm._v(" " + _vm._s(_vm.compareciente.actividad) + " ")]
                ),
                _c(
                  "td",
                  { staticClass: "right-title", staticStyle: { width: "15%" } },
                  [_vm._v(" Domicilio Social: ")]
                ),
                _c(
                  "td",
                  {
                    staticClass: "user",
                    staticStyle: { width: "35%" },
                    attrs: { colspan: "2" },
                  },
                  [
                    _vm._v(
                      " " + _vm._s(_vm.compareciente.domicilio_social) + " "
                    ),
                  ]
                ),
              ]),
              _c("tr", [
                _c(
                  "td",
                  {
                    staticClass: "title",
                    staticStyle: { width: "15%", "vertical-align": "top" },
                  },
                  [_vm._v(" Objeto Social: ")]
                ),
                _c(
                  "td",
                  {
                    staticClass: "verifycheck",
                    staticStyle: {
                      "text-align": "left",
                      "vertical-align": "top",
                      "font-weight": "lighter",
                      "font-size": "12px",
                      width: "65%",
                      height: "80px",
                    },
                    attrs: { colspan: "7" },
                  },
                  [_vm._v(" " + _vm._s(_vm.compareciente.objeto_social) + " ")]
                ),
              ]),
            ]),
          ]),
          _c("div", { attrs: { id: "seccion_tres" } }, [
            _c("table", { staticClass: "tabla" }, [
              _vm._m(34),
              true
                ? _c("div", [
                    _c("tr", [
                      _c(
                        "td",
                        { staticClass: "title", staticStyle: { width: "15%" } },
                        [_vm._v(" Instrumento Número: ")]
                      ),
                      _c(
                        "td",
                        {
                          staticClass: "user",
                          staticStyle: { width: "20%" },
                          attrs: { colspan: "2" },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.compareciente.instrumento_numero) +
                              " "
                          ),
                        ]
                      ),
                      _c(
                        "td",
                        {
                          staticClass: "right-title",
                          staticStyle: { width: "15%" },
                        },
                        [_vm._v(" De fecha: ")]
                      ),
                      _c(
                        "td",
                        {
                          staticClass: "user",
                          staticStyle: { width: "17.5%" },
                          attrs: { colspan: "2" },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.compareciente.instrumento_numero_fecha.substr(
                                  0,
                                  10
                                )
                              ) +
                              " "
                          ),
                        ]
                      ),
                      _c(
                        "td",
                        {
                          staticClass: "right-title",
                          staticStyle: { width: "15%" },
                        },
                        [_vm._v(" Fedatario: ")]
                      ),
                      _c(
                        "td",
                        {
                          staticClass: "user",
                          staticStyle: { width: "17.5%" },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.compareciente.instrumento_fedatario) +
                              " "
                          ),
                        ]
                      ),
                    ]),
                    _c("tr", [
                      _c(
                        "td",
                        { staticClass: "title", staticStyle: { width: "15%" } },
                        [_vm._v(" Nombre del Fedatario: ")]
                      ),
                      _c(
                        "td",
                        {
                          staticClass: "user",
                          staticStyle: { width: "35%" },
                          attrs: { colspan: "3" },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.compareciente.instrumento_fedatario_nombre
                              ) +
                              " "
                          ),
                        ]
                      ),
                      _c(
                        "td",
                        {
                          staticClass: "right-title",
                          staticStyle: { width: "8.75%" },
                        },
                        [_vm._v(" Número ")]
                      ),
                      _c(
                        "td",
                        {
                          staticClass: "user",
                          staticStyle: { width: "8.75%" },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.compareciente.instrumento_fedatario_numero
                              ) +
                              " "
                          ),
                        ]
                      ),
                      _c(
                        "td",
                        {
                          staticClass: "right-title",
                          staticStyle: { width: "15%" },
                        },
                        [_vm._v(" Lugar: ")]
                      ),
                      _c(
                        "td",
                        {
                          staticClass: "user",
                          staticStyle: { width: "17.5%" },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.compareciente.instrumento_fedatario_estado
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ]),
                    _c("tr", [
                      _c(
                        "td",
                        {
                          staticClass: "title",
                          staticStyle: { width: "30%" },
                          attrs: { colspan: "2" },
                        },
                        [
                          _vm._v(
                            " Datos de Inscripción en el RPC - Folio Número: "
                          ),
                        ]
                      ),
                      _c(
                        "td",
                        {
                          staticClass: "user",
                          staticStyle: { width: "20%" },
                          attrs: { colspan: "2" },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.compareciente.instrumento_rpf_folio) +
                              " "
                          ),
                        ]
                      ),
                      _c(
                        "td",
                        {
                          staticClass: "right-title",
                          staticStyle: { width: "17.5%" },
                          attrs: { colspan: "2" },
                        },
                        [_vm._v(" Fecha y Lugar: ")]
                      ),
                      _c(
                        "td",
                        {
                          staticClass: "user",
                          staticStyle: { width: "32.5%" },
                          attrs: { colspan: "2" },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.compareciente.instrumento_rpf_folio_fecha.substr(
                                  0,
                                  10
                                )
                              ) +
                              " " +
                              _vm._s(
                                _vm.compareciente.instrumento_rpf_folio_lugar
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ]),
                  ])
                : _c("div", [_vm._m(35)]),
            ]),
          ]),
          _c("div", { attrs: { id: "seccion_cuatro" } }, [
            _c("table", { staticClass: "tabla" }, [
              _vm._m(36),
              false
                ? _c(
                    "div",
                    _vm._l(_vm.compareciente.domicilios, function (domicilio) {
                      return _c("div", { key: domicilio.id }, [
                        domicilio.tipo == "Particular" ||
                        domicilio.tipo == "Particular y Fiscal"
                          ? _c("div", [
                              _c("tr", [
                                _c(
                                  "td",
                                  {
                                    staticClass: "title",
                                    staticStyle: { width: "15%" },
                                  },
                                  [_vm._v(" Calle / Avenida / Vía: ")]
                                ),
                                _c(
                                  "td",
                                  {
                                    staticClass: "user",
                                    staticStyle: { width: "30%" },
                                    attrs: { colspan: "4" },
                                  },
                                  [_vm._v(" " + _vm._s(domicilio.calle) + " ")]
                                ),
                                _c("td", { attrs: { colspan: "1" } }),
                                _c(
                                  "td",
                                  {
                                    staticClass: "maps",
                                    staticStyle: { width: "50%" },
                                    attrs: { rowspan: "8", valign: "center" },
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        width: "95%",
                                        height: "70%",
                                        src: `https://maps.googleapis.com/maps/api/staticmap?center=${domicilio.lat},${domicilio.lng}&zoom=17&scale=1&size=600x300&maptype=roadmap&format=png&visual_refresh=true&markers=color:red%7Alabel:C%7C${domicilio.lat},${domicilio.lng}&key=AIzaSyC1LrDy2Gk5QhV8SjD7k4S6b4zg-wF2YEA`,
                                      },
                                    }),
                                  ]
                                ),
                              ]),
                              _c("tr", [
                                _c(
                                  "td",
                                  {
                                    staticClass: "title",
                                    staticStyle: { width: "15%" },
                                  },
                                  [_vm._v(" Número Exterior: ")]
                                ),
                                _c(
                                  "td",
                                  {
                                    staticClass: "user",
                                    staticStyle: { width: "15%" },
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(domicilio.num_exterior) + " "
                                    ),
                                  ]
                                ),
                                _c(
                                  "td",
                                  {
                                    staticClass: "right-title",
                                    staticStyle: { width: "7.5%" },
                                  },
                                  [_vm._v(" Interior: ")]
                                ),
                                _c(
                                  "td",
                                  {
                                    staticClass: "user",
                                    staticStyle: { width: "7.5%" },
                                    attrs: { colspan: "2" },
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(domicilio.num_interior) + " "
                                    ),
                                  ]
                                ),
                              ]),
                              _c("tr", [
                                _c(
                                  "td",
                                  {
                                    staticClass: "title",
                                    staticStyle: { width: "15%" },
                                  },
                                  [_vm._v(" Alcaldía / Municipio: ")]
                                ),
                                _c(
                                  "td",
                                  {
                                    staticClass: "user",
                                    staticStyle: { width: "30%" },
                                    attrs: { colspan: "4" },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(domicilio.delegacion_municipio) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]),
                              _c("tr", [
                                _c(
                                  "td",
                                  {
                                    staticClass: "title",
                                    staticStyle: { width: "15%" },
                                  },
                                  [_vm._v(" Entidad Federativa: ")]
                                ),
                                _c(
                                  "td",
                                  {
                                    staticClass: "user",
                                    staticStyle: { width: "30%" },
                                    attrs: { colspan: "4" },
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(domicilio.entidad) + " "
                                    ),
                                  ]
                                ),
                              ]),
                              _c("tr", [
                                _c(
                                  "td",
                                  {
                                    staticClass: "title",
                                    staticStyle: { width: "15%" },
                                  },
                                  [_vm._v(" Colonia o Urbanización: ")]
                                ),
                                _c(
                                  "td",
                                  {
                                    staticClass: "user",
                                    staticStyle: { width: "15%" },
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(domicilio.colonia) + " "
                                    ),
                                  ]
                                ),
                                _c(
                                  "td",
                                  {
                                    staticClass: "right-title",
                                    staticStyle: { width: "7.5%" },
                                  },
                                  [_vm._v(" CP: ")]
                                ),
                                _c(
                                  "td",
                                  {
                                    staticClass: "user",
                                    staticStyle: { width: "7.5%" },
                                    attrs: { colspan: "2" },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(domicilio.codigo_postal) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]),
                              _c("tr", [
                                _c(
                                  "td",
                                  {
                                    staticClass: "title",
                                    staticStyle: { width: "15%" },
                                  },
                                  [_vm._v(" Ciudad o Población: ")]
                                ),
                                _c(
                                  "td",
                                  {
                                    staticClass: "user",
                                    staticStyle: { width: "15%" },
                                  },
                                  [_vm._v(" " + _vm._s(domicilio.ciudad) + " ")]
                                ),
                                _c(
                                  "td",
                                  {
                                    staticClass: "right-title",
                                    staticStyle: { width: "7.5%" },
                                  },
                                  [_vm._v(" País: ")]
                                ),
                                _c(
                                  "td",
                                  {
                                    staticClass: "user",
                                    staticStyle: { width: "7.5%" },
                                    attrs: { colspan: "2" },
                                  },
                                  [_vm._v(" " + _vm._s(domicilio.pais) + " ")]
                                ),
                              ]),
                            ])
                          : _vm._e(),
                      ])
                    }),
                    0
                  )
                : _c("div", [_vm._m(37)]),
            ]),
          ]),
          _c("div", { attrs: { id: "seccion_cinco" } }, [
            _c("table", { staticClass: "tabla" }, [
              _vm._m(38),
              true
                ? _c(
                    "div",
                    _vm._l(_vm.compareciente.domicilios, function (domicilio) {
                      return _c("div", { key: domicilio.id }, [
                        domicilio.tipo == "Fiscal"
                          ? _c("div", [
                              _c("tr", [
                                _c(
                                  "td",
                                  {
                                    staticClass: "title",
                                    staticStyle: { width: "15%" },
                                    attrs: { colspan: "2" },
                                  },
                                  [_vm._v(" Calle / Avenida / Vía: ")]
                                ),
                                _c(
                                  "td",
                                  {
                                    staticClass: "user",
                                    staticStyle: { width: "30%" },
                                    attrs: { colspan: "3" },
                                  },
                                  [_vm._v(" " + _vm._s(domicilio.calle) + " ")]
                                ),
                              ]),
                              _c("tr", [
                                _c(
                                  "td",
                                  {
                                    staticClass: "title",
                                    staticStyle: { width: "15%" },
                                    attrs: { colspan: "2" },
                                  },
                                  [_vm._v(" Número Exterior: ")]
                                ),
                                _c(
                                  "td",
                                  {
                                    staticClass: "user",
                                    staticStyle: { width: "15%" },
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(domicilio.num_exterior) + " "
                                    ),
                                  ]
                                ),
                                _c(
                                  "td",
                                  {
                                    staticClass: "right-title",
                                    staticStyle: { width: "7.5%" },
                                  },
                                  [_vm._v(" Interior: ")]
                                ),
                                _c(
                                  "td",
                                  {
                                    staticClass: "user",
                                    staticStyle: { width: "7.5%" },
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(domicilio.num_interior) + " "
                                    ),
                                  ]
                                ),
                              ]),
                              _c("tr", [
                                _c(
                                  "td",
                                  {
                                    staticClass: "title",
                                    staticStyle: { width: "15%" },
                                    attrs: { colspan: "2" },
                                  },
                                  [_vm._v(" Alcaldía / Municipio: ")]
                                ),
                                _c(
                                  "td",
                                  {
                                    staticClass: "user",
                                    staticStyle: { width: "30%" },
                                    attrs: { colspan: "3" },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(domicilio.delegacion_municipio) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]),
                              _c("tr", [
                                _c(
                                  "td",
                                  {
                                    staticClass: "title",
                                    staticStyle: { width: "15%" },
                                    attrs: { colspan: "2" },
                                  },
                                  [_vm._v(" Entidad Federativa: ")]
                                ),
                                _c(
                                  "td",
                                  {
                                    staticClass: "user",
                                    staticStyle: { width: "30%" },
                                    attrs: { colspan: "3" },
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(domicilio.entidad) + " "
                                    ),
                                  ]
                                ),
                              ]),
                              _c("tr", [
                                _c(
                                  "td",
                                  {
                                    staticClass: "title",
                                    staticStyle: { width: "15%" },
                                    attrs: { colspan: "2" },
                                  },
                                  [_vm._v(" Colonia o Urbanización: ")]
                                ),
                                _c(
                                  "td",
                                  {
                                    staticClass: "user",
                                    staticStyle: { width: "15%" },
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(domicilio.colonia) + " "
                                    ),
                                  ]
                                ),
                                _c(
                                  "td",
                                  {
                                    staticClass: "right-title",
                                    staticStyle: { width: "7.5%" },
                                  },
                                  [_vm._v(" CP: ")]
                                ),
                                _c(
                                  "td",
                                  {
                                    staticClass: "user",
                                    staticStyle: { width: "7.5%" },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(domicilio.codigo_postal) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]),
                              _c("tr", [
                                _c(
                                  "td",
                                  {
                                    staticClass: "title",
                                    staticStyle: { width: "15%" },
                                    attrs: { colspan: "2" },
                                  },
                                  [_vm._v(" Ciudad o Población: ")]
                                ),
                                _c(
                                  "td",
                                  {
                                    staticClass: "user",
                                    staticStyle: { width: "15%" },
                                  },
                                  [_vm._v(" " + _vm._s(domicilio.ciudad) + " ")]
                                ),
                                _c(
                                  "td",
                                  {
                                    staticClass: "right-title",
                                    staticStyle: { width: "7.5%" },
                                  },
                                  [_vm._v(" País: ")]
                                ),
                                _c(
                                  "td",
                                  {
                                    staticClass: "user",
                                    staticStyle: { width: "7.5%" },
                                  },
                                  [_vm._v(" " + _vm._s(domicilio.pais) + " ")]
                                ),
                              ]),
                            ])
                          : _vm._e(),
                      ])
                    }),
                    0
                  )
                : _c("div", [_vm._m(39)]),
            ]),
          ]),
          _c("div", { attrs: { id: "seccion_seis" } }, [
            _c("table", { staticClass: "tabla" }, [
              _vm._m(40),
              _vm.compareciente.representantes.length > 0
                ? _c("div", [
                    _c("tr", [
                      _c(
                        "td",
                        { staticClass: "title", staticStyle: { width: "15%" } },
                        [_vm._v(" Teléfono Fijo: ")]
                      ),
                      _c(
                        "td",
                        { staticClass: "user", staticStyle: { width: "35%" } },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.compareciente.telefonos[0].clave) +
                              " " +
                              _vm._s(_vm.compareciente.telefonos[0].numero) +
                              " " +
                              _vm._s(_vm.compareciente.telefonos[0].extension) +
                              " "
                          ),
                        ]
                      ),
                      _c(
                        "td",
                        {
                          staticClass: "right-title",
                          staticStyle: { width: "15%" },
                        },
                        [_vm._v(" Persona de Contacto: ")]
                      ),
                      _c(
                        "td",
                        { staticClass: "user", staticStyle: { width: "35%" } },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.compareciente.representantes[0].nombre
                              ) +
                              " " +
                              _vm._s(
                                _vm.compareciente.representantes[0].paterno
                              ) +
                              " " +
                              _vm._s(
                                _vm.compareciente.representantes[0].materno
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ]),
                    _c("tr", [
                      _c(
                        "td",
                        { staticClass: "title", staticStyle: { width: "15%" } },
                        [_vm._v(" Correo electrónico: ")]
                      ),
                      _c(
                        "td",
                        { staticClass: "user", staticStyle: { width: "35%" } },
                        [_vm._v(" " + _vm._s(_vm.compareciente.email) + " ")]
                      ),
                      _c(
                        "td",
                        {
                          staticClass: "right-title",
                          staticStyle: { width: "15%" },
                        },
                        [_vm._v(" WhatsApp o Red Social: ")]
                      ),
                      _c(
                        "td",
                        { staticClass: "user", staticStyle: { width: "35%" } },
                        [_vm._v(" No Aplica ")]
                      ),
                    ]),
                  ])
                : _c("div", [_vm._m(41)]),
            ]),
          ]),
          _c("div", { attrs: { id: "seccion_siete" } }, [
            _c("table", { staticClass: "tabla" }, [
              _vm._m(42),
              _vm.compareciente.representantes.length > 0
                ? _c("div", [
                    _c("tr", { staticClass: "firstline" }, [
                      _c(
                        "td",
                        { staticClass: "title", staticStyle: { width: "15%" } },
                        [_vm._v(" Nombre y apellidos: ")]
                      ),
                      _c(
                        "td",
                        {
                          staticClass: "user",
                          staticStyle: { width: "80%" },
                          attrs: { colspan: "7" },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.compareciente.representantes[0].nombre
                              ) +
                              " " +
                              _vm._s(
                                _vm.compareciente.representantes[0].paterno
                              ) +
                              " " +
                              _vm._s(
                                _vm.compareciente.representantes[0].materno
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ]),
                    _c("tr", [
                      _c(
                        "td",
                        { staticClass: "title", staticStyle: { width: "15%" } },
                        [_vm._v(" Fecha de Nacimiento: ")]
                      ),
                      _c(
                        "td",
                        {
                          staticClass: "user",
                          staticStyle: { width: "30%" },
                          attrs: { colspan: "3" },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.compareciente.representantes[0].fecha_nacimiento.substr(
                                  0,
                                  10
                                )
                              ) +
                              " "
                          ),
                        ]
                      ),
                      _c(
                        "td",
                        {
                          staticClass: "right-title",
                          staticStyle: { width: "7.5%" },
                        },
                        [_vm._v(" Sexo: ")]
                      ),
                      _c(
                        "td",
                        { staticClass: "user", staticStyle: { width: "7.5%" } },
                        [_vm._v(" No aplica ")]
                      ),
                      _c(
                        "td",
                        {
                          staticClass: "right-title",
                          staticStyle: { width: "10%" },
                        },
                        [_vm._v(" Alias/AKA: ")]
                      ),
                      _c(
                        "td",
                        { staticClass: "user", staticStyle: { width: "25%" } },
                        [_vm._v(" No aplica ")]
                      ),
                    ]),
                    _c("tr", [
                      _c(
                        "td",
                        { staticClass: "title", staticStyle: { width: "15%" } },
                        [_vm._v(" Lugar de Nacimiento: ")]
                      ),
                      _c(
                        "td",
                        {
                          staticClass: "title",
                          staticStyle: { width: "7.5%" },
                        },
                        [_vm._v(" Ciudad: ")]
                      ),
                      _c(
                        "td",
                        { staticClass: "user", staticStyle: { width: "10%" } },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.compareciente.representantes[0]
                                  .lugar_nacim_ciudad
                              ) +
                              " "
                          ),
                        ]
                      ),
                      _c(
                        "td",
                        {
                          staticClass: "right-title",
                          staticStyle: { width: "7.5%" },
                        },
                        [_vm._v(" Estado: ")]
                      ),
                      _c(
                        "td",
                        {
                          staticClass: "user",
                          staticStyle: { width: "10%" },
                          attrs: { colspan: "2" },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.compareciente.representantes[0]
                                  .lugar_nacim_estado
                              ) +
                              " "
                          ),
                        ]
                      ),
                      _c(
                        "td",
                        {
                          staticClass: "right-title",
                          staticStyle: { width: "10%" },
                        },
                        [_vm._v(" País: ")]
                      ),
                      _c(
                        "td",
                        { staticClass: "user", staticStyle: { width: "15%" } },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.compareciente.representantes[0]
                                  .lugar_nacim_pais
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ]),
                    _c("tr", [
                      _c(
                        "td",
                        { staticClass: "title", staticStyle: { width: "15%" } },
                        [_vm._v(" Nacionalidad: ")]
                      ),
                      _c(
                        "td",
                        {
                          staticClass: "user",
                          staticStyle: { width: "80%" },
                          attrs: { colspan: "7" },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.compareciente.representantes[0].nacionalidad
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ]),
                    _c("tr", [
                      _c(
                        "td",
                        { staticClass: "title", staticStyle: { width: "15%" } },
                        [_vm._v(" Actividad / Profesión: ")]
                      ),
                      _c(
                        "td",
                        {
                          staticClass: "user",
                          staticStyle: { width: "30%" },
                          attrs: { colspan: "3" },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.compareciente.representantes[0].ocupacion
                              ) +
                              " "
                          ),
                        ]
                      ),
                      _c(
                        "td",
                        {
                          staticClass: "right-title",
                          staticStyle: { width: "15%" },
                          attrs: { colspan: "2" },
                        },
                        [_vm._v(" Estado Civil: ")]
                      ),
                      _c(
                        "td",
                        {
                          staticClass: "user",
                          staticStyle: { width: "35%" },
                          attrs: { colspan: "2" },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.compareciente.representantes[0].estado_civil
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ]),
                    _c("tr", [
                      _c(
                        "td",
                        { staticClass: "title", staticStyle: { width: "15%" } },
                        [_vm._v(" CURP o equivalente: ")]
                      ),
                      _c(
                        "td",
                        {
                          staticClass: "user",
                          staticStyle: { width: "30%" },
                          attrs: { colspan: "3" },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.compareciente.representantes[0].curp) +
                              " "
                          ),
                        ]
                      ),
                      _c(
                        "td",
                        {
                          staticClass: "right-title",
                          staticStyle: { width: "15%" },
                          attrs: { colspan: "2" },
                        },
                        [_vm._v(" RFC o Tax ID: ")]
                      ),
                      _c(
                        "td",
                        {
                          staticClass: "user",
                          staticStyle: { width: "35%" },
                          attrs: { colspan: "2" },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.compareciente.representantes[0].rfc) +
                              " "
                          ),
                        ]
                      ),
                    ]),
                    _c("tr", [
                      _c(
                        "td",
                        { staticClass: "title", staticStyle: { width: "15%" } },
                        [_vm._v(" Identificación: ")]
                      ),
                      _c(
                        "td",
                        {
                          staticClass: "title",
                          staticStyle: { width: "7.5%" },
                        },
                        [_vm._v(" Tipo: ")]
                      ),
                      _c(
                        "td",
                        {
                          staticClass: "user",
                          staticStyle: { width: "12.5%" },
                          attrs: { colspan: "2" },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.compareciente.representantes[0]
                                  .identificacion_tipo
                              ) +
                              " "
                          ),
                        ]
                      ),
                      _c(
                        "td",
                        {
                          staticClass: "right-title",
                          staticStyle: { width: "15%" },
                          attrs: { colspan: "2" },
                        },
                        [_vm._v(" Autoridad que expide: ")]
                      ),
                      _c(
                        "td",
                        {
                          staticClass: "user",
                          staticStyle: { width: "35%" },
                          attrs: { colspan: "2" },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.compareciente.representantes[0]
                                  .identificacion_autoridad
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ]),
                    _c("tr", [
                      _c("td", {
                        staticClass: "title",
                        staticStyle: { width: "15%" },
                      }),
                      _c(
                        "td",
                        {
                          staticClass: "title",
                          staticStyle: { width: "7.5%" },
                        },
                        [_vm._v(" Número: ")]
                      ),
                      _c(
                        "td",
                        {
                          staticClass: "user",
                          staticStyle: { width: "72.5%" },
                          attrs: { colspan: "6" },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.compareciente.representantes[0]
                                  .identificacion_numero
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ]),
                  ])
                : _c("div", [_vm._m(43)]),
            ]),
          ]),
          _c("div", { attrs: { id: "seccion_ocho" } }, [
            _c("table", { staticClass: "tabla" }, [
              _vm._m(44),
              true
                ? _c("div", [
                    _c("tr", [
                      _c(
                        "td",
                        { staticClass: "title", staticStyle: { width: "15%" } },
                        [_vm._v(" Instrumento Número: ")]
                      ),
                      _c(
                        "td",
                        {
                          staticClass: "user",
                          staticStyle: { width: "20%" },
                          attrs: { colspan: "3" },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.compareciente.instrumento_numero) +
                              " "
                          ),
                        ]
                      ),
                      _c(
                        "td",
                        {
                          staticClass: "right-title",
                          staticStyle: { width: "15%" },
                        },
                        [_vm._v(" De fecha: ")]
                      ),
                      _c(
                        "td",
                        {
                          staticClass: "user",
                          staticStyle: { width: "17.5%" },
                          attrs: { colspan: "2" },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.compareciente.instrumento_numero_fecha.substr(
                                  0,
                                  10
                                )
                              ) +
                              " "
                          ),
                        ]
                      ),
                      _c(
                        "td",
                        {
                          staticClass: "right-title",
                          staticStyle: { width: "10%" },
                        },
                        [_vm._v(" Fedatario: ")]
                      ),
                      _c(
                        "td",
                        {
                          staticClass: "user",
                          staticStyle: { width: "22.5%" },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.compareciente.instrumento_fedatario) +
                              " "
                          ),
                        ]
                      ),
                    ]),
                    _c("tr", [
                      _c(
                        "td",
                        { staticClass: "title", staticStyle: { width: "15%" } },
                        [_vm._v(" Nombre del Fedatario: ")]
                      ),
                      _c(
                        "td",
                        {
                          staticClass: "user",
                          staticStyle: { width: "35%" },
                          attrs: { colspan: "4" },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.compareciente.instrumento_fedatario_nombre
                              ) +
                              " "
                          ),
                        ]
                      ),
                      _c(
                        "td",
                        {
                          staticClass: "right-title",
                          staticStyle: { width: "8.75%" },
                        },
                        [_vm._v(" Número ")]
                      ),
                      _c(
                        "td",
                        {
                          staticClass: "user",
                          staticStyle: { width: "8.75%" },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.compareciente.instrumento_fedatario_numero
                              ) +
                              " "
                          ),
                        ]
                      ),
                      _c(
                        "td",
                        {
                          staticClass: "right-title",
                          staticStyle: { width: "10%" },
                        },
                        [_vm._v(" Lugar: ")]
                      ),
                      _c(
                        "td",
                        {
                          staticClass: "user",
                          staticStyle: { width: "22.5%" },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.compareciente.instrumento_fedatario_estado
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ]),
                    _c("tr", [
                      _c(
                        "td",
                        {
                          staticClass: "title",
                          staticStyle: { width: "30%" },
                          attrs: { colspan: "2" },
                        },
                        [
                          _vm._v(
                            " Datos de Inscripción en el RPC - Folio Número: "
                          ),
                        ]
                      ),
                      _c(
                        "td",
                        {
                          staticClass: "user",
                          staticStyle: { width: "20%" },
                          attrs: { colspan: "3" },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.compareciente.instrumento_rpf_folio) +
                              " "
                          ),
                        ]
                      ),
                      _c(
                        "td",
                        {
                          staticClass: "right-title",
                          staticStyle: { width: "17.5%" },
                          attrs: { colspan: "2" },
                        },
                        [_vm._v(" Fecha y Lugar: ")]
                      ),
                      _c(
                        "td",
                        {
                          staticClass: "user",
                          staticStyle: { width: "32.5%" },
                          attrs: { colspan: "2" },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.compareciente.instrumento_rpf_folio_fecha.substr(
                                  0,
                                  10
                                )
                              ) +
                              " " +
                              _vm._s(
                                _vm.compareciente.instrumento_rpf_folio_lugar
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ]),
                  ])
                : _c("div", [_vm._m(45)]),
            ]),
          ]),
          _c("div", { attrs: { id: "seccion_nueve" } }, [
            _c(
              "table",
              { staticClass: "tabla" },
              [
                _vm._m(46),
                _vm._l(_vm.compareciente.domicilios, function (domicilio) {
                  return _c("div", { key: domicilio.id }, [
                    domicilio.tipo == "Extranjero"
                      ? _c("div", [
                          domicilio.calle === "No aplica"
                            ? _c("div", [_vm._m(47, true)])
                            : _c("div", [
                                _c("tr", [
                                  _c(
                                    "td",
                                    {
                                      staticClass: "title",
                                      staticStyle: { width: "15%" },
                                    },
                                    [_vm._v(" Calle / Avenida / Vía: ")]
                                  ),
                                  _c(
                                    "td",
                                    {
                                      staticClass: "user",
                                      staticStyle: { width: "35%" },
                                      attrs: { colspan: "3" },
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(domicilio.calle) + " "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "td",
                                    {
                                      staticClass: "right-title",
                                      staticStyle: { width: "10%" },
                                    },
                                    [_vm._v(" Num Exterior: ")]
                                  ),
                                  _c(
                                    "td",
                                    {
                                      staticClass: "user",
                                      staticStyle: { width: "20%" },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(domicilio.num_exterior) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "td",
                                    {
                                      staticClass: "right-title",
                                      staticStyle: { width: "10%" },
                                    },
                                    [_vm._v(" Num. Interior: ")]
                                  ),
                                  _c(
                                    "td",
                                    {
                                      staticClass: "user",
                                      staticStyle: { width: "10%" },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(domicilio.num_interior) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]),
                                _c("tr", [
                                  _c(
                                    "td",
                                    {
                                      staticClass: "title",
                                      staticStyle: { width: "15%" },
                                    },
                                    [_vm._v(" Colonia o Urbanización: ")]
                                  ),
                                  _c(
                                    "td",
                                    {
                                      staticClass: "user",
                                      staticStyle: { width: "35%" },
                                      attrs: { colspan: "3" },
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(domicilio.colonia) + " "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "td",
                                    {
                                      staticClass: "right-title",
                                      staticStyle: { width: "10%" },
                                    },
                                    [_vm._v(" Alcaldía / Municipio: ")]
                                  ),
                                  _c(
                                    "td",
                                    {
                                      staticClass: "user",
                                      staticStyle: { width: "40%" },
                                      attrs: { colspan: "3" },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            domicilio.delegacion_municipio
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]),
                                _c("tr", [
                                  _c(
                                    "td",
                                    {
                                      staticClass: "title",
                                      staticStyle: { width: "15%" },
                                    },
                                    [_vm._v(" Ciudad o Población: ")]
                                  ),
                                  _c(
                                    "td",
                                    {
                                      staticClass: "user",
                                      staticStyle: { width: "15%" },
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(domicilio.ciudad) + " "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "td",
                                    {
                                      staticClass: "right-title",
                                      staticStyle: { width: "10%" },
                                    },
                                    [_vm._v(" Código Postal: ")]
                                  ),
                                  _c(
                                    "td",
                                    {
                                      staticClass: "user",
                                      staticStyle: { width: "10%" },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(domicilio.codigo_postal) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "td",
                                    {
                                      staticClass: "right-title",
                                      staticStyle: { width: "10%" },
                                    },
                                    [_vm._v(" Entidad Federativa: ")]
                                  ),
                                  _c(
                                    "td",
                                    {
                                      staticClass: "user",
                                      staticStyle: { width: "40%" },
                                      attrs: { colspan: "3" },
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(domicilio.entidad) + " "
                                      ),
                                    ]
                                  ),
                                ]),
                                _c("tr", [
                                  _c(
                                    "td",
                                    {
                                      staticClass: "title",
                                      staticStyle: {
                                        width: "15%",
                                        "vertical-align": "top",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " Datos del documento constitutivo: "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "td",
                                    {
                                      staticClass: "verifycheck",
                                      staticStyle: {
                                        "text-align": "left",
                                        "vertical-align": "top",
                                        "font-weight": "lighter",
                                        "font-size": "12px",
                                        width: "65%",
                                        height: "80px",
                                      },
                                      attrs: { colspan: "7" },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.compareciente
                                              .datos_prueba_constitucion
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]),
                              ]),
                        ])
                      : _vm._e(),
                  ])
                }),
              ],
              2
            ),
          ]),
          _c("div", { attrs: { id: "seccion_diez" } }, [
            _c(
              "table",
              {
                staticClass: "tabla",
                staticStyle: { "border-collapse": "collapse !important" },
              },
              [
                _vm._m(48),
                false
                  ? _c("div", [
                      _vm._m(49),
                      _c("tr", { staticStyle: { height: "20px" } }, [
                        _c("td", {
                          staticClass: "space",
                          staticStyle: { width: "2%" },
                          attrs: { rowspan: "3" },
                        }),
                        _c(
                          "td",
                          {
                            staticClass: "verifycheck",
                            staticStyle: {
                              width: "3%",
                              "border-style": "dotted",
                            },
                            attrs: { rowspan: "3" },
                          },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.leyenda_beneficiario_1) + " "
                            ),
                          ]
                        ),
                        _c("td", {
                          staticClass: "space",
                          staticStyle: { width: "95%" },
                          attrs: { colspan: "6" },
                        }),
                      ]),
                      _c("tr", [
                        _c("td", {
                          staticClass: "space",
                          staticStyle: { width: "2%" },
                        }),
                        _c(
                          "td",
                          {
                            staticClass: "title",
                            staticStyle: { width: "60%" },
                            attrs: { colspan: "4" },
                          },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.leyenda_beneficiario_2) + " "
                            ),
                          ]
                        ),
                        _c("td", {
                          staticClass: "user",
                          staticStyle: {
                            width: "35%",
                            "background-color": "white !important",
                            "border-bottom": "solid 2px black",
                          },
                          attrs: { colspan: "4" },
                        }),
                      ]),
                      _c("tr", [
                        _c("td", {
                          staticClass: "space",
                          staticStyle: { width: "2%" },
                        }),
                        _c(
                          "td",
                          {
                            staticClass: "title",
                            staticStyle: { width: "60%" },
                            attrs: { colspan: "4" },
                          },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.leyenda_beneficiario_3) + " "
                            ),
                          ]
                        ),
                        _c(
                          "td",
                          {
                            staticStyle: {
                              width: "35%",
                              "vertical-align": "top",
                              "font-size": "10px",
                              "text-align": "center",
                            },
                            attrs: { colspan: "4" },
                          },
                          [_vm._v(" (NOMBRE Y FIRMA) ")]
                        ),
                      ]),
                      _vm._m(50),
                      _vm._m(51),
                    ])
                  : _c("div", [_vm._m(52)]),
              ]
            ),
          ]),
          _c("div", { attrs: { id: "seccion_once" } }, [
            _c(
              "table",
              {
                staticClass: "tabla",
                staticStyle: { "border-collapse": "collapse !important" },
              },
              [
                false
                  ? _c("div", [
                      _vm._m(53),
                      _c("tr", {
                        staticClass: "space",
                        staticStyle: { height: "50px" },
                      }),
                      _vm._m(54),
                      _vm._m(55),
                      _vm._m(56),
                    ])
                  : _vm._e(),
              ]
            ),
          ]),
          _c("div", { attrs: { id: "seccion_once_b" } }, [
            _c(
              "table",
              {
                staticClass: "tabla",
                staticStyle: { "border-collapse": "collapse !important" },
              },
              [
                _vm._m(57),
                false
                  ? _c("div", [
                      _vm._m(58),
                      _c("tr", { staticStyle: { height: "20px" } }, [
                        _c("td", {
                          staticClass: "space",
                          staticStyle: { width: "2%" },
                          attrs: { rowspan: "3" },
                        }),
                        _c(
                          "td",
                          {
                            staticClass: "verifycheck",
                            staticStyle: {
                              width: "3%",
                              "border-style": "dotted",
                            },
                            attrs: { rowspan: "3" },
                          },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.leyenda_beneficiario_4) + " "
                            ),
                          ]
                        ),
                        _c("td", {
                          staticClass: "space",
                          staticStyle: { width: "95%" },
                          attrs: { colspan: "6" },
                        }),
                      ]),
                      _c("tr", [
                        _c("td", {
                          staticClass: "space",
                          staticStyle: { width: "2%" },
                        }),
                        _c(
                          "td",
                          {
                            staticClass: "title",
                            staticStyle: { width: "60%" },
                            attrs: { colspan: "4" },
                          },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.leyenda_beneficiario_5) + " "
                            ),
                          ]
                        ),
                        _c("td", {
                          staticClass: "user",
                          staticStyle: {
                            width: "35%",
                            "background-color": "white !important",
                            "border-bottom": "solid 2px black",
                          },
                          attrs: { colspan: "6" },
                        }),
                      ]),
                      _c("tr", [
                        _c("td", {
                          staticClass: "space",
                          staticStyle: { width: "2%" },
                        }),
                        _c(
                          "td",
                          {
                            staticClass: "title",
                            staticStyle: { width: "60%" },
                            attrs: { colspan: "4" },
                          },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.leyenda_beneficiario_6) + " "
                            ),
                          ]
                        ),
                        _c(
                          "td",
                          {
                            staticStyle: {
                              width: "35%",
                              "vertical-align": "top",
                              "font-size": "10px",
                              "text-align": "center",
                            },
                            attrs: { colspan: "4" },
                          },
                          [_vm._v(" (NOMBRE Y FIRMA) ")]
                        ),
                      ]),
                      _vm._m(59),
                      _vm._m(60),
                      _vm._m(61),
                      _c("tr", {
                        staticClass: "space",
                        staticStyle: { height: "5px" },
                      }),
                      _vm._m(62),
                      _c("tr", {
                        staticClass: "space",
                        staticStyle: { height: "10px" },
                      }),
                      _vm._m(63),
                      _c("tr", {
                        staticClass: "space",
                        staticStyle: { height: "5px" },
                      }),
                      _vm._m(64),
                      _c("tr", {
                        staticClass: "space",
                        staticStyle: { height: "50px" },
                      }),
                      _vm._m(65),
                      _vm._m(66),
                      _vm._m(67),
                    ])
                  : _c("div", [_vm._m(68)]),
              ]
            ),
          ]),
          _c("div", { attrs: { id: "seccion_doce" } }, [
            true
              ? _c(
                  "div",
                  _vm._l(_vm.compareciente.documentos, function (doc) {
                    return _c("div", { key: doc.id }, [
                      _c("table", { staticClass: "tabla new-page" }, [
                        _vm._m(69, true),
                        _c("tr", [
                          _c(
                            "td",
                            {
                              staticStyle: {
                                "text-align": "left",
                                "font-size": "14px",
                                "font-weight": "bold",
                              },
                              attrs: { colspan: "8" },
                            },
                            [_vm._v(" " + _vm._s(doc.tipo) + " ")]
                          ),
                        ]),
                        _c("tr", [
                          _c(
                            "td",
                            {
                              staticStyle: {
                                "text-align": "left",
                                "font-size": "14px",
                                "font-weight": "bold",
                              },
                              attrs: { colspan: "8" },
                            },
                            [
                              _c("img", {
                                staticClass: "docs",
                                attrs: {
                                  width: "80%",
                                  "data-v-0e3bdfbb": "",
                                  src: `${doc.documento}?sv=2023-01-03&st=2024-02-09T17%3A04%3A42Z&se=2034-02-11T17%3A04%3A00Z&sr=c&sp=rl&sig=rq9SDEcZMPv%2FbgiTq9FRE66lHZvEAeeiMqytpyiH2sk%3D`,
                                },
                              }),
                            ]
                          ),
                        ]),
                      ]),
                    ])
                  }),
                  0
                )
              : _vm._e(),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("th", { staticClass: "section", attrs: { colspan: "10" } }, [
        _vm._v(" FORMATO DE IDENTIFICACIÓN DE CLIENTES - PERSONAS FÍSICAS "),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("td", { staticClass: "title" }, [
      _vm._v("Residente Temporal o"),
      _c("br"),
      _vm._v("Permanente"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c(
        "th",
        {
          staticClass: "section",
          staticStyle: { width: "100%" },
          attrs: { colspan: "8" },
        },
        [_vm._v(" DATOS GENERALES DE IDENTIFICACIÓN ")]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "firstline" }, [
      _c("th", { staticClass: "section", attrs: { colspan: "8" } }, [
        _vm._v(" DOMICILIO PARTICULAR DE LA PERSONA FÍSICA "),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "firstline" }, [
      _c("td", { staticClass: "section", attrs: { colspan: "8" } }, [
        _vm._v(" DOMCILIO FISCAL "),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "section-empty" }, [
      _c("td", [_vm._v(" No aplica ")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "firstline" }, [
      _c("td", { staticClass: "section", attrs: { colspan: "4" } }, [
        _vm._v(" DATOS DE CONTACTO Y REDES DE LA PERSONA FÍSICA "),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "firstline" }, [
      _c("td", { staticClass: "section", attrs: { colspan: "8" } }, [
        _vm._v(
          " EN CASO DE PERSONAS FÍSICAS EXTRANJERAS: DOMICILIO DE CONTACTO EN MÉXICO "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "table",
      {
        staticClass: "tabla",
        staticStyle: { "border-collapse": "collapse !important" },
      },
      [
        _c("tr", [
          _c("td", {
            staticClass: "ashurado",
            staticStyle: {
              height: "87px",
              "background-color": "gainsboro !important",
            },
            attrs: { colspan: "12" },
          }),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "section-empty" }, [
      _c("td", [_vm._v(" No aplica ")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "firstline" }, [
      _c("td", { staticClass: "section", attrs: { colspan: "8" } }, [
        _vm._v(
          " EN CASO DE ACTUAR REPRESENTANDO - DATOS GENERALES DEL APODERADO "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "table",
      {
        staticClass: "tabla",
        staticStyle: { "border-collapse": "collapse !important" },
      },
      [
        _c("tr", [
          _c("td", {
            staticClass: "ashurado",
            staticStyle: {
              height: "144px",
              "background-color": "gainsboro !important",
            },
            attrs: { colspan: "12" },
          }),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "section-empty" }, [
      _c("td", [_vm._v(" No aplica ")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "firstline" }, [
      _c("td", { staticClass: "section", attrs: { colspan: "8" } }, [
        _vm._v(
          " MANIFESTACIÓN SOBRE DUEÑO BENEFICIARIO / BENEFICIARIO CONTROLADOR (PLD) "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", { staticClass: "title", attrs: { colspan: "8" } }, [
        _vm._v(
          " Conforme a lo establecido en la fracción III de la Ley Federal para la Prevención e Identificación de Operaciones con Recursos de Procedencia lícita manifiesto, bajo protesta de decir verdad, que: "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", {
        staticClass: "space",
        staticStyle: { height: "15px" },
        attrs: { colspan: "8" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c(
        "td",
        {
          staticClass: "verifycheck",
          staticStyle: {
            "background-color": "white",
            "border-color": "black",
            "text-align": "left",
            "font-size": "12px",
            "font-style": "italic",
          },
          attrs: { colspan: "8" },
        },
        [
          _vm._v(
            " Artículo 3 de la Ley Federal para la Prevención e Identificación de Operaciones con Recursos de Procedencia Ilícita."
          ),
          _c("br"),
          _vm._v(" Para los efectos de esta Ley, se entenderá por:"),
          _c("br"),
          _vm._v(
            " …III. Beneficiario Controlador, a la persona o grupo de personas que:"
          ),
          _c("br"),
          _vm._v(
            " a) Por medio de otra o de cualquier acto, obtiene el beneficio derivado de éstos y es quien, en última instancia, ejerce los derechos de uso, goce, disfrute, aprovechamiento o "
          ),
          _c("br"),
          _vm._v(" disposición de un bien o servicio, o"),
          _c("br"),
          _vm._v(
            " b) Ejerce el control de aquella persona moral que, en su carácter de cliente o usuario, lleve a cabo actos u operaciones con quien realice Actividades Vulnerables, así como las "
          ),
          _c("br"),
          _vm._v(" personas por cuenta de quienes celebra alguno de ellos."),
          _c("br"),
          _vm._v(
            " Se entiende que una persona o grupo de personas controla a una persona moral cuando, a través de la titularidad de valores, por contrato o de cualquier otro acto, puede:"
          ),
          _c("br"),
          _vm._v(
            " i) Imponer, directa o indirectamente, decisiones en las asambleas generales de accionistas, socios unos órganos equivalentes, o nombrar o destituir a la mayoría de los consejeros, "
          ),
          _c("br"),
          _vm._v(" administradores o sus equivalentes;"),
          _c("br"),
          _vm._v(
            " ii) Mantener la titularidad de los derechos que permitan, directa o indirectamente, ejercer el voto respecto de más del cincuenta por ciento del capital social, o"
          ),
          _c("br"),
          _vm._v(
            " iii) Dirigir, directa o indirectamente, la administración, la estrategia o las principales políticas de la misma. "
          ),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", {
        staticClass: "ashurado",
        staticStyle: {
          height: "350px",
          "background-color": "gainsboro !important",
        },
        attrs: { colspan: "12" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "section-empty" }, [
      _c("td", [_vm._v(" No aplica ")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "firstline" }, [
      _c("td", { staticClass: "section", attrs: { colspan: "8" } }, [
        _vm._v(
          " MANIFESTACIÓN RELACIONADA A LAS DISPOSICIONES DEL CFF Y LAS RMF EN MATERIA DE BENEFICIARIO CONTROLADOR "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", { staticClass: "title", attrs: { colspan: "8" } }, [
        _vm._v(
          " Declaro, bajo protesta de decir verdad, lo siguiente: (i) que me fue solicitada la información suficiente y necesaria para identificar a los Beneficiarios Controladores a que se refiere el Código Fiscal de la Federación: (ii) que toda la información y documentación que fue entregada es verdadera y la misma corresponde a la realidad; y (iii) que no cuento con información y/o documentación adicional que pudiere afectar la solicitud e investigación realizada por el prestador del servicio de fe pública ligado a este formulario y a las operaciones que derivan de la misma. "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", {
        staticClass: "space",
        staticStyle: { width: "2%" },
        attrs: { colspan: "3" },
      }),
      _c(
        "td",
        {
          staticClass: "title",
          staticStyle: { width: "95%", "font-size": "8px" },
          attrs: { colspan: "5" },
        },
        [
          _vm._v(
            " *En caso de si tener conocimiento de la existencia de Beneficiario Controlador adicional al que suscribe, se adjunta copia de toda la información actualizada y me compromento a que, en caso de que existan cambios posteriores a la misma, hacerle llegar las constancias correspondientes "
          ),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", {
        staticClass: "space",
        staticStyle: { height: "15px" },
        attrs: { colspan: "8" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c(
        "td",
        {
          staticClass: "verifycheck",
          staticStyle: {
            "background-color": "white",
            "border-color": "black",
            "text-align": "left",
            "font-size": "12px",
            "font-style": "italic",
          },
          attrs: { colspan: "8" },
        },
        [
          _vm._v(
            " Artículo 32-B Quáter CFF. Para efectos de este Código se entenderá por beneficiario controlador a la persona física o grupo de personas físicas que: "
          ),
          _c("br"),
          _vm._v(
            " I. Directamente o por medio de otra u otras o de cualquier acto jurídico, obtiene u obtienen el beneficio derivado de su participación en una persona moral, un fideicomiso o cualquier"
          ),
          _c("br"),
          _vm._v(
            " otra figura jurídica, así como de cualquier otro acto jurídico, o es quien o quienes en última instancia ejerce o ejercen los derechos de uso, goce, disfrute, aprovechamiento o disposición"
          ),
          _c("br"),
          _vm._v(
            " de un bien o servicio o en cuyo nombre se realiza una transacción, aun y cuando lo haga o hagan de forma contingente. "
          ),
          _c("br"),
          _vm._v(
            " II. Directa, indirectamente o de forma contingente, ejerzan el control de la persona moral, fideicomiso o cualquier otra figura jurídica."
          ),
          _c("br"),
          _vm._v(
            " Se entiende que una persona física o grupo de personas físicas ejerce el control cuando, a través de la titularidad de valores, por contrato o por cualquier otro acto jurídico, puede o"
          ),
          _c("br"),
          _vm._v(" pueden:"),
          _c("br"),
          _vm._v(
            " a) Imponer, directa o indirectamente, decisiones en las asambleas generales de accionistas, socios u órganos equivalentes, o nombrar o destituir a la mayoría de los consejeros,"
          ),
          _c("br"),
          _vm._v(" administradores o sus equivalentes."),
          _c("br"),
          _vm._v(
            " b) Mantener la titularidad de los derechos que permitan, directa o indirectamente, ejercer el voto respecto de más del 15% del capital social o bien."
          ),
          _c("br"),
          _vm._v(
            " c) Dirigir, directa o indirectamente, la administración, la estrategia o las principales políticas de la persona moral, fideicomiso o cualquier otra figura jurídica. "
          ),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "firstline" }, [
      _c(
        "td",
        {
          staticClass: "section",
          staticStyle: { "background-color": "white", color: "black" },
          attrs: { colspan: "8" },
        },
        [
          _vm._v(
            " CRITERIOS PARA LA DETERMINACIÓN DE LA CONDICIÓN DE BENEFICIARIO CONTROLADOR DE LAS PERSONAS MORALES "
          ),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c(
        "td",
        {
          staticClass: "verifycheck",
          staticStyle: {
            "background-color": "white",
            "border-color": "black",
            "text-align": "left",
            "font-size": "12px",
            "font-style": "italic",
          },
          attrs: { colspan: "8" },
        },
        [
          _vm._v(
            " Entiendo que, para los efectos del artículo 32-B Ter del CFF, en la identificación de los beneficiarios controladores, se deberá aplicar lo dispuesto en el artículo 32-B Quáter, fracciones I"
          ),
          _c("br"),
          _vm._v(
            " y II, incisos a), b) y c) de forma sucesiva, como criterios para su determinación."
          ),
          _c("br"),
          _c("br"),
          _vm._v(
            " Para efectos de la obtención de la información a que se refiere el artículo 32-B Ter del CFF, las personas morales deberán identificar, verificar y validar la información sobre los"
          ),
          _c("br"),
          _vm._v(
            " beneficiarios controladores, indicando los porcentajes de participación en el capital de la persona moral, incluyendo la información relativa a la cadena de titularidad, en los casos en que"
          ),
          _c("br"),
          _vm._v(
            " el beneficiario controlador lo sea indirectamente. Se entiende por cadena de titularidad el supuesto en que se ostente la propiedad indirecta, a través de otras personas morales."
          ),
          _c("br"),
          _vm._v(
            " Asimismo, las personas morales también deberán identificar, verificar y validar la información relativa a la cadena de control, en los casos en los que el beneficiario controlador lo sea"
          ),
          _c("br"),
          _vm._v(
            " por medios distintos a la propiedad. Se entiende por cadena de control el supuesto en que se ostente el control indirectamente, a través de otras personas morales, fideicomisos o"
          ),
          _c("br"),
          _vm._v(" cualquier otra figura jurídica. "),
          _c("br"),
          _c("br"),
          _vm._v(
            " Cuando no se identifique a persona física alguna bajo los criterios establecidos en esta regla, en relación con el artículo 32-B Quáter del CFF, se considerará como beneficiario"
          ),
          _c("br"),
          _vm._v(
            " controlador a la persona física que ocupe el cargo de administrador único de la persona moral o equivalente. En caso de que la persona moral cuente con un consejo de administración"
          ),
          _c("br"),
          _vm._v(
            " u órgano equivalente, cada miembro de dicho consejo se considerará como beneficiario controlador de la persona moral. "
          ),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "firstline" }, [
      _c(
        "td",
        {
          staticClass: "section",
          staticStyle: { "background-color": "white", color: "black" },
          attrs: { colspan: "8" },
        },
        [
          _vm._v(
            " DECLARO, BAJO PROTESTA DE DECIR VERDAD, QUE LOS DATOS ASENTADOS SON VERDADEROS "
          ),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", {
        staticClass: "space",
        staticStyle: { width: "20%" },
        attrs: { colspan: "3" },
      }),
      _c("td", {
        staticClass: "user",
        staticStyle: {
          width: "60%",
          "background-color": "white !important",
          "border-bottom": "solid 2px black",
        },
        attrs: { colspan: "3" },
      }),
      _c("td", {
        staticClass: "space",
        staticStyle: { width: "20%" },
        attrs: { colspan: "2" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", {
        staticClass: "space",
        staticStyle: { width: "20%" },
        attrs: { colspan: "3" },
      }),
      _c(
        "td",
        {
          staticStyle: {
            width: "60%",
            "vertical-align": "top",
            "font-size": "14px",
            "text-align": "center",
            "background-color": "white",
            "font-weight": "bold",
          },
          attrs: { colspan: "3" },
        },
        [_vm._v(" NOMBRE Y FIRMA ")]
      ),
      _c("td", {
        staticClass: "space",
        staticStyle: { width: "20%" },
        attrs: { colspan: "2" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", {
        staticClass: "ashurado",
        staticStyle: {
          height: "350px",
          "background-color": "gainsboro !important",
        },
        attrs: { colspan: "12" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "section-empty" }, [
      _c("td", [_vm._v(" No aplica ")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c(
        "td",
        {
          staticStyle: {
            "text-align": "left",
            "font-size": "14px",
            "font-weight": "bold",
          },
          attrs: { colspan: "8" },
        },
        [_vm._v(" ANEXO DOCUMENTAL ")]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("th", { staticClass: "section", attrs: { colspan: "10" } }, [
        _vm._v(" FORMATO DE IDENTIFICACIÓN DE CLIENTES - PERSONAS MORALES "),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c(
        "th",
        {
          staticClass: "section",
          staticStyle: { width: "100%" },
          attrs: { colspan: "8" },
        },
        [_vm._v(" DATOS GENERALES DE IDENTIFICACIÓN ")]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "firstline" }, [
      _c(
        "td",
        {
          staticClass: "section",
          staticStyle: { width: "10%" },
          attrs: { colspan: "8" },
        },
        [_vm._v(" DATOS DE CONSTITUCIÓN DE PERSONA MORAL MEXICANA ")]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "section-empty" }, [
      _c("td", [_vm._v(" No aplica ")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "firstline" }, [
      _c("th", { staticClass: "section", attrs: { colspan: "8" } }, [
        _vm._v(" DOMICILIO CORPORATIVO O PRINCIPAL ASIENTO DE NEGOCIOS "),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "section-empty" }, [
      _c("td", [_vm._v(" No aplica ")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "firstline" }, [
      _c("th", { staticClass: "section", attrs: { colspan: "8" } }, [
        _vm._v(" DOMICILIO FISCAL "),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "section-empty" }, [
      _c("td", [_vm._v(" No aplica ")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "firstline" }, [
      _c("td", { staticClass: "section", attrs: { colspan: "4" } }, [
        _vm._v(" DATOS DE CONTACTO DE LA PERSONA MORAL "),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "section-empty" }, [
      _c("td", [_vm._v(" No aplica ")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c(
        "th",
        {
          staticClass: "section",
          staticStyle: { width: "100%" },
          attrs: { colspan: "8" },
        },
        [_vm._v(" DATOS GENERALES DEL APODERADO O REPRESENTANTE ")]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "section-empty" }, [
      _c("td", [_vm._v(" No aplica ")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "firstline" }, [
      _c("td", { staticClass: "section", attrs: { colspan: "9" } }, [
        _vm._v(" DATOS DEL PODER "),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "section-empty" }, [
      _c("td", [_vm._v(" No aplica ")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "firstline" }, [
      _c("td", { staticClass: "section", attrs: { colspan: "8" } }, [
        _vm._v(
          " EN CASO DE PERSONAS MORALES EXTRANJERAS: DOMICILIO DE CONTACTO EN MÉXICO "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "table",
      {
        staticClass: "tabla",
        staticStyle: { "border-collapse": "collapse !important" },
      },
      [
        _c("tr", [
          _c("td", {
            staticClass: "ashurado",
            staticStyle: {
              height: "500px",
              "background-color": "gainsboro !important",
            },
            attrs: { colspan: "12" },
          }),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "firstline" }, [
      _c("td", { staticClass: "section", attrs: { colspan: "8" } }, [
        _vm._v(
          " MANIFESTACIÓN SOBRE DUEÑO BENEFICIARIO / BENEFICIARIO CONTROLADOR (PLD) "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", { staticClass: "title", attrs: { colspan: "8" } }, [
        _vm._v(
          " Conforme a lo establecido en la fracción III de la Ley Federal para la Prevención e Identificación de Operaciones con Recursos de Procedencia lícita manifiesto, bajo protesta de decir verdad, que: "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", {
        staticClass: "space",
        staticStyle: { height: "15px" },
        attrs: { colspan: "8" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c(
        "td",
        {
          staticClass: "verifycheck",
          staticStyle: {
            "background-color": "white",
            "border-color": "black",
            "text-align": "left",
            "font-size": "12px",
            "font-style": "italic",
          },
          attrs: { colspan: "8" },
        },
        [
          _vm._v(
            " Artículo 3 de la Ley Federal para la Prevención e Identificación de Operaciones con Recursos de Procedencia Ilícita."
          ),
          _c("br"),
          _vm._v(" Para los efectos de esta Ley, se entenderá por:"),
          _c("br"),
          _vm._v(
            " …III. Beneficiario Controlador, a la persona o grupo de personas que:"
          ),
          _c("br"),
          _vm._v(
            " a) Por medio de otra o de cualquier acto, obtiene el beneficio derivado de éstos y es quien, en última instancia, ejerce los derechos de uso, goce, disfrute, aprovechamiento o "
          ),
          _c("br"),
          _vm._v(" disposición de un bien o servicio, o"),
          _c("br"),
          _vm._v(
            " b) Ejerce el control de aquella persona moral que, en su carácter de cliente o usuario, lleve a cabo actos u operaciones con quien realice Actividades Vulnerables, así como las "
          ),
          _c("br"),
          _vm._v(" personas por cuenta de quienes celebra alguno de ellos."),
          _c("br"),
          _vm._v(
            " Se entiende que una persona o grupo de personas controla a una persona moral cuando, a través de la titularidad de valores, por contrato o de cualquier otro acto, puede:"
          ),
          _c("br"),
          _vm._v(
            " i) Imponer, directa o indirectamente, decisiones en las asambleas generales de accionistas, socios unos órganos equivalentes, o nombrar o destituir a la mayoría de los consejeros, "
          ),
          _c("br"),
          _vm._v(" administradores o sus equivalentes;"),
          _c("br"),
          _vm._v(
            " ii) Mantener la titularidad de los derechos que permitan, directa o indirectamente, ejercer el voto respecto de más del cincuenta por ciento del capital social, o"
          ),
          _c("br"),
          _vm._v(
            " iii) Dirigir, directa o indirectamente, la administración, la estrategia o las principales políticas de la misma. "
          ),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "section-empty" }, [
      _c("td", [_vm._v(" No aplica ")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "firstline" }, [
      _c(
        "td",
        {
          staticClass: "section",
          staticStyle: { "background-color": "white", color: "black" },
          attrs: { colspan: "8" },
        },
        [
          _vm._v(
            " DECLARO, BAJO PROTESTA DE DECIR VERDAD, QUE LOS DATOS ASENTADOS SON VERDADEROS "
          ),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", {
        staticClass: "space",
        staticStyle: { width: "20%" },
        attrs: { colspan: "3" },
      }),
      _c("td", {
        staticClass: "user",
        staticStyle: {
          width: "60%",
          "background-color": "white !important",
          "border-bottom": "solid 2px black",
        },
        attrs: { colspan: "3" },
      }),
      _c("td", {
        staticClass: "space",
        staticStyle: { width: "20%" },
        attrs: { colspan: "2" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", {
        staticClass: "space",
        staticStyle: { width: "20%" },
        attrs: { colspan: "3" },
      }),
      _c(
        "td",
        {
          staticStyle: {
            width: "60%",
            "vertical-align": "top",
            "font-size": "14px",
            "text-align": "center",
            "background-color": "white",
            "font-weight": "bold",
          },
          attrs: { colspan: "3" },
        },
        [_vm._v(" NOMBRE Y FIRMA ")]
      ),
      _c("td", {
        staticClass: "space",
        staticStyle: { width: "20%" },
        attrs: { colspan: "2" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", {
        staticClass: "ashurado",
        staticStyle: {
          height: "750px",
          "background-color": "gainsboro !important",
        },
        attrs: { colspan: "12" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "firstline" }, [
      _c("td", { staticClass: "section", attrs: { colspan: "8" } }, [
        _vm._v(
          " MANIFESTACIÓN RELACIONADA A LAS DISPOSICIONES DEL CFF Y LAS RMF EN MATERIA DE BENEFICIARIO CONTROLADOR "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", { staticClass: "title", attrs: { colspan: "8" } }, [
        _vm._v(
          " Declaro, bajo protesta de decir verdad, lo siguiente: (i) que me fue solicitada la información suficiente y necesaria para identificar a los Beneficiarios Controladores a que se refiere el Código Fiscal de la Federación: (ii) que toda la información y documentación que fue entregada es verdadera y la misma corresponde a la realidad; y (iii) que no cuento con información y/o documentación adicional que pudiere afectar la solicitud e investigación realizada por el prestador del servicio de fe pública ligado a este formulario y a las operaciones que derivan de la misma. "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", {
        staticClass: "space",
        staticStyle: { width: "2%" },
        attrs: { colspan: "3" },
      }),
      _c(
        "td",
        {
          staticClass: "title",
          staticStyle: { width: "95%", "font-size": "8px" },
          attrs: { colspan: "5" },
        },
        [
          _vm._v(
            " *En caso de si tener conocimiento de la existencia de Beneficiario Controlador adicional al que suscribe, se adjunta copia de toda la información actualizada y me compromento a que, en caso de que existan cambios posteriores a la misma, hacerle llegar las constancias correspondientes "
          ),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", {
        staticClass: "space",
        staticStyle: { height: "15px" },
        attrs: { colspan: "8" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c(
        "td",
        {
          staticClass: "verifycheck",
          staticStyle: {
            "background-color": "white",
            "border-color": "black",
            "text-align": "left",
            "font-size": "12px",
            "font-style": "italic",
          },
          attrs: { colspan: "8" },
        },
        [
          _vm._v(
            " Artículo 32-B Quáter CFF. Para efectos de este Código se entenderá por beneficiario controlador a la persona física o grupo de personas físicas que: "
          ),
          _c("br"),
          _vm._v(
            " I. Directamente o por medio de otra u otras o de cualquier acto jurídico, obtiene u obtienen el beneficio derivado de su participación en una persona moral, un fideicomiso o cualquier"
          ),
          _c("br"),
          _vm._v(
            " otra figura jurídica, así como de cualquier otro acto jurídico, o es quien o quienes en última instancia ejerce o ejercen los derechos de uso, goce, disfrute, aprovechamiento o disposición"
          ),
          _c("br"),
          _vm._v(
            " de un bien o servicio o en cuyo nombre se realiza una transacción, aun y cuando lo haga o hagan de forma contingente. "
          ),
          _c("br"),
          _vm._v(
            " II. Directa, indirectamente o de forma contingente, ejerzan el control de la persona moral, fideicomiso o cualquier otra figura jurídica."
          ),
          _c("br"),
          _vm._v(
            " Se entiende que una persona física o grupo de personas físicas ejerce el control cuando, a través de la titularidad de valores, por contrato o por cualquier otro acto jurídico, puede o"
          ),
          _c("br"),
          _vm._v(" pueden:"),
          _c("br"),
          _vm._v(
            " a) Imponer, directa o indirectamente, decisiones en las asambleas generales de accionistas, socios u órganos equivalentes, o nombrar o destituir a la mayoría de los consejeros,"
          ),
          _c("br"),
          _vm._v(" administradores o sus equivalentes."),
          _c("br"),
          _vm._v(
            " b) Mantener la titularidad de los derechos que permitan, directa o indirectamente, ejercer el voto respecto de más del 15% del capital social o bien."
          ),
          _c("br"),
          _vm._v(
            " c) Dirigir, directa o indirectamente, la administración, la estrategia o las principales políticas de la persona moral, fideicomiso o cualquier otra figura jurídica. "
          ),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "firstline" }, [
      _c(
        "td",
        {
          staticClass: "section",
          staticStyle: { "background-color": "white", color: "black" },
          attrs: { colspan: "8" },
        },
        [
          _vm._v(
            " CRITERIOS PARA LA DETERMINACIÓN DE LA CONDICIÓN DE BENEFICIARIO CONTROLADOR DE LAS PERSONAS MORALES "
          ),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c(
        "td",
        {
          staticClass: "verifycheck",
          staticStyle: {
            "background-color": "white",
            "border-color": "black",
            "text-align": "left",
            "font-size": "12px",
            "font-style": "italic",
          },
          attrs: { colspan: "8" },
        },
        [
          _vm._v(
            " Entiendo que, para los efectos del artículo 32-B Ter del CFF, en la identificación de los beneficiarios controladores, se deberá aplicar lo dispuesto en el artículo 32-B Quáter, fracciones I"
          ),
          _c("br"),
          _vm._v(
            " y II, incisos a), b) y c) de forma sucesiva, como criterios para su determinación."
          ),
          _c("br"),
          _c("br"),
          _vm._v(
            " Para efectos de la obtención de la información a que se refiere el artículo 32-B Ter del CFF, las personas morales deberán identificar, verificar y validar la información sobre los"
          ),
          _c("br"),
          _vm._v(
            " beneficiarios controladores, indicando los porcentajes de participación en el capital de la persona moral, incluyendo la información relativa a la cadena de titularidad, en los casos en que"
          ),
          _c("br"),
          _vm._v(
            " el beneficiario controlador lo sea indirectamente. Se entiende por cadena de titularidad el supuesto en que se ostente la propiedad indirecta, a través de otras personas morales."
          ),
          _c("br"),
          _vm._v(
            " Asimismo, las personas morales también deberán identificar, verificar y validar la información relativa a la cadena de control, en los casos en los que el beneficiario controlador lo sea"
          ),
          _c("br"),
          _vm._v(
            " por medios distintos a la propiedad. Se entiende por cadena de control el supuesto en que se ostente el control indirectamente, a través de otras personas morales, fideicomisos o"
          ),
          _c("br"),
          _vm._v(" cualquier otra figura jurídica. "),
          _c("br"),
          _c("br"),
          _vm._v(
            " Cuando no se identifique a persona física alguna bajo los criterios establecidos en esta regla, en relación con el artículo 32-B Quáter del CFF, se considerará como beneficiario"
          ),
          _c("br"),
          _vm._v(
            " controlador a la persona física que ocupe el cargo de administrador único de la persona moral o equivalente. En caso de que la persona moral cuente con un consejo de administración"
          ),
          _c("br"),
          _vm._v(
            " u órgano equivalente, cada miembro de dicho consejo se considerará como beneficiario controlador de la persona moral. "
          ),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "firstline" }, [
      _c(
        "td",
        {
          staticClass: "section",
          staticStyle: { "background-color": "white", color: "black" },
          attrs: { colspan: "8" },
        },
        [
          _vm._v(
            " DECLARO, BAJO PROTESTA DE DECIR VERDAD, QUE LOS DATOS ASENTADOS SON VERDADEROS "
          ),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", {
        staticClass: "space",
        staticStyle: { width: "20%" },
        attrs: { colspan: "3" },
      }),
      _c("td", {
        staticClass: "user",
        staticStyle: {
          width: "60%",
          "background-color": "white !important",
          "border-bottom": "solid 2px black",
        },
        attrs: { colspan: "3" },
      }),
      _c("td", {
        staticClass: "space",
        staticStyle: { width: "20%" },
        attrs: { colspan: "2" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", {
        staticClass: "space",
        staticStyle: { width: "20%" },
        attrs: { colspan: "3" },
      }),
      _c(
        "td",
        {
          staticStyle: {
            width: "60%",
            "vertical-align": "top",
            "font-size": "14px",
            "text-align": "center",
            "background-color": "white",
            "font-weight": "bold",
          },
          attrs: { colspan: "3" },
        },
        [_vm._v(" NOMBRE Y FIRMA ")]
      ),
      _c("td", {
        staticClass: "space",
        staticStyle: { width: "20%" },
        attrs: { colspan: "2" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", {
        staticClass: "ashurado",
        staticStyle: {
          height: "350px",
          "background-color": "gainsboro !important",
        },
        attrs: { colspan: "12" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "section-empty" }, [
      _c("td", [_vm._v(" No aplica ")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c(
        "td",
        {
          staticStyle: {
            "text-align": "left",
            "font-size": "14px",
            "font-weight": "bold",
          },
          attrs: { colspan: "8" },
        },
        [_vm._v(" ANEXO DOCUMENTAL ")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }