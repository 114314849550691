var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { attrs: { id: "doc" } }, [
      _c(
        "video",
        {
          attrs: {
            id: "my-player",
            controls: "",
            preload: "none",
            autoplay: _vm.videoData.autoplay,
            poster: _vm.videoData.poster,
          },
        },
        [
          _c("source", {
            attrs: {
              src: _vm.videoData.documento,
              type: _vm.videoData.fileType,
            },
          }),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }