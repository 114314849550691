var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container" },
    [
      _vm.loading
        ? _c("div", [
            _c(
              "div",
              { attrs: { id: "loader" } },
              [
                _c("font-awesome-icon", {
                  attrs: { icon: "spinner", spin: "" },
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
      _c("h4", { staticClass: "cardD" }, [
        _vm._v("Complementa la información"),
      ]),
      _c("h4", { staticClass: "cardD" }, [_vm._v("Domicilio de la sociedad")]),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-md-4" }, [
          _c("label", [_vm._v("País")]),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.selectCountry,
                  expression: "selectCountry",
                },
              ],
              staticClass: "form-control",
              attrs: { disabled: _vm.constitutiva.disabledComplementa },
              on: {
                change: [
                  function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.selectCountry = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                  _vm.fetchStatesByCountry,
                ],
              },
            },
            _vm._l(_vm.countries, function (option) {
              return _c("option", { key: option.id }, [
                _vm._v(" " + _vm._s(option.name) + " "),
              ])
            }),
            0
          ),
        ]),
        _c(
          "div",
          { staticClass: "col-md-4" },
          [
            _c("ValidationProvider", {
              attrs: {
                name: "entidad",
                rules: "required",
                "custom-messages": { required: "La entidad es obligatoria" },
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ errors }) {
                    return [
                      _c("label", [_vm._v("Entidad Federativa")]),
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.selectState,
                              expression: "selectState",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            disabled: _vm.constitutiva.disabledComplementa,
                          },
                          on: {
                            change: [
                              function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.selectState = $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              },
                              _vm.fetchCitiesByState,
                            ],
                          },
                        },
                        [
                          _vm.isEmptyStates
                            ? _c(
                                "option",
                                { attrs: { value: "noStates", selected: "" } },
                                [_vm._v(" " + _vm._s(_vm.noStatesText) + " ")]
                              )
                            : _vm._e(),
                          _vm._l(_vm.states, function (option) {
                            return _c("option", { key: option.id }, [
                              _vm._v(" " + _vm._s(option.name) + " "),
                            ])
                          }),
                        ],
                        2
                      ),
                      _c("span", { staticClass: "cardErrors" }, [
                        _vm._v(_vm._s(errors[0])),
                      ]),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
        _vm.constitutiva.entidad != "Ciudad de México"
          ? _c("div", { staticClass: "col-md-4" }, [
              _c("label", [_vm._v("Demarcación Territorial o Municipio")]),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.selectCity,
                      expression: "selectCity",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: { disabled: _vm.constitutiva.disabledComplementa },
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.selectCity = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    },
                  },
                },
                [
                  _vm.isEmptyCities
                    ? _c(
                        "option",
                        { attrs: { value: "noCities", selected: "" } },
                        [_vm._v(" " + _vm._s(_vm.noCitiesText) + " ")]
                      )
                    : _vm._e(),
                  _vm._l(_vm.cities, function (option) {
                    return _c("option", { key: option.id }, [
                      _vm._v(" " + _vm._s(option.name) + " "),
                    ])
                  }),
                ],
                2
              ),
            ])
          : _vm._e(),
      ]),
      _c("h4", { staticClass: "cardD" }, [_vm._v("Accionistas")]),
      _c(
        "div",
        { staticClass: "mb-4" },
        [
          _c("label", { attrs: { for: "" } }, [
            _vm._v("Acciones con valor nominal"),
          ]),
          _c("b-form-checkbox", {
            attrs: { disabled: _vm.constitutiva.disabledComplementa },
            model: {
              value: _vm.constitutiva.acciones_valor_nominal,
              callback: function ($$v) {
                _vm.$set(_vm.constitutiva, "acciones_valor_nominal", $$v)
              },
              expression: "constitutiva.acciones_valor_nominal",
            },
          }),
        ],
        1
      ),
      _c("label", { staticClass: "leyenda" }, [
        _vm._v(
          " Edita cada uno de los accionistas para llenar todos los datos necesarios. "
        ),
      ]),
      _c("label", { staticClass: "leyenda" }, [
        _vm._v(" Serás enviado al apartado de comparecientes. "),
      ]),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-12" },
          [
            _c("label", { attrs: { for: "" } }, [_vm._v("Cuadro Accionario")]),
            _c("b-table", {
              attrs: {
                hover: "",
                fields: _vm.fieldsCuadro,
                items: _vm.constitutiva.accionistas,
              },
              scopedSlots: _vm._u([
                {
                  key: "cell(acciones)",
                  fn: function ({ item }) {
                    return [
                      _c(
                        "b-button",
                        {
                          directives: [
                            {
                              name: "b-tooltip",
                              rawName: "v-b-tooltip.hover",
                              modifiers: { hover: true },
                            },
                          ],
                          staticClass: "editBtn",
                          attrs: {
                            variant: "warning",
                            size: "sm",
                            title: "Editar",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.editCompareciente(item)
                            },
                          },
                        },
                        [_c("b-icon-pencil")],
                        1
                      ),
                    ]
                  },
                },
                {
                  key: "cell(listo)",
                  fn: function ({ item }) {
                    return [
                      item.persona == "Persona Moral"
                        ? _c("div", [
                            item.rfc
                              ? _c("div", [
                                  item.rfc.length === 12
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "circle completado_green",
                                        },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "number" },
                                            [
                                              _c("i", {
                                                staticClass: "fa fa-check",
                                              }),
                                            ]
                                          ),
                                        ]
                                      )
                                    : _c(
                                        "div",
                                        {
                                          staticClass:
                                            "circle completado_yellow",
                                        },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "number" },
                                            [
                                              _c("i", {
                                                staticClass: "fa fa-minus",
                                              }),
                                            ]
                                          ),
                                        ]
                                      ),
                                ])
                              : _c("div", [
                                  _c(
                                    "div",
                                    { staticClass: "circle completado_yellow" },
                                    [
                                      _c("span", { staticClass: "number" }, [
                                        _c("i", { staticClass: "fa fa-minus" }),
                                      ]),
                                    ]
                                  ),
                                ]),
                          ])
                        : item.persona == "Persona Física"
                        ? _c("div", [
                            item.rfc
                              ? _c("div", [
                                  item.rfc.length === 13
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "circle completado_green",
                                        },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "number" },
                                            [
                                              _c("i", {
                                                staticClass: "fa fa-check",
                                              }),
                                            ]
                                          ),
                                        ]
                                      )
                                    : _c(
                                        "div",
                                        {
                                          staticClass:
                                            "circle completado_yellow",
                                        },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "number" },
                                            [
                                              _c("i", {
                                                staticClass: "fa fa-minus",
                                              }),
                                            ]
                                          ),
                                        ]
                                      ),
                                ])
                              : _c("div", [
                                  _c(
                                    "div",
                                    { staticClass: "circle completado_yellow" },
                                    [
                                      _c("span", { staticClass: "number" }, [
                                        _c("i", { staticClass: "fa fa-minus" }),
                                      ]),
                                    ]
                                  ),
                                ]),
                          ])
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            }),
            _c("label", { attrs: { for: "" } }, [
              _vm._v("¿Tendrá cláusula de admisión de extranjeros?"),
            ]),
            _vm.constitutiva.capital_extranjero
              ? _c(
                  "div",
                  [
                    _c("b-form-checkbox", {
                      attrs: { disabled: "" },
                      model: {
                        value: _vm.constitutiva.clausula_extranjeros,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.constitutiva,
                            "clausula_extranjeros",
                            $$v
                          )
                        },
                        expression: "constitutiva.clausula_extranjeros",
                      },
                    }),
                  ],
                  1
                )
              : _c(
                  "div",
                  [
                    _c("b-form-checkbox", {
                      attrs: { disabled: _vm.constitutiva.disabledComplementa },
                      model: {
                        value: _vm.constitutiva.clausula_extranjeros,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.constitutiva,
                            "clausula_extranjeros",
                            $$v
                          )
                        },
                        expression: "constitutiva.clausula_extranjeros",
                      },
                    }),
                  ],
                  1
                ),
          ],
          1
        ),
      ]),
      _c("h4", { staticClass: "cardD mt-3" }, [_vm._v("Administradores")]),
      _c("label", { staticClass: "leyenda" }, [
        _vm._v(" Agrega el RFC a cada uno de los admnistradores. "),
      ]),
      _c(
        "b-modal",
        {
          ref: "administrador-modal",
          attrs: {
            "no-close-on-backdrop": "",
            centered: "",
            title: "",
            "hide-footer": "",
            id: "modalC",
            size: "xl",
          },
        },
        [
          _c("ValidationObserver", {
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ handleSubmit }) {
                  return [
                    _c(
                      "form",
                      {
                        on: {
                          submit: function ($event) {
                            $event.preventDefault()
                            return handleSubmit(_vm.putAdministrador)
                          },
                        },
                      },
                      [
                        _c("ModalOrganosAdministradores", {
                          attrs: {
                            currentAdministrador: _vm.currentAdministrador,
                            complete: true,
                          },
                          on: {
                            submit: function ($event) {
                              $event.preventDefault()
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          ref: "vigilancia-modal",
          attrs: {
            "no-close-on-backdrop": "",
            centered: "",
            title: "",
            "hide-footer": "",
            id: "modalV",
            size: "xl",
          },
        },
        [
          _c("ValidationObserver", {
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ handleSubmit }) {
                  return [
                    _c(
                      "form",
                      {
                        on: {
                          submit: function ($event) {
                            $event.preventDefault()
                            return handleSubmit(_vm.putVigilancia)
                          },
                        },
                      },
                      [
                        _c("ModalOrganosVigilancia", {
                          attrs: {
                            currentVigilancia: _vm.currentVigilancia,
                            complete: true,
                          },
                          on: {
                            submit: function ($event) {
                              $event.preventDefault()
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-12" },
          [
            _vm.constitutiva.tipo_societario === "SA" ||
            _vm.constitutiva.tipo_societario === "SAPI"
              ? _c("label", [_vm._v(" Administradores ")])
              : _vm._e(),
            _vm.constitutiva.tipo_societario === "S de RL"
              ? _c("label", [_vm._v("Gerentes")])
              : _vm._e(),
            _c("b-table", {
              attrs: {
                hover: "",
                fields: _vm.fieldsCuadroAdmin,
                items: _vm.constitutiva.administradores,
              },
              scopedSlots: _vm._u([
                {
                  key: "cell(acciones)",
                  fn: function ({ item }) {
                    return [
                      _c(
                        "b-button",
                        {
                          directives: [
                            {
                              name: "b-tooltip",
                              rawName: "v-b-tooltip.hover",
                              modifiers: { hover: true },
                            },
                          ],
                          staticClass: "editBtn",
                          attrs: {
                            variant: "warning",
                            size: "sm",
                            title: "Editar",
                            disabled: _vm.constitutiva.disabledComplementa,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.editAdministrador(item)
                            },
                          },
                        },
                        [_c("b-icon-pencil")],
                        1
                      ),
                    ]
                  },
                },
                {
                  key: "cell(listo)",
                  fn: function ({ item }) {
                    return [
                      item.rfc
                        ? _c("div", [
                            item.rfc.length === 13
                              ? _c(
                                  "div",
                                  { staticClass: "circle completado_green" },
                                  [
                                    _c("span", { staticClass: "number" }, [
                                      _c("i", { staticClass: "fa fa-check" }),
                                    ]),
                                  ]
                                )
                              : _c(
                                  "div",
                                  { staticClass: "circle completado_yellow" },
                                  [
                                    _c("span", { staticClass: "number" }, [
                                      _c("i", { staticClass: "fa fa-minus" }),
                                    ]),
                                  ]
                                ),
                          ])
                        : _c("div", [
                            _c(
                              "div",
                              { staticClass: "circle completado_yellow" },
                              [
                                _c("span", { staticClass: "number" }, [
                                  _c("i", { staticClass: "fa fa-minus" }),
                                ]),
                              ]
                            ),
                          ]),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
      ]),
      _c("h4", { staticClass: "cardD mt-3" }, [_vm._v("Vigilancia")]),
      _c("label", { staticClass: "leyenda" }, [
        _vm._v(" Agrega el RFC a cada uno de los comisarios. "),
      ]),
      _vm.constitutiva.tipo_administracion === "Consejo de Administración" ||
      _vm.constitutiva.tipo_administracion === "Consejo de Gerentes"
        ? _c("div")
        : _vm._e(),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col md-12" },
          [
            _c("label", { attrs: { for: "" } }, [
              _vm._v("Comisario u Órgano de Vigilancia"),
            ]),
            _c("div", [
              _vm.constitutiva.tipo_societario === "S de RL"
                ? _c("label", { staticClass: "mb-12 leyenda" }, [
                    _vm._v(" Campo opcional "),
                  ])
                : _vm._e(),
            ]),
            _c("b-table", {
              attrs: {
                hover: "",
                fields: _vm.fieldsCuadroVigilancia,
                items: _vm.constitutiva.vigilancia,
              },
              scopedSlots: _vm._u([
                {
                  key: "cell(acciones)",
                  fn: function ({ item }) {
                    return [
                      _c(
                        "b-button",
                        {
                          directives: [
                            {
                              name: "b-tooltip",
                              rawName: "v-b-tooltip.hover",
                              modifiers: { hover: true },
                            },
                          ],
                          staticClass: "editBtn",
                          attrs: {
                            variant: "warning",
                            size: "sm",
                            title: "Editar",
                            disabled: _vm.constitutiva.disabledComplementa,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.editVigilancia(item)
                            },
                          },
                        },
                        [_c("b-icon-pencil")],
                        1
                      ),
                    ]
                  },
                },
                {
                  key: "cell(listo)",
                  fn: function ({ item }) {
                    return [
                      item.rfc
                        ? _c("div", [
                            item.rfc.length === 13
                              ? _c(
                                  "div",
                                  { staticClass: "circle completado_green" },
                                  [
                                    _c("span", { staticClass: "number" }, [
                                      _c("i", { staticClass: "fa fa-check" }),
                                    ]),
                                  ]
                                )
                              : _c(
                                  "div",
                                  { staticClass: "circle completado_yellow" },
                                  [
                                    _c("span", { staticClass: "number" }, [
                                      _c("i", { staticClass: "fa fa-minus" }),
                                    ]),
                                  ]
                                ),
                          ])
                        : _c("div", [
                            _c(
                              "div",
                              { staticClass: "circle completado_yellow" },
                              [
                                _c("span", { staticClass: "number" }, [
                                  _c("i", { staticClass: "fa fa-minus" }),
                                ]),
                              ]
                            ),
                          ]),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
      ]),
      _c("h4", { staticClass: "cardD mt-3" }, [
        _vm._v("Representantes y Apoderados"),
      ]),
      _c("label", { staticClass: "leyenda" }, [
        _vm._v(" Agrega el RFC a cada uno de los apoderados. "),
      ]),
      _c(
        "b-modal",
        {
          ref: "apoderado-modal",
          attrs: {
            "no-close-on-backdrop": "",
            centered: "",
            title: "",
            "hide-footer": "",
            id: "modalC",
            size: "xl",
          },
        },
        [
          _c("ValidationObserver", {
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ handleSubmit }) {
                  return [
                    _c(
                      "form",
                      {
                        on: {
                          submit: function ($event) {
                            $event.preventDefault()
                            return handleSubmit(_vm.putApoderado)
                          },
                        },
                      },
                      [
                        _c("ModalApoderados", {
                          attrs: {
                            currentApoderado: _vm.currentApoderado,
                            options_facultades: _vm.options_facultades,
                            complete: true,
                          },
                          on: {
                            submit: function ($event) {
                              $event.preventDefault()
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-12" },
          [
            _c("label", { attrs: { for: "" } }, [_vm._v("Apoderados")]),
            _c("b-table", {
              attrs: {
                hover: "",
                fields: _vm.fieldsCuadroApoderados,
                items: _vm.constitutiva.apoderados,
              },
              scopedSlots: _vm._u([
                {
                  key: "cell(acciones)",
                  fn: function ({ item }) {
                    return [
                      _c(
                        "b-button",
                        {
                          directives: [
                            {
                              name: "b-tooltip",
                              rawName: "v-b-tooltip.hover",
                              modifiers: { hover: true },
                            },
                          ],
                          staticClass: "editBtn",
                          attrs: {
                            variant: "warning",
                            size: "sm",
                            title: "Editar",
                            disabled: _vm.constitutiva.disabledComplementa,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.editApoderado(item)
                            },
                          },
                        },
                        [_c("b-icon-pencil")],
                        1
                      ),
                    ]
                  },
                },
                {
                  key: "cell(listo)",
                  fn: function ({ item }) {
                    return [
                      item.rfc
                        ? _c("div", [
                            item.rfc.length === 13
                              ? _c(
                                  "div",
                                  { staticClass: "circle completado_green" },
                                  [
                                    _c("span", { staticClass: "number" }, [
                                      _c("i", { staticClass: "fa fa-check" }),
                                    ]),
                                  ]
                                )
                              : _c(
                                  "div",
                                  { staticClass: "circle completado_yellow" },
                                  [
                                    _c("span", { staticClass: "number" }, [
                                      _c("i", { staticClass: "fa fa-minus" }),
                                    ]),
                                  ]
                                ),
                          ])
                        : _c("div", [
                            _c(
                              "div",
                              { staticClass: "circle completado_yellow" },
                              [
                                _c("span", { staticClass: "number" }, [
                                  _c("i", { staticClass: "fa fa-minus" }),
                                ]),
                              ]
                            ),
                          ]),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "mt-4" }, [
        _vm.constitutiva.disabledComplementa === false
          ? _c(
              "div",
              [
                _c(
                  "b-button",
                  {
                    staticClass: "btn-fill orange-btn bottom2",
                    attrs: {
                      type: "submit",
                      size: "m",
                      disabled: !_vm.canValidate,
                    },
                  },
                  [_vm._v("Continuar")]
                ),
              ],
              1
            )
          : _c(
              "div",
              [
                _c(
                  "b-button",
                  {
                    staticClass: "btn-fill orange-btn bottom2",
                    attrs: {
                      type: "submit",
                      size: "m",
                      disabled: _vm.constitutiva.disabledComplementa,
                    },
                  },
                  [_vm._v(" Continuar ")]
                ),
              ],
              1
            ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }