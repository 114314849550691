var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c("h4", { staticClass: "cardD" }, [_vm._v("Generación de documentos")]),
      _c("card", { staticClass: "blue" }, [
        _c("i", { staticClass: "fa fa-info" }),
        _vm._v(" Aquí van instrucciones. "),
        _c("p"),
        _c("i", { staticClass: "fa fa-play" }),
        _vm._v(" Links a videos. "),
        _c("p"),
        _c("i", { staticClass: "fa fa-question" }),
        _vm._v(" Datos de contacta a un asesor. "),
      ]),
      _c("div", { staticClass: "container" }, [
        _vm.loading
          ? _c("div", [
              _c(
                "div",
                { attrs: { id: "loader" } },
                [
                  _c("font-awesome-icon", {
                    attrs: { icon: "spinner", spin: "" },
                  }),
                ],
                1
              ),
            ])
          : _vm._e(),
      ]),
      _c(
        "b-modal",
        {
          ref: "modal-confirm-dialog",
          attrs: {
            "no-close-on-backdrop": "",
            centered: "",
            "hide-footer": "",
            id: "modal-confirm-dialog",
          },
        },
        [
          _c("h4", { staticClass: "text-center" }, [
            _vm._v("¿Seguro que quiere eliminar el documento?"),
          ]),
          _c("h5", { staticClass: "text-center" }),
          _c(
            "b-button",
            {
              staticClass: "mt-3",
              attrs: { variant: "danger" },
              on: {
                click: function ($event) {
                  return _vm.$bvModal.hide("modal-confirm-dialog")
                },
              },
            },
            [_vm._v("Cancelar")]
          ),
          _c(
            "b-button",
            {
              staticClass: "mt-3 float-right",
              attrs: { variant: "primary" },
              on: { click: _vm.deleteDocumento },
            },
            [_vm._v("OK")]
          ),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          ref: "documento-modal",
          attrs: {
            "no-close-on-backdrop": "",
            centered: "",
            title: "",
            "hide-footer": "",
            id: "modalC",
            size: "xl",
          },
        },
        [
          _c("ValidationObserver", {
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ handleSubmit }) {
                  return [
                    _c(
                      "form",
                      {
                        on: {
                          submit: function ($event) {
                            $event.preventDefault()
                            return handleSubmit(_vm.postDocumento)
                          },
                        },
                      },
                      [
                        _c("ModalDocumentos", {
                          attrs: {
                            currentDocumento: _vm.currentDocumento,
                            options: _vm.options,
                          },
                          on: {
                            submit: function ($event) {
                              $event.preventDefault()
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          ref: "documento-modal-preview-img",
          attrs: {
            "no-close-on-backdrop": "",
            centered: "",
            title: "",
            "hide-footer": "",
            id: "modaldi",
            size: "xl",
          },
        },
        [
          _c("ModalPreviewImg", {
            attrs: { currentDocumento: _vm.currentDocumento },
          }),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          ref: "documento-modal-preview-pdf",
          attrs: {
            "no-close-on-backdrop": "",
            centered: "",
            title: "",
            "hide-footer": "",
            id: "modaldf",
            size: "xl",
          },
        },
        [
          _c("ModalPreviewPDF", {
            attrs: { currentDocumento: _vm.currentDocumento },
          }),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          ref: "documento-modal-preview-docx",
          attrs: {
            "no-close-on-backdrop": "",
            centered: "",
            title: "",
            "hide-footer": "",
            id: "modaldd",
            size: "xl",
          },
        },
        [
          _c("ModalPreviewDocx", {
            attrs: { currentDocumento: _vm.currentDocumento },
          }),
        ],
        1
      ),
      _c("h4", { staticClass: "cardD mt-1" }, [_vm._v("Póliza")]),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-6" },
          [
            _c(
              "b-button",
              {
                staticClass: "btn-fill",
                attrs: {
                  block: "",
                  variant: "primary",
                  size: "m",
                  disabled: !_vm.listoGenerarProyecto,
                },
                on: {
                  click: function ($event) {
                    return _vm.generaProyecto()
                  },
                },
              },
              [_vm._v(" Generar proyecto ")]
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-6" },
          [
            _c(
              "b-button",
              {
                staticClass: "btn-fill",
                attrs: { block: "", variant: "info", size: "m" },
                on: {
                  click: function ($event) {
                    return _vm.descargaProyecto()
                  },
                },
              },
              [
                _c("i", { staticClass: "fa fa-download" }),
                _vm._v(" Descargar proyecto "),
              ]
            ),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "row mt-5" }, [
        _c(
          "div",
          { staticClass: "col-md-4" },
          [
            _c(
              "b-button",
              {
                staticClass: "btn-fill",
                attrs: {
                  variant: "success",
                  hidden: _vm.formalizacion.documentos_proyecto.length >= 2,
                },
                on: { click: _vm.addDocumento },
              },
              [_vm._v(" + Cargar Proyecto ")]
            ),
          ],
          1
        ),
      ]),
      _c("b-table", {
        attrs: {
          hover: "",
          fields: _vm.fieldsProyecto,
          items: _vm.formalizacion.documentos_proyecto,
        },
        scopedSlots: _vm._u([
          {
            key: "cell(acciones)",
            fn: function ({ item }) {
              return [
                _c(
                  "b-button",
                  {
                    directives: [
                      {
                        name: "b-tooltip",
                        rawName: "v-b-tooltip.hover",
                        modifiers: { hover: true },
                      },
                    ],
                    staticClass: "editBtn",
                    attrs: { variant: "warning", size: "sm", title: "Editar" },
                    on: {
                      click: function ($event) {
                        return _vm.editDocumento(item)
                      },
                    },
                  },
                  [_c("b-icon-pencil")],
                  1
                ),
                _c(
                  "b-button",
                  {
                    directives: [
                      {
                        name: "b-tooltip",
                        rawName: "v-b-tooltip.hover",
                        modifiers: { hover: true },
                      },
                    ],
                    staticClass: "editBtn",
                    attrs: {
                      variant: "primary",
                      size: "sm",
                      title: "Descargar",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.descargaDocumento(item)
                      },
                    },
                  },
                  [_c("b-icon-download")],
                  1
                ),
                _c(
                  "b-button",
                  {
                    directives: [
                      {
                        name: "b-tooltip",
                        rawName: "v-b-tooltip.hover",
                        modifiers: { hover: true },
                      },
                    ],
                    staticClass: "editBtn",
                    attrs: { variant: "danger", size: "sm", title: "Editar" },
                    on: {
                      click: function ($event) {
                        return _vm.predeleteDocumento(item)
                      },
                    },
                  },
                  [_c("b-icon-trash")],
                  1
                ),
              ]
            },
          },
        ]),
      }),
      _c("div", { staticClass: "mt-4" }, [
        _c(
          "div",
          {},
          [
            _c(
              "b-button",
              {
                staticClass: "btn-fill orange-btn bottom2 float-right",
                attrs: { type: "submit", size: "m" },
              },
              [_vm._v("Guardar ")]
            ),
          ],
          1
        ),
      ]),
      _c("h4", { staticClass: "cardD mt-1 bottom3" }, [
        _vm._v(" Enviar Póliza a firma digital "),
      ]),
      !_vm.listoEnvioProyecto
        ? _c("label", { staticClass: "leyenda mt-1" }, [
            _vm._v(
              " Debe estar cargado el proyecto en Word y en PDF para poder enviarlo a los firmantes para su firma digital. "
            ),
          ])
        : _vm._e(),
      _c("div", { staticClass: "mt-3" }, [
        _vm.listoEnvioProyecto
          ? _c(
              "div",
              [
                _c(
                  "b-button",
                  {
                    staticClass: "btn-fill",
                    attrs: {
                      block: "",
                      variant: "success",
                      size: "m",
                      disabled: _vm.disabledSendProyectoFad,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.enviarProyectoFad()
                      },
                    },
                  },
                  [_vm._v(" Enviar Póliza a firma digital ")]
                ),
                _vm.disabledSendKycsFad
                  ? _c("label", { staticClass: "leyenda mt-1" }, [
                      _vm._v(
                        " Si la opción está deshabilitada, es porque ya se enviaron a Firma "
                      ),
                    ])
                  : _vm._e(),
              ],
              1
            )
          : _vm._e(),
      ]),
      _c("h4", { staticClass: "cardD bottom3" }, [_vm._v("Generar KYCs")]),
      _c(
        "b-button",
        {
          staticClass: "btn-fill mb-3",
          attrs: { block: "", variant: "success", size: "m" },
          on: {
            click: function ($event) {
              return _vm.generaKycs()
            },
          },
        },
        [_vm._v(" Generar ")]
      ),
      _c("b-table", {
        attrs: {
          hover: "",
          fields: _vm.fields,
          items: _vm.formalizacion.documentos_kycs,
        },
        scopedSlots: _vm._u([
          {
            key: "cell(acciones)",
            fn: function ({ item }) {
              return [
                _c(
                  "b-button",
                  {
                    directives: [
                      {
                        name: "b-tooltip",
                        rawName: "v-b-tooltip.hover",
                        modifiers: { hover: true },
                      },
                    ],
                    staticClass: "editBtn",
                    attrs: {
                      variant: "primary",
                      size: "sm",
                      title: "Vista previa",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.previewDocumento(item)
                      },
                    },
                  },
                  [_c("b-icon-eye")],
                  1
                ),
              ]
            },
          },
        ]),
      }),
      _c("h4", { staticClass: "cardD bottom3" }, [
        _vm._v("Enviar KYCs a firma digital"),
      ]),
      !_vm.listoGenerar
        ? _c("label", { staticClass: "leyenda mt-1" }, [
            _vm._v(
              " Todos los formularios deberán estar validados y se deberá tener cargada la Póliza en Word y en PDF para poder enviarlos a los firmantes para su firma digital. "
            ),
          ])
        : _vm._e(),
      _c("div", { staticClass: "mt-3" }, [
        _vm.listoGenerar
          ? _c(
              "div",
              [
                _c(
                  "b-button",
                  {
                    staticClass: "btn-fill",
                    attrs: {
                      block: "",
                      variant: "success",
                      size: "m",
                      disabled: _vm.disabledSendKycsFad,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.enviarKycsFad()
                      },
                    },
                  },
                  [_vm._v(" Enviar KYCs a firma digital ")]
                ),
                _vm.disabledSendKycsFad
                  ? _c("label", { staticClass: "leyenda mt-1" }, [
                      _vm._v(
                        " Si la opción está deshabilitada, es porque ya se enviaron a Firma "
                      ),
                    ])
                  : _vm._e(),
              ],
              1
            )
          : _c("div", [
              _c(
                "div",
                { staticClass: "bg-danger text-light", attrs: { id: "aviso" } },
                [_vm._v(" Contacte a cliente para obtener más datos ")]
              ),
            ]),
      ]),
      _c(
        "div",
        _vm._l(_vm.formalizacion.firmantes, function (item) {
          return _c(
            "div",
            { key: item.id, attrs: { hidden: "" } },
            [
              _c("KycTemplate", {
                attrs: {
                  id: item.id,
                  currentCompareciente: item,
                  beneficiario_pld: 2,
                  beneficiario_cff: 4,
                },
              }),
            ],
            1
          )
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }