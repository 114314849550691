var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-md-12" },
          [
            _c("ValidationProvider", {
              attrs: {
                name: "nombre",
                rules: "required",
                "custom-messages": { required: "El nombre es obligatorio" },
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ errors }) {
                    return [
                      _c("base-input", {
                        attrs: {
                          type: "text",
                          label: "Nombre",
                          placeholder: "Nombre",
                        },
                        model: {
                          value: _vm.correduria.nombre,
                          callback: function ($$v) {
                            _vm.$set(_vm.correduria, "nombre", $$v)
                          },
                          expression: "correduria.nombre",
                        },
                      }),
                      _c("span", { staticClass: "cardErrors" }, [
                        _vm._v(_vm._s(errors[0])),
                      ]),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
      ]),
      _c("label", [_vm._v("Domicilio")]),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-8" }, [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-md-6" },
              [
                _c("ValidationProvider", {
                  attrs: {
                    name: "entidad",
                    rules: "required",
                    "custom-messages": {
                      required: "La entidad es obligatoria",
                    },
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ errors }) {
                        return [
                          _c("label", [_vm._v("Entidad Federativa")]),
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.selectState,
                                  expression: "selectState",
                                },
                              ],
                              staticClass: "form-control",
                              on: {
                                change: [
                                  function ($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call(
                                        $event.target.options,
                                        function (o) {
                                          return o.selected
                                        }
                                      )
                                      .map(function (o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.selectState = $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  },
                                  _vm.fetchCitiesByState,
                                ],
                              },
                            },
                            [
                              _vm.isEmptyStates
                                ? _c(
                                    "option",
                                    {
                                      attrs: {
                                        value: "noStates",
                                        selected: "",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(_vm.noStatesText) + " "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._l(_vm.states, function (option) {
                                return _c("option", { key: option.id }, [
                                  _vm._v(" " + _vm._s(option.name) + " "),
                                ])
                              }),
                            ],
                            2
                          ),
                          _c("span", { staticClass: "cardErrors" }, [
                            _vm._v(_vm._s(errors[0])),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-md-6" },
              [
                _c("ValidationProvider", {
                  attrs: {
                    name: "delegacion_municipio",
                    rules: "required",
                    "custom-messages": {
                      required: "La demarcación territorial es obligatoria",
                    },
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ errors }) {
                        return [
                          _c("label", [
                            _vm._v("Demarcación Territorial o Municipio"),
                          ]),
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.selectCity,
                                  expression: "selectCity",
                                },
                              ],
                              staticClass: "form-control",
                              on: {
                                change: function ($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function (o) {
                                      return o.selected
                                    })
                                    .map(function (o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.selectCity = $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                },
                              },
                            },
                            [
                              _vm.isEmptyCities
                                ? _c(
                                    "option",
                                    {
                                      attrs: {
                                        value: "noCities",
                                        selected: "",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(_vm.noCitiesText) + " "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._l(_vm.cities, function (option) {
                                return _c("option", { key: option.id }, [
                                  _vm._v(" " + _vm._s(option.name) + " "),
                                ])
                              }),
                            ],
                            2
                          ),
                          _c("span", { staticClass: "cardErrors" }, [
                            _vm._v(_vm._s(errors[0])),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-md-6" },
              [
                _c("ValidationProvider", {
                  attrs: {
                    name: "calle",
                    rules: "required",
                    "custom-messages": {
                      required: "La calle es obligatoria",
                    },
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ errors }) {
                        return [
                          _c("base-input", {
                            attrs: {
                              type: "text",
                              label: "Calle / Avenida / Vía",
                              placeholder: "Calle",
                            },
                            model: {
                              value: _vm.correduria.domicilio.calle,
                              callback: function ($$v) {
                                _vm.$set(_vm.correduria.domicilio, "calle", $$v)
                              },
                              expression: "correduria.domicilio.calle",
                            },
                          }),
                          _c("span", { staticClass: "cardErrors" }, [
                            _vm._v(_vm._s(errors[0])),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-md-3" },
              [
                _c("ValidationProvider", {
                  attrs: {
                    name: "num_exterior",
                    rules: "required",
                    "custom-messages": {
                      required: "El número exterior es obligatorio",
                    },
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ errors }) {
                        return [
                          _c("base-input", {
                            attrs: {
                              type: "text",
                              label: "Número exterior",
                              placeholder: "Número exterior",
                            },
                            model: {
                              value: _vm.correduria.domicilio.num_exterior,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.correduria.domicilio,
                                  "num_exterior",
                                  $$v
                                )
                              },
                              expression: "correduria.domicilio.num_exterior",
                            },
                          }),
                          _c("span", { staticClass: "cardErrors" }, [
                            _vm._v(_vm._s(errors[0])),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-md-3" },
              [
                _c("base-input", {
                  attrs: {
                    type: "text",
                    label: "Número interior",
                    placeholder: "Número interior",
                  },
                  model: {
                    value: _vm.correduria.domicilio.num_interior,
                    callback: function ($$v) {
                      _vm.$set(_vm.correduria.domicilio, "num_interior", $$v)
                    },
                    expression: "correduria.domicilio.num_interior",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-md-6" },
              [
                _c("ValidationProvider", {
                  attrs: {
                    name: "colonia",
                    rules: "required",
                    "custom-messages": {
                      required: "La colonia es obligatoria",
                    },
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ errors }) {
                        return [
                          _c("base-input", {
                            attrs: {
                              type: "text",
                              label: "Colonia o Urbanización",
                              placeholder: "Colonia o Urbanización",
                            },
                            model: {
                              value: _vm.correduria.domicilio.colonia,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.correduria.domicilio,
                                  "colonia",
                                  $$v
                                )
                              },
                              expression: "correduria.domicilio.colonia",
                            },
                          }),
                          _c("span", { staticClass: "cardErrors" }, [
                            _vm._v(_vm._s(errors[0])),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-md-6" },
              [
                _c("ValidationProvider", {
                  attrs: {
                    name: "ciudad",
                    rules: "required",
                    "custom-messages": {
                      required: "La ciudad o población es obligatoria",
                    },
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ errors }) {
                        return [
                          _c("base-input", {
                            attrs: {
                              type: "text",
                              label: "Ciudad o Población",
                              placeholder: "Ciudad o Población",
                            },
                            model: {
                              value: _vm.correduria.domicilio.ciudad,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.correduria.domicilio,
                                  "ciudad",
                                  $$v
                                )
                              },
                              expression: "correduria.domicilio.ciudad",
                            },
                          }),
                          _c("span", { staticClass: "cardErrors" }, [
                            _vm._v(_vm._s(errors[0])),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-md-6" },
              [
                _c("ValidationProvider", {
                  attrs: {
                    name: "pais",
                    rules: "required",
                    "custom-messages": {
                      required: "El país es obligatorio",
                    },
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ errors }) {
                        return [
                          _c("base-input", {
                            attrs: {
                              type: "text",
                              disabled: "",
                              label: "País",
                              placeholder: "País",
                            },
                            model: {
                              value: _vm.correduria.domicilio.pais,
                              callback: function ($$v) {
                                _vm.$set(_vm.correduria.domicilio, "pais", $$v)
                              },
                              expression: "correduria.domicilio.pais",
                            },
                          }),
                          _c("span", { staticClass: "cardErrors" }, [
                            _vm._v(_vm._s(errors[0])),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-md-6" },
              [
                _c("ValidationProvider", {
                  attrs: {
                    name: "codigo_postal",
                    rules: "required|numeric",
                    "custom-messages": {
                      required: "El código postal es obligatorio",
                      numeric: "El código postal debe ser un número",
                    },
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ errors }) {
                        return [
                          _c("base-input", {
                            attrs: {
                              type: "text",
                              label: "Código Postal",
                              placeholder: "Código Postal",
                            },
                            model: {
                              value: _vm.correduria.domicilio.codigo_postal,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.correduria.domicilio,
                                  "codigo_postal",
                                  $$v
                                )
                              },
                              expression: "correduria.domicilio.codigo_postal",
                            },
                          }),
                          _c("span", { staticClass: "cardErrors" }, [
                            _vm._v(_vm._s(errors[0])),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-md-6" },
              [
                _c("ValidationProvider", {
                  attrs: {
                    name: "email",
                    rules: "required",
                    "custom-messages": {
                      required: "El email es requerido",
                    },
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ errors }) {
                        return [
                          _c("base-input", {
                            attrs: {
                              type: "email",
                              label: "Email",
                              placeholder: "Email",
                            },
                            model: {
                              value: _vm.correduria.email,
                              callback: function ($$v) {
                                _vm.$set(_vm.correduria, "email", $$v)
                              },
                              expression: "correduria.email",
                            },
                          }),
                          _c("span", { staticClass: "cardErrors" }, [
                            _vm._v(_vm._s(errors[0])),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "col-4" },
          [
            _c("DrawGoogleMap", {
              attrs: {
                lat: parseFloat(_vm.correduria.domicilio.lat),
                lng: parseFloat(_vm.correduria.domicilio.lng),
              },
              on: { location: _vm.updateCoordinates },
            }),
          ],
          1
        ),
      ]),
      _c(
        "b-button",
        {
          staticClass: "btn-fill float-right orange-btn",
          attrs: { type: "submit" },
        },
        [_vm._v("Guardar")]
      ),
      _c(
        "b-modal",
        {
          ref: "staff-modal",
          attrs: {
            "no-close-on-backdrop": "",
            centered: "",
            title: "",
            "hide-footer": "",
            id: "modalC",
            size: "xl",
          },
        },
        [
          _c("ModalStaff", {
            attrs: {
              user: _vm.currentStaff,
              corredurias_id: _vm.correduria.id,
            },
            on: { closeModal: _vm.closeModal },
          }),
        ],
        1
      ),
      _vm._m(0),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-8" }),
        _vm.correduria.id != 0
          ? _c(
              "div",
              { staticClass: "col-4" },
              [
                _c(
                  "b-button",
                  {
                    directives: [
                      {
                        name: "b-tooltip",
                        rawName: "v-b-tooltip.hover",
                        modifiers: { hover: true },
                      },
                    ],
                    staticClass: "btn-fill float-right orange-btn",
                    attrs: { variant: "warning", size: "l", title: "Editar" },
                    on: {
                      click: function ($event) {
                        return _vm.newStaff()
                      },
                    },
                  },
                  [_vm._v(" Nuevo staff ")]
                ),
              ],
              1
            )
          : _vm._e(),
      ]),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-12" },
          [
            _c(
              "b-modal",
              {
                ref: "modal-confirm-dialog",
                attrs: {
                  "no-close-on-backdrop": "",
                  centered: "",
                  "hide-footer": "",
                  id: "modal-confirm-dialog",
                },
              },
              [
                _c("h4", { staticClass: "text-center" }, [
                  _vm._v("¿Seguro que quiere eliminar al staff?"),
                ]),
                _c("h5", { staticClass: "text-center" }, [
                  _vm._v("Se eliminarán todos los datos."),
                ]),
                _c(
                  "b-button",
                  {
                    staticClass: "mt-3",
                    attrs: { variant: "danger" },
                    on: {
                      click: function ($event) {
                        return _vm.$bvModal.hide("modal-confirm-dialog")
                      },
                    },
                  },
                  [_vm._v("Cancelar")]
                ),
                _c(
                  "b-button",
                  {
                    staticClass: "mt-3 float-right",
                    attrs: { variant: "primary" },
                    on: {
                      click: function ($event) {
                        return _vm.deleteStaff()
                      },
                    },
                  },
                  [_vm._v("OK")]
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "text-center" },
              [
                _c("b-table", {
                  attrs: {
                    fields: _vm.fields,
                    items: _vm.correduria.staff,
                    hover: "",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "cell(acciones)",
                      fn: function ({ item }) {
                        return [
                          _c(
                            "b-button",
                            {
                              directives: [
                                {
                                  name: "b-tooltip",
                                  rawName: "v-b-tooltip.hover",
                                  modifiers: { hover: true },
                                },
                              ],
                              staticClass: "editBtn",
                              attrs: {
                                variant: "warning",
                                size: "l",
                                title: "Editar",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.editStaff(item.id)
                                },
                              },
                            },
                            [_c("b-icon-pencil")],
                            1
                          ),
                          _c(
                            "b-button",
                            {
                              directives: [
                                {
                                  name: "b-tooltip",
                                  rawName: "v-b-tooltip.hover",
                                  modifiers: { hover: true },
                                },
                              ],
                              attrs: {
                                variant: "danger",
                                size: "sm",
                                title: "Eliminar",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.predeleteStaff(item.id)
                                },
                              },
                            },
                            [_c("b-icon-trash")],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "titulo" }, [
      _c("hr"),
      _c("label", [_vm._v("Miembros del Staff")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }