var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content" },
    [
      _c(
        "b-breadcrumb",
        [
          _c("b-breadcrumb-item", { attrs: { to: { path: "/dashboard" } } }, [
            _vm._v("Procesos"),
          ]),
          _c("b-breadcrumb-item", { attrs: { active: "" } }, [
            _vm._v("Validar Proceso"),
          ]),
        ],
        1
      ),
      _c("div", { staticClass: "container" }, [
        _vm.loading
          ? _c("div", [
              _c(
                "div",
                { attrs: { id: "loader" } },
                [
                  _c("font-awesome-icon", {
                    attrs: { icon: "spinner", spin: "" },
                  }),
                ],
                1
              ),
            ])
          : _vm._e(),
      ]),
      _c(
        "b-modal",
        {
          ref: "modal-confirm-dialog-route",
          attrs: {
            "no-close-on-backdrop": "",
            centered: "",
            "hide-footer": "",
            id: "modal-confirm-dialog-route",
          },
        },
        [
          _c("h4", { staticClass: "text-center" }, [
            _vm._v(" ¿Seguro que quiere salir de la página? "),
          ]),
          _c("h5", { staticClass: "text-center" }),
          _c(
            "b-button",
            {
              staticClass: "mt-3",
              attrs: { variant: "danger" },
              on: {
                click: function ($event) {
                  return _vm.$bvModal.hide("modal-confirm-dialog-route")
                },
              },
            },
            [_vm._v("Cancelar")]
          ),
          _c(
            "b-button",
            {
              staticClass: "mt-3 float-right",
              attrs: { variant: "primary" },
              on: {
                click: function ($event) {
                  return _vm.beforeLeave(true)
                },
              },
            },
            [_vm._v("OK")]
          ),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          ref: "modal-confirm-dialog",
          attrs: {
            "no-close-on-backdrop": "",
            centered: "",
            "hide-footer": "",
            id: "modal-confirm-dialog",
          },
        },
        [
          _c("h4", { staticClass: "text-center" }, [
            _vm._v(" ¿Seguro que quiere salir sin guardar los cambios? "),
          ]),
          _c("h5", { staticClass: "text-center" }),
          _c(
            "b-button",
            {
              staticClass: "mt-3",
              attrs: { variant: "danger" },
              on: {
                click: function ($event) {
                  return _vm.$bvModal.hide("modal-confirm-dialog")
                },
              },
            },
            [_vm._v("Cancelar")]
          ),
          _c(
            "b-button",
            {
              staticClass: "mt-3 float-right",
              attrs: { variant: "primary" },
              on: {
                click: function ($event) {
                  return _vm.selected("go")
                },
              },
            },
            [_vm._v("OK")]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "container-fluid" },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { staticClass: "col-3" },
                [
                  _c(
                    "card",
                    [
                      _c("TreeM", {
                        attrs: {
                          constitutiva: _vm.constitutiva,
                          "selected-component": _vm.currentComponentBody,
                          user: "Staff",
                        },
                        on: { node: _vm.selected },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-col",
                { staticClass: "col-9" },
                [
                  _c(
                    "card",
                    [
                      _c(
                        "b-row",
                        [
                          _c(_vm.currentComponentInfoHead, {
                            tag: "component",
                            staticClass: "tab",
                          }),
                        ],
                        1
                      ),
                      _c(
                        "b-row",
                        [
                          _c("b-col", { staticClass: "col-12" }, [
                            _vm.currentComponentBody === "DatosGenerales"
                              ? _c(
                                  "div",
                                  [
                                    _c("ValidationObserver", {
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function ({ handleSubmit }) {
                                              return [
                                                _c(
                                                  "form",
                                                  {
                                                    on: {
                                                      submit: function (
                                                        $event
                                                      ) {
                                                        $event.preventDefault()
                                                        return handleSubmit(
                                                          _vm.saveConstitucion
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("DatosGenerales", {
                                                      attrs: {
                                                        constitutiva:
                                                          _vm.constitutiva,
                                                        optionsObjetoSocial:
                                                          _vm.optionsObjetoSocial,
                                                      },
                                                      on: {
                                                        submit: function (
                                                          $event
                                                        ) {
                                                          $event.preventDefault()
                                                        },
                                                        validaForm: function (
                                                          $event
                                                        ) {
                                                          return _vm.validar(
                                                            "DatosGenerales"
                                                          )
                                                        },
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        4134965329
                                      ),
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.currentComponentBody === "DatosCapital"
                              ? _c(
                                  "div",
                                  [
                                    _c("ValidationObserver", {
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function ({ handleSubmit }) {
                                              return [
                                                _c(
                                                  "form",
                                                  {
                                                    on: {
                                                      submit: function (
                                                        $event
                                                      ) {
                                                        $event.preventDefault()
                                                        return handleSubmit(
                                                          _vm.saveConstitucion
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("DatosCapital", {
                                                      attrs: {
                                                        constitutiva:
                                                          _vm.constitutiva,
                                                      },
                                                      on: {
                                                        submit: function (
                                                          $event
                                                        ) {
                                                          $event.preventDefault()
                                                        },
                                                        node: _vm.selected,
                                                        validaForm: function (
                                                          $event
                                                        ) {
                                                          return _vm.validar(
                                                            "DatosCapital"
                                                          )
                                                        },
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        1010963096
                                      ),
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.currentComponentBody === "DatosOrganos"
                              ? _c(
                                  "div",
                                  [
                                    _c("ValidationObserver", {
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function ({ handleSubmit }) {
                                              return [
                                                _c(
                                                  "form",
                                                  {
                                                    on: {
                                                      submit: function (
                                                        $event
                                                      ) {
                                                        $event.preventDefault()
                                                        return handleSubmit(
                                                          _vm.saveConstitucion
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("DatosOrganos", {
                                                      attrs: {
                                                        constitutiva:
                                                          _vm.constitutiva,
                                                      },
                                                      on: {
                                                        submit: function (
                                                          $event
                                                        ) {
                                                          $event.preventDefault()
                                                        },
                                                        node: _vm.selected,
                                                        validaForm: function (
                                                          $event
                                                        ) {
                                                          return _vm.validar(
                                                            "DatosOrganos"
                                                          )
                                                        },
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        3734613880
                                      ),
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.currentComponentBody ===
                            "RepresentantesYApoderados"
                              ? _c(
                                  "div",
                                  [
                                    _c("ValidationObserver", {
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function ({ handleSubmit }) {
                                              return [
                                                _c(
                                                  "form",
                                                  {
                                                    on: {
                                                      submit: function (
                                                        $event
                                                      ) {
                                                        $event.preventDefault()
                                                        return handleSubmit(
                                                          _vm.saveConstitucion
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "RepresentantesYApoderados",
                                                      {
                                                        attrs: {
                                                          constitutiva:
                                                            _vm.constitutiva,
                                                          cat_facultades:
                                                            _vm.cat_facultades,
                                                        },
                                                        on: {
                                                          submit: function (
                                                            $event
                                                          ) {
                                                            $event.preventDefault()
                                                          },
                                                          node: _vm.selected,
                                                          validaForm: function (
                                                            $event
                                                          ) {
                                                            return _vm.validar(
                                                              "RepresentantesYApoderados"
                                                            )
                                                          },
                                                        },
                                                      }
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        110252654
                                      ),
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.currentComponentBody === "EntidadFederativa"
                              ? _c(
                                  "div",
                                  [
                                    _c("ValidationObserver", {
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function ({ handleSubmit }) {
                                              return [
                                                _c(
                                                  "form",
                                                  {
                                                    on: {
                                                      submit: function (
                                                        $event
                                                      ) {
                                                        $event.preventDefault()
                                                        return handleSubmit(
                                                          _vm.saveConstitucion
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("EntidadFederativa", {
                                                      attrs: {
                                                        constitutiva:
                                                          _vm.constitutiva,
                                                        catCorredurias:
                                                          _vm.catCorredurias,
                                                      },
                                                      on: {
                                                        submit: function (
                                                          $event
                                                        ) {
                                                          $event.preventDefault()
                                                        },
                                                        node: _vm.selected,
                                                        validaForm: function (
                                                          $event
                                                        ) {
                                                          return _vm.validar(
                                                            "EntidadFederativa"
                                                          )
                                                        },
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        502794926
                                      ),
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.currentComponentBody === "CapitulosEspeciales"
                              ? _c(
                                  "div",
                                  [
                                    _c("ValidationObserver", {
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function ({ handleSubmit }) {
                                              return [
                                                _c(
                                                  "form",
                                                  {
                                                    on: {
                                                      submit: function (
                                                        $event
                                                      ) {
                                                        $event.preventDefault()
                                                        return handleSubmit(
                                                          _vm.saveConstitucion
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("CapitulosEspeciales", {
                                                      attrs: {
                                                        constitutiva:
                                                          _vm.constitutiva,
                                                      },
                                                      on: {
                                                        submit: function (
                                                          $event
                                                        ) {
                                                          $event.preventDefault()
                                                        },
                                                        node: _vm.selected,
                                                        validaForm: function (
                                                          $event
                                                        ) {
                                                          return _vm.validar(
                                                            "CapitulosEspeciales"
                                                          )
                                                        },
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        1460284792
                                      ),
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.currentComponentBody === "Cotizacion"
                              ? _c(
                                  "div",
                                  [
                                    _c("ValidationObserver", {
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function ({ handleSubmit }) {
                                              return [
                                                _c(
                                                  "form",
                                                  {
                                                    on: {
                                                      submit: function (
                                                        $event
                                                      ) {
                                                        $event.preventDefault()
                                                        return handleSubmit(
                                                          _vm.saveConstitucion
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("Cotizacion", {
                                                      attrs: {
                                                        constitutiva:
                                                          _vm.constitutiva,
                                                        cat_gastos:
                                                          _vm.cat_gastos,
                                                      },
                                                      on: {
                                                        submit: function (
                                                          $event
                                                        ) {
                                                          $event.preventDefault()
                                                        },
                                                        node: _vm.selected,
                                                        validaForm: function (
                                                          $event
                                                        ) {
                                                          return _vm.validar(
                                                            "Cotizacion"
                                                          )
                                                        },
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        1957766670
                                      ),
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.currentComponentBody ===
                            "ComplementaInformacion"
                              ? _c(
                                  "div",
                                  [
                                    _c("ValidationObserver", {
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function ({ handleSubmit }) {
                                              return [
                                                _c(
                                                  "form",
                                                  {
                                                    on: {
                                                      submit: function (
                                                        $event
                                                      ) {
                                                        $event.preventDefault()
                                                        return handleSubmit(
                                                          _vm.saveConstitucion
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "ComplementaInformacion",
                                                      {
                                                        attrs: {
                                                          constitutiva:
                                                            _vm.constitutiva,
                                                          cat_facultades:
                                                            _vm.cat_facultades,
                                                        },
                                                        on: {
                                                          submit: function (
                                                            $event
                                                          ) {
                                                            $event.preventDefault()
                                                          },
                                                          node: _vm.selected,
                                                          validaForm: function (
                                                            $event
                                                          ) {
                                                            return _vm.validar(
                                                              "ComplementaInformacion"
                                                            )
                                                          },
                                                        },
                                                      }
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        2144734638
                                      ),
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.currentComponentBody === "Expediente"
                              ? _c(
                                  "div",
                                  [
                                    _c("ValidationObserver", {
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function ({ handleSubmit }) {
                                              return [
                                                _c(
                                                  "form",
                                                  {
                                                    on: {
                                                      submit: function (
                                                        $event
                                                      ) {
                                                        $event.preventDefault()
                                                        return handleSubmit(
                                                          _vm.saveConstitucion
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("Expediente", {
                                                      attrs: {
                                                        constitutiva:
                                                          _vm.constitutiva,
                                                      },
                                                      on: {
                                                        submit: function (
                                                          $event
                                                        ) {
                                                          $event.preventDefault()
                                                        },
                                                        node: _vm.selected,
                                                        validaForm: function (
                                                          $event
                                                        ) {
                                                          return _vm.validar(
                                                            "Expediente"
                                                          )
                                                        },
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        75188120
                                      ),
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.currentComponentBody === "CitaFirma"
                              ? _c(
                                  "div",
                                  [
                                    _c("ValidationObserver", {
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function ({ handleSubmit }) {
                                              return [
                                                _c(
                                                  "form",
                                                  {
                                                    on: {
                                                      submit: function (
                                                        $event
                                                      ) {
                                                        $event.preventDefault()
                                                        return handleSubmit(
                                                          _vm.saveConstitucion
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("CitaFirma", {
                                                      attrs: {
                                                        constitutiva:
                                                          _vm.constitutiva,
                                                      },
                                                      on: {
                                                        submit: function (
                                                          $event
                                                        ) {
                                                          $event.preventDefault()
                                                        },
                                                        node: _vm.selected,
                                                        validaForm: function (
                                                          $event
                                                        ) {
                                                          return _vm.validar(
                                                            "CitaFirma"
                                                          )
                                                        },
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        760119800
                                      ),
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.currentComponentBody === "GeneraDocumento"
                              ? _c(
                                  "div",
                                  [
                                    _c("ValidationObserver", {
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function ({ handleSubmit }) {
                                              return [
                                                _c(
                                                  "form",
                                                  {
                                                    on: {
                                                      submit: function (
                                                        $event
                                                      ) {
                                                        $event.preventDefault()
                                                        return handleSubmit(
                                                          _vm.saveConstitucion
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("GeneraDocumento", {
                                                      attrs: {
                                                        constitutiva:
                                                          _vm.constitutiva,
                                                      },
                                                      on: {
                                                        submit: function (
                                                          $event
                                                        ) {
                                                          $event.preventDefault()
                                                        },
                                                        node: _vm.selected,
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        1243530710
                                      ),
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.currentComponentBody === "AsignarCorreduria"
                              ? _c(
                                  "div",
                                  [
                                    _c("ValidationObserver", {
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function ({ handleSubmit }) {
                                              return [
                                                _c(
                                                  "form",
                                                  {
                                                    on: {
                                                      submit: function (
                                                        $event
                                                      ) {
                                                        $event.preventDefault()
                                                        return handleSubmit(
                                                          _vm.saveConstitucion
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("AsignarCorreduria", {
                                                      attrs: {
                                                        constitutiva:
                                                          _vm.constitutiva,
                                                        catCorredurias:
                                                          _vm.catCorredurias,
                                                      },
                                                      on: {
                                                        submit: function (
                                                          $event
                                                        ) {
                                                          $event.preventDefault()
                                                        },
                                                        node: _vm.selected,
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        1425048185
                                      ),
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.currentComponentBody === "DenominacionSocial"
                              ? _c(
                                  "div",
                                  [
                                    _c("ValidationObserver", {
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function ({ handleSubmit }) {
                                              return [
                                                _c(
                                                  "form",
                                                  {
                                                    on: {
                                                      submit: function (
                                                        $event
                                                      ) {
                                                        $event.preventDefault()
                                                        return handleSubmit(
                                                          _vm.saveConstitucion
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("DenominacionSocial", {
                                                      attrs: {
                                                        constitutiva:
                                                          _vm.constitutiva,
                                                      },
                                                      on: {
                                                        submit: function (
                                                          $event
                                                        ) {
                                                          $event.preventDefault()
                                                        },
                                                        node: _vm.selected,
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        1001278967
                                      ),
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.currentComponentBody === "DocFirmado"
                              ? _c(
                                  "div",
                                  [
                                    _c("DocFirmado", {
                                      attrs: { constitutiva: _vm.constitutiva },
                                      on: {
                                        submit: function ($event) {
                                          $event.preventDefault()
                                        },
                                        node: _vm.selected,
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.currentComponentBody === "Terminado"
                              ? _c(
                                  "div",
                                  [
                                    _c("ValidationObserver", {
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function ({ handleSubmit }) {
                                              return [
                                                _c(
                                                  "form",
                                                  {
                                                    on: {
                                                      submit: function (
                                                        $event
                                                      ) {
                                                        $event.preventDefault()
                                                        return handleSubmit(
                                                          _vm.saveConstitucion
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("Terminado", {
                                                      attrs: {
                                                        constitutiva:
                                                          _vm.constitutiva,
                                                      },
                                                      on: {
                                                        submit: function (
                                                          $event
                                                        ) {
                                                          $event.preventDefault()
                                                        },
                                                        node: _vm.selected,
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        3375598191
                                      ),
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }