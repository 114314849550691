var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container" }, [
    _c("h4", { staticClass: "cardD" }, [
      _vm._v(
        " Información relacionada con la sociedad y el acta que se va a protocolarizar o formalizar "
      ),
    ]),
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-6" },
        [
          _c("ValidationProvider", {
            attrs: {
              name: "tipo_acta",
              "custom-messages": { required: "El tipo de acta es obligatorio" },
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ errors }) {
                  return [
                    _c("label", { attrs: { for: "" } }, [
                      _vm._v("Tipo de acta"),
                    ]),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.formalizacion.tipo_acta,
                            expression: "formalizacion.tipo_acta",
                          },
                        ],
                        staticClass: "custom-select",
                        attrs: { disabled: _vm.formalizacion.disabled },
                        on: {
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.formalizacion,
                              "tipo_acta",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                        },
                      },
                      [
                        _c(
                          "option",
                          { attrs: { value: "Asamblea de Accionistas" } },
                          [_vm._v("Asamblea de Accionistas")]
                        ),
                        _c(
                          "option",
                          {
                            attrs: {
                              value:
                                "Resoluciones Unánimes adoptadas fuera de asamblea (RUAs)",
                            },
                          },
                          [
                            _vm._v(
                              "Resoluciones Unánimes adoptadas fuera de asamblea (RUAs)"
                            ),
                          ]
                        ),
                        _c(
                          "option",
                          {
                            attrs: {
                              value:
                                "Acta de sesión del Consejo de Administración",
                            },
                          },
                          [
                            _vm._v(
                              "Acta de sesión del Consejo de Administración "
                            ),
                          ]
                        ),
                      ]
                    ),
                    _c("span", { staticClass: "cardErrors" }, [
                      _vm._v(_vm._s(errors[0])),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("div", { staticClass: "col-6" }, [
        _vm.formalizacion.tipo_acta == "Asamblea de Accionistas"
          ? _c(
              "div",
              [
                _c("ValidationProvider", {
                  attrs: {
                    name: "subtipo_acta",
                    "custom-messages": {
                      required: "La especificación de acta es obligatorio",
                    },
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function ({ errors }) {
                          return [
                            _c("label", { attrs: { for: "" } }, [
                              _vm._v("Especificación"),
                            ]),
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.formalizacion.subtipo_acta,
                                    expression: "formalizacion.subtipo_acta",
                                  },
                                ],
                                staticClass: "custom-select",
                                attrs: { disabled: _vm.formalizacion.disabled },
                                on: {
                                  change: function ($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call(
                                        $event.target.options,
                                        function (o) {
                                          return o.selected
                                        }
                                      )
                                      .map(function (o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.$set(
                                      _vm.formalizacion,
                                      "subtipo_acta",
                                      $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    )
                                  },
                                },
                              },
                              [
                                _c(
                                  "option",
                                  { attrs: { value: "General Ordinaria" } },
                                  [_vm._v("General Ordinaria")]
                                ),
                                _c(
                                  "option",
                                  {
                                    attrs: { value: "General Extraordinaria" },
                                  },
                                  [_vm._v("General Extraordinaria")]
                                ),
                                _c("option", { attrs: { value: "Especial" } }, [
                                  _vm._v("Especial"),
                                ]),
                              ]
                            ),
                            _c("span", { staticClass: "cardErrors" }, [
                              _vm._v(_vm._s(errors[0])),
                            ]),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    3490993394
                  ),
                }),
              ],
              1
            )
          : _vm.formalizacion.tipo_acta ==
            "Resoluciones Unánimes adoptadas fuera de asamblea (RUAs)"
          ? _c(
              "div",
              [
                _c("ValidationProvider", {
                  attrs: {
                    name: "subtipo_acta",
                    "custom-messages": {
                      required: "La especificación de acta es obligatorio",
                    },
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ errors }) {
                        return [
                          _c("label", { attrs: { for: "" } }, [
                            _vm._v("Especificación"),
                          ]),
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.formalizacion.subtipo_acta,
                                  expression: "formalizacion.subtipo_acta",
                                },
                              ],
                              staticClass: "custom-select",
                              attrs: { disabled: _vm.formalizacion.disabled },
                              on: {
                                change: function ($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function (o) {
                                      return o.selected
                                    })
                                    .map(function (o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.$set(
                                    _vm.formalizacion,
                                    "subtipo_acta",
                                    $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  )
                                },
                              },
                            },
                            [
                              _c(
                                "option",
                                { attrs: { value: "Firmadas autógrafamente" } },
                                [_vm._v("Firmadas autógrafamente")]
                              ),
                              _c(
                                "option",
                                {
                                  attrs: {
                                    value: "Firmadas de forma electrónica",
                                  },
                                },
                                [_vm._v("Firmadas de forma electrónica")]
                              ),
                            ]
                          ),
                          _c("span", { staticClass: "cardErrors" }, [
                            _vm._v(_vm._s(errors[0])),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            )
          : _vm._e(),
      ]),
    ]),
    _vm.block
      ? _c(
          "label",
          {
            staticClass: "mt-2 leyendaBlock",
            attrs: { disabled: _vm.formalizacion.disabled },
          },
          [
            _c("b", [
              _vm._v(
                " Para que esta opción pueda llevarse acabo, es necesario que se comunique con el personal de Correduría Digital para ser atendido de forma personalizada "
              ),
            ]),
          ]
        )
      : _vm._e(),
    _c("div", { staticClass: "row mt-4" }, [
      _c(
        "div",
        { staticClass: "col-md-9" },
        [
          _c("ValidationProvider", {
            attrs: {
              name: "nombre_sociedad",
              rules: "required",
              "custom-messages": {
                required: "El nombre de la sociedad es obligatorio",
              },
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ errors }) {
                  return [
                    _c("base-input", {
                      attrs: {
                        disabled: _vm.formalizacion.disabled,
                        type: "text",
                        label: "Nombre de la sociedad",
                        placeholder: "Nombre de la sociedad",
                      },
                      model: {
                        value: _vm.formalizacion.nombre_sociedad,
                        callback: function ($$v) {
                          _vm.$set(_vm.formalizacion, "nombre_sociedad", $$v)
                        },
                        expression: "formalizacion.nombre_sociedad",
                      },
                    }),
                    _c("span", { staticClass: "cardErrors" }, [
                      _vm._v(_vm._s(errors[0])),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-3" },
        [
          _c("ValidationProvider", {
            attrs: {
              name: "tipo_societario",
              "custom-messages": {
                required: "El tipo de societario es obligatorio",
              },
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ errors }) {
                  return [
                    _c("label", { attrs: { for: "" } }, [
                      _vm._v("Tipo de societario"),
                    ]),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.formalizacion.tipo_societario,
                            expression: "formalizacion.tipo_societario",
                          },
                        ],
                        staticClass: "custom-select",
                        attrs: { disabled: _vm.formalizacion.disabled },
                        on: {
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.formalizacion,
                              "tipo_societario",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                        },
                      },
                      [
                        _c("option", { attrs: { value: "S.A." } }, [
                          _vm._v("S.A."),
                        ]),
                        _c("option", { attrs: { value: "S.A. de C.V." } }, [
                          _vm._v("S.A. de C.V."),
                        ]),
                        _c("option", { attrs: { value: "S. de R.L." } }, [
                          _vm._v("S. de R.L."),
                        ]),
                        _c(
                          "option",
                          { attrs: { value: "S. de R.L. de C.V." } },
                          [_vm._v("S. de R.L. de C.V.")]
                        ),
                        _c("option", { attrs: { value: "S.A.P.I." } }, [
                          _vm._v("S.A.P.I."),
                        ]),
                        _c("option", { attrs: { value: "S.A.P.I. de C.V." } }, [
                          _vm._v("S.A.P.I. de C.V."),
                        ]),
                      ]
                    ),
                    _c("span", { staticClass: "cardErrors" }, [
                      _vm._v(_vm._s(errors[0])),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ]),
    _vm._m(0),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-md-12" }, [
        _c(
          "form",
          {
            on: {
              change: function ($event) {
                return _vm.getAddressInMap(null)
              },
            },
          },
          [
            _c("ModalDomicilio", {
              attrs: {
                currentDomicilio: _vm.formalizacion.domicilio,
                "hide-save-button": true,
              },
            }),
          ],
          1
        ),
      ]),
    ]),
    _vm._m(1),
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-md-3" },
        [
          _c(
            "b-form-radio",
            {
              attrs: { name: "radios", value: "Sí" },
              on: {
                change: function ($event) {
                  return _vm.selectInscrita("Sí")
                },
              },
              model: {
                value: _vm.sociedadInscrita,
                callback: function ($$v) {
                  _vm.sociedadInscrita = $$v
                },
                expression: "sociedadInscrita",
              },
            },
            [_vm._v(" Sí ")]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-md-3" },
        [
          _c(
            "b-form-radio",
            {
              attrs: { name: "radios", value: "No" },
              on: {
                change: function ($event) {
                  return _vm.selectInscrita("No")
                },
              },
              model: {
                value: _vm.sociedadInscrita,
                callback: function ($$v) {
                  _vm.sociedadInscrita = $$v
                },
                expression: "sociedadInscrita",
              },
            },
            [_vm._v(" No ")]
          ),
        ],
        1
      ),
    ]),
    _vm.blockInscrita
      ? _c(
          "label",
          {
            staticClass: "mt-2 leyendaBlock",
            attrs: { disabled: _vm.formalizacion.disabled },
          },
          [
            _c("b", [
              _vm._v(
                " Para poder continuar con el proceso es necesario que la sociedad esté debidamente registrada. "
              ),
            ]),
          ]
        )
      : _vm._e(),
    _c("div", { staticClass: "mt-4" }, [
      _c(
        "div",
        {},
        [
          _c(
            "b-button",
            {
              staticClass: "btn-fill orange-btn bottom2",
              attrs: {
                type: "submit",
                size: "m",
                disabled: _vm.formalizacion.disabled,
              },
            },
            [_vm._v("Continuar")]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row mt-4" }, [
      _c("div", { staticClass: "col-md-12" }, [
        _c("label", [_vm._v("Domicilio social y corporativo de la sociedad")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row mt-4" }, [
      _c("div", { staticClass: "col-md-12" }, [
        _c("label", [
          _vm._v(
            "La sociedad está debidamente inscrita en el Registro Público de Comercio"
          ),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }