<template>
  <div class="container">
    <div class="container">
      <div v-if="loading">
        <div id="loader">
          <font-awesome-icon icon="spinner" spin />
        </div>
      </div>
    </div>

    <!-- Modales Documento -->
    <b-modal no-close-on-backdrop centered ref="entrega-documento-modal" title="" hide-footer id="modalC" size="xl">
      <div>
        <ValidationObserver v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(postDocumento)">
            <ModalEntregaDocumento :currentDocumento="currentDocumento" :notificacion="notificacion" @submit.prevent />
          </form>
        </ValidationObserver>
      </div>
    </b-modal>
    <b-modal no-close-on-backdrop centered ref="documento-modal-preview-img" title="" hide-footer id="modaldi"
      size="xl">
      <ModalPreviewImg :currentDocumento="currentDocumento" />
    </b-modal>
    <b-modal no-close-on-backdrop centered ref="documento-modal-preview-pdf" title="" hide-footer id="modaldf"
      size="xl">
      <ModalPreviewPDF :currentDocumento="currentDocumento" />
    </b-modal>
    <b-modal no-close-on-backdrop centered ref="modal-confirm-regenerate" hide-footer id="modal-confirm-regenerate">
      <h4 class="text-center">
        ¿Está seguro de regenerar la Carta de Instrucción? La Carta existente será eliminada.
      </h4>
      <h5 class="text-center"></h5>
      <b-button class="mt-3" variant="danger" @click="$bvModal.hide('modal-confirm-regenerate')">Cancelar</b-button>
      <b-button class="mt-3 float-right" variant="primary" @click="regenerarCartaInstruccion()">OK</b-button>
    </b-modal>
    <b-modal no-close-on-backdrop centered ref="modal-confirm-regenerate-notificar" hide-footer
      id="modal-confirm-regenerate-notificar">
      <h4 class="text-center">
        ¿Está seguro de regenerar el Documento a Notificar con FAD? En caso de haber uno existente, será eliminado.
      </h4>
      <h5 class="text-center"></h5>
      <b-button class="mt-3" variant="danger"
        @click="$bvModal.hide('modal-confirm-regenerate-notificar')">Cancelar</b-button>
      <b-button class="mt-3 float-right" variant="primary" @click="regenerarDocumentoANotificar()">OK</b-button>
    </b-modal>
    <b-modal no-close-on-backdrop centered ref="modal-confirm-regenerate-kyc" hide-footer
      id="modal-confirm-regenerate-kyc">
      <h4 class="text-center">
        ¿Está seguro de regenerar el KYC? En caso de haber uno existente, será eliminado.
      </h4>
      <h5 class="text-center"></h5>
      <b-button class="mt-3" variant="danger" @click="$bvModal.hide('modal-confirm-regenerate-kyc')">Cancelar</b-button>
      <b-button class="mt-3 float-right" variant="primary" @click="regenerarKYC()">OK</b-button>
    </b-modal>

    <!-- Template -->
    <h4 class="cardD">Entrega de Documentos</h4>
    <p class="font-weight-bold">
      Comparte tus documentos con nosotros. Haz clic en el botón <b-icon-upload
        style="margin-left: 8px; margin-right: 8px;" />
      para subir tus documentos a notificar y tu identificación oficial.
    </p>

    <div>
      <b-table ref="table" hover :fields="fieldsDocumentos" :items="notificacion.documentos">
        <template #cell(tipo)="{ item }">
          <div v-if="item.tipo === 'Otro'">
            <div v-b-tooltip.hover :title="item.descripcion">
              {{ item.tipo }}
            </div>
          </div>
          <div v-else>
            {{ item.tipo }}
          </div>
        </template>
        <template #cell(acciones)="{ item }">
          <b-button
            v-if="!isDocumentoCartaDeInstruccion(item) && (notificacion.id_estatus == 22 || (notificacion.staff && !notificacion.validaDocumentos))"
            variant="warning" size="sm" v-b-tooltip.hover title="Subir documento" @click="openEditDocumentoModal(item)"
            class="editBtn">
            <b-icon-upload />
          </b-button>
          <b-button variant="primary" size="sm" v-b-tooltip.hover title="Vista previa" @click="previewDocumento(item)"
            class="editBtn">
            <b-icon-eye />
          </b-button>
        </template>
      </b-table>
    </div>

    <div hidden>
      <div ref="cartaInstruccion">
        <CartaInstruccionTemplate :key="cartaKey" :datos="datos" />
      </div>
      <div ref="hojaFirmas">
        <HojaFirmasTemplate :key="cartaKey" :datos="datos" />
      </div>
    </div>

    <div v-if="datosCompletos" ref="KycSolicitanteTemplate" hidden>
      <div v-for="solicitante in notificacion.solicitantes" :key="solicitante.id">
        <KycSolicitanteTemplate :id="solicitante.id" :currentCompareciente="solicitante" :beneficiario_pld="0"
          :beneficiario_cff="0" />
      </div>
    </div>

    <div class="row mt-4" v-if="!notificacion.staff">
      <div class="col-md-12">
        <ValidationProvider name="confirmAceptacion" :rules="{ required: { allowFalse: false } }" v-slot="{ errors }"
          :custom-messages="{ required: 'La confirmación es obligatoria' }">
          <b-form-checkbox v-model="confirmAceptacion"
            :disabled="(!notificacion.staff && (notificacion.id_estatus != 22 || !documentosCompletos)) || (notificacion.staff && notificacion.validaDocumentos == 1)"
            class="orange">
            Acepto que la información proporcionada es correcta.
          </b-form-checkbox>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>

    <div class="mt-4" v-if="!notificacion.staff">
      <div class="">
        <b-button type="submit" class="btn-fill orange-btn bottom2" size="m"
          :disabled="!confirmAceptacion || (notificacion.id_estatus != 22 && documentosCompletos)">Continuar</b-button>
      </div>
    </div>

    <div class="mt-4" v-if="notificacion.staff">
      <div class="">
        <b-button class="btn-fill orange-btn bottom1" size="m" @click="openModalRegenerate()"
          :disabled="notificacion.validaDocumentos == 1">Regenerar Carta de Instrucción</b-button>
      </div>
      <div class="" v-if="parseInt(notificacion.fad) == 1">
        <b-button class="btn-fill orange-btn bottom1" size="m" @click="openModalRegenerateNotificar()"
          :disabled="notificacion.validaDocumentos == 1">Regenerar Documento a Notificar</b-button>
      </div>
      <div class="">
        <b-button class="btn-fill orange-btn bottom1" size="m" @click="openModalRegenerateKYC()"
          :disabled="notificacion.validaDocumentos == 1">Regenerar KYC</b-button>
      </div>
      <div class="">
        <b-button type="submit" class="btn-fill orange-btn bottom2" size="m"
          :disabled="notificacion.validaDocumentos == 1">Validar</b-button>
      </div>
    </div>
  </div>
</template>

<script>
import ModalEntregaDocumento from "@/views/clients/procesos/notificaciones/completaInfo/modales/ModalEntregaDocumento.vue";
import ModalPreviewImg from "@/views/clients/procesos/constitucion/entregadocumentos/ModalPreviewImg.vue";
import ModalPreviewPDF from "@/views/clients/procesos/constitucion/entregadocumentos/ModalPreviewPDF.vue";

import CartaInstruccionTemplate from "@/views/clients/procesos/notificaciones/CartaInstruccionTemplate.vue";
import HojaFirmasTemplate from "@/views/clients/procesos/notificaciones/HojaFirmasTemplate.vue";
import KycSolicitanteTemplate from "@/components/KycSolicitanteTemplate.vue";

import { CatDocumentosApi, DocumentosXServiciosApi, DocumentosApi, FuncionesApi, SolicitantesApi, ComparecientesApi } from "@/api";

export default {
  name: "EntregaDocumentos",

  components: {
    ModalEntregaDocumento,
    ModalPreviewImg,
    ModalPreviewPDF,
    CartaInstruccionTemplate,
    HojaFirmasTemplate,
    KycSolicitanteTemplate
  },

  props: {
    notificacion: { type: Object },
  },

  computed: {
    fieldsDocumentos() {
      return [
        { key: "tipo", label: "Tipo", class: "text-center" },
        { key: "nombre", label: "Nombre", class: "text-center" },
        { key: "acciones", label: "Acciones", class: "text-center" }
      ];
    },
  },

  watch: {
    "notificacion.documentos": {
      handler: function () {
        this.areDocumentosCompletos();
      },
      deep: true
    }
  },

  async created() {
    try {
      this.loading = true;

      await this.getSolicitantesAsync();

      this.notificacion.documentos = [];
      await this.getCatDocumentosNotificaciones(); // aqui trae los cat_documentos de notificaciones (incluido kyc)
      await this.getCatDocumentosNotificacionesPersonas();
      await this.getDocumentosXServiciosWithNotificacion();
      this.areDocumentosCompletos();
      // this.searchMoralRepresentada();
      // await this.generaKycSolicitantes();

      if (this.notificacion.staff && (this.documentosCompletos || this.datosCompletos)) {
        this.loading = true;
        // console.log(this.notificacion.solicitantes[0].representantes);
        this.generaDatosParaCartaDeInstruccion();
        await this.generaCartaDeInstruccion();

        if (this.notificacion.fad) {
          await this.regenerarDocumentoANotificar();
        }

      }

      this.$refs.table.refresh();
      this.$toast.success("información cargada exitosamente.", {
        timeout: 4000, //false,
        hideProgressBar: false,
        position: "top-right",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        closeButton: "button",
        icon: true,
      });
    } catch (error) {
      console.log(error);
      this.showErrorToast('No se pudo obtener la información. Intente más tarde.');
    } finally {
      this.loading = false;
    }
  },

  data() {
    return {
      loading: false,

      optionsDocumentos: [],
      documentosXServicios: [],
      documentosCompletos: false,
      confirmAceptacion: false,

      currentDocumento: {},

      cartaInstruccion: null,
      datos: {},
      cartaKey: 0,
      datosCompletos: false,
    }
  },

  methods: {
    showErrorToast(msg) {
      this.$toast.error(msg, {
        timeout: 4000, //false,
        hideProgressBar: false,
        position: "top-right",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        closeButton: "button",
        icon: true,
      });
    },

    showGeneratingDocumentToast() {
      this.$toast.info("Se está generando el documento, espere un momento, por favor.", {
        timeout: 4000, //false,
        hideProgressBar: false,
        position: "top-right",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        closeButton: "button",
        icon: true,
      });
    },

    showGeneratedDocumentToast() {
      this.$toast.success("Documento generado exitosamente.", {
        timeout: 4000, //false,
        hideProgressBar: false,
        position: "top-right",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        closeButton: "button",
        icon: true,
      });
    },

    async getSolicitantesAsync() {
      const response = await SolicitantesApi.list({
        id_notificaciones: this.notificacion.id
      });
      const solicitantes = response.value;

      this.notificacion.solicitantes = [];

      for (const solicitante of solicitantes) {
        const compareciente = await ComparecientesApi.detail(solicitante.id_comparecientes);
        this.notificacion.solicitantes.push(compareciente);
      }
    },

    searchMoralRepresentada() {
      for (const solicitante of this.notificacion.solicitantes) {
        if (solicitante.moral_representada_id) {
          const index = this.notificacion.solicitantes.findIndex(o => o.id === solicitante.moral_representada_id);
          this.notificacion.solicitantes[index].representantes.push(solicitante);
        }
      }
    },

    async getCatDocumentosNotificaciones() {
      const { value } = await CatDocumentosApi.list({
        page: 0,
        registersPerPage: 0,
        categoria: "notificaciones",
      });

      this.populateOptionsDocumentos(value);
    },

    async getCatDocumentosNotificacionesPersonas() {
      let tipo = null;
      let persona = this.notificacion.solicitantes[0].persona;

      if (persona === "Física") {
        if(this.notificacion.solicitantes[0].actua_derecho_propio) {
          tipo = "pf";
        } else {
          tipo = "pfr";
        }
      } else if (persona === "Moral") {
        tipo = "pm";
      } else if (persona === "Fideicomiso") {
        tipo = "f"
      }

      const { value } = await CatDocumentosApi.list({
        page: 0,
        registersPerPage: 0,
        categoria: "notificaciones",
        tipo: tipo
      });

      console.log(value);

      this.populateOptionsDocumentos(value);
    },

    populateOptionsDocumentos(documentos) {
      documentos.forEach(documento => {
        const obj = { text: documento.documento, value: documento.id };
        this.optionsDocumentos.push(obj);
      });
    },

    async getDocumentosXServiciosWithNotificacion() {
      const { value } = await DocumentosXServiciosApi.list({
        page: 0,
        registersPerPage: 0,
        cat_servicios_id: 2,
        id_proceso: this.notificacion.id
      });
      this.documentosXServicios = value;

      await this.getDocumentosFromDocumentosXServicios();
    },

    async getDocumentosFromDocumentosXServicios() {
      for (const documentoServicio of this.documentosXServicios) {
        await this.getDocumentoDetail(documentoServicio.documentos_id);
      }

      await this.generaDocumentoFAD();
    },

    async getDocumentoDetail(documento_id) {
      try {
        this.loading = true;
        const documento = await DocumentosApi.detail(documento_id);

        // exluye facturas
        if (documento.cat_documentos_id === '94') {
          return;
        }

        this.completeFields(documento);
      } catch (error) {
        console.log(error);
        this.showErrorToast('Error al obtener archivo. Intente más tarde');
      } finally {
        this.loading = false;
      }
    },

    completeFields(documento) {
      const documentoWithTipo = this.addTipo(documento);
      this.addDocumentoToNotificacionDocumentos(documentoWithTipo);
    },

    addTipo(documento) {
      const tipo = this.getCatDocumentoNombre(documento);
      documento.tipo = tipo;
      return documento;
    },

    getCatDocumentoNombre(documento) {
      const catDcoumentosIndex = this.getCatDocumentosIndex(documento);

      if (catDcoumentosIndex === -1) {
        return "";
      }

      const nombre_catDocumento = this.optionsDocumentos[catDcoumentosIndex].text;
      return nombre_catDocumento;
    },

    getCatDocumentosIndex(documento) {
      let catDocumentosIndex = this.optionsDocumentos.findIndex(
        (optionDocumento) => optionDocumento.value === parseInt(documento.cat_documentos_id)
      );
      if (catDocumentosIndex == -1) {
        // console.log(documento)
      }
      this.areDocumentosCompletos();
      return catDocumentosIndex;
    },

    addDocumentoToNotificacionDocumentos(documento) {
      if (!documento.tipo) return;

      if (this.isDocumentoActa(documento) || this.isDocumentoFadObject(documento)) {
        // || this.isDocumentoANotificarFAD(documento)) {
        return;
      }
      if (documento.documento === "Identificación oficial del Solicitante principal"
        || documento.documento === "Identificación oficial del Representante") {
        this.notificacion.documentos.unshift(documento);
      } else {
        this.notificacion.documentos.push(documento);
      }
      // this.sortNotificacionDocumentosById();
      // this.putIdDocsAtTheBeginning();
    },

    isDocumentoCartaDeInstruccion(documento) {
      if ([39, 67, 93, 94, 95].includes(parseInt(documento.cat_documentos_id))) { return true }
      else { return false };
    },

    isDocumentoActa(documento) {
      const actas_id = [44, 45, 46]
      if (actas_id.includes(parseInt(documento.cat_documentos_id))) { return true }
      else { return false };
    },

    isDocumentoFadObject(documento) {
      const fad_id = [43]
      if (fad_id.includes(parseInt(documento.cat_documentos_id))) { return true }
      else { return false };
    },

    isDocumentoANotificarFAD(documento) {
      const fad_id = [67]
      if (fad_id.includes(parseInt(documento.cat_documentos_id))
        && !this.notificacion.staff) { return true }
      else { return false };
    },

    sortNotificacionDocumentosById() {
      this.notificacion.documentos.sort((a, b) => a.id - b.id);
    },

    putIdDocsAtTheBeginning() {
      const indexToMove = this.notificacion.documentos
        .findIndex(d => d.documento === "Identificación oficial del Solicitante principal"
          || d.documento === "Identificación oficial del Representante");

      if (indexToMove !== -1) {
        const elementToMove = this.notificacion.documentos.splice(indexToMove, 1)[0];

        this.notificacion.documentos.unshift(elementToMove);
      }
    },

    openEditDocumentoModal(documento) {
      this.$refs["entrega-documento-modal"].show();
      this.currentDocumento = documento;
    },

    postDocumento() {
      this.hideEntregaDocumentoModal();
      this.sendCurrentDocumentoToAzureStorage();
    },

    async sendCurrentDocumentoToAzureStorage() {
      this.loading = true;
      try {
        const formData = new FormData();
        this.currentDocumento.nombre = this.currentDocumento.file.name;
        formData.append("file", this.currentDocumento.file);
        const path = await FuncionesApi.file(
          this.notificacion.id,
          "documentosNotificaciones",
          formData,
          {}
        );
        this.currentDocumento.documento = path;
        this.updateDocumentoBD();
      } catch (error) {
        this.showErrorToast('Error al mandar el archivo. Intente más tarde.');
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    async updateDocumentoBD() {
      this.loading = true;
      try {
        await DocumentosApi.edit(this.currentDocumento.id, {
          documento: this.currentDocumento.documento,
          cat_documentos_id: this.currentDocumento.cat_documentos_id,
          auto_referencia: this.currentDocumento.auto_referencia,
          descripcion: this.currentDocumento.descripcion,
          paginas: parseInt(this.currentDocumento.paginas),
          copias_certificadas: null,
          nombre: this.currentDocumento.nombre,
          activo: 1
        });
        this.updateNotificacionDocumentoFromCurrentDocumento();
      } catch (error) {
        this.showErrorToast('Error al actualizar el documento. Intente más tarde.');
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    updateNotificacionDocumentoFromCurrentDocumento() {
      const notificacionDocumentosIndex = this.getNotificacionDocumentosIndex(this.currentDocumento);
      this.notificacion.documentos[notificacionDocumentosIndex] = this.currentDocumento;
      this.areDocumentosCompletos();
    },

    getNotificacionDocumentosIndex(documento) {
      let notificacionDocumentosIndex = this.notificacion.documentos.findIndex(
        (notificacionDocumento) => notificacionDocumento.id === parseInt(documento.id)
      );
      return notificacionDocumentosIndex;
    },

    hideEntregaDocumentoModal() {
      this.$refs["entrega-documento-modal"].hide();
    },

    previewDocumento(item) {
      this.currentDocumento = item;

      const isCurrentDocumentImage = this.isCurrentDocumentAnImage();

      if (isCurrentDocumentImage) {
        this.$refs["documento-modal-preview-img"].show();
      } else {
        this.$refs["documento-modal-preview-pdf"].show();
      }
    },

    isCurrentDocumentAnImage() {
      const documentoSeparatedByDots = this.currentDocumento.nombre.split('.')
      const extensionDocumento = documentoSeparatedByDots[documentoSeparatedByDots.length - 1]
      const imageExtensions = ["jpg", "JPG", "jpeg", "png", "gif", "bmp", "webp", "svg", "tiff", "avif"]

      return imageExtensions.includes(extensionDocumento);
    },

    async generaDocumentoFAD() {
      if (parseInt(this.notificacion.fad) === 1) {
        const hasCatDocumento67 =
          this.notificacion.documentos.some(documento => parseInt(documento.cat_documentos_id) === 67);

        if (!hasCatDocumento67) {
          await this.pushFAD();
        }
      }
    },

    getFilteredDocumentos() {
      return this.notificacion.documentos
        .filter(doc => {
          const id = parseInt(doc.cat_documentos_id);
          return id >= 30 && id <= 37;
        })
        .sort((a, b) => a.id - b.id)
        .map(doc => doc.documento);
    },

    async pushFAD() {
      try {
        this.loading = true;

        const { id } = await DocumentosApi.create({
          documento: "",
          cat_documentos_id: 67,
          auto_referencia: null,
          descripcion: null,
          paginas: null,
          copias_certificadas: null,
          nombre: null
        });
        await DocumentosXServiciosApi.create({
          documentos_id: id,
          cat_servicios_id: 2,
          proceso_id: this.notificacion.id
        });
      } catch (error) {
        this.showErrorToast('Error al crear documento para FAD. Intente más tarde.')
      } finally {
        this.loading = false;
        this.$refs.table.refresh();
      }
    },

    areDocumentosCompletos() {
      let completos = true;

      if (this.notificacion.documentos.length == 0) {
        return;
      }

      this.notificacion.documentos.forEach(documento => {
        if (parseInt(documento.cat_documentos_id) !== 67 && (!documento.nombre || !documento.documento)) {
          completos = false;
        }
      });
      this.documentosCompletos = completos;
      this.datosCompletos = true;
    },

    openModalRegenerate() {
      this.$refs["modal-confirm-regenerate"].show();
    },

    hideModalRegenerate() {
      this.$refs["modal-confirm-regenerate"].hide();
    },

    openModalRegenerateNotificar() {
      this.$refs["modal-confirm-regenerate-notificar"].show();
    },

    hideModalRegenerateNotificar() {
      this.$refs["modal-confirm-regenerate-notificar"].hide();
    },

    openModalRegenerateKYC() {
      this.$refs["modal-confirm-regenerate-kyc"].show();
    },

    hideModalRegenerateKYC() {
      this.$refs["modal-confirm-regenerate-kyc"].hide();
    },

    async regenerarCartaInstruccion() {
      this.hideModalRegenerate();
      this.showGeneratingDocumentToast();
      this.loading = true;

      try {
        await this.addComparecientesToNotificacionSolicitantes();
      } catch (error) {
        console.log(error);
        this.showErrorToast('No se pudo generar la carta de instrucción. Intenta más tarde.')
      } finally {
        this.loading = false;
      }
    },

    async regenerarDocumentoANotificar() {
      this.hideModalRegenerateNotificar();
      this.showGeneratingDocumentToast();
      this.loading = true;

      try {
        await this.addComparecientesToNotificacionSolicitantesNotificar();
        this.showGeneratedDocumentToast();
      } catch (error) {
        console.log(error);
        this.showErrorToast('No se pudo generar la carta de instrucción. Intenta más tarde.');
      } finally {
        this.loading = false;
      }
    },

    async regenerarKYC() {
      this.hideModalRegenerateKYC();
      this.showGeneratingDocumentToast();
      this.loading = true;

      try {
        await this.generaKycSolicitantes();
        this.showGeneratedDocumentToast();
      } catch (error) {
        console.log(error);
        this.showErrorToast('No se pudo generar el documento. Intente más tarde.');
      } finally {
        this.loading = false;
      }
    },

    async agregaSolicitantes() {
      let solicitantes = [];
      if (this.notificacion.solicitantes.length == 0) {
        solicitantes = this.getSolicitantes();
      } else {
        solicitantes = this.notificacion.solicitantes;
        this.notificacion.solicitantes = [];
      }
      return solicitantes;
    },

    async getSolicitantes() {
      const { value } = await SolicitantesApi.list({
        id_notificaciones: this.notificacion.id
      });
      let comparecientes = [];

      for (const solicitante of value) {

      }
      value.forEach(solicitante => {
        const compareciente = this.getCompareciente(solicitante);
        comparecientes.push(compareciente);
      })
      return comparecientes;
    },

    async getCompareciente(solicitante) {
      const compareciente = await ComparecientesApi.detail(solicitante.id_comparecientes)
      const nombreCompleto = this.createNombreCompleto(compareciente);

      compareciente.nombreCompleto = nombreCompleto;

      return compareciente;
    },

    async addComparecientesToNotificacionSolicitantes() {
      this.generaDatosParaCartaDeInstruccion();
      await this.generaCartaDeInstruccion();
    },

    async addComparecientesToNotificacionSolicitantesNotificar() {
      this.generaDatosParaCartaDeInstruccion();
      await this.generaDocumentoANotificar();
    },

    createNombreCompleto(compareciente) {
      let nombreCompletoTemp = ""
      if (compareciente.persona === 'Física') {
        nombreCompletoTemp = compareciente.nombre + ' ' + compareciente.paterno;
        if (compareciente.materno) {
          nombreCompletoTemp = nombreCompletoTemp + ' ' + compareciente.materno
        }
      } else {
        nombreCompletoTemp = compareciente.denominacion_social;
      }
      return nombreCompletoTemp;
    },

    generaDatosParaCartaDeInstruccion() {
      try {
        let nombre_completo_solicitante_principal = '';

        if (this.notificacion.solicitantes[0].persona === 'Física') {
          nombre_completo_solicitante_principal =
            (this.notificacion.solicitantes[0].nombre + ' ' + this.notificacion.solicitantes[0].paterno + ' '
              + (this.notificacion.solicitantes[0].materno || "")).trim();
        } else {
          nombre_completo_solicitante_principal = this.notificacion.solicitantes[0].denominacion_social.trim();
        }

        // Fecha
        const optionsMonth = { month: 'long' };
        const optionsDay = { month: '2-digit' };
        const today = new Date();
        const yyyy = today.getFullYear().toString();
        const formatedMonth = today.toLocaleDateString('es-MX', optionsMonth);
        const formatedDay = today.toLocaleDateString('es-MX', optionsDay);

        let notificados = '';

        this.notificacion.notificados.forEach(notificado => {
          let nombreCompletoTemp = "";

          if (notificado.persona === 'Física') {
            nombreCompletoTemp = (notificado.nombre + ' ' + notificado.paterno + ' ' + (notificado.materno || "")).trim();
          } else {
            nombreCompletoTemp = notificado.razon_social.trim();
          }

          notificados = notificados + nombreCompletoTemp;

          const index = this.notificacion.notificados.findIndex(o => o.id === notificado.id);

          if (index != this.notificacion.notificados.length - 1) {
            notificados = notificados + ', '
          }
        });

        let num_interior_string = "";
        if (this.notificacion.domicilio.num_interior) {
          num_interior_string = ` ${this.notificacion.domicilio.num_interior}`;
        }

        const domicilio_string =
          `${this.notificacion.domicilio.calle} ${this.notificacion.domicilio.num_exterior}` +
          `${num_interior_string}, Col. ${this.notificacion.domicilio.colonia}, ` +
          `${this.notificacion.domicilio.delegacion_municipio}, ${this.notificacion.domicilio.entidad}, ` +
          `C.P. ${this.notificacion.domicilio.codigo_postal}, ${this.notificacion.domicilio.pais}`;
        // `${this.notificacion.domicilio.ciudad}, C.P. ${this.notificacion.domicilio.codigo_postal}, ${this.notificacion.domicilio.pais}`

        let apoderado = null;
        let plural = false;

        // if (this.notificacion.solicitantes[0].persona === 'Moral') {
        //   apoderado = ''
        //   this.notificacion.solicitantes.forEach(solicitante => {
        //     if (this.notificacion.solicitantes[0].id == solicitante.moral_representada_id) {
        //       if (solicitante.persona === 'Física') {
        //         apoderado = solicitante.nombre + ' ' + solicitante.paterno;
        //         if (solicitante.materno) {
        //           apoderado = apoderado + ' ' + solicitante.materno;
        //         }
        //       } else {
        //         apoderado = solicitante.denominacion_social;
        //       }
        //     }
        //   })
        //   if (this.notificacion.solicitantes.length > 2) {
        //     plural = true;
        //   }
        // } else {
        //   if (this.notificacion.solicitantes.length > 1) {
        //     plural = true;
        //   }
        // }

        if (Array.isArray(this.notificacion?.solicitantes?.[0]?.representantes) && this.notificacion.solicitantes[0].representantes.length > 0) {
          const representante = this.notificacion.solicitantes[0].representantes[0];
          apoderado = representante.nombre + ' ' + representante.paterno + ' ' + (representante.materno || "").trim();
        }

        const datos = {
          compareciente_principal: nombre_completo_solicitante_principal,
          day: formatedDay,
          month: formatedMonth,
          year: yyyy,
          notificados: notificados,
          domicilio: domicilio_string,
          apoderado: apoderado,
          plaza: this.notificacion.domicilio.entidad,
          plural: false,
          fad: this.notificacion.fad
        }

        this.cartaKey += 1;
        this.datos = datos;
        this.cartaKey += 1;
      } catch (error) {
        console.log(error);
        this.$toast.error("Sucedió un error, intente más tarde", {
          timeout: 4000,
          hideProgressBar: false,
          position: "top-right",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          closeButton: "button",
          icon: true,
        });
      }
    },

    async generaCartaDeInstruccion() {
      await this.$nextTick();

      const htmlContent = this.$refs.cartaInstruccion.outerHTML;
      const style_pdf = `<style>
            .new-page {
              page-break-before: always;
            }
            .tabla {
                width: 100%;
                font-size: 14px;
                font-family: Arial, Helvetica, sans-serif;
                border-collapse: separate;
                margin-top: 10px;
            }
            .section {
                text-align: center;
                height: 40px;
            }
            .date {
                text-align: right;
                height: 40px;
            }
            .paragraph {
                padding-bottom: 2em;
            }
      </style>`
      let htmlToConvertPdf = `<!DOCTYPE html>
            <html>
            <head>
              <meta charset="utf-8">
              ${style_pdf}
            </head>
            <body>
            ${htmlContent}
            </body>
            </html>
            `;
      htmlToConvertPdf = htmlToConvertPdf.replace('//')
      const regex = /<body>\s*<div data-v-[a-z0-9]*="" hidden="hidden"><div data-v-[a-z0-9]*=""><table class="tabla">/;
      htmlToConvertPdf = htmlToConvertPdf.replace(regex, '<body><table class="tabla">');
      const regex2 = /<\/table><\/div><\/div>\s*<\/body>/;
      htmlToConvertPdf = htmlToConvertPdf.replace(regex2, '</table> </body>');
      var buffer = Buffer.from(htmlToConvertPdf);
      var buffer_str = buffer.toString("base64");

      const dataNotificacionHtml = {
        proceso_id: this.notificacion.id,
        cat_servicios_id: 2,
        html: buffer_str
      }
      let documentosCartaInstruccion = await FuncionesApi.generaKyc(dataNotificacionHtml);
      this.notificacion.cartaInstruccion = documentosCartaInstruccion[0];
      // this.$toast.success("La Carta de Instrucción fue regenerada exitosamente.", {
      //   timeout: 4000, //false,
      //   hideProgressBar: false,
      //   position: "top-right",
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: false,
      //   closeButton: "button",
      //   icon: true,
      // });

      this.$refs.table.refresh();
    },

    async generaDocumentoANotificar() {
      await this.$nextTick();

      const htmlContent = this.$refs.hojaFirmas.outerHTML;
      const style_pdf =
        '<style>.tabla{width:100%;font-size:14px;font-family:Arial,Helvetica,sans-serif;padding:50px}.section{text-align:center;height:40px}</style>';
      const htmlTemplate =
        `<!DOCTYPE html><html><head><meta charset="utf-8"><meta name="viewport" content="width=device-width, initial-scale=1.0">${style_pdf}</head><body>${htmlContent}</body></html>`;
      const regex = /data-v-[a-z0-9]*=""/g;

      // htmlToConvertPdf = htmlToConvertPdf.replace('//')
      // const regex = /<body>\s*<div data-v-.*?="" hidden="hidden"><div data-v-.*?="">/;
      // htmlToConvertPdf = htmlToConvertPdf.replace(regex, '<body>');
      // const regex2 = /<\/table><\/div><\/div>\s*<\/body>/;
      // htmlToConvertPdf = htmlToConvertPdf.replace(regex2, '</table> </body>');

      let htmlToConvertPdf = htmlTemplate
        .replace('hidden="hidden"', '')
        .replace(regex, '');

      const buffer_str = Buffer
        .from(htmlToConvertPdf)
        .toString("base64");
      const html_notificar = 'documentoanotificar' + buffer_str;
      const dataNotificacionHtml = {
        proceso_id: this.notificacion.id,
        cat_servicios_id: 2,
        html: html_notificar
      }

      const documentosANotificar = await FuncionesApi.generaKyc(dataNotificacionHtml);

      this.notificacion.cartaInstruccion = documentosANotificar[0];

      // this.$toast.success("El documento a notificar fue regenerado exitosamente.", {
      //   timeout: 4000, //false,
      //   hideProgressBar: false,
      //   position: "top-right",
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: false,
      //   closeButton: "button",
      //   icon: true,
      // });

      this.$refs.table.refresh();
    },

    async generaKycSolicitantes() {
      try {
        this.datosCompletos = true;
        await this.$nextTick();

        // css y plantilla html minificados, para editarlos usar una herramienta que lo estilice y despues volver a minificarlos
        const style_pdf =
          `<style>.maps{text-align:center;vertical-align:middle}.new-page{page-break-before:always}.logo{width:auto;height:100px;float:right}.tabla{width:100%;font-size:15px;font-family:Arial,Helvetica,sans-serif;border-collapse:separate;border-spacing:0 1em}.section{background-color:#16243c;color:#fff;text-align:center;height:40px;font-weight:bolder}.section-empty{text-align:center;font-weight:bolder;font-size:18px}.firstline{border:5px solid #fff}.title{font-size:12px;text-align:left;width:20%;font-weight:700}.select{text-align:left;width:5%}.circle{border-radius:100%;height:15px;width:15px;border:2px solid #16243c;margin-bottom:0}.filledcircle{border-radius:100%;height:15px;width:15px;border:2px solid #16243c;background-color:#16243c;margin-bottom:0}.verifycheck{border:2px solid #16243c;background-color:#e7eef5;text-align:center}.right-title{font-size:12px;text-align:right;font-weight:700}.user{text-align:left;font-size:12px;font-weight:lighter;border-bottom:solid 1px #000;background-color:#e7eef5}.leyenda{font-size:9px}</style>`;
        const htmlTemplate =
          `<!DOCTYPE html><html><head><meta charset="utf-8"><meta name="viewport" content="width=device-width, initial-scale=1.0">${style_pdf}</head><body>_contentPlaceholder</body></html>`;
        const regex = /data-v-[a-z0-9]*=""/g;
        // const regex2 = /<\/table><\/div><\/div>\s*<\/body>/;
        // const solicitantes = this.$refs.KycSolicitanteTemplate.children;
        const htmlKycs = [];

        const moralIndex = this.notificacion.solicitantes.findIndex(s => s.persona === "Moral");

        for (const solicitante of this.notificacion.solicitantes) {
          // if (moralIndex !== -1 && solicitante.persona === "Física") {
          //   continue;
          // }

          const solicitanteId = solicitante.id;
          const element = document.getElementById(solicitanteId);
          const htmlContent = element.outerHTML;
          // console.log(htmlContent);
          let htmlToConvertPdf = htmlTemplate
            .replace("_contentPlaceholder", htmlContent)
            .replace('hidden="hidden"', '')
            .replace(regex, '');
          const buffer_str = Buffer
            .from(htmlToConvertPdf)
            .toString("base64");
          const solicitanteToPush = {
            comparecientes_id: solicitanteId,
            html: buffer_str
          };
          htmlKycs.push(solicitanteToPush);
        }

        const dataNotificacionHtml = {
          proceso_id: this.notificacion.id,
          cat_servicios_id: 2,
          html: "kyc",
          comparecientes: htmlKycs
        };

        const kycsGenerados = await FuncionesApi.generaKyc(dataNotificacionHtml);

        console.log(kycsGenerados);

        const index = Array.from(this.notificacion.documentos).findIndex(d => d.id == kycsGenerados[0].id);

        if (index !== -1) {
          this.notificacion.documentos[index] = kycsGenerados[0];
        }

        this.$refs.table.refresh();
      } catch (error) {
        console.log(error);

        this.$toast.error("Sucedió un error, intente más tarde.", {
          timeout: 4000, //false,
          hideProgressBar: false,
          position: "top-right",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          closeButton: "button",
          icon: true,
        });
      }
    },
  }
}
</script>
