var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container" }, [
    _c("h4", { staticClass: "cardD" }, [_vm._v("Capítulos especiales")]),
    _c("div", { staticClass: "row mt-3" }, [
      _vm.constitutiva.tipo_societario != "S de RL"
        ? _c("div", { staticClass: "col-6" }, [
            _c("label", { attrs: { for: "" } }, [
              _vm._v("Reglas de control corporativo"),
            ]),
            _vm.constitutiva.roleStaff
              ? _c(
                  "div",
                  [
                    _c("b-form-checkbox", {
                      attrs: { disabled: _vm.constitutiva.disabledEspeciales },
                      model: {
                        value: _vm.constitutiva.reglas_control,
                        callback: function ($$v) {
                          _vm.$set(_vm.constitutiva, "reglas_control", $$v)
                        },
                        expression: "constitutiva.reglas_control",
                      },
                    }),
                  ],
                  1
                )
              : _c(
                  "div",
                  [
                    _c("b-form-checkbox", {
                      attrs: { disabled: _vm.constitutiva.disabled },
                      model: {
                        value: _vm.constitutiva.reglas_control,
                        callback: function ($$v) {
                          _vm.$set(_vm.constitutiva, "reglas_control", $$v)
                        },
                        expression: "constitutiva.reglas_control",
                      },
                    }),
                  ],
                  1
                ),
            _vm.constitutiva.reglas_control
              ? _c("label", { staticClass: "mt-2 leyenda" }, [
                  _vm._v(" La cotización básica no incluye esta opción. "),
                ])
              : _vm._e(),
          ])
        : _vm._e(),
      _c("div", { staticClass: "col-6" }, [
        _c("label", { attrs: { for: "" } }, [
          _vm._v("Reglas para asambleas telemáticas"),
        ]),
        _vm.constitutiva.roleStaff
          ? _c(
              "div",
              [
                _c("b-form-checkbox", {
                  attrs: { disabled: _vm.constitutiva.disabledEspeciales },
                  model: {
                    value: _vm.constitutiva.reglas_asambleas,
                    callback: function ($$v) {
                      _vm.$set(_vm.constitutiva, "reglas_asambleas", $$v)
                    },
                    expression: "constitutiva.reglas_asambleas",
                  },
                }),
              ],
              1
            )
          : _c(
              "div",
              [
                _c("b-form-checkbox", {
                  attrs: { disabled: _vm.constitutiva.disabled },
                  model: {
                    value: _vm.constitutiva.reglas_asambleas,
                    callback: function ($$v) {
                      _vm.$set(_vm.constitutiva, "reglas_asambleas", $$v)
                    },
                    expression: "constitutiva.reglas_asambleas",
                  },
                }),
              ],
              1
            ),
        _vm.constitutiva.reglas_asambleas
          ? _c("label", { staticClass: "mt-2 leyenda" }, [
              _vm._v(" La cotización básica no incluye esta opción. "),
            ])
          : _vm._e(),
      ]),
    ]),
    _c("div", { staticClass: "row mt-3" }, [
      _c("div", { staticClass: "col-6" }, [
        _c("label", { attrs: { for: "" } }, [
          _vm._v("Exclusión de accionistas"),
        ]),
        _vm.constitutiva.roleStaff
          ? _c(
              "div",
              [
                _c("b-form-checkbox", {
                  attrs: { disabled: _vm.constitutiva.disabledEspeciales },
                  model: {
                    value: _vm.constitutiva.exclusion_accionistas,
                    callback: function ($$v) {
                      _vm.$set(_vm.constitutiva, "exclusion_accionistas", $$v)
                    },
                    expression: "constitutiva.exclusion_accionistas",
                  },
                }),
              ],
              1
            )
          : _c(
              "div",
              [
                _c("b-form-checkbox", {
                  attrs: { disabled: _vm.constitutiva.disabled },
                  model: {
                    value: _vm.constitutiva.exclusion_accionistas,
                    callback: function ($$v) {
                      _vm.$set(_vm.constitutiva, "exclusion_accionistas", $$v)
                    },
                    expression: "constitutiva.exclusion_accionistas",
                  },
                }),
              ],
              1
            ),
        _vm.constitutiva.exclusion_accionistas
          ? _c("label", { staticClass: "mt-2 leyenda" }, [
              _vm._v(" La cotización básica no incluye esta opción. "),
            ])
          : _vm._e(),
      ]),
    ]),
    _c("div", { staticClass: "mt-4" }, [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-10" },
          [
            _c(
              "b-button",
              {
                staticClass: "btn-fill orange-btn bottom2",
                attrs: {
                  type: "submit",
                  size: "m",
                  disabled: _vm.constitutiva.disabledEspeciales,
                },
              },
              [_vm._v("Guardar")]
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-2" },
          [
            _c(
              "b-button",
              {
                staticClass: "btn-fill bottom2",
                attrs: {
                  variant: "success",
                  size: "m",
                  disabled: _vm.constitutiva.disabledEspeciales,
                },
                on: {
                  click: function ($event) {
                    return _vm.validar()
                  },
                },
              },
              [_vm._v("Validar")]
            ),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }