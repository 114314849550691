var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-md-6" }, [
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c("ValidationProvider", {
              attrs: {
                name: "estatus_migratorio",
                rules: "required",
                "custom-messages": {
                  required: "El estatus migratorio es obligatorio",
                },
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ errors }) {
                    return [
                      _c("label", { attrs: { for: "" } }, [
                        _vm._v("Estatus Migratorio"),
                      ]),
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.user.estatus_migratorio,
                              expression: "user.estatus_migratorio",
                            },
                          ],
                          staticClass: "custom-select",
                          attrs: { disabled: _vm.user.disabled },
                          on: {
                            change: function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.user,
                                "estatus_migratorio",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                          },
                        },
                        [
                          _c("option", { attrs: { value: "Mexicano" } }, [
                            _vm._v("Mexicano"),
                          ]),
                          _c(
                            "option",
                            { attrs: { value: "Residente Temporal" } },
                            [_vm._v("Residente Temporal")]
                          ),
                          _c(
                            "option",
                            { attrs: { value: "Residente Permanente" } },
                            [_vm._v("Residente Permanente")]
                          ),
                          _c("option", { attrs: { value: "Extranjero" } }, [
                            _vm._v("Extranjero"),
                          ]),
                        ]
                      ),
                      _c("span", { staticClass: "cardErrors" }, [
                        _vm._v(_vm._s(errors[0])),
                      ]),
                    ]
                  },
                },
              ]),
            }),
            _vm.user.estatus_migratorio === "Extranjero"
              ? _c(
                  "div",
                  { staticClass: "col-6" },
                  [
                    _c("base-input", {
                      attrs: {
                        type: "date",
                        label: "Fecha de ingreso al país",
                        placeholder: "Fecha de ingreso al país",
                        disabled: _vm.user.disabled,
                      },
                      model: {
                        value: _vm.user.fecha_ingreso_pais,
                        callback: function ($$v) {
                          _vm.$set(_vm.user, "fecha_ingreso_pais", $$v)
                        },
                        expression: "user.fecha_ingreso_pais",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
    ]),
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-md-4" },
        [
          _c("ValidationProvider", {
            attrs: {
              name: "firstName",
              rules: "required",
              "custom-messages": { required: "El nombre es obligatorio" },
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ errors }) {
                  return [
                    _c("base-input", {
                      attrs: {
                        type: "text",
                        label: "Nombre(s)",
                        placeholder: "Nombre(s)",
                        disabled: _vm.user.disabled,
                      },
                      model: {
                        value: _vm.user.nombre,
                        callback: function ($$v) {
                          _vm.$set(_vm.user, "nombre", $$v)
                        },
                        expression: "user.nombre",
                      },
                    }),
                    _c("span", { staticClass: "cardErrors" }, [
                      _vm._v(_vm._s(errors[0])),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-md-4" },
        [
          _c("ValidationProvider", {
            attrs: {
              name: "lastNameP",
              rules: "required",
              "custom-messages": {
                required: "El apellido paterno es obligatorio",
              },
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ errors }) {
                  return [
                    _c("base-input", {
                      attrs: {
                        type: "text",
                        label: "Apellido Paterno",
                        placeholder: "Apellido Paterno",
                        disabled: _vm.user.disabled,
                      },
                      model: {
                        value: _vm.user.paterno,
                        callback: function ($$v) {
                          _vm.$set(_vm.user, "paterno", $$v)
                        },
                        expression: "user.paterno",
                      },
                    }),
                    _c("span", { staticClass: "cardErrors" }, [
                      _vm._v(_vm._s(errors[0])),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-md-4" },
        [
          _c("ValidationProvider", {
            attrs: {
              name: "lastNameM",
              rules: _vm.isMaternoRequired,
              "custom-messages": {
                required: "El apellido materno es obligatorio",
              },
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ errors }) {
                  return [
                    _c("base-input", {
                      attrs: {
                        type: "text",
                        label: "Apellido Materno",
                        placeholder: "Apellido Materno",
                        disabled: _vm.user.disabled,
                      },
                      model: {
                        value: _vm.user.materno,
                        callback: function ($$v) {
                          _vm.$set(_vm.user, "materno", $$v)
                        },
                        expression: "user.materno",
                      },
                    }),
                    _c("span", { staticClass: "cardErrors" }, [
                      _vm._v(_vm._s(errors[0])),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-md-4" },
        [
          _c("ValidationProvider", {
            attrs: {
              name: "identificacion_tipo",
              rules: "required",
              "custom-messages": {
                required: "El tipo de identificación es obligatorio",
              },
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ errors }) {
                  return [
                    _c("label", [_vm._v("Identificación: Tipo")]),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.user.identificacion_tipo,
                            expression: "user.identificacion_tipo",
                          },
                        ],
                        staticClass: "custom-select mb-2",
                        attrs: { disabled: _vm.user.disabled },
                        on: {
                          change: [
                            function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.user,
                                "identificacion_tipo",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                            _vm.selectTipoId,
                          ],
                        },
                      },
                      _vm._l(_vm.optionsIdentificacionTipo, function (option) {
                        return _c("option", { key: option.value }, [
                          _vm._v(" " + _vm._s(option.text) + " "),
                        ])
                      }),
                      0
                    ),
                    _vm.user.identificacion_tipo === "Otro"
                      ? _c("base-input", {
                          attrs: {
                            type: "text",
                            label: "",
                            placeholder: "Tipo",
                            disabled: _vm.user.disabled,
                          },
                          model: {
                            value: _vm.user.identificacion_tipo_otro,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.user,
                                "identificacion_tipo_otro",
                                $$v
                              )
                            },
                            expression: "user.identificacion_tipo_otro",
                          },
                        })
                      : _vm._e(),
                    _c("span", { staticClass: "cardErrors" }, [
                      _vm._v(_vm._s(errors[0])),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-md-4" },
        [
          _c("ValidationProvider", {
            attrs: {
              name: "identificacion_autoridad",
              rules: _vm.isIdentificacionAutoridadRequired,
              "custom-messages": { required: "La autoridad es obligatoria" },
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ errors }) {
                  return [
                    _c("label", [_vm._v("Autoridad")]),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.user.identificacion_autoridad,
                            expression: "user.identificacion_autoridad",
                          },
                        ],
                        staticClass: "custom-select mb-2",
                        attrs: { disabled: _vm.user.disabled },
                        on: {
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.user,
                              "identificacion_autoridad",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                        },
                      },
                      _vm._l(
                        _vm.optionsIdentificacionAutoridad,
                        function (option) {
                          return _c("option", { key: option.value }, [
                            _vm._v(" " + _vm._s(option.text) + " "),
                          ])
                        }
                      ),
                      0
                    ),
                    _vm.user.identificacion_autoridad === "Otro"
                      ? _c("base-input", {
                          attrs: {
                            type: "text",
                            disabled: _vm.user.disabled,
                            label: "",
                            placeholder: "Autoridad",
                          },
                          model: {
                            value: _vm.user.identificacion_autoridad_otro,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.user,
                                "identificacion_autoridad_otro",
                                $$v
                              )
                            },
                            expression: "user.identificacion_autoridad_otro",
                          },
                        })
                      : _vm._e(),
                    _c("span", { staticClass: "cardErrors" }, [
                      _vm._v(_vm._s(errors[0])),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-md-4" },
        [
          _c("ValidationProvider", {
            attrs: {
              name: "identificacion_numero",
              rules: _vm.isIdentificacionNumeroRequired,
              "custom-messages": { required: "El número es obligatorio" },
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ errors }) {
                  return [
                    _c("base-input", {
                      attrs: {
                        type: "text",
                        label: "Número de Identificación",
                        placeholder: "Número de Identificación",
                        disabled: _vm.user.disabled,
                      },
                      model: {
                        value: _vm.user.identificacion_numero,
                        callback: function ($$v) {
                          _vm.$set(_vm.user, "identificacion_numero", $$v)
                        },
                        expression: "user.identificacion_numero",
                      },
                    }),
                    _c("span", { staticClass: "cardErrors" }, [
                      _vm._v(_vm._s(errors[0])),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ]),
    _vm.user.estatus_migratorio === "Extranjero"
      ? _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-md-6" },
            [
              _c("ValidationProvider", {
                attrs: {
                  name: "rfc",
                  rules: "required",
                  "custom-messages": { required: "El ID Tax es obligatorio" },
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function ({ errors }) {
                        return [
                          _c("base-input", {
                            attrs: {
                              disabled: _vm.user.disabled,
                              type: "text",
                              label: "ID Tax",
                              placeholder: "ID Tax",
                            },
                            model: {
                              value: _vm.user.rfc,
                              callback: function ($$v) {
                                _vm.$set(_vm.user, "rfc", $$v)
                              },
                              expression: "user.rfc",
                            },
                          }),
                          _c("span", { staticClass: "cardErrors" }, [
                            _vm._v(_vm._s(errors[0])),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2144641103
                ),
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-md-6" },
            [
              _c("ValidationProvider", {
                attrs: {
                  name: "curp",
                  rules: _vm.isCurpRequired,
                  "custom-messages": {
                    required: "El número de Identidad Nacional es obligatorio",
                  },
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function ({ errors }) {
                        return [
                          _c("base-input", {
                            attrs: {
                              disabled: _vm.user.disabled,
                              type: "text",
                              label:
                                "Número de Identidad Nacional, NSS o similar.",
                              placeholder:
                                "Número de Identidad Nacional, NSS o similar.",
                            },
                            model: {
                              value: _vm.user.curp,
                              callback: function ($$v) {
                                _vm.$set(_vm.user, "curp", $$v)
                              },
                              expression: "user.curp",
                            },
                          }),
                          _c("span", { staticClass: "cardErrors" }, [
                            _vm._v(_vm._s(errors[0])),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  1112852940
                ),
              }),
            ],
            1
          ),
        ])
      : _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-md-6" },
            [
              _c("ValidationProvider", {
                attrs: {
                  name: "rfc",
                  rules: "required|length:13",
                  "custom-messages": {
                    required: "El RFC es obligatorio",
                    length: "Debe tener 13 caracteres",
                  },
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ errors }) {
                      return [
                        _c("base-input", {
                          attrs: {
                            disabled: _vm.user.disabled,
                            type: "text",
                            label: "RFC",
                            placeholder: "RFC",
                          },
                          model: {
                            value: _vm.user.rfc,
                            callback: function ($$v) {
                              _vm.$set(_vm.user, "rfc", $$v)
                            },
                            expression: "user.rfc",
                          },
                        }),
                        _c("span", { staticClass: "cardErrors" }, [
                          _vm._v(_vm._s(errors[0])),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-md-6" },
            [
              _c("ValidationProvider", {
                attrs: {
                  name: "curp",
                  rules: _vm.isCurpRequired,
                  "custom-messages": {
                    required: "La CURP es obligatorio",
                    length: "Debe tener 18 caracteres",
                  },
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ errors }) {
                      return [
                        _c("base-input", {
                          attrs: {
                            disabled: _vm.user.disabled,
                            type: "text",
                            label: "CURP",
                            placeholder: "CURP",
                          },
                          model: {
                            value: _vm.user.curp,
                            callback: function ($$v) {
                              _vm.$set(_vm.user, "curp", $$v)
                            },
                            expression: "user.curp",
                          },
                        }),
                        _c("span", { staticClass: "cardErrors" }, [
                          _vm._v(_vm._s(errors[0])),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ]),
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-md-3" },
        [
          _c("ValidationProvider", {
            attrs: {
              name: "fecha_nacimiento",
              rules: _vm.isFechaNacimientoRequired,
              "custom-messages": {
                required: "La fecha de nacimiento es obligatoria",
              },
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ errors }) {
                  return [
                    _c("base-input", {
                      attrs: {
                        disabled: _vm.user.disabled,
                        type: "date",
                        label: "Fecha de nacimiento",
                        placeholder: "Fecha de nacimiento",
                      },
                      model: {
                        value: _vm.user.fecha_nacimiento,
                        callback: function ($$v) {
                          _vm.$set(_vm.user, "fecha_nacimiento", $$v)
                        },
                        expression: "user.fecha_nacimiento",
                      },
                    }),
                    _c("span", { staticClass: "cardErrors" }, [
                      _vm._v(_vm._s(errors[0])),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      !_vm.isNacionalidadHidden
        ? _c(
            "div",
            { staticClass: "col-md-5" },
            [
              _c("ValidationProvider", {
                attrs: {
                  name: "nacionalidad",
                  rules: "required",
                  "custom-messages": {
                    required: "La nacionalidad es obligatoria",
                  },
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function ({ errors }) {
                        return [
                          _c("label", [_vm._v("Nacionalidades")]),
                          _c("v-select", {
                            attrs: {
                              disabled: _vm.user.disabled,
                              multiple: "",
                              label: "nacionalidad",
                              options: _vm.optionsNacionalidades,
                            },
                            model: {
                              value: _vm.user.nacionalidades,
                              callback: function ($$v) {
                                _vm.$set(_vm.user, "nacionalidades", $$v)
                              },
                              expression: "user.nacionalidades",
                            },
                          }),
                          _c("span", { staticClass: "cardErrors" }, [
                            _vm._v(_vm._s(errors[0])),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  1674113424
                ),
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "col-md-4" },
        [
          _c("ValidationProvider", {
            attrs: {
              name: "sexo",
              rules: "required",
              "custom-messages": { required: "El género es obligatorio" },
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ errors }) {
                  return [
                    _c("label", { attrs: { for: "" } }, [_vm._v("Género")]),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.user.sexo,
                            expression: "user.sexo",
                          },
                        ],
                        staticClass: "custom-select",
                        attrs: { disabled: _vm.user.disabled },
                        on: {
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.user,
                              "sexo",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                        },
                      },
                      [
                        _c("option", { attrs: { value: "Femenino" } }, [
                          _vm._v("Femenino"),
                        ]),
                        _c("option", { attrs: { value: "Masculino" } }, [
                          _vm._v("Masculino"),
                        ]),
                        _c(
                          "option",
                          { attrs: { value: "Prefiero no decir" } },
                          [_vm._v("Prefiero no decir")]
                        ),
                      ]
                    ),
                    _c("span", { staticClass: "cardErrors" }, [
                      _vm._v(_vm._s(errors[0])),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-md-4" },
        [
          _c("ValidationProvider", {
            attrs: {
              name: "lugar_nacim_pais",
              rules: _vm.isLugarNacimientoPaisRequired,
              "custom-messages": { required: "El país es obligatorio" },
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ errors }) {
                  return [
                    _c("label", [_vm._v("Lugar de nacimiento: País")]),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.selectCountry,
                            expression: "selectCountry",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { disabled: _vm.user.disabled },
                        on: {
                          change: [
                            function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.selectCountry = $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            },
                            _vm.fetchStatesByCountry,
                          ],
                        },
                      },
                      _vm._l(_vm.countries, function (option) {
                        return _c("option", { key: option.id }, [
                          _vm._v(" " + _vm._s(option.name) + " "),
                        ])
                      }),
                      0
                    ),
                    _c("span", { staticClass: "cardErrors" }, [
                      _vm._v(_vm._s(errors[0])),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-md-4" },
        [
          _c("ValidationProvider", {
            attrs: {
              name: "lugar_nacim_estado",
              rules: _vm.isLugarNacimientoEstadoRequired,
              "custom-messages": { required: "El estado es obligatorio" },
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ errors }) {
                  return [
                    _c("label", [_vm._v("Lugar de nacimiento: Estado")]),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.selectState,
                            expression: "selectState",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { disabled: _vm.user.disabled },
                        on: {
                          change: [
                            function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.selectState = $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            },
                            _vm.fetchCitiesByState,
                          ],
                        },
                      },
                      [
                        _vm.isEmptyStates
                          ? _c(
                              "option",
                              { attrs: { value: "noStates", selected: "" } },
                              [_vm._v(" " + _vm._s(_vm.noStatesText) + " ")]
                            )
                          : _vm._e(),
                        _vm._l(_vm.states, function (option) {
                          return _c("option", { key: option.id }, [
                            _vm._v(" " + _vm._s(option.name) + " "),
                          ])
                        }),
                      ],
                      2
                    ),
                    _c("span", { staticClass: "cardErrors" }, [
                      _vm._v(_vm._s(errors[0])),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-md-4" },
        [
          _c("ValidationProvider", {
            attrs: {
              name: "lugar_nacim_ciudad",
              rules: _vm.isLugarNacimientoCiudadRequired,
              "custom-messages": {
                required: "La ciudad o municipio de nacimiento es obligatoria.",
              },
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ errors }) {
                  return [
                    _c("label", [_vm._v("Municipio / Alcaldía")]),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.selectCity,
                            expression: "selectCity",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { disabled: _vm.user.disabled },
                        on: {
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.selectCity = $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          },
                        },
                      },
                      [
                        _vm.isEmptyCities
                          ? _c(
                              "option",
                              { attrs: { value: "noCities", selected: "" } },
                              [_vm._v(" " + _vm._s(_vm.noCitiesText) + " ")]
                            )
                          : _vm._e(),
                        _vm._l(_vm.cities, function (option) {
                          return _c("option", { key: option.id }, [
                            _vm._v(" " + _vm._s(option.name) + " "),
                          ])
                        }),
                      ],
                      2
                    ),
                    _c("span", { staticClass: "cardErrors" }, [
                      _vm._v(_vm._s(errors[0])),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-md-4" },
        [
          _c("ValidationProvider", {
            attrs: {
              name: "ocupacion",
              rules: _vm.isOcupacionRequired,
              "custom-messages": { required: "La ocupación es obligatoria" },
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ errors }) {
                  return [
                    _c("base-input", {
                      attrs: {
                        disabled: _vm.user.disabled,
                        type: "text",
                        label: "Ocupación / Actividad / Profesión",
                        placeholder: "Ocupación",
                      },
                      model: {
                        value: _vm.user.ocupacion,
                        callback: function ($$v) {
                          _vm.$set(_vm.user, "ocupacion", $$v)
                        },
                        expression: "user.ocupacion",
                      },
                    }),
                    _c("span", { staticClass: "cardErrors" }, [
                      _vm._v(_vm._s(errors[0])),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      !_vm.isGiroNegociosHidden
        ? _c(
            "div",
            { staticClass: "col-md-4" },
            [
              _c("ValidationProvider", {
                attrs: {
                  name: "giro_negocios",
                  rules: _vm.isGiroNegociosRequired,
                  "custom-messages": {
                    required: "El giro de negocios es obligatorio",
                  },
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function ({ errors }) {
                        return [
                          _c("base-input", {
                            attrs: {
                              disabled: _vm.user.disabled,
                              type: "text",
                              label: "Giro de negocios",
                              placeholder: "Giro de negocios",
                            },
                            model: {
                              value: _vm.user.giro_negocios,
                              callback: function ($$v) {
                                _vm.$set(_vm.user, "giro_negocios", $$v)
                              },
                              expression: "user.giro_negocios",
                            },
                          }),
                          _c("span", { staticClass: "cardErrors" }, [
                            _vm._v(_vm._s(errors[0])),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3854256161
                ),
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "col-md-4" },
        [
          _c("ValidationProvider", {
            attrs: {
              name: "estado_civil",
              rules: _vm.isEstadoCivilRequired,
              "custom-messages": { required: "El estado civil es obligatorio" },
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ errors }) {
                  return [
                    _c("label", { attrs: { for: "" } }, [
                      _vm._v("Estado civil"),
                    ]),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.user.estado_civil,
                            expression: "user.estado_civil",
                          },
                        ],
                        staticClass: "custom-select",
                        attrs: { disabled: _vm.user.disabled },
                        on: {
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.user,
                              "estado_civil",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                        },
                      },
                      [
                        _c("option", { attrs: { value: "Soltero (a)" } }, [
                          _vm._v("Soltero (a)"),
                        ]),
                        _c("option", { attrs: { value: "Casado (a)" } }, [
                          _vm._v("Casado (a)"),
                        ]),
                        _c("option", { attrs: { value: "Unión Libre" } }, [
                          _vm._v("Unión Libre"),
                        ]),
                        _c(
                          "option",
                          { attrs: { value: "Sociedad de Convivencia" } },
                          [_vm._v("Sociedad de Convivencia")]
                        ),
                        _c("option", { attrs: { value: "Viudo (a)" } }, [
                          _vm._v("Viudo (a)"),
                        ]),
                      ]
                    ),
                    _c("span", { staticClass: "cardErrors" }, [
                      _vm._v(_vm._s(errors[0])),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ]),
    _vm.isFromConstitutiva
      ? _c("div", { staticClass: "row" }, [
          !_vm.isAliasHidden
            ? _c(
                "div",
                { staticClass: "col-md-4" },
                [
                  _c("ValidationProvider", {
                    attrs: {
                      name: "alias",
                      rules: _vm.isAliasRequired,
                      "custom-messages": {
                        required: "El alias es obligatorio",
                      },
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function ({ errors }) {
                            return [
                              _c("base-input", {
                                attrs: {
                                  disabled: _vm.user.disabled,
                                  type: "text",
                                  label: "Alias",
                                  placeholder: "Alias",
                                },
                                model: {
                                  value: _vm.user.alias,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.user, "alias", $$v)
                                  },
                                  expression: "user.alias",
                                },
                              }),
                              _c("span", { staticClass: "cardErrors" }, [
                                _vm._v(_vm._s(errors[0])),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1618358574
                    ),
                  }),
                ],
                1
              )
            : _vm._e(),
          !_vm.isRegimenPatrimonialHidden
            ? _c(
                "div",
                { staticClass: "col-md-4" },
                [
                  _c("ValidationProvider", {
                    attrs: {
                      name: "regimen_patrimonial",
                      rules: "required",
                      "custom-messages": {
                        required: "El régimen patrimonial es obligatorio",
                      },
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function ({ errors }) {
                            return [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v("Régimen patrimonial"),
                              ]),
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.user.regimen_patrimonial,
                                      expression: "user.regimen_patrimonial",
                                    },
                                  ],
                                  staticClass: "custom-select",
                                  attrs: { disabled: _vm.user.disabled },
                                  on: {
                                    change: function ($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call(
                                          $event.target.options,
                                          function (o) {
                                            return o.selected
                                          }
                                        )
                                        .map(function (o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.$set(
                                        _vm.user,
                                        "regimen_patrimonial",
                                        $event.target.multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      )
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "option",
                                    { attrs: { value: "Sociedad Conyugal" } },
                                    [_vm._v("Sociedad Conyugal")]
                                  ),
                                  _c(
                                    "option",
                                    {
                                      attrs: { value: "Separación de Bienes" },
                                    },
                                    [_vm._v("Separación de Bienes")]
                                  ),
                                  _c(
                                    "option",
                                    { attrs: { value: "No Aplica" } },
                                    [_vm._v("No Aplica")]
                                  ),
                                ]
                              ),
                              _c("span", { staticClass: "cardErrors" }, [
                                _vm._v(_vm._s(errors[0])),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2610930652
                    ),
                  }),
                ],
                1
              )
            : _vm._e(),
          !_vm.isIdentificacionConyugeHidden
            ? _c("div", { staticClass: "col-md-4" }, [
                _vm.user.regimen_patrimonial === "No Aplica"
                  ? _c(
                      "div",
                      [
                        _c("base-input", {
                          attrs: {
                            disabled: true,
                            type: "text",
                            label: "Nombre del cónyuge",
                            placeholder: "Nombre del cónyuge",
                          },
                          model: {
                            value: _vm.user.identificacion_conyuge,
                            callback: function ($$v) {
                              _vm.$set(_vm.user, "identificacion_conyuge", $$v)
                            },
                            expression: "user.identificacion_conyuge",
                          },
                        }),
                      ],
                      1
                    )
                  : _c(
                      "div",
                      [
                        _c("ValidationProvider", {
                          attrs: {
                            name: "identificacion_conyuge",
                            rules: "required",
                            "custom-messages": {
                              required: "El nombre del cónyuge es obligatorio",
                            },
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function ({ errors }) {
                                  return [
                                    _c("base-input", {
                                      attrs: {
                                        disabled: _vm.user.disabled,
                                        type: "text",
                                        label: "Nombre del cónyuge",
                                        placeholder: "Nombre del cónyuge",
                                      },
                                      model: {
                                        value: _vm.user.identificacion_conyuge,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.user,
                                            "identificacion_conyuge",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "user.identificacion_conyuge",
                                      },
                                    }),
                                    _c("span", { staticClass: "cardErrors" }, [
                                      _vm._v(_vm._s(errors[0])),
                                    ]),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            230137238
                          ),
                        }),
                      ],
                      1
                    ),
              ])
            : _vm._e(),
        ])
      : _vm._e(),
    _c(
      "div",
      {},
      [
        _c(
          "b-button",
          {
            staticClass: "btn-fill orange-btn bottom",
            attrs: { type: "submit", size: "lg", disabled: _vm.user.disabled },
          },
          [_vm._v("Guardar")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }