var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-md-4" },
        [
          _c("ValidationProvider", {
            attrs: {
              name: "firstName",
              rules: "required",
              "custom-messages": { required: "El nombre es obligatorio" },
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ errors }) {
                  return [
                    _c("base-input", {
                      attrs: {
                        type: "text",
                        label: "Nombre(s)",
                        placeholder: "Nombre(s)",
                      },
                      model: {
                        value: _vm.currentRepresentante.nombre,
                        callback: function ($$v) {
                          _vm.$set(_vm.currentRepresentante, "nombre", $$v)
                        },
                        expression: "currentRepresentante.nombre",
                      },
                    }),
                    _c("span", { staticClass: "cardErrors" }, [
                      _vm._v(_vm._s(errors[0])),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-md-4" },
        [
          _c("ValidationProvider", {
            attrs: {
              name: "lastNameP",
              rules: "required",
              "custom-messages": {
                required: "El apellido paterno es obligatorio",
              },
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ errors }) {
                  return [
                    _c("base-input", {
                      attrs: {
                        type: "text",
                        label: "Apellido Paterno",
                        placeholder: "Apellido Paterno",
                      },
                      model: {
                        value: _vm.currentRepresentante.paterno,
                        callback: function ($$v) {
                          _vm.$set(_vm.currentRepresentante, "paterno", $$v)
                        },
                        expression: "currentRepresentante.paterno",
                      },
                    }),
                    _c("span", { staticClass: "cardErrors" }, [
                      _vm._v(_vm._s(errors[0])),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-md-4" },
        [
          _c("base-input", {
            attrs: {
              type: "text",
              label: "Apellido Materno",
              placeholder: "Apellido Materno",
            },
            model: {
              value: _vm.currentRepresentante.materno,
              callback: function ($$v) {
                _vm.$set(_vm.currentRepresentante, "materno", $$v)
              },
              expression: "currentRepresentante.materno",
            },
          }),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-md-5" },
        [
          _c("ValidationProvider", {
            attrs: {
              name: "email",
              rules: "required|email",
              "custom-messages": {
                required: "El email es obligatorio",
                email: "Debe ser un email válido",
              },
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ errors }) {
                  return [
                    _c("base-input", {
                      attrs: {
                        type: "email",
                        label: "Email",
                        placeholder: "Email",
                      },
                      model: {
                        value: _vm.currentRepresentante.email,
                        callback: function ($$v) {
                          _vm.$set(_vm.currentRepresentante, "email", $$v)
                        },
                        expression: "currentRepresentante.email",
                      },
                    }),
                    _c("span", { staticClass: "cardErrors" }, [
                      _vm._v(_vm._s(errors[0])),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-md-3" },
        [
          _c("ValidationProvider", {
            attrs: {
              name: "rfc",
              rules: "required|length:13",
              "custom-messages": {
                required: "El RFC es obligatorio",
                length: "Debe tener 13 caracteres",
              },
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ errors }) {
                  return [
                    _c("base-input", {
                      attrs: { type: "text", label: "RFC", placeholder: "RFC" },
                      model: {
                        value: _vm.currentRepresentante.rfc,
                        callback: function ($$v) {
                          _vm.$set(_vm.currentRepresentante, "rfc", $$v)
                        },
                        expression: "currentRepresentante.rfc",
                      },
                    }),
                    _c("span", { staticClass: "cardErrors" }, [
                      _vm._v(_vm._s(errors[0])),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-md-4" },
        [
          _c("ValidationProvider", {
            attrs: {
              name: "curp",
              rules: "required|length:18",
              "custom-messages": {
                required: "La CURP es obligatorio",
                length: "Debe tener 18 caracteres",
              },
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ errors }) {
                  return [
                    _c("base-input", {
                      attrs: {
                        type: "text",
                        label: "CURP",
                        placeholder: "CURP",
                      },
                      model: {
                        value: _vm.currentRepresentante.curp,
                        callback: function ($$v) {
                          _vm.$set(_vm.currentRepresentante, "curp", $$v)
                        },
                        expression: "currentRepresentante.curp",
                      },
                    }),
                    _c("span", { staticClass: "cardErrors" }, [
                      _vm._v(_vm._s(errors[0])),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-md-3" },
        [
          _c("ValidationProvider", {
            attrs: {
              name: "fecha_nacimiento",
              rules: "required",
              "custom-messages": {
                required: "La fecha de nacimiento es obligatoria",
              },
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ errors }) {
                  return [
                    _c("base-input", {
                      attrs: {
                        type: "date",
                        label: "Fecha de nacimiento",
                        placeholder: "Fecha de nacimiento",
                      },
                      model: {
                        value: _vm.currentRepresentante.fecha_nacimiento,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.currentRepresentante,
                            "fecha_nacimiento",
                            $$v
                          )
                        },
                        expression: "currentRepresentante.fecha_nacimiento",
                      },
                    }),
                    _c("span", { staticClass: "cardErrors" }, [
                      _vm._v(_vm._s(errors[0])),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-md-5" },
        [
          _c("ValidationProvider", {
            attrs: {
              name: "nacionalidad",
              rules: "required",
              "custom-messages": {
                required: "La nacionalidad es obligatoria.",
              },
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ errors }) {
                  return [
                    _c("label", [_vm._v("Nacionalidad")]),
                    _c("v-select", {
                      attrs: {
                        multiple: "",
                        label: "nacionalidad",
                        options: _vm.optionsNacionalidades,
                      },
                      model: {
                        value: _vm.currentRepresentante.nacionalidades,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.currentRepresentante,
                            "nacionalidades",
                            $$v
                          )
                        },
                        expression: "currentRepresentante.nacionalidades",
                      },
                    }),
                    _c("span", { staticClass: "cardErrors" }, [
                      _vm._v(_vm._s(errors[0])),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-md-4" },
        [
          _c("ValidationProvider", {
            attrs: {
              name: "lugar_nacim_pais",
              rules: "required",
              "custom-messages": { required: "El país es obligatorio" },
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ errors }) {
                  return [
                    _c("label", [_vm._v("Lugar de nacimiento: País")]),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.selectCountry,
                            expression: "selectCountry",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { disabled: _vm.currentRepresentante.disabled },
                        on: {
                          change: [
                            function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.selectCountry = $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            },
                            _vm.fetchStatesByCountry,
                          ],
                        },
                      },
                      _vm._l(_vm.countries, function (option) {
                        return _c("option", { key: option.id }, [
                          _vm._v(" " + _vm._s(option.name) + " "),
                        ])
                      }),
                      0
                    ),
                    _c("span", { staticClass: "cardErrors" }, [
                      _vm._v(_vm._s(errors[0])),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-md-4" },
        [
          _c("ValidationProvider", {
            attrs: {
              name: "lugar_nacim_estado",
              rules: "required",
              "custom-messages": {
                required: "El estado es obligatorio",
              },
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ errors }) {
                  return [
                    _c("label", [_vm._v("Lugar de nacimiento: Estado")]),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.selectState,
                            expression: "selectState",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { disabled: _vm.currentRepresentante.disabled },
                        on: {
                          change: [
                            function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.selectState = $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            },
                            _vm.fetchCitiesByState,
                          ],
                        },
                      },
                      [
                        _vm.isEmptyStates
                          ? _c(
                              "option",
                              { attrs: { value: "noStates", selected: "" } },
                              [_vm._v(" " + _vm._s(_vm.noStatesText) + " ")]
                            )
                          : _vm._e(),
                        _vm._l(_vm.states, function (option) {
                          return _c("option", { key: option.id }, [
                            _vm._v(" " + _vm._s(option.name) + " "),
                          ])
                        }),
                      ],
                      2
                    ),
                    _c("span", { staticClass: "cardErrors" }, [
                      _vm._v(_vm._s(errors[0])),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-md-4" },
        [
          _c("ValidationProvider", {
            attrs: {
              name: "lugar_nacim_ciudad",
              rules: "required",
              "custom-messages": {
                required: "La ciudad de nacimiento es obligatoria",
              },
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ errors }) {
                  return [
                    _c("label", [
                      _vm._v("Lugar de nacimiento: Municipio / Alcaldía"),
                    ]),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.selectCity,
                            expression: "selectCity",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { disabled: _vm.currentRepresentante.disabled },
                        on: {
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.selectCity = $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          },
                        },
                      },
                      [
                        _vm.isEmptyCities
                          ? _c(
                              "option",
                              { attrs: { value: "noCities", selected: "" } },
                              [_vm._v(" " + _vm._s(_vm.noCitiesText) + " ")]
                            )
                          : _vm._e(),
                        _vm._l(_vm.cities, function (option) {
                          return _c("option", { key: option.id }, [
                            _vm._v(" " + _vm._s(option.name) + " "),
                          ])
                        }),
                      ],
                      2
                    ),
                    _c("span", { staticClass: "cardErrors" }, [
                      _vm._v(_vm._s(errors[0])),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-md-6" },
        [
          _c("ValidationProvider", {
            attrs: {
              name: "ocupacion",
              rules: "required",
              "custom-messages": {
                required: "La ocupación es obligatoria",
              },
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ errors }) {
                  return [
                    _c("base-input", {
                      attrs: {
                        type: "text",
                        label: "Ocupación / Actividad / Profesión",
                        placeholder: "Ocupación",
                      },
                      model: {
                        value: _vm.currentRepresentante.ocupacion,
                        callback: function ($$v) {
                          _vm.$set(_vm.currentRepresentante, "ocupacion", $$v)
                        },
                        expression: "currentRepresentante.ocupacion",
                      },
                    }),
                    _c("span", { staticClass: "cardErrors" }, [
                      _vm._v(_vm._s(errors[0])),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-md-6" },
        [
          _c("ValidationProvider", {
            attrs: {
              name: "estado_civil",
              rules: "required",
              "custom-messages": {
                required: "El estado civil es obligatorio",
              },
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ errors }) {
                  return [
                    _c("label", { attrs: { for: "" } }, [
                      _vm._v("Estado civil"),
                    ]),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.currentRepresentante.estado_civil,
                            expression: "currentRepresentante.estado_civil",
                          },
                        ],
                        staticClass: "custom-select",
                        on: {
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.currentRepresentante,
                              "estado_civil",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                        },
                      },
                      [
                        _c("option", { attrs: { value: "Soltero (a)" } }, [
                          _vm._v("Soltero (a)"),
                        ]),
                        _c("option", { attrs: { value: "Casado (a)" } }, [
                          _vm._v("Casado (a)"),
                        ]),
                        _c("option", { attrs: { value: "Unión Libre" } }, [
                          _vm._v("Unión Libre"),
                        ]),
                        _c(
                          "option",
                          { attrs: { value: "Sociedad de Convivencia" } },
                          [_vm._v("Sociedad de Convivencia")]
                        ),
                        _c("option", { attrs: { value: "Viudo (a)" } }, [
                          _vm._v("Viudo (a)"),
                        ]),
                      ]
                    ),
                    _c("span", { staticClass: "cardErrors" }, [
                      _vm._v(_vm._s(errors[0])),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-md-4" },
        [
          _c("ValidationProvider", {
            attrs: {
              name: "identificacion_tipo",
              rules: "required",
              "custom-messages": {
                required: "El tipo de identificación es obligatorio",
              },
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ errors }) {
                  return [
                    _c("label", [_vm._v("Identificación: Tipo")]),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.currentRepresentante.identificacion_tipo,
                            expression:
                              "currentRepresentante.identificacion_tipo",
                          },
                        ],
                        staticClass: "custom-select mb-2",
                        attrs: { disabled: _vm.currentRepresentante.disabled },
                        on: {
                          change: [
                            function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.currentRepresentante,
                                "identificacion_tipo",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                            _vm.selectTipoId,
                          ],
                        },
                      },
                      _vm._l(_vm.optionsIdentificacionTipo, function (option) {
                        return _c("option", { key: option.value }, [
                          _vm._v(" " + _vm._s(option.text) + " "),
                        ])
                      }),
                      0
                    ),
                    _vm.currentRepresentante.identificacion_tipo === "Otro"
                      ? _c("base-input", {
                          attrs: {
                            type: "text",
                            label: "",
                            placeholder: "Tipo",
                            disabled: _vm.currentRepresentante.disabled,
                          },
                          model: {
                            value:
                              _vm.currentRepresentante.identificacion_tipo_otro,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.currentRepresentante,
                                "identificacion_tipo_otro",
                                $$v
                              )
                            },
                            expression:
                              "currentRepresentante.identificacion_tipo_otro",
                          },
                        })
                      : _vm._e(),
                    _c("span", { staticClass: "cardErrors" }, [
                      _vm._v(_vm._s(errors[0])),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-md-4" },
        [
          _c("ValidationProvider", {
            attrs: {
              name: "identificacion_autoridad",
              rules: "required",
              "custom-messages": { required: "La autoridad es obligatoria" },
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ errors }) {
                  return [
                    _c("label", [_vm._v("Autoridad")]),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value:
                              _vm.currentRepresentante.identificacion_autoridad,
                            expression:
                              "currentRepresentante.identificacion_autoridad",
                          },
                        ],
                        staticClass: "custom-select mb-2",
                        attrs: { disabled: _vm.currentRepresentante.disabled },
                        on: {
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.currentRepresentante,
                              "identificacion_autoridad",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                        },
                      },
                      _vm._l(
                        _vm.optionsIdentificacionAutoridad,
                        function (option) {
                          return _c("option", { key: option.value }, [
                            _vm._v(" " + _vm._s(option.text) + " "),
                          ])
                        }
                      ),
                      0
                    ),
                    _vm.currentRepresentante.identificacion_autoridad === "Otro"
                      ? _c("base-input", {
                          attrs: {
                            type: "text",
                            disabled: _vm.user.disabled,
                            label: "",
                            placeholder: "Autoridad",
                          },
                          model: {
                            value:
                              _vm.currentRepresentante
                                .identificacion_autoridad_otro,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.currentRepresentante,
                                "identificacion_autoridad_otro",
                                $$v
                              )
                            },
                            expression:
                              "currentRepresentante.identificacion_autoridad_otro",
                          },
                        })
                      : _vm._e(),
                    _c("span", { staticClass: "cardErrors" }, [
                      _vm._v(_vm._s(errors[0])),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-md-4" },
        [
          _c("ValidationProvider", {
            attrs: {
              name: "identificacion_numero",
              rules: "required",
              "custom-messages": {
                required: "El número es obligatorio",
              },
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ errors }) {
                  return [
                    _c("base-input", {
                      attrs: {
                        type: "text",
                        label: "Número de identificación",
                        placeholder: "Número",
                      },
                      model: {
                        value: _vm.currentRepresentante.identificacion_numero,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.currentRepresentante,
                            "identificacion_numero",
                            $$v
                          )
                        },
                        expression:
                          "currentRepresentante.identificacion_numero",
                      },
                    }),
                    _c("span", { staticClass: "cardErrors" }, [
                      _vm._v(_vm._s(errors[0])),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ]),
    _vm.currentRepresentante.personaARepresentar === "Moral"
      ? _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-md-3" },
            [
              _c("label", { attrs: { for: "mismoConstitutivaInput" } }, [
                _vm._v("Mismo que constitutiva"),
              ]),
              _c("b-form-checkbox", {
                attrs: { id: "mismoConstitutivaInput" },
                on: {
                  change: function ($event) {
                    return _vm.mismaDataConstitutiva(
                      _vm.currentRepresentante.mismo_constitutiva
                    )
                  },
                },
                model: {
                  value: _vm.currentRepresentante.mismo_constitutiva,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.currentRepresentante,
                      "mismo_constitutiva",
                      $$v
                    )
                  },
                  expression: "currentRepresentante.mismo_constitutiva",
                },
              }),
            ],
            1
          ),
        ])
      : _vm._e(),
    _c("h4", { staticClass: "cardD" }, [_vm._v(" Datos del poder ")]),
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-md-6" },
        [
          _c("ValidationProvider", {
            attrs: {
              name: "instrumento_numero",
              rules: "required",
              "custom-messages": {
                required: "El número de instrumento es obligatorio",
              },
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ errors }) {
                  return [
                    _c("base-input", {
                      attrs: {
                        type: "text",
                        label: "Número de Instrumento público",
                        placeholder: "Número",
                      },
                      model: {
                        value: _vm.currentRepresentante.instrumento_numero,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.currentRepresentante,
                            "instrumento_numero",
                            $$v
                          )
                        },
                        expression: "currentRepresentante.instrumento_numero",
                      },
                    }),
                    _c("span", { staticClass: "cardErrors" }, [
                      _vm._v(_vm._s(errors[0])),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-md-6" },
        [
          _c("ValidationProvider", {
            attrs: {
              name: "instrumento_numero_fecha",
              rules: "required",
              "custom-messages": {
                required: "La fecha es obligatoria",
              },
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ errors }) {
                  return [
                    _c("base-input", {
                      attrs: {
                        type: "date",
                        label: "De Fecha",
                        placeholder: "De Fecha",
                      },
                      model: {
                        value:
                          _vm.currentRepresentante.instrumento_numero_fecha,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.currentRepresentante,
                            "instrumento_numero_fecha",
                            $$v
                          )
                        },
                        expression:
                          "currentRepresentante.instrumento_numero_fecha",
                      },
                    }),
                    _c("span", { staticClass: "cardErrors" }, [
                      _vm._v(_vm._s(errors[0])),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-md-2" },
        [
          _c("ValidationProvider", {
            attrs: {
              name: "instrumento_fedatario",
              rules: "required",
              "custom-messages": {
                required: "El tipo de fedatario es obligatorio",
              },
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ errors }) {
                  return [
                    _c("label", [_vm._v("Tipo de Fedatario")]),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value:
                              _vm.currentRepresentante.instrumento_fedatario,
                            expression:
                              "currentRepresentante.instrumento_fedatario",
                          },
                        ],
                        staticClass: "custom-select",
                        on: {
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.currentRepresentante,
                              "instrumento_fedatario",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                        },
                      },
                      [
                        _c("option", { attrs: { value: "Notario" } }, [
                          _vm._v("Notario"),
                        ]),
                        _c("option", { attrs: { value: "Corredor Público" } }, [
                          _vm._v("Corredor Público"),
                        ]),
                      ]
                    ),
                    _c("span", { staticClass: "cardErrors" }, [
                      _vm._v(_vm._s(errors[0])),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-md-4" },
        [
          _c("ValidationProvider", {
            attrs: {
              name: "instrumento_fedatario_nombre",
              rules: "required",
              "custom-messages": {
                required: "El nombre del fedatario es obligatorio",
              },
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ errors }) {
                  return [
                    _c("base-input", {
                      attrs: {
                        type: "text",
                        label: "Nombre de Fedatario",
                        placeholder: "Fedatario",
                      },
                      model: {
                        value:
                          _vm.currentRepresentante.instrumento_fedatario_nombre,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.currentRepresentante,
                            "instrumento_fedatario_nombre",
                            $$v
                          )
                        },
                        expression:
                          "currentRepresentante.instrumento_fedatario_nombre",
                      },
                    }),
                    _c("span", { staticClass: "cardErrors" }, [
                      _vm._v(_vm._s(errors[0])),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-md-3" },
        [
          _c("ValidationProvider", {
            attrs: {
              name: "instrumento_fedatario_numero",
              rules: "required",
              "custom-messages": {
                required: "El número es obligatorio",
              },
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ errors }) {
                  return [
                    _c("base-input", {
                      attrs: {
                        type: "text",
                        label: "Número",
                        placeholder: "Número",
                      },
                      model: {
                        value:
                          _vm.currentRepresentante.instrumento_fedatario_numero,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.currentRepresentante,
                            "instrumento_fedatario_numero",
                            $$v
                          )
                        },
                        expression:
                          "currentRepresentante.instrumento_fedatario_numero",
                      },
                    }),
                    _c("span", { staticClass: "cardErrors" }, [
                      _vm._v(_vm._s(errors[0])),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-md-3" },
        [
          _c("ValidationProvider", {
            attrs: {
              name: "instrumento_fedatario_estado",
              rules: "required",
              "custom-messages": {
                required: "El estado es obligatorio",
              },
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ errors }) {
                  return [
                    _c("label", [_vm._v("Del Estado de")]),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value:
                              _vm.currentRepresentante
                                .instrumento_fedatario_estado,
                            expression:
                              "currentRepresentante.instrumento_fedatario_estado",
                          },
                        ],
                        staticClass: "custom-select",
                        on: {
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.currentRepresentante,
                              "instrumento_fedatario_estado",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                        },
                      },
                      _vm._l(_vm.optionsEstados, function (option) {
                        return _c("option", { key: option.value }, [
                          _vm._v(" " + _vm._s(option.text) + " "),
                        ])
                      }),
                      0
                    ),
                    _c("span", { staticClass: "cardErrors" }, [
                      _vm._v(_vm._s(errors[0])),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-md-4" },
        [
          _c("ValidationProvider", {
            attrs: {
              name: "TipoPoder",
              rules: "required",
              "custom-messages": {
                required: "El tipo de poder es obligatorio",
              },
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ errors }) {
                  return [
                    _c("base-input", {
                      attrs: {
                        type: "text",
                        label: "Tipo de Poder",
                        placeholder: "Tipo de Poder",
                      },
                      model: {
                        value: _vm.currentRepresentante.tipo_poder,
                        callback: function ($$v) {
                          _vm.$set(_vm.currentRepresentante, "tipo_poder", $$v)
                        },
                        expression: "currentRepresentante.tipo_poder",
                      },
                    }),
                    _c("span", { staticClass: "cardErrors" }, [
                      _vm._v(_vm._s(errors[0])),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-md-4" },
        [
          _c("base-input", {
            attrs: {
              type: "text",
              label: "Vigencia del Poder",
              placeholder: "Vigencia del Poder",
            },
            model: {
              value: _vm.currentRepresentante.vigencia_poder,
              callback: function ($$v) {
                _vm.$set(_vm.currentRepresentante, "vigencia_poder", $$v)
              },
              expression: "currentRepresentante.vigencia_poder",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-md-4" },
        [
          _c("base-input", {
            attrs: {
              type: "text",
              label: "Limitaciones del Poder",
              placeholder: "Limitaciones del Poder",
            },
            model: {
              value: _vm.currentRepresentante.limitaciones_poder,
              callback: function ($$v) {
                _vm.$set(_vm.currentRepresentante, "limitaciones_poder", $$v)
              },
              expression: "currentRepresentante.limitaciones_poder",
            },
          }),
        ],
        1
      ),
    ]),
    _c("h4", { staticClass: "cardD" }, [_vm._v(" Datos de registro ")]),
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-md-4" },
        [
          _c("ValidationProvider", {
            attrs: {
              name: "instrumento_rpf_folio",
              rules: "required",
              "custom-messages": {
                required: "El Folio mercantil es obligatorio",
              },
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ errors }) {
                  return [
                    _c("base-input", {
                      attrs: {
                        type: "text",
                        label: "Folio mercantil",
                        placeholder: "Folio mercantil",
                      },
                      model: {
                        value: _vm.currentRepresentante.instrumento_rpf_folio,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.currentRepresentante,
                            "instrumento_rpf_folio",
                            $$v
                          )
                        },
                        expression:
                          "currentRepresentante.instrumento_rpf_folio",
                      },
                    }),
                    _c("span", { staticClass: "cardErrors" }, [
                      _vm._v(_vm._s(errors[0])),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-md-4" },
        [
          _c("ValidationProvider", {
            attrs: {
              name: "instrumento_rpf_folio_fecha",
              rules: "required",
              "custom-messages": {
                required: "La fecha de inscripción es obligatoria",
              },
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ errors }) {
                  return [
                    _c("base-input", {
                      attrs: {
                        type: "date",
                        label: "Fecha de inscripción",
                        placeholder: "Fecha de inscripción",
                      },
                      model: {
                        value:
                          _vm.currentRepresentante.instrumento_rpf_folio_fecha,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.currentRepresentante,
                            "instrumento_rpf_folio_fecha",
                            $$v
                          )
                        },
                        expression:
                          "currentRepresentante.instrumento_rpf_folio_fecha",
                      },
                    }),
                    _c("span", { staticClass: "cardErrors" }, [
                      _vm._v(_vm._s(errors[0])),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-md-4" },
        [
          _c("ValidationProvider", {
            attrs: {
              name: "instrumento_rpf_folio_lugar",
              rules: "required",
              "custom-messages": {
                required: "La oficina del RPC es obligatoria",
              },
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ errors }) {
                  return [
                    _c("base-input", {
                      attrs: {
                        type: "text",
                        label: "Oficina del RPC ubicada en",
                        placeholder: "Oficina del RPC ubicada en",
                      },
                      model: {
                        value:
                          _vm.currentRepresentante.instrumento_rpf_folio_lugar,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.currentRepresentante,
                            "instrumento_rpf_folio_lugar",
                            $$v
                          )
                        },
                        expression:
                          "currentRepresentante.instrumento_rpf_folio_lugar",
                      },
                    }),
                    _c("span", { staticClass: "cardErrors" }, [
                      _vm._v(_vm._s(errors[0])),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ]),
    _c("br"),
    _vm._m(0),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text-center" }, [
      _c(
        "button",
        {
          staticClass: "btn btn-fill float-right orange-btn bottomB",
          attrs: { type: "submit" },
        },
        [_vm._v(" Guardar ")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }