<template>
  <div class="container mt-5">
    <div v-if="loading">
      <div id="loader">
        <font-awesome-icon icon="spinner" spin />
      </div>
    </div>

    <div class="card" id="container-openpay">
      <div class="card-body">

        <div class="mt-3 center">
          <p>
            Correduría Digital no guarda nigún dato de la compra. Todo se efectúa a través de la plataforma de OpenPay.
          </p>
        </div>

        <form id="payment-form">
          <input type="hidden" name="token_id" id="token_id" />
          <div class="form-group">
            <label for="holder_name">Nombre del titular</label>
            <input type="text" class="form-control" id="holder_name" placeholder="Como aparece en la tarjeta"
              autocomplete="off" data-openpay-card="holder_name" ref="input_holder_name" />
          </div>
          <div class="form-group">
            <label for="card_number">Número de tarjeta</label>
            <input type="text" class="form-control" id="card_number" autocomplete="off" data-openpay-card="card_number"
              placeholder="Número de tarjeta" ref="input_card_number" v-model="cardNumber" @input="formatCardNumber" />
          </div>
          <div class="form-row">
            <div class="form-group col-md-6">
              <label for="expiration_month">Fecha de expiración (Mes)</label>
              <input type="text" class="form-control" id="expiration_month" placeholder="Mes 2 dígitos"
                data-openpay-card="expiration_month" ref="input_expiration_month" />
            </div>
            <div class="form-group col-md-6">
              <label for="expiration_year">Fecha de expiración (Año)</label>
              <input type="text" class="form-control" id="expiration_year" placeholder="Año 2 dígitos"
                data-openpay-card="expiration_year" ref="input_expiration_year" />
            </div>
          </div>
          <div class="form-group">
            <label for="cvv2">Código de seguridad</label>
            <input type="text" class="form-control" id="cvv2" placeholder="3 dígitos" autocomplete="off"
              data-openpay-card="cvv2" ref="input_cvv2" />
          </div>
          <div class="d-flex justify-content-between align-items-center my-3">
            <small class="orange-text size14">
              <img src="/img/lock.png" alt="Seguridad" height="32" class="mr-2" />
              Tus pagos se realizan de forma segura con encriptación de 256 bits
            </small>
          </div>
          <div class="m-3 cards">
            <img :src="require('@/assets/img/openpay/Openpay.webp')" alt="visa" height="64" />
            <img :src="require('@/assets/img/openpay/visa.webp')" alt="visa" height="48" />
            <img :src="require('@/assets/img/openpay/masterCard.webp')" alt="masterCard" height="48" />
            <img :src="require('@/assets/img/openpay/americanExpress.webp')" alt="americanExpress" height="38" />
            <img :src="require('@/assets/img/openpay/carnet.webp')" alt="carnet" height="48" />
          </div>
          <button type="button" ref="btnPagar" class="btn btn-block orange-btn" @click.prevent="pagar"
            :disabled="disabledButtonPago">
            Pagar
          </button>
        </form>
      </div>
    </div>

  </div>
</template>

<script>
import { CotizacionesApi, FuncionesApi } from "@/api";
import Storage from "@/utils/storage";

const storage = new Storage();

export default {
  name: "Openpay",

  props: {
    cotizacion_id: { type: Number },
    precio_total: { type: Number }
  },

  data: () => ({
    loading: false,

    token: "",
    cardNumber: "",
    deviceSessionId: "",

    openpay_id: "mev7vw81iraqwjctfttx",
    openpay_key: "pk_bd22fbb847f14bc3a8e22d6984eb7dab",
    openpay_sandbox_mode: true,
  }),

  computed: {
    disabledButtonPago() {
      return !this.precio_total;
    },
  },

  mounted() {
    OpenPay.setId(this.openpay_id);
    OpenPay.setApiKey(this.openpay_key);
    OpenPay.setSandboxMode(this.openpay_sandbox_mode);

    this.deviceSessionId = OpenPay.deviceData.setup("payment-form", "deviceIdHiddenFieldName");

    const successfulPayment = this.$route.query.payment_status === "successful";

    if (successfulPayment) {
      if (this.cotizacion_id) {
        CotizacionesApi.verificarCotizacionPagada(this.cotizacion_id).then((result) => {
          if (result.pagado) {
            this.$emit("payCotizacion", true);
          }
        });
        return;
      } else {
        this.$toast.success("El pago fue realizado correctamente y su saldo ha sido actualizado.", {
          timeout: 8000,
          hideProgressBar: false,
          position: "top-right",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          closeButton: "button",
          icon: true
        });
      }
    }
  },

  created() {
  },

  methods: {
    delay(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },

    formatCardNumber(event) {
      let value = event.target.value.replace(/\D/g, ''); // Remove non-numeric characters

      // Format as XXXX XXXX XXXX XXXX
      value = value.replace(/(\d{4})/g, '$1 ').trim();

      // Limit to 19 characters (16 digits + 3 spaces)
      if (value.length > 19) {
        value = value.slice(0, 19);
      }

      this.cardNumber = value;
    },

    pagar() {
      if (!this.precio_total) {
        this.showError(`Monto no válido.`);
        return;
      }
      if (!this.$refs.input_holder_name.value) {
        this.showError(`El nombre del titular no es válido.`);
        return;
      }

      this.cardNumber = this.cardNumber.replace(/\s/g, '');
      this.$refs.input_card_number.value = this.cardNumber;

      if (!OpenPay.card.validateCardNumber(this.$refs.input_card_number.value)) {
        this.showError(`El número de tarjeta no es válido.`);
        return;
      }
      if (!OpenPay.card.validateExpiry(this.$refs.input_expiration_month.value, this.$refs.input_expiration_year.value)) {
        this.showError(`La fecha de expiración no es válida.`);
        return;
      }
      if (!OpenPay.card.validateCVC(this.$refs.input_cvv2.value, this.$refs.input_card_number.value)) {
        this.showError(`El código de seguridad no es válido.`);
        return;
      }

      this.loading = true;

      this.$toast.info("Procesando pago, por favor espere.", {
        timeout: 4000,
        hideProgressBar: false,
        position: "top-right",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        closeButton: "button",
        icon: true
      });

      OpenPay.token.extractFormAndCreate(
        "payment-form",
        this.sucess_callbak,
        this.error_callbak
      );
    },

    showError(errorMessage = "") {
      this.$toast.error(errorMessage, {
        timeout: 4000,
        hideProgressBar: false,
        position: "top-right",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        closeButton: "button",
        icon: true
      });
    },

    async sucess_callbak(response) {
      try {
        this.loading = true;

        const token_id = response.data.id;
        this.token = token_id;
        $("#token_id").val(token_id);

        const clienteEmail = storage.getItem("user");
        const data = {
          sourceId: token_id,
          description: "Correduría Digital",
          amount: this.precio_total,
          deviceSessionId: this.deviceSessionId,
          cliente_email: clienteEmail,
          cotizaciones_id: this.cotizacion_id,
          callback_path: this.$route.fullPath,
          use_3d_secure: false
        };

        const result = await FuncionesApi.payCotizacion(data);

        // Undefined = 0,
        // Success = 1
        // Pending = 2
        // Failed = 3
        if (result.status === 2) {
          this.redirectTo3dSecurePage(result);
          return;
        }

        this.$toast.success("El pago fue realizado correctamente", {
          timeout: 4000,
          hideProgressBar: false,
          position: "top-right",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          closeButton: "button",
          icon: true
        });

        this.clearFields();
        this.$refs.btnPagar.disabled = true;
        this.$emit("payCotizacion", true);
      } catch (error) {
        console.log(error);

        if (error.data == 3005) {
          this.use3DSecureCharge();
          return;
        }

        this.$toast.error(error.data, {
          timeout: 4000,
          hideProgressBar: false,
          position: "top-right",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          closeButton: "button",
          icon: true
        });
      } finally {
        this.clearFields();
        this.loading = false;
      }
    },

    async error_callbak(response) {
      const desc = response.data.description !== undefined ? response.data.description : response.message;

      this.showError(desc);

      this.loading = false;
    },

    clearFields() {
      this.$refs.input_holder_name.value = "";
      this.$refs.input_card_number.value = "";
      this.$refs.input_expiration_month.value = "";
      this.$refs.input_expiration_year.value = "";
      this.$refs.input_cvv2.value = "";
    },

    async use3DSecureCharge() {
      try {
        this.loading = true;

        const clienteEmail = storage.getItem("user");
        const data = {
          sourceId: this.token,
          deviceSessionId: this.deviceSessionId,
          description: "Correduría Digital",
          amount: this.precio_total,
          cliente_email: clienteEmail,
          cotizaciones_id: this.cotizacion_id,
          callback_path: this.$route.fullPath,
          use_3d_secure: true
        };

        const result = await FuncionesApi.payCotizacion(data);

        // Undefined = 0,
        // Success = 1
        // Pending = 2
        // Failed = 3
        if (result.status === 2) {
          this.redirectTo3dSecurePage(result);
          return;
        }

        this.$toast.success("El pago fue realizado correctamente", {
          timeout: 4000,
          hideProgressBar: false,
          position: "top-right",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          closeButton: "button",
          icon: true
        });

        this.clearFields();
        this.$refs.btnPagar.disabled = true;
        this.$emit("payCotizacion", true);
      } catch (error) {
        console.log(error);
        this.showError("La tarjeta fue rechazada y no fue posible realizar el cargo.");
      } finally {
        this.loading = false;
      }
    },

    async getTransactionStatus(transactionId) {
      try {
        this.loading = true;

        const result = await FuncionesApi.getStatusCargo(transactionId);

        if (result.status === 1) {
          this.$toast.success("El pago fue realizado correctamente.", {
            timeout: 4000,
            hideProgressBar: false,
            position: "top-right",
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            closeButton: "button",
            icon: true
          });

          this.$emit("payCotizacion", true);
        } else {
          this.$toast.error("El pago no fue realizado.", {
            timeout: 4000,
            hideProgressBar: false,
            position: "top-right",
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            closeButton: "button",
            icon: true
          });
        }
      } catch (error) {
        console.log(error);
        this.showError("Ocurrió un error al procesar la información del pago.");
      } finally {
        this.loading = false;
      }
    },

    async redirectTo3dSecurePage(result) {
      this.$toast.success("Serás redirigido a la página de Openpay.", {
        timeout: 4000,
        hideProgressBar: false,
        position: "top-right",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        closeButton: "button",
        icon: true
      });
      this.loading = true;
      await this.delay(4000);
      this.loading = false;
      window.location.href = result.url;
    }
  }
};
</script>

<style scoped>
.container {
  margin: 0 auto;
}

.size14 {
  font-size: 14px;
}

.cards {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.card {
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.card-body {
  padding: 20px;
}

.card .form-group {
  margin-bottom: 20px;
}

.card .btn {
  margin-top: 20px;
}

.b-overlay-wrap {
  position: relative;
}
</style>
