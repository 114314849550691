var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container" }, [
    _c("h4", { staticClass: "cardD" }, [_vm._v("Cita para firma")]),
    _c(
      "div",
      { staticClass: "mt-4 fecha" },
      [
        _c(
          "b-form-group",
          {
            attrs: {
              label: "Link cita Calendly",
              "label-for": "input-link-cita",
            },
          },
          [
            _c(
              "b-input-group",
              { staticClass: "mt-3" },
              [
                _c(
                  "b-input-group-append",
                  [
                    _c(
                      "b-button",
                      {
                        directives: [
                          {
                            name: "b-tooltip",
                            rawName: "v-b-tooltip.hover",
                            modifiers: { hover: true },
                          },
                        ],
                        attrs: {
                          variant: _vm.isTextCopied ? "success" : "secondary",
                          squared: "",
                          title: "Copiar",
                        },
                        on: { click: _vm.copyLinkToClipboard },
                      },
                      [
                        _vm.isTextCopied
                          ? _c("b-icon", { attrs: { icon: "check" } })
                          : _c("b-icon", { attrs: { icon: "clipboard" } }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("b-form-input", {
                  attrs: {
                    id: "input-link-cita",
                    type: "link",
                    placeholder: "Link Calendly",
                    disabled: true,
                  },
                  model: {
                    value: _vm.constitutiva.link_calendario,
                    callback: function ($$v) {
                      _vm.$set(_vm.constitutiva, "link_calendario", $$v)
                    },
                    expression: "constitutiva.link_calendario",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c("div", { staticClass: "row mt-4 fecha" }, [
      _vm._m(0),
      _c("div", { staticClass: "col-9" }, [
        _vm._v(" " + _vm._s(_vm.fecha_formatted) + " "),
      ]),
    ]),
    !_vm.formalizacion.firma_fuera_oficina
      ? _c(
          "div",
          { staticClass: "mt-3" },
          [
            _c("ValidationProvider", {
              attrs: {
                name: "direccion_firma",
                rules: "required",
                "custom-messages": {
                  required: "La dirección es obligatoria",
                },
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function ({ errors }) {
                      return [
                        _c("base-input", {
                          attrs: {
                            disabled: _vm.formalizacion.disabledCita,
                            type: "text",
                            label: "Dirección",
                            placeholder: "Dirección donde será la firma",
                          },
                          model: {
                            value: _vm.formalizacion.direccion_firma,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.formalizacion,
                                "direccion_firma",
                                $$v
                              )
                            },
                            expression: "formalizacion.direccion_firma",
                          },
                        }),
                        _c("span", { staticClass: "cardErrors" }, [
                          _vm._v(_vm._s(errors[0])),
                        ]),
                      ]
                    },
                  },
                ],
                null,
                false,
                807549446
              ),
            }),
            _c("div", { staticClass: "mt-4" }, [
              _c(
                "div",
                {},
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "btn-fill orange-btn bottom2",
                      attrs: {
                        type: "submit",
                        size: "m",
                        disabled: _vm.formalizacion.disabledCita,
                      },
                    },
                    [_vm._v("Guardar")]
                  ),
                ],
                1
              ),
            ]),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-3" }, [
      _c("label", {}, [_vm._v("Fecha seleccionada")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }