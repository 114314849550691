<template>
  <div class="container">
    <!-- Modales -->
    <b-modal no-close-on-backdrop centered ref="entrega-documento-modal" title="" hide-footer id="modalC" size="xl">
      <div>
        <ValidationObserver v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(postDocumento)">
            <ModalEntregaDocumento :currentDocumento="currentDocumento" :notificacion="notificacion" @submit.prevent />
          </form>
        </ValidationObserver>
      </div>
    </b-modal>
    <b-modal no-close-on-backdrop centered ref="documento-modal-preview-img" title="" hide-footer id="modaldi"
      size="xl">
      <ModalPreviewImg :currentDocumento="currentDocumento" />
    </b-modal>
    <b-modal no-close-on-backdrop centered ref="documento-modal-preview-pdf" title="" hide-footer id="modaldf"
      size="xl">
      <ModalPreviewPDF :currentDocumento="currentDocumento" />
    </b-modal>
    <b-modal no-close-on-backdrop centered ref="documento-modal-preview-word" title="" hide-footer id="modaldd"
      size="xl">
      <ModalPreviewDocx :currentDocumento="currentDocumento" />
    </b-modal>
    <b-modal no-close-on-backdrop centered ref="modal-confirm-regenerate" hide-footer id="modal-confirm-regenerate">
      <h4 class="text-center">
        ¿Está seguro de regenerar el Acta de Diligencia? El Acta existente será eliminada.
      </h4>
      <h5 class="text-center"></h5>
      <b-button class="mt-3" variant="danger" @click="$bvModal.hide('modal-confirm-regenerate')">Cancelar</b-button>
      <b-button class="mt-3 float-right" variant="primary" @click="regenerarCartaInstruccion()">OK</b-button>
    </b-modal>

    <!-- Template -->
    <h4 class="cardD">Actas</h4>

    <div>
      <b-table ref="table" hover :fields="fieldsDocumentos" :items="notificacion.documentos">
        <template #cell(tipo)="{ item }">
          <div v-if="item.tipo === 'Otro'">
            <div v-b-tooltip.hover :title="item.descripcion">
              {{ item.tipo }}
            </div>
          </div>
          <div v-else>
            {{ item.tipo }}
          </div>
        </template>
        <template #cell(acciones)="{ item }">
          <b-button v-if="isDocumentoActaGenerada(item) || DocumentType(item) == 'word'" variant="success" size="sm"
            v-b-tooltip.hover title="Descarga documento" @click="descargaFile(item.documento)" class="editBtn">
            <i class="fa fa-download"></i>
          </b-button>
          <b-button v-if="!isDocumentoActaGenerada(item) && DocumentType(item) != 'word'" variant="primary" size="sm"
            v-b-tooltip.hover title="Vista previa" @click="previewDocumento(item)" class="editBtn">
            <b-icon-eye />
          </b-button>
          <b-button v-if="!isDocumentoActaGenerada(item) && ['11', '58'].includes(notificacion.id_estatus)"
            variant="warning" size="sm" v-b-tooltip.hover title="Editar" @click="openEditDocumentoModal(item)"
            class="editBtn">
            <b-icon-upload />
          </b-button>
        </template>
      </b-table>
    </div>

    <div class="mt-4">
      <div class="">
        <b-button class="btn-fill green-btn bottom1" size="m" :disabled="notificacion.id_estatus == 13"
          @click="openModalRegenerate()">Regenerar Acta Diligencia</b-button>
      </div>
      <div class="">
        <b-button type="submit" class="btn-fill orange-btn bottom2" size="m"
          :disabled="notificacion.id_estatus != '11' && notificacion.id_estatus != '58'">Continuar</b-button>
        <!-- <b-button type="submit" class="btn-fill orange-btn bottom2" size="m"
          :disabled="!documentosCompletos || notificacion.id_estatus != '11' || notificacion.id_estatus != '58'"
        >Continuar</b-button> -->
      </div>
    </div>
  </div>
</template>

<script>
// Modales
import ModalEntregaDocumento from "@/views/clients/procesos/notificaciones/completaInfo/modales/ModalEntregaDocumento.vue";
import ModalPreviewImg from "@/views/clients/procesos/constitucion/entregadocumentos/ModalPreviewImg.vue";
import ModalPreviewPDF from "@/views/clients/procesos/constitucion/entregadocumentos/ModalPreviewPDF.vue";
import ModalPreviewDocx from "@/views/clients/procesos/constitucion/entregadocumentos/ModalPreviewDocx.vue";

import { CatDocumentosApi, DocumentosXServiciosApi, DocumentosApi, FuncionesApi, SolicitantesApi, ComparecientesApi } from "@/api";

export default {
  name: "ActasNotificaciones",

  components: {
    ModalEntregaDocumento,
    ModalPreviewImg,
    ModalPreviewPDF,
    ModalPreviewDocx
  },

  props: {
    notificacion: { type: Object },
  },

  computed: {
    fieldsDocumentos() {
      return [
        { key: "tipo", label: "Tipo", class: "text-center" },
        { key: "nombre", label: "Nombre", class: "text-center" },
        { key: "acciones", label: "Acciones", class: "text-center" }
      ];
    },
  },

  async created() {
    try {
      this.loading = true;

      this.notificacion.documentos = [];

      const catDocumentos = await this.getCatDocumentosNotificaciones();
      this.populateOptionsDocumentos(catDocumentos);
      await this.getDocumentosXServiciosWithNotificacion();
      const documentos = await this.getDocumentosFromDocumentosXServicios();
      await this.addDocumentoToNotificacionDocumentos(documentos);

      // console.log(this.documentosCompletos);
      // console.log(this.notificacion);
    } catch (error) {
      console.log(error);
      this.showErrorToast('No se pudieron obtener los documentos. Intente más tarde.');
    } finally {
      this.loading = false;
    }
  },

  data() {
    return {
      allDocumentos: [],
      optionsDocumentos: [],
      documentosXServicios: [],
      documentosCompletos: false,
      confirmAceptacion: false,

      currentDocumento: {},

      cartaInstruccion: null,
      datos: {},
      cartaKey: 0,

      stgAccessKey: "?sv=2023-01-03&st=2024-02-09T17%3A04%3A42Z&se=2034-02-11T17%3A04%3A00Z&sr=c&sp=rl&sig=rq9SDEcZMPv%2FbgiTq9FRE66lHZvEAeeiMqytpyiH2sk%3D",
    }
  },

  methods: {
    showErrorToast(msg) {
      this.$toast.error(msg, {
        timeout: 4000, //false,
        hideProgressBar: false,
        position: "top-right",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        closeButton: "button",
        icon: true,
      });
    },

    async getCatDocumentosNotificaciones() {
      const { value } = await CatDocumentosApi.list({
        page: 0,
        registersPerPage: 0,
        categoria: "notificaciones"
      });

      return value;
    },

    populateOptionsDocumentos(documentos) {
      documentos.forEach(documento => {
        const obj = { text: documento.documento, value: documento.id };
        this.optionsDocumentos.push(obj);
      });
    },

    async getDocumentosXServiciosWithNotificacion() {
      const { value } = await DocumentosXServiciosApi.list({
        page: 0,
        registersPerPage: 0,
        cat_servicios_id: 2,
        id_proceso: this.notificacion.id
      });
      this.documentosXServicios = value;
    },

    async getDocumentosFromDocumentosXServicios() {
      const documentos2 = [];

      for (const documentoXServicio of this.documentosXServicios) {
        const documentoCompleto2 = await this.getDocumentoDetail(documentoXServicio.documentos_id);

        if (!documentoCompleto2) {
          continue; // se excluyen facturas
        }

        documentos2.push(documentoCompleto2);
      }

      return documentos2;
    },

    async getDocumentoDetail(documento_id) {
      const documento = await DocumentosApi.detail(documento_id);

      if (documento.cat_documentos_id == '94') {
        return null; // se excluyen facturas
      }

      const documentoCompleto = this.completeFields(documento);

      return documentoCompleto;
    },

    completeFields(documento) {
      const documentoWithTipo = this.addTipo(documento);
      return documentoWithTipo;
    },

    addTipo(documento) {
      const tipo = this.getCatDocumentoNombre(documento);
      documento.tipo = tipo;
      return documento;
    },

    getCatDocumentoNombre(documento) {
      const catDcoumentosIndex = this.getCatDocumentosIndex(documento);
      const nombre_catDocumento = this.optionsDocumentos[catDcoumentosIndex].text;
      return nombre_catDocumento;
    },

    getCatDocumentosIndex(documento) {
      const catDocumentosIndex =
        this.optionsDocumentos.findIndex(optionDocumento => optionDocumento.value === parseInt(documento.cat_documentos_id));
      if (catDocumentosIndex == -1) {
        console.log(documento)
      }
      this.areDocumentosCompletos();
      return catDocumentosIndex;
    },

    async addDocumentoToNotificacionDocumentos(documentos) {
      const cat_documentos_id_actas = [44, 45, 46];
      const cat_documentos_id_identificaciones = [40, 41, 42, 43];

      documentos.forEach(documento => {
        if (cat_documentos_id_actas.includes(parseInt(documento.cat_documentos_id))) {
          this.notificacion.documentos.push(documento);
        } else {
          if (!cat_documentos_id_identificaciones.includes(parseInt(documento.cat_documentos_id))) {
            this.allDocumentos.push(documento);
          }
        }
      });

      this.sortNotificacionDocumentosById();
      await this.generaActas();
    },

    isDocumentoActaGenerada(documento) {
      if (documento.cat_documentos_id == 44) { return true }
      else { return false };
    },

    sortNotificacionDocumentosById() {
      this.notificacion.documentos.sort((a, b) => parseInt(a.cat_documentos_id) - parseInt(b.cat_documentos_id));
    },

    openEditDocumentoModal(documento) {
      this.$refs["entrega-documento-modal"].show();
      this.currentDocumento = documento;
    },

    postDocumento() {
      this.hideEntregaDocumentoModal();
      this.sendCurrentDocumentoToAzureStorage();
    },

    async sendCurrentDocumentoToAzureStorage() {
      this.loading = true;
      try {
        const formData = new FormData();

        this.currentDocumento.nombre = this.currentDocumento.file.name;
        formData.append("file", this.currentDocumento.file);

        const path = await FuncionesApi.file(
          this.notificacion.id,
          "documentosNotificaciones",
          formData,
          {}
        );

        this.currentDocumento.documento = path;
        this.updateDocumentoBD();
      } catch (error) {
        this.showErrorToast('Error al mandar el archivo. Intente más tarde.');
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    async updateDocumentoBD() {
      this.loading = true;
      try {
        await DocumentosApi.edit(this.currentDocumento.id, {
          documento: this.currentDocumento.documento,
          cat_documentos_id: this.currentDocumento.cat_documentos_id,
          auto_referencia: this.currentDocumento.auto_referencia,
          descripcion: this.currentDocumento.descripcion,
          paginas: parseInt(this.currentDocumento.paginas),
          copias_certificadas: null,
          nombre: this.currentDocumento.nombre
        })
        this.updateNotificacionDocumentoFromCurrentDocumento();
      } catch (error) {
        this.showErrorToast('Error al actualizar el documento. Intente más tarde.');
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    updateNotificacionDocumentoFromCurrentDocumento() {
      const notificacionDocumentosIndex = this.getNotificacionDocumentosIndex(this.currentDocumento);
      this.notificacion.documentos[notificacionDocumentosIndex] = this.currentDocumento;
      this.areDocumentosCompletos();
    },

    getNotificacionDocumentosIndex(documento) {
      let notificacionDocumentosIndex = this.notificacion.documentos.findIndex(
        (notificacionDocumento) => notificacionDocumento.id === parseInt(documento.id)
      );
      return notificacionDocumentosIndex;
    },

    hideEntregaDocumentoModal() {
      this.$refs["entrega-documento-modal"].hide();
    },

    previewDocumento(item) {
      const documentType = this.DocumentType(item)
      if (documentType == 'image') {
        this.$refs["documento-modal-preview-img"].show();
      } else if (documentType == 'pdf') {
        this.$refs["documento-modal-preview-pdf"].show();
      } else {
        this.$refs['documento-modal-preview-word'].show();
      }
    },

    DocumentType(item) {
      const imageExtensions = ["jpg", "JPG", "jpeg", "png", "gif", "bmp", "webp", "svg", "tiff", "avif"];

      if (item.nombre) {
        let documentoSeparatedByDots = item.nombre.split('.')
        let extensionDocumento = documentoSeparatedByDots[documentoSeparatedByDots.length - 1]
        if (imageExtensions.includes(extensionDocumento)) {
          return 'image';
        }
        if (['doc', 'docx'].includes(extensionDocumento)) {
          return 'word'
        }
      }

      return 'pdf';
    },

    descargaFile(item) {
      const link = document.createElement("a");
      link.href = item + this.stgAccessKey;
      document.body.appendChild(link);
      link.click();
    },

    areDocumentosCompletos() {
      let completos = true;
      if (this.notificacion.documentos.length == 0) {
        return;
      }
      this.notificacion.documentos.forEach(documento => {
        if (documento.nombre == "") {
          completos = false;
        }
      });
      this.documentosCompletos = completos;
    },

    openModalRegenerate() {
      this.$refs["modal-confirm-regenerate"].show();
    },

    hideModalRegenerate() {
      this.$refs["modal-confirm-regenerate"].hide();
    },

    regenerarCartaInstruccion() {
      this.hideModalRegenerate();
      this.loading = true;
      this.agregaSolicitantes()
        .then((solicitantes) => { return this.addComparecientesToNotificacionSolicitantes(solicitantes); })
        .then(() => { this.generaDatosParaCartaDeInstruccion(); })
        .then(() => { this.generaProyecto(); })
        .catch((error) => {
          console.log(error);
          this.showErrorToast('No se pudo generar la carta de instrucción. Intenta más tarde.')
        })
        .finally(() => { this.loading = false; })
    },

    async agregaSolicitantes() {
      let solicitantes = [];
      if (this.notificacion.solicitantes.length == 0) {
        solicitantes = await this.getSolicitantes();
      } else {
        solicitantes = this.notificacion.solicitantes;
        this.notificacion.solicitantes = [];
      }
      return solicitantes;
    },

    async getSolicitantes() {
      const { value } = await SolicitantesApi.list({
        id_notificaciones: this.notificacion.id
      });
      let comparecientes = []
      value.forEach(solicitante => {
        const compareciente = this.getCompareciente(solicitante);
        comparecientes.push(compareciente);
      })
      return comparecientes;
    },

    async getCompareciente(solicitante) {
      let compareciente = await ComparecientesApi.detail(solicitante.id_comparecientes);
      const nombreCompleto = this.createNombreCompleto(compareciente);
      compareciente.nombreCompleto = nombreCompleto;
      return compareciente
    },

    async addComparecientesToNotificacionSolicitantes(comparecientes) {
      this.notificacion.solicitantes = await Promise.all(comparecientes);
      return this.notificacion.solicitantes
    },

    createNombreCompleto(compareciente) {
      let nombreCompletoTemp = ""
      if (compareciente.persona === 'Física') {
        nombreCompletoTemp = compareciente.nombre + ' ' + compareciente.paterno;
        if (compareciente.materno) {
          nombreCompletoTemp = nombreCompletoTemp + ' ' + compareciente.materno
        }
      } else {
        nombreCompletoTemp = compareciente.denominacion_social;
      }
      return nombreCompletoTemp;
    },

    generaDatosParaCartaDeInstruccion() {
      let nombre_completo_solicitante_principal = ''
      if (this.notificacion.solicitantes[0].persona === 'Física') {
        nombre_completo_solicitante_principal = this.notificacion.solicitantes[0].nombre + ' ' + this.notificacion.solicitantes[0].paterno;
        if (this.notificacion.solicitantes[0].materno) {
          nombre_completo_solicitante_principal = nombre_completo_solicitante_principal + ' ' + this.notificacion.solicitantes[0].materno;
        }
      } else {
        nombre_completo_solicitante_principal = this.notificacion.solicitantes[0].denominacion_social;
      }
      let notificados = '';

      this.notificacion.notificados.forEach(notificado => {
        let nombreCompletoTemp = ""
        if (notificado.persona === 'Física') {
          nombreCompletoTemp = notificado.nombre + ' ' + notificado.paterno;
          if (notificado.materno) {
            nombreCompletoTemp = nombreCompletoTemp + ' ' + notificado.materno
          }
        } else {
          nombreCompletoTemp = notificado.razon_social;
        }
        notificados = notificados + nombreCompletoTemp;
        let index = this.notificacion.notificados.findIndex(
          (o) => o.id === notificado.id
        );
        if (index != this.notificacion.notificados.length - 1) {
          notificados = notificados + ', '
        }
      })
      return;
    },

    async generaProyecto() {
      const notificacion_proyecto = { ...this.notificacion };
      notificacion_proyecto.documentos = this.allDocumentos;

      const dataNotificacion = {
        proceso_id: this.notificacion.id,
        cat_servicios_id: 2,
        notificacion: notificacion_proyecto
      }
      const url_proyecto = await FuncionesApi.generaProyecto(dataNotificacion);
      this.$toast.success("El acta de Diligencia fue regenerada exitosamente.", {
        timeout: 4000, //false,
        hideProgressBar: false,
        position: "top-right",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        closeButton: "button",
        icon: true,
      });
    },

    async generaActas() {
      if (this.notificacion.documentos.length == 0) {
        const cat_documentos_id_actas = [44, 45, 46];

        for (const cat_documento_id of cat_documentos_id_actas) {
          const nuevo_documento = {
            catDocumentos_id: cat_documento_id,
            paginas: 1
          };
          await this.postActa(nuevo_documento);
        }
      }
    },

    async postActa(acta) {
      const documento = await DocumentosApi.create({
        documento: "",
        cat_documentos_id: acta.catDocumentos_id,
        auto_referencia: null,
        descripcion: null,
        paginas: 1,
        copias_certificadas: null,
        nombre: null
      });
      acta.id = documento.id;
      await DocumentosXServiciosApi.create({
        documentos_id: acta.id,
        cat_servicios_id: 2,
        proceso_id: this.notificacion.id
      })
      const documentoCompleto = this.addTipo(documento);
      this.notificacion.documentos.push(documentoCompleto);
    }
  }
}
</script>
