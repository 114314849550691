<template>
  <div>
    <table class="tabla" style="text-align:justify">
      <tr>
        <td>
          <p>
            Esta hoja de firmas de este documento es tan vinculante y legalmente efectiva como cualquier otra sección
            del mismo. Al firmar la hoja de firmas, las partes reconocen y aceptan que han leído y comprendido todos los
            términos y condiciones contenidos en este documento y sus anexos, y acuerdan estar legalmente obligados por
            ellos.
          </p>
          <p>
            Por favor, revise cuidadosamente toda la documentación antes de firmar. Asegúrese de firmar en el espacio
            provisto al final de la hoja de firmas. Si representa a una persona moral, asegúrese de que su razón social
            y su posición dentro de la misma sea correcta, junto con el nombre legal completo de la misma.
          </p>
        </td>
      </tr>
    </table>
    <table class="tabla" v-if="datos.apoderado">
      <tr class="space" style="height: 500px;"></tr>
      <tr>
        <th colspan="10" class="section">
          {{ datos.compareciente_principal }}
        </th>
      </tr>
      <tr class="space" style="height: 100px;"></tr>
      <tr>
        <td class="space" style="width: 20%;"></td>
        <td class="user" style="width: 60%; background-color: white !important; border-bottom: solid 2px black;"></td>
        <td class="space" style="width: 20%;"></td>
      </tr>
      <tr>
        <td class="space" style="width: 20%;"></td>
        <td style="width: 60%; vertical-align: top; font-size: 14px; text-align: center; background-color: white; ">
          Por: {{ datos.apoderado }}
        </td>
        <td colspan="2" class="space" style="width: 20%;"></td>
      </tr>
      <tr>
        <td class="space" style="width: 20%;"></td>
        <td style="width: 60%; vertical-align: top; font-size: 14px; text-align: center; background-color: white; ">
          Cargo: Representante Legal
        </td>
        <td colspan="2" class="space" style="width: 20%;"></td>
      </tr>
    </table>
    <table class="tabla" v-else>
      <tr class="space" style="height:500px"></tr>
      <tr>
        <td class="space" style="width:20%"></td>
        <td class="user" style="width:60%;background-color:#fff!important;border-bottom:solid 2px #000"></td>
        <td class="space" style="width:20%"></td>
      </tr>
      <tr>
        <td class="space" style="width:20%"></td>
        <td style="width:60%;vertical-align:top;font-size:14px;text-align:center;background-color:#fff">{{
          datos.compareciente_principal }}</td>
        <td colspan="2" class="space" style="width:20%"></td>
      </tr>
    </table>

  </div>
</template>

<script>
export default {
  name: "HojaFirmasTemplate",

  props: {
    datos: { type: Object },
  },

  created() {
  },
}
</script>

<style scoped>
/* .tabla {
  width: 100%;
  font-size: 14px;
  font-family: Arial, Helvetica, sans-serif;
  padding: 50px;
}

.section {
  text-align: center;
  height: 40px
} */
</style>
