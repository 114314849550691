var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content" },
    [
      _c(
        "b-breadcrumb",
        [
          _c("b-breadcrumb-item", { attrs: { to: { path: "/dashboard" } } }, [
            _vm._v(" Inicio "),
          ]),
          _c("b-breadcrumb-item", { attrs: { active: "" } }, [
            _vm._v("Nueva Firma Digital de Documentos"),
          ]),
        ],
        1
      ),
      _c("div", { staticClass: "container" }, [
        _vm.loading
          ? _c("div", [
              _c(
                "div",
                { attrs: { id: "loader" } },
                [
                  _c("font-awesome-icon", {
                    attrs: { icon: "spinner", spin: "" },
                  }),
                ],
                1
              ),
            ])
          : _vm._e(),
      ]),
      _c(
        "b-modal",
        {
          ref: "modal-confirm-dialog-route",
          attrs: {
            "no-close-on-backdrop": "",
            centered: "",
            "hide-footer": "",
            id: "modal-confirm-dialog-route",
          },
        },
        [
          _c("h4", { staticClass: "text-center" }, [
            _vm._v(" ¿Seguro que quiere salir de la página? "),
          ]),
          _c("h5", { staticClass: "text-center" }),
          _c(
            "b-button",
            {
              staticClass: "mt-3",
              attrs: { variant: "danger" },
              on: {
                click: function ($event) {
                  return _vm.$bvModal.hide("modal-confirm-dialog-route")
                },
              },
            },
            [_vm._v("Cancelar")]
          ),
          _c(
            "b-button",
            {
              staticClass: "mt-3 float-right",
              attrs: { variant: "primary" },
              on: {
                click: function ($event) {
                  return _vm.beforeLeave(true)
                },
              },
            },
            [_vm._v("OK")]
          ),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          ref: "modal-confirm-dialog",
          attrs: {
            "no-close-on-backdrop": "",
            centered: "",
            "hide-footer": "",
            id: "modal-confirm-dialog",
          },
        },
        [
          _c("h4", { staticClass: "text-center" }, [
            _vm._v(" ¿Seguro que quiere salir sin guardar los cambios? "),
          ]),
          _c("h5", { staticClass: "text-center" }),
          _c(
            "b-button",
            {
              staticClass: "mt-3",
              attrs: { variant: "danger" },
              on: {
                click: function ($event) {
                  return _vm.$bvModal.hide("modal-confirm-dialog")
                },
              },
            },
            [_vm._v("Cancelar")]
          ),
          _c(
            "b-button",
            { staticClass: "mt-3 float-right", attrs: { variant: "primary" } },
            [_vm._v("OK")]
          ),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          ref: "modal-confirm-cotizacion",
          attrs: {
            "no-close-on-backdrop": "",
            centered: "",
            "hide-footer": "",
            id: "modal-confirm-cotizacion",
          },
        },
        [
          _c("h4", { staticClass: "text-center" }, [
            _vm._v(" ¿Seguro que quiere continuar al pago? "),
          ]),
          _c("h5", { staticClass: "text-center" }),
          _c(
            "b-button",
            {
              staticClass: "mt-3",
              attrs: { variant: "danger" },
              on: {
                click: function ($event) {
                  return _vm.$bvModal.hide("modal-confirm-cotizacion")
                },
              },
            },
            [_vm._v("Cancelar")]
          ),
          _c(
            "b-button",
            {
              staticClass: "mt-3 float-right",
              attrs: { variant: "primary" },
              on: {
                click: function ($event) {
                  return _vm.generaRatificacion()
                },
              },
            },
            [_vm._v("OK")]
          ),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          ref: "pago-modal",
          attrs: {
            "no-close-on-backdrop": "",
            centered: "",
            title: "",
            "hide-footer": "",
            id: "modalC",
            size: "xl",
          },
        },
        [
          _c("ValidationObserver", {
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ handleSubmit }) {
                  return [
                    _c(
                      "form",
                      {
                        on: {
                          submit: function ($event) {
                            $event.preventDefault()
                            return handleSubmit(_vm.payCotizacion)
                          },
                        },
                      },
                      [
                        _c("Openpay", {
                          attrs: {
                            cotizacion_id: _vm.ratificacion.id_cotizaciones,
                            precio_total: _vm.ratificacion.precio_total,
                          },
                          on: {
                            submit: function ($event) {
                              $event.preventDefault()
                            },
                            payCotizacion: function ($event) {
                              return _vm.payCotizacion($event)
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            "no-close-on-backdrop": "",
            id: "modal-ratificaciones-digital-help-descripcion-documentos",
            size: "xl",
            centered: "",
            "hide-footer": "",
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "b-carousel",
                {
                  attrs: {
                    id: "carousel-ratificaciones-1",
                    interval: 0,
                    indicators: "",
                    background: "#ababab",
                  },
                },
                [
                  _c("b-carousel-slide", {
                    attrs: {
                      "img-src": require("@/assets/img/ratificaciones/help/modal_3_1.webp"),
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "div",
        { staticClass: "container-fluid" },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { staticClass: "col-3" },
                [
                  _c(
                    "b-card",
                    [
                      _c("TreeRatificacionesDigital", {
                        attrs: {
                          ratificacion: _vm.ratificacion,
                          user: "Client",
                        },
                        on: { node: _vm.selected },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _vm.currentComponentBody === "Comparecientes"
                        ? _c("b-img", {
                            attrs: {
                              src: require("@/assets/img/ratificaciones/help/1.webp"),
                              "fluid-grow": "",
                              alt: "Descripción de los ratificantes",
                            },
                          })
                        : _vm._e(),
                      _vm.currentComponentBody === "Montos"
                        ? _c("b-img", {
                            attrs: {
                              src: require("@/assets/img/ratificaciones/help/2.webp"),
                              "fluid-grow": "",
                              alt: "Descripción de los montos",
                            },
                          })
                        : _vm._e(),
                      _vm.currentComponentBody === "Documentos"
                        ? _c("b-img", {
                            attrs: {
                              src: require("@/assets/img/ratificaciones/help/3.webp"),
                              "fluid-grow": "",
                              alt: "Descripción de los documentos",
                            },
                          })
                        : _vm._e(),
                      _vm.currentComponentBody === "Documentos"
                        ? _c(
                            "b-button",
                            {
                              staticClass: "orange-btn",
                              attrs: { block: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.$bvModal.show(
                                    "modal-ratificaciones-digital-help-descripcion-documentos"
                                  )
                                },
                              },
                            },
                            [_vm._v(" Más información ")]
                          )
                        : _vm._e(),
                      _vm.currentComponentBody === "Cotizacion"
                        ? _c("b-img", {
                            attrs: {
                              src: require("@/assets/img/ratificaciones/help/4.webp"),
                              "fluid-grow": "",
                              alt: "Descripción de la cotización",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-col",
                { staticClass: "col-9" },
                [
                  _c(
                    "b-card",
                    [
                      _c(
                        "b-row",
                        [
                          _c("b-col", { staticClass: "col-12" }, [
                            _vm.currentComponentBody === "Comparecientes"
                              ? _c(
                                  "div",
                                  [
                                    _c("ValidationObserver", {
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function ({ handleSubmit }) {
                                              return [
                                                _c(
                                                  "form",
                                                  {
                                                    ref: "comparecientes",
                                                    on: {
                                                      submit: function (
                                                        $event
                                                      ) {
                                                        $event.preventDefault()
                                                        handleSubmit(
                                                          _vm.changeCurrentComponentBody(
                                                            "Montos"
                                                          )
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("Ratificantes", {
                                                      attrs: {
                                                        ratificacion:
                                                          _vm.ratificacion,
                                                      },
                                                      on: {
                                                        submit: function (
                                                          $event
                                                        ) {
                                                          $event.preventDefault()
                                                        },
                                                        allChecksPassed:
                                                          function ($event) {
                                                            return _vm.submitForm(
                                                              "comparecientes"
                                                            )
                                                          },
                                                        node: _vm.selected,
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        3173162378
                                      ),
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.currentComponentBody === "Montos"
                              ? _c(
                                  "div",
                                  [
                                    _c("ValidationObserver", {
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function ({ handleSubmit }) {
                                              return [
                                                _c(
                                                  "form",
                                                  {
                                                    ref: "montos",
                                                    on: {
                                                      submit: function (
                                                        $event
                                                      ) {
                                                        $event.preventDefault()
                                                        handleSubmit(
                                                          _vm.changeCurrentComponentBody(
                                                            "Documentos"
                                                          )
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("Montos", {
                                                      attrs: {
                                                        ratificacion:
                                                          _vm.ratificacion,
                                                      },
                                                      on: {
                                                        submit: function (
                                                          $event
                                                        ) {
                                                          $event.preventDefault()
                                                        },
                                                        allChecksPassed:
                                                          function ($event) {
                                                            return _vm.submitForm(
                                                              "montos"
                                                            )
                                                          },
                                                        node: _vm.selected,
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        3154503606
                                      ),
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.currentComponentBody === "Documentos"
                              ? _c(
                                  "div",
                                  [
                                    _c("ValidationObserver", {
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function ({ handleSubmit }) {
                                              return [
                                                _c(
                                                  "form",
                                                  {
                                                    ref: "documentos",
                                                    on: {
                                                      submit: function (
                                                        $event
                                                      ) {
                                                        $event.preventDefault()
                                                        handleSubmit(
                                                          _vm.changeCurrentComponentBody(
                                                            "Cotizacion"
                                                          )
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("Documentos", {
                                                      attrs: {
                                                        ratificacion:
                                                          _vm.ratificacion,
                                                      },
                                                      on: {
                                                        submit: function (
                                                          $event
                                                        ) {
                                                          $event.preventDefault()
                                                        },
                                                        allChecksPassed:
                                                          function ($event) {
                                                            return _vm.submitForm(
                                                              "documentos"
                                                            )
                                                          },
                                                        node: _vm.selected,
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        1404971699
                                      ),
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.currentComponentBody === "Cotizacion"
                              ? _c(
                                  "div",
                                  [
                                    _c("ValidationObserver", {
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function ({ handleSubmit }) {
                                              return [
                                                _c(
                                                  "form",
                                                  {
                                                    ref: "cotizacion",
                                                    on: {
                                                      submit: function (
                                                        $event
                                                      ) {
                                                        $event.preventDefault()
                                                        handleSubmit(
                                                          _vm.confirmaCotizacion()
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("Cotizacion", {
                                                      attrs: {
                                                        ratificacion:
                                                          _vm.ratificacion,
                                                      },
                                                      on: {
                                                        submit: function (
                                                          $event
                                                        ) {
                                                          $event.preventDefault()
                                                        },
                                                        allChecksPassed:
                                                          function ($event) {
                                                            return _vm.submitForm(
                                                              "cotizacion"
                                                            )
                                                          },
                                                        node: _vm.selected,
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        3306823906
                                      ),
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }