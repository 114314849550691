var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c(
        "b-modal",
        {
          ref: "pago-modal",
          attrs: {
            "no-close-on-backdrop": "",
            centered: "",
            title: "",
            "hide-footer": "",
            id: "modalC",
            size: "xl",
          },
        },
        [
          _c("p", { attrs: { id: "titulo" } }, [_vm._v("Forma de pago")]),
          _c(
            "b-tabs",
            { attrs: { fill: "" } },
            [
              _c(
                "b-tab",
                { attrs: { title: "Tarjeta de crédito o débito", active: "" } },
                [
                  _c("div", { staticClass: "mt-3 center" }, [
                    _vm._v(
                      " Correduría Digital no guarda nigún dato de la compra. Todo se efectúa a través de la plataforma de OpenPay. "
                    ),
                  ]),
                  _c("br"),
                  _c("ValidationObserver", {
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ handleSubmit }) {
                          return [
                            _c(
                              "form",
                              {
                                on: {
                                  submit: function ($event) {
                                    $event.preventDefault()
                                    return handleSubmit(_vm.payCotizacion)
                                  },
                                },
                              },
                              [
                                _c("Openpay", {
                                  attrs: {
                                    cotizacion_id:
                                      _vm.ratificacion.cotizaciones_id,
                                    precio_total: _vm.ratificacion.precio_total,
                                  },
                                  on: {
                                    submit: function ($event) {
                                      $event.preventDefault()
                                    },
                                    payCotizacion: function ($event) {
                                      return _vm.payCotizacion($event)
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c(
                "b-tab",
                {
                  staticClass: "modalPay",
                  attrs: { title: "SPEI/Transferencia" },
                },
                [
                  _c("ValidationObserver", {
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ handleSubmit }) {
                          return [
                            _c(
                              "form",
                              {
                                on: {
                                  submit: function ($event) {
                                    $event.preventDefault()
                                    return handleSubmit(_vm.payCotizacionSpei)
                                  },
                                },
                              },
                              [
                                _c("Spei", {
                                  attrs: {
                                    cotizacion_id:
                                      _vm.ratificacion.cotizaciones_id,
                                    precio_total: _vm.ratificacion.precio_total,
                                    proceso_id:
                                      _vm.ratificacion.ratificaciones_id,
                                    tipo: "ratificacion",
                                  },
                                  on: {
                                    submit: function ($event) {
                                      $event.preventDefault()
                                    },
                                    payCotizacionSpei: function ($event) {
                                      return _vm.payCotizacionSpei($event)
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c(
                "b-tab",
                { staticClass: "modalPay", attrs: { title: "Monedero" } },
                [
                  _c("ValidationObserver", {
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ handleSubmit }) {
                          return [
                            _c(
                              "form",
                              {
                                on: {
                                  submit: function ($event) {
                                    $event.preventDefault()
                                    return handleSubmit(_vm.payCotizacion)
                                  },
                                },
                              },
                              [
                                _c("Monedero", {
                                  attrs: {
                                    cotizacion_id:
                                      _vm.ratificacion.cotizaciones_id,
                                    precio_total: _vm.ratificacion.precio_total,
                                    clientes_id: _vm.ratificacion.clientes_id,
                                    servicio:
                                      "Ratificacion " +
                                      _vm.ratificacion.cotizaciones_id,
                                  },
                                  on: {
                                    submit: function ($event) {
                                      $event.preventDefault()
                                    },
                                    payCotizacion: function ($event) {
                                      return _vm.payCotizacionMonedero($event)
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          ref: "confirmar-facturacion-modal",
          attrs: {
            "no-close-on-backdrop": "",
            id: "confirmar-facturacion-modal",
            centered: "",
            "hide-footer": "",
          },
        },
        [
          _c("h4", { staticClass: "text-center" }, [
            _vm._v(" ¿Desea facturar su pago? "),
          ]),
          _c("h5", { staticClass: "text-center" }),
          _c(
            "b-button",
            {
              staticClass: "mt-3",
              attrs: { variant: "danger" },
              on: {
                click: function ($event) {
                  return _vm.confirmGenerarFactura(false)
                },
              },
            },
            [_vm._v(" Cancelar ")]
          ),
          _c(
            "b-button",
            {
              staticClass: "mt-3 float-right",
              attrs: { variant: "primary" },
              on: {
                click: function ($event) {
                  return _vm.confirmGenerarFactura(true)
                },
              },
            },
            [_vm._v(" OK ")]
          ),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          ref: "datos-fiscales-modal",
          attrs: {
            "no-close-on-backdrop": "",
            centered: "",
            "hide-footer": "",
            "hide-header": "",
            id: "datos-fiscales-modal",
            size: "lg",
          },
          on: {
            close: function ($event) {
              $event.preventDefault()
            },
          },
        },
        [
          _c("FacturacionComponent", {
            attrs: { datosFacturacion: _vm.datosFacturacion },
            on: {
              closedFacturacionEvent: function ($event) {
                return _vm.closedFacturacion($event)
              },
              canceledFacturacionEvent: function ($event) {
                return _vm.canceledFacturacion()
              },
            },
          }),
        ],
        1
      ),
      _c("h4", { staticClass: "cardD" }, [_vm._v("Pago")]),
      _c("div", [
        _vm._m(0),
        _c("div", [
          _c(
            "div",
            {},
            [
              _c(
                "b-button",
                {
                  staticClass: "btn-fill orange-btn bottom2",
                  attrs: { size: "m" },
                  on: {
                    click: function ($event) {
                      return _vm.openPagoModal()
                    },
                  },
                },
                [_vm._v(" Continuar al pago ")]
              ),
            ],
            1
          ),
        ]),
      ]),
      _c("div", [
        _vm.forma_pago == 2
          ? _c("div", [
              _c("p", [
                _vm._v(
                  " Se envío un correo con la información de pago a la dirección de correo electrónico registrada en el sistema. Por favor revise su bandeja de entrada y siga las instrucciones para completar el pago. "
                ),
              ]),
            ])
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _vm._v(" Su pago será procesado a través de "),
      _c("a", { attrs: { href: "https://www.openpay.mx", target: "_blank" } }, [
        _vm._v("Openpay"),
      ]),
      _vm._v(
        ", una plataforma segura y confiable para realizar transacciones electrónicas. "
      ),
      _c("a", { attrs: { href: "https://www.openpay.mx", target: "_blank" } }, [
        _vm._v("Openpay"),
      ]),
      _vm._v(
        " garantiza la protección de sus datos y la integridad de la transacción. Para más información sobre Openpay y sus servicios, puede visitar su "
      ),
      _c(
        "a",
        {
          attrs: { href: "https://www.openpay.mx/servicios", target: "_blank" },
        },
        [_vm._v("página de servicios")]
      ),
      _vm._v(" o leer más sobre su "),
      _c(
        "a",
        {
          attrs: { href: "https://www.openpay.mx/nosotros", target: "_blank" },
        },
        [_vm._v("empresa")]
      ),
      _vm._v(". "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }