<template>
  <div class="container">
    <h4 class="cardD">Cotización</h4>

    <h4 class="cardD">Resumen de Notificación</h4>

    <table class="table table-bordered table-hover table-fixed">
      <thead class="">
        <tr>
          <th class="check-col">Check</th>
          <th class="descripcion-col">Descripción</th>
          <th class="precio-col">Precio</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td class="">✓</td>
          <td class="">Costo por zona indicada</td>
          <td class="font-weight-bold precio-col-container">{{ formattedCostoZona }}</td>
        </tr>
      </tbody>
    </table>

    <h4 class="cardD mt-1" v-if="cantidad_adicionales_fisicas > 0">Solicitantes Adicionales</h4>
    <div v-if="cantidad_adicionales_fisicas > 0">
      <!-- <label class="grey col-md-1">{{ cantidad_adicionales_fisicas }}</label>
      <label class="grey col-md-5"> Personas Físicas Adicionales </label>
      <label class="grey col-md-6">${{ precio_total_adicional_fisica }}</label> -->
      <table class="table table-bordered table-hover table-fixed">
        <thead class="">
          <tr>
            <th class="check-col">Cantidad</th>
            <th class="descripcion-col">Descripción</th>
            <th class="precio-col">Precio</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="">{{ cantidad_adicionales_fisicas }}</td>
            <td class="">Persona Física Adicional</td>
            <td class="font-weight-bold precio-col-container">{{ formattedPersonasAdicionales }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="" v-if="cantidad_adicionales_morales > 0">
      <!-- <label class="grey col-md-1">{{ cantidad_adicionales_morales }}</label>
      <label class="grey col-md-5"> Personas Morales Adicionales </label>
      <label class="grey col-md-6">${{ precio_total_adicional_moral }}</label> -->
      <table class="table table-bordered table-hover table-fixed">
        <thead class="">
          <tr>
            <th class="check-col">Cantidad</th>
            <th class="descripcion-col">Descripción</th>
            <th class="precio-col">Precio</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="">{{ cantidad_adicionales_morales }}</td>
            <td class="">Persona Moral Adicional</td>
            <td class="font-weight-bold">{{ formattedPersonaMoralAdicional }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="" v-if="cantidad_adicionales_representantes > 0">
      <!-- <label class="grey col-md-1">{{ cantidad_adicionales_representantes }}</label>
      <label class="grey col-md-5"> Representantes Adicionales </label>
      <label class="grey col-md-6">${{ precio_total_adicional_representante }}</label> -->
      <table class="table table-bordered table-hover table-fixed">
        <thead class="">
          <tr>
            <th class="check-col">Cantidad
            </th>
            <th class="descripcion-col">Descripción</th>
            <th class="precio-col">Precio</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="">{{ cantidad_adicionales_representantes }}</td>
            <td class="">Representante Adicional</td>
            <td class="font-weight-bold precio-col-container">{{ formattedRepresentanteAdicional }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <div v-if="subtotal_solicitantes > 0" class="mt-2">
      <!-- <label class="grey col-md-6"> Subtotal </label>
      <label class="grey col-md-6">${{ subtotal_solicitantes }}</label> -->
      <div class="d-flex justify-content-around">
        <div>
          <p class="font-weight-bold">Subtotal</p>
        </div>
        <div>
          <p class="orange-text">{{ subtotal_solicitantes }}</p>
        </div>
      </div>
    </div>

    <h4 class="cardD mt-1" v-if="subtotal_documentos > 0">Documentos</h4>
    <div class="mt-2" v-if="cantidad_documentos_adicionales > 0">
      <!-- <label class="grey col-md-1">{{ cantidad_documentos_adicionales }}</label>
      <label class="grey col-md-5"> Documentos Adicionales </label>
      <label class="grey col-md-6">${{ precio_total_documentos_adicionales }}</label> -->
      <table class="table table-bordered table-hover table-fixed">
        <thead class="">
          <tr>
            <th class="check-col">Cantidad</th>
            <th class="descripcion-col">Descripción</th>
            <th class="precio-col">Precio</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="">{{ cantidad_documentos_adicionales }}</td>
            <td class="">Documentos Adicionales</td>
            <td class="font-weight-bold precio-col-container">{{ formattedDocumentosAdicionales }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="" v-if="notificacion.fad">
      <!-- <label class="grey col-md-1">✓</label>
      <label class="grey col-md-5"> Firma Autógrafa Digital </label>
      <label class="grey col-md-6" v-if="notificacion.fad">${{ precio_fad }}</label> -->
      <table class="table table-bordered table-hover table-fixed">
        <thead class="">
          <tr>
            <th class="check-col">Check</th>
            <th class="descripcion-col">Descripción</th>
            <th class="precio-col">Precio</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="">✓</td>
            <td class="">Firma Autógrafa Digital</td>
            <td class="font-weight-bold precio-col-container">{{ formattedFad }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="mt-2" v-if="paginas_extra > 0">
      <!-- <label class="grey col-md-1">{{ paginas_extra }}</label>
      <label class="grey col-md-5"> Paginas Adicionales </label>
      <label class="grey col-md-6">${{ precio_total_paginas_extra }}</label> -->
      <table class="table table-bordered table-hover table-fixed">
        <thead class="">
          <tr>
            <th class="check-col">Cantidad</th>
            <th class="descripcion-col">Descripción</th>
            <th class="precio-col">Precio</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="">{{ paginas_extra }}</td>
            <td class="">Páginas Adicionales</td>
            <td class="font-weight-bold precio-col-container">{{ formattedPaginasExtra }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="" v-if="notificacion.contenido_digital">
      <!-- <label class="grey col-md-1">✓</label>
      <label class="grey col-md-5"> Contenido Digital </label>
      <label class="grey col-md-6" v-if="notificacion.contenido_digital">${{ precio_contenido_digital }}</label> -->
      <table class="table table-bordered table-hover table-fixed">
        <thead class="">
          <tr>
            <th class="check-col">Check</th>
            <th class="descripcion-col">Descripción</th>
            <th class="precio-col">Precio</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="">✓</td>
            <td class="">Contenido Digital</td>
            <td class="font-weight-bold precio-col-container">{{ formattedContenidoDigital }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- <div class="mt-2" v-if="subtotal_documentos > 0">
      <div class="d-flex justify-content-around">
        <div>
          <p class="font-weight-bold">Subtotal</p>
        </div>
        <div>
          <p class="orange-text">{{ formattedSubtotalDocumentos }}</p>
        </div>
      </div>
    </div> -->
    <h4 class="cardD">Agregar Extras</h4>

    <div class="row mt-4">
      <div class="col-md-12">
        <div style="display: flex; align-items: center;">
          <b-form-checkbox v-model="notificacion.acta_express">
            Generación de acta y entregable en plazo de 2-4 días hábiles
          </b-form-checkbox>
        </div>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col-md-12">
        <div style="display: flex; align-items: center;">
          <b-form-checkbox v-model="notificacion.envio_domicilio">
            Envío a domicilio del entregable
          </b-form-checkbox>
        </div>
      </div>
    </div>

    <hr v-if="subtotal_extras > 0">

    <h4 class="cardD" v-if="subtotal_extras > 0">Extras</h4>

    <div class="" v-if="notificacion.acta_express">
      <!-- <label class="grey col-md-1">✓</label>
      <label class="grey col-md-5"> Generación de acta y entregable en plazo de 2-4 días hábiles </label>
      <label class="grey col-md-6" v-if="notificacion.acta_express">${{ Contenido Digital }}</label> -->
      <table class="table table-bordered table-hover">
        <thead class="">
          <tr>
            <th class="check-col">Check</th>
            <th class="descripcion-col">Descripción</th>
            <th class="precio-col">Precio</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td scope="row" class="">✓</td>
            <td class="">Generación de acta y entregable en plazo de 2-4 días hábiles</td>
            <td class="font-weight-bold precio-col-container">{{ formattedActaExpress }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="" v-if="notificacion.envio_domicilio">
      <!-- <label class="grey col-md-1" v-if="notificacion.envio_domicilio">✓</label>
      <label class="grey col-md-5"> Envío a domicilio del entregable </label>
      <label class="grey col-md-6">$500</label> -->
      <table class="table table-bordered table-hover">
        <thead class="">
          <tr>
            <th class="check-col">Check</th>
            <th class="descripcion-col">Descripción</th>
            <th calss="precio-col">Precio</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td scope="row" class="">✓</td>
            <td class="">Envío a domicilio del entregables</td>
            <td class="font-weight-bold precio-col-container">$ 500.00</td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- <div class="mt-2" v-if="subtotal_extras > 0">
      <div class="d-flex justify-content-around">
        <div>
          <p class="font-weight-bold">Subtotal</p>
        </div>
        <div>
          <p class="orange-text">{{ formattedSubtotalExtras }}</p>
        </div>
      </div>
    </div> -->

    <h4 class="cardD mt-1" v-if="notificacion.envio_domicilio">Descuentos</h4>
    <div class="" v-if="notificacion.envio_domicilio">
      <!-- <label class="grey col-md-1"></label>
      <label class="grey col-md-5"> Envío a domicilio del entregable </label>
      <label class="grey col-md-6"> - $500</label> -->
      <table class="table table-bordered table-hover">
        <thead class="">
          <tr>
            <th class="check-col">Check</th>
            <th class="descripcion-col">Descripción</th>
            <th class="precio-col">Precio</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="">✓</td>
            <td class="">Envío a domicilio del entregable</td>
            <td class="font-weight-bold precio-col-container">- $500.00</td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- <div class="mt-2" v-if="notificacion.envio_domicilio">
      <div class="d-flex justify-content-around">
        <div>
          <p class="font-weight-bold">Subtotal</p>
        </div>
        <div>
          <p class="orange-text">- $500.00</p>
        </div>
      </div>
    </div> -->

    <h4 class="cardD mt-1">Cupones</h4>
    <div class="row">
      <div class="col-12">
        <base-input type="text" label="Si cuenta con un cupón puede aplicarlo." placeholder="Cupón de descuento"
          :disabled="cuponAplicado" v-model="nombreCuponDescuento"></base-input>
      </div>
      <div class="col-12 d-flex">
        <br />
        <b-button class="orange-btn" size="m" :disabled="cuponAplicado"
          @click="validarCupon(nombreCuponDescuento)">Aplicar</b-button>
      </div>
    </div>
    <!-- <div class="row">
      <div :hidden="!cuponAplicado">
        <b-button
          class="btn btn-fill"
          variant="danger"
          size="m"
          @click.prevent="borrarCupon()"
        >
          Borrar cupon
        </b-button>
      </div>
    </div> -->
    <!-- <h4 class="cardD mt-5 font-weight-bold">Total</h4>
    <div class="mt-2">
      <div class="font-weight-bold total">
        <div>
          <p class="orange-text">${{ this.notificacion.costo_total_antes_descuentos }} -- Extras {{ subtotal_extras }} --
            {{ totalAPagarSinDescuento }}
          </p>
        </div>
      </div>
    </div> -->

    <div class="mt-1" v-if="cuponAplicado">
      <div class="container">
        <div class="row justify-content-end">
          <div class="col-3">
            <table class="table table-bordered table-hover">
              <thead class="">
                <tr>
                  <th class="precio-col">Descuento del cupón {{ cantidadDescuentoCupon }}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="font-weight-bold precio-col-container">{{ formattedValorCupon }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-3">
      <!--
      v-if="this.cuponAplicado || this.notificacion.costo_total > this.notificacion.costo_total_antes_descuentos || this.notificacion.envio_domicilio"
      <div class="col-md-6">
        <h4 class="cardD">Total</h4>
      </div>
      <div class="col-md-6">
        <h4 class="cardD">{{ formattedTotal }}</h4>
      </div> -->
      <!-- <div class="d-flex justify-content-around">
        <div>
          <p class="font-weight-bold">Total</p>
        </div>
        <div>
          <p class="orange-text font-weight-bolder">{{ formattedTotal }}</p>
        </div>
      </div> -->
      <h4 class="cardD mt-3 font-weight-bold">{{ textoTotalAPagar }}</h4>
      <div class="mt-2">
        <div class="font-weight-bold total">
          <div>
            <p class="orange-text">{{ formattedTotal }}</p>
          </div>
        </div>
      </div>
    </div>

    <hr>

    <div class="row mt-4">
      <div class="col-md-12 d-flex">
        <ValidationProvider name="validacionDatos" :rules="{ required: { allowFalse: false } }" v-slot="{ errors }"
          :custom-messages="{ required: 'La confirmación es obligatoria' }">
          <div style="display: flex;">
            <b-form-checkbox id="checkbox-validacionDatos" v-model="validacionDatos" class="orange">
              Confirmo que la información proporcionada es correcta.
            </b-form-checkbox>
          </div>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>

    <div class="row mt-4" v-if="!notificacion.fad">
      <div class="col-md-12">
        <ValidationProvider name="validacionPaginas" :rules="{ required: { allowFalse: false } }" v-slot="{ errors }"
          :custom-messages="{ required: 'La confirmación es obligatoria' }">
          <div style="display: flex; align-items: center;">
            <b-form-checkbox id="checkbox-validacionPaginas" v-model="validacionPaginas" class="orange">
              Acepto que debo hacer llegar los documentos originales a la oficina que me sea señalada, o bien a la
              oficina de Correduría Digital, para que se pueda llevar a cabo la diligencia.
            </b-form-checkbox>
          </div>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col-md-12">
        <ValidationProvider name="validacionTerminos" :rules="{ required: { allowFalse: false } }" v-slot="{ errors }"
          :custom-messages="{ required: 'La confirmación es obligatoria' }">
          <div style="display: flex;">
            <b-form-checkbox id="checkbox-validacionTerminos" v-model="validacionTerminos" class="orange">
              Acepto los Términos y condiciones.
            </b-form-checkbox>
          </div>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col-md-12">
        <ValidationProvider name="validacionMercantil" :rules="{ required: { allowFalse: false } }" v-slot="{ errors }"
          :custom-messages="{ required: 'La confirmación es obligatoria' }">
          <div style="display: flex;">
            <b-form-checkbox id="checkbox-validacionMercantil" v-model="validacionMercantil" class="orange">
              Confirmo que el o los documentos que serán notificados son de carácter mercantil y que alguna de las
              partes (emisor o notificado) es comerciante.
            </b-form-checkbox>
          </div>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col-md-12">
        <ValidationProvider name="validacionInfoBase" :rules="{ required: { allowFalse: false } }" v-slot="{ errors }"
          :custom-messages="{ required: 'La confirmación es obligatoria' }">
          <div style="display: flex;">
            <b-form-checkbox id="checkbox-validacionInfoBase" v-model="validacionInfoBase" class="orange">
              Una vez aceptada la cotización, las condiciones de la notificación solicitada no podrán cambiar.
            </b-form-checkbox>
          </div>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>

    <div class="mt-4 d-flex justify-content-end">
      <div class="row">
        <div class="col-12">
          <b-button type="submit" class="orange-btn" size="m">Pagar</b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// API
import { formatCurrency } from "@/utils/number-utils";

import {
  CatGastosApi,
  CuponesApi
} from "@/api";

export default {
  name: "Cotizacion",

  props: {
    notificacion: { type: Object }
  },

  created() {
    this.loading = true;
    this.getGastos()
      .then(() => this.calculaSolicitantes())
      .then(() => this.calculaSubtotalSolicitantes())
      .then(() => this.calculaDocumentos())
      .then(() => this.calculaSubtotalDocumentos())
      .then(() => this.calculaTotal())
      .catch((error) => {
        console.log(error);
        this.showErrorToast('No se pudieron obtener los totales. Intente más tarde.')
      })
      .finally(() => { this.loading = false; });

    this.costoTotalAntesCupon = this.notificacion.costo_total_antes_descuentos;
  },

  data() {
    return {
      loading: false,

      // Costos en BD
      cat_gastos: null,
      precio_acta: 0,
      precio_adicional_fisica: 0,
      precio_adicional_moral: 0,
      precio_adicional_representante: 0,
      precio_fad: 0,
      precio_pagina_adicional: 0,
      precio_contenido_digital: 0,
      precio_documento_adicional: 0,
      // Costos pre-calculados
      precio_zona: 0,

      // Cantidades
      cantidad_adicionales_fisicas: 0,
      cantidad_adicionales_morales: 0,
      cantidad_adicionales_representantes: 0,
      cantidad_documentos_adicionales: 0,
      paginas_totales: 0,
      paginas_extra: 0,

      // Costos totales = Cantidad * Precio
      precio_total_adicional_fisica: 0,
      precio_total_adicional_moral: 0,
      precio_total_adicional_representante: 0,
      precio_total_documentos_adicionales: 0,
      precio_total_paginas_extra: 0,

      // Subtotales
      subtotal_zona: 0,
      subtotal_solicitantes: 0,
      subtotal_documentos: 0,
      subtotal_extras: 0,

      // Casillas de validacion
      validacionDatos: false,
      validacionTerminos: false,
      validacionPaginas: false,
      validacionMercantil: false,
      validacionInfoBase: false,

      nombreCuponDescuento: "",
      nombreCuponReferencia: "",
      cuponAplicado: false,
      costoTotalAntesCupon: 0,
      cantidadDescuentoCupon: "",
      cantidadDescontadaCupon: 0,
    }
  },

  watch: {
    'notificacion.acta_express': {
      handler(newValue) {
        this.recalculaExtras();
      },
      immediate: true,
    },
    'notificacion.envio_domicilio': {
      handler(newValue) {
        this.recalculaExtras();
      },
      immediate: true,
    },
  },

  computed: {
    formattedCostoZona() {
      return formatCurrency(this.notificacion.costo_zona);
    },
    formattedPersonasAdicionales() {
      return formatCurrency(this.precio_adicional_fisica);
    },
    formattedPersonaMoralAdicional() {
      return formatCurrency(this.precio_total_adicional_moral);
    },
    formattedRepresentanteAdicional() {
      return formatCurrency(this.precio_total_adicional_representante);
    },
    formattedDocumentosAdicionales() {
      return formatCurrency(this.precio_total_documentos_adicionales);
    },
    formattedFad() {
      return formatCurrency(this.precio_fad);
    },
    formattedPaginasExtra() {
      return formatCurrency(this.precio_total_paginas_extra);
    },
    formattedContenidoDigital() {
      return formatCurrency(this.precio_contenido_digital);
    },
    formattedSubtotalDocumentos() {
      return formatCurrency(this.subtotal_documentos);
    },
    // TODO: Revisar porque no funciona
    formattedBeforeDiscount() {
      return formatCurrency(this.notificacion.costo_total_antes_descuentos);
    },
    formattedActaExpress() {
      return formatCurrency(this.precio_acta);
    },
    formattedSubtotalExtras() {
      return formatCurrency(this.subtotal_extras);
    },
    formattedTotal() {
      return formatCurrency(this.notificacion.costo_total)
    },
    textoTotalAPagar() {
      let texto = "Total a pagar";
      if ((this.notificacion.acta_express || this.notificacion.envio_domicilio) && this.cuponAplicado) {
        texto += " con Extras y descuentos";
      } else if (!this.notificacion.acta_express && !this.notificacion.envio_domicilio && this.cuponAplicado) {
        texto += " con descuento";
      } else if (this.notificacion.acta_express || this.notificacion.envio_domicilio) {
        texto += " con Extras";
      }
      return texto;
    },
    totalAPagarSinDescuento() {
      return formatCurrency(this.notificacion.costo_total + this.subtotal_extras);
    },
    formattedValorCupon() {
      return formatCurrency(this.cantidadDescontadaCupon * -1);
    }
  },

  methods: {
    showErrorToast(msg) {
      this.$toast.error(msg, {
        timeout: 4000, //false,
        hideProgressBar: false,
        position: "top-right",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        closeButton: "button",
        icon: true,
      });
    },

    async getGastos() {
      const { value } = await CatGastosApi.filtraProcesos({
        page: 0,
        registersPerPage: 0,
        cat_servicios_id: "2"
      });

      this.cat_gastos = value;

      for (const gasto in this.cat_gastos) {
        if (this.cat_gastos[gasto]['clave'] === 'acta_express') {
          this.precio_acta = this.cat_gastos[gasto]['costo'];
        }
        if (this.cat_gastos[gasto]['clave'] === 'adicional_persona_fisica') {
          this.precio_adicional_fisica = this.cat_gastos[gasto]['costo'];
        }
        if (this.cat_gastos[gasto]['clave'] === 'adicional_persona_moral') {
          this.precio_adicional_moral = this.cat_gastos[gasto]['costo'];
        }
        if (this.cat_gastos[gasto]['clave'] === 'adicional_representante') {
          this.precio_adicional_representante = this.cat_gastos[gasto]['costo'];
        }
        if (this.cat_gastos[gasto]['clave'] === 'pagina_adicional') {
          this.precio_pagina_adicional = this.cat_gastos[gasto]['costo'];
        }
        if (this.cat_gastos[gasto]['clave'] === 'contenido_digital') {
          this.precio_contenido_digital = this.cat_gastos[gasto]['costo'];
        }
        if (this.cat_gastos[gasto]['clave'] === 'fad_documento_principal') {
          this.precio_fad = this.cat_gastos[gasto]['costo'];
        }
        if (this.cat_gastos[gasto]['clave'] === 'documento_adicional') {
          this.precio_documento_adicional = this.cat_gastos[gasto]['costo'];
        }
      }
      this.subtotal_zona = this.notificacion.costo_zona;
    },

    calculaSolicitantes() {
      return;
      // // Identifica tipo de persona. Ignora solicitante principal
      // this.notificacion.solicitantes.forEach(element => {
      //   if (element.principal) {
      //     return;
      //   }
      //   if (element.persona === 'Moral') {
      //     this.cantidad_adicionales_morales += 1;
      //   }
      //   if (element.persona === 'Física') {
      //     if (element.representada) {
      //       this.cantidad_adicionales_representantes += 1;
      //     } else {
      //       this.cantidad_adicionales_fisicas += 1;
      //     }
      //   }
      // });
      // // Se reducen los representantes incluidos (2)
      // this.cantidad_adicionales_representantes = Math.max(this.cantidad_adicionales_representantes - 2, 0);
      // return;
    },

    calculaSubtotalSolicitantes() {
      this.precio_total_adicional_fisica = this.precio_adicional_fisica * this.cantidad_adicionales_fisicas;
      this.precio_total_adicional_moral = this.precio_adicional_moral * this.cantidad_adicionales_morales;
      this.precio_total_adicional_representante = this.precio_adicional_representante * this.cantidad_adicionales_representantes;
      this.subtotal_solicitantes = this.precio_total_adicional_fisica +
        this.precio_total_adicional_moral +
        this.precio_total_adicional_representante;
      return;
    },

    calculaDocumentos() {
      // Calcula los documentos principales eliminando 1 incluido
      this.cantidad_documentos_adicionales = this.notificacion.documentos.length - 1;
      // Descarta los anexos
      this.notificacion.documentos.forEach(documento => {
        if (documento.anexoDe) {
          this.cantidad_documentos_adicionales -= 1;
        }
      });
      // Cantidad de paginas totales
      this.notificacion.documentos.forEach(documento => {
        this.paginas_totales += parseInt(documento.paginas);
      });
      this.paginas_extra = Math.max(this.paginas_totales - 50, 0);
      return;
    },

    calculaSubtotalDocumentos() {
      this.precio_total_documentos_adicionales = this.precio_documento_adicional * this.cantidad_documentos_adicionales;
      this.precio_total_paginas_extra = this.paginas_extra * this.precio_pagina_adicional;
      this.subtotal_documentos = this.precio_total_documentos_adicionales + this.precio_total_paginas_extra;

      if (this.notificacion.fad) {
        this.subtotal_documentos = this.subtotal_documentos + this.precio_fad;
      }

      if (this.notificacion.contenido_digital) {
        this.subtotal_documentos = this.subtotal_documentos + this.precio_contenido_digital;
      }

      return;
    },

    calculaTotal() {
      this.notificacion.costo_total_antes_descuentos = this.subtotal_zona + this.subtotal_solicitantes + this.subtotal_documentos;
      this.notificacion.costo_total = this.notificacion.costo_total_antes_descuentos;
    },

    recalculaExtras() {
      this.loading = true;
      this.subtotal_extras = 0;
      this.agregaActaExpress()
        .then(() => this.agregaEnvioDomicilio())
        .then(() => this.calculaTotalPostDescuento())
        .catch((error) => {
          console.log(error);
          this.showErrorToast('No se pudieron obtener los totales. Intente más tarde.')
        })
        .finally(() => { this.loading = false; })
    },

    agregaActaExpress() {
      return new Promise((resolve, reject) => {
        try {
          if (this.notificacion.acta_express) {
            this.subtotal_extras = this.precio_acta;
          }
          resolve();
        } catch (error) {
          reject(error);
        }
      });
    },

    agregaEnvioDomicilio() {
      return new Promise((resolve, reject) => {
        try {
          if (this.notificacion.envio_domicilio) {
            this.subtotal_extras += 500;
          }
          resolve();
        } catch (error) {
          reject(error);
        }
      });
    },

    calculaTotalPostDescuento() {
      return new Promise((resolve, reject) => {
        try {
          this.notificacion.costo_total = this.notificacion.costo_total_antes_descuentos;
          if (this.subtotal_extras > 0) {
            this.notificacion.costo_total += this.subtotal_extras;
          }
          if (this.notificacion.envio_domicilio) {
            this.notificacion.costo_total -= 500;
          }
          resolve();
        } catch (error) {
          reject(error);
        }
      });
    },

    async validarCupon(texto_cupon) {
      try {
        const time_zone = -(new Date().getTimezoneOffset() / 60);
        const data = {
          nombre: texto_cupon,
          timeZone: time_zone
        };
        const {
          id,
          isValid,
          tipo,
          descuento,
          message
        } = await CuponesApi.cuponValido(data);

        if (!isValid) {
          this.$toast.warning(message, {
            timeout: 4000,
            hideProgressBar: false,
            position: "top-right",
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            closeButton: "button",
            icon: true
          });

          return;
        }

        const total = this.notificacion.costo_total;

        if (tipo === "Porcentaje") {
          let descuentoDecimal = descuento;
          if (descuento > 1) {
            descuentoDecimal = descuento / 100;
          }

          let precio_total = total * (1 - descuentoDecimal);
          this.notificacion.costo_total = precio_total;
          this.cantidadDescuentoCupon = "% " + descuentoDecimal * 100;
          this.cantidadDescontadaCupon = total * descuentoDecimal;
        } else if (tipo === "Monto") {
          let precio_total = total - descuento;

          this.notificacion.costo_total = precio_total;
          this.cantidadDescuentoCupon = "$ " + descuento;
          this.cantidadDescontadaCupon = descuento;
        }

        this.cuponAplicado = true;
        this.notificacion.cotizacion.cupones_id = id;

        // En este momento ya se deja al componenete padre actualizar o crear la cotizacion con el nuevo precio

        this.$toast.success("El cupón se aplicó correctamente", {
          timeout: 3000,
          hideProgressBar: false,
          position: "top-right",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          closeButton: "button",
          icon: true
        });
      } catch (error) {
        console.log(error);

        this.$toast.error("Ocurrió un error al aplicar el cupón.", {
          timeout: 3000,
          hideProgressBar: false,
          position: "top-right",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          closeButton: "button",
          icon: true
        });
      }
    },

    borrarCupon() {
      this.notificacion.costo_total = this.notificacion.costo_total_antes_descuentos;
      this.notificacion.cotizacion.cupones_id = 0;
      this.nombreCuponDescuento = "";
      this.cuponAplicado = false;
    },
  }
}
</script>

<style scoped>
.container>>>.form-check-sign {
  display: inline-block;
  padding-top: 20px;
}

.table-fixed {
  table-layout: fixed;
  width: 100%;
}

.table {
  width: 100%;
  border-collapse: collapse;
}

.table th,
.table td {
  padding: 8px;
  text-align: left;
}

.check-col {
  width: 10%;
  /* Ancho ajustado para la columna Check */
}

.descripcion-col {
  width: 70%;
  /* Ajusta este valor según sea necesario */
}

.precio-col {
  width: 20%;
  /* Ajusta este valor según sea necesario */
}

.precio-col-container {
  display: flex;
  justify-content: flex-end;
}

.total {
  font-weight: bold;
  display: flex;
  justify-content: flex-end;
  padding-right: 20px;
}
</style>
