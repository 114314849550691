var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content" },
    [
      _c(
        "b-breadcrumb",
        [
          _c("b-breadcrumb-item", { attrs: { to: { path: "/dashboard" } } }, [
            _vm._v("Inicio"),
          ]),
          _c("b-breadcrumb-item", { attrs: { active: "" } }, [
            _vm._v("Más información"),
          ]),
        ],
        1
      ),
      _c("div", { staticClass: "container my-4" }, [
        _c(
          "section",
          { staticClass: "mb-5", attrs: { id: "descripcion-servicio" } },
          [
            _c("h2", { staticClass: "title" }, [
              _vm._v(_vm._s(_vm.datos.title)),
            ]),
          ]
        ),
        _c(
          "section",
          { staticClass: "mb-4 text-center", attrs: { id: "video" } },
          [
            _c("ModalPreviewVideo", {
              attrs: { "current-documento": _vm.videoObject },
            }),
          ],
          1
        ),
        _c("div", { staticClass: "container" }, [
          _c(
            "div",
            { staticClass: "row justify-content-center" },
            [
              _c(
                "router-link",
                {
                  staticClass: "route",
                  attrs: { to: { path: _vm.mapServiceToRoute } },
                },
                [
                  _c("button", { staticClass: "btn orange-btn" }, [
                    _vm._v(" Iniciar proceso "),
                  ]),
                ]
              ),
            ],
            1
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }