var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "content" }, [
    _c("div", { staticClass: "container-fluid mt-5 mb-5" }, [
      _vm._m(0),
      _c("div", { staticClass: "mb-3" }),
      _c("div", { staticClass: "row justify-content-around" }, [
        _c(
          "div",
          { staticClass: "col-md-3" },
          [
            _c(
              "b-card",
              [
                _c("template", { slot: "header" }, [
                  _c("h4", { staticClass: "card-title text-center" }, [
                    _vm._v("John Doe"),
                  ]),
                ]),
                _c("div", { staticClass: "text-center long" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "star-rating d-flex justify-content-center mb-3",
                    },
                    [
                      _c("span", { staticClass: "star" }, [_vm._v("★")]),
                      _c("span", { staticClass: "star" }, [_vm._v("★")]),
                      _c("span", { staticClass: "star" }, [_vm._v("★")]),
                      _c("span", { staticClass: "star" }, [_vm._v("★")]),
                      _c("span", { staticClass: "star" }, [_vm._v("★")]),
                    ]
                  ),
                  _c("p", [
                    _vm._v(
                      ' "This service is fantastic! The team was incredibly professional and exceeded all my expectations." '
                    ),
                  ]),
                ]),
              ],
              2
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-md-3" },
          [
            _c(
              "b-card",
              [
                _c("template", { slot: "header" }, [
                  _c("h4", { staticClass: "card-title text-center" }, [
                    _vm._v("John Doe"),
                  ]),
                ]),
                _c("div", { staticClass: "text-center long" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "star-rating d-flex justify-content-center mb-3",
                    },
                    [
                      _c("span", { staticClass: "star" }, [_vm._v("★")]),
                      _c("span", { staticClass: "star" }, [_vm._v("★")]),
                      _c("span", { staticClass: "star" }, [_vm._v("★")]),
                      _c("span", { staticClass: "star" }, [_vm._v("★")]),
                      _c("span", { staticClass: "star" }, [_vm._v("★")]),
                    ]
                  ),
                  _c("p", [
                    _vm._v(
                      ' "This service is fantastic! The team was incredibly professional and exceeded all my expectations." '
                    ),
                  ]),
                ]),
              ],
              2
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-md-3" },
          [
            _c(
              "b-card",
              [
                _c("template", { slot: "header" }, [
                  _c("h4", { staticClass: "card-title text-center" }, [
                    _vm._v("John Doe"),
                  ]),
                ]),
                _c("div", { staticClass: "text-center long" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "star-rating d-flex justify-content-center mb-3",
                    },
                    [
                      _c("span", { staticClass: "star" }, [_vm._v("★")]),
                      _c("span", { staticClass: "star" }, [_vm._v("★")]),
                      _c("span", { staticClass: "star" }, [_vm._v("★")]),
                      _c("span", { staticClass: "star" }, [_vm._v("★")]),
                      _c("span", { staticClass: "star" }, [_vm._v("★")]),
                    ]
                  ),
                  _c("p", [
                    _vm._v(
                      ' "This service is fantastic! The team was incredibly professional and exceeded all my expectations." '
                    ),
                  ]),
                ]),
              ],
              2
            ),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-md-4" }, [
        _c("h3", { staticClass: "text-warning" }, [
          _vm._v("Testimonios y reseñas"),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }