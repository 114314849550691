var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content" },
    [
      _c(
        "b-breadcrumb",
        [
          _c("b-breadcrumb-item", { attrs: { active: "" } }, [
            _vm._v("Procesos Cliente"),
          ]),
        ],
        1
      ),
      _c("div", { staticClass: "container" }, [
        _vm.loading
          ? _c("div", [
              _c(
                "div",
                { attrs: { id: "loader" } },
                [
                  _c("font-awesome-icon", {
                    attrs: { icon: "spinner", spin: "" },
                  }),
                ],
                1
              ),
            ])
          : _vm._e(),
      ]),
      _c(
        "b-modal",
        {
          ref: "facturacion-modal",
          attrs: {
            "no-close-on-backdrop": "",
            centered: "",
            "hide-footer": "",
            "hide-header": "",
            id: "facturacion-modal",
            size: "lg",
          },
          on: {
            close: function ($event) {
              $event.preventDefault()
            },
          },
        },
        [
          _c("FacturacionComponent", {
            attrs: { datosFacturacion: _vm.datosFacturacion },
            on: {
              closedFacturacionEvent: function ($event) {
                return _vm.closedFacturacion($event)
              },
              canceledFacturacionEvent: function ($event) {
                return _vm.canceledFacturacion()
              },
            },
          }),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          ref: "estatus-modal",
          attrs: {
            "no-close-on-backdrop": "",
            centered: "",
            id: "estatus-modal",
            size: "xl",
            "ok-only": "",
            "ok-variant": "success",
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "b-carousel",
                {
                  attrs: {
                    id: "carousel-notificaciones-1",
                    interval: 0,
                    indicators: "",
                    background: "#ababab",
                  },
                },
                [
                  _c("b-carousel-slide", {
                    attrs: { "img-src": _vm.statusImgUrl },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c("div", { staticClass: "container-fluid" }, [
        _c(
          "div",
          [
            _c("b-card", { attrs: { "no-body": "" } }, [
              _c(
                "div",
                { staticClass: "p-4" },
                [
                  _c(
                    "div",
                    { staticClass: "row mb-1" },
                    [
                      _c(
                        "b-form-group",
                        {
                          staticClass: "col-4",
                          attrs: {
                            label: "Buscar",
                            "label-for": "search-input",
                          },
                        },
                        [
                          _c(
                            "b-input-group",
                            [
                              _c("b-form-input", {
                                attrs: {
                                  id: "search-input",
                                  placeholder: "Ingrese un término de búsqueda",
                                },
                                model: {
                                  value: _vm.searchData,
                                  callback: function ($$v) {
                                    _vm.searchData = $$v
                                  },
                                  expression: "searchData",
                                },
                              }),
                              _c(
                                "b-input-group-append",
                                [
                                  _c(
                                    "b-button",
                                    {
                                      staticClass: "orange-btn",
                                      attrs: { disabled: _vm.isTableBusy },
                                      on: { click: _vm.handleSearchData },
                                    },
                                    [_vm._v("Buscar")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col-4" },
                      [
                        _c("b-form-select", {
                          staticClass: "custom-select",
                          attrs: { options: _vm.optionsCatServicios },
                          scopedSlots: _vm._u([
                            {
                              key: "first",
                              fn: function () {
                                return [
                                  _c(
                                    "b-form-select-option",
                                    { attrs: { value: null } },
                                    [_vm._v("Procesos...")]
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ]),
                          model: {
                            value: _vm.selectedProceso,
                            callback: function ($$v) {
                              _vm.selectedProceso = $$v
                            },
                            expression: "selectedProceso",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("b-table", {
                    attrs: {
                      hover: "",
                      fields: _vm.fieldsProcesos,
                      items: _vm.procesos,
                      busy: _vm.isTableBusy,
                      "sort-by": _vm.sortBy,
                      "sort-desc": _vm.sortDesc,
                    },
                    on: {
                      "update:sortBy": function ($event) {
                        _vm.sortBy = $event
                      },
                      "update:sort-by": function ($event) {
                        _vm.sortBy = $event
                      },
                      "update:sortDesc": function ($event) {
                        _vm.sortDesc = $event
                      },
                      "update:sort-desc": function ($event) {
                        _vm.sortDesc = $event
                      },
                      "sort-changed": _vm.sortingChanged,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "cell(index)",
                        fn: function ({ index }) {
                          return [
                            _vm._v(" " + _vm._s(_vm.getIndex(index + 1)) + " "),
                          ]
                        },
                      },
                      {
                        key: "cell(estatus)",
                        fn: function ({ item }) {
                          return [
                            _c(
                              "b-button",
                              {
                                attrs: {
                                  id: "btn-estatus",
                                  variant: "success",
                                  size: "sm",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.openStatusModal(item)
                                  },
                                },
                              },
                              [_c("b-icon-eye")],
                              1
                            ),
                            _c(
                              "b-tooltip",
                              {
                                attrs: {
                                  target: "btn-estatus",
                                  triggers: "hover",
                                },
                              },
                              [_vm._v(" " + _vm._s(item.estatus) + " ")]
                            ),
                          ]
                        },
                      },
                      {
                        key: "cell(acciones)",
                        fn: function ({ item }) {
                          return [
                            _c(
                              "div",
                              { staticClass: "row align-middle" },
                              [
                                item.proceso === "Constitución de sociedades"
                                  ? _c(
                                      "router-link",
                                      {
                                        attrs: {
                                          to: {
                                            path:
                                              "/dashboard/clienttasks/editconstitucion/" +
                                              item.id,
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "b-button",
                                          {
                                            directives: [
                                              {
                                                name: "b-tooltip",
                                                rawName: "v-b-tooltip.hover",
                                                modifiers: { hover: true },
                                              },
                                            ],
                                            staticClass: "editBtn",
                                            attrs: {
                                              variant: "warning",
                                              size: "sm",
                                              title: "Editar",
                                            },
                                          },
                                          [_c("b-icon-pencil")],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                item.proceso === "Formalización de actas"
                                  ? _c(
                                      "router-link",
                                      {
                                        attrs: {
                                          to: {
                                            path:
                                              "/dashboard/clienttasks/editformalizacion/" +
                                              item.id,
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "b-button",
                                          {
                                            directives: [
                                              {
                                                name: "b-tooltip",
                                                rawName: "v-b-tooltip.hover",
                                                modifiers: { hover: true },
                                              },
                                            ],
                                            staticClass: "editBtn",
                                            attrs: {
                                              variant: "warning",
                                              size: "sm",
                                              title: "Editar",
                                            },
                                          },
                                          [_c("b-icon-pencil")],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                item.proceso === "Notificaciones"
                                  ? _c(
                                      "router-link",
                                      {
                                        attrs: {
                                          to: {
                                            path:
                                              "/dashboard/clienttasks/editnotificacion/" +
                                              item.id,
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "b-button",
                                          {
                                            directives: [
                                              {
                                                name: "b-tooltip",
                                                rawName: "v-b-tooltip.hover",
                                                modifiers: { hover: true },
                                              },
                                            ],
                                            staticClass: "editBtn",
                                            attrs: {
                                              variant: "warning",
                                              size: "sm",
                                              title: "Editar",
                                            },
                                          },
                                          [_c("b-icon-pencil")],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                item.proceso === "Ratificación de firmas"
                                  ? _c(
                                      "router-link",
                                      {
                                        attrs: {
                                          to: {
                                            path:
                                              "/dashboard/clienttasks/editratificacion/" +
                                              item.id,
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "b-button",
                                          {
                                            directives: [
                                              {
                                                name: "b-tooltip",
                                                rawName: "v-b-tooltip.hover",
                                                modifiers: { hover: true },
                                              },
                                            ],
                                            staticClass: "editBtn",
                                            attrs: {
                                              variant: "warning",
                                              size: "sm",
                                              title: "Editar",
                                            },
                                          },
                                          [_c("b-icon-pencil")],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                item.proceso === "Firma Digital de Documentos"
                                  ? _c(
                                      "router-link",
                                      {
                                        attrs: {
                                          to: {
                                            path:
                                              "/dashboard/clienttasks/editratificaciondigital/" +
                                              item.id,
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "b-button",
                                          {
                                            directives: [
                                              {
                                                name: "b-tooltip",
                                                rawName: "v-b-tooltip.hover",
                                                modifiers: { hover: true },
                                              },
                                            ],
                                            staticClass: "editBtn",
                                            attrs: {
                                              variant: "warning",
                                              size: "sm",
                                              title: "Editar",
                                            },
                                          },
                                          [_c("b-icon-pencil")],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                item.proceso === "Copias certificadas"
                                  ? _c(
                                      "router-link",
                                      {
                                        attrs: {
                                          to: {
                                            path:
                                              "/dashboard/clienttasks/editcotejo/" +
                                              item.id,
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "b-button",
                                          {
                                            directives: [
                                              {
                                                name: "b-tooltip",
                                                rawName: "v-b-tooltip.hover",
                                                modifiers: { hover: true },
                                              },
                                            ],
                                            staticClass: "editBtn",
                                            attrs: {
                                              variant: "warning",
                                              size: "sm",
                                              title: "Editar",
                                            },
                                          },
                                          [_c("b-icon-pencil")],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.puedeFacturar(item)
                                  ? _c(
                                      "div",
                                      [
                                        _c(
                                          "b-button",
                                          {
                                            directives: [
                                              {
                                                name: "b-tooltip",
                                                rawName: "v-b-tooltip.hover",
                                                modifiers: { hover: true },
                                              },
                                            ],
                                            attrs: {
                                              variant: "primary",
                                              size: "sm",
                                              title: "Consultar factura",
                                            },
                                            on: {
                                              click: function ($event) {
                                                $event.preventDefault()
                                                return _vm.facturacionButtonClicked(
                                                  item
                                                )
                                              },
                                            },
                                          },
                                          [_c("b-icon-receipt")],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("div", { staticClass: "d-flex my-2" }, [
                    _c("span", [
                      _vm._v(" Ordenado por: "),
                      _c("b", [_vm._v(_vm._s(_vm.sortBy))]),
                      _vm._v(", en dirección: "),
                      _c("b", [
                        _vm._v(
                          _vm._s(_vm.sortDesc ? "descendente" : "ascendente")
                        ),
                      ]),
                    ]),
                  ]),
                  _c(
                    "div",
                    { staticClass: "d-flex justify-content-between pages" },
                    [
                      _c("b-pagination", {
                        attrs: {
                          "per-page": _vm.perPage,
                          "total-rows": _vm.totalProcesos,
                        },
                        model: {
                          value: _vm.currentPage,
                          callback: function ($$v) {
                            _vm.currentPage = $$v
                          },
                          expression: "currentPage",
                        },
                      }),
                      _c("span", [
                        _vm._v(
                          "Mostrando " +
                            _vm._s(_vm.procesos.length) +
                            " registros de " +
                            _vm._s(_vm.totalProcesos)
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }