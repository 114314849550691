var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content" },
    [
      _c(
        "b-modal",
        {
          ref: "videoModal",
          attrs: {
            id: "videoModal",
            size: "xl",
            centered: "",
            "hide-footer": "",
            "no-close-on-backdrop": "",
          },
        },
        [
          _vm.selectedVideo
            ? _c("video", {
                staticStyle: { width: "100%" },
                attrs: {
                  src: _vm.selectedVideo.url,
                  controls: "",
                  autoplay: "",
                },
              })
            : _vm._e(),
        ]
      ),
      _c(
        "div",
        { staticClass: "p-4" },
        [
          _c(
            "b-row",
            _vm._l(_vm.videos, function (video, index) {
              return _c(
                "b-col",
                {
                  key: index,
                  staticClass: "mb-4",
                  attrs: { cols: "6", md: "4", lg: "3" },
                },
                [
                  _c(
                    "b-card",
                    {
                      staticClass: "video-card",
                      attrs: { title: video.title },
                      on: {
                        click: function ($event) {
                          return _vm.showVideoModal(video)
                        },
                      },
                    },
                    [
                      _c("img", {
                        staticClass: "img-fluid video-thumbnail",
                        attrs: { src: video.thumbnail, alt: video.title },
                      }),
                    ]
                  ),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }