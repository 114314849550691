var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content" },
    [
      _c(
        "b-breadcrumb",
        [
          _c("b-breadcrumb-item", { attrs: { active: "" } }, [
            _vm._v("Procesos"),
          ]),
        ],
        1
      ),
      _c("div", { staticClass: "container" }, [
        _vm.loading
          ? _c("div", [
              _c(
                "div",
                { attrs: { id: "loader" } },
                [
                  _c("font-awesome-icon", {
                    attrs: { icon: "spinner", spin: "" },
                  }),
                ],
                1
              ),
            ])
          : _vm._e(),
      ]),
      _c("div", { staticClass: "container-fluid" }, [
        _c(
          "div",
          [
            _c("b-card", { attrs: { "no-body": "" } }, [
              _c("div", { staticClass: "p-4" }, [
                _c(
                  "div",
                  { staticClass: "row mb-1" },
                  [
                    _c(
                      "b-form-group",
                      {
                        staticClass: "col-4",
                        attrs: { label: "Buscar", "label-for": "search-input" },
                      },
                      [
                        _c(
                          "b-input-group",
                          [
                            _c("b-form-input", {
                              attrs: {
                                id: "search-input",
                                placeholder: "Ingrese un término de búsqueda",
                              },
                              model: {
                                value: _vm.searchData,
                                callback: function ($$v) {
                                  _vm.searchData = $$v
                                },
                                expression: "searchData",
                              },
                            }),
                            _c(
                              "b-input-group-append",
                              [
                                _c(
                                  "b-button",
                                  {
                                    staticClass: "orange-btn",
                                    attrs: { disabled: _vm.isTableBusy },
                                    on: { click: _vm.handleSearchData },
                                  },
                                  [_vm._v("Buscar")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("div", { staticClass: "row" }, [
                  _vm.showSelects
                    ? _c(
                        "div",
                        { staticClass: "col-4" },
                        [
                          _c("b-form-select", {
                            staticClass: "custom-select",
                            attrs: {
                              options: _vm.optionsCatCorredurias,
                              disabled: _vm.isTableBusy,
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "first",
                                  fn: function () {
                                    return [
                                      _c(
                                        "b-form-select-option",
                                        { attrs: { value: null } },
                                        [_vm._v("Corredurías...")]
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              3951804517
                            ),
                            model: {
                              value: _vm.selectedCorreduria,
                              callback: function ($$v) {
                                _vm.selectedCorreduria = $$v
                              },
                              expression: "selectedCorreduria",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "col-4" },
                    [
                      _c("b-form-select", {
                        staticClass: "custom-select",
                        attrs: {
                          options: _vm.optionsCatServicios,
                          disabled: _vm.isTableBusy,
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "first",
                            fn: function () {
                              return [
                                _c(
                                  "b-form-select-option",
                                  { attrs: { value: null } },
                                  [_vm._v("Procesos...")]
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                        model: {
                          value: _vm.selectedProceso,
                          callback: function ($$v) {
                            _vm.selectedProceso = $$v
                          },
                          expression: "selectedProceso",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-4" },
                    [
                      _c("b-form-select", {
                        staticClass: "custom-select",
                        attrs: {
                          options: _vm.optionsStatus,
                          disabled: _vm.isTableBusy,
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "first",
                            fn: function () {
                              return [
                                _c(
                                  "b-form-select-option",
                                  { attrs: { value: null } },
                                  [_vm._v("Estatus...")]
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                        model: {
                          value: _vm.selectedStatus,
                          callback: function ($$v) {
                            _vm.selectedStatus = $$v
                          },
                          expression: "selectedStatus",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "text-center" },
                  [
                    _c("b-table", {
                      staticClass: "mt-4",
                      attrs: {
                        hover: "",
                        fields: _vm.tableFields,
                        items: _vm.procesos,
                        busy: _vm.isTableBusy,
                        "sort-by": _vm.sortBy,
                        "sort-desc": _vm.sortDesc,
                      },
                      on: {
                        "update:sortBy": function ($event) {
                          _vm.sortBy = $event
                        },
                        "update:sort-by": function ($event) {
                          _vm.sortBy = $event
                        },
                        "update:sortDesc": function ($event) {
                          _vm.sortDesc = $event
                        },
                        "update:sort-desc": function ($event) {
                          _vm.sortDesc = $event
                        },
                        "sort-changed": _vm.sortingChanged,
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "table-busy",
                          fn: function () {
                            return [
                              _c(
                                "div",
                                { staticClass: "text-center my-2" },
                                [
                                  _c("b-spinner", {
                                    staticClass: "align-middle",
                                  }),
                                  _c("strong", [_vm._v("Cargando...")]),
                                ],
                                1
                              ),
                            ]
                          },
                          proxy: true,
                        },
                        {
                          key: "cell(index)",
                          fn: function ({ index }) {
                            return [
                              _vm._v(
                                " " + _vm._s(_vm.getIndex(index + 1)) + " "
                              ),
                            ]
                          },
                        },
                        {
                          key: "cell(acciones)",
                          fn: function ({ item }) {
                            return [
                              item.proceso == "Constitución de sociedades"
                                ? _c(
                                    "router-link",
                                    {
                                      attrs: {
                                        to: {
                                          path:
                                            "/dashboard/tasks/valid/constitucion/" +
                                            item.id,
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          directives: [
                                            {
                                              name: "b-tooltip",
                                              rawName: "v-b-tooltip.hover",
                                              modifiers: { hover: true },
                                            },
                                          ],
                                          staticClass: "editBtn",
                                          attrs: {
                                            variant: "warning",
                                            size: "sm",
                                            title: "Editar",
                                          },
                                        },
                                        [_c("b-icon-pencil")],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              item.proceso === "Formalización de actas"
                                ? _c(
                                    "router-link",
                                    {
                                      attrs: {
                                        to: {
                                          path:
                                            "/dashboard/tasks/valid/formalizacion/" +
                                            item.id,
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          directives: [
                                            {
                                              name: "b-tooltip",
                                              rawName: "v-b-tooltip.hover",
                                              modifiers: { hover: true },
                                            },
                                          ],
                                          staticClass: "editBtn",
                                          attrs: {
                                            variant: "warning",
                                            size: "sm",
                                            title: "Editar",
                                          },
                                        },
                                        [_c("b-icon-pencil")],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : item.proceso == "Notificaciones"
                                ? _c(
                                    "router-link",
                                    {
                                      attrs: {
                                        to: {
                                          path:
                                            "/dashboard/tasks/valid/notificacion/" +
                                            item.id,
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          directives: [
                                            {
                                              name: "b-tooltip",
                                              rawName: "v-b-tooltip.hover",
                                              modifiers: { hover: true },
                                            },
                                          ],
                                          staticClass: "editBtn",
                                          attrs: {
                                            variant: "warning",
                                            size: "sm",
                                            title: "Editar",
                                          },
                                        },
                                        [_c("b-icon-pencil")],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : item.proceso == "Ratificación de firmas"
                                ? _c(
                                    "router-link",
                                    {
                                      attrs: {
                                        to: {
                                          path:
                                            "/dashboard/tasks/valid/ratificacion/" +
                                            item.id,
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          directives: [
                                            {
                                              name: "b-tooltip",
                                              rawName: "v-b-tooltip.hover",
                                              modifiers: { hover: true },
                                            },
                                          ],
                                          staticClass: "editBtn",
                                          attrs: {
                                            variant: "warning",
                                            size: "sm",
                                            title: "Editar",
                                          },
                                        },
                                        [_c("b-icon-pencil")],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : item.proceso === "Firma Digital de Documentos"
                                ? _c(
                                    "router-link",
                                    {
                                      attrs: {
                                        to: {
                                          path:
                                            "/dashboard/tasks/valid/ratificaciondigital/" +
                                            item.id,
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          directives: [
                                            {
                                              name: "b-tooltip",
                                              rawName: "v-b-tooltip.hover",
                                              modifiers: { hover: true },
                                            },
                                          ],
                                          staticClass: "editBtn",
                                          attrs: {
                                            variant: "warning",
                                            size: "sm",
                                            title: "Editar",
                                          },
                                        },
                                        [_c("b-icon-pencil")],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : item.proceso == "Copias certificadas"
                                ? _c(
                                    "router-link",
                                    {
                                      attrs: {
                                        to: {
                                          path:
                                            "/dashboard/tasks/valid/cotejo/" +
                                            item.id,
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          directives: [
                                            {
                                              name: "b-tooltip",
                                              rawName: "v-b-tooltip.hover",
                                              modifiers: { hover: true },
                                            },
                                          ],
                                          staticClass: "editBtn",
                                          attrs: {
                                            variant: "warning",
                                            size: "sm",
                                            title: "Editar",
                                          },
                                        },
                                        [_c("b-icon-pencil")],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]
                          },
                        },
                        {
                          key: "cell(listo)",
                          fn: function ({ item }) {
                            return [
                              item.estatus === "Pagado"
                                ? _c(
                                    "div",
                                    { staticClass: "circle completado_red" },
                                    [
                                      _c("span", { staticClass: "number" }, [
                                        _c("i", { staticClass: "fa fa-minus" }),
                                      ]),
                                    ]
                                  )
                                : item.estatus === "Información Completada"
                                ? _c(
                                    "div",
                                    { staticClass: "circle completado_red" },
                                    [
                                      _c("span", { staticClass: "number" }, [
                                        _c("i", { staticClass: "fa fa-minus" }),
                                      ]),
                                    ]
                                  )
                                : item.estatus === "Finalizado"
                                ? _c(
                                    "div",
                                    { staticClass: "circle completado_blue" },
                                    [
                                      _c("span", { staticClass: "number" }, [
                                        _c("i", { staticClass: "fa fa-check" }),
                                      ]),
                                    ]
                                  )
                                : _c(
                                    "div",
                                    { staticClass: "circle completado_yellow" },
                                    [
                                      _c("span", { staticClass: "number" }, [
                                        _c("i", { staticClass: "fa fa-minus" }),
                                      ]),
                                    ]
                                  ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("div", { staticClass: "d-flex my-2" }, [
                      _c("span", [
                        _vm._v(" Ordenado por: "),
                        _c("b", [_vm._v(_vm._s(_vm.sortBy))]),
                        _vm._v(", en dirección: "),
                        _c("b", [
                          _vm._v(
                            _vm._s(_vm.sortDesc ? "descendente" : "ascendente")
                          ),
                        ]),
                      ]),
                    ]),
                    _c(
                      "div",
                      { staticClass: "d-flex justify-content-between pages" },
                      [
                        _c("b-pagination", {
                          attrs: {
                            "per-page": _vm.perPage,
                            "total-rows": _vm.totalProcesos,
                          },
                          model: {
                            value: _vm.currentPage,
                            callback: function ($$v) {
                              _vm.currentPage = $$v
                            },
                            expression: "currentPage",
                          },
                        }),
                        _c("span", [
                          _vm._v(
                            "Mostrando " +
                              _vm._s(_vm.procesos.length) +
                              " registros de " +
                              _vm._s(_vm.totalProcesos)
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ]),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }