var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container" }, [
    _c("h4", { staticClass: "cardD" }, [_vm._v("Denominación social")]),
    _c("div", { staticClass: "container" }, [
      _vm.loading
        ? _c("div", [
            _c(
              "div",
              { attrs: { id: "loader" } },
              [
                _c("font-awesome-icon", {
                  attrs: { icon: "spinner", spin: "" },
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
    ]),
    _c("h4", { staticClass: "cardD" }, [_vm._v(" Denominación social ")]),
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-6" },
        [
          _c("label", { attrs: { for: "" } }, [
            _vm._v("Denominacion solicitada"),
          ]),
          _c("b-form-checkbox", {
            attrs: { disabled: _vm.formalizacion.disabledDenominacion },
            model: {
              value: _vm.formalizacion.denominacion_solicitada,
              callback: function ($$v) {
                _vm.$set(_vm.formalizacion, "denominacion_solicitada", $$v)
              },
              expression: "formalizacion.denominacion_solicitada",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-6" },
        [
          _c("label", { attrs: { for: "" } }, [
            _vm._v("Formalizacío de Denominación Social"),
          ]),
          _c("b-form-checkbox", {
            attrs: { disabled: _vm.formalizacion.disabledDenominacion },
            model: {
              value: _vm.formalizacion.cp_formaliza_denominacion,
              callback: function ($$v) {
                _vm.$set(_vm.formalizacion, "cp_formaliza_denominacion", $$v)
              },
              expression: "formalizacion.cp_formaliza_denominacion",
            },
          }),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "mt-4" }, [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-10" },
          [
            _c(
              "b-button",
              {
                staticClass: "btn-fill orange-btn bottom2",
                attrs: {
                  type: "submit",
                  size: "m",
                  disabled: _vm.formalizacion.disabledDenominacion,
                },
              },
              [_vm._v("Guardar")]
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-2" },
          [
            _c(
              "b-button",
              {
                staticClass: "btn-fill bottom2",
                attrs: {
                  variant: "success",
                  size: "m",
                  disabled: _vm.formalizacion.disabledDenominacion,
                },
                on: {
                  click: function ($event) {
                    return _vm.validar()
                  },
                },
              },
              [_vm._v("Validar")]
            ),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }