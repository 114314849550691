var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("h4", { staticClass: "cardD" }, [_vm._v("Firma digital")]),
      _c("h4", {}, [
        _c("i", { staticClass: "fa fa-info" }),
        _vm._v(
          " Validaremos la información y los documentos que has cargado de los comparecientes y te contacteremos por email en caso de ser necesario. "
        ),
        _c("p"),
        _c("br"),
        _c("i", { staticClass: "fa fa-envelope" }),
        _vm._v(
          " Si todo es correcto, te llegará un email con los documentos que firmarás digitalmente, además, del proyecto de la constitutiva que firmarás presencialmente. Después, te llegará un email de FAD - Firma Autógrafa Digital, donde se te indicarán los pasos para realizar la firma digital. "
        ),
        _c("p"),
        _c("br"),
        _c("i", { staticClass: "fa fa-pencil" }),
        _vm._v(
          " Si estás de acuerdo en el contenido de los documentos, te pedimos que los firmes, de lo contrario, te pedimos que te comuniques con nosotros. "
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }