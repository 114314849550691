var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {},
      [
        _c("TopNavbar"),
        _c(
          "div",
          { staticClass: "contact-us" },
          [
            _c("b-img", {
              attrs: {
                src: require("@/assets/img/landing_page/Banner_CD_Avance_06.webp"),
                alt: "Banner Correduria Digital",
                fluid: "",
              },
            }),
          ],
          1
        ),
        _c("ComoFunciona"),
        _c("a", { attrs: { id: "servicios" } }),
        _c("Servicios"),
        _c("a", { attrs: { id: "registro" } }),
        _c("SignIn", { staticClass: "registro", attrs: { id: "registrar" } }),
        _c("a", { attrs: { id: "testimonios" } }),
        _c("TestimoniosComponent", { attrs: { id: "testimonios" } }),
        _c("a", { attrs: { id: "contacto" } }),
        _c("Contacto", { attrs: { id: "contacto" } }),
        _c("Footer"),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }