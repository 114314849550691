var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content" },
    [
      _c(
        "b-breadcrumb",
        [
          _c("b-breadcrumb-item", { attrs: { active: "" } }, [
            _vm._v("Clientes"),
          ]),
        ],
        1
      ),
      _c("div", { staticClass: "container" }, [
        _vm.loading
          ? _c("div", [
              _c(
                "div",
                { attrs: { id: "loader" } },
                [
                  _c("font-awesome-icon", {
                    attrs: { icon: "spinner", spin: "" },
                  }),
                ],
                1
              ),
            ])
          : _vm._e(),
      ]),
      _c("div", { staticClass: "container-fluid" }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-4" },
            [
              _c("base-input", {
                attrs: { placeholder: "Buscar cliente" },
                model: {
                  value: _vm.searchCliente,
                  callback: function ($$v) {
                    _vm.searchCliente = $$v
                  },
                  expression: "searchCliente",
                },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "col-4" }, [
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.rolSearch,
                    expression: "rolSearch",
                  },
                ],
                staticClass: "custom-select",
                on: {
                  change: [
                    function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.rolSearch = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    },
                    function ($event) {
                      return _vm.getClientesByRol($event)
                    },
                  ],
                },
              },
              [
                _c("option", { attrs: { value: "0" } }, [
                  _vm._v("Todos los roles"),
                ]),
                _c("option", { attrs: { value: "3" } }, [
                  _vm._v("Cliente Administrador"),
                ]),
                _c("option", { attrs: { value: "4" } }, [
                  _vm._v("Cliente Usuario"),
                ]),
              ]
            ),
          ]),
          _c("div", { staticClass: "col-4" }, [
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.personaSearch,
                    expression: "personaSearch",
                  },
                ],
                staticClass: "custom-select",
                on: {
                  change: [
                    function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.personaSearch = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    },
                    function ($event) {
                      return _vm.getClientesByPersona($event)
                    },
                  ],
                },
              },
              [
                _c("option", { attrs: { value: "null2" } }, [
                  _vm._v("Todas las personas"),
                ]),
                _c("option", { attrs: { value: "Moral" } }, [_vm._v("Moral")]),
                _c("option", { attrs: { value: "Física" } }, [
                  _vm._v("Física"),
                ]),
              ]
            ),
          ]),
          _c(
            "div",
            { staticClass: "col-12" },
            [
              _c(
                "card",
                {
                  staticClass: "strpied-tabled-with-hover",
                  attrs: {
                    "body-classes": "table-full-width table-responsive",
                  },
                },
                [
                  _c("template", { slot: "header" }, [
                    _c("h4", { staticClass: "card-title" }, [
                      _vm._v("Clientes"),
                    ]),
                  ]),
                  _c(
                    "b-modal",
                    {
                      ref: "modal-confirm-dialog",
                      attrs: {
                        "no-close-on-backdrop": "",
                        centered: "",
                        "hide-footer": "",
                        id: "modal-confirm-dialog",
                      },
                    },
                    [
                      _c("h4", { staticClass: "text-center" }, [
                        _vm._v(" ¿Seguro que quiere eliminar al cliente? "),
                      ]),
                      _c("h5", { staticClass: "text-center" }, [
                        _vm._v("Se eliminarán todos los datos."),
                      ]),
                      _c(
                        "b-button",
                        {
                          staticClass: "mt-3",
                          attrs: { variant: "danger" },
                          on: {
                            click: function ($event) {
                              return _vm.$bvModal.hide("modal-confirm-dialog")
                            },
                          },
                        },
                        [_vm._v("Cancelar")]
                      ),
                      _c(
                        "b-button",
                        {
                          staticClass: "mt-3 float-right",
                          attrs: { variant: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.deleteCliente()
                            },
                          },
                        },
                        [_vm._v("OK")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "text-center" },
                    [
                      _c("b-table", {
                        attrs: {
                          fields: _vm.fields,
                          items: _vm.clientes,
                          hover: "",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "cell(index)",
                            fn: function ({ index }) {
                              return [
                                _vm._v(
                                  " " + _vm._s(_vm.getIndex(index + 1)) + " "
                                ),
                              ]
                            },
                          },
                          {
                            key: "cell(acciones)",
                            fn: function ({ item }) {
                              return [
                                _c(
                                  "router-link",
                                  {
                                    attrs: {
                                      to: {
                                        path:
                                          "/dashboard/clientes/editcliente/" +
                                          item.id,
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "b-button",
                                      {
                                        directives: [
                                          {
                                            name: "b-tooltip",
                                            rawName: "v-b-tooltip.hover",
                                            modifiers: { hover: true },
                                          },
                                        ],
                                        staticClass: "editBtn",
                                        attrs: {
                                          variant: "warning",
                                          size: "sm",
                                          title: "Editar",
                                        },
                                      },
                                      [_c("b-icon-pencil")],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-button",
                                  {
                                    directives: [
                                      {
                                        name: "b-tooltip",
                                        rawName: "v-b-tooltip.hover",
                                        modifiers: { hover: true },
                                      },
                                    ],
                                    attrs: {
                                      variant: "danger",
                                      size: "sm",
                                      title: "Eliminar",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.predeleteCliente(item.id)
                                      },
                                    },
                                  },
                                  [_c("b-icon-trash")],
                                  1
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "d-flex justify-content-between pages" },
                    [
                      _c("b-pagination", {
                        attrs: {
                          "per-page": _vm.perPage,
                          "total-rows": _vm.totalClientes,
                        },
                        model: {
                          value: _vm.currentPage,
                          callback: function ($$v) {
                            _vm.currentPage = $$v
                          },
                          expression: "currentPage",
                        },
                      }),
                      _c("span", [
                        _vm._v(
                          "Mostrando " +
                            _vm._s(_vm.clientes.length) +
                            " registros de " +
                            _vm._s(_vm.totalClientes)
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                2
              ),
            ],
            1
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }