var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: "content", staticClass: "content" },
    [
      _c("div", { staticClass: "container" }, [
        _vm.loading
          ? _c("div", [
              _c(
                "div",
                { attrs: { id: "loader" } },
                [
                  _c("font-awesome-icon", {
                    attrs: { icon: "spinner", spin: "" },
                  }),
                ],
                1
              ),
            ])
          : _vm._e(),
      ]),
      _c(
        "b-breadcrumb",
        [
          _c("b-breadcrumb-item", { attrs: { to: { path: "/dashboard" } } }, [
            _vm._v("Inicio"),
          ]),
          _c("b-breadcrumb-item", { attrs: { active: "" } }, [
            _vm._v("Editar Notificación"),
          ]),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          ref: "modal-confirm-dialog-route",
          attrs: {
            "no-close-on-backdrop": "",
            centered: "",
            "hide-footer": "",
            id: "modal-confirm-dialog-route",
          },
        },
        [
          _c("h4", { staticClass: "text-center" }, [
            _vm._v(" ¿Seguro que quiere salir de la página? "),
          ]),
          _c("h5", { staticClass: "text-center" }),
          _c(
            "b-button",
            {
              staticClass: "mt-3",
              attrs: { variant: "danger" },
              on: {
                click: function ($event) {
                  return _vm.$bvModal.hide("modal-confirm-dialog-route")
                },
              },
            },
            [_vm._v("Cancelar")]
          ),
          _c(
            "b-button",
            {
              staticClass: "mt-3 float-right",
              attrs: { variant: "primary" },
              on: {
                click: function ($event) {
                  return _vm.beforeLeave(true)
                },
              },
            },
            [_vm._v(" OK ")]
          ),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          ref: "modal-confirm-dialog",
          attrs: {
            "no-close-on-backdrop": "",
            centered: "",
            "hide-footer": "",
            id: "modal-confirm-dialog",
          },
        },
        [
          _c("h4", { staticClass: "text-center" }, [
            _vm._v(" ¿Seguro que quiere salir sin guardar los cambios? "),
          ]),
          _c("h5", { staticClass: "text-center" }),
          _c(
            "b-button",
            {
              staticClass: "mt-3",
              attrs: { variant: "danger" },
              on: {
                click: function ($event) {
                  return _vm.$bvModal.hide("modal-confirm-dialog")
                },
              },
            },
            [_vm._v("Cancelar")]
          ),
          _c(
            "b-button",
            { staticClass: "mt-3 float-right", attrs: { variant: "primary" } },
            [_vm._v("OK")]
          ),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          ref: "confirmar-facturacion-modal",
          attrs: {
            "no-close-on-backdrop": "",
            id: "confirmar-facturacion-modal",
            centered: "",
            "hide-footer": "",
          },
        },
        [
          _c("h4", { staticClass: "text-center" }, [
            _vm._v("¿Desea facturar su pago?"),
          ]),
          _c("h5", { staticClass: "text-center" }),
          _c(
            "b-button",
            {
              staticClass: "mt-3",
              attrs: { variant: "danger" },
              on: {
                click: function ($event) {
                  return _vm.confirmGenerarFactura(false)
                },
              },
            },
            [_vm._v("No en este momento")]
          ),
          _c(
            "b-button",
            {
              staticClass: "mt-3 float-right",
              attrs: { variant: "primary" },
              on: {
                click: function ($event) {
                  return _vm.confirmGenerarFactura(true)
                },
              },
            },
            [_vm._v("Sí")]
          ),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          ref: "datos-fiscales-modal",
          attrs: {
            "no-close-on-backdrop": "",
            id: "datos-fiscales-modal",
            size: "lg",
            centered: "",
            "hide-footer": "",
            "hide-header": "",
          },
          on: {
            close: function ($event) {
              $event.preventDefault()
            },
          },
        },
        [
          _c("FacturacionComponent", {
            attrs: { datosFacturacion: _vm.datosFacturacion },
            on: {
              closedFacturacionEvent: function ($event) {
                return _vm.closedFacturacion($event)
              },
              canceledFacturacionEvent: function ($event) {
                return _vm.canceledFacturacion()
              },
            },
          }),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            "no-close-on-backdrop": "",
            id: "modal-help-entrega-documentos",
            size: "xl",
            centered: "",
            "hide-footer": "",
          },
        },
        [
          _c(
            "b-carousel",
            {
              attrs: {
                id: "carousel-notificaciones-2",
                interval: 0,
                indicators: "",
                background: "#ababab",
              },
            },
            [
              _c("b-carousel-slide", {
                attrs: {
                  "img-src": require("@/assets/img/notificaciones/help/modal_2_notificaciones_entrega.webp"),
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm.notificacion.id_estatus != 13
        ? _c(
            "div",
            { staticClass: "container-fluid" },
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { staticClass: "col-3" },
                    [
                      _c(
                        "b-card",
                        [
                          _c("TreeEditNotificaciones", {
                            attrs: {
                              notificacion: _vm.notificacion,
                              user: "Client",
                              selectedComponent: _vm.currentComponentBody,
                            },
                            on: { node: _vm.selected },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        [
                          _vm.currentComponentBody === "EnvioDomicilio"
                            ? _c("b-img", {
                                attrs: {
                                  src: require("@/assets/img/notificaciones/help/5_envio_a_domicilio.webp"),
                                  "fluid-grow": "",
                                  alt: "Descripción del envío a domicilio",
                                },
                              })
                            : _vm._e(),
                          _vm.currentComponentBody === "ComplementaNotificado"
                            ? _c("b-img", {
                                attrs: {
                                  src: require("@/assets/img/notificaciones/help/7_notificado.webp"),
                                  "fluid-grow": "",
                                  alt: "Descripción del notificado",
                                },
                              })
                            : _vm._e(),
                          _vm.currentComponentBody === "ComplementaSolicitantes"
                            ? _c("b-img", {
                                attrs: {
                                  src: require("@/assets/img/notificaciones/help/8_solicitante_2_final.webp"),
                                  "fluid-grow": "",
                                  alt: "Descripción de la información complementaria de los solicitantes",
                                },
                              })
                            : _vm._e(),
                          _vm.currentComponentBody === "EntregaDocumentos"
                            ? _c("b-img", {
                                attrs: {
                                  src: require("@/assets/img/notificaciones/help/6_entrega_docs_abre_modal_2.webp"),
                                  "fluid-grow": "",
                                  alt: "Descripción de los documentos a subir",
                                },
                              })
                            : _vm._e(),
                          _vm.currentComponentBody === "EntregaDocumentos"
                            ? _c(
                                "b-button",
                                {
                                  staticClass: "orange-btn",
                                  attrs: { block: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.$bvModal.show(
                                        "modal-help-entrega-documentos"
                                      )
                                    },
                                  },
                                },
                                [_vm._v(" Más información ")]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { staticClass: "col-9" },
                    [
                      _c(
                        "b-card",
                        [
                          _c(
                            "b-row",
                            [
                              _c("b-col", { staticClass: "col-12" }, [
                                _vm.currentComponentBody === "Pago"
                                  ? _c(
                                      "div",
                                      [
                                        _c("Pago", {
                                          attrs: {
                                            pagado: _vm.notificacion.pagado,
                                            forma_pago:
                                              _vm.notificacion.forma_pago,
                                            notificacion: _vm.notificacion,
                                          },
                                          on: {
                                            "pay-cotizacion": function (
                                              $event
                                            ) {
                                              return _vm.payCotizacion($event)
                                            },
                                            "pay-cotizacion-spei": function (
                                              $event
                                            ) {
                                              return _vm.payCotizacionSpei(
                                                $event
                                              )
                                            },
                                            "pay-cotizacion-monedero":
                                              function ($event) {
                                                return _vm.payCotizacion($event)
                                              },
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.currentComponentBody === "EnvioDomicilio"
                                  ? _c(
                                      "div",
                                      [
                                        _c("ValidationObserver", {
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function ({
                                                  handleSubmit,
                                                }) {
                                                  return [
                                                    _c(
                                                      "form",
                                                      {
                                                        on: {
                                                          submit: function (
                                                            $event
                                                          ) {
                                                            $event.preventDefault()
                                                            return handleSubmit(
                                                              _vm.postDomicilioEnvio
                                                            )
                                                          },
                                                          change: function (
                                                            $event
                                                          ) {
                                                            return _vm.getAddressInMapEnvio(
                                                              null
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c("ModalDomicilio", {
                                                          attrs: {
                                                            currentDomicilio:
                                                              _vm.domicilioEnvio,
                                                          },
                                                          on: {
                                                            submit: function (
                                                              $event
                                                            ) {
                                                              $event.preventDefault()
                                                            },
                                                            node: _vm.selected,
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            1055278716
                                          ),
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.currentComponentBody ===
                                "ComplementaNotificado"
                                  ? _c(
                                      "div",
                                      [
                                        _c("ValidationObserver", {
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function ({
                                                  handleSubmit,
                                                }) {
                                                  return [
                                                    _c(
                                                      "form",
                                                      {
                                                        on: {
                                                          submit: function (
                                                            $event
                                                          ) {
                                                            $event.preventDefault()
                                                            return handleSubmit(
                                                              _vm.saveComplementaNotificado
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "ComplementaNotificado",
                                                          {
                                                            attrs: {
                                                              notificado:
                                                                _vm.notificado,
                                                              notificacion:
                                                                _vm.notificacion,
                                                            },
                                                            on: {
                                                              submit: function (
                                                                $event
                                                              ) {
                                                                $event.preventDefault()
                                                              },
                                                              node: _vm.selected,
                                                            },
                                                          }
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            3608510844
                                          ),
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.currentComponentBody ===
                                "ComplementaSolicitantes"
                                  ? _c(
                                      "div",
                                      [
                                        _c("ValidationObserver", {
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function ({
                                                  handleSubmit,
                                                }) {
                                                  return [
                                                    _c(
                                                      "form",
                                                      {
                                                        on: {
                                                          submit: function (
                                                            $event
                                                          ) {
                                                            $event.preventDefault()
                                                            return handleSubmit(
                                                              _vm.saveComplementaSolicitantes
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "ComplementaSolicitantes",
                                                          {
                                                            attrs: {
                                                              notificacion:
                                                                _vm.notificacion,
                                                            },
                                                            on: {
                                                              submit: function (
                                                                $event
                                                              ) {
                                                                $event.preventDefault()
                                                              },
                                                              node: _vm.selected,
                                                            },
                                                          }
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            2487546730
                                          ),
                                        }),
                                        _c(
                                          "div",
                                          {
                                            ref: "cartaInstruccion",
                                            attrs: { hidden: "" },
                                          },
                                          [
                                            _c("CartaInstruccionTemplate", {
                                              key: _vm.cartaKey,
                                              attrs: { datos: _vm.datos },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.currentComponentBody === "EntregaDocumentos"
                                  ? _c(
                                      "div",
                                      [
                                        _c("ValidationObserver", {
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function ({
                                                  handleSubmit,
                                                }) {
                                                  return [
                                                    _c(
                                                      "form",
                                                      {
                                                        on: {
                                                          submit: function (
                                                            $event
                                                          ) {
                                                            $event.preventDefault()
                                                            return handleSubmit(
                                                              _vm.saveEntregaDocumentos
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "EntregaDocumentos",
                                                          {
                                                            attrs: {
                                                              notificacion:
                                                                _vm.notificacion,
                                                            },
                                                            on: {
                                                              submit: function (
                                                                $event
                                                              ) {
                                                                $event.preventDefault()
                                                              },
                                                              node: _vm.selected,
                                                            },
                                                          }
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            1055174122
                                          ),
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.currentComponentBody === "EsperaValidacion"
                                  ? _c(
                                      "div",
                                      [
                                        _c("ValidationObserver", {
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function ({
                                                  handleSubmit,
                                                }) {
                                                  return [
                                                    _c(
                                                      "form",
                                                      {
                                                        on: {
                                                          submit: function (
                                                            $event
                                                          ) {
                                                            $event.preventDefault()
                                                            return handleSubmit(
                                                              _vm.saveEntregaDocumentos
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c("EsperaValidacion", {
                                                          attrs: {
                                                            notificacion:
                                                              _vm.notificacion,
                                                          },
                                                          on: {
                                                            submit: function (
                                                              $event
                                                            ) {
                                                              $event.preventDefault()
                                                            },
                                                            node: _vm.selected,
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            2601994171
                                          ),
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.currentComponentBody === "FirmaDocumentos"
                                  ? _c(
                                      "div",
                                      [
                                        _c("ValidationObserver", {
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function ({
                                                  handleSubmit,
                                                }) {
                                                  return [
                                                    _c(
                                                      "form",
                                                      {
                                                        on: {
                                                          submit: function (
                                                            $event
                                                          ) {
                                                            $event.preventDefault()
                                                            return handleSubmit(
                                                              _vm.saveFirmaDocumentos
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c("FirmaDocumentos", {
                                                          attrs: {
                                                            notificacion:
                                                              _vm.notificacion,
                                                          },
                                                          on: {
                                                            submit: function (
                                                              $event
                                                            ) {
                                                              $event.preventDefault()
                                                            },
                                                            node: _vm.selected,
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            93812618
                                          ),
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.notificacion.id_estatus == 13
        ? _c(
            "div",
            { staticClass: "container-fluid" },
            [
              _c(
                "b-card",
                [
                  _c("Expediente", {
                    attrs: { notificacion: _vm.notificacion },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.datosCompletos
        ? _c(
            "div",
            { ref: "KycSolicitanteTemplate", attrs: { hidden: "" } },
            _vm._l(_vm.notificacion.solicitantes, function (solicitante) {
              return _c(
                "div",
                { key: solicitante.id },
                [
                  _c("KycSolicitanteTemplate", {
                    attrs: {
                      id: solicitante.id,
                      currentCompareciente: solicitante,
                      beneficiario_pld: 0,
                      beneficiario_cff: 0,
                    },
                  }),
                ],
                1
              )
            }),
            0
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }