var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c(
        "b-modal",
        {
          ref: "documento-modal-preview-img",
          attrs: {
            "no-close-on-backdrop": "",
            centered: "",
            title: "",
            "hide-footer": "",
            id: "modaldi",
            size: "xl",
          },
        },
        [
          _c("ModalPreviewImg", {
            attrs: { currentDocumento: _vm.currentDocumento },
          }),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          ref: "documento-modal-preview-pdf",
          attrs: {
            "no-close-on-backdrop": "",
            centered: "",
            title: "",
            "hide-footer": "",
            id: "modaldf",
            size: "xl",
          },
        },
        [
          _c("ModalPreviewPDF", {
            attrs: { currentDocumento: _vm.currentDocumento },
          }),
        ],
        1
      ),
      _c("h4", { staticClass: "cardD" }, [_vm._v("Expediente")]),
      _c(
        "div",
        [
          _c("b-table", {
            ref: "table",
            attrs: {
              hover: "",
              fields: _vm.fieldsDocumentos,
              items: _vm.ratificacion.documentos,
            },
            scopedSlots: _vm._u([
              {
                key: "cell(acciones)",
                fn: function ({ item }) {
                  return [
                    item.documento != ""
                      ? _c(
                          "b-button",
                          {
                            directives: [
                              {
                                name: "b-tooltip",
                                rawName: "v-b-tooltip.hover",
                                modifiers: { hover: true },
                              },
                            ],
                            staticClass: "editBtn",
                            attrs: {
                              variant: "primary",
                              size: "sm",
                              title: "Vista previa",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.previewDocumento(item)
                              },
                            },
                          },
                          [_c("b-icon-eye")],
                          1
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("div", { staticClass: "mt-4" }, [
        _c(
          "div",
          {},
          [
            _c(
              "b-button",
              {
                staticClass: "btn-fill orange-btn bottom2",
                attrs: {
                  type: "submit",
                  size: "m",
                  disabled: _vm.isButtonDisabled,
                },
              },
              [_vm._v("Continuar")]
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }