var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container" }, [
    _c("h4", { staticClass: "cardD" }, [
      _vm._v(" Documentos firmados digitalmente "),
    ]),
    _c(
      "div",
      { staticClass: "container-fluid" },
      [
        _c(
          "b-modal",
          {
            ref: "documento-modal-preview-img",
            attrs: {
              "no-close-on-backdrop": "",
              centered: "",
              title: "",
              "hide-footer": "",
              id: "modaldi",
              size: "xl",
            },
          },
          [
            _c("ModalPreviewImg", {
              attrs: { currentDocumento: _vm.currentDocumento },
            }),
          ],
          1
        ),
        _c(
          "b-modal",
          {
            ref: "documento-modal-preview-pdf",
            attrs: {
              "no-close-on-backdrop": "",
              centered: "",
              title: "",
              "hide-footer": "",
              id: "modaldf",
              size: "xl",
            },
          },
          [
            _c("ModalPreviewPDF", {
              attrs: { currentDocumento: _vm.currentDocumento },
            }),
          ],
          1
        ),
        _c(
          "b-modal",
          {
            ref: "documento-modal-preview-video",
            attrs: {
              "no-close-on-backdrop": "",
              centered: "",
              title: "",
              "hide-footer": "",
              id: "modalvideo",
              size: "xl",
            },
          },
          [
            _c("ModalPreviewVideo", {
              attrs: { currentDocumento: _vm.currentDocumento },
            }),
          ],
          1
        ),
        _c("b-table", {
          attrs: { hover: "", fields: _vm.fields, items: _vm.documentosFAD },
          scopedSlots: _vm._u([
            {
              key: "cell(index)",
              fn: function ({ index }) {
                return [_vm._v(" " + _vm._s(_vm.getIndex(index + 1)) + " ")]
              },
            },
            {
              key: "cell(acciones)",
              fn: function ({ item }) {
                return [
                  _c(
                    "b-button",
                    {
                      directives: [
                        {
                          name: "b-tooltip",
                          rawName: "v-b-tooltip.hover",
                          modifiers: { hover: true },
                        },
                      ],
                      staticClass: "editBtn",
                      attrs: {
                        variant: "primary",
                        size: "sm",
                        title: "Vista previa",
                        hidden: item.disabled,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.previewDocumento(item)
                        },
                      },
                    },
                    [_c("b-icon-eye")],
                    1
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: {
                        variant: "success",
                        size: "sm",
                        title: "Descarga documento",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.descargaFile(item.documento)
                        },
                      },
                    },
                    [_c("i", { staticClass: "fa fa-download" })]
                  ),
                ]
              },
            },
          ]),
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }