var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-md-12" },
        [
          _c("ValidationProvider", {
            attrs: {
              name: "persona",
              rules: "required",
              "custom-messages": {
                required: "La persona es obligatoria",
              },
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ errors }) {
                  return [
                    _c("label", { attrs: { for: "" } }, [_vm._v("Persona")]),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.currentAccionista.persona,
                            expression: "currentAccionista.persona",
                          },
                        ],
                        staticClass: "custom-select",
                        on: {
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.currentAccionista,
                              "persona",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                        },
                      },
                      _vm._l(_vm.optionsPersona, function (option) {
                        return _c("option", { key: option.value }, [
                          _vm._v(" " + _vm._s(option.text) + " "),
                        ])
                      }),
                      0
                    ),
                    _c("span", { staticClass: "cardErrors" }, [
                      _vm._v(_vm._s(errors[0])),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ]),
    _vm.currentAccionista.persona === "Persona Moral"
      ? _c("div", { staticClass: "row mt-4" }, [
          _c(
            "div",
            { staticClass: "col-md-12" },
            [
              _c("ValidationProvider", {
                attrs: {
                  name: "denominacion_social",
                  rules: "required",
                  "custom-messages": {
                    required: "La denominacion social es obligatoria",
                  },
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function ({ errors }) {
                        return [
                          _c("base-input", {
                            attrs: {
                              type: "text",
                              label: "Denominacion social",
                              placeholder: "Denominacion social",
                            },
                            model: {
                              value: _vm.currentAccionista.denominacion_social,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.currentAccionista,
                                  "denominacion_social",
                                  $$v
                                )
                              },
                              expression:
                                "currentAccionista.denominacion_social",
                            },
                          }),
                          _c("span", { staticClass: "cardErrors" }, [
                            _vm._v(_vm._s(errors[0])),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2740112811
                ),
              }),
            ],
            1
          ),
        ])
      : _vm.currentAccionista.persona === "Persona Física"
      ? _c("div", { staticClass: "row mt-4" }, [
          _c(
            "div",
            { staticClass: "col-md-4" },
            [
              _c("ValidationProvider", {
                attrs: {
                  name: "nombre",
                  rules: "required",
                  "custom-messages": {
                    required: "El nombre es obligatorio",
                  },
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ errors }) {
                      return [
                        _c("base-input", {
                          attrs: {
                            type: "text",
                            label: "Nombre",
                            placeholder: "Nombre",
                          },
                          model: {
                            value: _vm.currentAccionista.nombre,
                            callback: function ($$v) {
                              _vm.$set(_vm.currentAccionista, "nombre", $$v)
                            },
                            expression: "currentAccionista.nombre",
                          },
                        }),
                        _c("span", { staticClass: "cardErrors" }, [
                          _vm._v(_vm._s(errors[0])),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-md-4" },
            [
              _c("ValidationProvider", {
                attrs: {
                  name: "nombre",
                  rules: "required",
                  "custom-messages": {
                    required: "El paterno es obligatorio",
                  },
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ errors }) {
                      return [
                        _c("base-input", {
                          attrs: {
                            type: "text",
                            label: "Paterno",
                            placeholder: "Paterno",
                          },
                          model: {
                            value: _vm.currentAccionista.paterno,
                            callback: function ($$v) {
                              _vm.$set(_vm.currentAccionista, "paterno", $$v)
                            },
                            expression: "currentAccionista.paterno",
                          },
                        }),
                        _c("span", { staticClass: "cardErrors" }, [
                          _vm._v(_vm._s(errors[0])),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-md-4" },
            [
              _c("base-input", {
                attrs: {
                  type: "text",
                  label: "Materno",
                  placeholder: "Materno",
                },
                model: {
                  value: _vm.currentAccionista.materno,
                  callback: function ($$v) {
                    _vm.$set(_vm.currentAccionista, "materno", $$v)
                  },
                  expression: "currentAccionista.materno",
                },
              }),
            ],
            1
          ),
        ])
      : _vm._e(),
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-md-4" },
        [
          _c("ValidationProvider", {
            attrs: {
              name: "porcentaje",
              rules: "required",
              "custom-messages": {
                required: "El porcentaje es obligatorio",
              },
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ errors }) {
                  return [
                    _c("base-input", {
                      attrs: {
                        type: "number",
                        label: "Porcentaje",
                        placeholder: "Porcentaje",
                      },
                      on: { keyup: _vm.calcularCapitales },
                      model: {
                        value: _vm.currentAccionista.porcentaje,
                        callback: function ($$v) {
                          _vm.$set(_vm.currentAccionista, "porcentaje", $$v)
                        },
                        expression: "currentAccionista.porcentaje",
                      },
                    }),
                    _c("span", { staticClass: "cardErrors" }, [
                      _vm._v(_vm._s(errors[0])),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ]),
    _vm.currentAccionista.persona === "Persona Moral"
      ? _c("label", { staticClass: "mt-2 leyenda" }, [
          _vm._v(" La cotización básica incluye solo persona física. "),
        ])
      : _vm._e(),
    _vm._m(0),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text-center" }, [
      _c(
        "button",
        {
          staticClass: "btn btn-fill float-right orange-btn",
          attrs: { type: "submit" },
        },
        [_vm._v(" Guardar ")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }