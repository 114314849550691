var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c("div", { staticClass: "container" }, [
        _vm.loading
          ? _c("div", [
              _c(
                "div",
                { attrs: { id: "loader" } },
                [
                  _c("font-awesome-icon", {
                    attrs: { icon: "spinner", spin: "" },
                  }),
                ],
                1
              ),
            ])
          : _vm._e(),
      ]),
      _c(
        "b-modal",
        {
          ref: "entrega-documento-modal",
          attrs: {
            "no-close-on-backdrop": "",
            centered: "",
            title: "",
            "hide-footer": "",
            id: "modalC",
            size: "xl",
          },
        },
        [
          _c(
            "div",
            [
              _c("ValidationObserver", {
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ handleSubmit }) {
                      return [
                        _c(
                          "form",
                          {
                            on: {
                              submit: function ($event) {
                                $event.preventDefault()
                                return handleSubmit(_vm.postDocumento)
                              },
                            },
                          },
                          [
                            _c("ModalEntregaDocumento", {
                              attrs: {
                                currentDocumento: _vm.currentDocumento,
                                notificacion: _vm.notificacion,
                              },
                              on: {
                                submit: function ($event) {
                                  $event.preventDefault()
                                },
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ]
      ),
      _c(
        "b-modal",
        {
          ref: "documento-modal-preview-img",
          attrs: {
            "no-close-on-backdrop": "",
            centered: "",
            title: "",
            "hide-footer": "",
            id: "modaldi",
            size: "xl",
          },
        },
        [
          _c("ModalPreviewImg", {
            attrs: { currentDocumento: _vm.currentDocumento },
          }),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          ref: "documento-modal-preview-pdf",
          attrs: {
            "no-close-on-backdrop": "",
            centered: "",
            title: "",
            "hide-footer": "",
            id: "modaldf",
            size: "xl",
          },
        },
        [
          _c("ModalPreviewPDF", {
            attrs: { currentDocumento: _vm.currentDocumento },
          }),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          ref: "modal-confirm-regenerate",
          attrs: {
            "no-close-on-backdrop": "",
            centered: "",
            "hide-footer": "",
            id: "modal-confirm-regenerate",
          },
        },
        [
          _c("h4", { staticClass: "text-center" }, [
            _vm._v(
              " ¿Está seguro de regenerar la Carta de Instrucción? La Carta existente será eliminada. "
            ),
          ]),
          _c("h5", { staticClass: "text-center" }),
          _c(
            "b-button",
            {
              staticClass: "mt-3",
              attrs: { variant: "danger" },
              on: {
                click: function ($event) {
                  return _vm.$bvModal.hide("modal-confirm-regenerate")
                },
              },
            },
            [_vm._v("Cancelar")]
          ),
          _c(
            "b-button",
            {
              staticClass: "mt-3 float-right",
              attrs: { variant: "primary" },
              on: {
                click: function ($event) {
                  return _vm.regenerarCartaInstruccion()
                },
              },
            },
            [_vm._v("OK")]
          ),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          ref: "modal-confirm-regenerate-notificar",
          attrs: {
            "no-close-on-backdrop": "",
            centered: "",
            "hide-footer": "",
            id: "modal-confirm-regenerate-notificar",
          },
        },
        [
          _c("h4", { staticClass: "text-center" }, [
            _vm._v(
              " ¿Está seguro de regenerar el Documento a Notificar con FAD? En caso de haber uno existente, será eliminado. "
            ),
          ]),
          _c("h5", { staticClass: "text-center" }),
          _c(
            "b-button",
            {
              staticClass: "mt-3",
              attrs: { variant: "danger" },
              on: {
                click: function ($event) {
                  return _vm.$bvModal.hide("modal-confirm-regenerate-notificar")
                },
              },
            },
            [_vm._v("Cancelar")]
          ),
          _c(
            "b-button",
            {
              staticClass: "mt-3 float-right",
              attrs: { variant: "primary" },
              on: {
                click: function ($event) {
                  return _vm.regenerarDocumentoANotificar()
                },
              },
            },
            [_vm._v("OK")]
          ),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          ref: "modal-confirm-regenerate-kyc",
          attrs: {
            "no-close-on-backdrop": "",
            centered: "",
            "hide-footer": "",
            id: "modal-confirm-regenerate-kyc",
          },
        },
        [
          _c("h4", { staticClass: "text-center" }, [
            _vm._v(
              " ¿Está seguro de regenerar el KYC? En caso de haber uno existente, será eliminado. "
            ),
          ]),
          _c("h5", { staticClass: "text-center" }),
          _c(
            "b-button",
            {
              staticClass: "mt-3",
              attrs: { variant: "danger" },
              on: {
                click: function ($event) {
                  return _vm.$bvModal.hide("modal-confirm-regenerate-kyc")
                },
              },
            },
            [_vm._v("Cancelar")]
          ),
          _c(
            "b-button",
            {
              staticClass: "mt-3 float-right",
              attrs: { variant: "primary" },
              on: {
                click: function ($event) {
                  return _vm.regenerarKYC()
                },
              },
            },
            [_vm._v("OK")]
          ),
        ],
        1
      ),
      _c("h4", { staticClass: "cardD" }, [_vm._v("Entrega de Documentos")]),
      _c(
        "p",
        { staticClass: "font-weight-bold" },
        [
          _vm._v(
            " Comparte tus documentos con nosotros. Haz clic en el botón "
          ),
          _c("b-icon-upload", {
            staticStyle: { "margin-left": "8px", "margin-right": "8px" },
          }),
          _vm._v(
            " para subir tus documentos a notificar y tu identificación oficial. "
          ),
        ],
        1
      ),
      _c(
        "div",
        [
          _c("b-table", {
            ref: "table",
            attrs: {
              hover: "",
              fields: _vm.fieldsDocumentos,
              items: _vm.notificacion.documentos,
            },
            scopedSlots: _vm._u([
              {
                key: "cell(tipo)",
                fn: function ({ item }) {
                  return [
                    item.tipo === "Otro"
                      ? _c("div", [
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "b-tooltip",
                                  rawName: "v-b-tooltip.hover",
                                  modifiers: { hover: true },
                                },
                              ],
                              attrs: { title: item.descripcion },
                            },
                            [_vm._v(" " + _vm._s(item.tipo) + " ")]
                          ),
                        ])
                      : _c("div", [_vm._v(" " + _vm._s(item.tipo) + " ")]),
                  ]
                },
              },
              {
                key: "cell(acciones)",
                fn: function ({ item }) {
                  return [
                    !_vm.isDocumentoCartaDeInstruccion(item) &&
                    (_vm.notificacion.id_estatus == 22 ||
                      (_vm.notificacion.staff &&
                        !_vm.notificacion.validaDocumentos))
                      ? _c(
                          "b-button",
                          {
                            directives: [
                              {
                                name: "b-tooltip",
                                rawName: "v-b-tooltip.hover",
                                modifiers: { hover: true },
                              },
                            ],
                            staticClass: "editBtn",
                            attrs: {
                              variant: "warning",
                              size: "sm",
                              title: "Subir documento",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.openEditDocumentoModal(item)
                              },
                            },
                          },
                          [_c("b-icon-upload")],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "b-button",
                      {
                        directives: [
                          {
                            name: "b-tooltip",
                            rawName: "v-b-tooltip.hover",
                            modifiers: { hover: true },
                          },
                        ],
                        staticClass: "editBtn",
                        attrs: {
                          variant: "primary",
                          size: "sm",
                          title: "Vista previa",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.previewDocumento(item)
                          },
                        },
                      },
                      [_c("b-icon-eye")],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("div", { attrs: { hidden: "" } }, [
        _c(
          "div",
          { ref: "cartaInstruccion" },
          [
            _c("CartaInstruccionTemplate", {
              key: _vm.cartaKey,
              attrs: { datos: _vm.datos },
            }),
          ],
          1
        ),
        _c(
          "div",
          { ref: "hojaFirmas" },
          [
            _c("HojaFirmasTemplate", {
              key: _vm.cartaKey,
              attrs: { datos: _vm.datos },
            }),
          ],
          1
        ),
      ]),
      _vm.datosCompletos
        ? _c(
            "div",
            { ref: "KycSolicitanteTemplate", attrs: { hidden: "" } },
            _vm._l(_vm.notificacion.solicitantes, function (solicitante) {
              return _c(
                "div",
                { key: solicitante.id },
                [
                  _c("KycSolicitanteTemplate", {
                    attrs: {
                      id: solicitante.id,
                      currentCompareciente: solicitante,
                      beneficiario_pld: 0,
                      beneficiario_cff: 0,
                    },
                  }),
                ],
                1
              )
            }),
            0
          )
        : _vm._e(),
      !_vm.notificacion.staff
        ? _c("div", { staticClass: "row mt-4" }, [
            _c(
              "div",
              { staticClass: "col-md-12" },
              [
                _c("ValidationProvider", {
                  attrs: {
                    name: "confirmAceptacion",
                    rules: { required: { allowFalse: false } },
                    "custom-messages": {
                      required: "La confirmación es obligatoria",
                    },
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function ({ errors }) {
                          return [
                            _c(
                              "b-form-checkbox",
                              {
                                staticClass: "orange",
                                attrs: {
                                  disabled:
                                    (!_vm.notificacion.staff &&
                                      (_vm.notificacion.id_estatus != 22 ||
                                        !_vm.documentosCompletos)) ||
                                    (_vm.notificacion.staff &&
                                      _vm.notificacion.validaDocumentos == 1),
                                },
                                model: {
                                  value: _vm.confirmAceptacion,
                                  callback: function ($$v) {
                                    _vm.confirmAceptacion = $$v
                                  },
                                  expression: "confirmAceptacion",
                                },
                              },
                              [
                                _vm._v(
                                  " Acepto que la información proporcionada es correcta. "
                                ),
                              ]
                            ),
                            _c("span", { staticClass: "cardErrors" }, [
                              _vm._v(_vm._s(errors[0])),
                            ]),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    245962925
                  ),
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
      !_vm.notificacion.staff
        ? _c("div", { staticClass: "mt-4" }, [
            _c(
              "div",
              {},
              [
                _c(
                  "b-button",
                  {
                    staticClass: "btn-fill orange-btn bottom2",
                    attrs: {
                      type: "submit",
                      size: "m",
                      disabled:
                        !_vm.confirmAceptacion ||
                        (_vm.notificacion.id_estatus != 22 &&
                          _vm.documentosCompletos),
                    },
                  },
                  [_vm._v("Continuar")]
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm.notificacion.staff
        ? _c("div", { staticClass: "mt-4" }, [
            _c(
              "div",
              {},
              [
                _c(
                  "b-button",
                  {
                    staticClass: "btn-fill orange-btn bottom1",
                    attrs: {
                      size: "m",
                      disabled: _vm.notificacion.validaDocumentos == 1,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.openModalRegenerate()
                      },
                    },
                  },
                  [_vm._v("Regenerar Carta de Instrucción")]
                ),
              ],
              1
            ),
            parseInt(_vm.notificacion.fad) == 1
              ? _c(
                  "div",
                  {},
                  [
                    _c(
                      "b-button",
                      {
                        staticClass: "btn-fill orange-btn bottom1",
                        attrs: {
                          size: "m",
                          disabled: _vm.notificacion.validaDocumentos == 1,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.openModalRegenerateNotificar()
                          },
                        },
                      },
                      [_vm._v("Regenerar Documento a Notificar")]
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "div",
              {},
              [
                _c(
                  "b-button",
                  {
                    staticClass: "btn-fill orange-btn bottom1",
                    attrs: {
                      size: "m",
                      disabled: _vm.notificacion.validaDocumentos == 1,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.openModalRegenerateKYC()
                      },
                    },
                  },
                  [_vm._v("Regenerar KYC")]
                ),
              ],
              1
            ),
            _c(
              "div",
              {},
              [
                _c(
                  "b-button",
                  {
                    staticClass: "btn-fill orange-btn bottom2",
                    attrs: {
                      type: "submit",
                      size: "m",
                      disabled: _vm.notificacion.validaDocumentos == 1,
                    },
                  },
                  [_vm._v("Validar")]
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }