import { render, staticRenderFns } from "./ValidCotejo.vue?vue&type=template&id=3c83249e"
import script from "./ValidCotejo.vue?vue&type=script&lang=js"
export * from "./ValidCotejo.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/github/workspace/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3c83249e')) {
      api.createRecord('3c83249e', component.options)
    } else {
      api.reload('3c83249e', component.options)
    }
    module.hot.accept("./ValidCotejo.vue?vue&type=template&id=3c83249e", function () {
      api.rerender('3c83249e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/staff/tasks/validaciones/ValidCotejo.vue"
export default component.exports