var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-md-12" },
          [
            _c("ValidationProvider", {
              attrs: {
                name: "denominacion_social",
                rules: "required",
                "custom-messages": {
                  required: "La denominación social es obligatoria",
                },
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ errors }) {
                    return [
                      _c("base-input", {
                        attrs: {
                          disabled: _vm.user.disabled,
                          type: "text",
                          label: "Denominación o Razón Social",
                          placeholder: "Denominación Social",
                        },
                        model: {
                          value: _vm.user.denominacion_social,
                          callback: function ($$v) {
                            _vm.$set(_vm.user, "denominacion_social", $$v)
                          },
                          expression: "user.denominacion_social",
                        },
                      }),
                      _c("span", { staticClass: "cardErrors" }, [
                        _vm._v(_vm._s(errors[0])),
                      ]),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-md-8" },
          [
            _c("ValidationProvider", {
              attrs: {
                name: "actividad",
                rules: _vm.requiredIfNotRatificacion,
                "custom-messages": {
                  required: "La actividad es obligatoria",
                },
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ errors }) {
                    return [
                      _c("base-input", {
                        attrs: {
                          disabled: _vm.user.disabled,
                          type: "text",
                          label: "Actividad o Giro Mercantil",
                          placeholder: "Actividad",
                        },
                        model: {
                          value: _vm.user.actividad,
                          callback: function ($$v) {
                            _vm.$set(_vm.user, "actividad", $$v)
                          },
                          expression: "user.actividad",
                        },
                      }),
                      _c("span", { staticClass: "cardErrors" }, [
                        _vm._v(_vm._s(errors[0])),
                      ]),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-md-4" },
          [
            _c("ValidationProvider", {
              attrs: {
                name: "fecha_constitucion",
                rules: "required",
                "custom-messages": {
                  required: "La fecha de constitución es obligatoria",
                },
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ errors }) {
                    return [
                      _c("base-input", {
                        attrs: {
                          disabled: _vm.user.disabled,
                          type: "date",
                          label: "Fecha de constitución",
                          placeholder: "Fecha de constitución",
                        },
                        model: {
                          value: _vm.user.fecha_constitucion,
                          callback: function ($$v) {
                            _vm.$set(_vm.user, "fecha_constitucion", $$v)
                          },
                          expression: "user.fecha_constitucion",
                        },
                      }),
                      _c("span", { staticClass: "cardErrors" }, [
                        _vm._v(_vm._s(errors[0])),
                      ]),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-md-5" },
          [
            _c("ValidationProvider", {
              attrs: {
                name: "nacionalidad",
                rules: "required",
                "custom-messages": {
                  required: "La nacionalidad es obligatoria",
                },
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ errors }) {
                    return [
                      _c("label", [_vm._v("Nacionalidades")]),
                      _c("v-select", {
                        attrs: {
                          disabled: _vm.user.disabled,
                          multiple: "",
                          label: "nacionalidad",
                          options: _vm.optionsNacionalidades,
                        },
                        model: {
                          value: _vm.user.nacionalidades,
                          callback: function ($$v) {
                            _vm.$set(_vm.user, "nacionalidades", $$v)
                          },
                          expression: "user.nacionalidades",
                        },
                      }),
                      _c("span", { staticClass: "cardErrors" }, [
                        _vm._v(_vm._s(errors[0])),
                      ]),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-md-7" },
          [
            _c("ValidationProvider", {
              attrs: {
                name: "domicilio_social",
                rules: "required",
                "custom-messages": {
                  required: "El domicilio social es obligatorio",
                },
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ errors }) {
                    return [
                      _c("base-input", {
                        attrs: {
                          disabled: _vm.user.disabled,
                          type: "text",
                          label: "Domicilio social",
                          placeholder: "Domicilio social",
                        },
                        model: {
                          value: _vm.user.domicilio_social,
                          callback: function ($$v) {
                            _vm.$set(_vm.user, "domicilio_social", $$v)
                          },
                          expression: "user.domicilio_social",
                        },
                      }),
                      _c("span", { staticClass: "cardErrors" }, [
                        _vm._v(_vm._s(errors[0])),
                      ]),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-md-12" },
          [
            _c("ValidationProvider", {
              attrs: {
                name: "objeto_social",
                rules: _vm.requiredIfNotRatificacion,
                "custom-messages": {
                  required: "El objeto social es obligatorio",
                },
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ errors }) {
                    return [
                      _c("label", [_vm._v("Objeto Social")]),
                      _c("b-form-textarea", {
                        attrs: {
                          disabled: _vm.user.disabled,
                          type: "text",
                          placeholder: "Objeto Social (Resumen)",
                        },
                        model: {
                          value: _vm.user.objeto_social,
                          callback: function ($$v) {
                            _vm.$set(_vm.user, "objeto_social", $$v)
                          },
                          expression: "user.objeto_social",
                        },
                      }),
                      _c("span", { staticClass: "cardErrors" }, [
                        _vm._v(_vm._s(errors[0])),
                      ]),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
      ]),
      _c("br"),
      !_vm.user.entidad_extranjera
        ? _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-md-6" },
              [
                _c("ValidationProvider", {
                  attrs: {
                    name: "instrumento_numero",
                    rules: "required",
                    "custom-messages": {
                      required: "El número de instrumento es obligatorio",
                    },
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function ({ errors }) {
                          return [
                            _c("base-input", {
                              attrs: {
                                disabled: _vm.user.disabled,
                                type: "text",
                                label: "Instrumento Constitutivo",
                                placeholder:
                                  "Número de Instrumento Constitutivo",
                              },
                              model: {
                                value: _vm.user.instrumento_numero,
                                callback: function ($$v) {
                                  _vm.$set(_vm.user, "instrumento_numero", $$v)
                                },
                                expression: "user.instrumento_numero",
                              },
                            }),
                            _c("span", { staticClass: "cardErrors" }, [
                              _vm._v(_vm._s(errors[0])),
                            ]),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    2186914035
                  ),
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-md-6" },
              [
                _c("ValidationProvider", {
                  attrs: {
                    name: "instrumento_numero_fecha",
                    rules: "required",
                    "custom-messages": { required: "La fecha es obligatoria" },
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function ({ errors }) {
                          return [
                            _c("base-input", {
                              attrs: {
                                disabled: _vm.user.disabled,
                                type: "date",
                                label: "De Fecha",
                                placeholder: "De Fecha",
                              },
                              model: {
                                value: _vm.user.instrumento_numero_fecha,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.user,
                                    "instrumento_numero_fecha",
                                    $$v
                                  )
                                },
                                expression: "user.instrumento_numero_fecha",
                              },
                            }),
                            _c("span", { staticClass: "cardErrors" }, [
                              _vm._v(_vm._s(errors[0])),
                            ]),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    2921612844
                  ),
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
      !_vm.user.entidad_extranjera
        ? _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-md-2" },
              [
                _c("ValidationProvider", {
                  attrs: {
                    name: "instrumento_fedatario",
                    rules: "required",
                    "custom-messages": {
                      required: "El tipo de fedatario es obligatorio",
                    },
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function ({ errors }) {
                          return [
                            _c("label", [_vm._v("Tipo de Fedatario")]),
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.user.instrumento_fedatario,
                                    expression: "user.instrumento_fedatario",
                                  },
                                ],
                                staticClass: "custom-select",
                                on: {
                                  change: function ($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call(
                                        $event.target.options,
                                        function (o) {
                                          return o.selected
                                        }
                                      )
                                      .map(function (o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.$set(
                                      _vm.user,
                                      "instrumento_fedatario",
                                      $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    )
                                  },
                                },
                              },
                              [
                                _c("option", { attrs: { value: "Notario" } }, [
                                  _vm._v("Notario"),
                                ]),
                                _c(
                                  "option",
                                  { attrs: { value: "Corredor Público" } },
                                  [_vm._v("Corredor Público")]
                                ),
                              ]
                            ),
                            _c("span", { staticClass: "cardErrors" }, [
                              _vm._v(_vm._s(errors[0])),
                            ]),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    3465942812
                  ),
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-md-4" },
              [
                _c("ValidationProvider", {
                  attrs: {
                    name: "instrumento_fedatario_nombre",
                    rules: "required",
                    "custom-messages": {
                      required: "El nombre del fedatario es obligatorio",
                    },
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function ({ errors }) {
                          return [
                            _c("base-input", {
                              attrs: {
                                disabled: _vm.user.disabled,
                                type: "text",
                                label: "Nombre de Fedatario",
                                placeholder: "Nombre de Fedatario",
                              },
                              model: {
                                value: _vm.user.instrumento_fedatario_nombre,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.user,
                                    "instrumento_fedatario_nombre",
                                    $$v
                                  )
                                },
                                expression: "user.instrumento_fedatario_nombre",
                              },
                            }),
                            _c("span", { staticClass: "cardErrors" }, [
                              _vm._v(_vm._s(errors[0])),
                            ]),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    1018156540
                  ),
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-md-3" },
              [
                _c("ValidationProvider", {
                  attrs: {
                    name: "instrumento_fedatario_numero",
                    rules: "required",
                    "custom-messages": {
                      required: "El número es obligatorio",
                    },
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function ({ errors }) {
                          return [
                            _c("base-input", {
                              attrs: {
                                disabled: _vm.user.disabled,
                                type: "text",
                                label: "Número",
                                placeholder: "Número",
                              },
                              model: {
                                value: _vm.user.instrumento_fedatario_numero,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.user,
                                    "instrumento_fedatario_numero",
                                    $$v
                                  )
                                },
                                expression: "user.instrumento_fedatario_numero",
                              },
                            }),
                            _c("span", { staticClass: "cardErrors" }, [
                              _vm._v(_vm._s(errors[0])),
                            ]),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    2721151307
                  ),
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-md-3" },
              [
                _c("ValidationProvider", {
                  attrs: {
                    name: "instrumento_fedatario_estado",
                    rules: "required",
                    "custom-messages": {
                      required: "El estado es obligatorio",
                    },
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function ({ errors }) {
                          return [
                            _c("label", [_vm._v("Entidad Federativa")]),
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.user.instrumento_fedatario_estado,
                                    expression:
                                      "user.instrumento_fedatario_estado",
                                  },
                                ],
                                staticClass: "custom-select",
                                attrs: { disabled: _vm.user.disabled },
                                on: {
                                  change: function ($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call(
                                        $event.target.options,
                                        function (o) {
                                          return o.selected
                                        }
                                      )
                                      .map(function (o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.$set(
                                      _vm.user,
                                      "instrumento_fedatario_estado",
                                      $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    )
                                  },
                                },
                              },
                              _vm._l(_vm.optionsEstados, function (option) {
                                return _c("option", { key: option.value }, [
                                  _vm._v(" " + _vm._s(option.text) + " "),
                                ])
                              }),
                              0
                            ),
                            _c("span", { staticClass: "cardErrors" }, [
                              _vm._v(_vm._s(errors[0])),
                            ]),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    3607106968
                  ),
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
      !_vm.user.entidad_extranjera
        ? _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-md-4" },
              [
                _c("ValidationProvider", {
                  attrs: {
                    name: "instrumento_rpf_folio",
                    rules: "required",
                    "custom-messages": {
                      required: "El RPF Folio es obligatorio",
                    },
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function ({ errors }) {
                          return [
                            _c("base-input", {
                              attrs: {
                                disabled: _vm.user.disabled,
                                type: "text",
                                label: "Folio",
                                placeholder: "Registro Público",
                              },
                              model: {
                                value: _vm.user.instrumento_rpf_folio,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.user,
                                    "instrumento_rpf_folio",
                                    $$v
                                  )
                                },
                                expression: "user.instrumento_rpf_folio",
                              },
                            }),
                            _c("span", { staticClass: "cardErrors" }, [
                              _vm._v(_vm._s(errors[0])),
                            ]),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    680820996
                  ),
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-md-4" },
              [
                _c("ValidationProvider", {
                  attrs: {
                    name: "instrumento_rpf_folio_fecha",
                    rules: "required",
                    "custom-messages": {
                      required: "La fecha es obligatoria",
                    },
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function ({ errors }) {
                          return [
                            _c("base-input", {
                              attrs: {
                                disabled: _vm.user.disabled,
                                type: "date",
                                label: "De Fecha",
                                placeholder: "De Fecha",
                              },
                              model: {
                                value: _vm.user.instrumento_rpf_folio_fecha,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.user,
                                    "instrumento_rpf_folio_fecha",
                                    $$v
                                  )
                                },
                                expression: "user.instrumento_rpf_folio_fecha",
                              },
                            }),
                            _c("span", { staticClass: "cardErrors" }, [
                              _vm._v(_vm._s(errors[0])),
                            ]),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    1127785050
                  ),
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-md-4" },
              [
                _c("ValidationProvider", {
                  attrs: {
                    name: "instrumento_rpf_folio_lugar",
                    rules: "required",
                    "custom-messages": {
                      required: "El lugar es obligatorio",
                    },
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function ({ errors }) {
                          return [
                            _c("base-input", {
                              attrs: {
                                disabled: _vm.user.disabled,
                                type: "text",
                                label: "Lugar",
                                placeholder: "Lugar",
                              },
                              model: {
                                value: _vm.user.instrumento_rpf_folio_lugar,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.user,
                                    "instrumento_rpf_folio_lugar",
                                    $$v
                                  )
                                },
                                expression: "user.instrumento_rpf_folio_lugar",
                              },
                            }),
                            _c("span", { staticClass: "cardErrors" }, [
                              _vm._v(_vm._s(errors[0])),
                            ]),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    954276599
                  ),
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-md-4" },
          [
            _c("label", { attrs: { for: "" } }, [_vm._v("Entidad Extranjera")]),
            _c("b-form-checkbox", {
              on: {
                change: function ($event) {
                  return _vm.entidadExtranjera(_vm.user.entidad_extranjera)
                },
              },
              model: {
                value: _vm.user.entidad_extranjera,
                callback: function ($$v) {
                  _vm.$set(_vm.user, "entidad_extranjera", $$v)
                },
                expression: "user.entidad_extranjera",
              },
            }),
          ],
          1
        ),
        _vm.user.entidad_extranjera
          ? _c(
              "div",
              { staticClass: "col-md-8" },
              [
                _c("base-input", {
                  attrs: {
                    disabled: _vm.user.disabled,
                    type: "text",
                    label: "Datos del documento que pruebe constitución",
                    placeholder: "Datos del documento que pruebe constitución",
                  },
                  model: {
                    value: _vm.user.datos_prueba_constitucion,
                    callback: function ($$v) {
                      _vm.$set(_vm.user, "datos_prueba_constitucion", $$v)
                    },
                    expression: "user.datos_prueba_constitucion",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
      ]),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-md-5" },
          [
            _c("ValidationProvider", {
              attrs: {
                name: "rfc",
                rules: "required|length:12",
                "custom-messages": {
                  required: "El RFC es obligatorio",
                  length: "Debe tener 12 caracteres",
                },
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ errors }) {
                    return [
                      _c("base-input", {
                        attrs: {
                          disabled: _vm.user.disabled,
                          type: "text",
                          label: "RFC",
                          placeholder: "RFC",
                        },
                        model: {
                          value: _vm.user.rfc,
                          callback: function ($$v) {
                            _vm.$set(_vm.user, "rfc", $$v)
                          },
                          expression: "user.rfc",
                        },
                      }),
                      _c("span", { staticClass: "cardErrors" }, [
                        _vm._v(_vm._s(errors[0])),
                      ]),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
      ]),
      _c(
        "b-button",
        {
          staticClass: "btn-fill orange-btn bottom",
          attrs: { type: "submit", size: "lg", disabled: _vm.user.disabled },
        },
        [_vm._v("Guardar")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }