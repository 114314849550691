var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c("h4", { staticClass: "cardD" }, [
        _vm._v("Documento listo para entrega"),
      ]),
      _c("card", { staticClass: "blue" }, [
        _c("i", { staticClass: "fa fa-info" }),
        _vm._v(" Aquí van instrucciones. "),
        _c("p"),
        _c("i", { staticClass: "fa fa-play" }),
        _vm._v(" Links a videos. "),
        _c("p"),
        _c("i", { staticClass: "fa fa-question" }),
        _vm._v(" Datos de contacta a un asesor. "),
      ]),
      _vm.constitutiva.estatus === "Documento listo"
        ? _c("div", [
            _c("h4", { staticClass: "cardG" }, [
              _vm._v(
                " Pase por su documento a la Correduría en un horario de 10:00 a 18:00 horas de lunes a viernes. De ser posible llame para hacer una cita. "
              ),
            ]),
          ])
        : _c("div", { staticClass: "cardY" }, [_vm._v("En proceso")]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }