var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container" }, [
    _c("h4", { staticClass: "cardD" }, [_vm._v(" Finalización de proceso ")]),
    _c("div", { staticClass: "mt-4" }, [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-12" },
          [
            _c(
              "b-button",
              {
                staticClass: "btn-fill orange-btn bottom2",
                attrs: {
                  type: "submit",
                  block: "",
                  size: "m",
                  disabled: _vm.constitutiva.disabledTerminado,
                },
              },
              [_vm._v("Dar proceso por terminado")]
            ),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }