var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("table", { staticClass: "tabla" }, [
      _c("tr", { staticClass: "date" }, [
        _c("th", [
          _vm._v(
            " Ciudad de México a " +
              _vm._s(_vm.day) +
              " de " +
              _vm._s(_vm.month) +
              " de " +
              _vm._s(_vm.year) +
              " "
          ),
        ]),
      ]),
    ]),
    _vm._m(0),
    _vm._m(1),
    _c("table", { staticClass: "tabla" }, [
      _c("tr", { staticClass: "space", staticStyle: { height: "100px" } }),
      _c("tr", [
        _c("th", { staticClass: "section", attrs: { colspan: "10" } }, [
          _vm._v(" " + _vm._s(_vm.nombre_firmante) + " "),
        ]),
      ]),
      _c("tr", { staticClass: "space", staticStyle: { height: "50px" } }),
      _vm._m(2),
      _vm.apoderado
        ? _c("tr", [
            _c("td", { staticClass: "space", staticStyle: { width: "20%" } }),
            _c(
              "td",
              {
                staticStyle: {
                  width: "60%",
                  "vertical-align": "top",
                  "font-size": "14px",
                  "text-align": "center",
                  "background-color": "white",
                },
              },
              [_vm._v(" Por: " + _vm._s(_vm.nombre_apoderado) + " ")]
            ),
            _c("td", {
              staticClass: "space",
              staticStyle: { width: "20%" },
              attrs: { colspan: "2" },
            }),
          ])
        : _vm._e(),
      _vm.apoderado
        ? _c("tr", [
            _c("td", { staticClass: "space", staticStyle: { width: "20%" } }),
            _c(
              "td",
              {
                staticStyle: {
                  width: "60%",
                  "vertical-align": "top",
                  "font-size": "14px",
                  "text-align": "center",
                  "background-color": "white",
                },
              },
              [_vm._v(" Cargo: Representante Legal ")]
            ),
            _c("td", {
              staticClass: "space",
              staticStyle: { width: "20%" },
              attrs: { colspan: "2" },
            }),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("table", { staticClass: "tabla" }, [
      _c("tr", [
        _c("td", { staticStyle: { width: "62.5%", "font-weight": "bolder" } }, [
          _vm._v(" Correduria Digital, S. de R.L. de C.V. "),
        ]),
      ]),
      _c("tr", [
        _c("td", [_vm._v(" Av. Paseo de las Palmas 731, oficina 202, ")]),
      ]),
      _c("tr", [
        _c("td", [_vm._v(" Lomas de Chapultepec, 11000, Miguel Hidalgo, ")]),
      ]),
      _c("tr", [_c("td", [_vm._v(" Ciudad de México, México ")])]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "table",
      {
        staticClass: "tabla",
        staticStyle: { "margin-top": "5px", "border-spacing": "0 15px" },
      },
      [
        _c("tr", [
          _c("td", [
            _vm._v(
              " Por medio del presente escrito, autorizo e instruyo a Correduría Digital, S. de R.L. de C.V. para que gestione con el Corredor Público de su elección, la elaboración del cotejo de los documentos contenidos como anexos en esta solicitud y/o aquellos que sean presentados directamente en la oficina que haya sido asignada, aceptando en su totalidad los términos y condiciones del servicio. "
            ),
          ]),
        ]),
        _c("tr", [
          _c("td", [
            _vm._v(
              " El solicitante declara a Correduria Digital y al Corredor Público que ésta designe, bajo protesta de decir verdad: "
            ),
          ]),
        ]),
        _c("tr", [
          _c("td", [
            _c("ul", { staticStyle: { "list-style-type": "'- '" } }, [
              _c("li", [
                _vm._v(
                  " Que la totalidad de documentos, correspondientes al servicio solicitado, es aquella que ha quedado descrita en la plataforma de “Correduria Digital”, conforme a la información proporcionada por el solicitante. "
                ),
                _c("br"),
                _c("br"),
                _vm._v(
                  " Entiendo que el staff de Correduría Digital revisará la información proporcionada, conforme a la documentación electrónica y/o física que sea entrega, por lo que, en caso de existir diferencias entre éstas, entiendo que se generarán ajustes a la cotización proporcionada, mismos que, en este acto, me obligo a cubrir para que se pueda concluir con la prestación del servicio. "
                ),
                _c("br"),
                _c("br"),
              ]),
              _c("li", [
                _vm._v(
                  " Que los archivos en PDF que, en su caso, han sido enviados a través de la plataforma de Correduría Digital, han sido digitalizados por el solicitante, y los mismos corresponden "
                ),
                _c("b", [_vm._v("EXACTAMENTE")]),
                _vm._v(
                  " al/los documento/s que presentaré para su cotejo; los cuales no difieren en ninguna de sus partes, ni en volumen ni en contenido a aquellos que serán presentados para cotejo. "
                ),
                _c("br"),
                _c("br"),
                _vm._v(
                  " Entiendo que, en caso de que, al presentar los documentos originales para cotejo, éstos difieran, por cualquier motivo, del archivo digitalizado enviado, se generarán cargos adicionales y Correduría Digital no podrá garantizar los tiempos de entrega señalados. "
                ),
                _c("br"),
                _vm._v(
                  " Comprendo que, en caso de presentar un testimonio o cualquier tipo de documento identificable de forma individual, a cotejo, se deberá escanear, subir y presentar exactamente el mismo ejemplar del cual requiero obtener la copia certificada (por ejemplo, en caso de escanear el primer testimonio y presentar en las oficinas de la correduría un testimonio distinto, se deberá realizar de nuevo el trabajo y dicha situación generará un costo extra). "
                ),
                _c("br"),
                _c("br"),
              ]),
              _c("li", [
                _vm._v(
                  " Que me encuentro en legítima posesión de los documentos que serán entregados para cotejo y que, en su caso, han sido previamente digitalizados y enviados a través de la plataforma de Correduría Digital. "
                ),
                _c("br"),
                _c("br"),
              ]),
              _c("li", [
                _vm._v(
                  " Que los documentos objeto de la solicitud de servicio: (i) son mercantiles; (ii) no se refieren a documentos de los señalados como no cotejables; (iii) se encuentran completos; y (iv) no han sido alterados ni modificados, ni presentan enmendaduras. "
                ),
                _c("br"),
              ]),
            ]),
          ]),
        ]),
        _c("tr", [
          _c("td", { staticStyle: { "text-align": "center" } }, [
            _c("b", [
              _vm._v(
                "“EN TODOS LOS CASOS ES NECESARIO LA PRESENTACIÓN DE LOS DOCUMENTOS ORIGINALES PARA SU COTEJO.” "
              ),
            ]),
          ]),
        ]),
        _c("tr", [
          _c("td", { staticStyle: { "text-align": "center" } }, [
            _c("b", [
              _vm._v(
                "“NO SE ENTREGARÁ COTEJO ALGUNO SIN LA PRESENTACIÓN DE DOCUMENTOS ORIGINALES”"
              ),
            ]),
            _vm._v(" "),
            _c("br"),
          ]),
        ]),
        _c("tr", [_c("td", [_vm._v(" Atentamente, ")])]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", { staticClass: "space", staticStyle: { width: "20%" } }),
      _c("td", {
        staticClass: "user",
        staticStyle: {
          width: "60%",
          "background-color": "white !important",
          "border-bottom": "solid 2px black",
        },
      }),
      _c("td", { staticClass: "space", staticStyle: { width: "20%" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }