var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    _vm.tag,
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.closeDropDown,
          expression: "closeDropDown",
        },
      ],
      tag: "li",
      staticClass: "dropdown nav-item",
      class: { show: _vm.isOpen },
      attrs: { "aria-haspopup": "true", "aria-expanded": _vm.isOpen },
      on: { click: _vm.toggleDropDown },
    },
    [
      _c(
        "a",
        {
          staticClass: "nav-link dropdown-toggle",
          attrs: { "data-toggle": "dropdown" },
        },
        [
          _vm._t("title", function () {
            return [
              _c("i", { class: _vm.icon }),
              _c("span", { staticClass: "no-icon" }, [
                _vm._v(_vm._s(_vm.title)),
              ]),
              _c("b", { staticClass: "caret" }),
            ]
          }),
        ],
        2
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isOpen,
              expression: "isOpen",
            },
          ],
          staticClass: "dropdown-menu show",
        },
        [_vm._t("default")],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }