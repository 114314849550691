var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c("h4", { staticClass: "cardD" }, [
        _vm._v("Representantes orgánicos y apoderados"),
      ]),
      _c(
        "b-modal",
        {
          ref: "modal-confirm-dialog",
          attrs: {
            "no-close-on-backdrop": "",
            centered: "",
            "hide-footer": "",
            id: "modal-confirm-dialog",
          },
        },
        [
          _c("h4", { staticClass: "text-center" }, [
            _vm._v(
              " ¿Seguro que quiere eliminar al representante o apoderado? "
            ),
          ]),
          _c("h5", { staticClass: "text-center" }),
          _c(
            "b-button",
            {
              staticClass: "mt-3",
              attrs: { variant: "danger" },
              on: {
                click: function ($event) {
                  return _vm.$bvModal.hide("modal-confirm-dialog")
                },
              },
            },
            [_vm._v("Cancelar")]
          ),
          _c(
            "b-button",
            {
              staticClass: "mt-3 float-right",
              attrs: { variant: "primary" },
              on: { click: _vm.deleteApoderado },
            },
            [_vm._v("OK")]
          ),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          ref: "apoderado-modal",
          attrs: {
            "no-close-on-backdrop": "",
            centered: "",
            title: "",
            "hide-footer": "",
            id: "modalC",
            size: "xl",
          },
        },
        [
          _c("ValidationObserver", {
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ handleSubmit }) {
                  return [
                    _c(
                      "form",
                      {
                        on: {
                          submit: function ($event) {
                            $event.preventDefault()
                            return handleSubmit(_vm.putApoderado)
                          },
                        },
                      },
                      [
                        _c("ModalApoderados", {
                          attrs: {
                            currentApoderado: _vm.currentApoderado,
                            options_facultades: _vm.options_facultades,
                            complete: false,
                          },
                          on: {
                            submit: function ($event) {
                              $event.preventDefault()
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("div", { staticClass: "row mt-5" }, [
        _c(
          "div",
          { staticClass: "col-12" },
          [
            _c("label", { attrs: { for: "" } }, [_vm._v("Apoderados")]),
            _c(
              "b-button",
              {
                staticClass: "btn-fill spaceL",
                attrs: {
                  disabled: _vm.constitutiva.disabled,
                  variant: "success",
                  size: "sm",
                },
                on: { click: _vm.addApoderado },
              },
              [_vm._v(" + ")]
            ),
            _c("b-table", {
              attrs: {
                hover: "",
                fields: _vm.fieldsCuadro,
                items: _vm.constitutiva.apoderados,
              },
              scopedSlots: _vm._u([
                {
                  key: "cell(acciones)",
                  fn: function ({ item }) {
                    return [
                      _c(
                        "b-button",
                        {
                          directives: [
                            {
                              name: "b-tooltip",
                              rawName: "v-b-tooltip.hover",
                              modifiers: { hover: true },
                            },
                          ],
                          staticClass: "editBtn",
                          attrs: {
                            disabled: _vm.constitutiva.disabled,
                            variant: "warning",
                            size: "sm",
                            title: "Editar",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.editApoderado(item)
                            },
                          },
                        },
                        [_c("b-icon-pencil")],
                        1
                      ),
                      _c(
                        "b-button",
                        {
                          directives: [
                            {
                              name: "b-tooltip",
                              rawName: "v-b-tooltip.hover",
                              modifiers: { hover: true },
                            },
                          ],
                          attrs: {
                            disabled: _vm.constitutiva.disabled,
                            variant: "danger",
                            size: "sm",
                            title: "Eliminar",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.predeleteApoderado(item.id)
                            },
                          },
                        },
                        [_c("b-icon-trash")],
                        1
                      ),
                    ]
                  },
                },
              ]),
            }),
            _vm.constitutiva.apoderados.length > 1
              ? _c("label", { staticClass: "mt-2 leyenda" }, [
                  _vm._v(" La cotización básica incluye solo un apoderado. "),
                ])
              : _vm._e(),
          ],
          1
        ),
      ]),
      false
        ? _c("div", { staticClass: "col-6" }, [
            _c("label", { attrs: { for: "" } }, [
              _vm._v("Exclusión de accionistas"),
            ]),
            _vm.constitutiva.roleStaff
              ? _c(
                  "div",
                  [
                    _c("b-form-checkbox", {
                      attrs: { disabled: _vm.constitutiva.disabledEspeciales },
                      model: {
                        value: _vm.constitutiva.exclusion_accionistas,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.constitutiva,
                            "exclusion_accionistas",
                            $$v
                          )
                        },
                        expression: "constitutiva.exclusion_accionistas",
                      },
                    }),
                  ],
                  1
                )
              : _c(
                  "div",
                  [
                    _c("b-form-checkbox", {
                      attrs: { disabled: _vm.constitutiva.disabled },
                      model: {
                        value: _vm.constitutiva.exclusion_accionistas,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.constitutiva,
                            "exclusion_accionistas",
                            $$v
                          )
                        },
                        expression: "constitutiva.exclusion_accionistas",
                      },
                    }),
                  ],
                  1
                ),
            _vm.constitutiva.exclusion_accionistas
              ? _c("label", { staticClass: "mt-2 leyenda" }, [
                  _vm._v(" La cotización básica no incluye esta opción. "),
                ])
              : _vm._e(),
          ])
        : _vm._e(),
      _c(
        "div",
        { staticClass: "mt-4" },
        [
          _c("label", [_vm._v("Dueño beneficiario")]),
          _c("label", { staticClass: "beneficiario" }, [
            _vm._v(
              " Conforme a lo establecido en la Ley Federal para la Prevención e Identificación de Operaciones con Recursos de Procedencia Ilícita, su Reglamento y Reglas de Carácter General, manifiesto, bajo protesta de decir verdad, que: "
            ),
          ]),
          _c(
            "b-button",
            { attrs: { id: `popover-1`, variant: "info", size: "sm" } },
            [_c("i", { staticClass: "fa fa-question" })]
          ),
          _c("b-popover", {
            attrs: {
              target: `popover-1`,
              placement: "left",
              title:
                "LEY FEDERAL PARA LA PREVENCIÓN E IDENTIFICACIÓN DE OPERACIONES CON\n        RECURSOS DE PROCEDENCIA ILÍCITA.",
              triggers: "hover focus",
              content: `Artículo 3.- Para los efectos de esta Ley, se entenderá por:
…III. Beneficiario Controlador, a la persona o grupo de personas que:
a) Por medio de otra o de cualquier acto, obtiene el beneficio derivado de éstos y es quien, en última instancia, ejerce los derechos de uso, goce, disfrute, aprovechamiento o disposición de un bien o servicio, o
b) Ejerce el control de aquella persona moral que, en su carácter de cliente o usuario, lleve a cabo actos u operaciones con quien realice Actividades Vulnerables, así como las personas por cuenta de quienes celebra alguno de ellos.
Se entiende que una persona o grupo de personas controla a una persona moral cuando, a través de la titularidad de valores, por contrato o de cualquier otro acto, puede:
i) Imponer, directa o indirectamente, decisiones en las asambleas generales de accionistas, socios unos órganos equivalentes, o nombrar o destituir a la mayoría de los consejeros, administradores o sus equivalentes;
ii) Mantener la titularidad de los derechos que permitan, directa o indirectamente, ejercer el voto respecto de más del cincuenta por ciento del capital social, o
iii) Dirigir, directa o indirectamente, la administración, la estrategia o las principales políticas de la misma.`,
            },
          }),
          _c("div", { staticClass: "row mt-2" }, [
            _c(
              "div",
              { staticClass: "col-1" },
              [
                _c("b-form-radio", {
                  attrs: { name: "pld", value: "1" },
                  on: {
                    change: function ($event) {
                      return _vm.selectBeneficiarioPLD(1)
                    },
                  },
                  model: {
                    value: _vm.constitutiva.beneficiario_pld,
                    callback: function ($$v) {
                      _vm.$set(_vm.constitutiva, "beneficiario_pld", $$v)
                    },
                    expression: "constitutiva.beneficiario_pld",
                  },
                }),
              ],
              1
            ),
            _vm._m(0),
          ]),
          _c("div", { staticClass: "row mt-2" }, [
            _c(
              "div",
              { staticClass: "col-1" },
              [
                _c("b-form-radio", {
                  attrs: { name: "pld", value: "2" },
                  on: {
                    change: function ($event) {
                      return _vm.selectBeneficiarioPLD(2)
                    },
                  },
                  model: {
                    value: _vm.constitutiva.beneficiario_pld,
                    callback: function ($$v) {
                      _vm.$set(_vm.constitutiva, "beneficiario_pld", $$v)
                    },
                    expression: "constitutiva.beneficiario_pld",
                  },
                }),
              ],
              1
            ),
            _vm._m(1),
          ]),
          _c("div", { staticClass: "row mt-2" }, [
            _c(
              "div",
              { staticClass: "col-1" },
              [
                _c("b-form-radio", {
                  attrs: { name: "pld", value: "3" },
                  on: {
                    change: function ($event) {
                      return _vm.selectBeneficiarioPLD(3)
                    },
                  },
                  model: {
                    value: _vm.constitutiva.beneficiario_pld,
                    callback: function ($$v) {
                      _vm.$set(_vm.constitutiva, "beneficiario_pld", $$v)
                    },
                    expression: "constitutiva.beneficiario_pld",
                  },
                }),
              ],
              1
            ),
            _vm._m(2),
          ]),
          _vm.constitutiva.beneficiario_pld === 3
            ? _c("div", { staticClass: "row mt-4" }, [
                _c(
                  "div",
                  { staticClass: "col-12" },
                  [
                    _c(
                      "b-form-group",
                      {
                        attrs: {
                          id: "input-group-beneficiario",
                          label: "Nombre beneficiario:",
                          "label-for": "input-beneficiario",
                        },
                      },
                      [
                        _c("b-form-input", {
                          attrs: {
                            id: "input-beneficiario",
                            type: "text",
                            placeholder: "Nombre completo",
                          },
                          model: {
                            value: _vm.constitutiva.nombre_beneficiario_pld,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.constitutiva,
                                "nombre_beneficiario_pld",
                                $$v
                              )
                            },
                            expression: "constitutiva.nombre_beneficiario_pld",
                          },
                        }),
                        _c("span", { staticClass: "cardErrors" }, [
                          _vm._v(
                            "Para varios beneficiarios, incluirlos separándolos por comas."
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _c("br"),
          _c("label", { staticClass: "beneficiario" }, [
            _vm._v(
              " Conforme a lo establecido en el Código Fiscal de la Federación, manifiesto, bajo protesta de decir verdad, que: "
            ),
          ]),
          _c(
            "b-button",
            { attrs: { id: `popover-2`, variant: "info", size: "sm" } },
            [_c("i", { staticClass: "fa fa-question" })]
          ),
          _c("b-popover", {
            attrs: {
              target: `popover-2`,
              placement: "left",
              title:
                "Artículo 32-B Quáter CFF. Para efectos de este Código se entenderá por beneficiario controlador a la persona física o grupo de personas físicas que:",
              triggers: "hover focus",
              content: `I. Directamente o por medio de otra u otras o de cualquier acto jurídico, obtiene u obtienen el beneficio derivado de su participación en una persona moral, un fideicomiso o cualquier otra figura jurídica, así como de cualquier otro acto jurídico, o es quien o quienes en última instancia ejerce o ejercen los derechos de uso, goce, disfrute, aprovechamiento o disposición de un bien o servicio o en cuyo nombre se realiza una transacción, aun y cuando lo haga o hagan de forma contingente.
II. Directa, indirectamente o de forma contingente, ejerzan el control de la persona moral, fideicomiso o cualquier otra figura jurídica.
Se entiende que una persona física o grupo de personas físicas ejerce el control cuando, a través de la titularidad de valores, por contrato o por cualquier otro acto jurídico, puede o pueden:
a) Imponer, directa o indirectamente, decisiones en las asambleas generales de accionistas, socios u órganos equivalentes, o nombrar o destituir a la mayoría de los consejeros, administradores o sus equivalentes.
b) Mantener la titularidad de los derechos que permitan, directa o indirectamente, ejercer el voto respecto de más del 15% del capital social o bien.
c) Dirigir, directa o indirectamente, la administración, la estrategia o las principales políticas de la persona moral, fideicomiso o cualquier otra figura jurídica.`,
            },
          }),
          _c(
            "b-button",
            {
              staticStyle: { "margin-left": "10px !important" },
              attrs: { id: `popover-3`, variant: "primary fill", size: "sm" },
            },
            [_vm._v(" Criterios ")]
          ),
          _c("b-popover", {
            attrs: {
              target: `popover-3`,
              placement: "left",
              title:
                "CRITERIOS PARA LA DETERMINACIÓN DE LA CONDICIÓN DE BENEFICIARIO CONTROLADOR DE LAS PERSONAS MORALES",
              triggers: "hover focus",
              content: `Para los efectos del artículo 32-B Ter del CFF, en la identificación de los beneficiarios controladores, se deberá aplicar lo dispuesto en el artículo 32-B Quáter, fracciones I y II, incisos a), b) y c) de forma sucesiva, como criterios para su determinación.
Para efectos de la obtención de la información a que se refiere el artículo 32-B Ter del CFF, las personas morales deberán identificar, verificar y validar la información sobre los beneficiarios controladores, indicando los porcentajes de participación en el capital de la persona moral, incluyendo la información relativa a la cadena de titularidad, en los casos en que el beneficiario controlador lo sea indirectamente. Se entiende por cadena de titularidad el supuesto en que se ostente la propiedad indirecta, a través de otras personas morales. Asimismo, las personas morales también deberán identificar, verificar y validar la información relativa a la cadena de control, en los casos en los que el beneficiario controlador lo sea por medios distintos a la propiedad. Se entiende por cadena de control el supuesto en que se ostente el control indirectamente, a través de otras personas morales, fideicomisos o cualquier otra figura jurídica.
Cuando no se identifique a persona física alguna bajo los criterios establecidos en esta regla, en relación con el artículo 32-B Quáter del CFF, se considerará como beneficiario controlador a la persona física que ocupe el cargo de administrador único de la persona moral o equivalente. En caso de que la persona moral cuente con un consejo de administración u órgano equivalente, cada miembro de dicho consejo se  considerará como beneficiario controlador de la persona moral.`,
            },
          }),
          _c("div", { staticClass: "row mt-2" }, [
            _c(
              "div",
              { staticClass: "col-1" },
              [
                _c("b-form-radio", {
                  attrs: { name: "cff", value: "4" },
                  on: {
                    change: function ($event) {
                      return _vm.selectBeneficiarioCFF(4)
                    },
                  },
                  model: {
                    value: _vm.constitutiva.beneficiario_cff,
                    callback: function ($$v) {
                      _vm.$set(_vm.constitutiva, "beneficiario_cff", $$v)
                    },
                    expression: "constitutiva.beneficiario_cff",
                  },
                }),
              ],
              1
            ),
            _vm._m(3),
          ]),
          _c("div", { staticClass: "row mt-2" }, [
            _c(
              "div",
              { staticClass: "col-1" },
              [
                _c("b-form-radio", {
                  attrs: { name: "cff", value: "6" },
                  on: {
                    change: function ($event) {
                      return _vm.selectBeneficiarioCFF(6)
                    },
                  },
                  model: {
                    value: _vm.constitutiva.beneficiario_cff,
                    callback: function ($$v) {
                      _vm.$set(_vm.constitutiva, "beneficiario_cff", $$v)
                    },
                    expression: "constitutiva.beneficiario_cff",
                  },
                }),
              ],
              1
            ),
            _vm._m(4),
          ]),
          _c("div", { staticClass: "row mt-2" }, [
            _c(
              "div",
              { staticClass: "col-1" },
              [
                _c("b-form-radio", {
                  attrs: { name: "cff", value: "5" },
                  on: {
                    change: function ($event) {
                      return _vm.selectBeneficiarioCFF(5)
                    },
                  },
                  model: {
                    value: _vm.constitutiva.beneficiario_cff,
                    callback: function ($$v) {
                      _vm.$set(_vm.constitutiva, "beneficiario_cff", $$v)
                    },
                    expression: "constitutiva.beneficiario_cff",
                  },
                }),
              ],
              1
            ),
            _vm._m(5),
          ]),
          _vm.constitutiva.beneficiario_cff === 5
            ? _c("div", { staticClass: "row mt-4" }, [
                _c(
                  "div",
                  { staticClass: "col-12" },
                  [
                    _c(
                      "b-form-group",
                      {
                        attrs: {
                          id: "input-group-beneficiario",
                          label: "Nombre beneficiario:",
                          "label-for": "input-beneficiario",
                        },
                      },
                      [
                        _c("b-form-input", {
                          attrs: {
                            id: "input-beneficiario",
                            type: "text",
                            placeholder: "Nombre completo",
                          },
                          model: {
                            value: _vm.constitutiva.nombre_beneficiario_cff,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.constitutiva,
                                "nombre_beneficiario_cff",
                                $$v
                              )
                            },
                            expression: "constitutiva.nombre_beneficiario_cff",
                          },
                        }),
                        _c("span", { staticClass: "cardErrors" }, [
                          _vm._v(
                            "Para varios beneficiarios, incluirlos separándolos por comas."
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
        ],
        1
      ),
      _c("div", { staticClass: "mt-12" }, [
        _c(
          "div",
          {},
          [
            _c(
              "b-button",
              {
                staticClass: "btn-fill orange-btn bottom2",
                attrs: {
                  type: "submit",
                  size: "m",
                  disabled: _vm.constitutiva.disabled,
                },
              },
              [_vm._v("Continuar")]
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-md-11" }, [
      _c("b", [_vm._v("NO")]),
      _vm._v(
        " tengo conocimiento del dueño beneficiario o beneficiario controlador, pues actúo por mi propio derecho. "
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-md-11" }, [
      _c("b", [_vm._v("NO")]),
      _vm._v(
        " tengo conocimiento del dueño beneficiario o beneficiario controlador,ya que actué por cuenta de mi representada, por lo que son sus accionistas quienes se benefician de las consecuencias jurídicas del otorgamiento del instrumento público correspondiente. "
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-md-11" }, [
      _c("b", [_vm._v("SI")]),
      _vm._v(
        " tengo conocimiento del dueño beneficiario o beneficiario controlador. "
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-md-11" }, [
      _vm._v(" Actúo por mi propio derecho, por lo que "),
      _c("b", [_vm._v("NO")]),
      _vm._v(" hay beneficiario controlador distinto al suscrito. "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-md-11" }, [
      _c("b", [_vm._v("NO")]),
      _vm._v(
        " tengo conocimiento del beneficiario controlador bajo los criterios establecidos en la regla 2.8.1.20. de la RMF. No obstante lo anterior, tengo conocimiento de la forma en la que está compuesta la administración de mi representada, por lo que haré entrega de la información y documentación que me sea solicitada. "
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-md-11" }, [
      _c("b", [_vm._v("SI")]),
      _vm._v(
        " tengo conocimiento del o los beneficiarios controladores, por lo que haré entrega de la información y documentación que me sea solicitada. "
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }