import { render, staticRenderFns } from "./Staff.vue?vue&type=template&id=4854d8c0"
import script from "./Staff.vue?vue&type=script&lang=js"
export * from "./Staff.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/github/workspace/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4854d8c0')) {
      api.createRecord('4854d8c0', component.options)
    } else {
      api.reload('4854d8c0', component.options)
    }
    module.hot.accept("./Staff.vue?vue&type=template&id=4854d8c0", function () {
      api.rerender('4854d8c0', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/staff/users/Staff.vue"
export default component.exports