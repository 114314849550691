import { render, staticRenderFns } from "./AddCorreduria.vue?vue&type=template&id=f741e3a8&scoped=true"
import script from "./AddCorreduria.vue?vue&type=script&lang=js"
export * from "./AddCorreduria.vue?vue&type=script&lang=js"
import style0 from "./AddCorreduria.vue?vue&type=style&index=0&id=f741e3a8&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f741e3a8",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/github/workspace/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('f741e3a8')) {
      api.createRecord('f741e3a8', component.options)
    } else {
      api.reload('f741e3a8', component.options)
    }
    module.hot.accept("./AddCorreduria.vue?vue&type=template&id=f741e3a8&scoped=true", function () {
      api.rerender('f741e3a8', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/staff/corredurias/AddCorreduria.vue"
export default component.exports