var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-md-6" },
        [
          _c("base-input", {
            attrs: {
              type: "text",
              label: "Facebook",
              placeholder: "Facebook",
              disabled: _vm.user.disabled,
            },
            model: {
              value: _vm.user.facebook,
              callback: function ($$v) {
                _vm.$set(_vm.user, "facebook", $$v)
              },
              expression: "user.facebook",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-md-6" },
        [
          _c("base-input", {
            attrs: {
              type: "text",
              label: "Twitter",
              placeholder: "Twitter",
              disabled: _vm.user.disabled,
            },
            model: {
              value: _vm.user.twitter,
              callback: function ($$v) {
                _vm.$set(_vm.user, "twitter", $$v)
              },
              expression: "user.twitter",
            },
          }),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-md-6" },
        [
          _c("base-input", {
            attrs: {
              type: "text",
              label: "Instagram",
              placeholder: "Instagram",
              disabled: _vm.user.disabled,
            },
            model: {
              value: _vm.user.instagram,
              callback: function ($$v) {
                _vm.$set(_vm.user, "instagram", $$v)
              },
              expression: "user.instagram",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-md-6" },
        [
          _c("base-input", {
            attrs: {
              type: "text",
              label: "LinkedIn",
              placeholder: "LinkedIn",
              disabled: _vm.user.disabled,
            },
            model: {
              value: _vm.user.linkedin,
              callback: function ($$v) {
                _vm.$set(_vm.user, "linkedin", $$v)
              },
              expression: "user.linkedin",
            },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }