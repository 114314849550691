var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content" },
    [
      _c(
        "b-breadcrumb",
        [
          _c("b-breadcrumb-item", { attrs: { active: "" } }, [
            _vm._v("Corredurías"),
          ]),
        ],
        1
      ),
      _c("div", { staticClass: "container-fluid" }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-6" },
            [
              _c("base-input", {
                attrs: { placeholder: "Buscar Correduría" },
                model: {
                  value: _vm.searchCorreduria,
                  callback: function ($$v) {
                    _vm.searchCorreduria = $$v
                  },
                  expression: "searchCorreduria",
                },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "col-1" }),
          _c(
            "div",
            { staticClass: "col-5" },
            [
              _c(
                "router-link",
                {
                  attrs: {
                    to: { path: "/dashboard/corredurias/addcorreduria/" },
                  },
                },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "btn-fill float-right orange-btn",
                      attrs: { type: "submit" },
                    },
                    [_vm._v(" Nueva Correduría ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-12" },
            [
              _c(
                "card",
                {
                  staticClass: "strpied-tabled-with-hover",
                  attrs: {
                    "body-classes": "table-full-width table-responsive",
                  },
                },
                [
                  _c(
                    "b-modal",
                    {
                      ref: "modal-confirm-dialog",
                      attrs: {
                        "no-close-on-backdrop": "",
                        centered: "",
                        "hide-footer": "",
                        id: "modal-confirm-dialog",
                      },
                    },
                    [
                      _c("h4", { staticClass: "text-center" }, [
                        _vm._v(
                          " ¿Seguro que quiere eliminar a la correduría? "
                        ),
                      ]),
                      _c("h5", { staticClass: "text-center" }, [
                        _vm._v("Se eliminarán todos los datos."),
                      ]),
                      _c(
                        "b-button",
                        {
                          staticClass: "mt-3",
                          attrs: { variant: "danger" },
                          on: {
                            click: function ($event) {
                              return _vm.$bvModal.hide("modal-confirm-dialog")
                            },
                          },
                        },
                        [_vm._v("Cancelar")]
                      ),
                      _c(
                        "b-button",
                        {
                          staticClass: "mt-3 float-right",
                          attrs: { variant: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.deleteCorreduria()
                            },
                          },
                        },
                        [_vm._v("OK")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "text-center" },
                    [
                      _c("b-table", {
                        attrs: {
                          fields: _vm.fields,
                          items: _vm.corredurias,
                          hover: "",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "cell(index)",
                            fn: function ({ index }) {
                              return [
                                _vm._v(
                                  " " + _vm._s(_vm.getIndex(index + 1)) + " "
                                ),
                              ]
                            },
                          },
                          {
                            key: "cell(acciones)",
                            fn: function ({ item }) {
                              return [
                                _c(
                                  "router-link",
                                  {
                                    attrs: {
                                      to: {
                                        path:
                                          "/dashboard/corredurias/editcorreduria/" +
                                          item.id,
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "b-button",
                                      {
                                        directives: [
                                          {
                                            name: "b-tooltip",
                                            rawName: "v-b-tooltip.hover",
                                            modifiers: { hover: true },
                                          },
                                        ],
                                        staticClass: "editBtn",
                                        attrs: {
                                          variant: "warning",
                                          size: "sm",
                                          title: "Editar",
                                        },
                                      },
                                      [_c("b-icon-pencil")],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-button",
                                  {
                                    directives: [
                                      {
                                        name: "b-tooltip",
                                        rawName: "v-b-tooltip.hover",
                                        modifiers: { hover: true },
                                      },
                                    ],
                                    attrs: {
                                      variant: "danger",
                                      size: "sm",
                                      title: "Eliminar",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.predeleteCorreduria(item.id)
                                      },
                                    },
                                  },
                                  [_c("b-icon-trash")],
                                  1
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "d-flex justify-content-between pages" },
                    [
                      _c("b-pagination", {
                        attrs: {
                          "per-page": _vm.perPage,
                          "total-rows": _vm.totalCorredurias,
                        },
                        model: {
                          value: _vm.currentPage,
                          callback: function ($$v) {
                            _vm.currentPage = $$v
                          },
                          expression: "currentPage",
                        },
                      }),
                      _c("span", [
                        _vm._v(
                          "Mostrando " +
                            _vm._s(_vm.corredurias.length) +
                            " registros de " +
                            _vm._s(_vm.totalCorredurias)
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }