var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c("div", { staticClass: "container" }, [
        _vm.loading
          ? _c("div", [
              _c(
                "div",
                { attrs: { id: "loader" } },
                [
                  _c("font-awesome-icon", {
                    attrs: { icon: "spinner", spin: "" },
                  }),
                ],
                1
              ),
            ])
          : _vm._e(),
      ]),
      _c(
        "b-modal",
        {
          ref: "documento-modal-preview-img",
          attrs: {
            "no-close-on-backdrop": "",
            centered: "",
            title: "",
            "hide-footer": "",
            id: "modaldi",
            size: "xl",
          },
        },
        [
          _c("ModalPreviewImg", {
            attrs: { currentDocumento: _vm.currentDocumento },
          }),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          ref: "documento-modal-preview-pdf",
          attrs: {
            "no-close-on-backdrop": "",
            centered: "",
            title: "",
            "hide-footer": "",
            id: "modaldf",
            size: "xl",
          },
        },
        [
          _c("ModalPreviewPDF", {
            attrs: { currentDocumento: _vm.currentDocumento },
          }),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          ref: "documento-modal-preview-video",
          attrs: {
            "no-close-on-backdrop": "",
            centered: "",
            title: "",
            "hide-footer": "",
            id: "modalvideo",
            size: "xl",
          },
        },
        [
          _c("ModalPreviewVideo", {
            attrs: { currentDocumento: _vm.currentDocumento },
          }),
        ],
        1
      ),
      _c("h4", { staticClass: "cardD" }, [_vm._v("Expediente")]),
      _c(
        "div",
        [
          _c("b-table", {
            ref: "table",
            attrs: {
              hover: "",
              fields: _vm.fieldsDocumentos,
              items: _vm.notificacion.documentos,
            },
            scopedSlots: _vm._u([
              {
                key: "cell(tipo)",
                fn: function ({ item }) {
                  return [
                    item.tipo === "Otro"
                      ? _c("div", [
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "b-tooltip",
                                  rawName: "v-b-tooltip.hover",
                                  modifiers: { hover: true },
                                },
                              ],
                              attrs: { title: item.descripcion },
                            },
                            [_vm._v(" " + _vm._s(item.tipo) + " ")]
                          ),
                        ])
                      : _c("div", [_vm._v(" " + _vm._s(item.tipo) + " ")]),
                  ]
                },
              },
              {
                key: "cell(acciones)",
                fn: function ({ item }) {
                  return [
                    _c(
                      "b-button",
                      {
                        directives: [
                          {
                            name: "b-tooltip",
                            rawName: "v-b-tooltip.hover",
                            modifiers: { hover: true },
                          },
                        ],
                        staticClass: "editBtn",
                        attrs: {
                          variant: "primary",
                          size: "sm",
                          title: "Vista previa",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.previewDocumento(item)
                          },
                        },
                      },
                      [_c("b-icon-eye")],
                      1
                    ),
                    _c(
                      "b-button",
                      {
                        directives: [
                          {
                            name: "b-tooltip",
                            rawName: "v-b-tooltip.hover",
                            modifiers: { hover: true },
                          },
                        ],
                        staticClass: "editBtn",
                        attrs: {
                          variant: "primary",
                          size: "sm",
                          title: "Descargar",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.descargarDocumentoHandler(item)
                          },
                        },
                      },
                      [_c("b-icon-download")],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("div", { staticClass: "mt-4" }, [
        _c(
          "div",
          [
            _c(
              "b-button",
              {
                staticClass: "btn-fill green-btn bottom1",
                attrs: { size: "m" },
                on: { click: _vm.descargarExpedienteHandler },
              },
              [_vm._v(" Descargar Expediente ")]
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }