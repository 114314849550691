var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.user === "Client"
        ? _c("div", [_vm._m(0), _vm._m(1), _vm._m(2), _vm._m(3), _vm._m(4)])
        : _vm._e(),
      _vm.user === "Staff"
        ? [
            _c("div", [
              _c("div", [
                _vm._m(5),
                _c(
                  "div",
                  {
                    staticClass:
                      "row flex align-items-center p-2 pointer justify-content-between",
                  },
                  [
                    _c("div", { staticClass: "ml-3" }, [
                      _c(
                        "a",
                        {
                          class:
                            _vm.selectedComponent === "Ubicacion"
                              ? "active-menu"
                              : "text-menu",
                          on: {
                            click: function ($event) {
                              return _vm.selected("Ubicacion")
                            },
                          },
                        },
                        [_vm._v(" Ubicación ")]
                      ),
                    ]),
                    _c("div", { staticClass: "mr-3" }, [
                      _c(
                        "div",
                        {
                          class: [
                            "circle",
                            _vm.notificacion.validaDomicilio
                              ? "completado"
                              : "",
                          ],
                        },
                        [
                          _c("span", { staticClass: "number" }, [
                            _vm.notificacion.validaDomicilio
                              ? _c("i", { staticClass: "fa fa-check" })
                              : _c("i", { staticClass: "fa fa-minus" }),
                          ]),
                        ]
                      ),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "row flex align-items-center p-2 pointer justify-content-between",
                  },
                  [
                    _c("div", { staticClass: "ml-3" }, [
                      _c(
                        "a",
                        {
                          class:
                            _vm.selectedComponent === "Solicitantes"
                              ? "active-menu"
                              : "text-menu",
                          on: {
                            click: function ($event) {
                              return _vm.selected("Solicitantes")
                            },
                          },
                        },
                        [_vm._v(" Solicitantes ")]
                      ),
                    ]),
                    _c("div", { staticClass: "mr-3" }, [
                      _c(
                        "div",
                        {
                          class: [
                            "circle",
                            _vm.notificacion.validaSolicitantes
                              ? "completado"
                              : "",
                          ],
                        },
                        [
                          _c("span", { staticClass: "number" }, [
                            _vm.notificacion.validaSolicitantes
                              ? _c("i", { staticClass: "fa fa-check" })
                              : _c("i", { staticClass: "fa fa-minus" }),
                          ]),
                        ]
                      ),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "row flex align-items-center p-2 pointer justify-content-between",
                  },
                  [
                    _c("div", { staticClass: "ml-3" }, [
                      _c(
                        "a",
                        {
                          class:
                            _vm.selectedComponent === "Notificados"
                              ? "active-menu"
                              : "text-menu",
                          on: {
                            click: function ($event) {
                              return _vm.selected("Notificados")
                            },
                          },
                        },
                        [_vm._v(" Notificados ")]
                      ),
                    ]),
                    _c("div", { staticClass: "mr-3" }, [
                      _c(
                        "div",
                        {
                          class: [
                            "circle",
                            _vm.notificacion.validaNotificado
                              ? "completado"
                              : "",
                          ],
                        },
                        [
                          _c("span", { staticClass: "number" }, [
                            _vm.notificacion.validaNotificado
                              ? _c("i", { staticClass: "fa fa-check" })
                              : _c("i", { staticClass: "fa fa-minus" }),
                          ]),
                        ]
                      ),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "row flex align-items-center p-2 pointer justify-content-between",
                  },
                  [
                    _c("div", { staticClass: "ml-3" }, [
                      _c(
                        "a",
                        {
                          class:
                            _vm.selectedComponent === "Documentos"
                              ? "active-menu"
                              : "text-menu",
                          on: {
                            click: function ($event) {
                              return _vm.selected("Documentos")
                            },
                          },
                        },
                        [_vm._v(" Documentos ")]
                      ),
                    ]),
                    _c("div", { staticClass: "mr-3" }, [
                      _c(
                        "div",
                        {
                          class: [
                            "circle",
                            _vm.notificacion.validaDocumentos
                              ? "completado"
                              : "",
                          ],
                        },
                        [
                          _c("span", { staticClass: "number" }, [
                            _vm.notificacion.validaDocumentos
                              ? _c("i", { staticClass: "fa fa-check" })
                              : _c("i", { staticClass: "fa fa-minus" }),
                          ]),
                        ]
                      ),
                    ]),
                  ]
                ),
                _vm.notificacion.envio
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "row flex align-items-center p-2 pointer justify-content-between",
                      },
                      [
                        _c("div", { staticClass: "ml-3" }, [
                          _c(
                            "a",
                            {
                              class:
                                _vm.selectedComponent === "EnvioDomicilio"
                                  ? "active-menu"
                                  : "text-menu",
                              on: {
                                click: function ($event) {
                                  return _vm.selected("EnvioDomicilio")
                                },
                              },
                            },
                            [_vm._v(" Domicilio de Envio ")]
                          ),
                        ]),
                        _c("div", { staticClass: "mr-3" }, [
                          _c(
                            "div",
                            {
                              class: [
                                "circle",
                                _vm.notificacion.validaEnvio
                                  ? "completado"
                                  : "",
                              ],
                            },
                            [
                              _c("span", { staticClass: "number" }, [
                                _vm.notificacion.validaEnvio
                                  ? _c("i", { staticClass: "fa fa-check" })
                                  : _c("i", { staticClass: "fa fa-minus" }),
                              ]),
                            ]
                          ),
                        ]),
                      ]
                    )
                  : _vm._e(),
              ]),
            ]),
            _c(
              "div",
              {
                staticClass:
                  "row flex align-items-center p-2 pointer justify-content-between",
              },
              [
                _c("div", { staticClass: "ml-3" }, [
                  _c(
                    "a",
                    {
                      class:
                        _vm.selectedComponent === "Correduria"
                          ? "active-menu"
                          : "text-menu",
                      on: {
                        click: function ($event) {
                          return _vm.selected("Correduria")
                        },
                      },
                    },
                    [_vm._v(" Asignar Correduria ")]
                  ),
                ]),
                _c("div", { staticClass: "mr-3" }, [
                  _c(
                    "div",
                    {
                      class: [
                        "circle",
                        _vm.notificacion.corredurias_id ? "completado" : "",
                      ],
                    },
                    [
                      _c("span", { staticClass: "number" }, [
                        _vm.notificacion.corredurias_id
                          ? _c("i", { staticClass: "fa fa-check" })
                          : _c("i", { staticClass: "fa fa-minus" }),
                      ]),
                    ]
                  ),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass:
                  "row flex align-items-center p-2 pointer justify-content-between",
              },
              [
                _c("div", { staticClass: "ml-3" }, [
                  _c(
                    "a",
                    {
                      class:
                        _vm.selectedComponent === "Fad"
                          ? "active-menu"
                          : "text-menu",
                      on: {
                        click: function ($event) {
                          return _vm.selected("Fad")
                        },
                      },
                    },
                    [_vm._v(" FAD ")]
                  ),
                ]),
                _c("div", { staticClass: "mr-3" }, [
                  _c(
                    "div",
                    {
                      class: [
                        "circle",
                        [11, 12, 23].includes(_vm.notificacion.id_estatus)
                          ? "completado"
                          : "",
                      ],
                    },
                    [
                      _c("span", { staticClass: "number" }, [
                        [11, 12, 23].includes(_vm.notificacion.id_estatus)
                          ? _c("i", { staticClass: "fa fa-check" })
                          : _c("i", { staticClass: "fa fa-minus" }),
                      ]),
                    ]
                  ),
                ]),
              ]
            ),
            _vm._m(6),
            _c(
              "div",
              {
                staticClass:
                  "row flex align-items-center p-2 pointer justify-content-between",
              },
              [
                _c("div", { staticClass: "ml-3" }, [
                  _c(
                    "a",
                    {
                      staticClass: "f12",
                      class:
                        _vm.selectedComponent === "Diligencia"
                          ? "active-menu"
                          : "text-menu",
                      on: {
                        click: function ($event) {
                          return _vm.selected("Diligencia")
                        },
                      },
                    },
                    [_vm._v(" Gestión de Diligencia ")]
                  ),
                ]),
                _c("div", { staticClass: "mr-3" }, [
                  _c(
                    "div",
                    {
                      class: [
                        "circle",
                        [12, 13, 23, 58].includes(_vm.notificacion.id_estatus)
                          ? "completado"
                          : "",
                      ],
                    },
                    [
                      _c("span", { staticClass: "number" }, [
                        [12, 13, 23, 58].includes(_vm.notificacion.id_estatus)
                          ? _c("i", { staticClass: "fa fa-check" })
                          : _c("i", { staticClass: "fa fa-minus" }),
                      ]),
                    ]
                  ),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass:
                  "row flex align-items-center p-2 pointer justify-content-between",
              },
              [
                _c("div", { staticClass: "ml-3" }, [
                  _c(
                    "a",
                    {
                      class:
                        _vm.selectedComponent === "Actas"
                          ? "active-menu"
                          : "text-menu",
                      on: {
                        click: function ($event) {
                          return _vm.selected("Actas")
                        },
                      },
                    },
                    [_vm._v(" Actas ")]
                  ),
                ]),
                _c("div", { staticClass: "mr-3" }, [
                  _c(
                    "div",
                    {
                      class: [
                        "circle",
                        _vm.notificacion.id_estatus == 12 ||
                        _vm.notificacion.id_estatus == 13
                          ? "completado"
                          : "",
                      ],
                    },
                    [
                      _c("span", { staticClass: "number" }, [
                        _vm.notificacion.id_estatus == 12 ||
                        _vm.notificacion.id_estatus == 13
                          ? _c("i", { staticClass: "fa fa-check" })
                          : _c("i", { staticClass: "fa fa-minus" }),
                      ]),
                    ]
                  ),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass:
                  "row flex align-items-center p-2 pointer justify-content-between",
              },
              [
                _c("div", { staticClass: "ml-3" }, [
                  _c(
                    "a",
                    {
                      class:
                        _vm.selectedComponent === "Expediente"
                          ? "active-menu"
                          : "text-menu",
                      on: {
                        click: function ($event) {
                          return _vm.selected("Expediente")
                        },
                      },
                    },
                    [_vm._v(" Expediente ")]
                  ),
                ]),
                _c("div", { staticClass: "mr-3" }, [
                  _c(
                    "div",
                    {
                      class: [
                        "circle",
                        _vm.notificacion.id_estatus == 13 ? "completado" : "",
                      ],
                    },
                    [
                      _c("span", { staticClass: "number" }, [
                        _vm.notificacion.id_estatus == 13
                          ? _c("i", { staticClass: "fa fa-check" })
                          : _c("i", { staticClass: "fa fa-minus" }),
                      ]),
                    ]
                  ),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass:
                  "row flex align-items-center p-2 pointer justify-content-between",
              },
              [
                _c("div", { staticClass: "ml-3" }, [
                  _c(
                    "a",
                    {
                      class:
                        _vm.selectedComponent === "Terminado"
                          ? "active-menu"
                          : "text-menu",
                      on: {
                        click: function ($event) {
                          return _vm.selected("Terminado")
                        },
                      },
                    },
                    [_vm._v(" Terminado ")]
                  ),
                ]),
                _c("div", { staticClass: "mr-3" }, [
                  _c(
                    "div",
                    {
                      class: [
                        "circle",
                        _vm.notificacion.id_estatus == 13 ? "completado" : "",
                      ],
                    },
                    [
                      _c("span", { staticClass: "number" }, [
                        _vm.notificacion.id_estatus == 13
                          ? _c("i", { staticClass: "fa fa-check" })
                          : _c("i", { staticClass: "fa fa-minus" }),
                      ]),
                    ]
                  ),
                ]),
              ]
            ),
          ]
        : [
            _c("div", [
              _c(
                "div",
                { staticClass: "row flex align-items-center p-2 pointer" },
                [
                  _c("div", { staticClass: "ml-3" }, [
                    _c(
                      "div",
                      {
                        class: [
                          "circle",
                          _vm.notificacion.currentComponentValues.Pago
                            ? "completado"
                            : "",
                        ],
                      },
                      [
                        _c("span", { staticClass: "number" }, [
                          _vm.notificacion.currentComponentValues.Pago
                            ? _c("i", { staticClass: "fa fa-check" })
                            : _c("i", { staticClass: "fa fa-minus" }),
                        ]),
                      ]
                    ),
                  ]),
                  _c("div", { staticClass: "ml-3" }, [
                    _c(
                      "a",
                      {
                        class:
                          _vm.selectedComponent === "Pago"
                            ? "active-menu"
                            : "text-menu",
                        on: {
                          click: function ($event) {
                            return _vm.selected("Pago")
                          },
                        },
                      },
                      [_vm._v(" Pago ")]
                    ),
                  ]),
                ]
              ),
              _vm._m(7),
              _vm.notificacion.envio == 1
                ? _c(
                    "div",
                    { staticClass: "row flex align-items-center p-2 pointer" },
                    [
                      _c("div", { staticClass: "ml-3" }, [
                        _c(
                          "div",
                          {
                            class: [
                              "circle",
                              _vm.notificacion.currentComponentValues
                                .EnvioDomicilio
                                ? "completado"
                                : "",
                            ],
                          },
                          [
                            _c("span", { staticClass: "number" }, [
                              _vm.notificacion.currentComponentValues
                                .EnvioDomicilio
                                ? _c("i", { staticClass: "fa fa-check" })
                                : _c("i", { staticClass: "fa fa-minus" }),
                            ]),
                          ]
                        ),
                      ]),
                      _c("div", { staticClass: "ml-3" }, [
                        _c(
                          "a",
                          {
                            class:
                              _vm.selectedComponent === "EnvioDomicilio"
                                ? "active-menu"
                                : "text-menu",
                            on: {
                              click: function ($event) {
                                return _vm.selected("EnvioDomicilio")
                              },
                            },
                          },
                          [_vm._v(" Envío a Domicilio ")]
                        ),
                      ]),
                    ]
                  )
                : _vm._e(),
              _c(
                "div",
                { staticClass: "row flex align-items-center p-2 pointer" },
                [
                  _c("div", { staticClass: "ml-3" }, [
                    _c(
                      "div",
                      {
                        class: [
                          "circle",
                          _vm.notificacion.currentComponentValues
                            .ComplementaNotificado
                            ? "completado"
                            : "",
                        ],
                      },
                      [
                        _c("span", { staticClass: "number" }, [
                          _vm.notificacion.currentComponentValues
                            .ComplementaNotificado
                            ? _c("i", { staticClass: "fa fa-check" })
                            : _c("i", { staticClass: "fa fa-minus" }),
                        ]),
                      ]
                    ),
                  ]),
                  _c("div", { staticClass: "ml-3" }, [
                    _c(
                      "a",
                      {
                        class:
                          _vm.selectedComponent === "ComplementaNotificado"
                            ? "active-menu"
                            : "text-menu",
                        on: {
                          click: function ($event) {
                            return _vm.selected("ComplementaNotificado")
                          },
                        },
                      },
                      [_vm._v(" Notificado")]
                    ),
                  ]),
                ]
              ),
              _c(
                "div",
                { staticClass: "row flex align-items-center p-2 pointer" },
                [
                  _c("div", { staticClass: "ml-3" }, [
                    _c(
                      "div",
                      {
                        class: [
                          "circle",
                          _vm.notificacion.currentComponentValues
                            .ComplementaSolicitantes
                            ? "completado"
                            : "",
                        ],
                      },
                      [
                        _c("span", { staticClass: "number" }, [
                          _vm.notificacion.currentComponentValues
                            .ComplementaSolicitantes
                            ? _c("i", { staticClass: "fa fa-check" })
                            : _c("i", { staticClass: "fa fa-minus" }),
                        ]),
                      ]
                    ),
                  ]),
                  _c("div", { staticClass: "ml-3" }, [
                    _c(
                      "a",
                      {
                        class:
                          _vm.selectedComponent === "ComplementaSolicitantes"
                            ? "active-menu"
                            : "text-menu",
                        on: {
                          click: function ($event) {
                            return _vm.selected("ComplementaSolicitantes")
                          },
                        },
                      },
                      [_vm._v(" Solicitantes")]
                    ),
                  ]),
                ]
              ),
              _c(
                "div",
                { staticClass: "row flex align-items-center p-2 pointer" },
                [
                  _c("div", { staticClass: "ml-3" }, [
                    _c(
                      "div",
                      {
                        class: [
                          "circle",
                          _vm.notificacion.currentComponentValues
                            .EntregaDocumentos
                            ? "completado"
                            : "",
                        ],
                      },
                      [
                        _c("span", { staticClass: "number" }, [
                          _vm.notificacion.currentComponentValues
                            .EntregaDocumentos
                            ? _c("i", { staticClass: "fa fa-check" })
                            : _c("i", { staticClass: "fa fa-minus" }),
                        ]),
                      ]
                    ),
                  ]),
                  _c("div", { staticClass: "ml-3" }, [
                    _c(
                      "a",
                      {
                        class:
                          _vm.selectedComponent === "EntregaDocumentos"
                            ? "active-menu"
                            : "text-menu",
                        on: {
                          click: function ($event) {
                            return _vm.selected("EntregaDocumentos")
                          },
                        },
                      },
                      [_vm._v(" Entrega de documentos ")]
                    ),
                  ]),
                ]
              ),
            ]),
          ],
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12 text-center mb-3" }, [
        _c("label", [_vm._v(" Entrega de información base ")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "row flex align-items-center p-2 pointer" },
      [
        _c("div", { staticClass: "ml-3" }, [
          _c("div", { staticClass: "circle completado" }, [
            _c("span", { staticClass: "number" }, [
              _c("i", { staticClass: "fa fa-check" }),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "ml-3" }, [
          _c("span", { staticClass: "text-menu" }, [_vm._v(" Solicitantes ")]),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "row flex align-items-center p-2 pointer" },
      [
        _c("div", { staticClass: "ml-3" }, [
          _c("div", { staticClass: "circle completado" }, [
            _c("span", { staticClass: "number" }, [
              _c("i", { staticClass: "fa fa-check" }),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "ml-3" }, [
          _c("span", { staticClass: "text-menu" }, [_vm._v(" Ubicación ")]),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "row flex align-items-center p-2 pointer" },
      [
        _c("div", { staticClass: "ml-3" }, [
          _c("div", { staticClass: "circle completado" }, [
            _c("span", { staticClass: "number" }, [
              _c("i", { staticClass: "fa fa-check" }),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "ml-3" }, [
          _c("span", { staticClass: "text-menu" }, [_vm._v(" Documentos ")]),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "row flex align-items-center p-2 pointer" },
      [
        _c("div", { staticClass: "ml-3" }, [
          _c("div", { staticClass: "circle completado" }, [
            _c("span", { staticClass: "number" }, [
              _c("i", { staticClass: "fa fa-check" }),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "ml-3" }, [
          _c("span", { staticClass: "text-menu" }, [_vm._v(" Cotización ")]),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12 text-center mb-3" }, [
        _c("label", [_vm._v("Valida la información")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-12 text-center mb-3" }, [
      _c("label", [_vm._v("Diligencia")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "row flex align-items-center p-2 justify-content-center" },
      [
        _c("div", { staticClass: "text-center" }, [
          _c("label", [_vm._v("Complementa la información")]),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }