var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.currentDomicilio.compareciente
      ? _c("h4", { staticClass: "cardD" }, [
          _vm._v(
            " Es necesario registrar un domicilio en México para correspondencia "
          ),
        ])
      : _vm._e(),
    _vm.currentDomicilio.notificaciones
      ? _c("h4", { staticClass: "cardD" }, [
          _vm._v(" Verificar disponibilidad de Notificado "),
        ])
      : _vm._e(),
    _vm.currentDomicilio.cotejo || _vm.currentDomicilio.ratificacion
      ? _c("h4", { staticClass: "cardD" }, [
          _vm._v(" Domicilio de envío de los documentos. "),
        ])
      : _vm._e(),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12" }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-md-6" },
            [
              _c("ValidationProvider", {
                attrs: {
                  name: "calle",
                  rules: "required",
                  "custom-messages": { required: "La calle es obligatoria" },
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ errors }) {
                      return [
                        _c("base-input", {
                          attrs: {
                            type: "text",
                            label: "Calle / Avenida / Vía",
                            placeholder: "Calle",
                          },
                          model: {
                            value: _vm.currentDomicilio.calle,
                            callback: function ($$v) {
                              _vm.$set(_vm.currentDomicilio, "calle", $$v)
                            },
                            expression: "currentDomicilio.calle",
                          },
                        }),
                        _c("span", { staticClass: "cardErrors" }, [
                          _vm._v(_vm._s(errors[0])),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-md-3" },
            [
              _c("ValidationProvider", {
                attrs: {
                  name: "num_exterior",
                  rules: "required",
                  "custom-messages": {
                    required: "El número exterior es obligatorio",
                  },
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ errors }) {
                      return [
                        _c("base-input", {
                          attrs: {
                            type: "text",
                            label: "Número exterior",
                            placeholder: "Número exterior",
                          },
                          model: {
                            value: _vm.currentDomicilio.num_exterior,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.currentDomicilio,
                                "num_exterior",
                                $$v
                              )
                            },
                            expression: "currentDomicilio.num_exterior",
                          },
                        }),
                        _c("span", { staticClass: "cardErrors" }, [
                          _vm._v(_vm._s(errors[0])),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-md-3" },
            [
              _c("base-input", {
                attrs: {
                  type: "text",
                  label: "Número interior",
                  placeholder: "Número interior",
                },
                model: {
                  value: _vm.currentDomicilio.num_interior,
                  callback: function ($$v) {
                    _vm.$set(_vm.currentDomicilio, "num_interior", $$v)
                  },
                  expression: "currentDomicilio.num_interior",
                },
              }),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-md-6" },
            [
              _c("ValidationProvider", {
                attrs: {
                  name: "colonia",
                  rules: "required",
                  "custom-messages": {
                    required: "La colonia es obligatoria",
                  },
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ errors }) {
                      return [
                        _c("base-input", {
                          attrs: {
                            type: "text",
                            label: "Colonia o Urbanización",
                            placeholder: "Colonia o Urbanización",
                          },
                          model: {
                            value: _vm.currentDomicilio.colonia,
                            callback: function ($$v) {
                              _vm.$set(_vm.currentDomicilio, "colonia", $$v)
                            },
                            expression: "currentDomicilio.colonia",
                          },
                        }),
                        _c("span", { staticClass: "cardErrors" }, [
                          _vm._v(_vm._s(errors[0])),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-md-6" },
            [
              _c("ValidationProvider", {
                attrs: {
                  name: "codigo_postal",
                  rules: "required|numeric",
                  "custom-messages": {
                    required: "El código postal es obligatorio",
                    numeric: "El código postal debe ser un número",
                  },
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ errors }) {
                      return [
                        _c("base-input", {
                          attrs: {
                            type: "text",
                            label: "Código Postal",
                            placeholder: "Código Postal",
                          },
                          model: {
                            value: _vm.currentDomicilio.codigo_postal,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.currentDomicilio,
                                "codigo_postal",
                                $$v
                              )
                            },
                            expression: "currentDomicilio.codigo_postal",
                          },
                        }),
                        _c("span", { staticClass: "cardErrors" }, [
                          _vm._v(_vm._s(errors[0])),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-md-4" },
            [
              _c("ValidationProvider", {
                attrs: {
                  name: "pais",
                  rules: "required",
                  "custom-messages": { required: "El país es obligatorio" },
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ errors }) {
                      return [
                        _c("label", [_vm._v("País")]),
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.selectCountry,
                                expression: "selectCountry",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: { disabled: _vm.disableFields.disablePais },
                            on: {
                              change: [
                                function ($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function (o) {
                                      return o.selected
                                    })
                                    .map(function (o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.selectCountry = $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                },
                                _vm.fetchStatesByCountry,
                              ],
                            },
                          },
                          _vm._l(_vm.countries, function (option) {
                            return _c("option", { key: option.id }, [
                              _vm._v(" " + _vm._s(option.name) + " "),
                            ])
                          }),
                          0
                        ),
                        _c("span", { staticClass: "cardErrors" }, [
                          _vm._v(_vm._s(errors[0])),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-md-4" },
            [
              _c("ValidationProvider", {
                attrs: {
                  name: "entidad",
                  rules: "required",
                  "custom-messages": {
                    required: "La entidad es obligatoria",
                  },
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ errors }) {
                      return [
                        _c("label", [_vm._v("Entidad Federativa")]),
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.selectState,
                                expression: "selectState",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: { disabled: false },
                            on: {
                              change: [
                                function ($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function (o) {
                                      return o.selected
                                    })
                                    .map(function (o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.selectState = $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                },
                                _vm.fetchCitiesByState,
                              ],
                            },
                          },
                          [
                            _vm.isEmptyStates
                              ? _c(
                                  "option",
                                  {
                                    attrs: { value: "noStates", selected: "" },
                                  },
                                  [_vm._v(" " + _vm._s(_vm.noStatesText) + " ")]
                                )
                              : _vm._e(),
                            _vm._l(_vm.states, function (option) {
                              return _c("option", { key: option.id }, [
                                _vm._v(" " + _vm._s(option.name) + " "),
                              ])
                            }),
                          ],
                          2
                        ),
                        _c("span", { staticClass: "cardErrors" }, [
                          _vm._v(_vm._s(errors[0])),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-md-4" },
            [
              _c("ValidationProvider", {
                attrs: {
                  name: "delegacion_municipio",
                  rules: "required",
                  "custom-messages": {
                    required: "La demarcación territorial es obligatoria",
                  },
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ errors }) {
                      return [
                        _c("label", [
                          _vm._v("Demarcación Territorial o Municipio"),
                        ]),
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.selectCity,
                                expression: "selectCity",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: { disabled: false },
                            on: {
                              change: function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.selectCity = $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              },
                            },
                          },
                          [
                            _vm.isEmptyCities
                              ? _c(
                                  "option",
                                  {
                                    attrs: { value: "noCities", selected: "" },
                                  },
                                  [_vm._v(" " + _vm._s(_vm.noCitiesText) + " ")]
                                )
                              : _vm._e(),
                            _vm._l(_vm.cities, function (option) {
                              return _c("option", { key: option.id }, [
                                _vm._v(" " + _vm._s(option.name) + " "),
                              ])
                            }),
                          ],
                          2
                        ),
                        _c("span", { staticClass: "cardErrors" }, [
                          _vm._v(_vm._s(errors[0])),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "row" }, [
          _vm.currentDomicilio.tieneExtranjero
            ? _c(
                "div",
                { staticClass: "col-md-3" },
                [
                  _c("label", { attrs: { for: "" } }, [
                    _vm._v("Domicilio en el extranjero"),
                  ]),
                  _c("b-form-checkbox", {
                    model: {
                      value: _vm.currentDomicilio.extranjero,
                      callback: function ($$v) {
                        _vm.$set(_vm.currentDomicilio, "extranjero", $$v)
                      },
                      expression: "currentDomicilio.extranjero",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.currentDomicilio.tieneTipo
            ? _c(
                "div",
                { staticClass: "col-md-3" },
                [
                  _c("ValidationProvider", {
                    attrs: {
                      name: "tipo",
                      rules: "required",
                      "custom-messages": {
                        required: "El tipo es obligatorio",
                      },
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function ({ errors }) {
                            return [
                              _vm.currentDomicilio.tieneTipo
                                ? _c("label", { attrs: { for: "" } }, [
                                    _vm._v("Tipo"),
                                  ])
                                : _vm._e(),
                              _vm.currentDomicilio.tieneTipo
                                ? _c(
                                    "select",
                                    {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.currentDomicilio.tipo,
                                          expression: "currentDomicilio.tipo",
                                        },
                                      ],
                                      staticClass: "custom-select",
                                      on: {
                                        change: function ($event) {
                                          var $$selectedVal =
                                            Array.prototype.filter
                                              .call(
                                                $event.target.options,
                                                function (o) {
                                                  return o.selected
                                                }
                                              )
                                              .map(function (o) {
                                                var val =
                                                  "_value" in o
                                                    ? o._value
                                                    : o.value
                                                return val
                                              })
                                          _vm.$set(
                                            _vm.currentDomicilio,
                                            "tipo",
                                            $event.target.multiple
                                              ? $$selectedVal
                                              : $$selectedVal[0]
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "option",
                                        {
                                          attrs: {
                                            value: "Particular y Fiscal",
                                          },
                                        },
                                        [_vm._v("Particular y Fiscal")]
                                      ),
                                      _c(
                                        "option",
                                        { attrs: { value: "Particular" } },
                                        [_vm._v("Particular")]
                                      ),
                                      _c(
                                        "option",
                                        { attrs: { value: "Fiscal" } },
                                        [_vm._v("Fiscal")]
                                      ),
                                      _c(
                                        "option",
                                        { attrs: { value: "Extranjero" } },
                                        [_vm._v("Extranjero")]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm.currentDomicilio.tipo === "Personal"
                                ? _c("base-input", {
                                    attrs: {
                                      type: "text",
                                      placeholder: "Tipo",
                                      disabled: "",
                                    },
                                    model: {
                                      value: _vm.currentDomicilio.tipo,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.currentDomicilio,
                                          "tipo",
                                          $$v
                                        )
                                      },
                                      expression: "currentDomicilio.tipo",
                                    },
                                  })
                                : _vm._e(),
                              _c("span", { staticClass: "cardErrors" }, [
                                _vm._v(_vm._s(errors[0])),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2050284671
                    ),
                  }),
                ],
                1
              )
            : _vm._e(),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "col-12 mt-3" },
        [
          _c("span", { staticClass: "warningData" }, [
            _vm._v(
              "Puede colocar el marcador en el mapa indicando el domicilio exacto"
            ),
          ]),
          _c("DrawGoogleMap", {
            attrs: { lat: _vm.latitude, lng: _vm.longitude },
            on: { location: _vm.updateCoordinates },
          }),
        ],
        1
      ),
    ]),
    !_vm.currentDomicilio.staff &&
    (_vm.currentDomicilio.notificaciones ||
      _vm.currentDomicilio.cotejo ||
      _vm.currentDomicilio.ratificacion)
      ? _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-12 mt-3", attrs: { id: "form-checkbox" } },
            [
              _c("ValidationProvider", {
                attrs: {
                  name: "validacionDatos",
                  rules: { required: { allowFalse: false } },
                  "custom-messages": {
                    required: "La confirmación es obligatoria",
                  },
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function ({ errors }) {
                        return [
                          _c(
                            "b-form-checkbox",
                            {
                              staticClass: "orange",
                              attrs: { id: "checkbox-validacionDatos" },
                              model: {
                                value: _vm.validacionDatos,
                                callback: function ($$v) {
                                  _vm.validacionDatos = $$v
                                },
                                expression: "validacionDatos",
                              },
                            },
                            [
                              _vm._v(
                                " Confirmo que los datos proporcionados y la ubicación mostrada en el mapa son correctos "
                              ),
                            ]
                          ),
                          _c("span", { staticClass: "cardErrors" }, [
                            _vm._v(_vm._s(errors[0])),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  1635318242
                ),
              }),
            ],
            1
          ),
        ])
      : _vm._e(),
    !_vm.hideSaveButton
      ? _c(
          "div",
          { staticClass: "text-center" },
          [
            _c(
              "b-button",
              {
                staticClass: "btn btn-fill float-right orange-btn",
                attrs: { type: "submit" },
              },
              [_vm._v("Guardar")]
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }