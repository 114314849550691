var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "wrapper" },
    [
      _c(
        "side-bar",
        [
          _c("mobile-menu", { attrs: { slot: "content" }, slot: "content" }),
          _c("sidebar-link", { attrs: { to: "/dashboard" } }, [
            _c("i", { staticClass: "nc-icon nc-notes" }),
            _c("p", [_vm._v("Procesos")]),
          ]),
          _c("sidebar-link", { attrs: { to: "/dashboard/staffaccount" } }, [
            _c("i", { staticClass: "nc-icon nc-circle-09" }),
            _c("p", [_vm._v("Mi cuenta")]),
          ]),
          _vm.corredurias_id == 1
            ? _c("sidebar-link", { attrs: { to: "/dashboard/clientes" } }, [
                _c("i", { staticClass: "nc-icon nc-settings-tool-66" }),
                _c("p", [_vm._v("Clientes")]),
              ])
            : _vm._e(),
          _c("sidebar-link", { attrs: { to: "/dashboard/staff" } }, [
            _c("i", { staticClass: "nc-icon nc-badge" }),
            _c("p", [_vm._v("Staff")]),
          ]),
          _vm.corredurias_id == 1
            ? _c(
                "sidebar-link",
                { attrs: { to: "/dashboard/comparecientesStf" } },
                [
                  _c("i", { staticClass: "nc-icon nc-grid-45" }),
                  _c("p", [_vm._v("Comparecientes")]),
                ]
              )
            : _vm._e(),
          _vm.corredurias_id == 1
            ? _c("sidebar-link", { attrs: { to: "/dashboard/settings" } }, [
                _c("i", { staticClass: "nc-icon nc-settings-gear-64" }),
                _c("p", [_vm._v("Configuraciones")]),
              ])
            : _vm._e(),
          _vm.corredurias_id == 1
            ? _c("sidebar-link", { attrs: { to: "/dashboard/corredurias" } }, [
                _c("i", { staticClass: "nc-icon nc-paper-2" }),
                _c("p", [_vm._v("Corredurías")]),
              ])
            : _vm._e(),
          _c("hr"),
          _c("div", [
            _c("li", { staticClass: "nav-item" }, [
              _c(
                "a",
                {
                  staticClass: "nav-link",
                  attrs: { href: "/" },
                  on: { click: _vm.logout },
                },
                [_vm._v(" Cerrar sesión ")]
              ),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "main-panel" },
        [
          _c("dashboard-content", { on: { click: _vm.toggleSidebar } }),
          _c("content-footer"),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }