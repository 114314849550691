var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    !_vm.logged
      ? _c("div", [
          _c(
            "ul",
            {
              staticClass: "nav nav-mobile-menu",
              staticStyle: { "padding-left": "0px !important" },
            },
            [
              _c(
                "li",
                { staticClass: "nav-item" },
                [
                  _c(
                    "router-link",
                    {
                      directives: [
                        {
                          name: "scroll-to",
                          rawName: "v-scroll-to",
                          value: "#como_funciona",
                          expression: "'#como_funciona'",
                        },
                      ],
                      staticClass: "nav-link",
                      attrs: { to: "#" },
                    },
                    [_vm._v("¿Cómo funciona? ")]
                  ),
                ],
                1
              ),
              _vm._m(0),
              _c(
                "li",
                { staticClass: "nav-item" },
                [
                  _c(
                    "router-link",
                    {
                      directives: [
                        {
                          name: "scroll-to",
                          rawName: "v-scroll-to",
                          value: "#servicios",
                          expression: "'#servicios'",
                        },
                      ],
                      staticClass: "nav-link",
                      attrs: { to: "#" },
                    },
                    [_vm._v("Servicios ")]
                  ),
                ],
                1
              ),
              _c(
                "li",
                { staticClass: "nav-item" },
                [
                  _c(
                    "router-link",
                    { staticClass: "nav-link", attrs: { to: "/signin" } },
                    [_vm._v("Registro")]
                  ),
                ],
                1
              ),
              _c(
                "li",
                { staticClass: "nav-item" },
                [
                  _c(
                    "router-link",
                    {
                      directives: [
                        {
                          name: "scroll-to",
                          rawName: "v-scroll-to",
                          value: "#contacto",
                          expression: "'#contacto'",
                        },
                      ],
                      staticClass: "nav-link",
                      attrs: { to: "#" },
                    },
                    [_vm._v("Contacto")]
                  ),
                ],
                1
              ),
              _c(
                "li",
                { staticClass: "nav-item" },
                [
                  _c(
                    "router-link",
                    { staticClass: "nav-link", attrs: { to: "/login" } },
                    [_vm._v("Iniciar sesión")]
                  ),
                ],
                1
              ),
            ]
          ),
        ])
      : _vm.logged
      ? _c("div", [
          _c(
            "ul",
            {
              staticClass: "nav nav-mobile-menu",
              staticStyle: { "padding-left": "0px !important" },
            },
            [
              _c("base-dropdown", { attrs: { title: "Servicios" } }, [
                _c(
                  "a",
                  { staticClass: "dropdown-item", attrs: { href: "#" } },
                  [_vm._v("Constitución de sociedades")]
                ),
                _c(
                  "a",
                  { staticClass: "dropdown-item", attrs: { href: "#" } },
                  [_vm._v("Formalización de actas")]
                ),
                _c(
                  "a",
                  { staticClass: "dropdown-item", attrs: { href: "#" } },
                  [_vm._v("Notificaciones")]
                ),
                _c(
                  "a",
                  { staticClass: "dropdown-item", attrs: { href: "#" } },
                  [_vm._v("Copias Certificadas")]
                ),
                _c(
                  "a",
                  { staticClass: "dropdown-item", attrs: { href: "#" } },
                  [_vm._v("Fes de hechos")]
                ),
                _c(
                  "a",
                  { staticClass: "dropdown-item", attrs: { href: "#" } },
                  [_vm._v("Ratificación de firmas")]
                ),
                _c(
                  "a",
                  { staticClass: "dropdown-item", attrs: { href: "#" } },
                  [_vm._v("Avalúos de mejoras")]
                ),
              ]),
              _vm._m(1),
              _c("li", { staticClass: "nav-item" }, [
                _c(
                  "a",
                  {
                    staticClass: "nav-link",
                    attrs: { href: "#" },
                    on: { click: _vm.logout },
                  },
                  [_vm._v(" Log out ")]
                ),
              ]),
            ],
            1
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", { staticClass: "nav-item" }, [
      _c("a", { staticClass: "nav-link", attrs: { href: "#" } }, [
        _vm._v(" Cuenta "),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", { staticClass: "nav-item" }, [
      _c("a", { staticClass: "nav-link", attrs: { href: "#" } }, [
        _vm._v(" Cuenta "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }