var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c(
        "b-modal",
        {
          ref: "entrega-documento-modal",
          attrs: {
            "no-close-on-backdrop": "",
            centered: "",
            title: "",
            "hide-footer": "",
            id: "modalC",
            size: "xl",
          },
        },
        [
          _c(
            "div",
            [
              _c("ValidationObserver", {
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ handleSubmit }) {
                      return [
                        _c(
                          "form",
                          {
                            on: {
                              submit: function ($event) {
                                $event.preventDefault()
                                return handleSubmit(_vm.postDocumento)
                              },
                            },
                          },
                          [
                            _c("ModalEntregaDocumento", {
                              attrs: {
                                currentDocumento: _vm.currentDocumento,
                                ratificacion: _vm.ratificacion,
                              },
                              on: {
                                submit: function ($event) {
                                  $event.preventDefault()
                                },
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ]
      ),
      _c(
        "b-modal",
        {
          ref: "documento-modal-preview-img",
          attrs: {
            "no-close-on-backdrop": "",
            centered: "",
            title: "",
            "hide-footer": "",
            id: "modaldi",
            size: "xl",
          },
        },
        [
          _c("ModalPreviewImg", {
            attrs: { currentDocumento: _vm.currentDocumento },
          }),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          ref: "documento-modal-preview-pdf",
          attrs: {
            "no-close-on-backdrop": "",
            centered: "",
            title: "",
            "hide-footer": "",
            id: "modaldf",
            size: "xl",
          },
        },
        [
          _c("ModalPreviewPDF", {
            attrs: { currentDocumento: _vm.currentDocumento },
          }),
        ],
        1
      ),
      _c("h4", { staticClass: "cardD" }, [_vm._v("Entrega de Documentos")]),
      _c(
        "div",
        [
          _c("b-table", {
            ref: "table",
            attrs: {
              hover: "",
              fields: _vm.fieldsDocumentos,
              items: _vm.documentosToShow,
            },
            scopedSlots: _vm._u([
              {
                key: "cell(tipo)",
                fn: function ({ item }) {
                  return [
                    item.tipo === "Otro"
                      ? _c("div", [
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "b-tooltip",
                                  rawName: "v-b-tooltip.hover",
                                  modifiers: { hover: true },
                                },
                              ],
                              attrs: { title: item.descripcion },
                            },
                            [_vm._v(" " + _vm._s(item.tipo) + " ")]
                          ),
                        ])
                      : _c("div", [_vm._v(" " + _vm._s(item.tipo) + " ")]),
                  ]
                },
              },
              {
                key: "cell(acciones)",
                fn: function ({ item }) {
                  return [
                    (_vm.ratificacion.staff &&
                      _vm.ratificacion.validaDocumentos == 0) ||
                    (!_vm.ratificacion.staff &&
                      _vm.ratificacion.estatus_id == 35)
                      ? _c(
                          "b-button",
                          {
                            directives: [
                              {
                                name: "b-tooltip",
                                rawName: "v-b-tooltip.hover",
                                modifiers: { hover: true },
                              },
                            ],
                            staticClass: "editBtn",
                            attrs: {
                              variant: "warning",
                              size: "sm",
                              title: "Editar",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.openEditDocumentoModal(item)
                              },
                            },
                          },
                          [_c("b-icon-upload")],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "b-button",
                      {
                        directives: [
                          {
                            name: "b-tooltip",
                            rawName: "v-b-tooltip.hover",
                            modifiers: { hover: true },
                          },
                        ],
                        staticClass: "editBtn",
                        attrs: {
                          variant: "primary",
                          size: "sm",
                          title: "Vista previa",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.previewDocumento(item)
                          },
                        },
                      },
                      [_c("b-icon-eye")],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      !_vm.ratificacion.staff
        ? _c("div", { staticClass: "row mt-4" }, [
            _c(
              "div",
              { staticClass: "col-md-12" },
              [
                _c("ValidationProvider", {
                  attrs: {
                    name: "confirmAceptacion",
                    rules: { required: { allowFalse: false } },
                    "custom-messages": {
                      required: "La confirmación es obligatoria",
                    },
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function ({ errors }) {
                          return [
                            _c(
                              "b-form-checkbox",
                              {
                                attrs: {
                                  id: "checkInfoCorrecta",
                                  disabled:
                                    _vm.ratificacion.estatus_id != 35 ||
                                    !_vm.documentosCompletos,
                                },
                                model: {
                                  value: _vm.confirmAceptacion,
                                  callback: function ($$v) {
                                    _vm.confirmAceptacion = $$v
                                  },
                                  expression: "confirmAceptacion",
                                },
                              },
                              [
                                _vm._v(
                                  " Acepto que la información proporcionada es correcta. "
                                ),
                              ]
                            ),
                            _c("span", { staticClass: "cardErrors" }, [
                              _vm._v(_vm._s(errors[0])),
                            ]),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    3308923396
                  ),
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
      _c("div", { staticClass: "mt-4" }, [
        _c(
          "div",
          {},
          [
            _c(
              "b-button",
              {
                staticClass: "btn-fill orange-btn bottom2",
                attrs: {
                  type: "submit",
                  size: "m",
                  disabled: _vm.isButtonDisabled,
                },
              },
              [_vm._v(" " + _vm._s(_vm.buttonText) + " ")]
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }