var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content" },
    [
      _c(
        "b-breadcrumb",
        [
          _c(
            "b-breadcrumb-item",
            { attrs: { to: { path: "/dashboard/clientes" } } },
            [_vm._v(" Clientes ")]
          ),
          _c("b-breadcrumb-item", { attrs: { active: "" } }, [
            _vm._v("Editar cliente"),
          ]),
        ],
        1
      ),
      _c("div", { staticClass: "container" }, [
        _vm.loading
          ? _c("div", [
              _c(
                "div",
                { attrs: { id: "loader" } },
                [
                  _c("font-awesome-icon", {
                    attrs: { icon: "spinner", spin: "" },
                  }),
                ],
                1
              ),
            ])
          : _vm._e(),
      ]),
      _c(
        "b-modal",
        {
          ref: "modal-confirm-dialog",
          attrs: {
            "no-close-on-backdrop": "",
            centered: "",
            "hide-footer": "",
            id: "modal-confirm-dialog",
          },
        },
        [
          _c("h4", { staticClass: "text-center" }, [
            _vm._v(" ¿Seguro que quiere eliminar al usuario secundario? "),
          ]),
          _c("h5", { staticClass: "text-center" }, [
            _vm._v("Se eliminarán todos los datos."),
          ]),
          _c(
            "b-button",
            {
              staticClass: "mt-3",
              attrs: { variant: "danger" },
              on: {
                click: function ($event) {
                  return _vm.$bvModal.hide("modal-confirm-dialog")
                },
              },
            },
            [_vm._v("Cancelar")]
          ),
          _c(
            "b-button",
            {
              staticClass: "mt-3 float-right",
              attrs: { variant: "primary" },
              on: {
                click: function ($event) {
                  return _vm.deleteUsuarioSecundario()
                },
              },
            },
            [_vm._v("OK")]
          ),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          ref: "my-modal",
          attrs: {
            "no-close-on-backdrop": "",
            centered: "",
            title: "",
            "hide-footer": "",
            id: "modal",
            size: "lg",
          },
        },
        [
          _c("ValidationObserver", {
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ handleSubmit }) {
                  return [
                    _c(
                      "form",
                      {
                        on: {
                          submit: function ($event) {
                            $event.preventDefault()
                            return handleSubmit(_vm.postUsuario)
                          },
                        },
                      },
                      [
                        _c("Modal", {
                          attrs: { currentuser: _vm.currentuser },
                          on: {
                            submit: function ($event) {
                              $event.preventDefault()
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("div", { staticClass: "wrapper wrapper-full-page section content" }, [
        _c(
          "div",
          { staticClass: "container" },
          [
            _vm.user.rol === "Cliente Usuario"
              ? _c("div", { staticClass: "mb-4 client" }, [
                  _vm._v(
                    " Usuario del cliente administrador: " +
                      _vm._s(_vm.user.admin_cliente_nombre) +
                      " "
                  ),
                ])
              : _vm._e(),
            _c("ValidationObserver", {
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ handleSubmit }) {
                    return [
                      _c(
                        "form",
                        {
                          on: {
                            submit: function ($event) {
                              $event.preventDefault()
                              return handleSubmit(_vm.updateClient)
                            },
                          },
                        },
                        [
                          _c("Form", {
                            attrs: {
                              user: _vm.user,
                              second_users: _vm.second_users,
                            },
                            on: {
                              submit: function ($event) {
                                $event.preventDefault()
                              },
                              id: _vm.predeleteUsuarioSecundario,
                              dialog: _vm.showFormDialog,
                              usuariosecundario: _vm.editUsusarioSecundario,
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }