var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("ValidationObserver", {
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ invalid }) {
              return [
                _c(
                  "form",
                  {
                    on: {
                      submit: function ($event) {
                        $event.preventDefault()
                        return _vm.onSubmit.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col-md-12" },
                        [
                          _c("ValidationProvider", {
                            attrs: {
                              name: "persona",
                              rules: "required",
                              "custom-messages": {
                                required: "La persona es obligatoria",
                              },
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function ({ errors }) {
                                    return [
                                      _c(
                                        "b-form-group",
                                        {
                                          attrs: {
                                            id: "input-group-1",
                                            label: "Persona:",
                                            "label-for": "input-1",
                                          },
                                        },
                                        [
                                          _c("b-form-select", {
                                            staticClass: "custom-select",
                                            attrs: {
                                              id: "input-1",
                                              options: _vm.optionsPersona,
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "first",
                                                  fn: function () {
                                                    return [
                                                      _c(
                                                        "b-form-select-option",
                                                        {
                                                          attrs: {
                                                            value: null,
                                                            disabled: "",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "-- Seleccione una opción --"
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  },
                                                  proxy: true,
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                            model: {
                                              value: _vm.tipoPersona,
                                              callback: function ($$v) {
                                                _vm.tipoPersona = $$v
                                              },
                                              expression: "tipoPersona",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "span",
                                        { staticClass: "cardErrors" },
                                        [_vm._v(_vm._s(errors[0]))]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm.tipoPersona === "Física"
                      ? _c("div", [
                          _c("div", { staticClass: "row mt-4" }, [
                            _c(
                              "div",
                              { staticClass: "col-md-4" },
                              [
                                _c("ValidationProvider", {
                                  attrs: {
                                    name: "nombre",
                                    rules: "required",
                                    "custom-messages": {
                                      required: "El nombre es obligatorio",
                                    },
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function ({ errors }) {
                                          return [
                                            _c(
                                              "b-form-group",
                                              {
                                                attrs: {
                                                  id: "input-group-2",
                                                  label: "Nombre:",
                                                  "label-for": "input-2",
                                                },
                                              },
                                              [
                                                _c("b-form-input", {
                                                  attrs: {
                                                    id: "input-2",
                                                    type: "text",
                                                    placeholder: "Nombre",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm
                                                        .solicitantePersonaFisica
                                                        .nombre,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.solicitantePersonaFisica,
                                                        "nombre",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "solicitantePersonaFisica.nombre",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "span",
                                              { staticClass: "cardErrors" },
                                              [_vm._v(_vm._s(errors[0]))]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "col-md-4" },
                              [
                                _c("ValidationProvider", {
                                  attrs: {
                                    name: "nombre",
                                    rules: "required",
                                    "custom-messages": {
                                      required:
                                        "El apellido paterno es obligatorio",
                                    },
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function ({ errors }) {
                                          return [
                                            _c(
                                              "b-form-group",
                                              {
                                                attrs: {
                                                  id: "input-group-3",
                                                  label: "Apellido paterno:",
                                                  "label-for": "input-3",
                                                },
                                              },
                                              [
                                                _c("b-form-input", {
                                                  attrs: {
                                                    id: "input-3",
                                                    type: "text",
                                                    placeholder: "Paterno",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm
                                                        .solicitantePersonaFisica
                                                        .paterno,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.solicitantePersonaFisica,
                                                        "paterno",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "solicitantePersonaFisica.paterno",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "span",
                                              { staticClass: "cardErrors" },
                                              [_vm._v(_vm._s(errors[0]))]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "col-md-4" },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      id: "input-group-4",
                                      label: "Apellido materno:",
                                      "label-for": "input-4",
                                    },
                                  },
                                  [
                                    _c("b-form-input", {
                                      attrs: {
                                        id: "input-4",
                                        type: "text",
                                        placeholder: "Materno",
                                      },
                                      model: {
                                        value:
                                          _vm.solicitantePersonaFisica.materno,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.solicitantePersonaFisica,
                                            "materno",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "solicitantePersonaFisica.materno",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                          _vm.askForNumber
                            ? _c("div", { staticClass: "row mt-4" }, [
                                _c(
                                  "div",
                                  { staticClass: "col-md-12" },
                                  [
                                    _c("ValidationProvider", {
                                      attrs: {
                                        name: "numero",
                                        rules: {
                                          required: true,
                                          length: 10,
                                          regex: /^\d{10}$/,
                                        },
                                        "custom-messages": {
                                          required: "El número es obligatorio",
                                          length:
                                            "El número debe tener 10 dígitos",
                                          regex:
                                            "El número no tiene el formato correcto",
                                        },
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function ({ errors }) {
                                              return [
                                                _c(
                                                  "b-form-group",
                                                  {
                                                    attrs: {
                                                      id: "input-group-number",
                                                      label:
                                                        "Número de teléfono:",
                                                      "label-for":
                                                        "input-number",
                                                    },
                                                  },
                                                  [
                                                    _c("b-form-input", {
                                                      staticClass: "col-md-4",
                                                      attrs: {
                                                        id: "input-number",
                                                        type: "tel",
                                                        placeholder:
                                                          "Número de teléfono",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm
                                                            .solicitantePersonaFisica
                                                            .telefono.numero,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm
                                                              .solicitantePersonaFisica
                                                              .telefono,
                                                            "numero",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "solicitantePersonaFisica.telefono.numero",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "div",
                                                  { staticClass: "row" },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "cardErrors",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(errors[0])
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  { staticClass: "row" },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "cardErrors",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " Ingrese un número de teléfono celular válido que será usado para enviarle un SMS como parte del proceso de firma electrónica "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    }),
                                  ],
                                  1
                                ),
                              ])
                            : _vm._e(),
                          _c("div", { staticClass: "row mt-4" }, [
                            _c(
                              "div",
                              { staticClass: "col-md-4" },
                              [
                                _c("ValidationProvider", {
                                  attrs: {
                                    name: "rfc",
                                    rules: {
                                      required: true,
                                      length: 13,
                                      regex:
                                        /^[A-Z&Ñ]{4}\d{2}(0[1-9]|1[0-2])(0[1-9]|[12]\d|3[01])[A-Z0-9]{3}$/,
                                    },
                                    "custom-messages": {
                                      required: "El RFC es obligatorio",
                                      length: `Debe tener 13 caracteres`,
                                      regex:
                                        "El RFC no tiene el formato correcto",
                                    },
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function ({ errors }) {
                                          return [
                                            _c("base-input", {
                                              attrs: {
                                                type: "text",
                                                label: "RFC",
                                                placeholder: "RFC",
                                              },
                                              model: {
                                                value:
                                                  _vm.solicitantePersonaFisica
                                                    .rfc,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.solicitantePersonaFisica,
                                                    "rfc",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "solicitantePersonaFisica.rfc",
                                              },
                                            }),
                                            _c(
                                              "span",
                                              { staticClass: "cardErrors" },
                                              [_vm._v(_vm._s(errors[0]))]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "col-md-4" },
                              [
                                _c("ValidationProvider", {
                                  attrs: {
                                    name: "curp",
                                    rules: {
                                      required: true,
                                      length: 18,
                                      regex:
                                        /^[A-Z&Ñ]{4}\d{6}[HM](AS|BC|BS|CC|CL|CM|CS|CH|DF|DG|GT|GR|HG|JC|MC|MN|MS|NT|NL|OC|PL|QT|QR|SP|SL|SR|TC|TS|TL|VZ|YN|ZS)[A-Z0-9]{3}\d{2}$/,
                                    },
                                    "custom-messages": {
                                      required: "La CURP es obligatorio",
                                      length: "Debe tener 18 caracteres",
                                      regex:
                                        "La CURP no tiene el formato correcto",
                                    },
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function ({ errors }) {
                                          return [
                                            _c("base-input", {
                                              attrs: {
                                                type: "text",
                                                label: "CURP",
                                                placeholder: "CURP",
                                              },
                                              model: {
                                                value:
                                                  _vm.solicitantePersonaFisica
                                                    .curp,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.solicitantePersonaFisica,
                                                    "curp",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "solicitantePersonaFisica.curp",
                                              },
                                            }),
                                            _c(
                                              "span",
                                              { staticClass: "cardErrors" },
                                              [_vm._v(_vm._s(errors[0]))]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "col-md-4" },
                              [
                                _c("ValidationProvider", {
                                  attrs: {
                                    name: "fecha_nacimiento",
                                    rules: "required",
                                    "custom-messages": {
                                      required:
                                        "La fecha de nacimiento es obligatoria",
                                    },
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function ({ errors }) {
                                          return [
                                            _c("base-input", {
                                              attrs: {
                                                type: "date",
                                                label: "Fecha de nacimiento",
                                                placeholder:
                                                  "Fecha de nacimiento",
                                              },
                                              model: {
                                                value:
                                                  _vm.solicitantePersonaFisica
                                                    .fecha_nacimiento,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.solicitantePersonaFisica,
                                                    "fecha_nacimiento",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "solicitantePersonaFisica.fecha_nacimiento",
                                              },
                                            }),
                                            _c(
                                              "span",
                                              { staticClass: "cardErrors" },
                                              [_vm._v(_vm._s(errors[0]))]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                              ],
                              1
                            ),
                          ]),
                          _c("div", { staticClass: "row mt-4" }, [
                            _c(
                              "div",
                              { staticClass: "col-md-12" },
                              [
                                _c("ValidationProvider", {
                                  attrs: {
                                    name: "ocupacion",
                                    rules: "required",
                                    "custom-messages": {
                                      required: "La ocupación es obligatoria",
                                    },
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function ({ errors }) {
                                          return [
                                            _c("base-input", {
                                              attrs: {
                                                type: "text",
                                                label:
                                                  "Ocupación / Actividad / Profesión",
                                                placeholder: "Ocupación",
                                              },
                                              model: {
                                                value:
                                                  _vm.solicitantePersonaFisica
                                                    .ocupacion,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.solicitantePersonaFisica,
                                                    "ocupacion",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "solicitantePersonaFisica.ocupacion",
                                              },
                                            }),
                                            _c(
                                              "span",
                                              { staticClass: "cardErrors" },
                                              [_vm._v(_vm._s(errors[0]))]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                              ],
                              1
                            ),
                          ]),
                          _c("div", { staticClass: "row mb-4" }, [
                            _c(
                              "div",
                              { staticClass: "col-md-4" },
                              [
                                _c("ValidationProvider", {
                                  attrs: {
                                    name: "lugar_nacim_pais",
                                    rules: "required",
                                    "custom-messages": {
                                      required: "El país es obligatorio",
                                    },
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function ({ errors }) {
                                          return [
                                            _c("label", [
                                              _vm._v(
                                                "Lugar de nacimiento: País"
                                              ),
                                            ]),
                                            _c(
                                              "select",
                                              {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value: _vm.selectCountry,
                                                    expression: "selectCountry",
                                                  },
                                                ],
                                                staticClass: "form-control",
                                                on: {
                                                  change: [
                                                    function ($event) {
                                                      var $$selectedVal =
                                                        Array.prototype.filter
                                                          .call(
                                                            $event.target
                                                              .options,
                                                            function (o) {
                                                              return o.selected
                                                            }
                                                          )
                                                          .map(function (o) {
                                                            var val =
                                                              "_value" in o
                                                                ? o._value
                                                                : o.value
                                                            return val
                                                          })
                                                      _vm.selectCountry = $event
                                                        .target.multiple
                                                        ? $$selectedVal
                                                        : $$selectedVal[0]
                                                    },
                                                    _vm.fetchStatesByCountry,
                                                  ],
                                                },
                                              },
                                              _vm._l(
                                                _vm.countries,
                                                function (option) {
                                                  return _c(
                                                    "option",
                                                    { key: option.id },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(option.name) +
                                                          " "
                                                      ),
                                                    ]
                                                  )
                                                }
                                              ),
                                              0
                                            ),
                                            _c(
                                              "span",
                                              { staticClass: "cardErrors" },
                                              [_vm._v(_vm._s(errors[0]))]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "col-md-4" },
                              [
                                _c("ValidationProvider", {
                                  attrs: {
                                    name: "lugar_nacim_estado",
                                    rules: "required",
                                    "custom-messages": {
                                      required: "El estado es obligatorio",
                                    },
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function ({ errors }) {
                                          return [
                                            _c("label", [
                                              _vm._v(
                                                "Lugar de nacimiento: Estado"
                                              ),
                                            ]),
                                            _c(
                                              "select",
                                              {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value: _vm.selectState,
                                                    expression: "selectState",
                                                  },
                                                ],
                                                staticClass: "form-control",
                                                on: {
                                                  change: [
                                                    function ($event) {
                                                      var $$selectedVal =
                                                        Array.prototype.filter
                                                          .call(
                                                            $event.target
                                                              .options,
                                                            function (o) {
                                                              return o.selected
                                                            }
                                                          )
                                                          .map(function (o) {
                                                            var val =
                                                              "_value" in o
                                                                ? o._value
                                                                : o.value
                                                            return val
                                                          })
                                                      _vm.selectState = $event
                                                        .target.multiple
                                                        ? $$selectedVal
                                                        : $$selectedVal[0]
                                                    },
                                                    _vm.fetchCitiesByState,
                                                  ],
                                                },
                                              },
                                              [
                                                _vm.isEmptyStates
                                                  ? _c(
                                                      "option",
                                                      {
                                                        attrs: {
                                                          value: "noStates",
                                                          selected: "",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.noStatesText
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm._l(
                                                  _vm.states,
                                                  function (option) {
                                                    return _c(
                                                      "option",
                                                      { key: option.id },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              option.name
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    )
                                                  }
                                                ),
                                              ],
                                              2
                                            ),
                                            _c(
                                              "span",
                                              { staticClass: "cardErrors" },
                                              [_vm._v(_vm._s(errors[0]))]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "col-md-4" },
                              [
                                _c("ValidationProvider", {
                                  attrs: {
                                    name: "lugar_nacim_ciudad",
                                    rules: "required",
                                    "custom-messages": {
                                      required:
                                        "La ciudad de nacimiento es obligatoria",
                                    },
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function ({ errors }) {
                                          return [
                                            _c("label", [
                                              _vm._v("Municipio / Alcaldía"),
                                            ]),
                                            _c(
                                              "select",
                                              {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value: _vm.selectCity,
                                                    expression: "selectCity",
                                                  },
                                                ],
                                                staticClass: "form-control",
                                                on: {
                                                  change: function ($event) {
                                                    var $$selectedVal =
                                                      Array.prototype.filter
                                                        .call(
                                                          $event.target.options,
                                                          function (o) {
                                                            return o.selected
                                                          }
                                                        )
                                                        .map(function (o) {
                                                          var val =
                                                            "_value" in o
                                                              ? o._value
                                                              : o.value
                                                          return val
                                                        })
                                                    _vm.selectCity = $event
                                                      .target.multiple
                                                      ? $$selectedVal
                                                      : $$selectedVal[0]
                                                  },
                                                },
                                              },
                                              [
                                                _vm.isEmptyCities
                                                  ? _c(
                                                      "option",
                                                      {
                                                        attrs: {
                                                          value: "noCities",
                                                          selected: "",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.noCitiesText
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm._l(
                                                  _vm.cities,
                                                  function (option) {
                                                    return _c(
                                                      "option",
                                                      { key: option.id },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              option.name
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    )
                                                  }
                                                ),
                                              ],
                                              2
                                            ),
                                            _c(
                                              "span",
                                              { staticClass: "cardErrors" },
                                              [_vm._v(_vm._s(errors[0]))]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                              ],
                              1
                            ),
                          ]),
                        ])
                      : _vm.tipoPersona === "Moral"
                      ? _c(
                          "div",
                          { staticClass: "mt-4" },
                          [
                            _c("div", { staticClass: "row" }, [
                              _c(
                                "div",
                                { staticClass: "col-md-12" },
                                [
                                  _c("ValidationProvider", {
                                    attrs: {
                                      name: "denominacion_social",
                                      rules: "required",
                                      "custom-messages": {
                                        required:
                                          "La denominacion social es obligatoria",
                                      },
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function ({ errors }) {
                                            return [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    id: "input-group-5",
                                                    label:
                                                      "Denominación social:",
                                                    "label-for": "input-5",
                                                  },
                                                },
                                                [
                                                  _c("b-form-input", {
                                                    attrs: {
                                                      id: "input-5",
                                                      type: "text",
                                                      placeholder:
                                                        "Denominación social",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm
                                                          .solicitantePersonaMoral
                                                          .denominacion_social,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.solicitantePersonaMoral,
                                                          "denominacion_social",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "solicitantePersonaMoral.denominacion_social",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "span",
                                                { staticClass: "cardErrors" },
                                                [_vm._v(_vm._s(errors[0]))]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _c("div", { staticClass: "row" }, [
                              _c(
                                "div",
                                { staticClass: "col-md-6" },
                                [
                                  _c("ValidationProvider", {
                                    attrs: {
                                      name: "rfc",
                                      rules: {
                                        required: true,
                                        length: 12,
                                        regex:
                                          /^[A-Z&Ñ]{3}\d{2}(0[1-9]|1[0-2])(0[1-9]|[12]\d|3[01])[A-Z0-9]{3}$/,
                                      },
                                      "custom-messages": {
                                        required: "El RFC es obligatorio",
                                        length: `Debe tener 12 caracteres`,
                                        regex:
                                          "El RFC no tiene el formato correcto",
                                      },
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function ({ errors }) {
                                            return [
                                              _c("base-input", {
                                                attrs: {
                                                  type: "text",
                                                  label: "RFC",
                                                  placeholder: "RFC",
                                                },
                                                model: {
                                                  value:
                                                    _vm.solicitantePersonaMoral
                                                      .rfc,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.solicitantePersonaMoral,
                                                      "rfc",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "solicitantePersonaMoral.rfc",
                                                },
                                              }),
                                              _c(
                                                "span",
                                                { staticClass: "cardErrors" },
                                                [_vm._v(_vm._s(errors[0]))]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "col-md-6" },
                                [
                                  _c("ValidationProvider", {
                                    attrs: {
                                      name: "fecha_constitucion",
                                      rules: "required",
                                      "custom-messages": {
                                        required:
                                          "La fecha de constitución es obligatoria",
                                      },
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function ({ errors }) {
                                            return [
                                              _c("base-input", {
                                                attrs: {
                                                  type: "date",
                                                  label:
                                                    "Fecha de constitución",
                                                  placeholder:
                                                    "Fecha de constitución",
                                                },
                                                model: {
                                                  value:
                                                    _vm.solicitantePersonaMoral
                                                      .fecha_constitucion,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.solicitantePersonaMoral,
                                                      "fecha_constitucion",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "solicitantePersonaMoral.fecha_constitucion",
                                                },
                                              }),
                                              _c(
                                                "span",
                                                { staticClass: "cardErrors" },
                                                [_vm._v(_vm._s(errors[0]))]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _c("div", { staticClass: "row mb-4" }, [
                              _c(
                                "div",
                                { staticClass: "col-4" },
                                [
                                  _c("ValidationProvider", {
                                    attrs: {
                                      name: "lugar_nacim_pais",
                                      rules: "required",
                                      "custom-messages": {
                                        required: "El país es obligatorio",
                                      },
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function ({ errors }) {
                                            return [
                                              _c("label", [
                                                _vm._v(
                                                  "Lugar de constitución: País"
                                                ),
                                              ]),
                                              _c(
                                                "select",
                                                {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value: _vm.selectCountry,
                                                      expression:
                                                        "selectCountry",
                                                    },
                                                  ],
                                                  staticClass: "form-control",
                                                  on: {
                                                    change: [
                                                      function ($event) {
                                                        var $$selectedVal =
                                                          Array.prototype.filter
                                                            .call(
                                                              $event.target
                                                                .options,
                                                              function (o) {
                                                                return o.selected
                                                              }
                                                            )
                                                            .map(function (o) {
                                                              var val =
                                                                "_value" in o
                                                                  ? o._value
                                                                  : o.value
                                                              return val
                                                            })
                                                        _vm.selectCountry =
                                                          $event.target.multiple
                                                            ? $$selectedVal
                                                            : $$selectedVal[0]
                                                      },
                                                      _vm.fetchStatesByCountry,
                                                    ],
                                                  },
                                                },
                                                _vm._l(
                                                  _vm.countries,
                                                  function (option) {
                                                    return _c(
                                                      "option",
                                                      { key: option.id },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              option.name
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                0
                                              ),
                                              _c(
                                                "span",
                                                { staticClass: "cardErrors" },
                                                [_vm._v(_vm._s(errors[0]))]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "col-md-4" },
                                [
                                  _c("ValidationProvider", {
                                    attrs: {
                                      name: "clave",
                                      rules: "required",
                                      "custom-messages": {
                                        required: "La clave LADA es requerida",
                                      },
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function ({ errors }) {
                                            return [
                                              _c("base-input", {
                                                attrs: {
                                                  type: "text",
                                                  label: "Código país",
                                                  placeholder: "Código país",
                                                },
                                                model: {
                                                  value: _vm.phoneKey,
                                                  callback: function ($$v) {
                                                    _vm.phoneKey = $$v
                                                  },
                                                  expression: "phoneKey",
                                                },
                                              }),
                                              _c(
                                                "span",
                                                { staticClass: "cardErrors" },
                                                [_vm._v(_vm._s(errors[0]))]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "col-md-4" },
                                [
                                  _c("ValidationProvider", {
                                    attrs: {
                                      name: "numero",
                                      rules: "required|length:10",
                                      "custom-messages": {
                                        required:
                                          "El número de teléfono es requerido",
                                        length:
                                          "El número debe tener 10 dígitos",
                                      },
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function ({ errors }) {
                                            return [
                                              _c("base-input", {
                                                attrs: {
                                                  type: "text",
                                                  label:
                                                    "Número de Teléfono Celular",
                                                  placeholder: "Número",
                                                },
                                                model: {
                                                  value:
                                                    _vm.solicitantePersonaMoral
                                                      .telefonos[0].numero,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm
                                                        .solicitantePersonaMoral
                                                        .telefonos[0],
                                                      "numero",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "solicitantePersonaMoral.telefonos[0].numero",
                                                },
                                              }),
                                              _c(
                                                "span",
                                                { staticClass: "cardErrors" },
                                                [_vm._v(_vm._s(errors[0]))]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _c("div", { staticClass: "row" }, [
                              _c(
                                "label",
                                {
                                  staticClass:
                                    "col-md-12 fs-6 text-black-50 text-uppercase",
                                },
                                [_vm._v("Representante(s)")]
                              ),
                            ]),
                            _vm._l(
                              _vm.solicitantePersonaMoral.representantes,
                              function (representante, index) {
                                return _c("div", { key: index }, [
                                  _c("div", { staticClass: "row" }, [
                                    _c(
                                      "div",
                                      { staticClass: "col-md-4" },
                                      [
                                        _c("ValidationProvider", {
                                          attrs: {
                                            name: "nombre",
                                            rules: "required",
                                            "custom-messages": {
                                              required:
                                                "El nombre es obligatorio",
                                            },
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function ({ errors }) {
                                                  return [
                                                    _c(
                                                      "b-form-group",
                                                      {
                                                        attrs: {
                                                          id:
                                                            "input-group-representante-nombre-" +
                                                            index,
                                                          label: "Nombre:",
                                                          "label-for":
                                                            "input-representante-nombre-" +
                                                            index,
                                                        },
                                                      },
                                                      [
                                                        _c("b-form-input", {
                                                          attrs: {
                                                            id:
                                                              "input-representante-nombre-" +
                                                              index,
                                                            type: "text",
                                                            placeholder:
                                                              "Nombre",
                                                          },
                                                          model: {
                                                            value:
                                                              representante.nombre,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                representante,
                                                                "nombre",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "representante.nombre",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "cardErrors",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(errors[0])
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "col-md-4" },
                                      [
                                        _c("ValidationProvider", {
                                          attrs: {
                                            name: "nombre",
                                            rules: "required",
                                            "custom-messages": {
                                              required:
                                                "El apellido paterno es obligatorio",
                                            },
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function ({ errors }) {
                                                  return [
                                                    _c(
                                                      "b-form-group",
                                                      {
                                                        attrs: {
                                                          id:
                                                            "input-group-representante-paterno-" +
                                                            index,
                                                          label:
                                                            "Apellido paterno:",
                                                          "label-for":
                                                            "input-representante-materno-" +
                                                            index,
                                                        },
                                                      },
                                                      [
                                                        _c("b-form-input", {
                                                          attrs: {
                                                            id:
                                                              "input-representante-materno-" +
                                                              index,
                                                            type: "text",
                                                            placeholder:
                                                              "Paterno",
                                                          },
                                                          model: {
                                                            value:
                                                              representante.paterno,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                representante,
                                                                "paterno",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "representante.paterno",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "cardErrors",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(errors[0])
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "col-md-4" },
                                      [
                                        _c(
                                          "b-form-group",
                                          {
                                            attrs: {
                                              id:
                                                "input-group-representante-materno-" +
                                                index,
                                              label: "Apellido materno:",
                                              "label-for":
                                                "input-representante-materno-" +
                                                index,
                                            },
                                          },
                                          [
                                            _c("b-form-input", {
                                              attrs: {
                                                id:
                                                  "input-representante-materno-" +
                                                  index,
                                                type: "text",
                                                placeholder: "Materno",
                                              },
                                              model: {
                                                value: representante.materno,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    representante,
                                                    "materno",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "representante.materno",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]),
                                  _vm.askForNumber
                                    ? _c("div", { staticClass: "row mt-4" }, [
                                        _c(
                                          "div",
                                          { staticClass: "col-md-12" },
                                          [
                                            _c("ValidationProvider", {
                                              attrs: {
                                                name: "numero",
                                                rules: {
                                                  required: true,
                                                  length: 10,
                                                  regex: /^\d{10}$/,
                                                },
                                                "custom-messages": {
                                                  required:
                                                    "El número es obligatorio",
                                                  length:
                                                    "El número debe tener 10 dígitos",
                                                  regex:
                                                    "El número no tiene el formato correcto",
                                                },
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function ({ errors }) {
                                                      return [
                                                        _c(
                                                          "b-form-group",
                                                          {
                                                            attrs: {
                                                              id:
                                                                "input-group-representante-number-" +
                                                                index,
                                                              label:
                                                                "Número de teléfono:",
                                                              "label-for":
                                                                "input-representante-number-" +
                                                                index,
                                                            },
                                                          },
                                                          [
                                                            _c("b-form-input", {
                                                              staticClass:
                                                                "col-md-4",
                                                              attrs: {
                                                                id: "input-number",
                                                                type: "tel",
                                                                placeholder:
                                                                  "Número de teléfono",
                                                              },
                                                              model: {
                                                                value:
                                                                  representante
                                                                    .telefono
                                                                    .numero,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      representante.telefono,
                                                                      "numero",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "representante.telefono.numero",
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass: "row",
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "cardErrors",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    errors[0]
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass: "row",
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "cardErrors",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " Ingrese un número de teléfono celular que válido será usado para enviarle un SMS como parte del proceso de firma electrónica "
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            }),
                                          ],
                                          1
                                        ),
                                      ])
                                    : _vm._e(),
                                ])
                              }
                            ),
                            _c(
                              "div",
                              { staticClass: "row" },
                              [
                                _vm.canRemoveRepresentante
                                  ? _c(
                                      "b-button",
                                      {
                                        staticClass: "btn-fill spaceL",
                                        attrs: {
                                          variant: "danger",
                                          size: "sm",
                                        },
                                        on: { click: _vm.removeRepresentante },
                                      },
                                      [_vm._v(" Remover representante ")]
                                    )
                                  : _vm._e(),
                                _vm.canAddRepresentante
                                  ? _c(
                                      "b-button",
                                      {
                                        staticClass: "btn-fill spaceL",
                                        attrs: {
                                          variant: "secondary",
                                          size: "sm",
                                        },
                                        on: { click: _vm.addRepresentante },
                                      },
                                      [_vm._v(" Agregar otro representante ")]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          2
                        )
                      : _vm._e(),
                    _c("div", { staticClass: "row mt-4" }),
                    _c("div", { staticClass: "mt-4 text-center" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-fill float-right orange-btn",
                          attrs: { type: "submit", disabled: invalid },
                        },
                        [_vm._v(" Guardar ")]
                      ),
                    ]),
                  ]
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }