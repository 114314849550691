var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("table", { staticClass: "tabla" }, [
      _c("tr", [
        _c("th", { staticClass: "section", attrs: { colspan: "10" } }, [
          _vm._v(" " + _vm._s(_vm.datos.compareciente_principal) + " "),
        ]),
      ]),
      _c("tr", { staticClass: "date" }, [
        _c("th", [
          _vm._v(
            " Ciudad de México a " +
              _vm._s(_vm.datos.day) +
              " de " +
              _vm._s(_vm.datos.month) +
              " de " +
              _vm._s(_vm.datos.year) +
              " "
          ),
        ]),
      ]),
    ]),
    _vm._m(0),
    _vm._m(1),
    _c(
      "table",
      {
        staticClass: "tabla",
        staticStyle: { "margin-top": "5px", "border-spacing": "0 15px" },
      },
      [
        _c("tr", [
          _c("td", [
            _vm._v(
              " Por medio del presente escrito, " +
                _vm._s(_vm.autorizo) +
                " a Correduría Digital, S. de R.L. de C.V. para gestionar con el Corredor Público de su elección, en la plaza de " +
                _vm._s(_vm.datos.plaza) +
                ", para que éste lleve a cabo como si " +
                _vm._s(_vm.elSuscritoInstruyera) +
                " de forma directa, la diligencia a que se refiere la presente instrucción. "
            ),
          ]),
        ]),
        _c("tr", [
          _c("td", [
            _vm._v(
              " Por medio de la presente " +
                _vm._s(_vm.solicito) +
                " al Corredor Público designado por Correduría Digital, S. de R.L. de C.V., se sirva notificar a " +
                _vm._s(_vm.datos.notificados) +
                " en el domicilio ubicado en " +
                _vm._s(_vm.datos.domicilio) +
                ", " +
                _vm._s(_vm.elOriginal_unaImpresionDeLaVersionDigital) +
                " de la comunicación que adjunto a la presente solicitud, cuyo contenido y alcance declaro, bajo protesta de decir verdad, conocer y entender. "
            ),
          ]),
        ]),
        _c("tr", [
          _c("td", [
            _vm._v(
              " Expresamente " +
                _vm._s(_vm.autorizo) +
                " al Corredor Público para (i) llevar a cabo la diligencia de notificación en el domicilio señalado, no obstante que al momento de la actuación, la persona que atienda le informe que en dicho domicilio no se encuentra el destinatario; (ii) llevar a cabo la diligencia de notificación en el domicilio señalado mediante la fijación del instructivo de notificación y anexos correspondientes en la puerta u otro lugar visible del domicilio, o bien depositándolos en el interior del domicilio por cualquier acceso; y (iii) en caso de que la persona a la que se dirige la carta objeto de la notificación se encuentre en otro domicilio al anteriormente señalado, llevar a cabo la diligencia correspondiente en dicho otro domicilio, siempre y cuando la persona con quien se entienda la diligencia manifieste que es domicilio de la persona con la cual deba practicarse la notificación, o le conste por cualquier otro medio. "
            ),
          ]),
        ]),
        _c("tr", [
          _c("td", [
            _vm._v(
              " Asimismo, " +
                _vm._s(_vm.autorizo) +
                " al Corredor Público designado para, que en caso de que no le sea permitido el acceso a los números interiores del inmueble, realizar la notificación en los términos arriba planteados en la recepción del inmueble principal, en la entrada principal del mismo, en cualquier buzón localizado en el inmueble, o bien en la oficialía de partes, de mensajería o similar ubicada en el domicilio señalado. "
            ),
          ]),
        ]),
        _c("tr", [
          _c("td", [
            _vm._v(
              " " +
                _vm._s(_vm.Declaro) +
                ", bajo protesta de decir verdad: (i) conocer y entender el contenido y alcance de la comunicación objeto de la diligencia de notificación que se solicita; (ii) que los hechos sobre los cuales versará la diligencia solicitada, forman parte del medio para el ejercicio de la actividad mercantil de los comerciantes involucrados; (iii) que la comunicación señalada forma parte de los documentos a que se refieren los artículos treinta y tres a cincuenta del Código de Comercio; y (iv) ser " +
                _vm._s(_vm.responsable) +
                " del contenido del documento que presento para llevar a cabo la diligencia, y de la exactitud de los datos proporcionados para el desarrollo de la misma. "
            ),
          ]),
        ]),
        _vm._m(2),
      ]
    ),
    _vm.datos.apoderado
      ? _c("table", { staticClass: "tabla" }, [
          _c("tr", { staticClass: "space", staticStyle: { height: "200px" } }),
          _c("tr", [
            _c("th", { staticClass: "section", attrs: { colspan: "10" } }, [
              _vm._v(" " + _vm._s(_vm.datos.compareciente_principal) + " "),
            ]),
          ]),
          _c("tr", { staticClass: "space", staticStyle: { height: "100px" } }),
          _vm._m(3),
          _c("tr", [
            _c("td", { staticClass: "space", staticStyle: { width: "20%" } }),
            _c(
              "td",
              {
                staticStyle: {
                  width: "60%",
                  "vertical-align": "top",
                  "font-size": "14px",
                  "text-align": "center",
                  "background-color": "white",
                },
              },
              [_vm._v(" Por: " + _vm._s(_vm.datos.apoderado) + " ")]
            ),
            _c("td", {
              staticClass: "space",
              staticStyle: { width: "20%" },
              attrs: { colspan: "2" },
            }),
          ]),
          _vm._m(4),
        ])
      : _c("table", { staticClass: "tabla" }, [
          _c("tr", { staticClass: "space", staticStyle: { height: "200px" } }),
          _c("tr", [
            _c("th", { staticClass: "section", attrs: { colspan: "10" } }, [
              _vm._v(" " + _vm._s(_vm.datos.compareciente_principal) + " "),
            ]),
          ]),
          _c("tr", { staticClass: "space", staticStyle: { height: "100px" } }),
          _vm._m(5),
        ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("table", { staticClass: "tabla" }, [
      _c("tr", [
        _c("td", { staticStyle: { width: "62.5%", "font-weight": "bolder" } }, [
          _vm._v(" Correduria Digital, S. de R.L. de C.V. "),
        ]),
      ]),
      _c("tr", [
        _c("td", [_vm._v(" Av. Paseo de las Palmas 731, oficina 202, ")]),
      ]),
      _c("tr", [
        _c("td", [_vm._v(" Lomas de Chapultepec, 11000, Miguel Hidalgo, ")]),
      ]),
      _c("tr", [_c("td", [_vm._v(" Ciudad de México, México ")])]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "table",
      { staticClass: "tabla", staticStyle: { "margin-top": "15px" } },
      [
        _c("tr", [
          _c(
            "td",
            {
              staticStyle: {
                width: "5%",
                "font-weight": "bolder",
                "vertical-align": "top",
              },
            },
            [_vm._v(" Atn': ")]
          ),
          _c("td", [
            _vm._v(
              " Corredor Público asignado por Correduria Digital, S. de R.L. de C.V., para llevar a cabo la diligencia a que se refiere la presente instrucción "
            ),
          ]),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [_c("td", [_vm._v(" Atentamente, ")])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", { staticClass: "space", staticStyle: { width: "10%" } }),
      _c("td", {
        staticClass: "user",
        staticStyle: {
          width: "auto",
          "background-color": "white !important",
          "border-bottom": "solid 2px black",
        },
      }),
      _c("td", { staticClass: "space", staticStyle: { width: "30%" } }),
      _c("td", {
        staticClass: "user",
        staticStyle: {
          width: "auto",
          "background-color": "white",
          "border-bottom": "solid 2px",
        },
      }),
      _c("td", { staticStyle: { width: "10%" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", { staticClass: "space", staticStyle: { width: "20%" } }),
      _c(
        "td",
        {
          staticStyle: {
            width: "60%",
            "vertical-align": "top",
            "font-size": "14px",
            "text-align": "center",
            "background-color": "white",
          },
        },
        [_vm._v(" Cargo: Representante Legal ")]
      ),
      _c("td", {
        staticClass: "space",
        staticStyle: { width: "20%" },
        attrs: { colspan: "2" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", { staticClass: "space", staticStyle: { width: "20%" } }),
      _c("td", {
        staticClass: "user",
        staticStyle: {
          width: "60%",
          "background-color": "white !important",
          "border-bottom": "solid 2px black",
        },
      }),
      _c("td", { staticClass: "space", staticStyle: { width: "20%" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }