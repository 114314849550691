var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-md-6" },
        [
          _c("ValidationProvider", {
            attrs: {
              name: "tipo",
              rules: "required",
              "custom-messages": {
                required: "El tipo es obligatorio",
              },
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ errors }) {
                  return [
                    _c("label", { attrs: { for: "" } }, [_vm._v("Tipo")]),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.currentDocumento.tipo,
                            expression: "currentDocumento.tipo",
                          },
                        ],
                        staticClass: "custom-select",
                        on: {
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.currentDocumento,
                              "tipo",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                        },
                      },
                      _vm._l(_vm.options, function (option) {
                        return _c("option", { key: option.value }, [
                          _vm._v(" " + _vm._s(option.text) + " "),
                        ])
                      }),
                      0
                    ),
                    _vm.currentDocumento.tipo === "Otro"
                      ? _c("base-input", {
                          staticClass: "mt-2",
                          attrs: {
                            type: "text",
                            placeholder: "Tipo de documento",
                          },
                          model: {
                            value: _vm.currentDocumento.descripcion,
                            callback: function ($$v) {
                              _vm.$set(_vm.currentDocumento, "descripcion", $$v)
                            },
                            expression: "currentDocumento.descripcion",
                          },
                        })
                      : _vm._e(),
                    _c("span", { staticClass: "cardErrors" }, [
                      _vm._v(_vm._s(errors[0])),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-md-6" },
        [
          _c("ValidationProvider", {
            attrs: {
              name: "documento",
              rules: "required",
              "custom-messages": { required: "El documento es obligatorio" },
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ errors }) {
                  return [
                    _c("label", { attrs: { for: "" } }, [_vm._v("Documento")]),
                    _c("b-form-file", {
                      attrs: {
                        "browse-text": "Seleccionar",
                        "drop-placeholder": "Cargar aquí",
                        placeholder: "Da click aquí o arrastra el archivo",
                      },
                      on: { input: _vm.onInput },
                      model: {
                        value: _vm.currentDocumento.file,
                        callback: function ($$v) {
                          _vm.$set(_vm.currentDocumento, "file", $$v)
                        },
                        expression: "currentDocumento.file",
                      },
                    }),
                    _c("p", { staticClass: "orange-italic-13p-text" }, [
                      _vm._v("Tamaño máximo del archivo: 25 MB"),
                    ]),
                    _c("span", { staticClass: "cardErrors" }, [
                      _vm._v(_vm._s(errors[0])),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ]),
    _vm.currentDocumento.tipo === "Cuadro de distribución accionaria"
      ? _c("label", { staticClass: "mt-4 leyenda" }, [
          _vm._v(
            " Del análisis del cuadro, el staff te contactará para solicitar la información requierida de los beneficiarios controladores. "
          ),
        ])
      : _vm._e(),
    _vm._m(0),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text-center mt-5" }, [
      _c(
        "button",
        {
          staticClass: "btn btn-fill float-right orange-btn",
          attrs: { type: "submit" },
        },
        [_vm._v(" Guardar ")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }