<template>
  <div class="content" ref="content">
    <div class="container">
      <div v-if="loading">
        <div id="loader">
          <font-awesome-icon icon="spinner" spin />
        </div>
      </div>
    </div>

    <b-breadcrumb>
      <b-breadcrumb-item :to="{ path: '/dashboard' }">Inicio</b-breadcrumb-item>
      <b-breadcrumb-item active>Editar Notificación</b-breadcrumb-item>
    </b-breadcrumb>

    <b-modal no-close-on-backdrop centered ref="modal-confirm-dialog-route" hide-footer id="modal-confirm-dialog-route">
      <h4 class="text-center">
        ¿Seguro que quiere salir de la página?
      </h4>
      <h5 class="text-center"></h5>
      <b-button class="mt-3" variant="danger" @click="$bvModal.hide('modal-confirm-dialog-route')">Cancelar</b-button>
      <b-button class="mt-3 float-right" variant="primary" @click="beforeLeave(true)">
        OK
      </b-button>
    </b-modal>

    <b-modal no-close-on-backdrop centered ref="modal-confirm-dialog" hide-footer id="modal-confirm-dialog">
      <h4 class="text-center">
        ¿Seguro que quiere salir sin guardar los cambios?
      </h4>
      <h5 class="text-center"></h5>
      <b-button class="mt-3" variant="danger" @click="$bvModal.hide('modal-confirm-dialog')">Cancelar</b-button>
      <b-button class="mt-3 float-right" variant="primary">OK</b-button>
    </b-modal>

    <b-modal no-close-on-backdrop id="confirmar-facturacion-modal" ref="confirmar-facturacion-modal" centered
      hide-footer>
      <h4 class="text-center">¿Desea facturar su pago?</h4>
      <h5 class="text-center"></h5>
      <b-button class="mt-3" variant="danger" @click="confirmGenerarFactura(false)">No en este momento</b-button>
      <b-button class="mt-3 float-right" variant="primary" @click="confirmGenerarFactura(true)">Sí</b-button>
    </b-modal>

    <b-modal no-close-on-backdrop id="datos-fiscales-modal" ref="datos-fiscales-modal" size="lg" centered hide-footer
      hide-header @close.prevent>
      <FacturacionComponent :datosFacturacion="datosFacturacion" @closedFacturacionEvent="closedFacturacion($event)"
        @canceledFacturacionEvent="canceledFacturacion()" />
    </b-modal>

    <b-modal no-close-on-backdrop id="modal-help-entrega-documentos" size="xl" centered hide-footer>
      <b-carousel id="carousel-notificaciones-2" :interval="0" indicators background="#ababab">
        <b-carousel-slide :img-src="require('@/assets/img/notificaciones/help/modal_2_notificaciones_entrega.webp')">
        </b-carousel-slide>
      </b-carousel>
    </b-modal>

    <div class="container-fluid" v-if="notificacion.id_estatus != 13">
      <b-row>
        <b-col class="col-3">
          <b-card>
            <TreeEditNotificaciones :notificacion="notificacion" :user="'Client'" @node="selected"
              :selectedComponent="currentComponentBody" />
          </b-card>
          <div>
            <b-img v-if="currentComponentBody === 'EnvioDomicilio'"
              :src="require('@/assets/img/notificaciones/help/5_envio_a_domicilio.webp')" fluid-grow
              alt="Descripción del envío a domicilio"></b-img>
            <b-img v-if="currentComponentBody === 'ComplementaNotificado'"
              :src="require('@/assets/img/notificaciones/help/7_notificado.webp')" fluid-grow
              alt="Descripción del notificado"></b-img>
            <b-img v-if="currentComponentBody === 'ComplementaSolicitantes'"
              :src="require('@/assets/img/notificaciones/help/8_solicitante_2_final.webp')" fluid-grow
              alt="Descripción de la información complementaria de los solicitantes"></b-img>
            <b-img v-if="currentComponentBody === 'EntregaDocumentos'"
              :src="require('@/assets/img/notificaciones/help/6_entrega_docs_abre_modal_2.webp')" fluid-grow
              alt="Descripción de los documentos a subir"></b-img>
            <b-button v-if="currentComponentBody === 'EntregaDocumentos'" class="orange-btn" block
              @click="$bvModal.show('modal-help-entrega-documentos')">
              Más información
            </b-button>
          </div>
        </b-col>
        <b-col class="col-9">
          <b-card>
            <b-row>
              <b-col class="col-12">
                <div v-if="currentComponentBody === 'Pago'">
                  <Pago :pagado="notificacion.pagado" :forma_pago="notificacion.forma_pago" :notificacion="notificacion"
                    @pay-cotizacion="payCotizacion($event)" @pay-cotizacion-spei="payCotizacionSpei($event)"
                    @pay-cotizacion-monedero="payCotizacion($event)" />
                </div>
                <div v-if="currentComponentBody === 'EnvioDomicilio'">
                  <ValidationObserver v-slot="{ handleSubmit }">
                    <form @submit.prevent="handleSubmit(postDomicilioEnvio)" @change="getAddressInMapEnvio(null)">
                      <ModalDomicilio :currentDomicilio="domicilioEnvio" @submit.prevent @node="selected" />
                    </form>
                  </ValidationObserver>
                </div>
                <div v-if="currentComponentBody === 'ComplementaNotificado'">
                  <ValidationObserver v-slot="{ handleSubmit }">
                    <form @submit.prevent="handleSubmit(saveComplementaNotificado)">
                      <ComplementaNotificado :notificado="notificado" :notificacion="notificacion" @submit.prevent
                        @node="selected" />
                    </form>
                  </ValidationObserver>
                </div>
                <div v-if="currentComponentBody === 'ComplementaSolicitantes'">
                  <ValidationObserver v-slot="{ handleSubmit }">
                    <form @submit.prevent="handleSubmit(saveComplementaSolicitantes)">
                      <ComplementaSolicitantes :notificacion="notificacion" @submit.prevent @node="selected" />
                    </form>
                  </ValidationObserver>
                  <div ref="cartaInstruccion" hidden>
                    <CartaInstruccionTemplate :key="cartaKey" :datos="datos" />
                  </div>
                </div>
                <div v-if="currentComponentBody === 'EntregaDocumentos'">
                  <ValidationObserver v-slot="{ handleSubmit }">
                    <form @submit.prevent="handleSubmit(saveEntregaDocumentos)">
                      <EntregaDocumentos :notificacion="notificacion" @submit.prevent @node="selected" />
                    </form>
                  </ValidationObserver>
                </div>
                <div v-if="currentComponentBody === 'EsperaValidacion'">
                  <ValidationObserver v-slot="{ handleSubmit }">
                    <form @submit.prevent="handleSubmit(saveEntregaDocumentos)">
                      <EsperaValidacion :notificacion="notificacion" @submit.prevent @node="selected" />
                    </form>
                  </ValidationObserver>
                </div>
                <div v-if="currentComponentBody === 'FirmaDocumentos'">
                  <ValidationObserver v-slot="{ handleSubmit }">
                    <form @submit.prevent="handleSubmit(saveFirmaDocumentos)">
                      <FirmaDocumentos :notificacion="notificacion" @submit.prevent @node="selected" />
                    </form>
                  </ValidationObserver>
                </div>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </div>

    <div class="container-fluid" v-if="notificacion.id_estatus == 13">
      <b-card>
        <Expediente :notificacion="notificacion" />
      </b-card>
    </div>

    <div v-if="datosCompletos" ref="KycSolicitanteTemplate" hidden>
      <div v-for="solicitante in notificacion.solicitantes" :key="solicitante.id">
        <KycSolicitanteTemplate :id="solicitante.id" :currentCompareciente="solicitante" :beneficiario_pld="0"
          :beneficiario_cff="0" />
      </div>
    </div>
  </div>
</template>

<script>
import EsperaValidacion from "@/components/EsperaValidacion.vue";
import KycSolicitanteTemplate from '@/components/KycSolicitanteTemplate.vue';
import ModalDomicilio from "@/views/clients/comparecientes/ModalDomicilio.vue";
import CartaInstruccionTemplate from "@/views/clients/procesos/notificaciones/CartaInstruccionTemplate.vue";
import ComplementaNotificado from "@/views/clients/procesos/notificaciones/completaInfo/Notificado.vue";
import ComplementaSolicitantes from "@/views/clients/procesos/notificaciones/completaInfo/Solicitantes.vue";
import EntregaDocumentos from "@/views/clients/procesos/notificaciones/EntregaDocumentos.vue";
import FirmaDocumentos from "@/views/clients/procesos/notificaciones/FirmaDocumentos.vue";
import Pago from "@/views/clients/procesos/notificaciones/Pago.vue";
import TreeEditNotificaciones from "@/views/clients/procesos/notificaciones/TreeEditNotificaciones.vue";
import FacturacionComponent from '@/views/openpay/FacturacionComponent.vue';
import Expediente from '@/views/clients/procesos/notificaciones/Expediente.vue';

import { ComparecientesApi, CotizacionesApi, DomiciliosApi, FuncionesApi, NotificacionesApi, NotificadosApi, LayoutsApi } from "@/api";
import { getGoogleMapUrl } from "@/utils/google-maps-utils";
import Storage from "@/utils/storage";

const storage = new Storage();

export default {
  name: "AddNotificaciones",

  components: {
    ModalDomicilio,
    Pago,
    TreeEditNotificaciones,
    ComplementaNotificado,
    ComplementaSolicitantes,
    EntregaDocumentos,
    FirmaDocumentos,
    CartaInstruccionTemplate,
    KycSolicitanteTemplate,
    FacturacionComponent,
    EsperaValidacion,
    Expediente
  },

  async created() {
    try {
      this.loading = true;
      const id = this.$route.params.id;

      await this.getNotificacion(id);
      await this.getNotificado(id);
      await this.getDomicilio(this.notificado.id_domicilios);
      await this.getDomicilioEnvio();
      await this.getCotizacion(this.notificacion.id_cotizaciones);

      this.getEstatus();
    } catch (error) {
      this.$toast.error("Sucedió un error, intente más tarde", {
        timeout: 4000, //false,
        hideProgressBar: false,
        position: "top-right",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        closeButton: "button",
        icon: true,
      });
      console.log(error);
    } finally {
      this.loading = false;
    }
  },

  beforeRouteLeave(to, from, next) {
    if (to.path.includes("editcomparecientes")) {
      next();
      return;
    }

    this.$refs["modal-confirm-dialog-route"].show();
    this.next = next;
  },

  data: () => ({
    next: null,
    prenode: null,

    loading: false,
    currentComponentBody: "",

    currentDomicilio: {
      tieneExtranjero: false,
      tieneTipo: false,
      compareciente: false,
      extranjero: null,
      notificaciones: true,
      lat: 19.4320605,
      lng: -99.1335938
    },

    domicilioEnvio: {
      tieneExtranjero: false,
      tieneTipo: false,
      compareciente: false,
      extranjero: null,
      ratificacion: true,
      lat: 19.4320605,
      lng: -99.1335938
    },

    notificacion: {
      id: null,
      id_estatus: null,
      id_cotizaciones: null,
      id_clientes: null,
      solicitantes: [],
      documentos: [],
      cartaInstruccion: null,
      notificados: [],
      fad: null,
      acta_express_extra: null,
      envio: null,
      contenido_digital: null,
      corredurias_id: null,
      domicilios_id: null,
      hora_llegada: null,
      forma_cerciorarse: null,
      tipo_lugar: null,
      momento_clave_atendido: null,
      varios_lugares: null,
      otro_lugar: null,
      abierto_publico: null,
      ingreso_numeros_interiores: null,
      lugar_final_persona_atendio: null,
      persona_atendio_sexo: null,
      persona_atendio_edad: null,
      persona_atendio_estatura: null,
      persona_atendio_cabello: null,
      persona_atendio_complexion: null,
      momento_clave_persona_atendio: null,
      domicilio_señalado: null,
      domicilio_persona_buscada: null,
      declaracion_domicilio_negativo: null,
      recibio_comunicacion: null,
      instructivo_firmado: null,
      persona_atendio_nombre_proporcionado: null,
      persona_atendio_nombre: null,
      persona_atendio_identificacion: null,
      persona_atendio_identificacion_tipo_documento: null,
      persona_atendio_identificacion_numero: null,
      procedimiento_notificacion: null,
      hora_notificacion: null,
      persona_atendio_manifestaciones_realizadas: null,
      persona_atendio_manifestaciones: null,
      reporte_fotografico: null,
      validaEnvio: null,
      pagado: null,
      forma_pago: null,
      precio_total: 0,

      currentComponentValues: {
        Ubicacion: false,
        Solicitantes: false,
        Documentos: false,
        Extras: false,
        Cotizacion: false,
        Pago: false,
        EnvioDomicilio: false,
        ComplementaNotificado: false,
        ComplementaSolicitantes: false,
        EntregaDocumentos: false,
        FirmaDocumentos: false,
        Diligencia: false,
        Actas: false,
        Expediente: false,
        Terminado: false,
        EsperaValidacion: false
      },
    },

    notificado: {
      id: null,
      nombre: null,
      paterno: null,
      materno: null,
      razon_social: null,
      persona: null,
      nombre_notificado: null,
      id_notificaciones: null,
      id_domicilios: null,
    },

    datos: {},
    cartaKey: 0,
    datosCompletos: false,

    facturarPago: false,
    datosFacturacion: {
      cotizacionId: null,
      formaPago: null,
      alreadyInvoiced: false,
    },

    headerImgUrl: 'https://correduriadigital.blob.core.windows.net/correduria-digital-storage/layouts/cp_logooficial_color.jpg?sv=2023-01-03&st=2024-02-09T17%3A04%3A42Z&se=2034-02-11T17%3A04%3A00Z&sr=c&sp=rl&sig=rq9SDEcZMPv%2FbgiTq9FRE66lHZvEAeeiMqytpyiH2sk%3D',
  }),

  methods: {
    async beforeLeave(answer) {
      if (answer) {
        this.next();
      }
    },

    async selected(node) {
      const keys = Object.keys(this.notificacion.currentComponentValues);
      const values = Object.values(this.notificacion.currentComponentValues);
      const dest = keys.find(value => value == node);
      const pagoIndex = keys.findIndex(value => value === 'Pago');
      const currentIndex = keys.findIndex(value => value == this.currentComponentBody);
      const destIndex = keys.findIndex(value => value == node);
      const lastCompletedIndex = values.lastIndexOf(true);
      // Ir mas adelante
      if (destIndex > currentIndex) {
        // Dest verde
        if (this.notificacion.currentComponentValues[dest]) {
          this.currentComponentBody = dest;
        }
        // Dest amarillo
        else {
          var lastCompleted = keys[lastCompletedIndex + 1];
          if ((destIndex - lastCompletedIndex) === 1) {
            this.currentComponentBody = dest;
          }
          else {
            if (lastCompletedIndex === -1) {
              lastCompleted = keys[0]
            }
            // this.$toast.warning("Completa la sección " + lastCompleted + " para poder continuar", {
            this.$toast.warning("Completa la sección para poder continuar", {
              timeout: 3000, //false,
              hideProgressBar: false,
              position: "top-right",
              closeOnClick: true,
              pauseOnHover: true,
              draggable: false,
              closeButton: "button",
              icon: true,
            });
          }
        }
      }
      // Ir mas atras
      else {
        if (destIndex > pagoIndex) {
          this.currentComponentBody = dest;
        }
      }
    },

    async getAddressInMap(direcciones_id) {
      if (direcciones_id === null) {
        const address = `${this.currentDomicilio.calle},
                      ${this.currentDomicilio.num_exterior},
                      ${this.currentDomicilio.colonia},
                      ${this.currentDomicilio.delegacion_municipio},
                      ${this.currentDomicilio.codigo_postal},
                      ${this.currentDomicilio.ciudad},
                      ${this.currentDomicilio.entidad}
                      `;
        const mapUrl = await getGoogleMapUrl(address);
        if (mapUrl === null) {
          return
        }
        // this.currentDomicilio.mapUrl = mapUrl;
        this.currentDomicilio.lat = mapUrl.lat.toString(); //parseFloat(mapUrl.lat);
        this.currentDomicilio.lng = mapUrl.lng.toString(); //parseFloat(mapUrl.lng);
        var a = Object.assign({}, this.currentDomicilio);
        this.currentDomicilio = a;
      }
    },

    async getAddressInMapEnvio(direcciones_id) {
      if (direcciones_id === null) {
        const address = `${this.domicilioEnvio.calle},
                      ${this.domicilioEnvio.num_exterior},
                      ${this.domicilioEnvio.colonia},
                      ${this.domicilioEnvio.delegacion_municipio},
                      ${this.domicilioEnvio.codigo_postal},
                      ${this.domicilioEnvio.ciudad},
                      ${this.domicilioEnvio.entidad}
                      `;
        const mapUrl = await getGoogleMapUrl(address);

        if (!mapUrl) {
          return;
        }

        // this.domicilioEnvio.mapUrl = mapUrl;
        this.domicilioEnvio.lat = mapUrl.lat.toString(); //parseFloat(mapUrl.lat);
        this.domicilioEnvio.lng = mapUrl.lng.toString(); //parseFloat(mapUrl.lng);

        const a = Object.assign({}, this.domicilioEnvio);

        this.domicilioEnvio = a;
      }
    },

    async getNotificacion(id) {
      this.notificacion = {
        ...this.notificacion,
        ...await NotificacionesApi.detail(id)
      };
    },

    async getNotificado(id) {
      const { value } = await NotificadosApi.list({ id_notificaciones: id });

      this.notificado.id = value[0].id;
      this.notificado.nombre = value[0].nombre;
      this.notificado.paterno = value[0].paterno;
      this.notificado.materno = value[0].materno;
      this.notificado.razon_social = value[0].razon_social;
      this.notificado.persona = value[0].persona;
      this.notificado.nombre_notificado = value[0].nombre_notificado;
      this.notificado.id_domicilios = value[0].id_domicilios;
      this.notificado.id_notificaciones = value[0].id_notificaciones;

      if (this.notificado.nombre || this.notificado.razon_social) {
        this.notificacion.notificados = value;
      }
    },

    async getDomicilio(id_domicilios) {
      const {
        calle,
        num_exterior,
        num_interior,
        colonia,
        delegacion_municipio,
        ciudad,
        entidad,
        pais,
        codigo_postal,
        lat,
        lng,
      } = await DomiciliosApi.detail(id_domicilios);

      this.currentDomicilio.calle = calle;
      this.currentDomicilio.num_exterior = num_exterior;
      this.currentDomicilio.num_interior = num_interior;
      this.currentDomicilio.colonia = colonia;
      this.currentDomicilio.delegacion_municipio = delegacion_municipio;
      this.currentDomicilio.ciudad = ciudad;
      this.currentDomicilio.entidad = entidad;
      this.currentDomicilio.pais = pais;
      this.currentDomicilio.codigo_postal = codigo_postal;
      this.currentDomicilio.lat = lat;
      this.currentDomicilio.lng = lng;
    },

    async getDomicilioEnvio() {
      this.domicilioEnvio = {
        ...this.domicilioEnvio,
        ...await DomiciliosApi.detail(this.notificacion.domicilios_id)
      }
    },

    async getCotizacion(id_cotizacion) {
      const { precio_total, pagado, forma_pago } = await CotizacionesApi.detail(id_cotizacion);

      this.notificacion.precio_total = precio_total;
      this.notificacion.pagado = pagado;
      this.notificacion.forma_pago = forma_pago;
    },

    getEstatus() {
      const keys = Object.keys(this.notificacion.currentComponentValues);
      let currentIndex = keys.findIndex(value => value === 'Pago');

      if (this.notificacion.id_estatus == 8 && this.notificacion.envio == 1) {
        this.currentComponentBody = "EnvioDomicilio";
        currentIndex = keys.findIndex(value => value === 'EnvioDomicilio')
      }
      if (this.notificacion.id_estatus == 8 && (this.notificacion.envio == 0 || this.notificacion.domicilios_id != null)) {
        this.domicilioEnvio.disabled = true;
        this.currentComponentBody = "ComplementaNotificado";
        currentIndex = keys.findIndex(value => value === 'ComplementaNotificado')
      }
      if (this.notificacion.id_estatus == 8 && (this.notificado.nombre || this.notificado.razon_social)) {
        this.notificado.aceptado = true;
        this.currentComponentBody = "ComplementaSolicitantes";
        currentIndex = keys.findIndex(value => value === 'ComplementaSolicitantes')
      }
      if (this.notificacion.id_estatus == 22) {
        this.notificado.aceptado = true;
        this.currentComponentBody = "EntregaDocumentos";
        currentIndex = keys.findIndex(value => value === 'EntregaDocumentos')
      }
      if (this.notificacion.id_estatus == 10) {
        this.notificado.aceptado = true;
        this.currentComponentBody = "EsperaValidacion";
        currentIndex = keys.findIndex(value => value === 'FirmaDocumentos')
      }
      if (this.notificacion.id_estatus == 13) {
        this.currentComponentBody = "Terminado";
        // currentIndex = keys.findIndex(value => value === 'FirmaDocumentos')
      }

      for (let i = 0; i < currentIndex; i++) {
        this.notificacion.currentComponentValues[keys[i]] = true;
      }
    },

    payCotizacion(payed) {
      if (payed == true) {
        this.generaPago();
      }
    },

    payCotizacionSpei(payed) {
      if (payed) {
        this.$toast.warning("Recibirá la información para la transferencia en su correo electrónico.", {
          timeout: 10000,
          hideProgressBar: false,
          position: "top-right",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          closeButton: "button",
          icon: true
        });
      } else {
        this.$toast.error("Sucedió un error, intente más tarde", {
          timeout: 4000, //false,
          hideProgressBar: false,
          position: "top-right",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          closeButton: "button",
          icon: true
        });
      }

      this.loading = false;
    },

    async generaPago(isMonedero = false) {
      try {
        // Cambia el estatus de la notificacion a pagado
        this.loading = true;

        const { id_estatus } = await NotificacionesApi.edit(this.notificacion.id, {
          id_estatus: 8,
          id_cotizaciones: this.notificacion.id_cotizaciones,
          id_clientes: this.notificacion.id_clientes,
          fad: this.notificacion.fad,
          acta_express_extra: this.notificacion.acta_express_extra,
          envio: this.notificacion.envio,
          validaSolicitantes: 0,
          validaDomicilio: 0,
          validaNotificado: 0,
          validaDocumentos: 0,
          validaExtras: 0,
          activo: 1,
          contenido_digital: this.notificacion.contenido_digital,
          validaEnvio: this.notificacion.validaEnvio,
          domicilios_id: this.notificacion.domicilios_id,
          corredurias_id: this.notificacion.corredurias_id
        });

        this.notificacion.id_estatus = id_estatus;
        this.notificacion.currentComponentValues.Pago = true;
        this.loading = false;

        if (!isMonedero) {
          this.$refs['confirmar-facturacion-modal'].show();

          return;
        }

        if (this.notificacion.envio == 1) {
          this.currentComponentBody = "EnvioDomicilio"
        } else {
          this.currentComponentBody = "ComplementaNotificado"
        }
      } catch (error) {
        this.loading = false;
        this.$toast.error("Sucedió un error, intente más tarde", {
          timeout: 4000, //false,
          hideProgressBar: false,
          position: "top-right",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          closeButton: "button",
          icon: true,
        });
        console.log(error);
      }
    },

    confirmGenerarFactura(crearFactura) {
      this.$refs['confirmar-facturacion-modal'].hide();

      if (!crearFactura) {
        if (this.notificacion.envio == 1) {
          this.currentComponentBody = "EnvioDomicilio"
        } else {
          this.currentComponentBody = "ComplementaNotificado"
        }

        return;
      }

      this.datosFacturacion.cotizacionId = this.notificacion.id_cotizaciones;
      this.datosFacturacion.alreadyInvoiced = false;

      this.$refs["datos-fiscales-modal"].show();
    },

    async postDomicilioEnvio() {
      try {
        this.loading = true;

        const { id } = await DomiciliosApi.create({
          calle: this.domicilioEnvio.calle,
          num_exterior: this.domicilioEnvio.num_exterior,
          num_interior: this.domicilioEnvio.num_interior,
          colonia: this.domicilioEnvio.colonia,
          delegacion_municipio: this.domicilioEnvio.delegacion_municipio,
          ciudad: this.domicilioEnvio.ciudad,
          entidad: this.domicilioEnvio.entidad,
          pais: this.domicilioEnvio.pais,
          codigo_postal: this.domicilioEnvio.codigo_postal,
          extranjero: 0,
          tipo: 'Personal',
          tieneExtranjero: null,
          tieneTipo: null,
          lat: this.domicilioEnvio.lat,
          lng: this.domicilioEnvio.lng,
          valido: 1,
          comentario: null,
          validador: null,
          activo: 1
        });
        this.notificacion.domicilios_id = id;
        const { id_estatus } = await NotificacionesApi.edit(this.notificacion.id, {
          id_estatus: 8,
          id_cotizaciones: this.notificacion.id_cotizaciones,
          id_clientes: this.notificacion.id_clientes,
          fad: this.notificacion.fad,
          acta_express_extra: this.notificacion.acta_express_extra,
          envio: this.notificacion.envio,
          validaSolicitantes: 0,
          validaDomicilio: 0,
          validaNotificado: 0,
          validaDocumentos: 0,
          validaExtras: 0,
          activo: 1,
          contenido_digital: this.notificacion.contenido_digital,
          validaEnvio: this.notificacion.validaEnvio,
          domicilios_id: this.notificacion.domicilios_id,
          corredurias_id: this.notificacion.corredurias_id
        });
        this.notificacion.currentComponentValues.EnvioDomicilio = true;
        this.currentComponentBody = 'ComplementaNotificado';
      } catch (error) {
        this.$toast.error("Sucedió un error, intente más tarde", {
          timeout: 4000, //false,
          hideProgressBar: false,
          position: "top-right",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          closeButton: "button",
          icon: true,
        });
      } finally {
        this.loading = false;
      }
    },

    async saveComplementaNotificado() {
      if (this.notificacion.notificados.length == 0) {
        this.$toast.warning("Debe ingresar al menos 1 notificado.", {
          timeout: 4000, //false,
          hideProgressBar: false,
          position: "top-right",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          closeButton: "button",
          icon: true,
        });
        return;
      }

      this.loading = true;
      try {
        // Agrega los datos del notificado a BD
        await NotificadosApi.edit(this.notificado.id, {
          "nombre": this.notificacion.notificados[0].nombre,
          "paterno": this.notificacion.notificados[0].paterno,
          "materno": this.notificacion.notificados[0].materno,
          "razon_social": this.notificacion.notificados[0].razon_social,
          "persona": this.notificacion.notificados[0].persona,
          "nombre_notificado": this.notificacion.notificados[0].nombre_notificado,
          "id_notificaciones": this.notificado.id_notificaciones,
          "id_domicilios": this.notificado.id_domicilios,
          "activo": 1,
          "contenido_digital": this.notificacion.contenido_digital
        });

        if (this.notificacion.notificados.length > 1) {
          this.createNewNotificados();
        }

        this.notificacion.currentComponentValues.ComplementaNotificado = true;
        this.currentComponentBody = "ComplementaSolicitantes"
        this.loading = false;
        this.notificacion.aceptado = true;
      } catch (error) {
        console.log(error);
        this.$toast.error("Sucedió un error, intente más tarde", {
          timeout: 4000, //false,
          hideProgressBar: false,
          position: "top-right",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          closeButton: "button",
          icon: true,
        });
      } finally {
        this.loading = false;
      }
    },

    async createNewNotificados() {
      for (let i = 1; i < this.notificacion.notificados.length; i++) {
        const { id } = await NotificadosApi.create({
          id_notificaciones: this.notificado.id_notificaciones,
        });
        this.editNewNotificado(id, i);
      }
    },

    async editNewNotificado(id, i) {
      await NotificadosApi.edit(id, {
        "nombre": this.notificacion.notificados[i].nombre,
        "paterno": this.notificacion.notificados[i].paterno,
        "materno": this.notificacion.notificados[i].materno,
        "razon_social": this.notificacion.notificados[i].razon_social,
        "persona": this.notificacion.notificados[i].persona,
        "nombre_notificado": this.notificacion.notificados[i].nombre_notificado,
        "id_notificaciones": this.notificado.id_notificaciones,
        "id_domicilios": this.notificado.id_domicilios,
        "activo": 1,
        "contenido_digital": this.notificacion.contenido_digital
      });
    },

    async actualizaCompareciente(compareciente) {
      try {
        const comparecienteToUpdate = {
          'nombre': compareciente.nombre,
          'paterno': compareciente.paterno,
          'fecha_nacimiento': compareciente.fecha_nacimiento,
          'curp': compareciente.curp,
          'rfc': compareciente.rfc,
          'ocupacion': compareciente.ocupacion,
          'lugar_nacim_ciudad': compareciente.lugar_nacim_ciudad,
          'lugar_nacim_estado': compareciente.lugar_nacim_estado,
          'lugar_nacim_pais': compareciente.lugar_nacim_pais,
          'denominacion_social': compareciente.denominacion_social,
          'fecha_constitucion': compareciente.fecha_constitucion,
          'tipo_validacion': compareciente.tipo_validacion,
          'cliente_id': compareciente.cliente_id,
          'persona': compareciente.persona,
          'telefonos': compareciente.telefonos,
          'email': compareciente.email,
          'activo': 1
        };

        await ComparecientesApi.edit(compareciente.id, comparecienteToUpdate);
      } catch (error) {
        this.$toast.error("Sucedió un error, intente más tarde", {
          timeout: 4000, //false,
          hideProgressBar: false,
          position: "top-right",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          closeButton: "button",
          icon: true,
        });
        console.log(error);
      }
    },

    async saveComplementaSolicitantes() {
      this.loading = true;

      // Busca los solicitantes que hay que modificar
      // const solicitantesModificados = [];

      // this.notificacion.solicitantes.forEach(solicitante => {
      //   if (solicitante.editado) {
      //     solicitantesModificados.push(solicitante);
      //   }
      // });

      // Modifica los solicitantes encontrados
      // for (const solicitanteModificado of solicitantesModificados) {
      //   await this.actualizaCompareciente(solicitanteModificado);
      // };

      // Cambia el estatus de la notificacion a Informacion Completada
      const { id_estatus } = await NotificacionesApi.edit(this.notificacion.id, {
        id_estatus: 22,
        id_cotizaciones: this.notificacion.id_cotizaciones,
        id_clientes: this.notificacion.id_clientes,
        fad: this.notificacion.fad,
        acta_express_extra: this.notificacion.acta_express_extra,
        envio: this.notificacion.envio,
        validaSolicitantes: 0,
        validaDomicilio: 0,
        validaNotificado: 0,
        validaDocumentos: 0,
        validaExtras: 0,
        activo: 1,
        contenido_digital: this.notificacion.contenido_digital,
        validaEnvio: this.notificacion.validaEnvio,
        domicilios_id: this.notificacion.domicilios_id,
        corredurias_id: this.notificacion.corredurias_id
      });
      this.notificacion.id_estatus = id_estatus;

      // await this.generaKycSolicitantes();
      // this.generaDatosParaCartaDeInstruccion();
      // await this.generaCartaDeInstruccion();

      this.loading = false;
      this.notificacion.currentComponentValues.ComplementaSolicitantes = true;
      this.currentComponentBody = "EntregaDocumentos";
    },

    generaDatosParaCartaDeInstruccion() {
      try {
        let nombre_completo_solicitante_principal = '';

        if (this.notificacion.solicitantes[0].persona === 'Física') {
          nombre_completo_solicitante_principal =
            (this.notificacion.solicitantes[0].nombre + ' ' + this.notificacion.solicitantes[0].paterno + ' '
              + (this.notificacion.solicitantes[0].materno || "")).trim();
        } else {
          nombre_completo_solicitante_principal = this.notificacion.solicitantes[0].denominacion_social.trim();
        }

        // Fecha
        const optionsMonth = { month: 'long' };
        const optionsDay = { month: '2-digit' };
        const today = new Date();
        const yyyy = today.getFullYear().toString();
        const formatedMonth = today.toLocaleDateString('es-MX', optionsMonth);
        const formatedDay = today.toLocaleDateString('es-MX', optionsDay);

        let notificados = '';

        this.notificacion.notificados.forEach(notificado => {
          let nombreCompletoTemp = "";

          if (notificado.persona === 'Física') {
            nombreCompletoTemp = notificado.nombre + ' ' + notificado.paterno + (notificado.materno + "");
          } else {
            nombreCompletoTemp = notificado.razon_social;
          }

          notificados = notificados + nombreCompletoTemp;

          const index = this.notificacion.notificados.findIndex(o => o.id === notificado.id);

          if (index != this.notificacion.notificados.length - 1) {
            notificados = notificados + ', '
          }
        });

        let num_interior_string = "";

        if (this.currentDomicilio.num_interior) {
          num_interior_string = ` ${this.currentDomicilio.num_interior}`;
        }

        const domicilio_string = `${this.currentDomicilio.calle} ${this.currentDomicilio.num_exterior}` +
          `${num_interior_string}, Col. ${this.currentDomicilio.colonia}, ` +
          `${this.currentDomicilio.delegacion_municipio}, ${this.currentDomicilio.entidad}, ` +
          `${this.currentDomicilio.ciudad || ""}, C.P. ${this.currentDomicilio.codigo_postal}, ${this.currentDomicilio.pais}`

        let apoderado = null;
        let plural = false;
        let numeroRepresentantes = 0;

        if (this.notificacion.solicitantes[0].persona === 'Moral') {
          apoderado = '';

          this.notificacion.solicitantes.forEach(solicitante => {
            if (this.notificacion.solicitantes[0].id == solicitante.moral_representada_id) {
              if (solicitante.persona === 'Física') {
                apoderado = solicitante.nombre + ' ' + solicitante.paterno + (solicitante.materno || "");
                numeroRepresentantes++;
              } else {
                apoderado = solicitante.denominacion_social;
              }
            }
          });

          if (this.notificacion.solicitantes.length > 2) {
            plural = true;
          }
        } else {
          if (this.notificacion.solicitantes.length > 1) {
            plural = true;
          }
        }

        const datos = {
          compareciente_principal: nombre_completo_solicitante_principal,
          day: formatedDay,
          month: formatedMonth,
          year: yyyy,
          notificados: notificados,
          domicilio: domicilio_string,
          apoderado: apoderado,
          plaza: this.currentDomicilio.entidad,
          plural: plural,
          fad: this.notificacion.fad
        }

        this.cartaKey += 1;
        this.datos = datos;
        this.cartaKey += 1;
        // debugger;
        // setTimeout(() => {
        //   this.generaCartaDeInstruccion(); NOTE Invoco la funcion afuera de de esta funcion, con un await en lugar de un timeout
        //  }, 6000);
      } catch (error) {
        console.log(error);
        this.$toast.error("Sucedió un error, intente más tarde", {
          timeout: 4000, //false,
          hideProgressBar: false,
          position: "top-right",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          closeButton: "button",
          icon: true,
        });
      }
    },

    async generaCartaDeInstruccion() {
      await this.$nextTick();

      const htmlContent = this.$refs.cartaInstruccion.outerHTML;
      const style_pdf =
        `<style>
              .new-page {
                page-break-before: always;
              }
              .tabla {
                  width: 100%;
                  font-size: 14px;
                  font-family: Arial, Helvetica, sans-serif;
                  border-collapse: separate;
                  margin-top: 10px;
              }
              .section {
                  text-align: center;
                  height: 40px;
              }
              .date {
                  text-align: right;
                  height: 40px;
              }
              .paragraph {
                  padding-bottom: 2em;
              }
        </style>`
      let htmlToConvertPdf = `<!DOCTYPE html>
              <html>
              <head>
                <meta charset="utf-8">
                ${style_pdf}
              </head>
              <body>
              ${htmlContent}
              </body>
              </html>
              `;
      const regex = /<body>\s*<div data-v-[a-z0-9]*="" hidden="hidden"><div data-v-[a-z0-9]*=""><table class="tabla">/;
      const regex2 = /<\/table><\/div><\/div>\s*<\/body>/;

      htmlToConvertPdf = htmlToConvertPdf.replace('//')
      htmlToConvertPdf = htmlToConvertPdf.replace(regex, '<body><table class="tabla">');
      htmlToConvertPdf = htmlToConvertPdf.replace(regex2, '</table> </body>');

      const buffer = Buffer.from(htmlToConvertPdf);
      const buffer_str = buffer.toString("base64");

      const dataNotificacionHtml = {
        proceso_id: this.notificacion.id,
        cat_servicios_id: 2,
        html: buffer_str
      }

      try {
        this.loading = true;
        const documentosCartaInstruccion = await FuncionesApi.generaKyc(dataNotificacionHtml);
        // console.log(documentosCartaInstruccion);
        this.notificacion.cartaInstruccion = documentosCartaInstruccion[0];
        this.notificacion.currentComponentValues.ComplementaSolicitantes = true;
        this.currentComponentBody = "EntregaDocumentos";
      } catch (error) {
        this.$toast.error("Sucedió un error, intente más tarde", {
          timeout: 4000, //false,
          hideProgressBar: false,
          position: "top-right",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          closeButton: "button",
          icon: true,
        });
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    async generaKycSolicitantes() {
      this.datosCompletos = true;
      /**
       * Se invoca la funcion nextTick para que el DOM se actualice con el componente KycSolicitanteTemplate
       * despues de colocar la propiedad datosCompletos en true
       */
      await this.$nextTick();

      // css y plantilla html minificados, para editarlos usar una herramienta que lo estilice y despues volver a minificarlos
      const style_pdf =
        `<style>.maps{text-align:center;vertical-align:middle}.new-page{page-break-before:always}.logo{width:auto;height:100px;float:right}.tabla{width:100%;font-size:15px;font-family:Arial,Helvetica,sans-serif;border-collapse:separate;border-spacing:0 1em}.section{background-color:#16243C;color:#fff;text-align:center;height:40px;font-weight:bolder}.section-empty{text-align: center;font-weight: bolder;font-size: 18px;}.firstline{border:5px solid #fff}.title{font-size:12px;text-align:left;width:20%;font-weight:700}.select{text-align:left;width:5%}.circle{border-radius:100%;height:15px;width:15px;border:2px solid #16243C;margin-bottom:0}.filledcircle{border-radius:100%;height:15px;width:15px;border:2px solid #16243C;background-color:#16243C;margin-bottom:0}.verifycheck{border:2px solid #16243C;background-color:#e7eef5;text-align:center}.right-title{font-size:12px;text-align:right;font-weight:700}.user{text-align:left;font-size:12px;font-weight:lighter;border-bottom:solid 1px #000;background-color:#e7eef5}.leyenda{font-size:9px}</style>`;
      const htmlTemplate =
        `<!DOCTYPE html><html><head><meta charset="utf-8">_stylePlaceholder</head><body>_contentPlaceholder</body></html>`;
      const regex = /data-v-[a-z0-9]*=""/g;
      // const regex2 = /<\/table><\/div><\/div>\s*<\/body>/;
      // const solicitantes = this.$refs.KycSolicitanteTemplate.children;
      const htmlKycs = [];

      for (const solicitante of this.notificacion.solicitantes) {
        const solicitanteId = solicitante.id;
        const element = document.getElementById(solicitanteId);
        const htmlContent = element.outerHTML;
        let htmlToConvertPdf = htmlTemplate
          .replace("_stylePlaceholder", style_pdf)
          .replace("_contentPlaceholder", htmlContent)
          .replace('hidden="hidden"', '')
          .replace(regex, '');
        // console.log(Buffer.from(htmlToConvertPdf).toString("utf8"));
        const buffer_str = Buffer
          .from(htmlToConvertPdf)
          .toString("base64");
        const solicitanteToPush = {
          comparecientes_id: solicitanteId,
          html: buffer_str
        };
        htmlKycs.push(solicitanteToPush)
      }

      const dataNotificacionHtml = {
        proceso_id: this.notificacion.id,
        cat_servicios_id: 2,
        html: "kyc",
        comparecientes: htmlKycs
      };

      try {
        const kycsGenerados = await FuncionesApi.generaKyc(dataNotificacionHtml);
      } catch (error) {
        console.log(error);

        this.$toast.error("Sucedió un error, intente más tarde.", {
          timeout: 4000, //false,
          hideProgressBar: false,
          position: "top-right",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          closeButton: "button",
          icon: true,
        });
      }
    },

    async saveEntregaDocumentos() {
      this.loading = true;
      try {
        const { id_estatus } = await NotificacionesApi.edit(this.notificacion.id, {
          id_estatus: 10,
          id_cotizaciones: this.notificacion.id_cotizaciones,
          id_clientes: this.notificacion.id_clientes,
          fad: this.notificacion.fad,
          acta_express_extra: this.notificacion.acta_express_extra,
          envio: this.notificacion.envio,
          validaSolicitantes: 0,
          validaDomicilio: 0,
          validaNotificado: 0,
          validaDocumentos: 0,
          validaExtras: 0,
          activo: 1,
          contenido_digital: this.notificacion.contenido_digital,
          validaEnvio: this.notificacion.validaEnvio,
          domicilios_id: this.notificacion.domicilios_id,
          corredurias_id: this.notificacion.corredurias_id
        });
        this.notificacion.id_estatus = id_estatus;
        this.notificacion.currentComponentValues.EntregaDocumentos = true;
        this.notificacion.currentComponentValues.EsperaValidacion = true;
        this.currentComponentBody = 'EsperaValidacion'
        this.$toast.success("Los documentos fueron entregados correctamente y serán revisados por un corredor. Posteriormente recibirá un mail con las instrucciones para la Firma Digital de sus documentos.", {
          timeout: 40000, //false,
          hideProgressBar: false,
          position: "top-right",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          closeButton: "button",
          icon: true,
        });

        this.sendMailInformacionEntregada();
      } catch (error) {
        this.$toast.error("Sucedió un error, intente más tarde", {
          timeout: 4000, //false,
          hideProgressBar: false,
          position: "top-right",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          closeButton: "button",
          icon: true,
        });
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    async sendMailInformacionEntregada() {
      try {
        let nombre_completo_solicitante_principal = '';

        if (this.notificacion.solicitantes[0].persona && this.notificacion.solicitantes[0].persona === 'Física') {
          nombre_completo_solicitante_principal = this.notificacion.solicitantes[0].nombre + ' ' + this.notificacion.solicitantes[0].paterno;
          if (this.notificacion.solicitantes[0].materno) {
            nombre_completo_solicitante_principal = nombre_completo_solicitante_principal + ' ' + this.notificacion.solicitantes[0].materno;
          }
        } else {
          nombre_completo_solicitante_principal = this.notificacion.solicitantes[0].denominacion_social;
        }

        const layout = await LayoutsApi.detail(24);

        layout.body = layout.body.replace('{nombre}', nombre_completo_solicitante_principal);

        await FuncionesApi.sendMail({
          emailTo: storage.getItem("user"),
          body: layout.body,
          subject: layout.subject
        });

        // this.$toast.success('Se ha notificado por correo.', {
        //     timeout: 40000, //false,
        //     hideProgressBar: false,
        //     position: "top-right",
        //     closeOnClick: true,
        //     pauseOnHover: true,
        //     draggable: false,
        //     closeButton: "button",
        //     icon: true,
        //   });
      } catch (error) {
        console.log(error);
      }
    },

    closedFacturacion(event) {
      // this.facturarPago = true;

      if (this.notificacion.envio == 1) {
        this.currentComponentBody = "EnvioDomicilio"
      } else {
        this.currentComponentBody = "ComplementaNotificado"
      }

      this.$refs['datos-fiscales-modal'].hide();
    },

    canceledFacturacion() {
      if (this.notificacion.envio == 1) {
        this.currentComponentBody = "EnvioDomicilio"
      } else {
        this.currentComponentBody = "ComplementaNotificado"
      }

      this.$refs['datos-fiscales-modal'].hide();
    }
  }
};
</script>

<style scoped></style>
