var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content" },
    [
      _c(
        "b-breadcrumb",
        [
          _c(
            "b-breadcrumb-item",
            { attrs: { to: { path: "/dashboard/corredurias" } } },
            [_vm._v(" Corredurías ")]
          ),
          _c("b-breadcrumb-item", { attrs: { active: "" } }, [
            _vm._v("Editar correduría"),
          ]),
        ],
        1
      ),
      _c("div", { staticClass: "container" }, [
        _vm.loading
          ? _c("div", [
              _c(
                "div",
                { attrs: { id: "loader" } },
                [
                  _c("font-awesome-icon", {
                    attrs: { icon: "spinner", spin: "" },
                  }),
                ],
                1
              ),
            ])
          : _vm._e(),
      ]),
      _c("div", { staticClass: "wrapper wrapper-full-page section content" }, [
        _c(
          "div",
          { staticClass: "container" },
          [
            _c(
              "card",
              { attrs: { id: "card" } },
              [
                _c("ValidationObserver", {
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ handleSubmit }) {
                        return [
                          _c(
                            "form",
                            {
                              on: {
                                submit: function ($event) {
                                  $event.preventDefault()
                                  return handleSubmit(_vm.saveCorreduria)
                                },
                                change: function ($event) {
                                  return _vm.getAddressInMap(null)
                                },
                              },
                            },
                            [
                              _c("Form", {
                                attrs: { correduria: _vm.correduria },
                                on: {
                                  submit: function ($event) {
                                    $event.preventDefault()
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }