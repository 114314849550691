var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content" },
    [
      _c(
        "b-breadcrumb",
        [
          _c(
            "b-breadcrumb-item",
            { attrs: { to: { path: "/dashboard/settings" } } },
            [_vm._v("Configuraciones")]
          ),
          _c("b-breadcrumb-item", { attrs: { active: "" } }, [
            _vm._v("Detalle de cupón"),
          ]),
        ],
        1
      ),
      _c("div", { staticClass: "container" }, [
        _vm.loading
          ? _c("div", [
              _c(
                "div",
                { attrs: { id: "loader" } },
                [
                  _c("font-awesome-icon", {
                    attrs: { icon: "spinner", spin: "" },
                  }),
                ],
                1
              ),
            ])
          : _vm._e(),
      ]),
      _c(
        "div",
        { staticClass: "container-fluid" },
        [
          _c("b-card", { staticClass: "mt-3" }, [
            _c(
              "div",
              { staticClass: "mw-75 px-3 justify-content-center" },
              [
                _c("ModalCupon", {
                  attrs: { isEmbedded: true, currentCupon: _vm.cupon },
                  on: {
                    putCupon: function ($event) {
                      return _vm.putCupon($event)
                    },
                  },
                }),
              ],
              1
            ),
          ]),
          _c("b-card", { staticClass: "mt-3" }, [
            _c(
              "div",
              [
                _c("b-table", {
                  attrs: {
                    hover: "",
                    fields: _vm.fieldsCupones,
                    items: _vm.aplicaciones,
                    busy: _vm.isTableBusy,
                    "sort-by": _vm.sortBy,
                    "sort-desc": _vm.sortDesc,
                  },
                  on: {
                    "update:sortBy": function ($event) {
                      _vm.sortBy = $event
                    },
                    "update:sort-by": function ($event) {
                      _vm.sortBy = $event
                    },
                    "update:sortDesc": function ($event) {
                      _vm.sortDesc = $event
                    },
                    "update:sort-desc": function ($event) {
                      _vm.sortDesc = $event
                    },
                    "sort-changed": _vm.sortingChanged,
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "table-busy",
                      fn: function () {
                        return [
                          _c(
                            "div",
                            { staticClass: "text-center my-2" },
                            [
                              _c("b-spinner", { staticClass: "align-middle" }),
                              _c("strong", [_vm._v("Cargando...")]),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                    {
                      key: "cell(index)",
                      fn: function ({ index }) {
                        return [
                          _vm._v(" " + _vm._s(_vm.getIndex(index + 1)) + " "),
                        ]
                      },
                    },
                    {
                      key: "cell(precioTotal)",
                      fn: function (data) {
                        return [
                          _vm._v(
                            " " + _vm._s(_vm.formatTotalPrice(data.value)) + " "
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _c("div", { staticClass: "d-flex my-2" }, [
                  _c("span", [
                    _vm._v(" Ordenado por: "),
                    _c("b", [_vm._v(_vm._s(_vm.sortBy))]),
                    _vm._v(", en dirección: "),
                    _c("b", [
                      _vm._v(
                        _vm._s(_vm.sortDesc ? "descendente" : "ascendente")
                      ),
                    ]),
                  ]),
                ]),
                _c(
                  "div",
                  { staticClass: "d-flex justify-content-between pages" },
                  [
                    _c("b-pagination", {
                      attrs: {
                        "per-page": _vm.perPage,
                        "total-rows": _vm.total,
                      },
                      model: {
                        value: _vm.currentPage,
                        callback: function ($$v) {
                          _vm.currentPage = $$v
                        },
                        expression: "currentPage",
                      },
                    }),
                    _c("span", [
                      _vm._v(
                        "Mostrando " +
                          _vm._s(_vm.perPage) +
                          " registros de " +
                          _vm._s(_vm.total)
                      ),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }