var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container" }, [
    _c("h4", { staticClass: "cardD" }, [_vm._v("Estatus de Documentos")]),
    _c(
      "div",
      { staticClass: "mt-4" },
      [
        _c("ValidationProvider", {
          attrs: {
            name: "estatus",
            rules: "required",
            "custom-messages": {
              required: "El estatus de documentos es obligatorio",
            },
          },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function ({ errors }) {
                return [
                  _c("label", [_vm._v("Estatus")]),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.ratificacion.estatusDocumentos,
                          expression: "ratificacion.estatusDocumentos",
                        },
                      ],
                      staticClass: "custom-select",
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.ratificacion,
                            "estatusDocumentos",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                      },
                    },
                    [
                      _c("option", { attrs: { value: "ok" } }, [
                        _vm._v("OK. Se puede continuar con el proceso."),
                      ]),
                      _c("option", { attrs: { value: "no ok" } }, [
                        _vm._v("No OK. Ponerse en contacto con el cliente."),
                      ]),
                      _c("option", { attrs: { value: "pago" } }, [
                        _vm._v("OK. Requiere pago complementario."),
                      ]),
                    ]
                  ),
                  _c("span", { staticClass: "cardErrors" }, [
                    _vm._v(_vm._s(errors[0])),
                  ]),
                ]
              },
            },
          ]),
        }),
      ],
      1
    ),
    _c("div", { staticClass: "mt-4" }, [
      _c(
        "div",
        {},
        [
          _c(
            "b-button",
            {
              staticClass: "btn-fill orange-btn bottom2",
              attrs: {
                type: "submit",
                size: "m",
                disabled: _vm.ratificacion.estatus_id > 37,
              },
            },
            [_vm._v("Continuar")]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }