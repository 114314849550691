var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c(
        "b-modal",
        {
          ref: "notificado-modal",
          attrs: {
            "no-close-on-backdrop": "",
            centered: "",
            title: "",
            "hide-footer": "",
            id: "modalC",
            size: "xl",
          },
        },
        [
          _c("ValidationObserver", {
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ handleSubmit }) {
                  return [
                    _c(
                      "form",
                      {
                        on: {
                          submit: function ($event) {
                            $event.preventDefault()
                            _vm.currentNotificado.nuevo
                              ? handleSubmit(_vm.completeCurrentNotificado)
                              : handleSubmit(
                                  _vm.editExistentNotificadoFromCurrent
                                )
                          },
                        },
                      },
                      [
                        _c("ModalCompletaNotificado", {
                          attrs: {
                            currentNotificado: _vm.currentNotificado,
                            notificacion: _vm.notificacion,
                          },
                          on: {
                            submit: function ($event) {
                              $event.preventDefault()
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          ref: "modal-confirm-delete",
          attrs: {
            "no-close-on-backdrop": "",
            centered: "",
            "hide-footer": "",
            id: "modal-confirm-delete",
          },
        },
        [
          _c("h4", { staticClass: "text-center" }, [
            _vm._v("¿Seguro que quiere eliminar al notificado?"),
          ]),
          _c("h5", { staticClass: "text-center" }),
          _c(
            "b-button",
            {
              staticClass: "mt-3",
              attrs: { variant: "danger" },
              on: {
                click: function ($event) {
                  return _vm.$bvModal.hide("modal-confirm-delete")
                },
              },
            },
            [_vm._v("Cancelar")]
          ),
          _c(
            "b-button",
            {
              staticClass: "mt-3 float-right",
              attrs: { variant: "primary" },
              on: { click: _vm.deleteNotificadoFromTable },
            },
            [_vm._v("OK")]
          ),
        ],
        1
      ),
      _c("h4", { staticClass: "cardD" }, [
        _vm._v("Información de los Notificados"),
      ]),
      !_vm.notificacion.staff
        ? _c(
            "div",
            { staticClass: "mt-4" },
            [
              _c("label", { attrs: { for: "" } }, [_vm._v("Agregar nuevo")]),
              _c(
                "b-button",
                {
                  staticClass: "btn-fill spaceL",
                  attrs: { variant: "success", size: "sm" },
                  on: { click: _vm.openNotificadoModalVacio },
                },
                [_vm._v("+")]
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        [
          _c("b-table", {
            ref: "table",
            attrs: {
              hover: "",
              fields: _vm.fieldsCuadro,
              items: _vm.notificacion.notificados,
            },
            scopedSlots: _vm._u([
              {
                key: "cell(acciones)",
                fn: function ({ item }) {
                  return [
                    _c(
                      "b-button",
                      {
                        directives: [
                          {
                            name: "b-tooltip",
                            rawName: "v-b-tooltip.hover",
                            modifiers: { hover: true },
                          },
                        ],
                        staticClass: "editBtn",
                        attrs: {
                          variant: "warning",
                          size: "sm",
                          title: "Editar",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.openEditNotificadoModal(item)
                          },
                        },
                      },
                      [_c("b-icon-pencil")],
                      1
                    ),
                    !_vm.notificado.aceptado && !_vm.notificacion.staff
                      ? _c(
                          "b-button",
                          {
                            directives: [
                              {
                                name: "b-tooltip",
                                rawName: "v-b-tooltip.hover",
                                modifiers: { hover: true },
                              },
                            ],
                            attrs: {
                              variant: "danger",
                              size: "sm",
                              title: "Eliminar",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.openModalConfirmDelete(item.id)
                              },
                            },
                          },
                          [_c("b-icon-trash")],
                          1
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("div", { staticClass: "row mt-5" }),
      !_vm.notificacion.staff
        ? _c("div", { staticClass: "row mt-4" }, [
            _c(
              "div",
              { staticClass: "col-md-12" },
              [
                _c("ValidationProvider", {
                  attrs: {
                    name: "validacionDatos",
                    rules: { required: { allowFalse: false } },
                    "custom-messages": {
                      required: "La confirmación es obligatoria",
                    },
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function ({ errors }) {
                          return [
                            _c(
                              "b-form-checkbox",
                              {
                                staticClass: "orange",
                                attrs: {
                                  id: "validacionDatos-notificado-checkbox",
                                  disabled:
                                    (!_vm.notificacion.staff &&
                                      _vm.notificado.aceptado) ||
                                    (_vm.notificacion.staff &&
                                      _vm.notificacion.validaNotificado == 1),
                                },
                                model: {
                                  value: _vm.validacionDatos,
                                  callback: function ($$v) {
                                    _vm.validacionDatos = $$v
                                  },
                                  expression: "validacionDatos",
                                },
                              },
                              [
                                _vm._v(
                                  " Acepto que la información proporcionada es correcta. "
                                ),
                              ]
                            ),
                            _c("span", { staticClass: "cardErrors" }, [
                              _vm._v(_vm._s(errors[0])),
                            ]),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    1730739256
                  ),
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
      !_vm.notificacion.staff
        ? _c("div", { staticClass: "mt-5" }, [
            _c(
              "div",
              { staticClass: "mt-5" },
              [
                _c(
                  "b-button",
                  {
                    staticClass: "btn-fill orange-btn bottom2",
                    attrs: {
                      type: "submit",
                      size: "m",
                      disabled: !_vm.validacionDatos || _vm.notificado.aceptado,
                    },
                  },
                  [_vm._v("Continuar")]
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm.notificacion.staff
        ? _c("div", { staticClass: "mt-5" }, [
            _c(
              "div",
              { staticClass: "mt-5" },
              [
                _c(
                  "b-button",
                  {
                    staticClass: "btn-fill orange-btn bottom2",
                    attrs: {
                      type: "submit",
                      size: "m",
                      disabled: _vm.notificacion.validaNotificado == 1,
                    },
                  },
                  [_vm._v("Validar")]
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }