var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container" }, [
    _vm.formalizacion.tipo_acta == "Asamblea de Accionistas"
      ? _c("div", [
          _c("h4", { staticClass: "cardD" }, [
            _vm._v(
              "Cuestionario relacionado con la instalación de la asamblea cuya acta se protocolizará"
            ),
          ]),
          _vm._m(0),
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-md-3" },
              [
                _c(
                  "b-form-radio",
                  {
                    attrs: { name: "radios", value: "Sí" },
                    on: {
                      change: function ($event) {
                        return _vm.selectLgsm("Sí")
                      },
                    },
                    model: {
                      value: _vm.convocatoriaLgsm,
                      callback: function ($$v) {
                        _vm.convocatoriaLgsm = $$v
                      },
                      expression: "convocatoriaLgsm",
                    },
                  },
                  [_vm._v(" Sí ")]
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-md-3" },
              [
                _c(
                  "b-form-radio",
                  {
                    attrs: { name: "radios", value: "No" },
                    on: {
                      change: function ($event) {
                        return _vm.selectLgsm("No")
                      },
                    },
                    model: {
                      value: _vm.convocatoriaLgsm,
                      callback: function ($$v) {
                        _vm.convocatoriaLgsm = $$v
                      },
                      expression: "convocatoriaLgsm",
                    },
                  },
                  [_vm._v(" No ")]
                ),
              ],
              1
            ),
          ]),
          _vm._m(1),
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-md-3" },
              [
                _c(
                  "b-form-radio",
                  {
                    attrs: { name: "radios92", value: "Sí" },
                    on: {
                      change: function ($event) {
                        return _vm.selectPsm("Sí")
                      },
                    },
                    model: {
                      value: _vm.convocatoriaPsm,
                      callback: function ($$v) {
                        _vm.convocatoriaPsm = $$v
                      },
                      expression: "convocatoriaPsm",
                    },
                  },
                  [_vm._v(" Sí ")]
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-md-3" },
              [
                _c(
                  "b-form-radio",
                  {
                    attrs: { name: "radios92", value: "No" },
                    on: {
                      change: function ($event) {
                        return _vm.selectPsm("No")
                      },
                    },
                    model: {
                      value: _vm.convocatoriaPsm,
                      callback: function ($$v) {
                        _vm.convocatoriaPsm = $$v
                      },
                      expression: "convocatoriaPsm",
                    },
                  },
                  [_vm._v(" No ")]
                ),
              ],
              1
            ),
          ]),
          _vm.formalizacion.convocatoria_psm == "0"
            ? _c("div", { staticClass: "indented" }, [
                _vm._m(2),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-md-3" },
                    [
                      _c(
                        "b-form-radio",
                        {
                          attrs: { name: "radios3", value: "Sí" },
                          on: {
                            change: function ($event) {
                              return _vm.selectRepresentacionCien("Sí")
                            },
                          },
                          model: {
                            value: _vm.representacionCien,
                            callback: function ($$v) {
                              _vm.representacionCien = $$v
                            },
                            expression: "representacionCien",
                          },
                        },
                        [_vm._v(" Sí ")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-md-3" },
                    [
                      _c(
                        "b-form-radio",
                        {
                          attrs: { name: "radios3", value: "No" },
                          on: {
                            change: function ($event) {
                              return _vm.selectRepresentacionCien("No")
                            },
                          },
                          model: {
                            value: _vm.representacionCien,
                            callback: function ($$v) {
                              _vm.representacionCien = $$v
                            },
                            expression: "representacionCien",
                          },
                        },
                        [_vm._v(" No ")]
                      ),
                    ],
                    1
                  ),
                ]),
              ])
            : _vm._e(),
          _vm.blockCien
            ? _c(
                "label",
                {
                  staticClass: "mt-2 leyendaBlock",
                  attrs: { disabled: _vm.formalizacion.disabled },
                },
                [
                  _c("b", [
                    _vm._v(
                      " Para poder continuar con el proceso es necesario que esté representado en asamblea con el 100%. "
                    ),
                  ]),
                ]
              )
            : _vm._e(),
          _vm._m(3),
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-md-3" },
              [
                _c(
                  "b-form-radio",
                  {
                    attrs: { name: "radios4", value: "Sí" },
                    on: {
                      change: function ($event) {
                        return _vm.selectLista("Sí")
                      },
                    },
                    model: {
                      value: _vm.listaAsistencia,
                      callback: function ($$v) {
                        _vm.listaAsistencia = $$v
                      },
                      expression: "listaAsistencia",
                    },
                  },
                  [_vm._v(" Sí ")]
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-md-3" },
              [
                _c(
                  "b-form-radio",
                  {
                    attrs: { name: "radios4", value: "No" },
                    on: {
                      change: function ($event) {
                        return _vm.selectLista("No")
                      },
                    },
                    model: {
                      value: _vm.listaAsistencia,
                      callback: function ($$v) {
                        _vm.listaAsistencia = $$v
                      },
                      expression: "listaAsistencia",
                    },
                  },
                  [_vm._v(" No ")]
                ),
              ],
              1
            ),
          ]),
          _vm.formalizacion.lista_asistencia_firmada == 0 &&
          _vm.formalizacion.representacion_cien == 1
            ? _c("div", [
                _vm._m(4),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-md-3" },
                    [
                      _c(
                        "b-form-radio",
                        {
                          attrs: { name: "radios5", value: "Sí" },
                          on: {
                            change: function ($event) {
                              return _vm.selectAsistentesFirma("Sí")
                            },
                          },
                          model: {
                            value: _vm.asistentesFirma,
                            callback: function ($$v) {
                              _vm.asistentesFirma = $$v
                            },
                            expression: "asistentesFirma",
                          },
                        },
                        [_vm._v(" Sí ")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-md-3" },
                    [
                      _c(
                        "b-form-radio",
                        {
                          attrs: { name: "radios5", value: "No" },
                          on: {
                            change: function ($event) {
                              return _vm.selectAsistentesFirma("No")
                            },
                          },
                          model: {
                            value: _vm.asistentesFirma,
                            callback: function ($$v) {
                              _vm.asistentesFirma = $$v
                            },
                            expression: "asistentesFirma",
                          },
                        },
                        [_vm._v(" No ")]
                      ),
                    ],
                    1
                  ),
                ]),
              ])
            : _vm._e(),
          _vm.blockFirma
            ? _c(
                "label",
                {
                  staticClass: "mt-2 leyendaBlock",
                  attrs: { disabled: _vm.formalizacion.disabled },
                },
                [
                  _c("b", [
                    _vm._v(
                      " Para poder continuar con el proceso es necesario que los asistentes hayan firmado el acta. "
                    ),
                  ]),
                ]
              )
            : _vm._e(),
          _vm._m(5),
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-md-3" },
              [
                _c(
                  "b-form-radio",
                  {
                    attrs: { name: "radios6", value: "Sí" },
                    on: {
                      change: function ($event) {
                        return _vm.selectRfcs("Sí")
                      },
                    },
                    model: {
                      value: _vm.rfcsAccionistas,
                      callback: function ($$v) {
                        _vm.rfcsAccionistas = $$v
                      },
                      expression: "rfcsAccionistas",
                    },
                  },
                  [_vm._v(" Sí ")]
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-md-3" },
              [
                _c(
                  "b-form-radio",
                  {
                    attrs: { name: "radios6", value: "No" },
                    on: {
                      change: function ($event) {
                        return _vm.selectRfcs("No")
                      },
                    },
                    model: {
                      value: _vm.rfcsAccionistas,
                      callback: function ($$v) {
                        _vm.rfcsAccionistas = $$v
                      },
                      expression: "rfcsAccionistas",
                    },
                  },
                  [_vm._v(" No ")]
                ),
              ],
              1
            ),
          ]),
        ])
      : _vm._e(),
    _vm.formalizacion.tipo_acta ===
    "Acta de sesión del Consejo de Administración"
      ? _c("div", [
          _c("h4", { staticClass: "cardD" }, [
            _vm._v(
              " Cuestionario relacionado con la instalación de la Sesión de Consejo cuya acta se protocolizará "
            ),
          ]),
          _vm._m(6),
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-md-3" },
              [
                _c(
                  "b-form-radio",
                  {
                    attrs: { name: "radios", value: "Sí" },
                    on: {
                      change: function ($event) {
                        return _vm.selectLgsm("Sí")
                      },
                    },
                    model: {
                      value: _vm.convocatoriaLgsm,
                      callback: function ($$v) {
                        _vm.convocatoriaLgsm = $$v
                      },
                      expression: "convocatoriaLgsm",
                    },
                  },
                  [_vm._v(" Sí ")]
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-md-3" },
              [
                _c(
                  "b-form-radio",
                  {
                    attrs: { name: "radios", value: "No" },
                    on: {
                      change: function ($event) {
                        return _vm.selectLgsm("No")
                      },
                    },
                    model: {
                      value: _vm.convocatoriaLgsm,
                      callback: function ($$v) {
                        _vm.convocatoriaLgsm = $$v
                      },
                      expression: "convocatoriaLgsm",
                    },
                  },
                  [_vm._v(" No ")]
                ),
              ],
              1
            ),
          ]),
          _vm._m(7),
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-md-3" },
              [
                _c(
                  "b-form-radio",
                  {
                    attrs: { name: "radios2", value: "Sí" },
                    on: {
                      change: function ($event) {
                        return _vm.selectSesion("Sí")
                      },
                    },
                    model: {
                      value: _vm.sesionInstalada,
                      callback: function ($$v) {
                        _vm.sesionInstalada = $$v
                      },
                      expression: "sesionInstalada",
                    },
                  },
                  [_vm._v(" Sí ")]
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-md-3" },
              [
                _c(
                  "b-form-radio",
                  {
                    attrs: { name: "radios3", value: "No" },
                    on: {
                      change: function ($event) {
                        return _vm.selectSesion("No")
                      },
                    },
                    model: {
                      value: _vm.sesionInstalada,
                      callback: function ($$v) {
                        _vm.sesionInstalada = $$v
                      },
                      expression: "sesionInstalada",
                    },
                  },
                  [_vm._v(" No ")]
                ),
              ],
              1
            ),
          ]),
          _vm.blockSesion
            ? _c(
                "label",
                {
                  staticClass: "mt-2 leyendaBlock",
                  attrs: { disabled: _vm.formalizacion.disabled },
                },
                [
                  _c("b", [
                    _vm._v(
                      " Para poder continuar con el proceso es necesario que la sesión esté instalada "
                    ),
                  ]),
                ]
              )
            : _vm._e(),
          _vm._m(8),
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-md-3" },
              [
                _c(
                  "b-form-radio",
                  {
                    attrs: { name: "radios4", value: "Sí" },
                    on: {
                      change: function ($event) {
                        return _vm.selectLista("Sí")
                      },
                    },
                    model: {
                      value: _vm.listaAsistencia,
                      callback: function ($$v) {
                        _vm.listaAsistencia = $$v
                      },
                      expression: "listaAsistencia",
                    },
                  },
                  [_vm._v(" Sí ")]
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-md-3" },
              [
                _c(
                  "b-form-radio",
                  {
                    attrs: { name: "radios4", value: "No" },
                    on: {
                      change: function ($event) {
                        return _vm.selectLista("No")
                      },
                    },
                    model: {
                      value: _vm.listaAsistencia,
                      callback: function ($$v) {
                        _vm.listaAsistencia = $$v
                      },
                      expression: "listaAsistencia",
                    },
                  },
                  [_vm._v(" No ")]
                ),
              ],
              1
            ),
          ]),
          _vm._m(9),
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-md-3" },
              [
                _c(
                  "b-form-radio",
                  {
                    attrs: { name: "radios5", value: "Sí" },
                    on: {
                      change: function ($event) {
                        return _vm.selectTotalitaria("Sí")
                      },
                    },
                    model: {
                      value: _vm.sesionTotalitaria,
                      callback: function ($$v) {
                        _vm.sesionTotalitaria = $$v
                      },
                      expression: "sesionTotalitaria",
                    },
                  },
                  [_vm._v(" Sí ")]
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-md-3" },
              [
                _c(
                  "b-form-radio",
                  {
                    attrs: { name: "radios5", value: "No" },
                    on: {
                      change: function ($event) {
                        return _vm.selectTotalitaria("No")
                      },
                    },
                    model: {
                      value: _vm.sesionTotalitaria,
                      callback: function ($$v) {
                        _vm.sesionTotalitaria = $$v
                      },
                      expression: "sesionTotalitaria",
                    },
                  },
                  [_vm._v(" No ")]
                ),
              ],
              1
            ),
          ]),
          _vm._m(10),
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-md-3" },
              [
                _c(
                  "b-form-radio",
                  {
                    attrs: { name: "radios51", value: "Sí" },
                    on: {
                      change: function ($event) {
                        return _vm.selectAsistentesFirma("Sí")
                      },
                    },
                    model: {
                      value: _vm.asistentesFirma,
                      callback: function ($$v) {
                        _vm.asistentesFirma = $$v
                      },
                      expression: "asistentesFirma",
                    },
                  },
                  [_vm._v(" Sí ")]
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-md-3" },
              [
                _c(
                  "b-form-radio",
                  {
                    attrs: { name: "radios51", value: "No" },
                    on: {
                      change: function ($event) {
                        return _vm.selectAsistentesFirma("No")
                      },
                    },
                    model: {
                      value: _vm.asistentesFirma,
                      callback: function ($$v) {
                        _vm.asistentesFirma = $$v
                      },
                      expression: "asistentesFirma",
                    },
                  },
                  [_vm._v(" No ")]
                ),
              ],
              1
            ),
          ]),
          _vm.blockFirma
            ? _c(
                "label",
                {
                  staticClass: "mt-2 leyendaBlock",
                  attrs: { disabled: _vm.formalizacion.disabled },
                },
                [
                  _c("b", [
                    _vm._v(
                      " Para poder continuar con el proceso es necesario que los asistentes hayan firmado el acta. "
                    ),
                  ]),
                ]
              )
            : _vm._e(),
        ])
      : _vm._e(),
    _vm.formalizacion.tipo_acta == "Asamblea de Accionistas" ||
    _vm.formalizacion.tipo_acta ==
      "Resoluciones Unánimes adoptadas fuera de asamblea (RUAs)"
      ? _c("div", [
          _c("h4", { staticClass: "cardD" }, [
            _vm._v(
              " Cuestionario relacionado al contenido del acta que se protocoliza "
            ),
          ]),
          _c("div", { staticClass: "row mt-4" }, [
            _c("div", { staticClass: "col-6" }, [
              _vm._m(11),
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-md-3" },
                  [
                    _c(
                      "b-form-radio",
                      {
                        attrs: {
                          name: "radios12",
                          disabled: _vm.formalizacion.disabledAsamblea,
                          value: "Sí",
                        },
                        on: {
                          change: function ($event) {
                            return _vm.selectModificaCapital("Sí")
                          },
                        },
                        model: {
                          value: _vm.modificaCapital,
                          callback: function ($$v) {
                            _vm.modificaCapital = $$v
                          },
                          expression: "modificaCapital",
                        },
                      },
                      [_vm._v(" Sí ")]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-md-3" },
                  [
                    _c(
                      "b-form-radio",
                      {
                        attrs: {
                          name: "radios12",
                          value: "No",
                          disabled: _vm.formalizacion.disabledAsamblea,
                        },
                        on: {
                          change: function ($event) {
                            return _vm.selectModificaCapital("No")
                          },
                        },
                        model: {
                          value: _vm.modificaCapital,
                          callback: function ($$v) {
                            _vm.modificaCapital = $$v
                          },
                          expression: "modificaCapital",
                        },
                      },
                      [_vm._v(" No ")]
                    ),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
          _vm.formalizacion.modifica_capital == 1
            ? _c("div", { staticClass: "row indented" }, [
                _c("div", { staticClass: "row" }, [
                  _vm._m(12),
                  _c(
                    "div",
                    { staticClass: "col-md-3" },
                    [
                      _c(
                        "b-form-radio",
                        {
                          attrs: {
                            name: "radios8",
                            value: "Fijo",
                            disabled: _vm.formalizacion.disabledAsamblea,
                          },
                        },
                        [_vm._v(" Fijo ")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-md-3" },
                    [
                      _c(
                        "b-form-radio",
                        {
                          attrs: {
                            name: "radios8",
                            value: "Variable",
                            disabled: _vm.formalizacion.disabledAsamblea,
                          },
                        },
                        [_vm._v(" Variable ")]
                      ),
                    ],
                    1
                  ),
                  _vm._m(13),
                  _c(
                    "div",
                    { staticClass: "col-md-3" },
                    [
                      _c(
                        "b-form-radio",
                        {
                          attrs: {
                            name: "radios9",
                            value: "Aumenta",
                            disabled: _vm.formalizacion.disabledAsamblea,
                          },
                          on: {
                            change: function ($event) {
                              return _vm.selectTipoModificado("Aumenta")
                            },
                          },
                          model: {
                            value: _vm.formalizacion.tipo_modificado,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.formalizacion,
                                "tipo_modificado",
                                $$v
                              )
                            },
                            expression: "formalizacion.tipo_modificado",
                          },
                        },
                        [_vm._v(" Aumenta ")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-md-3" },
                    [
                      _c(
                        "b-form-radio",
                        {
                          attrs: {
                            disabled: _vm.formalizacion.disabledAsamblea,
                            name: "radios9",
                            value: "Disminuye",
                          },
                          on: {
                            change: function ($event) {
                              return _vm.selectTipoModificado("Disminuye")
                            },
                          },
                          model: {
                            value: _vm.formalizacion.tipo_modificado,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.formalizacion,
                                "tipo_modificado",
                                $$v
                              )
                            },
                            expression: "formalizacion.tipo_modificado",
                          },
                        },
                        [_vm._v(" Disminuye ")]
                      ),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "w-100" }),
                  _c(
                    "div",
                    { staticClass: "col-md-4 mt-3" },
                    [
                      _c("ValidationProvider", {
                        attrs: {
                          name: "nombre_sociedad",
                          rules: "required",
                          "custom-messages": {
                            required: "El monto es obligatorio",
                          },
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function ({ errors }) {
                                return [
                                  _c("base-input", {
                                    attrs: {
                                      type: "number",
                                      label: `Monto ${_vm.computedMontoModificado}`,
                                      placeholder: "Monto",
                                    },
                                    model: {
                                      value: _vm.formalizacion.monto_modificado,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.formalizacion,
                                          "monto_modificado",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "formalizacion.monto_modificado",
                                    },
                                  }),
                                  _c("span", { staticClass: "cardErrors" }, [
                                    _vm._v(_vm._s(errors[0])),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          175302928
                        ),
                      }),
                    ],
                    1
                  ),
                ]),
                _vm.blockMonto
                  ? _c(
                      "label",
                      {
                        staticClass: "mt-2 leyendaBlock",
                        attrs: { disabled: _vm.formalizacion.disabled },
                      },
                      [
                        _c("b", [
                          _vm._v(
                            " Para poder continuar con el proceso para montos superiores a 200MDP es necesario que se comunique con Correduría Digital "
                          ),
                        ]),
                      ]
                    )
                  : _vm._e(),
              ])
            : _vm._e(),
          _c("div", { staticClass: "row mt-3" }, [
            _c("div", { staticClass: "col-6" }, [
              _vm._m(14),
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-md-3" },
                  [
                    _c(
                      "b-form-radio",
                      {
                        attrs: {
                          name: "radios11",
                          disabled: _vm.formalizacion.disabledAsamblea,
                          value: "Sí",
                        },
                        on: {
                          change: function ($event) {
                            return _vm.selectNuevosAccionistas("Sí")
                          },
                        },
                        model: {
                          value: _vm.nuevosAccionistas,
                          callback: function ($$v) {
                            _vm.nuevosAccionistas = $$v
                          },
                          expression: "nuevosAccionistas",
                        },
                      },
                      [_vm._v(" Sí ")]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-md-3" },
                  [
                    _c(
                      "b-form-radio",
                      {
                        attrs: {
                          name: "radios11",
                          value: "No",
                          disabled: _vm.formalizacion.disabledAsamblea,
                        },
                        on: {
                          change: function ($event) {
                            return _vm.selectNuevosAccionistas("No")
                          },
                        },
                        model: {
                          value: _vm.nuevosAccionistas,
                          callback: function ($$v) {
                            _vm.nuevosAccionistas = $$v
                          },
                          expression: "nuevosAccionistas",
                        },
                      },
                      [_vm._v(" No ")]
                    ),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "row mt-3" }, [
            _c("div", { staticClass: "col-6" }, [
              _vm._m(15),
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-md-3" },
                  [
                    _c(
                      "b-form-radio",
                      {
                        attrs: {
                          name: "radios10",
                          disabled: _vm.formalizacion.disabledAsamblea,
                          value: "Sí",
                        },
                        on: {
                          change: function ($event) {
                            return _vm.selectCapitalExtranjero("Sí")
                          },
                        },
                        model: {
                          value: _vm.capitalExtranjero,
                          callback: function ($$v) {
                            _vm.capitalExtranjero = $$v
                          },
                          expression: "capitalExtranjero",
                        },
                      },
                      [_vm._v(" Sí ")]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-md-3" },
                  [
                    _c(
                      "b-form-radio",
                      {
                        attrs: {
                          name: "radios10",
                          value: "No",
                          disabled: _vm.formalizacion.disabledAsamblea,
                        },
                        on: {
                          change: function ($event) {
                            return _vm.selectCapitalExtranjero("No")
                          },
                        },
                        model: {
                          value: _vm.capitalExtranjero,
                          callback: function ($$v) {
                            _vm.capitalExtranjero = $$v
                          },
                          expression: "capitalExtranjero",
                        },
                      },
                      [_vm._v(" No ")]
                    ),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
        ])
      : _vm._e(),
    _vm.formalizacion.subtipo_acta == "General Extraordinaria" ||
    _vm.formalizacion.tipo_acta ==
      "Resoluciones Unánimes adoptadas fuera de asamblea (RUAs)"
      ? _c("div", [
          _c("div", { staticClass: "row mt-4" }, [
            _c("div", { staticClass: "col-6" }, [
              _vm._m(16),
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-md-3" },
                  [
                    _c(
                      "b-form-radio",
                      {
                        attrs: {
                          name: "radios20",
                          disabled: _vm.formalizacion.disabledAsamblea,
                          value: "Sí",
                        },
                        on: {
                          change: function ($event) {
                            return _vm.selectModificaEstatutos("Sí")
                          },
                        },
                        model: {
                          value: _vm.modificaEstatutos,
                          callback: function ($$v) {
                            _vm.modificaEstatutos = $$v
                          },
                          expression: "modificaEstatutos",
                        },
                      },
                      [_vm._v(" Sí ")]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-md-3" },
                  [
                    _c(
                      "b-form-radio",
                      {
                        attrs: {
                          name: "radios20",
                          value: "No",
                          disabled: _vm.formalizacion.disabledAsamblea,
                        },
                        on: {
                          change: function ($event) {
                            return _vm.selectModificaEstatutos("No")
                          },
                        },
                        model: {
                          value: _vm.modificaEstatutos,
                          callback: function ($$v) {
                            _vm.modificaEstatutos = $$v
                          },
                          expression: "modificaEstatutos",
                        },
                      },
                      [_vm._v(" No ")]
                    ),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
          _vm.formalizacion.modifica_estatutos == 1
            ? _c("div", { staticClass: "mt-3 indented" }, [
                _vm._m(17),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-md-3" },
                    [
                      _c(
                        "b-form-radio",
                        {
                          attrs: {
                            name: "radios21",
                            disabled: _vm.formalizacion.disabledAsamblea,
                            value: "Sí",
                          },
                          on: {
                            change: function ($event) {
                              return _vm.selectReformaTotal("Sí")
                            },
                          },
                          model: {
                            value: _vm.reformaTotal,
                            callback: function ($$v) {
                              _vm.reformaTotal = $$v
                            },
                            expression: "reformaTotal",
                          },
                        },
                        [_vm._v(" Sí ")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-md-3" },
                    [
                      _c(
                        "b-form-radio",
                        {
                          attrs: {
                            name: "radios21",
                            value: "No",
                            disabled: _vm.formalizacion.disabledAsamblea,
                          },
                          on: {
                            change: function ($event) {
                              return _vm.selectReformaTotal("No")
                            },
                          },
                          model: {
                            value: _vm.reformaTotal,
                            callback: function ($$v) {
                              _vm.reformaTotal = $$v
                            },
                            expression: "reformaTotal",
                          },
                        },
                        [_vm._v(" No ")]
                      ),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "mt-3" }),
                _vm._m(18),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-6" }, [
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col-md-3" },
                        [
                          _c(
                            "b-form-radio",
                            {
                              attrs: {
                                name: "radios22",
                                disabled: _vm.formalizacion.disabledAsamblea,
                                value: "Sí",
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.selectModificaObjeto("Sí")
                                },
                              },
                              model: {
                                value: _vm.modificaObjeto,
                                callback: function ($$v) {
                                  _vm.modificaObjeto = $$v
                                },
                                expression: "modificaObjeto",
                              },
                            },
                            [_vm._v(" Sí ")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-md-3" },
                        [
                          _c(
                            "b-form-radio",
                            {
                              attrs: {
                                name: "radios23",
                                value: "No",
                                disabled: _vm.formalizacion.disabledAsamblea,
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.selectModificaObjeto("No")
                                },
                              },
                              model: {
                                value: _vm.modificaObjeto,
                                callback: function ($$v) {
                                  _vm.modificaObjeto = $$v
                                },
                                expression: "modificaObjeto",
                              },
                            },
                            [_vm._v(" No ")]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "mt-3" }),
                _vm._m(19),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-6" }, [
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col-md-3" },
                        [
                          _c(
                            "b-form-radio",
                            {
                              attrs: {
                                name: "radios24",
                                disabled: _vm.formalizacion.disabledAsamblea,
                                value: "Sí",
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.selectCambioDuracion("Sí")
                                },
                              },
                              model: {
                                value: _vm.cambioDuracion,
                                callback: function ($$v) {
                                  _vm.cambioDuracion = $$v
                                },
                                expression: "cambioDuracion",
                              },
                            },
                            [_vm._v(" Sí ")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-md-3" },
                        [
                          _c(
                            "b-form-radio",
                            {
                              attrs: {
                                name: "radios24",
                                value: "No",
                                disabled: _vm.formalizacion.disabledAsamblea,
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.selectCambioDuracion("No")
                                },
                              },
                              model: {
                                value: _vm.cambioDuracion,
                                callback: function ($$v) {
                                  _vm.cambioDuracion = $$v
                                },
                                expression: "cambioDuracion",
                              },
                            },
                            [_vm._v(" No ")]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]),
              ])
            : _vm._e(),
          _vm.formalizacion.modifica_estatutos == 1
            ? _c("div", { staticClass: "row mt-4" }, [
                _c("div", { staticClass: "col-6" }, [
                  _vm._m(20),
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col-md-3" },
                      [
                        _c(
                          "b-form-radio",
                          {
                            attrs: {
                              name: "radios25",
                              disabled: _vm.formalizacion.disabledAsamblea,
                              value: "Sí",
                            },
                            on: {
                              change: function ($event) {
                                return _vm.selectCambioDenominacion("Sí")
                              },
                            },
                            model: {
                              value: _vm.cambioDenominacion,
                              callback: function ($$v) {
                                _vm.cambioDenominacion = $$v
                              },
                              expression: "cambioDenominacion",
                            },
                          },
                          [_vm._v(" Sí ")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-md-3" },
                      [
                        _c(
                          "b-form-radio",
                          {
                            attrs: {
                              name: "radios25",
                              value: "No",
                              disabled: _vm.formalizacion.disabledAsamblea,
                            },
                            on: {
                              change: function ($event) {
                                return _vm.selectCambioDenominacion("No")
                              },
                            },
                            model: {
                              value: _vm.cambioDenominacion,
                              callback: function ($$v) {
                                _vm.cambioDenominacion = $$v
                              },
                              expression: "cambioDenominacion",
                            },
                          },
                          [_vm._v(" No ")]
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
              ])
            : _vm._e(),
          _c("div", { staticClass: "row mt-3" }, [
            _c("div", { staticClass: "col-6" }, [
              _vm._m(21),
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-md-3" },
                  [
                    _c(
                      "b-form-radio",
                      {
                        attrs: {
                          name: "radios26",
                          disabled: _vm.formalizacion.disabledAsamblea,
                          value: "Sí",
                        },
                        on: {
                          change: function ($event) {
                            return _vm.selectCambioDomicilio("Sí")
                          },
                        },
                        model: {
                          value: _vm.cambioDomicilio,
                          callback: function ($$v) {
                            _vm.cambioDomicilio = $$v
                          },
                          expression: "cambioDomicilio",
                        },
                      },
                      [_vm._v(" Sí ")]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-md-3" },
                  [
                    _c(
                      "b-form-radio",
                      {
                        attrs: {
                          name: "radios26",
                          value: "No",
                          disabled: _vm.formalizacion.disabledAsamblea,
                        },
                        on: {
                          change: function ($event) {
                            return _vm.selectCambioDomicilio("No")
                          },
                        },
                        model: {
                          value: _vm.cambioDomicilio,
                          callback: function ($$v) {
                            _vm.cambioDomicilio = $$v
                          },
                          expression: "cambioDomicilio",
                        },
                      },
                      [_vm._v(" No ")]
                    ),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "row mt-3" }, [
            _c("div", { staticClass: "col-7" }, [
              _vm._m(22),
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-md-3" },
                  [
                    _c(
                      "b-form-radio",
                      {
                        attrs: {
                          name: "radios30",
                          disabled: _vm.formalizacion.disabledAsamblea,
                          value: "Sí",
                        },
                        on: {
                          change: function ($event) {
                            return _vm.selectRevocaFuncionarios("Sí")
                          },
                        },
                        model: {
                          value: _vm.revocaFuncionarios,
                          callback: function ($$v) {
                            _vm.revocaFuncionarios = $$v
                          },
                          expression: "revocaFuncionarios",
                        },
                      },
                      [_vm._v(" Sí ")]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-md-3" },
                  [
                    _c(
                      "b-form-radio",
                      {
                        attrs: {
                          name: "radios30",
                          value: "No",
                          disabled: _vm.formalizacion.disabledAsamblea,
                        },
                        on: {
                          change: function ($event) {
                            return _vm.selectRevocaFuncionarios("No")
                          },
                        },
                        model: {
                          value: _vm.revocaFuncionarios,
                          callback: function ($$v) {
                            _vm.revocaFuncionarios = $$v
                          },
                          expression: "revocaFuncionarios",
                        },
                      },
                      [_vm._v(" No ")]
                    ),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
          _vm.formalizacion.revoca_funcionarios
            ? _c("div", { staticClass: "mt-3 indented" }, [
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-4" },
                    [
                      _c("base-input", {
                        attrs: {
                          disabled: _vm.formalizacion.disabledAsamblea,
                          type: "text",
                          label: "¿Cuántos?",
                          placeholder: "Cantidad",
                        },
                        model: {
                          value: _vm.formalizacion.num_funcionarios_revocados,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.formalizacion,
                              "num_funcionarios_revocados",
                              $$v
                            )
                          },
                          expression:
                            "formalizacion.num_funcionarios_revocados",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ])
            : _vm._e(),
          _c("div", { staticClass: "row mt-3" }, [
            _c("div", { staticClass: "col-8" }, [
              _vm._m(23),
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-md-3" },
                  [
                    _c(
                      "b-form-radio",
                      {
                        attrs: {
                          name: "radios31",
                          disabled: _vm.formalizacion.disabledAsamblea,
                          value: "Sí",
                        },
                        on: {
                          change: function ($event) {
                            return _vm.selectModificaFacutlades("Sí")
                          },
                        },
                        model: {
                          value: _vm.modificaFacutlades,
                          callback: function ($$v) {
                            _vm.modificaFacutlades = $$v
                          },
                          expression: "modificaFacutlades",
                        },
                      },
                      [_vm._v(" Sí ")]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-md-3" },
                  [
                    _c(
                      "b-form-radio",
                      {
                        attrs: {
                          name: "radios31",
                          value: "No",
                          disabled: _vm.formalizacion.disabledAsamblea,
                        },
                        on: {
                          change: function ($event) {
                            return _vm.selectModificaFacutlades("No")
                          },
                        },
                        model: {
                          value: _vm.modificaFacutlades,
                          callback: function ($$v) {
                            _vm.modificaFacutlades = $$v
                          },
                          expression: "modificaFacutlades",
                        },
                      },
                      [_vm._v(" No ")]
                    ),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
          _vm.formalizacion.modifica_facultades_funcionarios
            ? _c("div", { staticClass: "mt-3 indented" }, [
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-4" },
                    [
                      _c("base-input", {
                        attrs: {
                          disabled: _vm.formalizacion.disabledAsamblea,
                          type: "text",
                          label: "¿Cuántos?",
                          placeholder: "Cantidad",
                        },
                        model: {
                          value: _vm.formalizacion.num_modificacion_facultades,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.formalizacion,
                              "num_modificacion_facultades",
                              $$v
                            )
                          },
                          expression:
                            "formalizacion.num_modificacion_facultades",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ])
            : _vm._e(),
          _c("div", { staticClass: "row mt-3" }, [
            _c("div", { staticClass: "col-6" }, [
              _vm._m(24),
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-md-3" },
                  [
                    _c(
                      "b-form-radio",
                      {
                        attrs: { name: "radios32", value: "Sí" },
                        on: {
                          change: function ($event) {
                            return _vm.selectTransformaSociedad("Sí")
                          },
                        },
                        model: {
                          value: _vm.transformaSociedad,
                          callback: function ($$v) {
                            _vm.transformaSociedad = $$v
                          },
                          expression: "transformaSociedad",
                        },
                      },
                      [_vm._v(" Sí ")]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-md-3" },
                  [
                    _c(
                      "b-form-radio",
                      {
                        attrs: { name: "radios32", value: "No" },
                        on: {
                          change: function ($event) {
                            return _vm.selectTransformaSociedad("No")
                          },
                        },
                        model: {
                          value: _vm.transformaSociedad,
                          callback: function ($$v) {
                            _vm.transformaSociedad = $$v
                          },
                          expression: "transformaSociedad",
                        },
                      },
                      [_vm._v(" No ")]
                    ),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
          _vm.blockTransformo
            ? _c(
                "label",
                {
                  staticClass: "mt-2 leyendaBlock",
                  attrs: { disabled: _vm.formalizacion.disabled },
                },
                [
                  _c("b", [
                    _vm._v(
                      " Para poder continuar con el proceso es necesario que contacte a Correduría Digital "
                    ),
                  ]),
                ]
              )
            : _vm._e(),
          _c("div", { staticClass: "row mt-3" }, [
            _c("div", { staticClass: "col-6" }, [
              _vm._m(25),
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-md-3" },
                  [
                    _c(
                      "b-form-radio",
                      {
                        attrs: { name: "radios34", value: "Sí" },
                        on: {
                          change: function ($event) {
                            return _vm.selectFusion("Sí")
                          },
                        },
                        model: {
                          value: _vm.fusion,
                          callback: function ($$v) {
                            _vm.fusion = $$v
                          },
                          expression: "fusion",
                        },
                      },
                      [_vm._v(" Sí ")]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-md-3" },
                  [
                    _c(
                      "b-form-radio",
                      {
                        attrs: { name: "radios34", value: "No" },
                        on: {
                          change: function ($event) {
                            return _vm.selectFusion("No")
                          },
                        },
                        model: {
                          value: _vm.fusion,
                          callback: function ($$v) {
                            _vm.fusion = $$v
                          },
                          expression: "fusion",
                        },
                      },
                      [_vm._v(" No ")]
                    ),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
          _vm.blockFusion
            ? _c(
                "label",
                {
                  staticClass: "mt-2 leyendaBlock",
                  attrs: { disabled: _vm.formalizacion.disabledAsamblea },
                },
                [
                  _c("b", [
                    _vm._v(
                      " Para poder continuar con el proceso es necesario que contacte a Correduría Digital "
                    ),
                  ]),
                ]
              )
            : _vm._e(),
          _c("div", { staticClass: "row mt-3" }, [
            _c("div", { staticClass: "col-6" }, [
              _vm._m(26),
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-md-3" },
                  [
                    _c(
                      "b-form-radio",
                      {
                        attrs: {
                          name: "radios37",
                          disabled: _vm.formalizacion.disabledAsamblea,
                          value: "Sí",
                        },
                        on: {
                          change: function ($event) {
                            return _vm.selectDisolucion("Sí")
                          },
                        },
                        model: {
                          value: _vm.disolucion,
                          callback: function ($$v) {
                            _vm.disolucion = $$v
                          },
                          expression: "disolucion",
                        },
                      },
                      [_vm._v(" Sí ")]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-md-3" },
                  [
                    _c(
                      "b-form-radio",
                      {
                        attrs: {
                          name: "radios37",
                          value: "No",
                          disabled: _vm.formalizacion.disabledAsamblea,
                        },
                        on: {
                          change: function ($event) {
                            return _vm.selectDisolucion("No")
                          },
                        },
                        model: {
                          value: _vm.disolucion,
                          callback: function ($$v) {
                            _vm.disolucion = $$v
                          },
                          expression: "disolucion",
                        },
                      },
                      [_vm._v(" No ")]
                    ),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "row mt-3" }, [
            _c("div", { staticClass: "col-6" }, [
              _vm._m(27),
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-md-3" },
                  [
                    _c(
                      "b-form-radio",
                      {
                        attrs: { name: "radios35", value: "Sí" },
                        on: {
                          change: function ($event) {
                            return _vm.selectLiquidacion("Sí")
                          },
                        },
                        model: {
                          value: _vm.liquidacion,
                          callback: function ($$v) {
                            _vm.liquidacion = $$v
                          },
                          expression: "liquidacion",
                        },
                      },
                      [_vm._v(" Sí ")]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-md-3" },
                  [
                    _c(
                      "b-form-radio",
                      {
                        attrs: { name: "radios35", value: "No" },
                        on: {
                          change: function ($event) {
                            return _vm.selectLiquidacion("No")
                          },
                        },
                        model: {
                          value: _vm.liquidacion,
                          callback: function ($$v) {
                            _vm.liquidacion = $$v
                          },
                          expression: "liquidacion",
                        },
                      },
                      [_vm._v(" No ")]
                    ),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
          _vm.blockLiquidacion
            ? _c("label", { staticClass: "mt-2 leyendaBlock" }, [
                _c("b", [
                  _vm._v(
                    " Para poder continuar con el proceso es necesario que contacte a Correduría Digital "
                  ),
                ]),
              ])
            : _vm._e(),
        ])
      : _vm._e(),
    _c("label", { staticClass: "leyenda mt-4" }, [
      _vm._v(
        " Será necesario entregar todas las claves de RFC de los accionistas y administradores "
      ),
    ]),
    _c("div", { staticClass: "mt-4" }, [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-10" },
          [
            _c(
              "b-button",
              {
                staticClass: "btn-fill orange-btn bottom2",
                attrs: {
                  type: "submit",
                  size: "m",
                  disabled: _vm.formalizacion.disabledAsamblea,
                },
              },
              [_vm._v("Guardar")]
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-2" },
          [
            _c(
              "b-button",
              {
                staticClass: "btn-fill bottom2",
                attrs: {
                  variant: "success",
                  size: "m",
                  disabled: _vm.formalizacion.disabledAsamblea,
                },
                on: {
                  click: function ($event) {
                    return _vm.validar()
                  },
                },
              },
              [_vm._v("Validar")]
            ),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row mt-4" }, [
      _c("div", { staticClass: "col-md-12" }, [
        _c("label", [
          _vm._v(
            "Existe convocatoria y publicaciones hechas conforme a estatutos sociales y LGSM"
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row mt-4" }, [
      _c("div", { staticClass: "col-md-12" }, [
        _c("label", [_vm._v("Existe convocatoria en el PSM")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row mt-4" }, [
      _c("div", { staticClass: "col-md-12" }, [
        _c("label", [
          _vm._v(
            "Estuvo representado en asamblea el 100% del capital con derecho a voto"
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row mt-4" }, [
      _c("div", { staticClass: "col-md-12" }, [
        _c("label", { attrs: { for: "" } }, [
          _vm._v(
            " Existe lista de asistencia firmada por los asistentes a la misma "
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row mt-4" }, [
      _c("div", { staticClass: "col-md-12" }, [
        _c("label", { attrs: { for: "" } }, [
          _vm._v(" Todos los asistentes firmaron el acta correspondiente "),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row mt-4" }, [
      _c("div", { staticClass: "col-md-12" }, [
        _c("label", { attrs: { for: "" } }, [
          _vm._v(
            " Cuenta con la clave de RFC de los accionistas y administradores de la sociedad "
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row mt-4" }, [
      _c("div", { staticClass: "col-md-12" }, [
        _c("label", { attrs: { for: "" } }, [
          _vm._v(
            " Existe convocatoria hecha conforme a estatutos sociales y LGSM "
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row mt-4" }, [
      _c("div", { staticClass: "col-md-12" }, [
        _c("label", { attrs: { for: "" } }, [
          _vm._v(
            " Estuvo debidamente instalada la sesión de consejo, conforme a estatutos sociales "
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row mt-4" }, [
      _c("div", { staticClass: "col-md-12" }, [
        _c("label", { attrs: { for: "" } }, [
          _vm._v(
            " Existe lista de asistencia firmada por los asistentes a la misma "
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row mt-4" }, [
      _c("div", { staticClass: "col-md-12" }, [
        _c("label", { attrs: { for: "" } }, [
          _vm._v(" Fue una sesión totalitaria "),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row mt-4" }, [
      _c("div", { staticClass: "col-md-12" }, [
        _c("label", { attrs: { for: "" } }, [
          _vm._v(" Todos los asistentes firmaron el acta correspondiente "),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-md-12" }, [
        _c("label", { attrs: { for: "" } }, [
          _vm._v(" Hay modificaciones al capital "),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-12 mt-3" }, [
      _c("label", [_vm._v("Capital modificado")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-12 mt-3" }, [
      _c("label", [_vm._v("¿Qué sucede con el capital?")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-md-12" }, [
        _c("label", { attrs: { for: "" } }, [
          _vm._v(" Ingresan nuevos accionistas o salen accionistas "),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-md-12" }, [
        _c("label", { attrs: { for: "" } }, [
          _vm._v(" Ingresa capital extranjero a la sociedad "),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-md-12" }, [
        _c("label", { attrs: { for: "" } }, [
          _vm._v(" Se modifican los estatutos de la sociedad "),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-md-12" }, [
        _c("label", [
          _vm._v("Hubo reforma total de estatutos o compulsa de los mismos"),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-md-12" }, [
        _c("label", [_vm._v("Se modificó el objeto social")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-md-12" }, [
        _c("label", [_vm._v("Cambió la duración de la sociedad")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-md-12" }, [
        _c("label", [_vm._v("Cambió la denominación social")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-md-12" }, [
        _c("label", [
          _vm._v(" Se modificó el domicilio social de la sociedad "),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-md-12" }, [
        _c("label", { attrs: { for: "" } }, [
          _vm._v(" Revocación o Renuncia de funcionarios o apoderados "),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-md-12" }, [
        _c("label", { attrs: { for: "" } }, [
          _vm._v(
            " Nombramiento o modificación de facultades de funcionarios o apoderados "
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-md-12" }, [
        _c("label", { attrs: { for: "" } }, [
          _vm._v(" Se transformó la sociedad "),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-md-12" }, [
        _c("label", { attrs: { for: "" } }, [_vm._v(" Fusión o escisión ")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-md-12" }, [
        _c("label", { attrs: { for: "" } }, [
          _vm._v(" Se disuelve la sociedad "),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-md-12" }, [
        _c("label", { attrs: { for: "" } }, [
          _vm._v(" Se liquida la sociedad "),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }