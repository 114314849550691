var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container" }, [
    _c("div", { staticClass: "container" }, [
      _vm.loading
        ? _c("div", [
            _c(
              "div",
              { attrs: { id: "loader" } },
              [
                _c("font-awesome-icon", {
                  attrs: { icon: "spinner", spin: "" },
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
    ]),
    _c("h4", { staticClass: "cardD" }, [_vm._v("Cotización y pago")]),
    _c("h4", { staticClass: "cardD" }, [_vm._v("Resumen de Constitución")]),
    _c("div", { staticClass: "row" }, [
      _c("label", { staticClass: "grey col-md-1" }, [
        _vm._v(_vm._s(_vm.num_accionistas_pf_incluido)),
      ]),
      _c("label", { staticClass: "grey col-md-5" }, [
        _vm._v(" Accionistas Personas Físicas"),
      ]),
    ]),
    _c("div", { staticClass: "row" }, [
      _c("label", { staticClass: "grey col-md-1" }, [
        _vm._v(_vm._s(_vm.num_administradores_incluido)),
      ]),
      _c("label", { staticClass: "grey col-md-5" }, [
        _vm._v(" Administradores "),
      ]),
    ]),
    _c("div", { staticClass: "row" }, [
      _c("label", { staticClass: "grey col-md-1" }, [
        _vm._v(_vm._s(_vm.num_comisarios_incluido)),
      ]),
      _c("label", { staticClass: "grey col-md-5" }, [_vm._v("Comisarios ")]),
    ]),
    _c("div", { staticClass: "row" }, [
      _c("label", { staticClass: "grey col-md-1" }, [
        _vm._v(_vm._s(_vm.num_apoderados_incluido)),
      ]),
      _c("label", { staticClass: "grey col-md-5" }, [_vm._v(" Apoderados ")]),
    ]),
    _c("div", { staticClass: "row mt-2" }, [
      _c("label", { staticClass: "grey col-md-6" }, [_vm._v(" Subtotal ")]),
      _c("label", { staticClass: "grey col-md-6" }, [
        _vm._v("$" + _vm._s(_vm.subtotal_resumen)),
      ]),
    ]),
    _c("h4", { staticClass: "cardD mt-1" }, [_vm._v("Adicionales")]),
    _c("div", { staticClass: "row" }, [
      _c("label", { staticClass: "grey col-md-1" }, [
        _vm._v(_vm._s(_vm.num_accionistas_pf_adicionales)),
      ]),
      _c("label", { staticClass: "grey col-md-5" }, [
        _vm._v(" Accionistas Personas Físicas "),
      ]),
      _c("label", { staticClass: "grey col-md-6" }, [
        _vm._v("$" + _vm._s(_vm.precio_accionistas_pf_adicionales)),
      ]),
    ]),
    _c("div", { staticClass: "row" }, [
      _c("label", { staticClass: "grey col-md-1" }, [
        _vm._v(_vm._s(_vm.num_accionistas_pm_total)),
      ]),
      _c("label", { staticClass: "grey col-md-5" }, [
        _vm._v(" Accionistas Personas Morales "),
      ]),
      _c("label", { staticClass: "grey col-md-6" }, [
        _vm._v("$" + _vm._s(_vm.precio_accionistas_pm_adicionales)),
      ]),
    ]),
    _c("div", { staticClass: "row" }, [
      _c("label", { staticClass: "grey col-md-1" }, [
        _vm._v(_vm._s(_vm.num_administradores_adicionales)),
      ]),
      _c("label", { staticClass: "grey col-md-5" }, [
        _vm._v(" Administradores "),
      ]),
      _c("label", { staticClass: "grey col-md-6" }, [
        _vm._v("$" + _vm._s(_vm.precio_administradores_adicionales)),
      ]),
    ]),
    _c("div", { staticClass: "row" }, [
      _c("label", { staticClass: "grey col-md-1" }, [
        _vm._v(_vm._s(_vm.num_apoderados_adicionales)),
      ]),
      _c("label", { staticClass: "grey col-md-5" }, [_vm._v(" Apoderados ")]),
      _c("label", { staticClass: "grey col-md-6" }, [
        _vm._v("$" + _vm._s(_vm.precio_apoderados_adicionales)),
      ]),
    ]),
    _c("div", { staticClass: "row" }, [
      _c("label", { staticClass: "grey col-md-1" }, [
        _vm._v(_vm._s(_vm.icon_capital_extranjero)),
      ]),
      _c("label", { staticClass: "grey col-md-5" }, [
        _vm._v(" Capital extranjero "),
      ]),
      _c("label", { staticClass: "grey col-md-6" }, [
        _vm._v("$" + _vm._s(_vm.precio_capital_extranjero)),
      ]),
    ]),
    _c("div", { staticClass: "row" }, [
      _c("label", { staticClass: "grey col-md-1" }, [
        _vm._v(_vm._s(_vm.icon_reglas_control)),
      ]),
      _c("label", { staticClass: "grey col-md-5" }, [
        _vm._v(" Reglas de control corporativo"),
      ]),
      _c("label", { staticClass: "grey col-md-6" }, [
        _vm._v("$" + _vm._s(_vm.precio_reglas_control)),
      ]),
    ]),
    _c("div", { staticClass: "row" }, [
      _c("label", { staticClass: "grey col-md-1" }, [
        _vm._v(_vm._s(_vm.icon_reglas_asambleas)),
      ]),
      _c("label", { staticClass: "grey col-md-5" }, [
        _vm._v(" Reglas para asambleas telemáticas "),
      ]),
      _c("label", { staticClass: "grey col-md-6" }, [
        _vm._v("$" + _vm._s(_vm.precio_reglas_asambleas)),
      ]),
    ]),
    _c("div", { staticClass: "row" }, [
      _c("label", { staticClass: "grey col-md-1" }, [
        _vm._v(_vm._s(_vm.icon_exclusion_accionistas)),
      ]),
      _c("label", { staticClass: "grey col-md-5" }, [
        _vm._v(" Exclusión de accionistas "),
      ]),
      _c("label", { staticClass: "grey col-md-6" }, [
        _vm._v("$" + _vm._s(_vm.precio_exclusion_accionistas)),
      ]),
    ]),
    _c("div", { staticClass: "row" }, [
      _c("label", { staticClass: "grey col-md-1" }, [
        _vm._v(_vm._s(_vm.icon_firma_fuera)),
      ]),
      _c("label", { staticClass: "grey col-md-5" }, [
        _vm._v(" Firma fuera de la oficina "),
      ]),
      _c("label", { staticClass: "grey col-md-6" }, [
        _vm._v("$" + _vm._s(_vm.precio_firma_fuera)),
      ]),
    ]),
    _c("div", { staticClass: "row mt-2" }, [
      _c("label", { staticClass: "grey col-md-6" }, [_vm._v(" Subtotal ")]),
      _c("label", { staticClass: "grey col-md-6" }, [
        _vm._v("$" + _vm._s(_vm.subtotal_adicionales)),
      ]),
    ]),
    _c("h4", { staticClass: "cardD mt-1" }, [_vm._v("Extras")]),
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-md-1" },
        [
          _c("b-form-checkbox", {
            attrs: { disabled: _vm.constitutiva.disabled },
            on: { change: _vm.setTitulos },
            model: {
              value: _vm.constitutiva.titulos_acciones,
              callback: function ($$v) {
                _vm.$set(_vm.constitutiva, "titulos_acciones", $$v)
              },
              expression: "constitutiva.titulos_acciones",
            },
          }),
        ],
        1
      ),
      _vm._m(0),
      _c("div", { staticClass: "col-md-6" }, [
        _c("label", { staticClass: "grey" }, [
          _vm._v("$" + _vm._s(_vm.constitutiva.subtotal_titulos)),
        ]),
      ]),
    ]),
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-md-1" },
        [
          _c("b-form-checkbox", {
            attrs: { disabled: _vm.constitutiva.disabled },
            on: { change: _vm.setLibros },
            model: {
              value: _vm.constitutiva.libros_corporativos,
              callback: function ($$v) {
                _vm.$set(_vm.constitutiva, "libros_corporativos", $$v)
              },
              expression: "constitutiva.libros_corporativos",
            },
          }),
        ],
        1
      ),
      _vm._m(1),
      _c("div", { staticClass: "col-md-6" }, [
        _c("label", { staticClass: "grey" }, [
          _vm._v("$" + _vm._s(_vm.constitutiva.subtotal_libros)),
        ]),
      ]),
    ]),
    _c("div", { staticClass: "row mt-2" }, [
      _vm._m(2),
      _c("div", { staticClass: "col-md-6" }, [
        _c("label", { staticClass: "grey" }, [
          _vm._v("$" + _vm._s(_vm.subtotal_extras)),
        ]),
      ]),
    ]),
    _c("h4", { staticClass: "cardD mt-1" }, [_vm._v("Gastos")]),
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-md-1" },
        [
          _c("b-form-checkbox", {
            attrs: { disabled: "" },
            model: {
              value: _vm.gastos_envio,
              callback: function ($$v) {
                _vm.gastos_envio = $$v
              },
              expression: "gastos_envio",
            },
          }),
        ],
        1
      ),
      _vm._m(3),
      _vm._m(4),
    ]),
    _c("div", { staticClass: "row mt-2" }, [
      _vm._m(5),
      _c("div", { staticClass: "col-md-6" }, [
        _c("label", { staticClass: "grey" }, [
          _vm._v("$" + _vm._s(_vm.subtotal_gastos)),
        ]),
      ]),
    ]),
    _c("h4", { staticClass: "cardD mt-1" }, [_vm._v("Cupones")]),
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-12" },
        [
          _c("base-input", {
            attrs: {
              type: "text",
              label: "Si cuenta con un cupón puede aplicarlo.",
              placeholder: "Cupón de descuento",
              disabled: _vm.cuponAplicado,
            },
            model: {
              value: _vm.nombreCuponDescuento,
              callback: function ($$v) {
                _vm.nombreCuponDescuento = $$v
              },
              expression: "nombreCuponDescuento",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-12 d-flex" },
        [
          _c("br"),
          _c(
            "b-button",
            {
              staticClass: "orange-btn",
              attrs: { size: "m", disabled: _vm.cuponAplicado },
              on: {
                click: function ($event) {
                  return _vm.validarCupon(_vm.nombreCuponDescuento)
                },
              },
            },
            [_vm._v("Aplicar")]
          ),
        ],
        1
      ),
    ]),
    _vm.cuponAplicado
      ? _c("div", { staticClass: "row mt-1" }, [
          _c("div", { staticClass: "col-md-6" }, [
            _c("label", { staticClass: "grey" }, [
              _vm._v("Descuento " + _vm._s(_vm.valorDescuentoCupon)),
            ]),
          ]),
          _c("div", { staticClass: "col-md-6" }, [
            _c("label", { staticClass: "grey" }, [
              _vm._v(_vm._s(_vm.formattedCantidadDescontadaCupon)),
            ]),
          ]),
        ])
      : _vm._e(),
    _c("div", { staticClass: "row mt-1" }, [
      _vm._m(6),
      _c("div", { staticClass: "col-md-6" }, [
        _c("h4", { staticClass: "cardD" }, [
          _vm._v(_vm._s(_vm.formattedPrecioTotal)),
        ]),
      ]),
    ]),
    _c("label", { staticClass: "mb-12 leyenda" }, [
      _vm._v(
        " Esta es una plataforma automática, no sustituye a una asesoría legal. "
      ),
    ]),
    _c("div", { staticClass: "mt-4" }, [
      _c(
        "div",
        {},
        [
          _c(
            "b-button",
            {
              staticClass: "btn-fill orange-btn bottom2",
              attrs: {
                type: "submit",
                size: "m",
                disabled: _vm.constitutiva.disabled,
              },
            },
            [_vm._v("Pagar")]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-md-5" }, [
      _c("label", { staticClass: "grey" }, [_vm._v("Títulos de acciones")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-md-5" }, [
      _c("label", { staticClass: "grey" }, [_vm._v("Libros corporativos")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-md-6" }, [
      _c("label", { staticClass: "grey" }, [_vm._v("Subtotal")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-md-5" }, [
      _c("label", { staticClass: "grey" }, [_vm._v("Gastos de envío")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-md-6" }, [
      _c("label", { staticClass: "grey" }, [_vm._v("$0")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-md-6" }, [
      _c("label", { staticClass: "grey" }, [_vm._v("Subtotal")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-md-6" }, [
      _c("h4", { staticClass: "cardD" }, [_vm._v("Total")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }