var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "container" }, [
      _c("p", [
        _vm._v(
          " Por el momento, no tenemos cobertura automática en el domicilio que señalas, pero no queremos dejar de atenderte. "
        ),
      ]),
      _c("p", [
        _vm._v(" Por favor mándanos un correo electrónico a la dirección: "),
        _c("a", { attrs: { href: "mailto:info@correduriadigital.mx" } }, [
          _vm._v("info@correduriadigital.mx"),
        ]),
        _vm._v(" en el cual nos indiques: "),
      ]),
      _c("ul", [
        _c("li", [
          _vm._v(
            " El domicilio en el que requieres que llevemos a cabo la notificación; y "
          ),
        ]),
        _c("li", [
          _vm._v(" El tipo de documento que quieres que entreguemos. "),
        ]),
      ]),
      _c("p", [
        _vm._v(
          " Un miembro de nuestro equipo se pondrá en contacto contigo a la brevedad. "
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }