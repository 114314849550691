var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { attrs: { id: "doc" } },
      [
        _c("VueDocPreview", {
          attrs: {
            value: _vm.currentDocumento.documento + _vm.stgAccessKey,
            type: "office",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }