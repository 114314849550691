var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-md-12" },
        [
          _c("ValidationProvider", {
            attrs: {
              name: "persona",
              rules: "required",
              "custom-messages": {
                required: "La persona es obligatoria",
              },
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ errors }) {
                  return [
                    _c("label", { attrs: { for: "" } }, [_vm._v("Persona")]),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.currentCompareciente.persona,
                            expression: "currentCompareciente.persona",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { disabled: "" },
                        on: {
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.currentCompareciente,
                              "persona",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                        },
                      },
                      _vm._l(_vm.optionsPersona, function (option) {
                        return _c(
                          "option",
                          {
                            key: option.value,
                            domProps: { value: option.value },
                          },
                          [_vm._v(" " + _vm._s(option.text) + " ")]
                        )
                      }),
                      0
                    ),
                    _c("span", { staticClass: "cardErrors" }, [
                      _vm._v(_vm._s(errors[0])),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ]),
    _vm.currentCompareciente.persona === "Moral"
      ? _c("div", { staticClass: "row mt-4" }, [
          _c(
            "div",
            { staticClass: "col-md-12" },
            [
              _c("ValidationProvider", {
                attrs: {
                  name: "denominacion_social",
                  rules: "required",
                  "custom-messages": {
                    required: "La denominacion social es obligatoria",
                  },
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function ({ errors }) {
                        return [
                          _c("base-input", {
                            attrs: {
                              disabled:
                                _vm.notificacion.validaSolicitantes == 1,
                              type: "text",
                              label: "Denominacion social",
                              placeholder: "Denominacion social",
                            },
                            model: {
                              value:
                                _vm.currentCompareciente.denominacion_social,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.currentCompareciente,
                                  "denominacion_social",
                                  $$v
                                )
                              },
                              expression:
                                "currentCompareciente.denominacion_social",
                            },
                          }),
                          _c("span", { staticClass: "cardErrors" }, [
                            _vm._v(_vm._s(errors[0])),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  4163656452
                ),
              }),
            ],
            1
          ),
        ])
      : _vm.currentCompareciente.persona === "Física"
      ? _c("div", { staticClass: "row mt-4" }, [
          _c(
            "div",
            { staticClass: "col-md-4" },
            [
              _c("ValidationProvider", {
                attrs: {
                  name: "nombre",
                  rules: "required",
                  "custom-messages": {
                    required: "El nombre es obligatorio",
                  },
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ errors }) {
                      return [
                        _c("base-input", {
                          attrs: {
                            disabled: _vm.notificacion.validaSolicitantes == 1,
                            type: "text",
                            label: "Nombre",
                            placeholder: "Nombre",
                          },
                          model: {
                            value: _vm.currentCompareciente.nombre,
                            callback: function ($$v) {
                              _vm.$set(_vm.currentCompareciente, "nombre", $$v)
                            },
                            expression: "currentCompareciente.nombre",
                          },
                        }),
                        _c("span", { staticClass: "cardErrors" }, [
                          _vm._v(_vm._s(errors[0])),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-md-4" },
            [
              _c("ValidationProvider", {
                attrs: {
                  name: "nombre",
                  rules: "required",
                  "custom-messages": {
                    required: "El paterno es obligatorio",
                  },
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ errors }) {
                      return [
                        _c("base-input", {
                          attrs: {
                            disabled: _vm.notificacion.validaSolicitantes == 1,
                            type: "text",
                            label: "Paterno",
                            placeholder: "Paterno",
                          },
                          model: {
                            value: _vm.currentCompareciente.paterno,
                            callback: function ($$v) {
                              _vm.$set(_vm.currentCompareciente, "paterno", $$v)
                            },
                            expression: "currentCompareciente.paterno",
                          },
                        }),
                        _c("span", { staticClass: "cardErrors" }, [
                          _vm._v(_vm._s(errors[0])),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-md-4" },
            [
              _c("base-input", {
                attrs: {
                  disabled: _vm.notificacion.validaSolicitantes == 1,
                  type: "text",
                  label: "Materno",
                  placeholder: "Materno",
                },
                model: {
                  value: _vm.currentCompareciente.materno,
                  callback: function ($$v) {
                    _vm.$set(_vm.currentCompareciente, "materno", $$v)
                  },
                  expression: "currentCompareciente.materno",
                },
              }),
            ],
            1
          ),
        ])
      : _vm._e(),
    _c("div", { staticClass: "row mt-4" }, [
      _vm.currentCompareciente.persona === "Física"
        ? _c(
            "div",
            { staticClass: "col-md-4" },
            [
              _c("ValidationProvider", {
                attrs: {
                  name: "rfc",
                  rules: {
                    required: true,
                    length: 13,
                    regex:
                      /^[A-Z&Ñ]{4}\d{2}(0[1-9]|1[0-2])(0[1-9]|[12]\d|3[01])[A-Z0-9]{3}$/,
                  },
                  "custom-messages": {
                    required: "El RFC es obligatorio",
                    length: `Debe tener 13 caracteres`,
                    regex: "El RFC no tiene el formato correcto",
                  },
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function ({ errors }) {
                        return [
                          _c("base-input", {
                            attrs: {
                              disabled:
                                _vm.notificacion.validaSolicitantes == 1,
                              type: "text",
                              label: "RFC",
                              placeholder: "RFC",
                            },
                            model: {
                              value: _vm.currentCompareciente.rfc,
                              callback: function ($$v) {
                                _vm.$set(_vm.currentCompareciente, "rfc", $$v)
                              },
                              expression: "currentCompareciente.rfc",
                            },
                          }),
                          _c("span", { staticClass: "cardErrors" }, [
                            _vm._v(_vm._s(errors[0])),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3691605367
                ),
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.currentCompareciente.persona === "Moral"
        ? _c(
            "div",
            { staticClass: "col-md-6" },
            [
              _c("ValidationProvider", {
                attrs: {
                  name: "rfc",
                  rules: {
                    required: true,
                    length: 12,
                    regex:
                      /^[A-Z&Ñ]{3}\d{2}(0[1-9]|1[0-2])(0[1-9]|[12]\d|3[01])[A-Z0-9]{3}$/,
                  },
                  "custom-messages": {
                    required: "El RFC es obligatorio",
                    length: `Debe tener 12 caracteres`,
                    regex: "El RFC no tiene el formato correcto",
                  },
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function ({ errors }) {
                        return [
                          _c("base-input", {
                            attrs: {
                              disabled:
                                _vm.notificacion.validaSolicitantes == 1,
                              type: "text",
                              label: "RFC",
                              placeholder: "RFC",
                            },
                            model: {
                              value: _vm.currentCompareciente.rfc,
                              callback: function ($$v) {
                                _vm.$set(_vm.currentCompareciente, "rfc", $$v)
                              },
                              expression: "currentCompareciente.rfc",
                            },
                          }),
                          _c("span", { staticClass: "cardErrors" }, [
                            _vm._v(_vm._s(errors[0])),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3691605367
                ),
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.currentCompareciente.persona === "Física"
        ? _c(
            "div",
            { staticClass: "col-md-4" },
            [
              _c("ValidationProvider", {
                attrs: {
                  name: "curp",
                  rules: {
                    required: true,
                    length: 18,
                    regex:
                      /^[A-Z&Ñ]{4}\d{6}[HM](AS|BC|BS|CC|CL|CM|CS|CH|DF|DG|GT|GR|HG|JC|MC|MN|MS|NT|NL|OC|PL|QT|QR|SP|SL|SR|TC|TS|TL|VZ|YN|ZS)[A-Z0-9]{3}\d{2}$/,
                  },
                  "custom-messages": {
                    required: "La CURP es obligatorio",
                    length: "Debe tener 18 caracteres",
                    regex: "La CURP no tiene el formato correcto",
                  },
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function ({ errors }) {
                        return [
                          _c("base-input", {
                            attrs: {
                              disabled:
                                _vm.notificacion.validaSolicitantes == 1,
                              type: "text",
                              label: "CURP",
                              placeholder: "CURP",
                            },
                            model: {
                              value: _vm.currentCompareciente.curp,
                              callback: function ($$v) {
                                _vm.$set(_vm.currentCompareciente, "curp", $$v)
                              },
                              expression: "currentCompareciente.curp",
                            },
                          }),
                          _c("span", { staticClass: "cardErrors" }, [
                            _vm._v(_vm._s(errors[0])),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  1926552244
                ),
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.currentCompareciente.persona === "Física"
        ? _c(
            "div",
            { staticClass: "col-md-4" },
            [
              _c("ValidationProvider", {
                attrs: {
                  name: "fecha_nacimiento",
                  rules: "required",
                  "custom-messages": {
                    required: "La fecha de nacimiento es obligatoria",
                  },
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function ({ errors }) {
                        return [
                          _c("base-input", {
                            attrs: {
                              disabled:
                                _vm.notificacion.validaSolicitantes == 1,
                              type: "date",
                              label: "Fecha de nacimiento",
                              placeholder: "Fecha de nacimiento",
                            },
                            model: {
                              value: _vm.currentCompareciente.fecha_nacimiento,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.currentCompareciente,
                                  "fecha_nacimiento",
                                  $$v
                                )
                              },
                              expression:
                                "currentCompareciente.fecha_nacimiento",
                            },
                          }),
                          _c("span", { staticClass: "cardErrors" }, [
                            _vm._v(_vm._s(errors[0])),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  1763514734
                ),
              }),
            ],
            1
          )
        : _vm.currentCompareciente.persona === "Moral"
        ? _c(
            "div",
            { staticClass: "col-md-6" },
            [
              _c("ValidationProvider", {
                attrs: {
                  name: "fecha_constitucion",
                  rules: "required",
                  "custom-messages": {
                    required: "La fecha de constitución es obligatoria",
                  },
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ errors }) {
                      return [
                        _c("base-input", {
                          attrs: {
                            disabled: _vm.notificacion.validaSolicitantes == 1,
                            type: "date",
                            label: "Fecha de constitución",
                            placeholder: "Fecha de constitución",
                          },
                          model: {
                            value: _vm.currentCompareciente.fecha_constitucion,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.currentCompareciente,
                                "fecha_constitucion",
                                $$v
                              )
                            },
                            expression:
                              "currentCompareciente.fecha_constitucion",
                          },
                        }),
                        _c("span", { staticClass: "cardErrors" }, [
                          _vm._v(_vm._s(errors[0])),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          )
        : _vm._e(),
    ]),
    _c("div", { staticClass: "row mt-4" }, [
      _vm.currentCompareciente.persona === "Física"
        ? _c(
            "div",
            { staticClass: "col-md-12" },
            [
              _c("ValidationProvider", {
                attrs: {
                  name: "ocupacion",
                  rules: "required",
                  "custom-messages": {
                    required: "La ocupación es obligatoria",
                  },
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function ({ errors }) {
                        return [
                          _c("base-input", {
                            attrs: {
                              disabled:
                                _vm.notificacion.validaSolicitantes == 1,
                              type: "text",
                              label: "Ocupación / Actividad / Profesión",
                              placeholder: "Ocupación",
                            },
                            model: {
                              value: _vm.currentCompareciente.ocupacion,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.currentCompareciente,
                                  "ocupacion",
                                  $$v
                                )
                              },
                              expression: "currentCompareciente.ocupacion",
                            },
                          }),
                          _c("span", { staticClass: "cardErrors" }, [
                            _vm._v(_vm._s(errors[0])),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  972380875
                ),
              }),
            ],
            1
          )
        : _vm._e(),
    ]),
    _vm.currentCompareciente.persona === "Física"
      ? _c("div", { staticClass: "row mb-4" }, [
          _c(
            "div",
            { staticClass: "col-md-4" },
            [
              _c("ValidationProvider", {
                attrs: {
                  name: "lugar_nacim_pais",
                  rules: "required",
                  "custom-messages": { required: "El país es obligatorio" },
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function ({ errors }) {
                        return [
                          _c("label", [_vm._v("Lugar de nacimiento: País")]),
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.selectCountry,
                                  expression: "selectCountry",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                disabled:
                                  _vm.notificacion.validaSolicitantes == 1,
                              },
                              on: {
                                change: [
                                  function ($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call(
                                        $event.target.options,
                                        function (o) {
                                          return o.selected
                                        }
                                      )
                                      .map(function (o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.selectCountry = $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  },
                                  _vm.fetchStatesByCountry,
                                ],
                              },
                            },
                            _vm._l(_vm.countries, function (option) {
                              return _c("option", { key: option.id }, [
                                _vm._v(" " + _vm._s(option.name) + " "),
                              ])
                            }),
                            0
                          ),
                          _c("span", { staticClass: "cardErrors" }, [
                            _vm._v(_vm._s(errors[0])),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3811174375
                ),
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-md-4" },
            [
              _c("ValidationProvider", {
                attrs: {
                  name: "lugar_nacim_estado",
                  rules: "required",
                  "custom-messages": {
                    required: "El estado es obligatorio",
                  },
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function ({ errors }) {
                        return [
                          _c("label", [_vm._v("Lugar de nacimiento: Estado")]),
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.selectState,
                                  expression: "selectState",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                disabled:
                                  _vm.notificacion.validaSolicitantes == 1,
                              },
                              on: {
                                change: [
                                  function ($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call(
                                        $event.target.options,
                                        function (o) {
                                          return o.selected
                                        }
                                      )
                                      .map(function (o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.selectState = $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  },
                                  _vm.fetchCitiesByState,
                                ],
                              },
                            },
                            [
                              _vm.isEmptyStates
                                ? _c(
                                    "option",
                                    {
                                      attrs: {
                                        value: "noStates",
                                        selected: "",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(_vm.noStatesText) + " "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._l(_vm.states, function (option) {
                                return _c("option", { key: option.id }, [
                                  _vm._v(" " + _vm._s(option.name) + " "),
                                ])
                              }),
                            ],
                            2
                          ),
                          _c("span", { staticClass: "cardErrors" }, [
                            _vm._v(_vm._s(errors[0])),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3982960711
                ),
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-md-4" },
            [
              _c("ValidationProvider", {
                attrs: {
                  name: "lugar_nacim_ciudad",
                  rules: "required",
                  "custom-messages": {
                    required: "La ciudad de nacimiento es obligatoria",
                  },
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function ({ errors }) {
                        return [
                          _c("label", [_vm._v("Municipio / Alcaldía")]),
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.selectCity,
                                  expression: "selectCity",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                disabled:
                                  _vm.notificacion.validaSolicitantes == 1,
                              },
                              on: {
                                change: function ($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function (o) {
                                      return o.selected
                                    })
                                    .map(function (o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.selectCity = $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                },
                              },
                            },
                            [
                              _vm.isEmptyCities
                                ? _c(
                                    "option",
                                    {
                                      attrs: {
                                        value: "noCities",
                                        selected: "",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(_vm.noCitiesText) + " "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._l(_vm.cities, function (option) {
                                return _c("option", { key: option.id }, [
                                  _vm._v(" " + _vm._s(option.name) + " "),
                                ])
                              }),
                            ],
                            2
                          ),
                          _c("span", { staticClass: "cardErrors" }, [
                            _vm._v(_vm._s(errors[0])),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  323450916
                ),
              }),
            ],
            1
          ),
        ])
      : _vm._e(),
    _c("div", { staticClass: "row mb-4" }, [
      _vm.currentCompareciente.persona === "Moral"
        ? _c(
            "div",
            { staticClass: "col-4" },
            [
              _c("ValidationProvider", {
                attrs: {
                  name: "lugar_nacim_pais",
                  rules: "required",
                  "custom-messages": { required: "El país es obligatorio" },
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function ({ errors }) {
                        return [
                          _c("label", [_vm._v("Lugar de nacimiento: País")]),
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.selectCountry,
                                  expression: "selectCountry",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                disabled:
                                  _vm.notificacion.validaSolicitantes == 1,
                              },
                              on: {
                                change: [
                                  function ($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call(
                                        $event.target.options,
                                        function (o) {
                                          return o.selected
                                        }
                                      )
                                      .map(function (o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.selectCountry = $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  },
                                  _vm.fetchStatesByCountry,
                                ],
                              },
                            },
                            _vm._l(_vm.countries, function (option) {
                              return _c("option", { key: option.id }, [
                                _vm._v(" " + _vm._s(option.name) + " "),
                              ])
                            }),
                            0
                          ),
                          _c("span", { staticClass: "cardErrors" }, [
                            _vm._v(_vm._s(errors[0])),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3811174375
                ),
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        {
          class: {
            "col-md-2": _vm.currentCompareciente.persona === "Física",
            "col-md-4": _vm.currentCompareciente.persona !== "Física",
          },
        },
        [
          _c("ValidationProvider", {
            attrs: {
              name: "clave",
              rules: "required",
              "custom-messages": {
                required: "La clave LADA es requerida",
              },
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ errors }) {
                  return [
                    _c("base-input", {
                      attrs: {
                        type: "text",
                        label: "Código país",
                        placeholder: "Código país",
                      },
                      model: {
                        value: _vm.phoneKey,
                        callback: function ($$v) {
                          _vm.phoneKey = $$v
                        },
                        expression: "phoneKey",
                      },
                    }),
                    _c("span", { staticClass: "cardErrors" }, [
                      _vm._v(_vm._s(errors[0])),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "div",
        {
          class: {
            "col-md-5": _vm.currentCompareciente.persona === "Física",
            "col-md-4": _vm.currentCompareciente.persona !== "Física",
          },
        },
        [
          _c("ValidationProvider", {
            attrs: {
              name: "numero",
              rules: "required|length:10",
              "custom-messages": {
                required: "El número de teléfono es requerido",
                length: "El número debe tener 10 dígitos",
              },
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ errors }) {
                  return [
                    _c("base-input", {
                      attrs: {
                        disabled: _vm.notificacion.validaSolicitantes == 1,
                        type: "text",
                        label: "Número de Teléfono Celular",
                        placeholder: "Número",
                      },
                      model: {
                        value: _vm.currentCompareciente.telefonos[0].numero,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.currentCompareciente.telefonos[0],
                            "numero",
                            $$v
                          )
                        },
                        expression: "currentCompareciente.telefonos[0].numero",
                      },
                    }),
                    _c("span", { staticClass: "cardErrors" }, [
                      _vm._v(_vm._s(errors[0])),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm.currentCompareciente.persona === "Física"
        ? _c(
            "div",
            { staticClass: "col-md-5" },
            [
              _c("ValidationProvider", {
                attrs: {
                  name: "email",
                  rules: "required",
                  "custom-messages": {
                    required: "El email es requerido",
                  },
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function ({ errors }) {
                        return [
                          _c("base-input", {
                            attrs: {
                              disabled:
                                _vm.notificacion.validaSolicitantes == 1,
                              type: "email",
                              label: "Email",
                              placeholder: "Email",
                            },
                            model: {
                              value: _vm.currentCompareciente.email,
                              callback: function ($$v) {
                                _vm.$set(_vm.currentCompareciente, "email", $$v)
                              },
                              expression: "currentCompareciente.email",
                            },
                          }),
                          _c("span", { staticClass: "cardErrors" }, [
                            _vm._v(_vm._s(errors[0])),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  1746556637
                ),
              }),
            ],
            1
          )
        : _vm._e(),
    ]),
    _c("div", { staticClass: "row mt-4" }),
    _c("div", { staticClass: "mt-4 text-center" }, [
      _c(
        "button",
        {
          staticClass: "btn btn-fill float-right orange-btn",
          attrs: { type: "submit" },
          on: { click: _vm.setGeoCompareciente },
        },
        [_vm._v(" Guardar ")]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }