var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { attrs: { id: "pdf" } },
      [
        _c("vue-pdf-embed", {
          attrs: { source: _vm.currentDocumento.documento + _vm.stgAccessKey },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }