var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-12" },
        [
          _c("ValidationProvider", {
            attrs: {
              name: "subject",
              rules: "required",
              "custom-messages": {
                required: "El asunto es obligatorio",
              },
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ errors }) {
                  return [
                    _c("base-input", {
                      attrs: {
                        type: "text",
                        label: "Asunto",
                        placeholder: "Asunto",
                      },
                      model: {
                        value: _vm.layout.subject,
                        callback: function ($$v) {
                          _vm.$set(_vm.layout, "subject", $$v)
                        },
                        expression: "layout.subject",
                      },
                    }),
                    _c("span", { staticClass: "cardErrors" }, [
                      _vm._v(_vm._s(errors[0])),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("label", [_vm._v("Cuerpo")]),
          _c(
            "div",
            [
              _c("ValidationProvider", {
                attrs: {
                  name: "body",
                  rules: "required",
                  "custom-messages": {
                    required: "El cuerpo es obligatorio",
                  },
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ errors }) {
                      return [
                        _c("vue-editor", {
                          attrs: { "editor-toolbar": _vm.customToolbar },
                          model: {
                            value: _vm.layout.body,
                            callback: function ($$v) {
                              _vm.$set(_vm.layout, "body", $$v)
                            },
                            expression: "layout.body",
                          },
                        }),
                        _c("span", { staticClass: "cardErrors" }, [
                          _vm._v(_vm._s(errors[0])),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c("br"),
          _c(
            "button",
            {
              staticClass: "btn orange-btn float-right",
              attrs: { type: "submit" },
            },
            [_vm._v(" Guardar ")]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }