var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container" }, [
    _c("h4", { staticClass: "cardD" }, [_vm._v("Pago Complementario")]),
    _c(
      "div",
      { staticClass: "mt-4" },
      [
        _c("ValidationProvider", {
          attrs: {
            name: "liga_pago",
            rules: "required",
            "custom-messages": { required: "La liga de pago es requerida" },
          },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function ({ errors }) {
                return [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "Liga de pago Openpay",
                        "label-for": "input-link-pago-complementario",
                      },
                    },
                    [
                      _c(
                        "b-input-group",
                        { staticClass: "mt-3" },
                        [
                          _c(
                            "b-input-group-append",
                            [
                              _c(
                                "b-button",
                                {
                                  directives: [
                                    {
                                      name: "b-tooltip",
                                      rawName: "v-b-tooltip.hover",
                                      modifiers: { hover: true },
                                    },
                                  ],
                                  attrs: {
                                    variant: _vm.isTextCopied
                                      ? "success"
                                      : "secondary",
                                    squared: "",
                                    title: "Copiar",
                                  },
                                  on: { click: _vm.copyLinkToClipboard },
                                },
                                [
                                  _vm.isTextCopied
                                    ? _c("b-icon", { attrs: { icon: "check" } })
                                    : _c("b-icon", {
                                        attrs: { icon: "clipboard" },
                                      }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c("b-form-input", {
                            attrs: {
                              id: "input-link-pago-complementario",
                              type: "link",
                              disabled:
                                _vm.formalizacion.disabledPagoComplementario &&
                                !_vm.forma.staff,
                              placeholder: "Link Openpay",
                            },
                            model: {
                              value: _vm.formalizacion.liga_pago_complementario,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.formalizacion,
                                  "liga_pago_complementario",
                                  $$v
                                )
                              },
                              expression:
                                "formalizacion.liga_pago_complementario",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("span", { staticClass: "cardErrors" }, [
                    _vm._v(_vm._s(errors[0])),
                  ]),
                ]
              },
            },
          ]),
        }),
      ],
      1
    ),
    _c("div", { staticClass: "mt-4" }, [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-9" },
          [
            _c(
              "b-button",
              {
                staticClass: "btn-fill orange-btn bottom2",
                attrs: {
                  type: "submit",
                  size: "m",
                  disabled: _vm.formalizacion.disabledPagoComplementario,
                },
              },
              [_vm._v("Enviar liga")]
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-3" },
          [
            _c(
              "b-button",
              {
                staticClass: "btn-fill bottom2",
                attrs: {
                  variant: "success",
                  size: "m",
                  disabled: _vm.formalizacion.disabledPagoComplementario,
                },
                on: {
                  click: function ($event) {
                    return _vm.validar()
                  },
                },
              },
              [_vm._v("Marcar como pagado")]
            ),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }