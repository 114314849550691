var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container" }, [
    _c("h4", { staticClass: "cardD" }, [_vm._v("Cotización")]),
    _c("h4", { staticClass: "cardD" }, [_vm._v("Resumen de Notificación")]),
    _c(
      "table",
      { staticClass: "table table-bordered table-hover table-fixed" },
      [
        _vm._m(0),
        _c("tbody", [
          _c("tr", [
            _c("td", {}, [_vm._v("✓")]),
            _c("td", {}, [_vm._v("Costo por zona indicada")]),
            _c("td", { staticClass: "font-weight-bold precio-col-container" }, [
              _vm._v(_vm._s(_vm.formattedCostoZona)),
            ]),
          ]),
        ]),
      ]
    ),
    _vm.cantidad_adicionales_fisicas > 0
      ? _c("h4", { staticClass: "cardD mt-1" }, [
          _vm._v("Solicitantes Adicionales"),
        ])
      : _vm._e(),
    _vm.cantidad_adicionales_fisicas > 0
      ? _c("div", [
          _c(
            "table",
            { staticClass: "table table-bordered table-hover table-fixed" },
            [
              _vm._m(1),
              _c("tbody", [
                _c("tr", [
                  _c("td", {}, [
                    _vm._v(_vm._s(_vm.cantidad_adicionales_fisicas)),
                  ]),
                  _c("td", {}, [_vm._v("Persona Física Adicional")]),
                  _c(
                    "td",
                    { staticClass: "font-weight-bold precio-col-container" },
                    [_vm._v(_vm._s(_vm.formattedPersonasAdicionales))]
                  ),
                ]),
              ]),
            ]
          ),
        ])
      : _vm._e(),
    _vm.cantidad_adicionales_morales > 0
      ? _c("div", {}, [
          _c(
            "table",
            { staticClass: "table table-bordered table-hover table-fixed" },
            [
              _vm._m(2),
              _c("tbody", [
                _c("tr", [
                  _c("td", {}, [
                    _vm._v(_vm._s(_vm.cantidad_adicionales_morales)),
                  ]),
                  _c("td", {}, [_vm._v("Persona Moral Adicional")]),
                  _c("td", { staticClass: "font-weight-bold" }, [
                    _vm._v(_vm._s(_vm.formattedPersonaMoralAdicional)),
                  ]),
                ]),
              ]),
            ]
          ),
        ])
      : _vm._e(),
    _vm.cantidad_adicionales_representantes > 0
      ? _c("div", {}, [
          _c(
            "table",
            { staticClass: "table table-bordered table-hover table-fixed" },
            [
              _vm._m(3),
              _c("tbody", [
                _c("tr", [
                  _c("td", {}, [
                    _vm._v(_vm._s(_vm.cantidad_adicionales_representantes)),
                  ]),
                  _c("td", {}, [_vm._v("Representante Adicional")]),
                  _c(
                    "td",
                    { staticClass: "font-weight-bold precio-col-container" },
                    [_vm._v(_vm._s(_vm.formattedRepresentanteAdicional))]
                  ),
                ]),
              ]),
            ]
          ),
        ])
      : _vm._e(),
    _vm.subtotal_solicitantes > 0
      ? _c("div", { staticClass: "mt-2" }, [
          _c("div", { staticClass: "d-flex justify-content-around" }, [
            _vm._m(4),
            _c("div", [
              _c("p", { staticClass: "orange-text" }, [
                _vm._v(_vm._s(_vm.subtotal_solicitantes)),
              ]),
            ]),
          ]),
        ])
      : _vm._e(),
    _vm.subtotal_documentos > 0
      ? _c("h4", { staticClass: "cardD mt-1" }, [_vm._v("Documentos")])
      : _vm._e(),
    _vm.cantidad_documentos_adicionales > 0
      ? _c("div", { staticClass: "mt-2" }, [
          _c(
            "table",
            { staticClass: "table table-bordered table-hover table-fixed" },
            [
              _vm._m(5),
              _c("tbody", [
                _c("tr", [
                  _c("td", {}, [
                    _vm._v(_vm._s(_vm.cantidad_documentos_adicionales)),
                  ]),
                  _c("td", {}, [_vm._v("Documentos Adicionales")]),
                  _c(
                    "td",
                    { staticClass: "font-weight-bold precio-col-container" },
                    [_vm._v(_vm._s(_vm.formattedDocumentosAdicionales))]
                  ),
                ]),
              ]),
            ]
          ),
        ])
      : _vm._e(),
    _vm.notificacion.fad
      ? _c("div", {}, [
          _c(
            "table",
            { staticClass: "table table-bordered table-hover table-fixed" },
            [
              _vm._m(6),
              _c("tbody", [
                _c("tr", [
                  _c("td", {}, [_vm._v("✓")]),
                  _c("td", {}, [_vm._v("Firma Autógrafa Digital")]),
                  _c(
                    "td",
                    { staticClass: "font-weight-bold precio-col-container" },
                    [_vm._v(_vm._s(_vm.formattedFad))]
                  ),
                ]),
              ]),
            ]
          ),
        ])
      : _vm._e(),
    _vm.paginas_extra > 0
      ? _c("div", { staticClass: "mt-2" }, [
          _c(
            "table",
            { staticClass: "table table-bordered table-hover table-fixed" },
            [
              _vm._m(7),
              _c("tbody", [
                _c("tr", [
                  _c("td", {}, [_vm._v(_vm._s(_vm.paginas_extra))]),
                  _c("td", {}, [_vm._v("Páginas Adicionales")]),
                  _c(
                    "td",
                    { staticClass: "font-weight-bold precio-col-container" },
                    [_vm._v(_vm._s(_vm.formattedPaginasExtra))]
                  ),
                ]),
              ]),
            ]
          ),
        ])
      : _vm._e(),
    _vm.notificacion.contenido_digital
      ? _c("div", {}, [
          _c(
            "table",
            { staticClass: "table table-bordered table-hover table-fixed" },
            [
              _vm._m(8),
              _c("tbody", [
                _c("tr", [
                  _c("td", {}, [_vm._v("✓")]),
                  _c("td", {}, [_vm._v("Contenido Digital")]),
                  _c(
                    "td",
                    { staticClass: "font-weight-bold precio-col-container" },
                    [_vm._v(_vm._s(_vm.formattedContenidoDigital))]
                  ),
                ]),
              ]),
            ]
          ),
        ])
      : _vm._e(),
    _c("h4", { staticClass: "cardD" }, [_vm._v("Agregar Extras")]),
    _c("div", { staticClass: "row mt-4" }, [
      _c("div", { staticClass: "col-md-12" }, [
        _c(
          "div",
          { staticStyle: { display: "flex", "align-items": "center" } },
          [
            _c(
              "b-form-checkbox",
              {
                model: {
                  value: _vm.notificacion.acta_express,
                  callback: function ($$v) {
                    _vm.$set(_vm.notificacion, "acta_express", $$v)
                  },
                  expression: "notificacion.acta_express",
                },
              },
              [
                _vm._v(
                  " Generación de acta y entregable en plazo de 2-4 días hábiles "
                ),
              ]
            ),
          ],
          1
        ),
      ]),
    ]),
    _c("div", { staticClass: "row mt-4" }, [
      _c("div", { staticClass: "col-md-12" }, [
        _c(
          "div",
          { staticStyle: { display: "flex", "align-items": "center" } },
          [
            _c(
              "b-form-checkbox",
              {
                model: {
                  value: _vm.notificacion.envio_domicilio,
                  callback: function ($$v) {
                    _vm.$set(_vm.notificacion, "envio_domicilio", $$v)
                  },
                  expression: "notificacion.envio_domicilio",
                },
              },
              [_vm._v(" Envío a domicilio del entregable ")]
            ),
          ],
          1
        ),
      ]),
    ]),
    _vm.subtotal_extras > 0 ? _c("hr") : _vm._e(),
    _vm.subtotal_extras > 0
      ? _c("h4", { staticClass: "cardD" }, [_vm._v("Extras")])
      : _vm._e(),
    _vm.notificacion.acta_express
      ? _c("div", {}, [
          _c("table", { staticClass: "table table-bordered table-hover" }, [
            _vm._m(9),
            _c("tbody", [
              _c("tr", [
                _c("td", { attrs: { scope: "row" } }, [_vm._v("✓")]),
                _c("td", {}, [
                  _vm._v(
                    "Generación de acta y entregable en plazo de 2-4 días hábiles"
                  ),
                ]),
                _c(
                  "td",
                  { staticClass: "font-weight-bold precio-col-container" },
                  [_vm._v(_vm._s(_vm.formattedActaExpress))]
                ),
              ]),
            ]),
          ]),
        ])
      : _vm._e(),
    _vm.notificacion.envio_domicilio ? _c("div", {}, [_vm._m(10)]) : _vm._e(),
    _vm.notificacion.envio_domicilio
      ? _c("h4", { staticClass: "cardD mt-1" }, [_vm._v("Descuentos")])
      : _vm._e(),
    _vm.notificacion.envio_domicilio ? _c("div", {}, [_vm._m(11)]) : _vm._e(),
    _c("h4", { staticClass: "cardD mt-1" }, [_vm._v("Cupones")]),
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-12" },
        [
          _c("base-input", {
            attrs: {
              type: "text",
              label: "Si cuenta con un cupón puede aplicarlo.",
              placeholder: "Cupón de descuento",
              disabled: _vm.cuponAplicado,
            },
            model: {
              value: _vm.nombreCuponDescuento,
              callback: function ($$v) {
                _vm.nombreCuponDescuento = $$v
              },
              expression: "nombreCuponDescuento",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-12 d-flex" },
        [
          _c("br"),
          _c(
            "b-button",
            {
              staticClass: "orange-btn",
              attrs: { size: "m", disabled: _vm.cuponAplicado },
              on: {
                click: function ($event) {
                  return _vm.validarCupon(_vm.nombreCuponDescuento)
                },
              },
            },
            [_vm._v("Aplicar")]
          ),
        ],
        1
      ),
    ]),
    _vm.cuponAplicado
      ? _c("div", { staticClass: "mt-1" }, [
          _c("div", { staticClass: "container" }, [
            _c("div", { staticClass: "row justify-content-end" }, [
              _c("div", { staticClass: "col-3" }, [
                _c(
                  "table",
                  { staticClass: "table table-bordered table-hover" },
                  [
                    _c("thead", {}, [
                      _c("tr", [
                        _c("th", { staticClass: "precio-col" }, [
                          _vm._v(
                            "Descuento del cupón " +
                              _vm._s(_vm.cantidadDescuentoCupon)
                          ),
                        ]),
                      ]),
                    ]),
                    _c("tbody", [
                      _c("tr", [
                        _c(
                          "td",
                          {
                            staticClass:
                              "font-weight-bold precio-col-container",
                          },
                          [_vm._v(_vm._s(_vm.formattedValorCupon))]
                        ),
                      ]),
                    ]),
                  ]
                ),
              ]),
            ]),
          ]),
        ])
      : _vm._e(),
    _c("div", { staticClass: "mt-3" }, [
      _c("h4", { staticClass: "cardD mt-3 font-weight-bold" }, [
        _vm._v(_vm._s(_vm.textoTotalAPagar)),
      ]),
      _c("div", { staticClass: "mt-2" }, [
        _c("div", { staticClass: "font-weight-bold total" }, [
          _c("div", [
            _c("p", { staticClass: "orange-text" }, [
              _vm._v(_vm._s(_vm.formattedTotal)),
            ]),
          ]),
        ]),
      ]),
    ]),
    _c("hr"),
    _c("div", { staticClass: "row mt-4" }, [
      _c(
        "div",
        { staticClass: "col-md-12 d-flex" },
        [
          _c("ValidationProvider", {
            attrs: {
              name: "validacionDatos",
              rules: { required: { allowFalse: false } },
              "custom-messages": { required: "La confirmación es obligatoria" },
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ errors }) {
                  return [
                    _c(
                      "div",
                      { staticStyle: { display: "flex" } },
                      [
                        _c(
                          "b-form-checkbox",
                          {
                            staticClass: "orange",
                            attrs: { id: "checkbox-validacionDatos" },
                            model: {
                              value: _vm.validacionDatos,
                              callback: function ($$v) {
                                _vm.validacionDatos = $$v
                              },
                              expression: "validacionDatos",
                            },
                          },
                          [
                            _vm._v(
                              " Confirmo que la información proporcionada es correcta. "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c("span", { staticClass: "cardErrors" }, [
                      _vm._v(_vm._s(errors[0])),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ]),
    !_vm.notificacion.fad
      ? _c("div", { staticClass: "row mt-4" }, [
          _c(
            "div",
            { staticClass: "col-md-12" },
            [
              _c("ValidationProvider", {
                attrs: {
                  name: "validacionPaginas",
                  rules: { required: { allowFalse: false } },
                  "custom-messages": {
                    required: "La confirmación es obligatoria",
                  },
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function ({ errors }) {
                        return [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                display: "flex",
                                "align-items": "center",
                              },
                            },
                            [
                              _c(
                                "b-form-checkbox",
                                {
                                  staticClass: "orange",
                                  attrs: { id: "checkbox-validacionPaginas" },
                                  model: {
                                    value: _vm.validacionPaginas,
                                    callback: function ($$v) {
                                      _vm.validacionPaginas = $$v
                                    },
                                    expression: "validacionPaginas",
                                  },
                                },
                                [
                                  _vm._v(
                                    " Acepto que debo hacer llegar los documentos originales a la oficina que me sea señalada, o bien a la oficina de Correduría Digital, para que se pueda llevar a cabo la diligencia. "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c("span", { staticClass: "cardErrors" }, [
                            _vm._v(_vm._s(errors[0])),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3098095079
                ),
              }),
            ],
            1
          ),
        ])
      : _vm._e(),
    _c("div", { staticClass: "row mt-4" }, [
      _c(
        "div",
        { staticClass: "col-md-12" },
        [
          _c("ValidationProvider", {
            attrs: {
              name: "validacionTerminos",
              rules: { required: { allowFalse: false } },
              "custom-messages": { required: "La confirmación es obligatoria" },
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ errors }) {
                  return [
                    _c(
                      "div",
                      { staticStyle: { display: "flex" } },
                      [
                        _c(
                          "b-form-checkbox",
                          {
                            staticClass: "orange",
                            attrs: { id: "checkbox-validacionTerminos" },
                            model: {
                              value: _vm.validacionTerminos,
                              callback: function ($$v) {
                                _vm.validacionTerminos = $$v
                              },
                              expression: "validacionTerminos",
                            },
                          },
                          [_vm._v(" Acepto los Términos y condiciones. ")]
                        ),
                      ],
                      1
                    ),
                    _c("span", { staticClass: "cardErrors" }, [
                      _vm._v(_vm._s(errors[0])),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "row mt-4" }, [
      _c(
        "div",
        { staticClass: "col-md-12" },
        [
          _c("ValidationProvider", {
            attrs: {
              name: "validacionMercantil",
              rules: { required: { allowFalse: false } },
              "custom-messages": { required: "La confirmación es obligatoria" },
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ errors }) {
                  return [
                    _c(
                      "div",
                      { staticStyle: { display: "flex" } },
                      [
                        _c(
                          "b-form-checkbox",
                          {
                            staticClass: "orange",
                            attrs: { id: "checkbox-validacionMercantil" },
                            model: {
                              value: _vm.validacionMercantil,
                              callback: function ($$v) {
                                _vm.validacionMercantil = $$v
                              },
                              expression: "validacionMercantil",
                            },
                          },
                          [
                            _vm._v(
                              " Confirmo que el o los documentos que serán notificados son de carácter mercantil y que alguna de las partes (emisor o notificado) es comerciante. "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c("span", { staticClass: "cardErrors" }, [
                      _vm._v(_vm._s(errors[0])),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "row mt-4" }, [
      _c(
        "div",
        { staticClass: "col-md-12" },
        [
          _c("ValidationProvider", {
            attrs: {
              name: "validacionInfoBase",
              rules: { required: { allowFalse: false } },
              "custom-messages": { required: "La confirmación es obligatoria" },
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ errors }) {
                  return [
                    _c(
                      "div",
                      { staticStyle: { display: "flex" } },
                      [
                        _c(
                          "b-form-checkbox",
                          {
                            staticClass: "orange",
                            attrs: { id: "checkbox-validacionInfoBase" },
                            model: {
                              value: _vm.validacionInfoBase,
                              callback: function ($$v) {
                                _vm.validacionInfoBase = $$v
                              },
                              expression: "validacionInfoBase",
                            },
                          },
                          [
                            _vm._v(
                              " Una vez aceptada la cotización, las condiciones de la notificación solicitada no podrán cambiar. "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c("span", { staticClass: "cardErrors" }, [
                      _vm._v(_vm._s(errors[0])),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "mt-4 d-flex justify-content-end" }, [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-12" },
          [
            _c(
              "b-button",
              {
                staticClass: "orange-btn",
                attrs: { type: "submit", size: "m" },
              },
              [_vm._v("Pagar")]
            ),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", {}, [
      _c("tr", [
        _c("th", { staticClass: "check-col" }, [_vm._v("Check")]),
        _c("th", { staticClass: "descripcion-col" }, [_vm._v("Descripción")]),
        _c("th", { staticClass: "precio-col" }, [_vm._v("Precio")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", {}, [
      _c("tr", [
        _c("th", { staticClass: "check-col" }, [_vm._v("Cantidad")]),
        _c("th", { staticClass: "descripcion-col" }, [_vm._v("Descripción")]),
        _c("th", { staticClass: "precio-col" }, [_vm._v("Precio")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", {}, [
      _c("tr", [
        _c("th", { staticClass: "check-col" }, [_vm._v("Cantidad")]),
        _c("th", { staticClass: "descripcion-col" }, [_vm._v("Descripción")]),
        _c("th", { staticClass: "precio-col" }, [_vm._v("Precio")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", {}, [
      _c("tr", [
        _c("th", { staticClass: "check-col" }, [_vm._v("Cantidad ")]),
        _c("th", { staticClass: "descripcion-col" }, [_vm._v("Descripción")]),
        _c("th", { staticClass: "precio-col" }, [_vm._v("Precio")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("p", { staticClass: "font-weight-bold" }, [_vm._v("Subtotal")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", {}, [
      _c("tr", [
        _c("th", { staticClass: "check-col" }, [_vm._v("Cantidad")]),
        _c("th", { staticClass: "descripcion-col" }, [_vm._v("Descripción")]),
        _c("th", { staticClass: "precio-col" }, [_vm._v("Precio")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", {}, [
      _c("tr", [
        _c("th", { staticClass: "check-col" }, [_vm._v("Check")]),
        _c("th", { staticClass: "descripcion-col" }, [_vm._v("Descripción")]),
        _c("th", { staticClass: "precio-col" }, [_vm._v("Precio")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", {}, [
      _c("tr", [
        _c("th", { staticClass: "check-col" }, [_vm._v("Cantidad")]),
        _c("th", { staticClass: "descripcion-col" }, [_vm._v("Descripción")]),
        _c("th", { staticClass: "precio-col" }, [_vm._v("Precio")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", {}, [
      _c("tr", [
        _c("th", { staticClass: "check-col" }, [_vm._v("Check")]),
        _c("th", { staticClass: "descripcion-col" }, [_vm._v("Descripción")]),
        _c("th", { staticClass: "precio-col" }, [_vm._v("Precio")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", {}, [
      _c("tr", [
        _c("th", { staticClass: "check-col" }, [_vm._v("Check")]),
        _c("th", { staticClass: "descripcion-col" }, [_vm._v("Descripción")]),
        _c("th", { staticClass: "precio-col" }, [_vm._v("Precio")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("table", { staticClass: "table table-bordered table-hover" }, [
      _c("thead", {}, [
        _c("tr", [
          _c("th", { staticClass: "check-col" }, [_vm._v("Check")]),
          _c("th", { staticClass: "descripcion-col" }, [_vm._v("Descripción")]),
          _c("th", { attrs: { calss: "precio-col" } }, [_vm._v("Precio")]),
        ]),
      ]),
      _c("tbody", [
        _c("tr", [
          _c("td", { attrs: { scope: "row" } }, [_vm._v("✓")]),
          _c("td", {}, [_vm._v("Envío a domicilio del entregables")]),
          _c("td", { staticClass: "font-weight-bold precio-col-container" }, [
            _vm._v("$ 500.00"),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("table", { staticClass: "table table-bordered table-hover" }, [
      _c("thead", {}, [
        _c("tr", [
          _c("th", { staticClass: "check-col" }, [_vm._v("Check")]),
          _c("th", { staticClass: "descripcion-col" }, [_vm._v("Descripción")]),
          _c("th", { staticClass: "precio-col" }, [_vm._v("Precio")]),
        ]),
      ]),
      _c("tbody", [
        _c("tr", [
          _c("td", {}, [_vm._v("✓")]),
          _c("td", {}, [_vm._v("Envío a domicilio del entregable")]),
          _c("td", { staticClass: "font-weight-bold precio-col-container" }, [
            _vm._v("- $500.00"),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }