import { render, staticRenderFns } from "./ModalFirmantes.vue?vue&type=template&id=55f8f1b3"
import script from "./ModalFirmantes.vue?vue&type=script&lang=js"
export * from "./ModalFirmantes.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/github/workspace/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('55f8f1b3')) {
      api.createRecord('55f8f1b3', component.options)
    } else {
      api.reload('55f8f1b3', component.options)
    }
    module.hot.accept("./ModalFirmantes.vue?vue&type=template&id=55f8f1b3", function () {
      api.rerender('55f8f1b3', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/clients/procesos/formalizacionactas/infoBase/modales/ModalFirmantes.vue"
export default component.exports