var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-md-12" },
        [
          _c("ValidationProvider", {
            attrs: {
              name: "nombre",
              rules: "required",
              "custom-messages": {
                required: "El nombre del documento es obligatorio",
              },
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ errors }) {
                  return [
                    _c("label", { attrs: { for: "" } }, [
                      _vm._v("Título del documento"),
                    ]),
                    _c("base-input", {
                      attrs: { type: "text", placeholder: "Nombre" },
                      model: {
                        value: _vm.currentDocumento.nombre,
                        callback: function ($$v) {
                          _vm.$set(_vm.currentDocumento, "nombre", $$v)
                        },
                        expression: "currentDocumento.nombre",
                      },
                    }),
                    _c("span", { staticClass: "cardErrors" }, [
                      _vm._v(_vm._s(errors[0])),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "row mt-4" }, [
      _c(
        "div",
        { staticClass: "col-md-12" },
        [
          _c("ValidationProvider", {
            attrs: {
              name: "paginas",
              rules: "required",
              "custom-messages": {
                required: "La cantidad de páginas es obligatoria",
              },
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ errors }) {
                  return [
                    _c("label", { attrs: { for: "" } }, [
                      _vm._v("Número de páginas del documento"),
                    ]),
                    _c("base-input", {
                      staticClass: "custom-number",
                      attrs: {
                        type: "number",
                        required: "",
                        placeholder: "1",
                        min: 1,
                      },
                      model: {
                        value: _vm.currentDocumento.paginas,
                        callback: function ($$v) {
                          _vm.$set(_vm.currentDocumento, "paginas", $$v)
                        },
                        expression: "currentDocumento.paginas",
                      },
                    }),
                    _c("span", { staticClass: "cardErrors" }, [
                      _vm._v(_vm._s(errors[0])),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "row mt-4" }, [
      _c(
        "div",
        { staticClass: "col-md-12" },
        [
          _c("ValidationProvider", {
            attrs: { name: "anexo" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ errors }) {
                  return [
                    _c(
                      "b-form-checkbox",
                      {
                        attrs: { id: "chkTieneAnexo" },
                        model: {
                          value: _vm.currentDocumento.tiene_anexo,
                          callback: function ($$v) {
                            _vm.$set(_vm.currentDocumento, "tiene_anexo", $$v)
                          },
                          expression: "currentDocumento.tiene_anexo",
                        },
                      },
                      [
                        _c("label", { attrs: { for: "chkTieneAnexo" } }, [
                          _vm._v("El documento contiene anexo"),
                        ]),
                      ]
                    ),
                    _c("span", { staticClass: "cardErrors" }, [
                      _vm._v(_vm._s(errors[0])),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ]),
    _vm.currentDocumento.tiene_anexo
      ? _c("div", { staticClass: "row mt-4" }, [
          _c(
            "div",
            { staticClass: "col-md-12" },
            [
              _c("ValidationProvider", {
                attrs: {
                  name: "numero_anexos",
                  rules: "required",
                  "custom-messages": {
                    required: "La cantidad de páginas es obligatoria",
                  },
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function ({ errors }) {
                        return [
                          _c("label", { attrs: { for: "" } }, [
                            _vm._v("Número de anexos"),
                          ]),
                          _c("base-input", {
                            staticClass: "custom-number",
                            attrs: {
                              type: "number",
                              required: "",
                              placeholder: "1",
                              min: 1,
                            },
                            model: {
                              value: _vm.currentDocumento.numero_anexos,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.currentDocumento,
                                  "numero_anexos",
                                  $$v
                                )
                              },
                              expression: "currentDocumento.numero_anexos",
                            },
                          }),
                          _c("span", { staticClass: "cardErrors" }, [
                            _vm._v(_vm._s(errors[0])),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  999493930
                ),
              }),
            ],
            1
          ),
        ])
      : _vm._e(),
    _vm.currentDocumento.tiene_anexo
      ? _c(
          "div",
          _vm._l(_vm.currentDocumento.numero_anexos, function (anexo, index) {
            return _c("div", { key: index, staticClass: "row mt-4" }, [
              _c(
                "div",
                { staticClass: "col-md-12" },
                [
                  _c("ValidationProvider", {
                    attrs: {
                      name: "paginas_anexo",
                      rules: "required",
                      "custom-messages": {
                        required: "La cantidad de páginas es obligatoria",
                      },
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function ({ errors }) {
                            return [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v(
                                  "Número de páginas del anexo " + _vm._s(anexo)
                                ),
                              ]),
                              _c("base-input", {
                                staticClass: "custom-number",
                                attrs: {
                                  type: "number",
                                  required: "",
                                  placeholder: "1",
                                  min: 1,
                                },
                                model: {
                                  value:
                                    _vm.currentDocumento.paginas_anexos[index],
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.currentDocumento.paginas_anexos,
                                      index,
                                      $$v
                                    )
                                  },
                                  expression:
                                    "currentDocumento.paginas_anexos[index]",
                                },
                              }),
                              _c("span", { staticClass: "cardErrors" }, [
                                _vm._v(_vm._s(errors[0])),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  }),
                ],
                1
              ),
            ])
          }),
          0
        )
      : _vm._e(),
    _vm._m(0),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "mt-4 text-center" }, [
      _c(
        "button",
        {
          staticClass: "btn btn-fill float-right orange-btn",
          attrs: { type: "submit" },
        },
        [_vm._v(" Guardar ")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }