var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content" },
    [
      _c(
        "b-breadcrumb",
        [
          _c("b-breadcrumb-item", { attrs: { active: "" } }, [
            _vm._v("Configuraciones"),
          ]),
        ],
        1
      ),
      _c("div", { staticClass: "container" }, [
        _vm.loading
          ? _c("div", [
              _c(
                "div",
                { attrs: { id: "loader" } },
                [
                  _c("font-awesome-icon", {
                    attrs: { icon: "spinner", spin: "" },
                  }),
                ],
                1
              ),
            ])
          : _vm._e(),
      ]),
      _c("div", { staticClass: "container-fluid" }, [
        _c(
          "div",
          [
            _c(
              "b-card",
              { attrs: { "no-body": "" } },
              [
                _c(
                  "b-tabs",
                  { attrs: { "content-class": "mt-3", fill: "" } },
                  [
                    _c(
                      "b-tab",
                      { attrs: { title: "Catálogo de gastos", active: "" } },
                      [_c("CatGastos", { attrs: { loading: _vm.loading } })],
                      1
                    ),
                    _c(
                      "b-tab",
                      { attrs: { title: "Layouts de emails" } },
                      [
                        _c("LayoutsDeEmails", {
                          attrs: { layout: _vm.layout },
                          on: {
                            getLayout: function ($event) {
                              return _vm.getLayout($event, _vm.layout.id)
                            },
                            deleteFile: function ($event) {
                              return _vm.deleteFile($event, _vm.layout.id)
                            },
                            saveLayout: function ($event) {
                              return _vm.saveLayout($event, _vm.layout.id)
                            },
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "b-tab",
                      { attrs: { title: "Cupones" } },
                      [_c("Cupones")],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }