var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "content" }, [
    _c(
      "div",
      [
        _c("b-form-file", {
          on: { input: _vm.onInput },
          model: {
            value: _vm.file,
            callback: function ($$v) {
              _vm.file = $$v
            },
            expression: "file",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }