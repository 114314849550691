var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "form-check-radio" }, [
    _c("label", { staticClass: "form-check-label", attrs: { for: _vm.cbId } }, [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.model,
            expression: "model",
          },
        ],
        attrs: { id: _vm.cbId, type: "radio", disabled: _vm.disabled },
        domProps: { value: _vm.label, checked: _vm._q(_vm.model, _vm.label) },
        on: {
          change: function ($event) {
            _vm.model = _vm.label
          },
        },
      }),
      _c("span", { staticClass: "form-check-sign" }, [_vm._t("default")], 2),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }