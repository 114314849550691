var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c(
        "b-modal",
        {
          ref: "modal-confirm-fad",
          attrs: {
            "no-close-on-backdrop": "",
            centered: "",
            "hide-footer": "",
            id: "modal-confirm-fad",
          },
        },
        [
          _vm.loading
            ? _c("div", [
                _c(
                  "div",
                  { attrs: { id: "loader" } },
                  [
                    _c("font-awesome-icon", {
                      attrs: { icon: "spinner", spin: "" },
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _c("h4", { staticClass: "text-center" }, [
            _vm._v(
              " ¿Está seguro de enviar a FAD? El cliente será notificado. "
            ),
          ]),
          _c("h5", { staticClass: "text-center" }),
          _c(
            "b-button",
            {
              staticClass: "mt-3",
              attrs: { variant: "danger" },
              on: {
                click: function ($event) {
                  return _vm.$bvModal.hide("modal-confirm-fad")
                },
              },
            },
            [_vm._v("Cancelar")]
          ),
          _c(
            "b-button",
            {
              staticClass: "mt-3 float-right",
              attrs: { variant: "primary" },
              on: {
                click: function ($event) {
                  return _vm.sendToFad()
                },
              },
            },
            [_vm._v("OK")]
          ),
        ],
        1
      ),
      _c("h4", { staticClass: "cardD" }, [_vm._v("Firma Digital")]),
      _c("div", { staticClass: "mt-4" }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-12" },
            [
              _c(
                "b-button",
                {
                  staticClass: "btn-fill orange-btn bottom2",
                  attrs: {
                    type: "button",
                    block: "",
                    size: "m",
                    disabled:
                      _vm.notificacion.id_estatus == 11 ||
                      _vm.notificacion.id_estatus == 12 ||
                      _vm.notificacion.id_estatus == 13 ||
                      _vm.notificacion.id_estatus == 23,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.openSendToFadModal()
                    },
                  },
                },
                [_vm._v(" Enviar a FAD ")]
              ),
            ],
            1
          ),
        ]),
      ]),
      _vm.notificacion.staff
        ? _c("div", { staticClass: "mt-4" }, [
            _c(
              "div",
              {},
              [
                _c(
                  "b-button",
                  {
                    staticClass: "btn-fill orange-btn bottom2",
                    attrs: {
                      type: "submit",
                      size: "m",
                      disabled:
                        _vm.notificacion.id_estatus == 11 ||
                        _vm.notificacion.id_estatus == 12 ||
                        _vm.notificacion.id_estatus == 13 ||
                        _vm.notificacion.id_estatus == 23,
                    },
                  },
                  [_vm._v(" Verificar firma ")]
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }