var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("footer", { staticClass: "footer" }, [
    _c("div", { staticClass: "copyright version" }, [
      _vm._v(" Versión: " + _vm._s(_vm.version) + " "),
    ]),
    _c("div", { staticClass: "container-fluid" }, [
      _c("div", { staticClass: "copyright" }, [
        _vm._v(
          " © " + _vm._s(new Date().getFullYear()) + " Correduría Digital "
        ),
      ]),
      _c("div", { staticClass: "copyright" }, [
        _c(
          "a",
          { attrs: { href: _vm.urlTerminosYCondiciones, target: "_blank" } },
          [_vm._v("Términos y Condiciones")]
        ),
      ]),
      _c("div", { staticClass: "copyright" }, [
        _c(
          "a",
          { attrs: { href: _vm.urlAvisoDePrivacidad, target: "_blank" } },
          [_vm._v("Aviso de Privacidad")]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }