var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("nav", { staticClass: "navbar navbar-expand-lg" }, [
    _c(
      "div",
      { staticClass: "container-fluid" },
      [
        _c("router-link", { staticClass: "nav-link", attrs: { to: "/" } }, [
          _c("div", { staticClass: "logo-img" }, [
            _c("img", {
              staticStyle: { "max-width": "100px" },
              attrs: { src: "/img/cp_logooficial_color.png", alt: "" },
            }),
          ]),
        ]),
        _c(
          "button",
          {
            staticClass: "navbar-toggler navbar-toggler-right",
            class: { toggled: _vm.$sidebar.showSidebar },
            attrs: {
              type: "button",
              "aria-controls": "navigation-index",
              "aria-expanded": "false",
              "aria-label": "Toggle navigation",
            },
            on: { click: _vm.toggleSidebar },
          },
          [
            _c("span", { staticClass: "navbar-toggler-bar burger-lines" }),
            _c("span", { staticClass: "navbar-toggler-bar burger-lines" }),
            _c("span", { staticClass: "navbar-toggler-bar burger-lines" }),
          ]
        ),
        _c(
          "div",
          { staticClass: "collapse navbar-collapse justify-content-end" },
          [
            _c("ul", { staticClass: "navbar-nav ml-auto" }, [
              _c(
                "li",
                { staticClass: "nav-item" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "nav-link",
                      attrs: { to: { name: "Home", hash: "#como_funciona" } },
                    },
                    [_vm._v("¿Cómo funciona? ")]
                  ),
                ],
                1
              ),
              _c(
                "li",
                { staticClass: "nav-item" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "nav-link",
                      attrs: { to: { name: "Home", hash: "#servicios" } },
                    },
                    [_vm._v("Servicios ")]
                  ),
                ],
                1
              ),
              _c(
                "li",
                { staticClass: "nav-item" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "nav-link",
                      attrs: { to: { name: "Home", hash: "#registro" } },
                    },
                    [_vm._v("Registro ")]
                  ),
                ],
                1
              ),
              _c(
                "li",
                { staticClass: "nav-item" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "nav-link",
                      attrs: { to: { name: "Home", hash: "#contacto" } },
                    },
                    [_vm._v("Contacto")]
                  ),
                ],
                1
              ),
              _c(
                "li",
                { staticClass: "nav-item" },
                [
                  _c(
                    "router-link",
                    { staticClass: "nav-link", attrs: { to: "/login" } },
                    [_vm._v("Iniciar sesión")]
                  ),
                ],
                1
              ),
            ]),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }