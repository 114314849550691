var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container" }, [
    _c("h4", { staticClass: "cardD" }, [_vm._v(" Pago Complementario ")]),
    _c(
      "div",
      { staticClass: "mt-4" },
      [
        _c(
          "b-form-group",
          {
            attrs: {
              label: "Liga de pago Openpay",
              "label-for": "input-link-pago-complementario",
            },
          },
          [
            _c(
              "b-input-group",
              { staticClass: "mt-3" },
              [
                _c(
                  "b-input-group-append",
                  [
                    _c(
                      "b-button",
                      {
                        directives: [
                          {
                            name: "b-tooltip",
                            rawName: "v-b-tooltip.hover",
                            modifiers: { hover: true },
                          },
                        ],
                        attrs: {
                          variant: _vm.isTextCopied ? "success" : "secondary",
                          squared: "",
                          title: "Copiar",
                        },
                        on: { click: _vm.copyLinkToClipboard },
                      },
                      [
                        _vm.isTextCopied
                          ? _c("b-icon", { attrs: { icon: "check" } })
                          : _c("b-icon", { attrs: { icon: "clipboard" } }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("b-form-input", {
                  attrs: {
                    id: "input-link-pago-complementario",
                    type: "link",
                    disabled: "",
                    placeholder: "Link Openpay",
                  },
                  model: {
                    value: _vm.formalizacion.liga_pago_complementario,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.formalizacion,
                        "liga_pago_complementario",
                        $$v
                      )
                    },
                    expression: "formalizacion.liga_pago_complementario",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }