var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container" },
    [
      _vm.loading
        ? _c("div", [
            _c(
              "div",
              { attrs: { id: "loader" } },
              [
                _c("font-awesome-icon", {
                  attrs: { icon: "spinner", spin: "" },
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
      _c("h4", { staticClass: "cardD" }, [
        _vm._v(
          " Datos Generales del Delegado Especial o de quien hace la protocolización "
        ),
      ]),
      _c(
        "b-modal",
        {
          ref: "firmantes-modal",
          attrs: {
            "no-close-on-backdrop": "",
            centered: "",
            title: "",
            "hide-footer": "",
            id: "modalC",
            size: "xl",
          },
        },
        [
          _c("ValidationObserver", {
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ handleSubmit }) {
                  return [
                    _c(
                      "form",
                      {
                        on: {
                          submit: function ($event) {
                            $event.preventDefault()
                            return handleSubmit(_vm.putFirmante)
                          },
                        },
                      },
                      [
                        _c("ModalFirmantes", {
                          attrs: {
                            currentFirmante: _vm.currentFirmante,
                            complete: false,
                          },
                          on: {
                            submit: function ($event) {
                              $event.preventDefault()
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          ref: "modal-confirm-dialog",
          attrs: {
            "no-close-on-backdrop": "",
            centered: "",
            "hide-footer": "",
            id: "modal-confirm-dialog",
          },
        },
        [
          _c("h4", { staticClass: "text-center" }, [
            _vm._v(" ¿Seguro que quiere eliminar al firmante? "),
          ]),
          _c("h5", { staticClass: "text-center" }),
          _c(
            "b-button",
            {
              staticClass: "mt-3",
              attrs: { variant: "danger" },
              on: {
                click: function ($event) {
                  return _vm.$bvModal.hide("modal-confirm-dialog")
                },
              },
            },
            [_vm._v("Cancelar")]
          ),
          _c(
            "b-button",
            {
              staticClass: "mt-3 float-right",
              attrs: { variant: "primary" },
              on: { click: _vm.deleteFirmante },
            },
            [_vm._v("OK")]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mt-4" },
        [
          _c("label", { attrs: { for: "" } }, [
            _vm._v("Delegado especial o firmantes"),
          ]),
          _vm.formalizacion.firmantes.length < 2 ||
          _vm.formalizacion.firmantes[0].cargo != "Delegado especial"
            ? _c(
                "b-button",
                {
                  staticClass: "btn-fill spaceL",
                  attrs: {
                    disabled: _vm.formalizacion.disabled,
                    variant: "success",
                    size: "sm",
                  },
                  on: { click: _vm.addFirmante },
                },
                [_vm._v("+")]
              )
            : _vm._e(),
        ],
        1
      ),
      _c("div", { staticClass: "mt-4" }, [
        _c("label", { attrs: { for: "" } }, [
          _vm._v("Seleccionar Compareciente"),
        ]),
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.selectedCompareciente,
                expression: "selectedCompareciente",
              },
            ],
            staticClass: "custom-select",
            attrs: { disabled: _vm.formalizacion.disabledDelegado },
            on: {
              change: [
                function ($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function (o) {
                      return o.selected
                    })
                    .map(function (o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.selectedCompareciente = $event.target.multiple
                    ? $$selectedVal
                    : $$selectedVal[0]
                },
                _vm.selectCompareciente,
              ],
            },
          },
          _vm._l(_vm.options_comparecientes, function (option) {
            return _c(
              "option",
              { key: option.value, domProps: { value: option.value } },
              [_vm._v(" " + _vm._s(option.text) + " ")]
            )
          }),
          0
        ),
      ]),
      _c(
        "div",
        [
          _c("b-table", {
            attrs: {
              hover: "",
              fields: _vm.fieldsCuadro,
              items: _vm.formalizacion.firmantes,
            },
            scopedSlots: _vm._u([
              {
                key: "cell(acciones)",
                fn: function ({ item }) {
                  return [
                    _c(
                      "b-button",
                      {
                        directives: [
                          {
                            name: "b-tooltip",
                            rawName: "v-b-tooltip.hover",
                            modifiers: { hover: true },
                          },
                        ],
                        staticClass: "editBtn",
                        attrs: {
                          disabled: _vm.formalizacion.disabledDelegado,
                          variant: "warning",
                          size: "sm",
                          title: "Editar",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.editFirmante(item)
                          },
                        },
                      },
                      [_c("b-icon-pencil")],
                      1
                    ),
                    _c(
                      "b-button",
                      {
                        directives: [
                          {
                            name: "b-tooltip",
                            rawName: "v-b-tooltip.hover",
                            modifiers: { hover: true },
                          },
                        ],
                        attrs: {
                          disabled: _vm.formalizacion.disabledDelegado,
                          variant: "danger",
                          size: "sm",
                          title: "Eliminar",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.predeleteFirmante(item.id)
                          },
                        },
                      },
                      [_c("b-icon-trash")],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("div", { staticClass: "mt-4" }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-10" },
            [
              _c(
                "b-button",
                {
                  staticClass: "btn-fill orange-btn bottom2",
                  attrs: {
                    type: "submit",
                    size: "m",
                    disabled: _vm.formalizacion.disabledDelegado,
                  },
                },
                [_vm._v("Guardar")]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-2" },
            [
              _c(
                "b-button",
                {
                  staticClass: "btn-fill bottom2",
                  attrs: {
                    variant: "success",
                    size: "m",
                    disabled: _vm.formalizacion.disabledDelegado,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.validar()
                    },
                  },
                },
                [_vm._v("Validar")]
              ),
            ],
            1
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }