var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.user === "Staff"
        ? [
            _c("div", [
              _c("div", [
                _vm._m(0),
                _c(
                  "div",
                  {
                    staticClass:
                      "row flex align-items-center p-2 pointer justify-content-between",
                  },
                  [
                    _c("div", { staticClass: "ml-3" }, [
                      _c(
                        "a",
                        {
                          class:
                            _vm.selectedComponent === "DatosGenerales"
                              ? "active-menu"
                              : "text-menu",
                          on: {
                            click: function ($event) {
                              return _vm.selected("DatosGenerales")
                            },
                          },
                        },
                        [_vm._v(" Datos de la Sociedad ")]
                      ),
                    ]),
                    _c("div", { staticClass: "mr-3" }, [
                      _c(
                        "div",
                        {
                          class: [
                            "circle",
                            _vm.constitutiva.validoGenerales
                              ? "completado"
                              : "",
                          ],
                        },
                        [
                          _c("span", { staticClass: "number" }, [
                            _vm.constitutiva.validoGenerales
                              ? _c("i", { staticClass: "fa fa-check" })
                              : _c("i", { staticClass: "fa fa-minus" }),
                          ]),
                        ]
                      ),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "row flex align-items-center p-2 pointer justify-content-between",
                  },
                  [
                    _c("div", { staticClass: "ml-3" }, [
                      _c(
                        "a",
                        {
                          class:
                            _vm.selectedComponent === "DatosCapital"
                              ? "active-menu"
                              : "text-menu",
                          on: {
                            click: function ($event) {
                              return _vm.selected("DatosCapital")
                            },
                          },
                        },
                        [_vm._v(" Capital y Accionistas ")]
                      ),
                    ]),
                    _c("div", { staticClass: "mr-3" }, [
                      _c(
                        "div",
                        {
                          class: [
                            "circle",
                            _vm.constitutiva.validoCapital ? "completado" : "",
                          ],
                        },
                        [
                          _c("span", { staticClass: "number" }, [
                            _vm.constitutiva.validoCapital
                              ? _c("i", { staticClass: "fa fa-check" })
                              : _c("i", { staticClass: "fa fa-minus" }),
                          ]),
                        ]
                      ),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "row flex align-items-center p-2 pointer justify-content-between",
                  },
                  [
                    _c("div", { staticClass: "ml-3" }, [
                      _c(
                        "a",
                        {
                          class:
                            _vm.selectedComponent === "DatosOrganos"
                              ? "active-menu"
                              : "text-menu",
                          on: {
                            click: function ($event) {
                              return _vm.selected("DatosOrganos")
                            },
                          },
                        },
                        [_vm._v(" Órganos Corporativos ")]
                      ),
                    ]),
                    _c("div", { staticClass: "mr-3" }, [
                      _c(
                        "div",
                        {
                          class: [
                            "circle",
                            _vm.constitutiva.validoOrganos ? "completado" : "",
                          ],
                        },
                        [
                          _c("span", { staticClass: "number" }, [
                            _vm.constitutiva.validoOrganos
                              ? _c("i", { staticClass: "fa fa-check" })
                              : _c("i", { staticClass: "fa fa-minus" }),
                          ]),
                        ]
                      ),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "row flex align-items-center p-2 pointer justify-content-between",
                  },
                  [
                    _c("div", { staticClass: "ml-3" }, [
                      _c(
                        "a",
                        {
                          class:
                            _vm.selectedComponent ===
                            "RepresentantesYApoderados"
                              ? "active-menu"
                              : "text-menu",
                          on: {
                            click: function ($event) {
                              return _vm.selected("RepresentantesYApoderados")
                            },
                          },
                        },
                        [_vm._v(" Representantes ")]
                      ),
                    ]),
                    _c("div", { staticClass: "mr-3" }, [
                      _c(
                        "div",
                        {
                          class: [
                            "circle",
                            _vm.constitutiva.validoRepresentantes
                              ? "completado"
                              : "",
                          ],
                        },
                        [
                          _c("span", { staticClass: "number" }, [
                            _vm.constitutiva.validoRepresentantes
                              ? _c("i", { staticClass: "fa fa-check" })
                              : _c("i", { staticClass: "fa fa-minus" }),
                          ]),
                        ]
                      ),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "row flex align-items-center p-2 pointer justify-content-between",
                  },
                  [
                    _c("div", { staticClass: "ml-3" }, [
                      _c(
                        "a",
                        {
                          class:
                            _vm.selectedComponent === "CapitulosEspeciales"
                              ? "active-menu"
                              : "text-menu",
                          on: {
                            click: function ($event) {
                              return _vm.selected("CapitulosEspeciales")
                            },
                          },
                        },
                        [_vm._v(" Capítulos especiales ")]
                      ),
                    ]),
                    _c("div", { staticClass: "mr-3" }, [
                      _c(
                        "div",
                        {
                          class: [
                            "circle",
                            _vm.constitutiva.validoCapitulos
                              ? "completado"
                              : "",
                          ],
                        },
                        [
                          _c("span", { staticClass: "number" }, [
                            _vm.constitutiva.validoCapitulos
                              ? _c("i", { staticClass: "fa fa-check" })
                              : _c("i", { staticClass: "fa fa-minus" }),
                          ]),
                        ]
                      ),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "row flex align-items-center p-2 pointer justify-content-between",
                  },
                  [
                    _c("div", { staticClass: "ml-3" }, [
                      _c(
                        "a",
                        {
                          class:
                            _vm.selectedComponent === "EntidadFederativa"
                              ? "active-menu"
                              : "text-menu",
                          on: {
                            click: function ($event) {
                              return _vm.selected("EntidadFederativa")
                            },
                          },
                        },
                        [_vm._v(" Entidad Federativa firma ")]
                      ),
                    ]),
                    _c("div", { staticClass: "mr-3" }, [
                      _c(
                        "div",
                        {
                          class: [
                            "circle",
                            _vm.constitutiva.validoEntidad ? "completado" : "",
                          ],
                        },
                        [
                          _c("span", { staticClass: "number" }, [
                            _vm.constitutiva.validoEntidad
                              ? _c("i", { staticClass: "fa fa-check" })
                              : _c("i", { staticClass: "fa fa-minus" }),
                          ]),
                        ]
                      ),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "row flex align-items-center p-2 pointer justify-content-between",
                  },
                  [
                    _c("div", { staticClass: "ml-3" }, [
                      _c(
                        "a",
                        {
                          class:
                            _vm.selectedComponent === "Cotizacion"
                              ? "active-menu"
                              : "text-menu",
                          on: {
                            click: function ($event) {
                              return _vm.selected("Cotizacion")
                            },
                          },
                        },
                        [_vm._v(" Cotización y pago ")]
                      ),
                    ]),
                    _c("div", { staticClass: "mr-3" }, [
                      _c(
                        "div",
                        {
                          class: [
                            "circle",
                            _vm.constitutiva.validoCotizacion
                              ? "completado"
                              : "",
                          ],
                        },
                        [
                          _c("span", { staticClass: "number" }, [
                            _vm.constitutiva.validoCotizacion
                              ? _c("i", { staticClass: "fa fa-check" })
                              : _c("i", { staticClass: "fa fa-minus" }),
                          ]),
                        ]
                      ),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "row flex align-items-center p-2 pointer justify-content-between",
                  },
                  [
                    _c("div", { staticClass: "ml-3" }, [
                      _c(
                        "a",
                        {
                          class:
                            _vm.selectedComponent === "ComplementaInformacion"
                              ? "active-menu"
                              : "text-menu",
                          on: {
                            click: function ($event) {
                              return _vm.selected("ComplementaInformacion")
                            },
                          },
                        },
                        [_vm._v(" Complementa la información ")]
                      ),
                    ]),
                    _c("div", { staticClass: "mr-3" }, [
                      _c(
                        "div",
                        {
                          class: [
                            "circle",
                            _vm.constitutiva.validoComplementa
                              ? "completado"
                              : "",
                          ],
                        },
                        [
                          _c("span", { staticClass: "number" }, [
                            _vm.constitutiva.validoComplementa
                              ? _c("i", { staticClass: "fa fa-check" })
                              : _c("i", { staticClass: "fa fa-minus" }),
                          ]),
                        ]
                      ),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "row flex align-items-center p-2 pointer justify-content-between",
                  },
                  [
                    _c("div", { staticClass: "ml-3" }, [
                      _c(
                        "a",
                        {
                          class:
                            _vm.selectedComponent === "AsignarCorreduria"
                              ? "active-menu"
                              : "text-menu",
                          on: {
                            click: function ($event) {
                              return _vm.selected("AsignarCorreduria")
                            },
                          },
                        },
                        [_vm._v(" Asignar correduría ")]
                      ),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "row flex align-items-center p-2 pointer justify-content-between",
                  },
                  [
                    _c("div", { staticClass: "ml-3" }, [
                      _c(
                        "a",
                        {
                          class:
                            _vm.selectedComponent === "DenominacionSocial"
                              ? "active-menu"
                              : "text-menu",
                          on: {
                            click: function ($event) {
                              return _vm.selected("DenominacionSocial")
                            },
                          },
                        },
                        [_vm._v(" Denominación social ")]
                      ),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "row flex align-items-center p-2 pointer justify-content-between",
                  },
                  [
                    _c("div", { staticClass: "ml-3" }, [
                      _c(
                        "a",
                        {
                          class:
                            _vm.selectedComponent === "Expediente"
                              ? "active-menu"
                              : "text-menu",
                          on: {
                            click: function ($event) {
                              return _vm.selected("Expediente")
                            },
                          },
                        },
                        [_vm._v(" Expediente ")]
                      ),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "row flex align-items-center p-2 pointer justify-content-between",
                  },
                  [
                    _c("div", { staticClass: "ml-3" }, [
                      _c(
                        "a",
                        {
                          class:
                            _vm.selectedComponent === "GeneraDocumento"
                              ? "active-menu"
                              : "text-menu",
                          on: {
                            click: function ($event) {
                              return _vm.selected("GeneraDocumento")
                            },
                          },
                        },
                        [_vm._v(" Genera documentos ")]
                      ),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "row flex align-items-center p-2 pointer justify-content-between",
                  },
                  [
                    _c("div", { staticClass: "ml-3" }, [
                      _c(
                        "a",
                        {
                          class:
                            _vm.selectedComponent === "DocFirmado"
                              ? "active-menu"
                              : "text-menu",
                          on: {
                            click: function ($event) {
                              return _vm.selected("DocFirmado")
                            },
                          },
                        },
                        [_vm._v(" Documento firmado ")]
                      ),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "row flex align-items-center p-2 pointer justify-content-between",
                  },
                  [
                    _c("div", { staticClass: "ml-3" }, [
                      _c(
                        "a",
                        {
                          class:
                            _vm.selectedComponent === "CitaFirma"
                              ? "active-menu"
                              : "text-menu",
                          on: {
                            click: function ($event) {
                              return _vm.selected("CitaFirma")
                            },
                          },
                        },
                        [_vm._v(" Agenda tu cita ")]
                      ),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "row flex align-items-center p-2 pointer justify-content-between",
                  },
                  [
                    _c("div", { staticClass: "ml-3" }, [
                      _c(
                        "a",
                        {
                          class:
                            _vm.selectedComponent === "Terminado"
                              ? "active-menu"
                              : "text-menu",
                          on: {
                            click: function ($event) {
                              return _vm.selected("Terminado")
                            },
                          },
                        },
                        [_vm._v(" Terminado ")]
                      ),
                    ]),
                    _c("div", { staticClass: "mr-3" }, [
                      _c(
                        "div",
                        {
                          class: [
                            "circle",
                            _vm.constitutiva.validoCita ? "completado" : "",
                          ],
                        },
                        [
                          _c("span", { staticClass: "number" }, [
                            _vm.constitutiva.validoCita
                              ? _c("i", { staticClass: "fa fa-check" })
                              : _c("i", { staticClass: "fa fa-minus" }),
                          ]),
                        ]
                      ),
                    ]),
                  ]
                ),
              ]),
            ]),
          ]
        : _vm.user === "Client"
        ? [
            _c("div", [
              _c("div", [
                _vm._m(1),
                _c(
                  "div",
                  {
                    staticClass:
                      "row flex align-items-center p-2 pointer justify-content-between",
                  },
                  [
                    _c("div", { staticClass: "ml-3" }, [
                      _c(
                        "a",
                        {
                          class:
                            _vm.selectedComponent === "DatosGenerales"
                              ? "active-menu"
                              : "text-menu",
                          on: {
                            click: function ($event) {
                              return _vm.selected("DatosGenerales")
                            },
                          },
                        },
                        [_vm._v(" Datos de la Sociedad ")]
                      ),
                    ]),
                    _c("div", { staticClass: "mr-3" }, [
                      _c(
                        "div",
                        {
                          class: [
                            "circle",
                            _vm.constitutiva.validoGenerales
                              ? "completado"
                              : "",
                          ],
                        },
                        [
                          _c("span", { staticClass: "number" }, [
                            _vm.constitutiva.validoGenerales
                              ? _c("i", { staticClass: "fa fa-check" })
                              : _c("i", { staticClass: "fa fa-minus" }),
                          ]),
                        ]
                      ),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "row flex align-items-center p-2 pointer justify-content-between",
                  },
                  [
                    _c("div", { staticClass: "ml-3" }, [
                      _c(
                        "a",
                        {
                          class:
                            _vm.selectedComponent === "DatosCapital"
                              ? "active-menu"
                              : "text-menu",
                          on: {
                            click: function ($event) {
                              return _vm.selected("DatosCapital")
                            },
                          },
                        },
                        [_vm._v(" Capital y Accionistas ")]
                      ),
                    ]),
                    _c("div", { staticClass: "mr-3" }, [
                      _c(
                        "div",
                        {
                          class: [
                            "circle",
                            _vm.constitutiva.validoCapital ? "completado" : "",
                          ],
                        },
                        [
                          _c("span", { staticClass: "number" }, [
                            _vm.constitutiva.validoCapital
                              ? _c("i", { staticClass: "fa fa-check" })
                              : _c("i", { staticClass: "fa fa-minus" }),
                          ]),
                        ]
                      ),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "row flex align-items-center p-2 pointer justify-content-between",
                  },
                  [
                    _c("div", { staticClass: "ml-3" }, [
                      _c(
                        "a",
                        {
                          class:
                            _vm.selectedComponent === "DatosOrganos"
                              ? "active-menu"
                              : "text-menu",
                          on: {
                            click: function ($event) {
                              return _vm.selected("DatosOrganos")
                            },
                          },
                        },
                        [_vm._v(" Órganos Corporativos ")]
                      ),
                    ]),
                    _c("div", { staticClass: "mr-3" }, [
                      _c(
                        "div",
                        {
                          class: [
                            "circle",
                            _vm.constitutiva.validoOrganos ? "completado" : "",
                          ],
                        },
                        [
                          _c("span", { staticClass: "number" }, [
                            _vm.constitutiva.validoOrganos
                              ? _c("i", { staticClass: "fa fa-check" })
                              : _c("i", { staticClass: "fa fa-minus" }),
                          ]),
                        ]
                      ),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "row flex align-items-center p-2 pointer justify-content-between",
                  },
                  [
                    _c("div", { staticClass: "ml-3" }, [
                      _c(
                        "a",
                        {
                          class:
                            _vm.selectedComponent ===
                            "RepresentantesYApoderados"
                              ? "active-menu"
                              : "text-menu",
                          on: {
                            click: function ($event) {
                              return _vm.selected("RepresentantesYApoderados")
                            },
                          },
                        },
                        [_vm._v(" Representantes ")]
                      ),
                    ]),
                    _c("div", { staticClass: "mr-3" }, [
                      _c(
                        "div",
                        {
                          class: [
                            "circle",
                            _vm.constitutiva.validoRepresentantes
                              ? "completado"
                              : "",
                          ],
                        },
                        [
                          _c("span", { staticClass: "number" }, [
                            _vm.constitutiva.validoRepresentantes
                              ? _c("i", { staticClass: "fa fa-check" })
                              : _c("i", { staticClass: "fa fa-minus" }),
                          ]),
                        ]
                      ),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "row flex align-items-center p-2 pointer justify-content-between",
                  },
                  [
                    _c("div", { staticClass: "ml-3" }, [
                      _c(
                        "a",
                        {
                          class:
                            _vm.selectedComponent === "CapitulosEspeciales"
                              ? "active-menu"
                              : "text-menu",
                          on: {
                            click: function ($event) {
                              return _vm.selected("CapitulosEspeciales")
                            },
                          },
                        },
                        [_vm._v(" Capítulos especiales ")]
                      ),
                    ]),
                    _c("div", { staticClass: "mr-3" }, [
                      _c(
                        "div",
                        {
                          class: [
                            "circle",
                            _vm.constitutiva.validoCapitulos
                              ? "completado"
                              : "",
                          ],
                        },
                        [
                          _c("span", { staticClass: "number" }, [
                            _vm.constitutiva.validoCapitulos
                              ? _c("i", { staticClass: "fa fa-check" })
                              : _c("i", { staticClass: "fa fa-minus" }),
                          ]),
                        ]
                      ),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "row flex align-items-center p-2 pointer justify-content-between",
                  },
                  [
                    _c("div", { staticClass: "ml-3" }, [
                      _c(
                        "a",
                        {
                          class:
                            _vm.selectedComponent === "EntidadFederativa"
                              ? "active-menu"
                              : "text-menu",
                          on: {
                            click: function ($event) {
                              return _vm.selected("EntidadFederativa")
                            },
                          },
                        },
                        [_vm._v(" Entidad Federativa firma ")]
                      ),
                    ]),
                    _c("div", { staticClass: "mr-3" }, [
                      _c(
                        "div",
                        {
                          class: [
                            "circle",
                            _vm.constitutiva.validoEntidad ? "completado" : "",
                          ],
                        },
                        [
                          _c("span", { staticClass: "number" }, [
                            _vm.constitutiva.validoEntidad
                              ? _c("i", { staticClass: "fa fa-check" })
                              : _c("i", { staticClass: "fa fa-minus" }),
                          ]),
                        ]
                      ),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "row flex align-items-center p-2 pointer justify-content-between",
                  },
                  [
                    _c("div", { staticClass: "ml-3" }, [
                      _c(
                        "a",
                        {
                          class:
                            _vm.selectedComponent === "Cotizacion"
                              ? "active-menu"
                              : "text-menu",
                          on: {
                            click: function ($event) {
                              return _vm.selected("Cotizacion")
                            },
                          },
                        },
                        [_vm._v(" Cotización y pago ")]
                      ),
                    ]),
                    _c("div", { staticClass: "mr-3" }, [
                      _c(
                        "div",
                        {
                          class: [
                            "circle",
                            _vm.constitutiva.validoCotizacion
                              ? "completado"
                              : "",
                          ],
                        },
                        [
                          _c("span", { staticClass: "number" }, [
                            _vm.constitutiva.validoCotizacion
                              ? _c("i", { staticClass: "fa fa-check" })
                              : _c("i", { staticClass: "fa fa-minus" }),
                          ]),
                        ]
                      ),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "row flex align-items-center p-2 pointer justify-content-between",
                  },
                  [
                    _c("div", { staticClass: "ml-3" }, [
                      _c(
                        "a",
                        {
                          class:
                            _vm.selectedComponent === "ComplementaInformacion"
                              ? "active-menu"
                              : "text-menu",
                          on: {
                            click: function ($event) {
                              return _vm.selected("ComplementaInformacion")
                            },
                          },
                        },
                        [_vm._v(" Complementa la información ")]
                      ),
                    ]),
                    _c("div", { staticClass: "mr-3" }, [
                      _c(
                        "div",
                        {
                          class: [
                            "circle",
                            _vm.constitutiva.validoComplementa
                              ? "completado"
                              : "",
                          ],
                        },
                        [
                          _c("span", { staticClass: "number" }, [
                            _vm.constitutiva.validoComplementa
                              ? _c("i", { staticClass: "fa fa-check" })
                              : _c("i", { staticClass: "fa fa-minus" }),
                          ]),
                        ]
                      ),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "row flex align-items-center p-2 pointer justify-content-between",
                  },
                  [
                    _c("div", { staticClass: "ml-3" }, [
                      _c(
                        "a",
                        {
                          class:
                            _vm.selectedComponent === "FirmaDigital"
                              ? "active-menu"
                              : "text-menu",
                          on: {
                            click: function ($event) {
                              return _vm.selected("FirmaDigital")
                            },
                          },
                        },
                        [_vm._v(" Firma digital ")]
                      ),
                    ]),
                    _c("div", { staticClass: "mr-3" }, [
                      _c(
                        "div",
                        {
                          class: [
                            "circle",
                            _vm.constitutiva.validoCita ? "completado" : "",
                          ],
                        },
                        [
                          _c("span", { staticClass: "number" }, [
                            _vm.constitutiva.validoCita
                              ? _c("i", { staticClass: "fa fa-check" })
                              : _c("i", { staticClass: "fa fa-minus" }),
                          ]),
                        ]
                      ),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "row flex align-items-center p-2 pointer justify-content-between",
                  },
                  [
                    _c("div", { staticClass: "ml-3" }, [
                      _c(
                        "a",
                        {
                          class:
                            _vm.selectedComponent === "CitaFirma"
                              ? "active-menu"
                              : "text-menu",
                          on: {
                            click: function ($event) {
                              return _vm.selected("CitaFirma")
                            },
                          },
                        },
                        [_vm._v(" Agenda tu cita ")]
                      ),
                    ]),
                    _c("div", { staticClass: "mr-3" }, [
                      _c(
                        "div",
                        {
                          class: [
                            "circle",
                            _vm.constitutiva.validoCita ? "completado" : "",
                          ],
                        },
                        [
                          _c("span", { staticClass: "number" }, [
                            _vm.constitutiva.validoCita
                              ? _c("i", { staticClass: "fa fa-check" })
                              : _c("i", { staticClass: "fa fa-minus" }),
                          ]),
                        ]
                      ),
                    ]),
                  ]
                ),
              ]),
            ]),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12 text-center mb-3" }, [
        _c("label", [_vm._v(" Entrega de información base ")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12 text-center mb-3" }, [
        _c("label", [_vm._v(" Entrega de información base ")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }