var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "content" }, [
      _c(
        "div",
        {
          staticClass: "d-flex justify-content-center align-items-center h-75",
        },
        [
          _c("div", { staticClass: "text-center" }, [
            _c("img", {
              attrs: {
                width: "200",
                height: "200",
                src: "/img/cp_logooficial_color.png",
              },
            }),
            _c("h1", [_vm._v("Próximamente")]),
            _c("p", [
              _vm._v(
                "Estamos trabajando en esta sección. Pronto estará disponible."
              ),
            ]),
          ]),
        ]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }