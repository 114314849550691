var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "row justify-content-center p-2" },
      [
        _c("b-img", {
          attrs: {
            src: require("@/assets/img/documentos_entregados.webp"),
            alt: "Documentos entregados",
            fluid: "",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }