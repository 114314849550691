var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-md-4" },
        [
          _c("ValidationProvider", {
            attrs: {
              name: "nombre",
              rules: "required",
              "custom-messages": { required: "El nombre es obligatorio" },
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ errors }) {
                  return [
                    _c("base-input", {
                      attrs: {
                        type: "text",
                        label: "Nombre(s)",
                        placeholder: "Nombre(s)",
                      },
                      model: {
                        value: _vm.currentFirmante.nombre,
                        callback: function ($$v) {
                          _vm.$set(_vm.currentFirmante, "nombre", $$v)
                        },
                        expression: "currentFirmante.nombre",
                      },
                    }),
                    _c("span", { staticClass: "cardErrors" }, [
                      _vm._v(_vm._s(errors[0])),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-md-4" },
        [
          _c("ValidationProvider", {
            attrs: {
              name: "paterno",
              rules: "required",
              "custom-messages": {
                required: "El apellido paterno es obligatorio",
              },
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ errors }) {
                  return [
                    _c("base-input", {
                      attrs: {
                        type: "text",
                        label: "Apellido Paterno",
                        placeholder: "Apellido Paterno",
                      },
                      model: {
                        value: _vm.currentFirmante.paterno,
                        callback: function ($$v) {
                          _vm.$set(_vm.currentFirmante, "paterno", $$v)
                        },
                        expression: "currentFirmante.paterno",
                      },
                    }),
                    _c("span", { staticClass: "cardErrors" }, [
                      _vm._v(_vm._s(errors[0])),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-md-4" },
        [
          _c("base-input", {
            attrs: {
              type: "text",
              label: "Apellido Materno",
              placeholder: "Apellido Materno",
            },
            model: {
              value: _vm.currentFirmante.materno,
              callback: function ($$v) {
                _vm.$set(_vm.currentFirmante, "materno", $$v)
              },
              expression: "currentFirmante.materno",
            },
          }),
        ],
        1
      ),
    ]),
    _vm._m(0),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text-center" }, [
      _c(
        "button",
        {
          staticClass: "btn btn-fill float-right orange-btn",
          attrs: { type: "submit" },
        },
        [_vm._v(" Guardar ")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }