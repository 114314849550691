var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container" }, [
    _c("h4", { staticClass: "cardD" }, [_vm._v("Cotización")]),
    _vm.subtotal_montos > 0
      ? _c("h4", { staticClass: "cardD mt-1" }, [_vm._v("Resumen de Montos")])
      : _vm._e(),
    _vm.subtotal_monto_global > 0
      ? _c("div", { staticClass: "row" }, [
          _c("label", { staticClass: "grey col-md-1" }),
          _c("label", { staticClass: "grey col-md-5" }, [
            _vm._v(" Costo por Monto Global"),
          ]),
          _c("label", { staticClass: "grey col-md-6" }, [
            _vm._v("$" + _vm._s(_vm.subtotal_monto_global)),
          ]),
        ])
      : _vm._e(),
    _vm.ratificacion.inscripcion_rug == 1
      ? _c("div", { staticClass: "row" }, [
          _c("label", { staticClass: "grey col-md-1" }, [_vm._v("✓")]),
          _c("label", { staticClass: "grey col-md-5" }, [
            _vm._v(" Inscripción en RUG"),
          ]),
          _c("label", { staticClass: "grey col-md-6" }, [
            _vm._v("$" + _vm._s(_vm.precio_inscripcion_rug)),
          ]),
        ])
      : _vm._e(),
    _vm.subtotal_montos > 0
      ? _c("div", { staticClass: "row mt-2" }, [
          _c("label", { staticClass: "grey col-md-6" }, [_vm._v(" Subtotal ")]),
          _c("label", { staticClass: "grey col-md-6" }, [
            _vm._v("$" + _vm._s(_vm.subtotal_montos)),
          ]),
        ])
      : _vm._e(),
    _vm.subtotal_documentos > 0
      ? _c("h4", { staticClass: "cardD mt-1" }, [_vm._v("Documentos")])
      : _vm._e(),
    _vm.documentos_base > 0
      ? _c("div", { staticClass: "row" }, [
          _c("label", { staticClass: "grey col-md-1" }, [
            _vm._v(_vm._s(_vm.documentos_base)),
          ]),
          _c("label", { staticClass: "grey col-md-5" }, [
            _vm._v("Documentos Base"),
          ]),
          _c("label", { staticClass: "grey col-md-6" }, [
            _vm._v(
              "$" + _vm._s(_vm.documentos_base * _vm.precio_documento_base)
            ),
          ]),
        ])
      : _vm._e(),
    _vm.contenido_digital > 0
      ? _c("div", { staticClass: "row" }, [
          _c("label", { staticClass: "grey col-md-1" }, [
            _vm._v(_vm._s(_vm.contenido_digital)),
          ]),
          _c("label", { staticClass: "grey col-md-5" }, [
            _vm._v("Documentos con Contenido Digital"),
          ]),
          _c("label", { staticClass: "grey col-md-6" }, [
            _vm._v(
              "$" + _vm._s(_vm.contenido_digital * _vm.precio_contenido_digital)
            ),
          ]),
        ])
      : _vm._e(),
    _vm.paginas_extra > 0
      ? _c("div", { staticClass: "row" }, [
          _c("label", { staticClass: "grey col-md-1" }, [
            _vm._v(_vm._s(_vm.paginas_extra)),
          ]),
          _c("label", { staticClass: "grey col-md-5" }, [
            _vm._v("Páginas Adicionales"),
          ]),
          _c("label", { staticClass: "grey col-md-6" }, [
            _vm._v(
              "$" + _vm._s(_vm.paginas_extra * _vm.precio_pagina_adicional)
            ),
          ]),
        ])
      : _vm._e(),
    _vm.subtotal_documentos > 0
      ? _c("div", { staticClass: "row mt-2" }, [
          _c("label", { staticClass: "grey col-md-6" }, [_vm._v(" Subtotal ")]),
          _c("label", { staticClass: "grey col-md-6" }, [
            _vm._v("$" + _vm._s(_vm.subtotal_documentos)),
          ]),
        ])
      : _vm._e(),
    _vm.subtotal_comparecientes > 0
      ? _c("h4", { staticClass: "cardD mt-1" }, [_vm._v("Comparecientes")])
      : _vm._e(),
    _vm.comparecientes_fisicas_extra > 0
      ? _c("div", { staticClass: "row" }, [
          _c("label", { staticClass: "grey col-md-1" }, [
            _vm._v(_vm._s(_vm.comparecientes_fisicas_extra)),
          ]),
          _c("label", { staticClass: "grey col-md-5" }, [
            _vm._v("Personas Físicas Adicionales"),
          ]),
          _c("label", { staticClass: "grey col-md-6" }, [
            _vm._v(
              "$" +
                _vm._s(
                  _vm.comparecientes_fisicas_extra *
                    _vm.precio_adicional_persona_fisica
                )
            ),
          ]),
        ])
      : _vm._e(),
    _vm.comparecientes_morales_extra > 0
      ? _c("div", { staticClass: "row" }, [
          _c("label", { staticClass: "grey col-md-1" }, [
            _vm._v(_vm._s(_vm.comparecientes_morales_extra)),
          ]),
          _c("label", { staticClass: "grey col-md-5" }, [
            _vm._v("Personas Morales Adicionales"),
          ]),
          _c("label", { staticClass: "grey col-md-6" }, [
            _vm._v(
              "$" +
                _vm._s(
                  _vm.comparecientes_morales_extra *
                    _vm.precio_adicional_persona_moral
                )
            ),
          ]),
        ])
      : _vm._e(),
    _vm.subtotal_comparecientes > 0
      ? _c("div", { staticClass: "row mt-2" }, [
          _c("label", { staticClass: "grey col-md-6" }, [_vm._v(" Subtotal ")]),
          _c("label", { staticClass: "grey col-md-6" }, [
            _vm._v("$" + _vm._s(_vm.subtotal_comparecientes)),
          ]),
        ])
      : _vm._e(),
    _c("div", { staticClass: "row mt-1" }, [
      _vm._m(0),
      _c("div", { staticClass: "col-md-6" }, [
        _c("h4", { staticClass: "cardD" }, [
          _vm._v("$" + _vm._s(_vm.total_previo_descuento)),
        ]),
      ]),
    ]),
    _c("hr"),
    _c("h4", { staticClass: "cardD" }, [_vm._v("Agregar Extras")]),
    _c("div", { staticClass: "row mt-4" }, [
      _c("div", { staticClass: "col-md-12" }, [
        _c(
          "div",
          { staticStyle: { display: "flex", "align-items": "center" } },
          [
            _c(
              "b-form-checkbox",
              {
                attrs: { id: "chkEntregablesAdicionales" },
                model: {
                  value: _vm.entregablesAdicionales,
                  callback: function ($$v) {
                    _vm.entregablesAdicionales = $$v
                  },
                  expression: "entregablesAdicionales",
                },
              },
              [
                _c(
                  "label",
                  {
                    staticClass: "control-label validacionDatosLabel",
                    attrs: { for: "chkEntregablesAdicionales" },
                  },
                  [
                    _vm._v(
                      " Ejemplares adicionales del documento certificado (Se incluyen 2) "
                    ),
                  ]
                ),
              ]
            ),
          ],
          1
        ),
      ]),
    ]),
    _vm.entregablesAdicionales
      ? _c("div", { staticClass: "row mt-6" }, [
          _c(
            "div",
            { staticClass: "col-md-12" },
            [
              _c("ValidationProvider", {
                attrs: {
                  name: "entregables",
                  rules: "required",
                  "custom-messages": {
                    required: "El monto es obligatorio",
                  },
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function ({ errors }) {
                        return [
                          _c("label", { attrs: { for: "" } }, [
                            _vm._v("Cantidad de Entregables Adicionales"),
                          ]),
                          _c("base-input", {
                            staticClass: "custom-number",
                            attrs: {
                              type: "number",
                              required: "",
                              placeholder: "1",
                              min: 1,
                            },
                            model: {
                              value: _vm.ratificacion.entregables_adicionales,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.ratificacion,
                                  "entregables_adicionales",
                                  $$v
                                )
                              },
                              expression:
                                "ratificacion.entregables_adicionales",
                            },
                          }),
                          _c("span", { staticClass: "cardErrors" }, [
                            _vm._v(_vm._s(errors[0])),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  4207664180
                ),
              }),
            ],
            1
          ),
        ])
      : _vm._e(),
    _c("div", { staticClass: "row mt-4" }, [
      _c("div", { staticClass: "col-md-12" }, [
        _c(
          "div",
          { staticStyle: { display: "flex", "align-items": "center" } },
          [
            _c(
              "b-form-checkbox",
              {
                attrs: { id: "chkAnexosCertificados" },
                model: {
                  value: _vm.anexosCertificados,
                  callback: function ($$v) {
                    _vm.anexosCertificados = $$v
                  },
                  expression: "anexosCertificados",
                },
              },
              [
                _c(
                  "label",
                  {
                    staticClass: "control-label validacionDatosLabel",
                    attrs: { for: "chkAnexosCertificados" },
                  },
                  [_vm._v(" Agregar Anexos Certificados ")]
                ),
              ]
            ),
          ],
          1
        ),
      ]),
    ]),
    _c("div", { staticClass: "row mt-4" }, [
      _c("div", { staticClass: "col-md-12" }, [
        _c(
          "div",
          { staticStyle: { display: "flex", "align-items": "center" } },
          [
            _c(
              "b-form-checkbox",
              {
                attrs: { id: "chkFirmaFuera" },
                model: {
                  value: _vm.firmaFuera,
                  callback: function ($$v) {
                    _vm.firmaFuera = $$v
                  },
                  expression: "firmaFuera",
                },
              },
              [
                _c(
                  "label",
                  {
                    staticClass: "control-label validacionDatosLabel",
                    attrs: { for: "chkFirmaFuera" },
                  },
                  [
                    _vm._v(
                      " Firma Fuera de la Oficina (No se recaban firmas en domicilios particulares, únicamente en oficinas corporativas) "
                    ),
                  ]
                ),
              ]
            ),
          ],
          1
        ),
      ]),
    ]),
    _vm.firmaFuera
      ? _c("div", { staticClass: "row mt-6" }, [
          _c(
            "div",
            { staticClass: "col-md-12" },
            [
              _c("ValidationProvider", {
                attrs: {
                  name: "firmas-fuera",
                  rules: "required",
                  "custom-messages": { required: "El número es obligatorio" },
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function ({ errors }) {
                        return [
                          _c(
                            "label",
                            { attrs: { for: "input-firmas-fuera" } },
                            [_vm._v("Número de firmas fuera de la oficina")]
                          ),
                          _c("base-input", {
                            staticClass: "custom-number",
                            attrs: {
                              type: "number",
                              required: "",
                              placeholder: "1",
                              min: 1,
                            },
                            model: {
                              value: _vm.cantidad_firmas_fuera_oficina,
                              callback: function ($$v) {
                                _vm.cantidad_firmas_fuera_oficina = $$v
                              },
                              expression: "cantidad_firmas_fuera_oficina",
                            },
                          }),
                          _c("span", { staticClass: "cardErrors" }, [
                            _vm._v(_vm._s(errors[0])),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2387233146
                ),
              }),
            ],
            1
          ),
        ])
      : _vm._e(),
    _c("div", { staticClass: "row mt-4" }, [
      _c("div", { staticClass: "col-md-12" }, [
        _c(
          "div",
          { staticStyle: { display: "flex", "align-items": "center" } },
          [
            _c("b-form-checkbox", {
              attrs: { id: "chkEntregaFuera" },
              model: {
                value: _vm.entregaFuera,
                callback: function ($$v) {
                  _vm.entregaFuera = $$v
                },
                expression: "entregaFuera",
              },
            }),
            _c(
              "label",
              {
                staticClass: "control-label validacionDatosLabel",
                attrs: { for: "chkEntregaFuera" },
              },
              [_vm._v(" Envío a domicilio del entregable ")]
            ),
          ],
          1
        ),
      ]),
    ]),
    _vm.subtotal_extras > 0 ? _c("hr") : _vm._e(),
    _vm.subtotal_extras > 0
      ? _c("h4", { staticClass: "cardD" }, [_vm._v("Extras")])
      : _vm._e(),
    _vm.entregablesAdicionales &&
    parseInt(_vm.ratificacion.entregables_adicionales) > 0
      ? _c("div", { staticClass: "row" }, [
          _c("label", { staticClass: "grey col-md-1" }, [
            _vm._v(_vm._s(_vm.ratificacion.entregables_adicionales)),
          ]),
          _c("label", { staticClass: "grey col-md-5" }, [
            _vm._v("Entregables Adicionales"),
          ]),
          _c("label", { staticClass: "grey col-md-6" }, [
            _vm._v(
              "$" +
                _vm._s(
                  _vm.ratificacion.entregables_adicionales *
                    _vm.precio_entregable_adicional
                )
            ),
          ]),
        ])
      : _vm._e(),
    _vm.anexosCertificados && parseInt(_vm.paginas_anexos) > 0
      ? _c("div", { staticClass: "row" }, [
          _c("label", { staticClass: "grey col-md-1" }, [
            _vm._v(_vm._s(_vm.paginas_anexos)),
          ]),
          _c("label", { staticClass: "grey col-md-5" }, [
            _vm._v("Paginas de Anexos"),
          ]),
          _c("label", { staticClass: "grey col-md-6" }, [
            _vm._v(
              "$" +
                _vm._s(_vm.paginas_anexos * _vm.precio_pagina_anexo_certificado)
            ),
          ]),
        ])
      : _vm._e(),
    _vm.firmaFuera
      ? _c("div", { staticClass: "row" }, [
          _c("label", { staticClass: "grey col-md-1" }),
          _c("label", { staticClass: "grey col-md-5" }, [
            _vm._v("Firma Fuera de la Oficina"),
          ]),
          _c("label", { staticClass: "grey col-md-6" }, [
            _vm._v("$" + _vm._s(_vm.precio_firma_fuera_oficina)),
          ]),
        ])
      : _vm._e(),
    _vm.entregaFuera
      ? _c("div", { staticClass: "row" }, [
          _c("label", { staticClass: "grey col-md-1" }),
          _c("label", { staticClass: "grey col-md-5" }, [
            _vm._v("Envío a domicilio del entregable"),
          ]),
          _c("label", { staticClass: "grey col-md-6" }, [_vm._v("$500")]),
        ])
      : _vm._e(),
    _vm.subtotal_extras > 0
      ? _c("div", { staticClass: "row mt-2" }, [
          _c("label", { staticClass: "grey col-md-6" }, [_vm._v(" Subtotal ")]),
          _c("label", { staticClass: "grey col-md-6" }, [
            _vm._v("$" + _vm._s(_vm.subtotal_extras)),
          ]),
        ])
      : _vm._e(),
    _vm.entregaFuera
      ? _c("h4", { staticClass: "cardD mt-1" }, [_vm._v("Descuentos")])
      : _vm._e(),
    _vm.entregaFuera
      ? _c("div", { staticClass: "row" }, [
          _c("label", { staticClass: "grey col-md-1" }),
          _c("label", { staticClass: "grey col-md-5" }, [
            _vm._v("Envío a domicilio del entregable"),
          ]),
          _c("label", { staticClass: "grey col-md-6" }, [_vm._v(" - $500")]),
        ])
      : _vm._e(),
    _vm.entregaFuera
      ? _c("div", { staticClass: "row mt-2" }, [
          _c("label", { staticClass: "grey col-md-6" }, [_vm._v(" Subtotal ")]),
          _c("label", { staticClass: "grey col-md-6" }, [_vm._v("- $500")]),
        ])
      : _vm._e(),
    _c("h4", { staticClass: "cardD mt-1" }, [_vm._v("Cupones")]),
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-12" },
        [
          _c("base-input", {
            attrs: {
              type: "text",
              label: "Si cuenta con un cupón puede aplicarlo.",
              placeholder: "Cupón de descuento",
              disabled: _vm.cuponAplicado,
            },
            model: {
              value: _vm.nombreCuponDescuento,
              callback: function ($$v) {
                _vm.nombreCuponDescuento = $$v
              },
              expression: "nombreCuponDescuento",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-12 d-flex" },
        [
          _c("br"),
          _c(
            "b-button",
            {
              staticClass: "orange-btn",
              attrs: { size: "m", disabled: _vm.cuponAplicado },
              on: {
                click: function ($event) {
                  return _vm.validarCupon(_vm.nombreCuponDescuento)
                },
              },
            },
            [_vm._v("Aplicar")]
          ),
        ],
        1
      ),
    ]),
    _vm.total_post_descuento ||
    _vm.total_post_descuento > _vm.total_previo_descuento ||
    this.entregaFuera
      ? _c("div", { staticClass: "row mt-1" }, [
          _vm._m(1),
          _c("div", { staticClass: "col-md-6" }, [
            _c("h4", { staticClass: "cardD" }, [
              _vm._v("$" + _vm._s(_vm.total_post_descuento)),
            ]),
          ]),
        ])
      : _vm._e(),
    _c("hr"),
    _c("div", { staticClass: "row mt-4" }, [
      _c(
        "div",
        { staticClass: "col-md-12" },
        [
          _c("ValidationProvider", {
            attrs: {
              name: "validacionTerminos",
              rules: { required: { allowFalse: false } },
              "custom-messages": {
                required: "La confirmación es obligatoria",
              },
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ errors }) {
                  return [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "align-items": "center",
                        },
                      },
                      [
                        _c(
                          "b-form-checkbox",
                          {
                            attrs: { id: "chkValidacionTerminos" },
                            model: {
                              value: _vm.validacionTerminos,
                              callback: function ($$v) {
                                _vm.validacionTerminos = $$v
                              },
                              expression: "validacionTerminos",
                            },
                          },
                          [
                            _c(
                              "label",
                              {
                                staticClass:
                                  "control-label validacionDatosLabel",
                                attrs: { for: "chkValidacionTerminos" },
                              },
                              [_vm._v(" Acepto los Términos y condiciones. ")]
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c("span", { staticClass: "cardErrors" }, [
                      _vm._v(_vm._s(errors[0])),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "row mt-4" }, [
      _c(
        "div",
        { staticClass: "col-md-12" },
        [
          _c("ValidationProvider", {
            attrs: {
              name: "validacionPaginas",
              rules: { required: { allowFalse: false } },
              "custom-messages": {
                required: "La confirmación es obligatoria",
              },
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ errors }) {
                  return [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "align-items": "center",
                        },
                      },
                      [
                        _c(
                          "b-form-checkbox",
                          {
                            attrs: { id: "chkValidacionPaginas" },
                            model: {
                              value: _vm.validacionPaginas,
                              callback: function ($$v) {
                                _vm.validacionPaginas = $$v
                              },
                              expression: "validacionPaginas",
                            },
                          },
                          [
                            _c(
                              "label",
                              {
                                staticClass:
                                  "control-label validacionPaginasLabel",
                                attrs: { for: "chkValidacionPaginas" },
                              },
                              [
                                _vm._v(
                                  " Declaro bajo protesta de decir la verdad, que el volumen de páginas corresponde a la totalidad de las páginas que conforman los documentos COMPLETOS. "
                                ),
                              ]
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c("span", { staticClass: "cardErrors" }, [
                      _vm._v(_vm._s(errors[0])),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "row mt-4" }, [
      _c(
        "div",
        { staticClass: "col-md-12" },
        [
          _c("ValidationProvider", {
            attrs: {
              name: "validacionMontos",
              rules: { required: { allowFalse: false } },
              "custom-messages": {
                required: "La confirmación es obligatoria",
              },
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ errors }) {
                  return [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "align-items": "center",
                        },
                      },
                      [
                        _c(
                          "b-form-checkbox",
                          {
                            attrs: { id: "chkValidacionMontos" },
                            model: {
                              value: _vm.validacionMontos,
                              callback: function ($$v) {
                                _vm.validacionMontos = $$v
                              },
                              expression: "validacionMontos",
                            },
                          },
                          [
                            _c(
                              "label",
                              {
                                staticClass:
                                  "control-label validacionMontosLabel",
                                attrs: { for: "chkValidacionMontos" },
                              },
                              [
                                _vm._v(
                                  " Declaro bajo protesta de decir la verdad, que los montos indicados son correctos. "
                                ),
                              ]
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c("span", { staticClass: "cardErrors" }, [
                      _vm._v(_vm._s(errors[0])),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "mt-4" }, [
      _c(
        "div",
        {},
        [
          _c(
            "b-button",
            {
              staticClass: "btn-fill orange-btn bottom2",
              attrs: { size: "m", disabled: _vm.isAbleToPay() },
              on: {
                click: function ($event) {
                  return _vm.submitCotizacion()
                },
              },
            },
            [_vm._v("Pagar")]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-md-6" }, [
      _c("h4", { staticClass: "cardD" }, [_vm._v("Total")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-md-6" }, [
      _c("h4", { staticClass: "cardD" }, [_vm._v("Total")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }