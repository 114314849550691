var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "col-md-12" },
      [
        _c("ValidationProvider", {
          attrs: {
            name: "documento",
            rules: "required",
            "custom-messages": { required: "El documento es obligatorio" },
          },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function ({ errors }) {
                return [
                  _c(
                    "b-form-group",
                    { attrs: { label: "Documento:" } },
                    [
                      _c("b-form-file", {
                        attrs: {
                          "browse-text": "Seleccionar",
                          "drop-placeholder": "Cargar aquí",
                          placeholder: "Da click aquí o arrastra el archivo",
                          accept: _vm.acceptedFileTypes,
                        },
                        on: { input: _vm.onInput },
                        model: {
                          value: _vm.currentDocumento.file,
                          callback: function ($$v) {
                            _vm.$set(_vm.currentDocumento, "file", $$v)
                          },
                          expression: "currentDocumento.file",
                        },
                      }),
                      _c("span", { staticClass: "cardErrors" }, [
                        _vm._v(_vm._s(errors[0])),
                      ]),
                    ],
                    1
                  ),
                ]
              },
            },
          ]),
        }),
      ],
      1
    ),
    _c("div", { staticClass: "mt-4 text-center" }, [
      _c(
        "button",
        {
          staticClass: "btn btn-fill float-right orange-btn",
          attrs: { type: "submit", disabled: !_vm.currentDocumento.file },
        },
        [_vm._v(" Guardar ")]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }