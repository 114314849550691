var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content" },
    [
      _c(
        "b-modal",
        {
          attrs: {
            "no-close-on-backdrop": "",
            id: "modal-videos-servicios",
            size: "xl",
            centered: "",
            "hide-footer": "",
          },
        },
        [
          _c("p", { staticClass: "info-text" }, [
            _vm._v(
              "Para mayor información y acceso al contenido Regístrate con nosotros"
            ),
          ]),
          _c("div", { staticClass: "text-center" }, [
            _c(
              "a",
              {
                staticClass: "btn orange-btn",
                on: { click: _vm.goToRegistro },
              },
              [_vm._v("Regístrate")]
            ),
          ]),
        ]
      ),
      _c("div", { staticClass: "container-fluid" }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-md-3" },
            [
              _c(
                "b-card",
                { staticClass: "cardServicio" },
                [
                  _c("template", { slot: "header" }, [
                    _c("h4", { staticClass: "card-title" }, [
                      _vm._v("Constitución de sociedades"),
                    ]),
                  ]),
                  _c("div", { staticClass: "text-center long" }, [
                    _c("img", {
                      staticClass: "imgCard",
                      attrs: { src: "/img/designed/constSocie.PNG", alt: "" },
                    }),
                  ]),
                  _c("template", { slot: "footer" }, [
                    _c("hr"),
                    _c(
                      "a",
                      {
                        staticClass: "route",
                        staticStyle: { cursor: "pointer" },
                        on: {
                          click: function ($event) {
                            return _vm.openModalVideo("constitutiva")
                          },
                        },
                      },
                      [_vm._v("Más información")]
                    ),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-md-3" },
            [
              _c(
                "b-card",
                { staticClass: "cardServicio" },
                [
                  _c("template", { slot: "header" }, [
                    _c("h4", { staticClass: "card-title" }, [
                      _vm._v("Formalización de actas"),
                    ]),
                  ]),
                  _c("div", { staticClass: "text-center long" }, [
                    _c("img", {
                      staticClass: "imgCard",
                      attrs: { src: "/img/designed/formActas.PNG", alt: "" },
                    }),
                  ]),
                  _c("template", { slot: "footer" }, [
                    _c("hr"),
                    _c(
                      "a",
                      {
                        staticClass: "route",
                        staticStyle: { cursor: "pointer" },
                        on: {
                          click: function ($event) {
                            return _vm.openModalVideo("formalizaciones")
                          },
                        },
                      },
                      [_vm._v("Más información")]
                    ),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-md-3" },
            [
              _c(
                "b-card",
                { staticClass: "cardServicio" },
                [
                  _c("template", { slot: "header" }, [
                    _c("h4", { staticClass: "card-title" }, [
                      _vm._v("Notificaciones"),
                    ]),
                  ]),
                  _c("div", { staticClass: "text-center long" }, [
                    _c("img", {
                      staticClass: "imgCard",
                      attrs: { src: "/img/designed/notifs.PNG", alt: "" },
                    }),
                  ]),
                  _c("template", { slot: "footer" }, [
                    _c("hr"),
                    _c(
                      "a",
                      {
                        staticClass: "route",
                        staticStyle: { cursor: "pointer" },
                        on: {
                          click: function ($event) {
                            return _vm.openModalVideo("notificaciones")
                          },
                        },
                      },
                      [_vm._v("Más información")]
                    ),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-md-3" },
            [
              _c(
                "b-card",
                { staticClass: "cardServicio" },
                [
                  _c("template", { slot: "header" }, [
                    _c("h4", { staticClass: "card-title" }, [
                      _vm._v("Copias certificadas"),
                    ]),
                  ]),
                  _c("div", { staticClass: "text-center long" }, [
                    _c("img", {
                      staticClass: "imgCard",
                      attrs: { src: "/img/designed/cotejoDocs.PNG", alt: "" },
                    }),
                  ]),
                  _c("template", { slot: "footer" }, [
                    _c("hr"),
                    _c(
                      "a",
                      {
                        staticClass: "route",
                        staticStyle: { cursor: "pointer" },
                        on: {
                          click: function ($event) {
                            return _vm.openModalVideo("cotejos")
                          },
                        },
                      },
                      [_vm._v("Más información")]
                    ),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "row mb-5" }),
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-md-3" },
            [
              _c(
                "card",
                { staticClass: "cardServicio" },
                [
                  _c("template", { slot: "header" }, [
                    _c("h4", { staticClass: "card-title" }, [
                      _vm._v("Fes de hechos"),
                    ]),
                  ]),
                  _c("div", { staticClass: "text-center long" }, [
                    _c("img", {
                      staticClass: "imgCard",
                      attrs: { src: "/img/designed/fesHechos.PNG", alt: "" },
                    }),
                  ]),
                  _c("template", { slot: "footer" }, [
                    _c("hr"),
                    _c(
                      "a",
                      {
                        staticClass: "route",
                        staticStyle: { cursor: "pointer" },
                        on: {
                          click: function ($event) {
                            return _vm.openModalVideo("fe_hechos")
                          },
                        },
                      },
                      [_vm._v("Más información")]
                    ),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-md-3" },
            [
              _c(
                "card",
                { staticClass: "cardServicio" },
                [
                  _c("template", { slot: "header" }, [
                    _c("h4", { staticClass: "card-title" }, [
                      _vm._v("Ratificación de firmas"),
                    ]),
                  ]),
                  _c("div", { staticClass: "text-center long" }, [
                    _c("img", {
                      staticClass: "imgCard",
                      attrs: { src: "/img/designed/ratifFirmas.PNG", alt: "" },
                    }),
                  ]),
                  _c("template", { slot: "footer" }, [
                    _c("hr"),
                    _c(
                      "a",
                      {
                        staticClass: "route",
                        staticStyle: { cursor: "pointer" },
                        on: {
                          click: function ($event) {
                            return _vm.openModalVideo("ratificaciones")
                          },
                        },
                      },
                      [_vm._v("Más información")]
                    ),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-md-3" },
            [
              _c(
                "card",
                { staticClass: "cardServicio" },
                [
                  _c("template", { slot: "header" }, [
                    _c("h4", { staticClass: "card-title" }, [
                      _vm._v("Firma Digital de Documentos"),
                    ]),
                  ]),
                  _c("div", { staticClass: "text-center long" }, [
                    _c("img", {
                      staticClass: "imgCard",
                      attrs: {
                        src: "/img/designed/ratifFirmasDigital.png",
                        alt: "",
                      },
                    }),
                  ]),
                  _c("template", { slot: "footer" }, [
                    _c("hr"),
                    _c(
                      "a",
                      {
                        staticClass: "route",
                        staticStyle: { cursor: "pointer" },
                        on: {
                          click: function ($event) {
                            return _vm.openModalVideo("ratificaciones")
                          },
                        },
                      },
                      [_vm._v("Más información")]
                    ),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-md-3" },
            [
              _c(
                "card",
                { staticClass: "cardServicio" },
                [
                  _c("template", { slot: "header" }, [
                    _c("h4", { staticClass: "card-title" }, [
                      _vm._v("Avalúos de mejoras"),
                    ]),
                  ]),
                  _c("div", { staticClass: "text-center long" }, [
                    _c("img", {
                      staticClass: "imgCard",
                      attrs: { src: "/img/designed/avaluosMej.PNG", alt: "" },
                    }),
                  ]),
                  _c("template", { slot: "footer" }, [
                    _c("hr"),
                    _c(
                      "a",
                      {
                        staticClass: "route",
                        staticStyle: { cursor: "pointer" },
                        on: {
                          click: function ($event) {
                            return _vm.openModalVideo("avaluos")
                          },
                        },
                      },
                      [_vm._v("Más información")]
                    ),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
          _c("div", { staticClass: "col-md-1" }),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }