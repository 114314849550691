var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container" },
    [
      _vm.loading
        ? _c("div", [
            _c(
              "div",
              { attrs: { id: "loader" } },
              [
                _c("font-awesome-icon", {
                  attrs: { icon: "spinner", spin: "" },
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
      _c("h4", { staticClass: "cardD" }, [
        _vm._v("Complementa la información"),
      ]),
      _c("h4", { staticClass: "cardD" }, [_vm._v("Firmantes")]),
      _c("label", { staticClass: "leyenda" }, [
        _vm._v(
          " Edita cada uno de los firmante para llenar todos los datos necesarios. Serás enviado al apartado de comparecientes. "
        ),
      ]),
      _c(
        "b-modal",
        {
          ref: "documento-modal",
          attrs: {
            "no-close-on-backdrop": "",
            centered: "",
            title: "",
            "hide-footer": "",
            id: "modalC",
            size: "xl",
          },
        },
        [
          _c("ValidationObserver", {
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ handleSubmit }) {
                  return [
                    _c(
                      "form",
                      {
                        on: {
                          submit: function ($event) {
                            $event.preventDefault()
                            return handleSubmit(_vm.postDocumento)
                          },
                        },
                      },
                      [
                        _c("ModalDocumentos", {
                          attrs: {
                            currentDocumento: _vm.currentDocumento,
                            options: _vm.optionsDocs,
                          },
                          on: {
                            submit: function ($event) {
                              $event.preventDefault()
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          ref: "documento-modal-preview-img",
          attrs: {
            "no-close-on-backdrop": "",
            centered: "",
            title: "",
            "hide-footer": "",
            id: "modaldi",
            size: "xl",
          },
        },
        [
          _c("ModalPreviewImg", {
            attrs: { currentDocumento: _vm.currentDocumento },
          }),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          ref: "documento-modal-preview-pdf",
          attrs: {
            "no-close-on-backdrop": "",
            centered: "",
            title: "",
            "hide-footer": "",
            id: "modaldf",
            size: "xl",
          },
        },
        [
          _c("ModalPreviewPDF", {
            attrs: { currentDocumento: _vm.currentDocumento },
          }),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          ref: "modal-confirm-dialog-documento",
          attrs: {
            "no-close-on-backdrop": "",
            centered: "",
            "hide-footer": "",
            id: "modal-confirm-dialog-documento",
          },
        },
        [
          _c("h4", { staticClass: "text-center" }, [
            _vm._v("¿Seguro que quiere eliminar el documento?"),
          ]),
          _c("h5", { staticClass: "text-center" }),
          _c(
            "b-button",
            {
              staticClass: "mt-3",
              attrs: { variant: "danger" },
              on: {
                click: function ($event) {
                  return _vm.$bvModal.hide("modal-confirm-dialog-documento")
                },
              },
            },
            [_vm._v("Cancelar")]
          ),
          _c(
            "b-button",
            {
              staticClass: "mt-3 float-right",
              attrs: { variant: "primary" },
              on: { click: _vm.deleteDocumento },
            },
            [_vm._v("OK")]
          ),
        ],
        1
      ),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-12" },
          [
            _c("b-table", {
              attrs: {
                hover: "",
                fields: _vm.fieldsCuadro,
                items: _vm.formalizacion.firmantes,
              },
              scopedSlots: _vm._u([
                {
                  key: "cell(acciones)",
                  fn: function ({ item }) {
                    return [
                      _c(
                        "b-button",
                        {
                          directives: [
                            {
                              name: "b-tooltip",
                              rawName: "v-b-tooltip.hover",
                              modifiers: { hover: true },
                            },
                          ],
                          staticClass: "editBtn",
                          attrs: {
                            variant: "warning",
                            size: "sm",
                            title: "Editar",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.editCompareciente(item)
                            },
                          },
                        },
                        [_c("b-icon-pencil")],
                        1
                      ),
                    ]
                  },
                },
                {
                  key: "cell(listo)",
                  fn: function ({ item }) {
                    return [
                      item.rfc
                        ? _c("div", [
                            item.rfc.length === 13
                              ? _c(
                                  "div",
                                  { staticClass: "circle completado_green" },
                                  [
                                    _c("span", { staticClass: "number" }, [
                                      _c("i", { staticClass: "fa fa-check" }),
                                    ]),
                                  ]
                                )
                              : _c(
                                  "div",
                                  { staticClass: "circle completado_yellow" },
                                  [
                                    _c("span", { staticClass: "number" }, [
                                      _c("i", { staticClass: "fa fa-minus" }),
                                    ]),
                                  ]
                                ),
                          ])
                        : _c("div", [
                            _c(
                              "div",
                              { staticClass: "circle completado_yellow" },
                              [
                                _c("span", { staticClass: "number" }, [
                                  _c("i", { staticClass: "fa fa-minus" }),
                                ]),
                              ]
                            ),
                          ]),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
      ]),
      _c(
        "b-modal",
        {
          ref: "accionista-modal",
          attrs: {
            "no-close-on-backdrop": "",
            centered: "",
            title: "",
            "hide-footer": "",
            id: "modalC",
            size: "xl",
          },
        },
        [
          _c("ValidationObserver", {
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ handleSubmit }) {
                  return [
                    _c(
                      "form",
                      {
                        on: {
                          submit: function ($event) {
                            $event.preventDefault()
                            return handleSubmit(_vm.putAccionista)
                          },
                        },
                      },
                      [
                        _c("ModalAccionista", {
                          attrs: {
                            currentAccionista: _vm.currentAccionista,
                            complete: false,
                          },
                          on: {
                            submit: function ($event) {
                              $event.preventDefault()
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          ref: "modal-confirm-dialog-accionista",
          attrs: {
            "no-close-on-backdrop": "",
            centered: "",
            "hide-footer": "",
            id: "modal-confirm-dialog-accionista",
          },
        },
        [
          _c("h4", { staticClass: "text-center" }, [
            _vm._v(" ¿Seguro que quiere eliminar al accionista? "),
          ]),
          _c("h5", { staticClass: "text-center" }),
          _c(
            "b-button",
            {
              staticClass: "mt-3",
              attrs: { variant: "danger" },
              on: {
                click: function ($event) {
                  return _vm.$bvModal.hide("modal-confirm-dialog-accionista")
                },
              },
            },
            [_vm._v("Cancelar")]
          ),
          _c(
            "b-button",
            {
              staticClass: "mt-3 float-right",
              attrs: { variant: "primary" },
              on: { click: _vm.deleteAccionista },
            },
            [_vm._v("OK")]
          ),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          ref: "administrador-modal",
          attrs: {
            "no-close-on-backdrop": "",
            centered: "",
            title: "",
            "hide-footer": "",
            id: "modalC",
            size: "xl",
          },
        },
        [
          _c("ValidationObserver", {
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ handleSubmit }) {
                  return [
                    _c(
                      "form",
                      {
                        on: {
                          submit: function ($event) {
                            $event.preventDefault()
                            return handleSubmit(_vm.putAdministrador)
                          },
                        },
                      },
                      [
                        _c("ModalAdmin", {
                          attrs: {
                            currentAdministrador: _vm.currentAdministrador,
                            complete: false,
                          },
                          on: {
                            submit: function ($event) {
                              $event.preventDefault()
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          ref: "modal-confirm-dialog-administrador",
          attrs: {
            "no-close-on-backdrop": "",
            centered: "",
            "hide-footer": "",
            id: "modal-confirm-dialog-administrador",
          },
        },
        [
          _c("h4", { staticClass: "text-center" }, [
            _vm._v(" ¿Seguro que quiere eliminar al miembro? "),
          ]),
          _c("h5", { staticClass: "text-center" }),
          _c(
            "b-button",
            {
              staticClass: "mt-3",
              attrs: { variant: "danger" },
              on: {
                click: function ($event) {
                  return _vm.$bvModal.hide("modal-confirm-dialog-administrador")
                },
              },
            },
            [_vm._v("Cancelar")]
          ),
          _c(
            "b-button",
            {
              staticClass: "mt-3 float-right",
              attrs: { variant: "primary" },
              on: { click: _vm.deleteAdministrador },
            },
            [_vm._v("OK")]
          ),
        ],
        1
      ),
      _c("div", { staticClass: "row mt-4" }, [
        _c("div", { staticClass: "col-12" }, [
          _c(
            "h4",
            { staticClass: "cardD" },
            [
              _vm._v(" Documentos "),
              _c(
                "b-button",
                {
                  staticClass: "btn-fill",
                  attrs: {
                    id: "popover-target-1",
                    size: "sm",
                    variant: "secondary",
                  },
                },
                [
                  _c("font-awesome-icon", {
                    attrs: { icon: "question-circle" },
                  }),
                ],
                1
              ),
              _c(
                "b-popover",
                { attrs: { target: "popover-target-1", triggers: "hover" } },
                [
                  _vm._v(
                    " Los documentos que deberá subir como mínimo son los siguientes: "
                  ),
                  _c("ul", [
                    _c("li", [_vm._v("Constitutiva de la Persona Moral")]),
                    _c("li", [_vm._v("Acta en PDF Firmada")]),
                    _c("li", [_vm._v("Acta en Word")]),
                  ]),
                ]
              ),
              _c(
                "b-button",
                {
                  staticClass: "btn-fill",
                  attrs: {
                    disabled: _vm.formalizacion.disabledComplementa,
                    variant: "success",
                    size: "sm",
                  },
                  on: { click: _vm.addDocumento },
                },
                [_vm._v("+")]
              ),
            ],
            1
          ),
        ]),
      ]),
      _c(
        "div",
        [
          _c("b-table", {
            attrs: {
              hover: "",
              fields: _vm.fieldsDocus,
              items: _vm.formalizacion.documentos,
            },
            scopedSlots: _vm._u([
              {
                key: "cell(tipo)",
                fn: function ({ item }) {
                  return [
                    item.tipo === "Otro"
                      ? _c("div", [
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "b-tooltip",
                                  rawName: "v-b-tooltip.hover",
                                  modifiers: { hover: true },
                                },
                              ],
                              attrs: { title: item.descripcion },
                            },
                            [_vm._v(" " + _vm._s(item.tipo) + " ")]
                          ),
                        ])
                      : _c("div", [_vm._v(" " + _vm._s(item.tipo) + " ")]),
                  ]
                },
              },
              {
                key: "cell(acciones)",
                fn: function ({ item }) {
                  return [
                    _c(
                      "b-button",
                      {
                        directives: [
                          {
                            name: "b-tooltip",
                            rawName: "v-b-tooltip.hover",
                            modifiers: { hover: true },
                          },
                        ],
                        staticClass: "editBtn",
                        attrs: {
                          variant: "warning",
                          size: "sm",
                          title: "Editar",
                          disabled: _vm.formalizacion.disabledComplementa,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.editDocumento(item)
                          },
                        },
                      },
                      [_c("b-icon-pencil")],
                      1
                    ),
                    _c(
                      "b-button",
                      {
                        directives: [
                          {
                            name: "b-tooltip",
                            rawName: "v-b-tooltip.hover",
                            modifiers: { hover: true },
                          },
                        ],
                        staticClass: "editBtn",
                        attrs: {
                          variant: "primary",
                          size: "sm",
                          title: "Vista previa",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.previewDocumento(item)
                          },
                        },
                      },
                      [_c("b-icon-eye")],
                      1
                    ),
                    _c(
                      "b-button",
                      {
                        directives: [
                          {
                            name: "b-tooltip",
                            rawName: "v-b-tooltip.hover",
                            modifiers: { hover: true },
                          },
                        ],
                        staticClass: "editBtn",
                        attrs: {
                          variant: "danger",
                          size: "sm",
                          title: "Eliminar",
                          disabled: _vm.formalizacion.disabledComplementa,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.predeleteDocumento(item.id)
                          },
                        },
                      },
                      [_c("b-icon-trash")],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("div", { staticClass: "mt-4" }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-10" },
            [
              _c(
                "b-button",
                {
                  staticClass: "btn-fill orange-btn bottom2",
                  attrs: {
                    type: "submit",
                    size: "m",
                    disabled: _vm.formalizacion.disabledComplementa,
                  },
                },
                [_vm._v("Guardar")]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-2" },
            [
              _c(
                "b-button",
                {
                  staticClass: "btn-fill bottom2",
                  attrs: {
                    variant: "success",
                    size: "m",
                    disabled: _vm.formalizacion.disabledComplementa,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.validar()
                    },
                  },
                },
                [_vm._v("Validar")]
              ),
            ],
            1
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }