var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content" },
    [
      _c(
        "b-breadcrumb",
        [
          _c("b-breadcrumb-item", { attrs: { to: { path: "/dashboard" } } }, [
            _vm._v("Inicio"),
          ]),
          _c("b-breadcrumb-item", { attrs: { active: "" } }, [
            _vm._v("Editar Ratificación de Firmas"),
          ]),
        ],
        1
      ),
      _c("div", { staticClass: "container" }, [
        _vm.loading
          ? _c("div", [
              _c(
                "div",
                { attrs: { id: "loader" } },
                [
                  _c("font-awesome-icon", {
                    attrs: { icon: "spinner", spin: "" },
                  }),
                ],
                1
              ),
            ])
          : _vm._e(),
      ]),
      _c(
        "b-modal",
        {
          ref: "modal-confirm-dialog-route",
          attrs: {
            "no-close-on-backdrop": "",
            centered: "",
            "hide-footer": "",
            id: "modal-confirm-dialog-route",
          },
        },
        [
          _c("h4", { staticClass: "text-center" }, [
            _vm._v(" ¿Seguro que quiere salir de la página? "),
          ]),
          _c("h5", { staticClass: "text-center" }),
          _c(
            "b-button",
            {
              staticClass: "mt-3",
              attrs: { variant: "danger" },
              on: {
                click: function ($event) {
                  return _vm.$bvModal.hide("modal-confirm-dialog-route")
                },
              },
            },
            [_vm._v("Cancelar")]
          ),
          _c(
            "b-button",
            {
              staticClass: "mt-3 float-right",
              attrs: { variant: "primary" },
              on: {
                click: function ($event) {
                  return _vm.beforeLeave(true)
                },
              },
            },
            [_vm._v("OK")]
          ),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          ref: "modal-confirm-dialog",
          attrs: {
            "no-close-on-backdrop": "",
            centered: "",
            "hide-footer": "",
            id: "modal-confirm-dialog",
          },
        },
        [
          _c("h4", { staticClass: "text-center" }, [
            _vm._v(" ¿Seguro que quiere salir sin guardar los cambios? "),
          ]),
          _c("h5", { staticClass: "text-center" }),
          _c(
            "b-button",
            {
              staticClass: "mt-3",
              attrs: { variant: "danger" },
              on: {
                click: function ($event) {
                  return _vm.$bvModal.hide("modal-confirm-dialog")
                },
              },
            },
            [_vm._v("Cancelar")]
          ),
          _c(
            "b-button",
            { staticClass: "mt-3 float-right", attrs: { variant: "primary" } },
            [_vm._v("OK")]
          ),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          ref: "pago-modal",
          attrs: {
            "no-close-on-backdrop": "",
            centered: "",
            title: "",
            "hide-footer": "",
            id: "modalC",
            size: "xl",
          },
        },
        [
          _c("ValidationObserver", {
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ handleSubmit }) {
                  return [
                    _c(
                      "form",
                      {
                        on: {
                          submit: function ($event) {
                            $event.preventDefault()
                            return handleSubmit(_vm.saveRatificacion)
                          },
                        },
                      },
                      [
                        _c("ModalPago", {
                          attrs: { ratificacion: _vm.ratificacion },
                          on: {
                            submit: function ($event) {
                              $event.preventDefault()
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            "no-close-on-backdrop": "",
            id: "modal-edit-ratificaciones-help-descripcion-documentos",
            size: "xl",
            centered: "",
            "hide-footer": "",
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "b-carousel",
                {
                  attrs: {
                    id: "carousel-ratificaciones-2",
                    interval: 0,
                    controls: "",
                    indicators: "",
                    background: "#ababab",
                  },
                },
                [
                  _c("b-carousel-slide", {
                    attrs: {
                      "img-src": require("@/assets/img/ratificaciones/help/modal_6_1.webp"),
                    },
                  }),
                  _c("b-carousel-slide", {
                    attrs: {
                      "img-src": require("@/assets/img/ratificaciones/help/modal_6_2.webp"),
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "div",
        { staticClass: "container-fluid" },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { staticClass: "col-4" },
                [
                  _c(
                    "b-card",
                    [
                      _c("TreeEditRatificaciones", {
                        attrs: {
                          ratificacion: _vm.ratificacion,
                          user: "Client",
                        },
                        on: { node: _vm.selected },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _vm.currentComponentBody === "ComplementaInformacion"
                        ? _c("b-img", {
                            attrs: {
                              src: require("@/assets/img/ratificaciones/help/5.webp"),
                              "fluid-grow": "",
                              alt: "Descripción de la información a llenar",
                            },
                          })
                        : _vm._e(),
                      _vm.currentComponentBody === "Montos"
                        ? _c("b-img", {
                            attrs: {
                              src: require("@/assets/img/ratificaciones/help/2.webp"),
                              "fluid-grow": "",
                              alt: "Descripción de los montos",
                            },
                          })
                        : _vm._e(),
                      _vm.currentComponentBody === "Documentos"
                        ? _c("b-img", {
                            attrs: {
                              src: require("@/assets/img/ratificaciones/help/6.webp"),
                              "fluid-grow": "",
                              alt: "Descripción de los documentos",
                            },
                          })
                        : _vm._e(),
                      _vm.currentComponentBody === "Documentos"
                        ? _c(
                            "b-button",
                            {
                              staticClass: "orange-btn",
                              attrs: { block: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.$bvModal.show(
                                    "modal-edit-ratificaciones-help-descripcion-documentos"
                                  )
                                },
                              },
                            },
                            [_vm._v(" Más información ")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-col",
                { staticClass: "col-8" },
                [
                  _c(
                    "b-card",
                    [
                      _c(
                        "b-row",
                        [
                          _c("b-col", { staticClass: "col-12" }, [
                            _vm.currentComponentBody === "Pago"
                              ? _c(
                                  "div",
                                  [
                                    _c("ValidationObserver", {
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function ({ handleSubmit }) {
                                              return [
                                                _c(
                                                  "form",
                                                  {
                                                    ref: "pago",
                                                    on: {
                                                      submit: function (
                                                        $event
                                                      ) {
                                                        $event.preventDefault()
                                                        handleSubmit(
                                                          _vm.changeCurrentComponentBody(
                                                            "ComplementaInformacion"
                                                          )
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("Pago", {
                                                      attrs: {
                                                        ratificacion:
                                                          _vm.ratificacion,
                                                      },
                                                      on: {
                                                        submit: function (
                                                          $event
                                                        ) {
                                                          $event.preventDefault()
                                                        },
                                                        allChecksPassed:
                                                          function ($event) {
                                                            return _vm.submitForm(
                                                              "pago"
                                                            )
                                                          },
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        3857015191
                                      ),
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.currentComponentBody ===
                            "ComplementaInformacion"
                              ? _c(
                                  "div",
                                  [
                                    _c("ValidationObserver", {
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function ({ handleSubmit }) {
                                              return [
                                                _c(
                                                  "form",
                                                  {
                                                    ref: "complementainformacion",
                                                    on: {
                                                      submit: function (
                                                        $event
                                                      ) {
                                                        $event.preventDefault()
                                                        handleSubmit(
                                                          _vm.changeCurrentComponentBody(
                                                            "Documentos"
                                                          )
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "ComplementaInformacion",
                                                      {
                                                        attrs: {
                                                          ratificacion:
                                                            _vm.ratificacion,
                                                        },
                                                        on: {
                                                          submit: function (
                                                            $event
                                                          ) {
                                                            $event.preventDefault()
                                                          },
                                                          allChecksPassed:
                                                            function ($event) {
                                                              return _vm.informacionCompletada()
                                                            },
                                                        },
                                                      }
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        2948532220
                                      ),
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.currentComponentBody === "Documentos"
                              ? _c(
                                  "div",
                                  [
                                    _c("ValidationObserver", {
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function ({ handleSubmit }) {
                                              return [
                                                _c(
                                                  "form",
                                                  {
                                                    ref: "documentos",
                                                    on: {
                                                      submit: function (
                                                        $event
                                                      ) {
                                                        $event.preventDefault()
                                                        handleSubmit(
                                                          _vm.documentosCompletos()
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("EntregaDocumentos", {
                                                      attrs: {
                                                        ratificacion:
                                                          _vm.ratificacion,
                                                      },
                                                      on: {
                                                        submit: function (
                                                          $event
                                                        ) {
                                                          $event.preventDefault()
                                                        },
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        559633493
                                      ),
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.currentComponentBody === "Domicilio"
                              ? _c(
                                  "div",
                                  [
                                    _c("ValidationObserver", {
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function ({ handleSubmit }) {
                                              return [
                                                _c(
                                                  "form",
                                                  {
                                                    ref: "domicilio",
                                                    on: {
                                                      submit: function (
                                                        $event
                                                      ) {
                                                        $event.preventDefault()
                                                        return handleSubmit()
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("Domicilio", {
                                                      attrs: {
                                                        ratificacion:
                                                          _vm.ratificacion,
                                                      },
                                                      on: {
                                                        submit: function (
                                                          $event
                                                        ) {
                                                          $event.preventDefault()
                                                        },
                                                        allChecksPassed:
                                                          function ($event) {
                                                            return _vm.domicilioCompleto()
                                                          },
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        346730123
                                      ),
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.currentComponentBody === "PagoComplementario"
                              ? _c(
                                  "div",
                                  [
                                    _c("ValidationObserver", {
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function ({ handleSubmit }) {
                                              return [
                                                _c(
                                                  "form",
                                                  {
                                                    ref: "pagocomplementario",
                                                    on: {
                                                      submit: function (
                                                        $event
                                                      ) {
                                                        $event.preventDefault()
                                                        return handleSubmit()
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("PagoComplementario", {
                                                      attrs: {
                                                        ratificacion:
                                                          _vm.ratificacion,
                                                      },
                                                      on: {
                                                        submit: function (
                                                          $event
                                                        ) {
                                                          $event.preventDefault()
                                                        },
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        1636461619
                                      ),
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.currentComponentBody === "EsperaValidacion"
                              ? _c("div", [_c("EsperaValidacion")], 1)
                              : _vm._e(),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }