import { render, staticRenderFns } from "./LayoutEmail.vue?vue&type=template&id=65ca396f&scoped=true"
import script from "./LayoutEmail.vue?vue&type=script&lang=js"
export * from "./LayoutEmail.vue?vue&type=script&lang=js"
import style0 from "./LayoutEmail.vue?vue&type=style&index=0&id=65ca396f&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65ca396f",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/github/workspace/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('65ca396f')) {
      api.createRecord('65ca396f', component.options)
    } else {
      api.reload('65ca396f', component.options)
    }
    module.hot.accept("./LayoutEmail.vue?vue&type=template&id=65ca396f&scoped=true", function () {
      api.rerender('65ca396f', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/staff/settings/LayoutEmail.vue"
export default component.exports