var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-tabs",
        { attrs: { card: "", vertical: "" } },
        [
          _c(
            "b-tab",
            {
              attrs: { title: "Bienvenida Staff", active: "" },
              on: {
                click: function ($event) {
                  return _vm.getLayout(1)
                },
              },
            },
            [
              _c("ValidationObserver", {
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ handleSubmit }) {
                      return [
                        _c(
                          "form",
                          {
                            on: {
                              submit: function ($event) {
                                $event.preventDefault()
                                return handleSubmit(_vm.saveLayout)
                              },
                            },
                          },
                          [
                            _c("LayoutEmail", {
                              attrs: { layout: _vm.layout },
                              on: { id: _vm.deleteFile },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "b-tab",
            {
              attrs: { title: "Bienvenida Cliente Administrador" },
              on: {
                click: function ($event) {
                  return _vm.getLayout(2)
                },
              },
            },
            [
              _c("ValidationObserver", {
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ handleSubmit }) {
                      return [
                        _c(
                          "form",
                          {
                            on: {
                              submit: function ($event) {
                                $event.preventDefault()
                                return handleSubmit(_vm.saveLayout)
                              },
                            },
                          },
                          [
                            _c("LayoutEmail", {
                              attrs: { layout: _vm.layout },
                              on: { id: _vm.deleteFile },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "b-tab",
            {
              attrs: { title: "Bienvenida Cliente Usuario" },
              on: {
                click: function ($event) {
                  return _vm.getLayout(3)
                },
              },
            },
            [
              _c("ValidationObserver", {
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ handleSubmit }) {
                      return [
                        _c(
                          "form",
                          {
                            on: {
                              submit: function ($event) {
                                $event.preventDefault()
                                return handleSubmit(_vm.saveLayout)
                              },
                            },
                          },
                          [
                            _c("LayoutEmail", {
                              attrs: { layout: _vm.layout },
                              on: { id: _vm.deleteFile },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "b-tab",
            {
              attrs: { title: "Verificación de cuenta" },
              on: {
                click: function ($event) {
                  return _vm.getLayout(4)
                },
              },
            },
            [
              _c("ValidationObserver", {
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ handleSubmit }) {
                      return [
                        _c(
                          "form",
                          {
                            on: {
                              submit: function ($event) {
                                $event.preventDefault()
                                return handleSubmit(_vm.saveLayout)
                              },
                            },
                          },
                          [
                            _c("LayoutEmail", {
                              attrs: { layout: _vm.layout },
                              on: { id: _vm.deleteFile },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "b-tab",
            {
              attrs: { title: "Reestablecer contraseña" },
              on: {
                click: function ($event) {
                  return _vm.getLayout(5)
                },
              },
            },
            [
              _c("ValidationObserver", {
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ handleSubmit }) {
                      return [
                        _c(
                          "form",
                          {
                            on: {
                              submit: function ($event) {
                                $event.preventDefault()
                                return handleSubmit(_vm.saveLayout)
                              },
                            },
                          },
                          [
                            _c("LayoutEmail", {
                              attrs: { layout: _vm.layout },
                              on: { id: _vm.deleteFile },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }