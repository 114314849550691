var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container", attrs: { id: "container" } },
    [
      _c(
        "b-modal",
        {
          ref: "compareciente-modal",
          attrs: {
            "no-close-on-backdrop": "",
            centered: "",
            title: "",
            "hide-footer": "",
            id: "modalC",
            size: "xl",
          },
        },
        [
          _c("AgregaRatificante", {
            attrs: {
              "current-solicitante": _vm.currentCompareciente,
              "max-number-of-representantes": 1,
            },
            on: { "submit-form": _vm.putCompareciente },
          }),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          ref: "modal-confirm-dialog",
          attrs: {
            "no-close-on-backdrop": "",
            centered: "",
            "hide-footer": "",
            id: "modal-confirm-dialog",
          },
        },
        [
          _c("h4", { staticClass: "text-center" }, [
            _vm._v("¿Seguro que quiere remover al ratificante?"),
          ]),
          _c("h5", { staticClass: "text-center" }),
          _c(
            "b-button",
            {
              staticClass: "mt-3",
              attrs: { variant: "danger" },
              on: {
                click: function ($event) {
                  return _vm.$bvModal.hide("modal-confirm-dialog")
                },
              },
            },
            [_vm._v("Cancelar")]
          ),
          _c(
            "b-button",
            {
              staticClass: "mt-3 float-right",
              attrs: { variant: "primary" },
              on: { click: _vm.deleteCompareciente },
            },
            [_vm._v("OK")]
          ),
        ],
        1
      ),
      _c("h4", { staticClass: "cardD" }, [
        _vm._v("Información de los ratificantes"),
      ]),
      _c("label", { staticClass: "fs-6 text-black-50 text-uppercase" }, [
        _vm._v("Agregar ratificantes"),
      ]),
      _c(
        "div",
        { staticClass: "mt-2" },
        [
          _c("label", { attrs: { for: "btn-add" } }, [_vm._v("Nuevo")]),
          _c(
            "b-button",
            {
              staticClass: "btn-fill spaceL",
              attrs: {
                variant: "success",
                size: "sm",
                disabled: !true,
                id: "btn-add",
              },
              on: { click: _vm.addCompareciente },
            },
            [_vm._v(" + ")]
          ),
        ],
        1
      ),
      _vm._m(0),
      _c("div", { staticClass: "mt-2" }, [
        _vm._m(1),
        _c("div", { staticClass: "col-12" }, [
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.selectedRatificante,
                  expression: "selectedRatificante",
                },
              ],
              staticClass: "custom-select",
              attrs: { id: "select-comparecientes", disabled: false },
              on: {
                change: [
                  function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.selectedRatificante = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                  _vm.selectedRatificantes,
                ],
              },
            },
            _vm._l(_vm.comparecientes, function (option) {
              return _c(
                "option",
                { key: option.id, domProps: { value: option.id } },
                [_vm._v(" " + _vm._s(_vm.displaytext(option)) + " ")]
              )
            }),
            0
          ),
        ]),
      ]),
      this.ratificacion.ratificantes.length > 0
        ? _c(
            "div",
            { staticClass: "mt-5" },
            [
              _c("b-table", {
                ref: "table",
                attrs: {
                  hover: "",
                  fields: _vm.fieldsCuadro,
                  items: _vm.ratificacion.ratificantes,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "cell(acciones)",
                      fn: function ({ item }) {
                        return [
                          item.persona !== "Moral" ||
                          (item.persona === "Moral" && !item.existente)
                            ? _c(
                                "b-button",
                                {
                                  directives: [
                                    {
                                      name: "b-tooltip",
                                      rawName: "v-b-tooltip.hover",
                                      modifiers: { hover: true },
                                    },
                                  ],
                                  staticClass: "editBtn",
                                  attrs: {
                                    variant: "warning",
                                    size: "sm",
                                    title: "Editar",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.editCompareciente(item)
                                    },
                                  },
                                },
                                [_c("b-icon-pencil")],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "b-button",
                            {
                              directives: [
                                {
                                  name: "b-tooltip",
                                  rawName: "v-b-tooltip.hover",
                                  modifiers: { hover: true },
                                },
                              ],
                              attrs: {
                                variant: "danger",
                                size: "sm",
                                title: "Eliminar",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.predeleteComparreciente(item.id)
                                },
                              },
                            },
                            [_c("b-icon-trash")],
                            1
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  184600447
                ),
              }),
            ],
            1
          )
        : _vm._e(),
      _c("div", { staticClass: "mt-4" }, [
        _c(
          "div",
          [
            _c(
              "b-button",
              {
                staticClass: "btn-fill orange-btn bottom2",
                attrs: { size: "m" },
                on: {
                  click: function ($event) {
                    return _vm.checkComparecientes()
                  },
                },
              },
              [_vm._v("Continuar")]
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "mt-2" }, [_c("label", [_vm._v("Ó")])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("label", { attrs: { for: "select-comparecientes" } }, [
        _vm._v(
          "Existente (solo aparecerán ratificantes de procesos concluídos)"
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }