var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "content" }, [
    _c(
      "div",
      { staticClass: "loader" },
      [
        _c("font-awesome-icon", {
          attrs: { icon: _vm.icon, spin: _vm.icon === "spinner" },
        }),
        _c("p", { staticClass: "message" }, [_vm._v(_vm._s(_vm.message))]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }