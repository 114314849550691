var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content" },
    [
      _c(
        "b-breadcrumb",
        [
          _c("b-breadcrumb-item", { attrs: { to: { path: "/dashboard" } } }, [
            _vm._v("Inicio"),
          ]),
          _c("b-breadcrumb-item", { attrs: { active: "" } }, [
            _vm._v("Nueva Formalización de actas"),
          ]),
        ],
        1
      ),
      _c("div", { staticClass: "container" }, [
        _vm.loading
          ? _c("div", [
              _c(
                "div",
                { attrs: { id: "loader" } },
                [
                  _c("font-awesome-icon", {
                    attrs: { icon: "spinner", spin: "" },
                  }),
                ],
                1
              ),
            ])
          : _vm._e(),
      ]),
      _c(
        "b-modal",
        {
          ref: "modal-confirm-dialog-route",
          attrs: {
            "no-close-on-backdrop": "",
            centered: "",
            "hide-footer": "",
            id: "modal-confirm-dialog-route",
          },
        },
        [
          _c("h4", { staticClass: "text-center" }, [
            _vm._v(" ¿Seguro que quiere salir de la página? "),
          ]),
          _c("h5", { staticClass: "text-center" }),
          _c(
            "b-button",
            {
              staticClass: "mt-3",
              attrs: { variant: "danger" },
              on: {
                click: function ($event) {
                  return _vm.$bvModal.hide("modal-confirm-dialog-route")
                },
              },
            },
            [_vm._v("Cancelar")]
          ),
          _c(
            "b-button",
            {
              staticClass: "mt-3 float-right",
              attrs: { variant: "primary" },
              on: {
                click: function ($event) {
                  return _vm.beforeLeave(true)
                },
              },
            },
            [_vm._v("OK")]
          ),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          ref: "modal-confirm-dialog",
          attrs: {
            "no-close-on-backdrop": "",
            centered: "",
            "hide-footer": "",
            id: "modal-confirm-dialog",
          },
        },
        [
          _c("h4", { staticClass: "text-center" }, [
            _vm._v(" ¿Seguro que quiere salir sin guardar los cambios? "),
          ]),
          _c("h5", { staticClass: "text-center" }),
          _c(
            "b-button",
            {
              staticClass: "mt-3",
              attrs: { variant: "danger" },
              on: {
                click: function ($event) {
                  return _vm.$bvModal.hide("modal-confirm-dialog")
                },
              },
            },
            [_vm._v("Cancelar")]
          ),
          _c(
            "b-button",
            {
              staticClass: "mt-3 float-right",
              attrs: { variant: "primary" },
              on: {
                click: function ($event) {
                  return _vm.selected("go")
                },
              },
            },
            [_vm._v("OK")]
          ),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          ref: "pago-modal",
          attrs: {
            "no-close-on-backdrop": "",
            centered: "",
            title: "",
            "hide-footer": "",
            id: "modalC",
            size: "xl",
          },
        },
        [
          _c("p", { attrs: { id: "titulo" } }, [_vm._v("Forma de pago")]),
          _c(
            "b-tabs",
            { attrs: { fill: "" } },
            [
              _c(
                "b-tab",
                { attrs: { title: "Tarjeta de crédito o débito", active: "" } },
                [
                  _c("div", { staticClass: "mt-3 center" }, [
                    _vm._v(
                      " Correduría Digital no guarda nigún dato de la compra. Todo se efectúa a través de la plataforma de OpenPay. "
                    ),
                  ]),
                  _c("br"),
                  _c("ValidationObserver", {
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ handleSubmit }) {
                          return [
                            _c(
                              "form",
                              {
                                on: {
                                  submit: function ($event) {
                                    $event.preventDefault()
                                    return handleSubmit(_vm.payCotizacion)
                                  },
                                },
                              },
                              [
                                _c("Openpay", {
                                  attrs: {
                                    cotizacion_id:
                                      _vm.formalizacion.cotizaciones_id,
                                    precio_total:
                                      _vm.formalizacion.precio_total,
                                  },
                                  on: {
                                    submit: function ($event) {
                                      $event.preventDefault()
                                    },
                                    payCotizacion: function ($event) {
                                      return _vm.payCotizacion($event)
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c(
                "b-tab",
                {
                  staticClass: "modalPay",
                  attrs: { title: "SPEI/Transferencia" },
                },
                [
                  _c("ValidationObserver", {
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ handleSubmit }) {
                          return [
                            _c(
                              "form",
                              {
                                on: {
                                  submit: function ($event) {
                                    $event.preventDefault()
                                    return handleSubmit(_vm.payCotizacionSpei)
                                  },
                                },
                              },
                              [
                                _c("Spei", {
                                  attrs: {
                                    cotizacion_id:
                                      _vm.formalizacion.cotizaciones_id,
                                    precio_total:
                                      _vm.formalizacion.precio_total,
                                    proceso_id: _vm.formalizacion.id,
                                    tipo: "formalizacion",
                                  },
                                  on: {
                                    submit: function ($event) {
                                      $event.preventDefault()
                                    },
                                    payCotizacionSpei: function ($event) {
                                      return _vm.payCotizacionSpei($event)
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c(
                "b-tab",
                { staticClass: "modalPay", attrs: { title: "Monedero" } },
                [
                  _c("ValidationObserver", {
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ handleSubmit }) {
                          return [
                            _c(
                              "form",
                              {
                                on: {
                                  submit: function ($event) {
                                    $event.preventDefault()
                                    return handleSubmit(_vm.payCotizacion)
                                  },
                                },
                              },
                              [
                                _c("Monedero", {
                                  attrs: {
                                    cotizacion_id:
                                      _vm.formalizacion.cotizaciones_id,
                                    precio_total:
                                      _vm.formalizacion.precio_total,
                                    clientes_id: _vm.formalizacion.clientes_id,
                                    servicio:
                                      "Formalización de Actas " +
                                      _vm.formalizacion.id,
                                  },
                                  on: {
                                    submit: function ($event) {
                                      $event.preventDefault()
                                    },
                                    payCotizacion: function ($event) {
                                      return _vm.payCotizacionMonedero($event)
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          ref: "confirmar-facturacion-modal",
          attrs: {
            "no-close-on-backdrop": "",
            id: "confirmar-facturacion-modal",
            centered: "",
            "hide-footer": "",
          },
        },
        [
          _c("h4", { staticClass: "text-center" }, [
            _vm._v("¿Desea facturar su pago?"),
          ]),
          _c("h5", { staticClass: "text-center" }),
          _c(
            "b-button",
            {
              staticClass: "mt-3",
              attrs: { variant: "danger" },
              on: {
                click: function ($event) {
                  return _vm.confirmGenerarFactura(false)
                },
              },
            },
            [_vm._v("No en este momento")]
          ),
          _c(
            "b-button",
            {
              staticClass: "mt-3 float-right",
              attrs: { variant: "primary" },
              on: {
                click: function ($event) {
                  return _vm.confirmGenerarFactura(true)
                },
              },
            },
            [_vm._v("Sí")]
          ),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          ref: "datos-fiscales-modal",
          attrs: {
            "no-close-on-backdrop": "",
            id: "datos-fiscales-modal",
            centered: "",
            "hide-footer": "",
            "hide-header": "",
            size: "lg",
          },
          on: {
            close: function ($event) {
              $event.preventDefault()
            },
          },
        },
        [
          _c("FacturacionComponent", {
            attrs: { datosFacturacion: _vm.datosFacturacion },
            on: {
              closedFacturacionEvent: function ($event) {
                return _vm.closedFacturacion($event)
              },
              canceledFacturacionEvent: function ($event) {
                return _vm.closedFacturacion($event)
              },
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "container-fluid" },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { staticClass: "col-3" },
                [
                  _c(
                    "card",
                    [
                      _c("TreeM", {
                        attrs: {
                          formalizacion: _vm.formalizacion,
                          user: "Client",
                        },
                        on: { node: _vm.selected },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-col",
                { staticClass: "col-9" },
                [
                  _c(
                    "card",
                    [
                      _c(
                        "b-row",
                        [
                          _c(_vm.currentComponentInfoHead, {
                            tag: "component",
                            staticClass: "tab",
                          }),
                        ],
                        1
                      ),
                      _c(
                        "b-row",
                        [
                          _c("b-col", { staticClass: "col-12" }, [
                            _vm.currentComponentBody === "DatosSociedad"
                              ? _c(
                                  "div",
                                  [
                                    _c("ValidationObserver", {
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function ({ handleSubmit }) {
                                              return [
                                                _c(
                                                  "form",
                                                  {
                                                    on: {
                                                      submit: function (
                                                        $event
                                                      ) {
                                                        $event.preventDefault()
                                                        return handleSubmit(
                                                          _vm.saveFormalizacion
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("DatosSociedad", {
                                                      attrs: {
                                                        formalizacion:
                                                          _vm.formalizacion,
                                                      },
                                                      on: {
                                                        submit: function (
                                                          $event
                                                        ) {
                                                          $event.preventDefault()
                                                        },
                                                        node: _vm.selected,
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        2445503511
                                      ),
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.currentComponentBody === "DatosAsamblea"
                              ? _c(
                                  "div",
                                  [
                                    _c("ValidationObserver", {
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function ({ handleSubmit }) {
                                              return [
                                                _c(
                                                  "form",
                                                  {
                                                    on: {
                                                      submit: function (
                                                        $event
                                                      ) {
                                                        $event.preventDefault()
                                                        return handleSubmit(
                                                          _vm.saveFormalizacion
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("DatosAsamblea", {
                                                      attrs: {
                                                        formalizacion:
                                                          _vm.formalizacion,
                                                      },
                                                      on: {
                                                        submit: function (
                                                          $event
                                                        ) {
                                                          $event.preventDefault()
                                                        },
                                                        node: _vm.selected,
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        403990065
                                      ),
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.currentComponentBody === "DatosDelegado"
                              ? _c(
                                  "div",
                                  [
                                    _c("ValidationObserver", {
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function ({ handleSubmit }) {
                                              return [
                                                _c(
                                                  "form",
                                                  {
                                                    on: {
                                                      submit: function (
                                                        $event
                                                      ) {
                                                        $event.preventDefault()
                                                        return handleSubmit(
                                                          _vm.saveFormalizacion
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("DatosDelegado", {
                                                      attrs: {
                                                        formalizacion:
                                                          _vm.formalizacion,
                                                      },
                                                      on: {
                                                        submit: function (
                                                          $event
                                                        ) {
                                                          $event.preventDefault()
                                                        },
                                                        node: _vm.selected,
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        1373864064
                                      ),
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.currentComponentBody === "EntidadFederativa"
                              ? _c(
                                  "div",
                                  [
                                    _c("ValidationObserver", {
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function ({ handleSubmit }) {
                                              return [
                                                _c(
                                                  "form",
                                                  {
                                                    on: {
                                                      submit: function (
                                                        $event
                                                      ) {
                                                        $event.preventDefault()
                                                        return handleSubmit(
                                                          _vm.saveFormalizacion
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("EntidadFederativa", {
                                                      attrs: {
                                                        formalizacion:
                                                          _vm.formalizacion,
                                                        catCorredurias:
                                                          _vm.catCorredurias,
                                                      },
                                                      on: {
                                                        submit: function (
                                                          $event
                                                        ) {
                                                          $event.preventDefault()
                                                        },
                                                        node: _vm.selected,
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        392784178
                                      ),
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.currentComponentBody === "Cotizacion"
                              ? _c(
                                  "div",
                                  [
                                    _c("ValidationObserver", {
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function ({ handleSubmit }) {
                                              return [
                                                _c(
                                                  "form",
                                                  {
                                                    on: {
                                                      submit: function (
                                                        $event
                                                      ) {
                                                        $event.preventDefault()
                                                        return handleSubmit(
                                                          _vm.pagar
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("Cotizacion", {
                                                      attrs: {
                                                        cat_gastos:
                                                          _vm.cat_gastos,
                                                        formalizacion:
                                                          _vm.formalizacion,
                                                      },
                                                      on: {
                                                        submit: function (
                                                          $event
                                                        ) {
                                                          $event.preventDefault()
                                                        },
                                                        node: _vm.selected,
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        1257807129
                                      ),
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.currentComponentBody ===
                            "ComplementaInformacion"
                              ? _c(
                                  "div",
                                  [
                                    _c("ValidationObserver", {
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function ({ handleSubmit }) {
                                              return [
                                                _c(
                                                  "form",
                                                  {
                                                    on: {
                                                      submit: function (
                                                        $event
                                                      ) {
                                                        $event.preventDefault()
                                                        return handleSubmit(
                                                          _vm.saveFormalizacion
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "ComplementaInformacion",
                                                      {
                                                        attrs: {
                                                          formalizacion:
                                                            _vm.formalizacion,
                                                        },
                                                        on: {
                                                          submit: function (
                                                            $event
                                                          ) {
                                                            $event.preventDefault()
                                                          },
                                                          node: _vm.selected,
                                                        },
                                                      }
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        1472410296
                                      ),
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.currentComponentBody === "PagoComplementario"
                              ? _c(
                                  "div",
                                  [
                                    _c("ValidationObserver", {
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function ({ handleSubmit }) {
                                              return [
                                                _c(
                                                  "form",
                                                  {
                                                    on: {
                                                      submit: function (
                                                        $event
                                                      ) {
                                                        $event.preventDefault()
                                                        return handleSubmit(
                                                          _vm.saveFormalizacion
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("PagoComplementario", {
                                                      attrs: {
                                                        formalizacion:
                                                          _vm.formalizacion,
                                                      },
                                                      on: {
                                                        submit: function (
                                                          $event
                                                        ) {
                                                          $event.preventDefault()
                                                        },
                                                        node: _vm.selected,
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        3166322926
                                      ),
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.currentComponentBody === "FirmaDigital"
                              ? _c(
                                  "div",
                                  [
                                    _c("ValidationObserver", {
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function ({ handleSubmit }) {
                                              return [
                                                _c(
                                                  "form",
                                                  {
                                                    on: {
                                                      submit: function (
                                                        $event
                                                      ) {
                                                        $event.preventDefault()
                                                        return handleSubmit(
                                                          _vm.saveFormalizacion
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("FirmaDigital", {
                                                      attrs: {
                                                        formalizacion:
                                                          _vm.formalizacion,
                                                      },
                                                      on: {
                                                        submit: function (
                                                          $event
                                                        ) {
                                                          $event.preventDefault()
                                                        },
                                                        node: _vm.selected,
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        2588348835
                                      ),
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.currentComponentBody === "CitaFirma"
                              ? _c(
                                  "div",
                                  [
                                    _c("ValidationObserver", {
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function ({ handleSubmit }) {
                                              return [
                                                _c(
                                                  "form",
                                                  {
                                                    on: {
                                                      submit: function (
                                                        $event
                                                      ) {
                                                        $event.preventDefault()
                                                        return handleSubmit(
                                                          _vm.saveFormalizacion
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("CitaFirma", {
                                                      attrs: {
                                                        formalizacion:
                                                          _vm.formalizacion,
                                                      },
                                                      on: {
                                                        submit: function (
                                                          $event
                                                        ) {
                                                          $event.preventDefault()
                                                        },
                                                        node: _vm.selected,
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        34113894
                                      ),
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }