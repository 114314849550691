var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.user === "Client"
      ? _c("div", [
          _vm._m(0),
          _c(
            "div",
            { staticClass: "row flex align-items-center p-2 pointer" },
            [
              _c("div", { staticClass: "ml-3" }, [
                _c(
                  "div",
                  {
                    class: [
                      "circle",
                      _vm.notificacion.currentComponentValues.Solicitantes
                        ? "completado"
                        : "",
                    ],
                  },
                  [
                    _c("span", { staticClass: "number" }, [
                      _vm.notificacion.currentComponentValues.Solicitantes
                        ? _c("i", { staticClass: "fa fa-check" })
                        : _c("i", { staticClass: "fa fa-minus" }),
                    ]),
                  ]
                ),
              ]),
              _c("div", { staticClass: "ml-3" }, [
                _c(
                  "a",
                  {
                    class:
                      _vm.selectedComponent === "Solicitantes"
                        ? "active-menu"
                        : "text-menu",
                    on: {
                      click: function ($event) {
                        return _vm.selected("Solicitantes")
                      },
                    },
                  },
                  [_vm._v(" Solicitantes ")]
                ),
              ]),
            ]
          ),
          _c(
            "div",
            { staticClass: "row flex align-items-center p-2 pointer" },
            [
              _c("div", { staticClass: "ml-3" }, [
                _c(
                  "div",
                  {
                    class: [
                      "circle",
                      _vm.notificacion.currentComponentValues.Ubicacion
                        ? "completado"
                        : "",
                    ],
                  },
                  [
                    _c("span", { staticClass: "number" }, [
                      _vm.notificacion.currentComponentValues.Ubicacion
                        ? _c("i", { staticClass: "fa fa-check" })
                        : _c("i", { staticClass: "fa fa-minus" }),
                    ]),
                  ]
                ),
              ]),
              _c("div", { staticClass: "ml-3" }, [
                _c(
                  "a",
                  {
                    class:
                      _vm.selectedComponent === "Ubicacion"
                        ? "active-menu"
                        : "text-menu",
                    on: {
                      click: function ($event) {
                        return _vm.selected("Ubicacion")
                      },
                    },
                  },
                  [_vm._v(" Ubicación ")]
                ),
              ]),
            ]
          ),
          _c(
            "div",
            { staticClass: "row flex align-items-center p-2 pointer" },
            [
              _c("div", { staticClass: "ml-3" }, [
                _c(
                  "div",
                  {
                    class: [
                      "circle",
                      _vm.notificacion.currentComponentValues.Documentos
                        ? "completado"
                        : "",
                    ],
                  },
                  [
                    _c("span", { staticClass: "number" }, [
                      _vm.notificacion.currentComponentValues.Documentos
                        ? _c("i", { staticClass: "fa fa-check" })
                        : _c("i", { staticClass: "fa fa-minus" }),
                    ]),
                  ]
                ),
              ]),
              _c("div", { staticClass: "ml-3" }, [
                _c(
                  "a",
                  {
                    class:
                      _vm.selectedComponent === "Documentos"
                        ? "active-menu"
                        : "text-menu",
                    on: {
                      click: function ($event) {
                        return _vm.selected("Documentos")
                      },
                    },
                  },
                  [_vm._v(" Documentos ")]
                ),
              ]),
            ]
          ),
          _c(
            "div",
            { staticClass: "row flex align-items-center p-2 pointer" },
            [
              _c("div", { staticClass: "ml-3" }, [
                _c(
                  "div",
                  {
                    class: [
                      "circle",
                      _vm.notificacion.currentComponentValues.Cotizacion
                        ? "completado"
                        : "",
                    ],
                  },
                  [
                    _c("span", { staticClass: "number" }, [
                      _vm.notificacion.currentComponentValues.Cotizacion
                        ? _c("i", { staticClass: "fa fa-check" })
                        : _c("i", { staticClass: "fa fa-minus" }),
                    ]),
                  ]
                ),
              ]),
              _c("div", { staticClass: "ml-3" }, [
                _c(
                  "a",
                  {
                    class:
                      _vm.selectedComponent === "Cotizacion"
                        ? "active-menu"
                        : "text-menu",
                    on: {
                      click: function ($event) {
                        return _vm.selected("Cotizacion")
                      },
                    },
                  },
                  [_vm._v(" Cotización ")]
                ),
              ]),
            ]
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12 text-center mb-3" }, [
        _c("label", [_vm._v("Entrega de información base")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }