var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.user === "Staff"
      ? _c("div", [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-10" },
              [
                _c(
                  "b-button",
                  {
                    staticClass: "btn-fill blue-btn title-text",
                    attrs: { block: "" },
                    on: {
                      click: function ($event) {
                        return _vm.selected("DatosSociedad")
                      },
                    },
                  },
                  [_vm._v(" Entrega de información base ")]
                ),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-1" }, [
              _c(
                "div",
                {
                  class: [
                    "circle",
                    _vm.formalizacion.validoSociedad ? "completado" : "",
                  ],
                },
                [
                  _c("span", { staticClass: "number" }, [
                    _vm.formalizacion.validoSociedad
                      ? _c("i", { staticClass: "fa fa-check" })
                      : _c("i", { staticClass: "fa fa-minus" }),
                  ]),
                ]
              ),
            ]),
            _c(
              "div",
              { staticClass: "col-10" },
              [
                _c(
                  "b-button",
                  {
                    staticClass: "btn-fill blue-btn child",
                    class: {
                      "complete-text": _vm.formalizacion.validoSociedad,
                    },
                    attrs: { block: "" },
                    on: {
                      click: function ($event) {
                        return _vm.selected("DatosSociedad")
                      },
                    },
                  },
                  [_vm._v(" Datos de la Sociedad ")]
                ),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-1" }, [
              _c(
                "div",
                {
                  class: [
                    "circle",
                    _vm.formalizacion.validoAsamblea ? "completado" : "",
                  ],
                },
                [
                  _c("span", { staticClass: "number" }, [
                    _vm.formalizacion.validoAsamblea
                      ? _c("i", { staticClass: "fa fa-check" })
                      : _c("i", { staticClass: "fa fa-minus" }),
                  ]),
                ]
              ),
            ]),
            _c(
              "div",
              { staticClass: "col-10" },
              [
                _c(
                  "b-button",
                  {
                    staticClass: "btn-fill blue-btn child",
                    class: {
                      "complete-text": _vm.formalizacion.validoAsamblea,
                    },
                    attrs: { block: "" },
                    on: {
                      click: function ($event) {
                        return _vm.selected("DatosAsamblea")
                      },
                    },
                  },
                  [_vm._v(" Datos de la Asamblea ")]
                ),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-1" }, [
              _c(
                "div",
                {
                  class: [
                    "circle",
                    _vm.formalizacion.validoDelegado ? "completado" : "",
                  ],
                },
                [
                  _c("span", { staticClass: "number" }, [
                    _vm.formalizacion.validoDelegado
                      ? _c("i", { staticClass: "fa fa-check" })
                      : _c("i", { staticClass: "fa fa-minus" }),
                  ]),
                ]
              ),
            ]),
            _c(
              "div",
              { staticClass: "col-10" },
              [
                _c(
                  "b-button",
                  {
                    staticClass: "btn-fill blue-btn child",
                    class: {
                      "complete-text": _vm.formalizacion.validoDelegado,
                    },
                    attrs: { block: "" },
                    on: {
                      click: function ($event) {
                        return _vm.selected("DatosDelegado")
                      },
                    },
                  },
                  [_vm._v(" Datos Delegado ")]
                ),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-1" }, [
              _c(
                "div",
                {
                  class: [
                    "circle",
                    _vm.formalizacion.validoEntidad ? "completado" : "",
                  ],
                },
                [
                  _c("span", { staticClass: "number" }, [
                    _vm.formalizacion.validoEntidad
                      ? _c("i", { staticClass: "fa fa-check" })
                      : _c("i", { staticClass: "fa fa-minus" }),
                  ]),
                ]
              ),
            ]),
            _c(
              "div",
              { staticClass: "col-10" },
              [
                _c(
                  "b-button",
                  {
                    staticClass: "btn-fill blue-btn child",
                    class: { "complete-text": _vm.formalizacion.validoEntidad },
                    attrs: { block: "" },
                    on: {
                      click: function ($event) {
                        return _vm.selected("EntidadFederativa")
                      },
                    },
                  },
                  [_vm._v(" Entidad Federativa firma ")]
                ),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-1" }, [
              _c(
                "div",
                {
                  class: [
                    "circle",
                    _vm.formalizacion.validoCotizacion ? "completado" : "",
                  ],
                },
                [
                  _c("span", { staticClass: "number" }, [
                    _vm.formalizacion.validoCotizacion
                      ? _c("i", { staticClass: "fa fa-check" })
                      : _c("i", { staticClass: "fa fa-minus" }),
                  ]),
                ]
              ),
            ]),
            _c(
              "div",
              { staticClass: "col-10" },
              [
                _c(
                  "b-button",
                  {
                    staticClass: "btn-fill blue-btn",
                    class: {
                      "complete-text": _vm.formalizacion.validoCotizacion,
                    },
                    attrs: { block: "" },
                    on: {
                      click: function ($event) {
                        return _vm.selected("Cotizacion")
                      },
                    },
                  },
                  [_vm._v(" Cotización ")]
                ),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-1" }, [
              _c(
                "div",
                {
                  class: [
                    "circle",
                    _vm.formalizacion.validoComplementa ? "completado" : "",
                  ],
                },
                [
                  _c("span", { staticClass: "number" }, [
                    _vm.formalizacion.validoComplementa
                      ? _c("i", { staticClass: "fa fa-check" })
                      : _c("i", { staticClass: "fa fa-minus" }),
                  ]),
                ]
              ),
            ]),
            _c(
              "div",
              { staticClass: "col-10" },
              [
                _c(
                  "b-button",
                  {
                    staticClass: "btn-fill blue-btn title-text",
                    class: {
                      "complete-text": _vm.formalizacion.validoComplementa,
                    },
                    attrs: { block: "" },
                    on: {
                      click: function ($event) {
                        return _vm.selected("ComplementaInformacion")
                      },
                    },
                  },
                  [_vm._v(" Complementa la información")]
                ),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-1" }, [
              _c(
                "div",
                {
                  class: [
                    "circle",
                    _vm.formalizacion.validoDenominacion ? "completado" : "",
                  ],
                },
                [
                  _c("span", { staticClass: "number" }, [
                    _vm.formalizacion.validoDenominacion
                      ? _c("i", { staticClass: "fa fa-check" })
                      : _c("i", { staticClass: "fa fa-minus" }),
                  ]),
                ]
              ),
            ]),
            _c(
              "div",
              { staticClass: "col-10" },
              [
                _c(
                  "b-button",
                  {
                    staticClass: "btn-fill blue-btn title-text",
                    class: {
                      "complete-text": _vm.formalizacion.validoDenominacion,
                    },
                    attrs: { block: "" },
                    on: {
                      click: function ($event) {
                        return _vm.selected("DenominacionSocial")
                      },
                    },
                  },
                  [_vm._v(" Denominación social")]
                ),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-1" }, [
              _c(
                "div",
                {
                  class: [
                    "circle",
                    _vm.formalizacion.validoPagoComplementario
                      ? "completado"
                      : "",
                  ],
                },
                [
                  _c("span", { staticClass: "number" }, [
                    _vm.formalizacion.validoPagoComplementario
                      ? _c("i", { staticClass: "fa fa-check" })
                      : _c("i", { staticClass: "fa fa-minus" }),
                  ]),
                ]
              ),
            ]),
            _c(
              "div",
              { staticClass: "col-10" },
              [
                _c(
                  "b-button",
                  {
                    staticClass: "btn-fill blue-btn title-text",
                    class: {
                      "complete-text":
                        _vm.formalizacion.validoPagoComplementario,
                    },
                    attrs: { block: "" },
                    on: {
                      click: function ($event) {
                        return _vm.selected("PagoComplementario")
                      },
                    },
                  },
                  [_vm._v(" Pago complementario")]
                ),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-10" },
              [
                _c(
                  "b-button",
                  {
                    staticClass: "btn-fill blue-btn title-text",
                    attrs: { block: "" },
                    on: {
                      click: function ($event) {
                        return _vm.selected("AsignarCorreduria")
                      },
                    },
                  },
                  [_vm._v(" Asignar correduría ")]
                ),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-10" },
              [
                _c(
                  "b-button",
                  {
                    staticClass: "btn-fill blue-btn title-text",
                    attrs: { block: "" },
                    on: {
                      click: function ($event) {
                        return _vm.selected("Expediente")
                      },
                    },
                  },
                  [_vm._v("Expediente ")]
                ),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-10" },
              [
                _c(
                  "b-button",
                  {
                    staticClass: "btn-fill blue-btn",
                    class: { "complete-text": _vm.formalizacion.validoGenera },
                    attrs: { block: "" },
                    on: {
                      click: function ($event) {
                        return _vm.selected("GeneraDocumento")
                      },
                    },
                  },
                  [_vm._v(" Genera documentos ")]
                ),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-10" },
              [
                _c(
                  "b-button",
                  {
                    staticClass: "btn-fill blue-btn",
                    class: {
                      "complete-text": _vm.formalizacion.validoDocListo,
                    },
                    attrs: { block: "" },
                    on: {
                      click: function ($event) {
                        return _vm.selected("DocFirmado")
                      },
                    },
                  },
                  [_vm._v(" Documento firmado ")]
                ),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-1" }, [
              _c(
                "div",
                {
                  class: [
                    "circle",
                    _vm.formalizacion.validoCita ? "completado" : "",
                  ],
                },
                [
                  _c("span", { staticClass: "number" }, [
                    _vm.formalizacion.validoCita
                      ? _c("i", { staticClass: "fa fa-check" })
                      : _c("i", { staticClass: "fa fa-minus" }),
                  ]),
                ]
              ),
            ]),
            _c(
              "div",
              { staticClass: "col-10" },
              [
                _c(
                  "b-button",
                  {
                    staticClass: "btn-fill blue-btn",
                    class: { "complete-text": _vm.formalizacion.validoCita },
                    attrs: { block: "" },
                    on: {
                      click: function ($event) {
                        return _vm.selected("CitaFirma")
                      },
                    },
                  },
                  [_vm._v(" Agenda tu cita ")]
                ),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-1" }, [
              _c(
                "div",
                {
                  class: [
                    "circle",
                    _vm.formalizacion.validoTerminado ? "completado" : "",
                  ],
                },
                [
                  _c("span", { staticClass: "number" }, [
                    _vm.formalizacion.validoTerminado
                      ? _c("i", { staticClass: "fa fa-check" })
                      : _c("i", { staticClass: "fa fa-minus" }),
                  ]),
                ]
              ),
            ]),
            _c(
              "div",
              { staticClass: "col-10" },
              [
                _c(
                  "b-button",
                  {
                    staticClass: "btn-fill blue-btn",
                    class: {
                      "complete-text": _vm.formalizacion.validoTerminado,
                    },
                    attrs: { block: "" },
                    on: {
                      click: function ($event) {
                        return _vm.selected("Terminado")
                      },
                    },
                  },
                  [_vm._v(" Terminado ")]
                ),
              ],
              1
            ),
          ]),
        ])
      : _vm.user === "Client"
      ? _c("div", [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-12" },
              [
                _c(
                  "b-button",
                  {
                    staticClass: "btn-fill blue-btn title-text",
                    attrs: { block: "" },
                    on: {
                      click: function ($event) {
                        return _vm.selected("DatosSociedad")
                      },
                    },
                  },
                  [_vm._v(" Entrega de información base ")]
                ),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-1" }, [
              _c(
                "div",
                {
                  class: [
                    "circle",
                    _vm.formalizacion.validoSociedad ? "completado" : "",
                  ],
                },
                [
                  _c("span", { staticClass: "number" }, [
                    _vm.formalizacion.validoSociedad
                      ? _c("i", { staticClass: "fa fa-check" })
                      : _c("i", { staticClass: "fa fa-minus" }),
                  ]),
                ]
              ),
            ]),
            _c(
              "div",
              { staticClass: "col-10" },
              [
                _c(
                  "b-button",
                  {
                    staticClass: "btn-fill blue-btn child",
                    attrs: { block: "" },
                    on: {
                      click: function ($event) {
                        return _vm.selected("DatosSociedad")
                      },
                    },
                  },
                  [_vm._v(" Datos de la Sociedad ")]
                ),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-1" }, [
              _c(
                "div",
                {
                  class: [
                    "circle",
                    _vm.formalizacion.validoAsamblea ? "completado" : "",
                  ],
                },
                [
                  _c("span", { staticClass: "number" }, [
                    _vm.formalizacion.validoAsamblea
                      ? _c("i", { staticClass: "fa fa-check" })
                      : _c("i", { staticClass: "fa fa-minus" }),
                  ]),
                ]
              ),
            ]),
            _c(
              "div",
              { staticClass: "col-10" },
              [
                _c(
                  "b-button",
                  {
                    staticClass: "btn-fill blue-btn child",
                    attrs: { block: "" },
                    on: {
                      click: function ($event) {
                        return _vm.selected("DatosAsamblea")
                      },
                    },
                  },
                  [_vm._v(" Datos de la asamblea ")]
                ),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-1" }, [
              _c(
                "div",
                {
                  class: [
                    "circle",
                    _vm.formalizacion.validoDelegado ? "completado" : "",
                  ],
                },
                [
                  _c("span", { staticClass: "number" }, [
                    _vm.formalizacion.validoDelegado
                      ? _c("i", { staticClass: "fa fa-check" })
                      : _c("i", { staticClass: "fa fa-minus" }),
                  ]),
                ]
              ),
            ]),
            _c(
              "div",
              { staticClass: "col-10" },
              [
                _c(
                  "b-button",
                  {
                    staticClass: "btn-fill blue-btn child",
                    attrs: { block: "" },
                    on: {
                      click: function ($event) {
                        return _vm.selected("DatosDelegado")
                      },
                    },
                  },
                  [_vm._v(" Datos Delegado ")]
                ),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-1" }, [
              _c(
                "div",
                {
                  class: [
                    "circle",
                    _vm.formalizacion.validoEntidad ? "completado" : "",
                  ],
                },
                [
                  _c("span", { staticClass: "number" }, [
                    _vm.formalizacion.validoEntidad
                      ? _c("i", { staticClass: "fa fa-check" })
                      : _c("i", { staticClass: "fa fa-minus" }),
                  ]),
                ]
              ),
            ]),
            _c(
              "div",
              { staticClass: "col-10" },
              [
                _c(
                  "b-button",
                  {
                    staticClass: "btn-fill blue-btn child",
                    class: { "complete-text": _vm.formalizacion.validoEntidad },
                    attrs: { block: "" },
                    on: {
                      click: function ($event) {
                        return _vm.selected("EntidadFederativa")
                      },
                    },
                  },
                  [_vm._v(" Entidad Federativa firma ")]
                ),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-1" }, [
              _c(
                "div",
                {
                  class: [
                    "circle",
                    _vm.formalizacion.validoCotizacion ? "completado" : "",
                  ],
                },
                [
                  _c("span", { staticClass: "number" }, [
                    _vm.formalizacion.validoCotizacion
                      ? _c("i", { staticClass: "fa fa-check" })
                      : _c("i", { staticClass: "fa fa-minus" }),
                  ]),
                ]
              ),
            ]),
            _c(
              "div",
              { staticClass: "col-10" },
              [
                _c(
                  "b-button",
                  {
                    staticClass: "btn-fill blue-btn child",
                    attrs: { block: "" },
                    on: {
                      click: function ($event) {
                        return _vm.selected("Cotizacion")
                      },
                    },
                  },
                  [_vm._v(" Cotización y pago ")]
                ),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-1" }, [
              _c(
                "div",
                {
                  class: [
                    "circle",
                    _vm.formalizacion.validoComplementa ? "completado" : "",
                  ],
                },
                [
                  _c("span", { staticClass: "number" }, [
                    _vm.formalizacion.validoComplementa
                      ? _c("i", { staticClass: "fa fa-check" })
                      : _c("i", { staticClass: "fa fa-minus" }),
                  ]),
                ]
              ),
            ]),
            _c(
              "div",
              { staticClass: "col-10" },
              [
                _c(
                  "b-button",
                  {
                    staticClass: "btn-fill blue-btn",
                    attrs: { block: "" },
                    on: {
                      click: function ($event) {
                        return _vm.selected("ComplementaInformacion")
                      },
                    },
                  },
                  [_vm._v(" Complementa la información")]
                ),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-1" }, [
              _c(
                "div",
                {
                  class: [
                    "circle",
                    _vm.formalizacion.validoPagoComplementario
                      ? "completado"
                      : "",
                  ],
                },
                [
                  _c("span", { staticClass: "number" }, [
                    _vm.formalizacion.validoPagoComplementario
                      ? _c("i", { staticClass: "fa fa-check" })
                      : _c("i", { staticClass: "fa fa-minus" }),
                  ]),
                ]
              ),
            ]),
            _c(
              "div",
              { staticClass: "col-10" },
              [
                _c(
                  "b-button",
                  {
                    staticClass: "btn-fill blue-btn",
                    attrs: { block: "" },
                    on: {
                      click: function ($event) {
                        return _vm.selected("PagoComplementario")
                      },
                    },
                  },
                  [_vm._v(" Pago complementario ")]
                ),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-1" }, [
              _c(
                "div",
                {
                  class: [
                    "circle",
                    _vm.formalizacion.validoCita ? "completado" : "",
                  ],
                },
                [
                  _c("span", { staticClass: "number" }, [
                    _vm.formalizacion.validoCita
                      ? _c("i", { staticClass: "fa fa-check" })
                      : _c("i", { staticClass: "fa fa-minus" }),
                  ]),
                ]
              ),
            ]),
            _c(
              "div",
              { staticClass: "col-10" },
              [
                _c(
                  "b-button",
                  {
                    staticClass: "btn-fill blue-btn",
                    attrs: { block: "" },
                    on: {
                      click: function ($event) {
                        return _vm.selected("FirmaDigital")
                      },
                    },
                  },
                  [_vm._v(" Firma digital ")]
                ),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-1" }, [
              _c(
                "div",
                {
                  class: [
                    "circle",
                    _vm.formalizacion.validoCita ? "completado" : "",
                  ],
                },
                [
                  _c("span", { staticClass: "number" }, [
                    _vm.formalizacion.validoCita
                      ? _c("i", { staticClass: "fa fa-check" })
                      : _c("i", { staticClass: "fa fa-minus" }),
                  ]),
                ]
              ),
            ]),
            _c(
              "div",
              { staticClass: "col-10" },
              [
                _c(
                  "b-button",
                  {
                    staticClass: "btn-fill blue-btn",
                    attrs: { block: "" },
                    on: {
                      click: function ($event) {
                        return _vm.selected("CitaFirma")
                      },
                    },
                  },
                  [_vm._v(" Agenda tu cita ")]
                ),
              ],
              1
            ),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }