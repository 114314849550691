var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-md-6" },
        [
          _c("ValidationProvider", {
            attrs: {
              name: "email",
              rules: "required|email",
              "custom-messages": {
                required: "El email es obligatorio",
                email: "Debe ser un email válido",
              },
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ errors }) {
                  return [
                    _c("base-input", {
                      attrs: {
                        type: "email",
                        label: "Email",
                        placeholder: "Email",
                        autocomplete: "username",
                      },
                      model: {
                        value: _vm.user.email,
                        callback: function ($$v) {
                          _vm.$set(_vm.user, "email", $$v)
                        },
                        expression: "user.email",
                      },
                    }),
                    _c("span", { staticClass: "cardErrors" }, [
                      _vm._v(_vm._s(errors[0])),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-md-6" },
        [
          _c("ValidationProvider", {
            attrs: {
              name: "password",
              rules: "required",
              "custom-messages": {
                required: "La contraseña es obligatoria",
              },
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ errors }) {
                  return [
                    _c("base-input", {
                      attrs: {
                        type: "password",
                        label: "Contraseña",
                        placeholder: "Contraseña",
                        autocomplete: "current-password",
                      },
                      model: {
                        value: _vm.user.password,
                        callback: function ($$v) {
                          _vm.$set(_vm.user, "password", $$v)
                        },
                        expression: "user.password",
                      },
                    }),
                    _c("span", { staticClass: "cardErrors" }, [
                      _vm._v(_vm._s(errors[0])),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ]),
    _c(
      "button",
      { staticClass: "btn orange-btn float-right", attrs: { type: "submit" } },
      [_vm._v("Continuar")]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }