var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c(
        "b-modal",
        {
          ref: "documento-modal",
          attrs: {
            "no-close-on-backdrop": "",
            centered: "",
            title: "",
            "hide-footer": "",
            id: "modalC",
            size: "l",
          },
        },
        [
          _c("ValidationObserver", {
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ handleSubmit }) {
                  return [
                    _c(
                      "form",
                      {
                        on: {
                          submit: function ($event) {
                            $event.preventDefault()
                            return handleSubmit(_vm.putDocumento)
                          },
                        },
                      },
                      [
                        _c("ModalDocumento", {
                          attrs: {
                            currentDocumento: _vm.currentDocumento,
                            optionsDocumentos: _vm.optionsDocumentos,
                          },
                          on: {
                            submit: function ($event) {
                              $event.preventDefault()
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          ref: "modal-confirm-dialog",
          attrs: {
            "no-close-on-backdrop": "",
            centered: "",
            "hide-footer": "",
            id: "modal-confirm-dialog",
          },
        },
        [
          _c("h4", { staticClass: "text-center" }, [
            _vm._v("¿Seguro que quiere eliminar el documento?"),
          ]),
          _c("h5", { staticClass: "text-center" }),
          _c(
            "b-button",
            {
              staticClass: "mt-3",
              attrs: { variant: "danger" },
              on: {
                click: function ($event) {
                  return _vm.$bvModal.hide("modal-confirm-dialog")
                },
              },
            },
            [_vm._v("Cancelar")]
          ),
          _c(
            "b-button",
            {
              staticClass: "mt-3 float-right",
              attrs: { variant: "primary" },
              on: { click: _vm.deleteDocumento },
            },
            [_vm._v("OK")]
          ),
        ],
        1
      ),
      _c("h4", { staticClass: "cardD" }, [
        _vm._v("Información de los Documentos a Notificar"),
      ]),
      _c(
        "div",
        { staticClass: "mt-4" },
        [
          _c("label", { attrs: { for: "btn-add-document" } }, [
            _vm._v("Agregar documento"),
          ]),
          _c(
            "b-button",
            {
              staticClass: "btn-fill spaceL",
              attrs: { id: "btn-add-document", variant: "success", size: "sm" },
              on: { click: _vm.addDocumento },
            },
            [_vm._v("+")]
          ),
        ],
        1
      ),
      _c(
        "div",
        [
          _c("b-table", {
            ref: "table",
            attrs: {
              hover: "",
              fields: _vm.fieldsCuadro,
              items: _vm.notificacion.documentos,
            },
            scopedSlots: _vm._u([
              {
                key: "cell(acciones)",
                fn: function ({ item }) {
                  return [
                    _c(
                      "b-button",
                      {
                        directives: [
                          {
                            name: "b-tooltip",
                            rawName: "v-b-tooltip.hover",
                            modifiers: { hover: true },
                          },
                        ],
                        staticClass: "editBtn",
                        attrs: {
                          variant: "warning",
                          size: "sm",
                          title: "Editar",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.editDocumento(item)
                          },
                        },
                      },
                      [_c("b-icon-pencil")],
                      1
                    ),
                    _c(
                      "b-button",
                      {
                        directives: [
                          {
                            name: "b-tooltip",
                            rawName: "v-b-tooltip.hover",
                            modifiers: { hover: true },
                          },
                        ],
                        attrs: {
                          variant: "danger",
                          size: "sm",
                          title: "Eliminar",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.predeleteDocumento(item.id)
                          },
                        },
                      },
                      [_c("b-icon-trash")],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm.notificacion.documentos.length > 0
        ? _c("div", { staticClass: "row mt-1" }, [
            _c(
              "div",
              { staticClass: "col-12 d-flex" },
              [
                _c(
                  "b-form-checkbox",
                  {
                    staticClass: "orange",
                    attrs: { id: "checkbox-fad" },
                    model: {
                      value: _vm.notificacion.fad,
                      callback: function ($$v) {
                        _vm.$set(_vm.notificacion, "fad", $$v)
                      },
                      expression: "notificacion.fad",
                    },
                  },
                  [
                    _vm._v(
                      " Alguno de los documentos será firmado mediante Firma Autógrafa Digital "
                    ),
                  ]
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
      _c("div", { staticClass: "mt-4" }, [
        _c(
          "div",
          [
            _c(
              "b-button",
              {
                staticClass: "btn-fill orange-btn bottom2",
                attrs: { type: "submit", size: "m" },
              },
              [_vm._v("Continuar")]
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }