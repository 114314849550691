import { render, staticRenderFns } from "./ConstitucionSociedadesInfo.vue?vue&type=template&id=faf38a10&scoped=true"
import script from "./ConstitucionSociedadesInfo.vue?vue&type=script&lang=js"
export * from "./ConstitucionSociedadesInfo.vue?vue&type=script&lang=js"
import style0 from "./ConstitucionSociedadesInfo.vue?vue&type=style&index=0&id=faf38a10&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "faf38a10",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/github/workspace/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('faf38a10')) {
      api.createRecord('faf38a10', component.options)
    } else {
      api.reload('faf38a10', component.options)
    }
    module.hot.accept("./ConstitucionSociedadesInfo.vue?vue&type=template&id=faf38a10&scoped=true", function () {
      api.rerender('faf38a10', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/clients/procesos/info_procesos/ConstitucionSociedadesInfo.vue"
export default component.exports