var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container mt-5" },
    [
      _vm.loading
        ? _c("div", [
            _c(
              "div",
              { attrs: { id: "loader" } },
              [
                _c("font-awesome-icon", {
                  attrs: { icon: "spinner", spin: "" },
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
      _c("b-card", [
        _c("div", { staticClass: "mt-3" }, [
          _c("p", [
            _vm._v(
              " Al elegir esta forma de pago le llegará un correo electrónico con los datos bancarios para que realice la transferencia. "
            ),
          ]),
          _c("p", [
            _vm._v(
              " Una vez realizada, le enviaremos un mail de confirmación y se verá reflejado en su proceso. "
            ),
          ]),
          _c("p", [
            _vm._v(
              " A continuación, podrá continuar con los siguientes pasos. "
            ),
          ]),
        ]),
        _c("div", { staticClass: "m-3 cards" }, [
          _c("img", {
            staticClass: "mr-3",
            attrs: {
              src: require("@/assets/img/openpay/spei.webp"),
              alt: "spei",
              height: "120",
            },
          }),
        ]),
        _c("div", { staticClass: "mt-3" }, [
          _c("p", [
            _vm._v(
              " Puede realizar su pago en cualquiera de las siguientes instituciones bancarias: "
            ),
          ]),
        ]),
        _c("div", { staticClass: "m-3 cards" }, [
          _c("img", {
            attrs: {
              src: require("@/assets/img/openpay/afirme.webp"),
              alt: "afirme",
              height: "32",
            },
          }),
          _c("img", {
            attrs: {
              src: require("@/assets/img/openpay/bancoAzteca.webp"),
              alt: "bancoAzteca",
              height: "32",
            },
          }),
          _c("img", {
            attrs: {
              src: require("@/assets/img/openpay/banorte.webp"),
              alt: "banorte",
              height: "32",
            },
          }),
          _c("img", {
            attrs: {
              src: require("@/assets/img/openpay/banregio.webp"),
              alt: "banregio",
              height: "32",
            },
          }),
          _c("img", {
            attrs: {
              src: require("@/assets/img/openpay/BBVA.webp"),
              alt: "BBVA",
              height: "32",
            },
          }),
          _c("img", {
            attrs: {
              src: require("@/assets/img/openpay/citibanamex.webp"),
              alt: "citibanamex",
              height: "32",
            },
          }),
        ]),
        _c("div", { staticClass: "m-3 cards" }, [
          _c("img", {
            attrs: {
              src: require("@/assets/img/openpay/hsbc.webp"),
              alt: "hsbc",
              height: "32",
            },
          }),
          _c("img", {
            attrs: {
              src: require("@/assets/img/openpay/inbursa.webp"),
              alt: "inbursa",
              height: "32",
            },
          }),
          _c("img", {
            attrs: {
              src: require("@/assets/img/openpay/invex.webp"),
              alt: "invex",
              height: "32",
            },
          }),
          _c("img", {
            attrs: {
              src: require("@/assets/img/openpay/ixe.webp"),
              alt: "ixe",
              height: "32",
            },
          }),
          _c("img", {
            attrs: {
              src: require("@/assets/img/openpay/monex.webp"),
              alt: "monex",
              height: "32",
            },
          }),
          _c("img", {
            attrs: {
              src: require("@/assets/img/openpay/santander.webp"),
              alt: "santander",
              height: "32",
            },
          }),
          _c("img", {
            attrs: {
              src: require("@/assets/img/openpay/scotiabank.webp"),
              alt: "scotiabank",
              height: "32",
            },
          }),
        ]),
        _c(
          "div",
          [
            _c(
              "b-button",
              {
                staticClass: "btn-fill orange-btn bottom2",
                attrs: { size: "m", disabled: !_vm.precio_total },
                on: {
                  click: function ($event) {
                    return _vm.pagar()
                  },
                },
              },
              [_vm._v(" Pagar ")]
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }