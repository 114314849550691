/**
 * Global configuration variables.
 */
export default {
  // TODO Eliminar urls hardcodeadas
  DEBUG: process.env.NODE_ENV === 'development',
  BASE_URL: process.env.BASE_URL,

  API_URL: process.env.VUE_APP_API_URL,
  API_KEY: process.env.VUE_APP_API_KEY,
  CD_OPENPAY_ID: process.env.VUE_APP_CD_OPENPAY_ID,
  CD_OPENPAY_PUBLIC: process.env.VUE_APP_CD_OPENPAY_PUBLIC,
  CD_OPENPAY_PRIVATE: process.env.VUE_APP_CD_OPENPAY_PRIVATE,
  CD_OPENPAY_URL: process.env.VUE_APP_CD_OPENPAY_URL,
  CD_OPENPAY_REDIRECT_BASE_URL: process.env.VUE_APP_CD_OPENPAY_REDIRECT_BASE_URL,
  CD_OPENPAY_IS_DEVELOPMENT: process.env.VUE_APP_CD_OPENPAY_IS_DEVELOPMENT,
  DOMAIN: process.env.VUE_APP_DOMAIN,
  GOOGLE_API_KEY: process.env.VUE_APP_GOOGLE_API_KEY,
  STG_BASE_URL: process.env.VUE_APP_STG_BASE_URL,
  STG_ACCESS_KEY: process.env.VUE_APP_STG_ACCESS_KEY,

  EXAMPLE_ENV_VARIABLE: process.env.VUE_APP_EXAMPLE_ENV_VARIABLE,
  URL_TEST: process.env.VUE_APP_URL_TEST,
  BUILD_MODE: process.env.VUE_APP_BUILD_MODE,
};
