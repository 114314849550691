var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-md-12" },
        [
          _c("ValidationProvider", {
            attrs: {
              name: "catDocumentos_id",
              rules: "required",
              "custom-messages": {
                required: "El tipo de documento es obligatorio",
              },
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ errors }) {
                  return [
                    _c("label", { attrs: { for: "" } }, [
                      _vm._v("Tipo de documento"),
                    ]),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.currentDocumento.catDocumentos_id,
                            expression: "currentDocumento.catDocumentos_id",
                          },
                        ],
                        staticClass: "form-control",
                        on: {
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.currentDocumento,
                              "catDocumentos_id",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                        },
                      },
                      _vm._l(
                        _vm.options_principal_documento,
                        function (option) {
                          return _c(
                            "option",
                            {
                              key: option.value,
                              domProps: { value: option.value },
                            },
                            [_vm._v(" " + _vm._s(option.text) + " ")]
                          )
                        }
                      ),
                      0
                    ),
                    _c("span", { staticClass: "cardErrors" }, [
                      _vm._v(_vm._s(errors[0])),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ]),
    _vm.currentDocumento.catDocumentos_id == _vm.id_otro
      ? _c("div", { staticClass: "row mt-4" }, [
          _c(
            "div",
            { staticClass: "col-md-12" },
            [
              _c("ValidationProvider", {
                attrs: {
                  name: "descripcion",
                  rules: "required",
                  "custom-messages": {
                    required: "La descripción del documento es obligatoria",
                  },
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function ({ errors }) {
                        return [
                          _c("base-input", {
                            attrs: {
                              type: "text",
                              label: "Descripción",
                              placeholder: "Descripción",
                            },
                            model: {
                              value: _vm.currentDocumento.descripcion,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.currentDocumento,
                                  "descripcion",
                                  $$v
                                )
                              },
                              expression: "currentDocumento.descripcion",
                            },
                          }),
                          _c("span", { staticClass: "cardErrors" }, [
                            _vm._v(_vm._s(errors[0])),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2706138744
                ),
              }),
            ],
            1
          ),
        ])
      : _vm._e(),
    _c("div", { staticClass: "row mt-4" }, [
      _c(
        "div",
        { staticClass: "col-md-12" },
        [
          _c("ValidationProvider", {
            attrs: {
              name: "descripcion",
              rules: "required",
              "custom-messages": {
                required: "La cantidad de páginas es obligatoria",
              },
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ errors }) {
                  return [
                    _c("div", [
                      _c("label", { attrs: { for: "" } }, [
                        _vm._v("Número de páginas del documento"),
                      ]),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.currentDocumento.paginas,
                            expression: "currentDocumento.paginas",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "number",
                          required: "",
                          placeholder: "1",
                          min: "1",
                        },
                        domProps: { value: _vm.currentDocumento.paginas },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.currentDocumento,
                              "paginas",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                    _c("span", { staticClass: "cardErrors" }, [
                      _vm._v(_vm._s(errors[0])),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "row mt-4" }, [
      _c(
        "div",
        { staticClass: "col-md-12" },
        [
          _c("ValidationProvider", {
            attrs: { name: "anexo" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ errors }) {
                  return [
                    _c(
                      "b-form-checkbox",
                      {
                        on: { change: _vm.resetAnexo },
                        model: {
                          value: _vm.currentDocumento.tiene_anexo,
                          callback: function ($$v) {
                            _vm.$set(_vm.currentDocumento, "tiene_anexo", $$v)
                          },
                          expression: "currentDocumento.tiene_anexo",
                        },
                      },
                      [_vm._v(" El documento contiene anexo(s) ")]
                    ),
                    _c("span", { staticClass: "cardErrors" }, [
                      _vm._v(_vm._s(errors[0])),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ]),
    _vm.currentDocumento.tiene_anexo
      ? _c("div", { staticClass: "row mt-4" }, [
          _c(
            "div",
            { staticClass: "col-md-12" },
            [
              _c("ValidationProvider", {
                attrs: {
                  name: "descripcion",
                  rules: "required",
                  "custom-messages": {
                    required: "La cantidad de páginas es obligatoria",
                  },
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function ({ errors }) {
                        return [
                          _c("div", [
                            _c("label", { attrs: { for: "" } }, [
                              _vm._v(
                                "Número total de páginas de todos los anexos"
                              ),
                            ]),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.currentDocumento.paginas_anexo,
                                  expression: "currentDocumento.paginas_anexo",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                type: "number",
                                required: "",
                                placeholder: "1",
                                min: "1",
                              },
                              domProps: {
                                value: _vm.currentDocumento.paginas_anexo,
                              },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.currentDocumento,
                                    "paginas_anexo",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]),
                          _c("span", { staticClass: "cardErrors" }, [
                            _vm._v(_vm._s(errors[0])),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  1731204381
                ),
              }),
            ],
            1
          ),
          _c("div", { staticClass: "col-md-12" }, [
            _c(
              "div",
              { staticClass: "mt-4" },
              [
                _c("ValidationProvider", {
                  attrs: { name: "digital" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function ({ errors }) {
                          return [
                            _c(
                              "b-form-checkbox",
                              {
                                model: {
                                  value: _vm.currentDocumento.digital,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.currentDocumento,
                                      "digital",
                                      $$v
                                    )
                                  },
                                  expression: "currentDocumento.digital",
                                },
                              },
                              [
                                _vm._v(
                                  " Alguno de los anexos incluye algún dispositivo digital (USB Pendrive, CD) "
                                ),
                              ]
                            ),
                            _c("span", { staticClass: "cardErrors" }, [
                              _vm._v(_vm._s(errors[0])),
                            ]),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    2274025552
                  ),
                }),
              ],
              1
            ),
          ]),
        ])
      : _vm._e(),
    _vm._m(0),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "mt-4 text-center" }, [
      _c(
        "button",
        {
          staticClass: "btn btn-fill float-right orange-btn",
          attrs: { type: "submit" },
        },
        [_vm._v(" Guardar ")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }