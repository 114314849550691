var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("h4", { staticClass: "cardD" }, [_vm._v("Firma digital")]),
      _c("EsperaValidacion"),
      _vm._m(0),
      _c(
        "b-modal",
        {
          ref: "documento-modal-preview-img",
          attrs: {
            "no-close-on-backdrop": "",
            centered: "",
            title: "",
            "hide-footer": "",
            id: "modaldi",
            size: "xl",
          },
        },
        [
          _c("ModalPreviewImg", {
            attrs: { currentDocumento: _vm.currentDocumento },
          }),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          ref: "documento-modal-preview-pdf",
          attrs: {
            "no-close-on-backdrop": "",
            centered: "",
            title: "",
            "hide-footer": "",
            id: "modaldf",
            size: "xl",
          },
        },
        [
          _c("ModalPreviewPDF", {
            attrs: { currentDocumento: _vm.currentDocumento },
          }),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          ref: "documento-modal-preview-video",
          attrs: {
            "no-close-on-backdrop": "",
            centered: "",
            title: "",
            "hide-footer": "",
            id: "modalvideo",
            size: "xl",
          },
        },
        [
          _c("ModalPreviewVideo", {
            attrs: { currentDocumento: _vm.currentDocumento },
          }),
        ],
        1
      ),
      _c("h4", { staticClass: "cardD mt-4" }, [_vm._v("Documentos firmados")]),
      _c("label", { attrs: { for: "" } }, [
        _vm._v(
          "Se mostrarán en la tabla los archivos que firmen los accionistas"
        ),
      ]),
      _c("b-table", {
        attrs: { hover: "", fields: _vm.fields, items: _vm.documentosFAD },
        scopedSlots: _vm._u([
          {
            key: "cell(index)",
            fn: function ({ index }) {
              return [_vm._v(" " + _vm._s(_vm.getIndex(index + 1)) + " ")]
            },
          },
          {
            key: "cell(acciones)",
            fn: function ({ item }) {
              return [
                _c(
                  "b-button",
                  {
                    directives: [
                      {
                        name: "b-tooltip",
                        rawName: "v-b-tooltip.hover",
                        modifiers: { hover: true },
                      },
                    ],
                    staticClass: "editBtn",
                    attrs: {
                      variant: "primary",
                      size: "sm",
                      title: "Vista previa",
                      hidden: item.disabled,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.previewDocumento(item)
                      },
                    },
                  },
                  [_c("b-icon-eye")],
                  1
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h4", {}, [
      _c("i", { staticClass: "fa fa-info" }),
      _vm._v(
        " Validaremos la información y los documentos que has cargado de los comparecientes y te contacteremos por email en caso de ser necesario. "
      ),
      _c("p"),
      _c("br"),
      _c("i", { staticClass: "fa fa-envelope" }),
      _vm._v(
        " Si todo es correcto, te llegará un email con los documentos que firmarás digitalmente, además, del proyecto de la constitutiva que firmarás presencialmente. Después, te llegará un email de FAD - Firma Autógrafa Digital, donde se te indicarán los pasos para realizar la firma digital. "
      ),
      _c("p"),
      _c("br"),
      _c("i", { staticClass: "fa fa-pencil" }),
      _vm._v(
        " Si estás de acuerdo en el contenido de los documentos, te pedimos que los firmes, de lo contrario, te pedimos que te comuniques con nosotros. "
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }