var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c("h4", { staticClass: "cardD" }, [_vm._v("Pago")]),
      _c(
        "b-tabs",
        { attrs: { fill: "" } },
        [
          _c(
            "b-tab",
            { attrs: { title: "Tarjeta de crédito o débito", active: "" } },
            [
              _c("Openpay", {
                attrs: {
                  cotizacion_id: _vm.notificacion.id_cotizaciones,
                  precio_total: _vm.notificacion.precio_total,
                },
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                  },
                  payCotizacion: function ($event) {
                    return _vm.payCotizacion($event)
                  },
                },
              }),
            ],
            1
          ),
          _c(
            "b-tab",
            { staticClass: "modalPay", attrs: { title: "SPEI/Transferencia" } },
            [
              _c("Spei", {
                attrs: {
                  cotizacion_id: _vm.notificacion.id_cotizaciones,
                  precio_total: _vm.notificacion.precio_total,
                  proceso_id: _vm.notificacion.id,
                  tipo: "notificacion",
                },
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                  },
                  payCotizacionSpei: function ($event) {
                    return _vm.payCotizacionSpei($event)
                  },
                },
              }),
            ],
            1
          ),
          _c(
            "b-tab",
            { staticClass: "modalPay", attrs: { title: "Monedero" } },
            [
              _c("Monedero", {
                attrs: {
                  cotizacion_id: _vm.notificacion.id_cotizaciones,
                  precio_total: _vm.notificacion.precio_total,
                  clientes_id: _vm.notificacion.id_clientes,
                  servicio: "Notificacion " + _vm.notificacion.id,
                },
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                  },
                  payCotizacion: function ($event) {
                    return _vm.payCotizacionMonedero($event)
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }