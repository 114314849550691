<template>
  <div class="content">
    <!-- <b-breadcrumb>
      <b-breadcrumb-item active>Monedero</b-breadcrumb-item>
    </b-breadcrumb> -->

    <div class="container">
      <div v-if="loading">
        <div id="loader">
          <font-awesome-icon icon="spinner" spin />
        </div>
      </div>
    </div>

    <div class="container">
      <b-card>
        <div class="row">
          <div class="col-6">
            <h4 class="cardD">Saldo actual</h4>
            <h3 class="saldo">{{ formattedSaldo }}</h3>
          </div>
          <div class="col-6">
            <h4 class="cardD">Monto a recargar: {{ formattedMontoARecargar }}</h4>
            <b-form-input type="number" class="saldo" v-model="monto_carga" placeholder="Cantidad a recargar"
              min="0"></b-form-input>
          </div>
        </div>

        <p class="cardD">Forma de 
          pago</p>
        <b-tabs fill>
          <b-tab title="Tarjeta de crédito o débito" active>
            <ValidationObserver v-slot="{ handleSubmit }">
              <form @submit.prevent="handleSubmit(payCotizacion)">
                <Openpay :cotizacion_id="null" :precio_total="monto_carga" @submit.prevent
                  @payCotizacion="recargar($event)" />
              </form>
            </ValidationObserver>
          </b-tab>
          <b-tab title="SPEI/Transferencia" class="modalPay">
            <ValidationObserver v-slot="{ handleSubmit }">
              <form @submit.prevent="handleSubmit(payCotizacionSpei)">
                <Spei :cotizacion_id="0" :precio_total="monto_carga" :proceso_id="null" :tipo="'monedero'"
                  @submit.prevent @payCotizacionSpei="payCotizacionSpei($event)" />
              </form>
            </ValidationObserver>
          </b-tab>
        </b-tabs>
      </b-card>
    </div>

  </div>

</template>
<script>
import Openpay from "@/views/openpay/Openpay.vue";
import Spei from "@/views/openpay/Spei.vue";
import Card from "src/components/Cards/Card.vue";

import { ClientesApi } from "@/api";

import { formatCurrency } from "@/utils/number-utils";
import Storage from "@/utils/storage";

const storage = new Storage();

export default {
  name: "Monedero",

  components: {
    Card,
    Openpay,
    Spei
  },

  data: () => ({
    loading: false,
    id: 0,
    saldo: 0,
    monto_carga: null
  }),

  created() {
    this.getClient();
  },

  computed: {
    formattedSaldo() {
      return formatCurrency(this.saldo);
    },
    formattedMontoARecargar() {
      return formatCurrency(this.monto_carga);
    },
  },

  methods: {
    formatter(value) {
      return formatCurrency(value);
    },

    async getClient() {
      try {
        this.loading = true;

        const clienteEmail = storage.getItem("user");
        const { id } = await ClientesApi.clienteId(clienteEmail);
        const { saldo } = await ClientesApi.detail(id);

        this.id = id;
        this.saldo = saldo;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    async recargar(payed) {
      try {
        this.loading = true;

        if (!payed) {
          return;
        }

        const clienteEmail = storage.getItem("user");
        const { id } = await ClientesApi.clienteId(clienteEmail);
        const { saldo } = await ClientesApi.detail(id);
        this.saldo = saldo;

        return;

        //tengo que sumarle la cantidad al saldo
        // const saldo_suma = this.saldo + Number(this.monto_carga);
        // this.saldo = saldo_suma;

        // this.$toast.success("Recarga de saldo realizada, se está verificando su pago.", {
        //   timeout: 5000, //false,
        //   hideProgressBar: false,
        //   position: "top-right",
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: false,
        //   closeButton: "button",
        //   icon: true
        // });
      } catch (error) {
        console.log(error);

        this.$toast.error("Sucedió un error al cargar la información. Intente recargar la página.", {
          timeout: 4000, //false,
          hideProgressBar: false,
          position: "top-right",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          closeButton: "button",
          icon: true
        });
      } finally {
        this.loading = false;
      }
    },

    async payCotizacionSpei(payed) {
      if (payed === 'invalid amount') {
        this.$toast.error("Monto no válido.", {
          timeout: 4000,
          hideProgressBar: false,
          position: "top-right",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          closeButton: "button",
          icon: true
        });

        this.loading = false;
        return;
      }

      if (payed == true) {
        // this.constitutiva.disabled = true;
        // this.$refs["pago-modal"].hide();
        this.$toast.warning("Recibirá la información para la transferencia en su correo electrónico", {
          timeout: 10000, //false,
          hideProgressBar: false,
          position: "top-right",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          closeButton: "button",
          icon: true
        });
      } else {
        this.$toast.error("Sucedió un error, intente más tarde", {
          timeout: 4000, //false,
          hideProgressBar: false,
          position: "top-right",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          closeButton: "button",
          icon: true
        });
      }
      this.loading = false;
    }
  }
};
</script>

<style scoped>
.saldo {
  text-align: center;
}

.titulo {
  /* text-align: center; */
  color: #e87b31;
}

/* .modalPay {
  height: 530px !important;
  width: 830px !important;
} */
</style>
