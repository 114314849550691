var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c("div", { staticClass: "container" }, [
        _vm.loading
          ? _c("div", [
              _c(
                "div",
                { attrs: { id: "loader" } },
                [
                  _c("font-awesome-icon", {
                    attrs: { icon: "spinner", spin: "" },
                  }),
                ],
                1
              ),
            ])
          : _vm._e(),
      ]),
      _c("h4", { staticClass: "cardD" }, [_vm._v("Cotización y pago")]),
      _c("card", { staticClass: "blue" }, [
        _c("i", { staticClass: "fa fa-info" }),
        _vm._v(" Aquí van instrucciones. "),
        _c("p"),
        _c("i", { staticClass: "fa fa-play" }),
        _vm._v(" Links a videos. "),
        _c("p"),
        _c("i", { staticClass: "fa fa-question" }),
        _vm._v(" Datos de contacta a un asesor. "),
      ]),
      _c("h4", { staticClass: "cardD" }, [
        _vm._v("Resumen de Formalización de Acta"),
      ]),
      _vm._m(0),
      _c("div", { staticClass: "row mt-2" }, [
        _c("label", { staticClass: "grey col-md-6" }, [_vm._v(" Subtotal ")]),
        _c("label", { staticClass: "grey col-md-6" }, [
          _vm._v("$" + _vm._s(_vm.subtotal_resumen)),
        ]),
      ]),
      _c("h4", { staticClass: "cardD mt-1" }, [_vm._v("Adicionales")]),
      _vm.formalizacion.modifica_capital
        ? _c("div", [
            _vm.formalizacion.monto_modificado <= 5000000
              ? _c("div", { staticClass: "row" }, [
                  _c("label", { staticClass: "grey col-md-1" }, [
                    _vm._v(_vm._s(_vm.icon_aumento_disminucion_capital_5mdp)),
                  ]),
                  _c("label", { staticClass: "grey col-md-5" }, [
                    _vm._v(
                      " Aumento o disminución de capital fijo o variable hasta 5MDP"
                    ),
                  ]),
                  _c("label", { staticClass: "grey col-md-6" }, [
                    _vm._v(
                      "$" + _vm._s(_vm.precio_aumento_disminucion_capital_5mdp)
                    ),
                  ]),
                ])
              : _vm.formalizacion.monto_modificado > 5000000
              ? _c("div", [
                  _vm.formalizacion.capital_modificado == "Fijo"
                    ? _c("div", { staticClass: "row" }, [
                        _c("label", { staticClass: "grey col-md-1" }, [
                          _vm._v(
                            _vm._s(
                              _vm.icon_aumento_disminucion_capital_fijo_20mdp
                            )
                          ),
                        ]),
                        _c("label", { staticClass: "grey col-md-5" }, [
                          _vm._v(
                            " Aumento o disminución de capital fijo hasta 200MDP"
                          ),
                        ]),
                        _c("label", { staticClass: "grey col-md-6" }, [
                          _vm._v(
                            "$" +
                              _vm._s(
                                _vm.precio_aumento_disminucion_capital_fijo_20mdp
                              )
                          ),
                        ]),
                      ])
                    : _vm.formalizacion.capital_modificado == "Variable"
                    ? _c("div", { staticClass: "row" }, [
                        _c("label", { staticClass: "grey col-md-1" }, [
                          _vm._v(
                            _vm._s(
                              _vm.icon_aumento_disminucion_capital_variable_20mdp
                            )
                          ),
                        ]),
                        _c("label", { staticClass: "grey col-md-5" }, [
                          _vm._v(
                            " Aumento o disminución de capital variable hasta 200MDP "
                          ),
                        ]),
                        _c("label", { staticClass: "grey col-md-6" }, [
                          _vm._v(
                            "$" +
                              _vm._s(
                                _vm.precio_aumento_disminucion_capital_variable_20mdp
                              )
                          ),
                        ]),
                      ])
                    : _vm._e(),
                ])
              : _vm._e(),
          ])
        : _vm._e(),
      _vm.formalizacion.nuevos_accionistas
        ? _c("div", { staticClass: "row" }, [
            _c("label", { staticClass: "grey col-md-1" }, [
              _vm._v(_vm._s(_vm.icon_ingreso_accionistas)),
            ]),
            _c("label", { staticClass: "grey col-md-5" }, [
              _vm._v("Ingreso de accionistas "),
            ]),
            _c("label", { staticClass: "grey col-md-6" }, [
              _vm._v("$" + _vm._s(_vm.precio_ingreso_accionistas)),
            ]),
          ])
        : _vm._e(),
      _vm.formalizacion.capital_extranjero
        ? _c("div", { staticClass: "row" }, [
            _c("label", { staticClass: "grey col-md-1" }, [
              _vm._v(_vm._s(_vm.icon_ingreso_capital_extranjero)),
            ]),
            _c("label", { staticClass: "grey col-md-5" }, [
              _vm._v(" Ingreso de capital extranjero"),
            ]),
            _c("label", { staticClass: "grey col-md-6" }, [
              _vm._v("$" + _vm._s(_vm.precio_ingreso_capital_extranjero)),
            ]),
          ])
        : _vm._e(),
      _vm.formalizacion.modifica_estatutos
        ? _c("div", { staticClass: "row" }, [
            _c("label", { staticClass: "grey col-md-1" }, [
              _vm._v(_vm._s(_vm.icon_modifica_estatutos)),
            ]),
            _c("label", { staticClass: "grey col-md-5" }, [
              _vm._v(" Modificación de estatutos"),
            ]),
            _c("label", { staticClass: "grey col-md-6" }, [
              _vm._v("$" + _vm._s(_vm.precio_modifica_estatutos)),
            ]),
          ])
        : _vm._e(),
      _vm.formalizacion.modifica_domicilio_social
        ? _c("div", { staticClass: "row" }, [
            _c("label", { staticClass: "grey col-md-1" }, [
              _vm._v(_vm._s(_vm.icon_cambio_domicilio)),
            ]),
            _c("label", { staticClass: "grey col-md-5" }, [
              _vm._v(" Cambio de domicilio de la sociedad"),
            ]),
            _c("label", { staticClass: "grey col-md-6" }, [
              _vm._v("$" + _vm._s(_vm.precio_cambio_domicilio)),
            ]),
          ])
        : _vm._e(),
      _vm.formalizacion.modifica_denominacion_social
        ? _c("div", { staticClass: "row" }, [
            _c("label", { staticClass: "grey col-md-1" }, [
              _vm._v(_vm._s(_vm.icon_cambio_denominacion)),
            ]),
            _c("label", { staticClass: "grey col-md-5" }, [
              _vm._v(" Cambio de la denominación social"),
            ]),
            _c("label", { staticClass: "grey col-md-6" }, [
              _vm._v("$" + _vm._s(_vm.precio_cambio_denominacion)),
            ]),
          ])
        : _vm._e(),
      _vm.formalizacion.modifica_duracion_sociedad
        ? _c("div", { staticClass: "row" }, [
            _c("label", { staticClass: "grey col-md-1" }, [
              _vm._v(_vm._s(_vm.icon_cambio_duracion)),
            ]),
            _c("label", { staticClass: "grey col-md-5" }, [
              _vm._v(" Cambio de la duración de la sociedad"),
            ]),
            _c("label", { staticClass: "grey col-md-6" }, [
              _vm._v("$" + _vm._s(_vm.precio_cambio_duracion)),
            ]),
          ])
        : _vm._e(),
      _vm.formalizacion.modifica_objeto_social
        ? _c("div", { staticClass: "row" }, [
            _c("label", { staticClass: "grey col-md-1" }, [
              _vm._v(_vm._s(_vm.icon_cambio_objeto_social)),
            ]),
            _c("label", { staticClass: "grey col-md-5" }, [
              _vm._v(" Cambio del objeto social"),
            ]),
            _c("label", { staticClass: "grey col-md-6" }, [
              _vm._v("$" + _vm._s(_vm.precio_cambio_objeto_social)),
            ]),
          ])
        : _vm._e(),
      _vm.formalizacion.reforma_total
        ? _c("div", { staticClass: "row" }, [
            _c("label", { staticClass: "grey col-md-1" }, [
              _vm._v(_vm._s(_vm.icon_reforma_total_estatutos)),
            ]),
            _c("label", { staticClass: "grey col-md-5" }, [
              _vm._v(" Reforma total de estatutos"),
            ]),
            _c("label", { staticClass: "grey col-md-6" }, [
              _vm._v("$" + _vm._s(_vm.precio_reforma_total_estatutos)),
            ]),
          ])
        : _vm._e(),
      _vm.formalizacion.disolucion_sociedad
        ? _c("div", { staticClass: "row" }, [
            _c("label", { staticClass: "grey col-md-1" }, [
              _vm._v(_vm._s(_vm.icon_disolucion)),
            ]),
            _c("label", { staticClass: "grey col-md-5" }, [
              _vm._v(" Disolución de la sociedad"),
            ]),
            _c("label", { staticClass: "grey col-md-6" }, [
              _vm._v("$" + _vm._s(_vm.precio_disolucion)),
            ]),
          ])
        : _vm._e(),
      _c("div", { staticClass: "row" }, [
        _c("label", { staticClass: "grey col-md-1" }, [
          _vm._v(_vm._s(_vm.formalizacion.num_funcionarios_revocados)),
        ]),
        _c("label", { staticClass: "grey col-md-5" }, [
          _vm._v(" Revocación de poderes"),
        ]),
        _c("label", { staticClass: "grey col-md-6" }, [
          _vm._v("$" + _vm._s(_vm.costos_revocaciones_poder)),
        ]),
      ]),
      _vm.formalizacion.modifica_facultades_funcionarios
        ? _c("div", { staticClass: "row" }, [
            _c("label", { staticClass: "grey col-md-5" }, [
              _vm._v(" Nombramientos adicionales"),
            ]),
            _c("label", { staticClass: "grey col-md-6" }, [
              _vm._v("$" + _vm._s(this.costos_modificacion_facultades)),
            ]),
          ])
        : _vm._e(),
      _c("div", { staticClass: "row mt-2" }, [
        _c("label", { staticClass: "grey col-md-6" }, [_vm._v(" Subtotal ")]),
        _c("label", { staticClass: "grey col-md-6" }, [
          _vm._v("$" + _vm._s(_vm.subtotal_adicionales)),
        ]),
      ]),
      _c("h4", { staticClass: "cardD mt-1" }, [_vm._v("Extras")]),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-md-1" },
          [
            _c("b-form-checkbox", {
              attrs: { disabled: _vm.formalizacion.disabledCotizacion },
              on: { change: _vm.setEntregableAdicional },
              model: {
                value: _vm.entregablesAdicionales,
                callback: function ($$v) {
                  _vm.entregablesAdicionales = $$v
                },
                expression: "entregablesAdicionales",
              },
            }),
          ],
          1
        ),
        _vm._m(1),
        _c("div", { staticClass: "col-md-6" }, [
          _c("label", { staticClass: "grey" }, [
            _vm._v(
              "$" + _vm._s(_vm.formalizacion.subtotal_entregable_adicional)
            ),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-md-1" },
          [
            _c("b-form-checkbox", {
              attrs: { disabled: _vm.formalizacion.disabledCotizacion },
              on: { change: _vm.setFirmaOficina },
              model: {
                value: _vm.firmaFueraOficina,
                callback: function ($$v) {
                  _vm.firmaFueraOficina = $$v
                },
                expression: "firmaFueraOficina",
              },
            }),
          ],
          1
        ),
        _vm._m(2),
        _c("div", { staticClass: "col-md-6" }, [
          _c("label", { staticClass: "grey" }, [
            _vm._v(
              "$" + _vm._s(_vm.formalizacion.subtotal_firma_fuera_oficina)
            ),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "row mt-2" }, [
        _vm._m(3),
        _c("div", { staticClass: "col-md-6" }, [
          _c("label", { staticClass: "grey" }, [
            _vm._v("$" + _vm._s(_vm.subtotal_extras)),
          ]),
        ]),
      ]),
      _c("h4", { staticClass: "cardD mt-1" }, [_vm._v("Gastos")]),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-md-1" },
          [
            _c("b-form-checkbox", {
              attrs: { disabled: "" },
              model: {
                value: _vm.gastos_envio,
                callback: function ($$v) {
                  _vm.gastos_envio = $$v
                },
                expression: "gastos_envio",
              },
            }),
          ],
          1
        ),
        _vm._m(4),
        _vm._m(5),
      ]),
      _c("div", { staticClass: "row mt-2" }, [
        _vm._m(6),
        _c("div", { staticClass: "col-md-6" }, [
          _c("label", { staticClass: "grey" }, [
            _vm._v("$" + _vm._s(_vm.subtotal_gastos)),
          ]),
        ]),
      ]),
      _c("h4", { staticClass: "cardD mt-1" }, [_vm._v("Cupones")]),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-12" },
          [
            _c("base-input", {
              attrs: {
                type: "text",
                label: "Si cuenta con un cupón puede aplicarlo.",
                placeholder: "Cupón de descuento",
                disabled: _vm.cuponAplicado,
              },
              model: {
                value: _vm.nombreCuponDescuento,
                callback: function ($$v) {
                  _vm.nombreCuponDescuento = $$v
                },
                expression: "nombreCuponDescuento",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-12 d-flex" },
          [
            _c("br"),
            _c(
              "b-button",
              {
                staticClass: "orange-btn",
                attrs: { size: "m", disabled: _vm.cuponAplicado },
                on: {
                  click: function ($event) {
                    return _vm.validarCupon(_vm.nombreCuponDescuento)
                  },
                },
              },
              [_vm._v("Aplicar")]
            ),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "row mt-1" }, [
        _vm._m(7),
        _c("div", { staticClass: "col-md-6" }, [
          _c("h4", { staticClass: "cardD" }, [
            _vm._v("$" + _vm._s(_vm.formalizacion.precio_total)),
          ]),
        ]),
      ]),
      _c("label", { staticClass: "mb-12 leyenda" }, [
        _vm._v(
          " Esta es una plataforma automática, no sustituye a una asesoría legal. "
        ),
      ]),
      _c("div", { staticClass: "mt-4" }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-10" },
            [
              _c(
                "b-button",
                {
                  staticClass: "btn-fill orange-btn bottom2",
                  attrs: {
                    type: "submit",
                    size: "m",
                    disabled: _vm.formalizacion.disabledCotizacion,
                  },
                },
                [_vm._v("Guardar")]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-2" },
            [
              _c(
                "b-button",
                {
                  staticClass: "btn-fill bottom2",
                  attrs: {
                    variant: "success",
                    size: "m",
                    disabled: _vm.formalizacion.disabledCotizacion,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.validar()
                    },
                  },
                },
                [_vm._v("Validar")]
              ),
            ],
            1
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row" }, [
      _c("label", { staticClass: "grey col-md-1" }, [_vm._v("✓")]),
      _c("label", { staticClass: "grey col-md-5" }, [_vm._v(" Monto base")]),
      _c("label", { staticClass: "grey col-md-6" }, [_vm._v("$7000")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-md-5" }, [
      _c("label", { staticClass: "grey" }, [_vm._v("Entregable adicional")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-md-5" }, [
      _c("label", { staticClass: "grey" }, [_vm._v("Firma fuera de oficina")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-md-6" }, [
      _c("label", { staticClass: "grey" }, [_vm._v("Subtotal")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-md-5" }, [
      _c("label", { staticClass: "grey" }, [_vm._v("Gastos de envío")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-md-6" }, [
      _c("label", { staticClass: "grey" }, [_vm._v("$0")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-md-6" }, [
      _c("label", { staticClass: "grey" }, [_vm._v("Subtotal")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-md-6" }, [
      _c("h4", { staticClass: "cardD" }, [_vm._v("Total")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }