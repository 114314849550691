<template>
  <div>
    <div class="col-md-12">
      <ValidationProvider name="documento" rules="required" v-slot="{ errors }"
        :custom-messages="{ required: 'El documento es obligatorio' }">
        <b-form-group label="Documento:">
          <b-form-file v-model="currentDocumento.file" browse-text="Seleccionar" drop-placeholder="Cargar aquí"
            placeholder="Da click aquí o arrastra el archivo" :accept="acceptedFileTypes" @input="onInput" />
          <span class="cardErrors">{{ errors[0] }}</span>
        </b-form-group>
      </ValidationProvider>
    </div>

    <div class="mt-4 text-center">
      <button type="submit" class="btn btn-fill float-right orange-btn" :disabled="!currentDocumento.file">
        Guardar
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    currentDocumento: { type: Object },
    notificacion: { type: Object },
    acceptedFileTypes: { type: String, default: '.pdf' },
  },

  methods: {
    onInput(input) {
      if (this.acceptedFileTypes === '.pdf' && !String(input.name).endsWith('.pdf')) {
        this.$toast.error('El archivo debe ser de tipo PDF.', {
          timeout: 4000,
          hideProgressBar: false,
          position: "top-right",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          closeButton: "button",
          icon: true
        });

        this.currentDocumento.file = null;

        return;
      }

      const types = Array.from(this.acceptedFileTypes.split(','));
      const type = types.find(t => String(input.name).endsWith(t));

      if (!type) {
        this.$toast.error('El tipo de archivo no es válido.', {
          timeout: 4000,
          hideProgressBar: false,
          position: "top-right",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          closeButton: "button",
          icon: true
        });

        this.currentDocumento.file = null;
      }
    },
  },
}
</script>

<style scoped>
.control-label {
  position: absolute;
  display: inline-block;
  margin-left: 30px;
}

.custom-number {
  width: 100px !important;
  display: inline-block;
  margin-left: 30px;
}
</style>
