var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c(
        "b-modal",
        {
          ref: "documento-modal",
          attrs: {
            "no-close-on-backdrop": "",
            centered: "",
            title: "",
            "hide-footer": "",
            id: "modalC",
            size: "l",
          },
        },
        [
          _c("ValidationObserver", {
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ handleSubmit }) {
                  return [
                    _c(
                      "form",
                      {
                        on: {
                          submit: function ($event) {
                            $event.preventDefault()
                            return handleSubmit(_vm.putDocumento)
                          },
                        },
                      },
                      [
                        _c("ModalDocumento", {
                          attrs: {
                            currentDocumento: _vm.currentDocumento,
                            optionsDocumentos: _vm.optionsDocumentos,
                          },
                          on: {
                            submit: function ($event) {
                              $event.preventDefault()
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          ref: "modal-confirm-dialog",
          attrs: {
            "no-close-on-backdrop": "",
            centered: "",
            "hide-footer": "",
            id: "modal-confirm-dialog",
          },
        },
        [
          _c("h4", { staticClass: "text-center" }, [
            _vm._v("¿Seguro que quiere eliminar el documento?"),
          ]),
          _c("h5", { staticClass: "text-center" }),
          _c(
            "b-button",
            {
              staticClass: "mt-3",
              attrs: { variant: "danger" },
              on: {
                click: function ($event) {
                  return _vm.$bvModal.hide("modal-confirm-dialog")
                },
              },
            },
            [_vm._v("Cancelar")]
          ),
          _c(
            "b-button",
            {
              staticClass: "mt-3 float-right",
              attrs: { variant: "primary" },
              on: { click: _vm.deleteDocumento },
            },
            [_vm._v("OK")]
          ),
        ],
        1
      ),
      _c("h4", { staticClass: "cardD" }, [
        _vm._v("Información de los Documentos"),
      ]),
      _c(
        "div",
        { staticClass: "mt-4" },
        [
          _c("label", { attrs: { for: "" } }, [_vm._v("Agregar documento")]),
          _c(
            "b-button",
            {
              staticClass: "btn-fill spaceL",
              attrs: { variant: "success", size: "sm" },
              on: { click: _vm.addDocumento },
            },
            [_vm._v("+")]
          ),
        ],
        1
      ),
      _c(
        "div",
        [
          _c("b-table", {
            ref: "table",
            attrs: {
              hover: "",
              fields: _vm.fieldsCuadro,
              items: _vm.cotejo.documentos,
            },
            scopedSlots: _vm._u([
              {
                key: "cell(acciones)",
                fn: function ({ item }) {
                  return [
                    _c(
                      "b-button",
                      {
                        directives: [
                          {
                            name: "b-tooltip",
                            rawName: "v-b-tooltip.hover",
                            modifiers: { hover: true },
                          },
                        ],
                        staticClass: "editBtn",
                        attrs: {
                          variant: "warning",
                          size: "sm",
                          title: "Editar",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.editDocumento(item)
                          },
                        },
                      },
                      [_c("b-icon-pencil")],
                      1
                    ),
                    _c(
                      "b-button",
                      {
                        directives: [
                          {
                            name: "b-tooltip",
                            rawName: "v-b-tooltip.hover",
                            modifiers: { hover: true },
                          },
                        ],
                        attrs: {
                          variant: "danger",
                          size: "sm",
                          title: "Eliminar",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.predeleteDocumento(item.id)
                          },
                        },
                      },
                      [_c("b-icon-trash")],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-md-12" }, [
          _c(
            "div",
            { staticStyle: { display: "flex", "align-items": "center" } },
            [
              _c(
                "b-form-checkbox",
                {
                  attrs: { disabled: _vm.urgenciaNoAplica },
                  model: {
                    value: _vm.urgencia,
                    callback: function ($$v) {
                      _vm.urgencia = $$v
                    },
                    expression: "urgencia",
                  },
                },
                [_vm._v(" Los documentos se requieren de urgencia ")]
              ),
            ],
            1
          ),
          _vm.urgencia
            ? _c("p", { staticClass: "cardErrors" }, [
                _vm._v(
                  " Se generarán cargos adicionales y no se pueden garantizar los tiempos de entrega. "
                ),
              ])
            : _vm._e(),
        ]),
      ]),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-md-12" }, [
          _c(
            "div",
            { staticStyle: { display: "flex", "align-items": "center" } },
            [
              _c(
                "b-form-checkbox",
                {
                  attrs: { disabled: _vm.urgencia },
                  model: {
                    value: _vm.envioDomicilio,
                    callback: function ($$v) {
                      _vm.envioDomicilio = $$v
                    },
                    expression: "envioDomicilio",
                  },
                },
                [_vm._v(" Envío a domicilio ")]
              ),
            ],
            1
          ),
        ]),
      ]),
      _c("div", { staticClass: "mt-4" }, [
        _c(
          "div",
          {},
          [
            _c(
              "b-button",
              {
                staticClass: "btn-fill orange-btn bottom2",
                attrs: { size: "m" },
                on: {
                  click: function ($event) {
                    return _vm.checkDocumentos()
                  },
                },
              },
              [_vm._v("Continuar")]
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }