var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container" },
    [
      !_vm.alreadyInvoiced
        ? _c("div", [
            _vm._m(0),
            _c("div", { staticClass: "row mt-3" }, [
              _c(
                "div",
                { staticClass: "col-md-4" },
                [
                  _c("ValidationProvider", {
                    attrs: {
                      rules: "required",
                      "custom-messages": { required: "Seleccione una opcion" },
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function ({ errors }) {
                            return [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    id: "input-group-tipo-persona",
                                    label: "Tipo persona:",
                                    "label-for": "input-tipo-persona",
                                  },
                                },
                                [
                                  _c("b-form-select", {
                                    attrs: {
                                      id: "input-tipo-persona",
                                      options: _vm.tipoPersonaOptions,
                                    },
                                    model: {
                                      value: _vm.tipoPersonaSelected,
                                      callback: function ($$v) {
                                        _vm.tipoPersonaSelected = $$v
                                      },
                                      expression: "tipoPersonaSelected",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c("span", { staticClass: "cardErrors" }, [
                                _vm._v(_vm._s(errors[0])),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      4244691826
                    ),
                  }),
                ],
                1
              ),
            ]),
            _vm.tipoPersonaSelected
              ? _c("div", [
                  _c("div", { staticClass: "row mt-3" }, [
                    _c(
                      "div",
                      { staticClass: "col-md-4" },
                      [
                        _c("ValidationProvider", {
                          attrs: {
                            name: "rfc",
                            rules: {
                              required: true,
                              regex:
                                /^[A-Z&Ñ]{3,4}[0-9]{2}(0[1-9]|1[012])(0[1-9]|[12][0-9]|3[01])[A-Z0-9]{2}[0-9A]$/,
                            },
                            "custom-messages": {
                              required: "Campo obligatorio",
                              regex: "Formato incorrecto",
                            },
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function ({ errors }) {
                                  return [
                                    _c(
                                      "b-form-group",
                                      { attrs: { label: "RFC" } },
                                      [
                                        _c("b-form-input", {
                                          attrs: {
                                            type: "text",
                                            placeholder: "RFC",
                                            formatter:
                                              _vm.toUpperAndNormalizerFormatter,
                                          },
                                          model: {
                                            value: _vm.rfc,
                                            callback: function ($$v) {
                                              _vm.rfc = $$v
                                            },
                                            expression: "rfc",
                                          },
                                        }),
                                        _c(
                                          "span",
                                          { staticClass: "cardErrors" },
                                          [_vm._v(_vm._s(errors[0]))]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            302149865
                          ),
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-md-6" },
                      [
                        _c("ValidationProvider", {
                          attrs: {
                            name: "razonSocial",
                            rules: "required",
                            "custom-messages": {
                              required: "Campo obligatorio",
                            },
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function ({ errors }) {
                                  return [
                                    _c(
                                      "b-form-group",
                                      {
                                        attrs: {
                                          label: _vm.labelRazonSocialComputed,
                                        },
                                      },
                                      [
                                        _c("b-form-input", {
                                          attrs: {
                                            type: "text",
                                            placeholder:
                                              _vm.labelRazonSocialComputed,
                                            formatter:
                                              _vm.toUpperAndNormalizerFormatter,
                                          },
                                          model: {
                                            value: _vm.razonSocial,
                                            callback: function ($$v) {
                                              _vm.razonSocial = $$v
                                            },
                                            expression: "razonSocial",
                                          },
                                        }),
                                        _c(
                                          "span",
                                          { staticClass: "cardErrors" },
                                          [_vm._v(_vm._s(errors[0]))]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            460816621
                          ),
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "row mt-3" }, [
                    _c(
                      "div",
                      { staticClass: "col-md-5" },
                      [
                        _c("ValidationProvider", {
                          attrs: {
                            rules: "required",
                            "custom-messages": {
                              required: "Seleccione una opcion",
                            },
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function ({ errors }) {
                                  return [
                                    _c(
                                      "b-form-group",
                                      {
                                        attrs: {
                                          id: "input-group-regimen-fiscal",
                                          label: "Régimen fiscal:",
                                          "label-for": "input-regimen-fiscal",
                                        },
                                      },
                                      [
                                        _c("b-form-select", {
                                          attrs: {
                                            id: "input-regimen-fiscal",
                                            options:
                                              _vm.regimenesFiscalesOptions,
                                          },
                                          model: {
                                            value: _vm.regimenFiscal,
                                            callback: function ($$v) {
                                              _vm.regimenFiscal = $$v
                                            },
                                            expression: "regimenFiscal",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c("span", { staticClass: "cardErrors" }, [
                                      _vm._v(_vm._s(errors[0])),
                                    ]),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            933983671
                          ),
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-md-5" },
                      [
                        _c("ValidationProvider", {
                          attrs: {
                            rules: "required",
                            "custom-messages": {
                              required: "Seleccione una opcion",
                            },
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function ({ errors }) {
                                  return [
                                    _c(
                                      "b-form-group",
                                      {
                                        attrs: {
                                          id: "input-group-uso-cfdi",
                                          label: "Uso de CFDI:",
                                          "label-for": "input-uso-cfdi",
                                        },
                                      },
                                      [
                                        _c("b-form-select", {
                                          attrs: {
                                            id: "input-uso-cfdi",
                                            options: _vm.usoCFDIOptions,
                                          },
                                          model: {
                                            value: _vm.usoCFDI,
                                            callback: function ($$v) {
                                              _vm.usoCFDI = $$v
                                            },
                                            expression: "usoCFDI",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c("span", { staticClass: "cardErrors" }, [
                                      _vm._v(_vm._s(errors[0])),
                                    ]),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            2343224890
                          ),
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "row mt-3" }, [
                    _c(
                      "div",
                      { staticClass: "col-md-3" },
                      [
                        _c("ValidationProvider", {
                          attrs: {
                            name: "codigoPostal",
                            rules: { required: true, regex: /^\d{5}$/ },
                            "custom-messages": {
                              required: "Campo obligatorio",
                              regex: "El código postal es incorrecto",
                            },
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function ({ errors }) {
                                  return [
                                    _c(
                                      "b-form-group",
                                      { attrs: { label: "Código postal" } },
                                      [
                                        _c("b-form-input", {
                                          attrs: {
                                            type: "text",
                                            placeholder: "Código postal",
                                          },
                                          model: {
                                            value: _vm.codigoPostal,
                                            callback: function ($$v) {
                                              _vm.codigoPostal = $$v
                                            },
                                            expression: "codigoPostal",
                                          },
                                        }),
                                        _c(
                                          "span",
                                          { staticClass: "cardErrors" },
                                          [_vm._v(_vm._s(errors[0]))]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            2019559345
                          ),
                        }),
                      ],
                      1
                    ),
                  ]),
                ])
              : _vm._e(),
          ])
        : _c("div", [
            _vm._m(1),
            _c("div", { staticClass: "mt-3" }, [
              _c("p", [
                _vm._v("Folio Fiscal: "),
                _c("span", { staticClass: "text-monospace text-uppercase" }, [
                  _vm._v(_vm._s(_vm.folioFiscal)),
                ]),
              ]),
              _c(
                "div",
                { staticClass: "row justify-content-center" },
                [
                  _c(
                    "b-button",
                    {
                      directives: [
                        {
                          name: "b-tooltip",
                          rawName: "v-b-tooltip.hover",
                          modifiers: { hover: true },
                        },
                      ],
                      staticClass: "btn btn-fill orange-btn",
                      attrs: { size: "lg", title: "Descargar" },
                      on: {
                        click: function ($event) {
                          return _vm.downloadCFDI(_vm.urlFacturaPdf, "PDF")
                        },
                      },
                    },
                    [_c("b-icon-download"), _vm._v(" PDF ")],
                    1
                  ),
                  _c(
                    "b-button",
                    {
                      directives: [
                        {
                          name: "b-tooltip",
                          rawName: "v-b-tooltip.hover",
                          modifiers: { hover: true },
                        },
                      ],
                      staticClass: "btn btn-fill orange-btn",
                      attrs: { size: "lg", title: "Descargar" },
                      on: {
                        click: function ($event) {
                          return _vm.downloadCFDI(_vm.urlFacturaXml, "XML")
                        },
                      },
                    },
                    [_c("b-icon-download"), _vm._v(" XML ")],
                    1
                  ),
                ],
                1
              ),
            ]),
          ]),
      _c("div", { staticClass: "row mt-3" }),
      _c("div", { staticClass: "row mt-4 justify-content-end" }, [
        !_vm.alreadyInvoiced
          ? _c(
              "div",
              [
                _c(
                  "b-button",
                  {
                    staticClass: "btn btn-fill gray-btn",
                    attrs: { disabled: _vm.loading },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.cancelFacturacion()
                      },
                    },
                  },
                  [_vm._v(" Cancelar ")]
                ),
                _c(
                  "b-button",
                  {
                    staticClass: "btn btn-fill orange-btn",
                    attrs: { disabled: _vm.loading },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.submitFactura()
                      },
                    },
                  },
                  [_vm._v(" Facturar ")]
                ),
              ],
              1
            )
          : _c(
              "div",
              [
                _c(
                  "b-button",
                  {
                    staticClass: "btn btn-fill gray-btn",
                    attrs: { disabled: _vm.loading },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.closeFacturacion()
                      },
                    },
                  },
                  [_vm._v(" Cerrar ")]
                ),
              ],
              1
            ),
      ]),
      _c("b-overlay", { attrs: { show: _vm.loading, "no-wrap": "" } }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row" }, [
      _c("h5", { staticClass: "col-12", staticStyle: { color: "#ea640b" } }, [
        _vm._v("Facturación"),
      ]),
      _c("p", { staticClass: "col-12", staticStyle: { "font-size": "15px" } }, [
        _vm._v("Ingrese los datos fiscales para facturación"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row" }, [
      _c("h5", { staticClass: "col-12", staticStyle: { color: "#ea640b" } }, [
        _vm._v("Facturación"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }