var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container" },
    [
      _vm.loading
        ? _c("div", [
            _c(
              "div",
              { attrs: { id: "loader" } },
              [
                _c("font-awesome-icon", {
                  attrs: { icon: "spinner", spin: "" },
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
      _c(
        "b-modal",
        {
          ref: "compareciente-modal",
          attrs: {
            "no-close-on-backdrop": "",
            centered: "",
            title: "",
            "hide-footer": "",
            id: "modalC",
            size: "xl",
          },
        },
        [
          _c("ValidationObserver", {
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ handleSubmit }) {
                  return [
                    _c(
                      "form",
                      {
                        on: {
                          submit: function ($event) {
                            $event.preventDefault()
                            return handleSubmit(_vm.putCompareciente)
                          },
                        },
                      },
                      [
                        _c("ModalCompletaCompareciente", {
                          attrs: {
                            currentCompareciente: _vm.currentCompareciente,
                            solicitantes: _vm.notificacion.solicitantes,
                            notificacion: _vm.notificacion,
                          },
                          on: {
                            submit: function ($event) {
                              $event.preventDefault()
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("h4", { staticClass: "cardD" }, [
        _vm._v("Información de los Solicitantes"),
      ]),
      !_vm.datosCompletos
        ? _c("span", { staticClass: "warningData" }, [
            _vm._v("Favor de completar los datos de los solicitantes."),
          ])
        : _vm._e(),
      _c(
        "div",
        [
          _c("b-table", {
            ref: "table",
            attrs: {
              hover: "",
              fields: _vm.fieldsCuadro,
              items: _vm.notificacion.solicitantes,
            },
            scopedSlots: _vm._u([
              {
                key: "cell(acciones)",
                fn: function ({ item }) {
                  return [
                    _c(
                      "b-button",
                      {
                        directives: [
                          {
                            name: "b-tooltip",
                            rawName: "v-b-tooltip.hover",
                            modifiers: { hover: true },
                          },
                        ],
                        staticClass: "editBtn",
                        attrs: {
                          variant: "warning",
                          size: "sm",
                          title: "Editar",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.editCompareciente(item)
                          },
                        },
                      },
                      [_c("b-icon-pencil")],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      !_vm.notificacion.staff
        ? _c("div", { staticClass: "row mt-4" }, [
            _c(
              "div",
              { staticClass: "col-md-12 d-flex" },
              [
                _c("ValidationProvider", {
                  attrs: {
                    name: "validacionDatos",
                    rules: { required: { allowFalse: false } },
                    "custom-messages": {
                      required: "La confirmación es obligatoria",
                    },
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function ({ errors }) {
                          return [
                            _c(
                              "b-form-checkbox",
                              {
                                staticClass: "orange",
                                attrs: { id: "checkbox-validacionDatos" },
                                on: { change: _vm.validate },
                                model: {
                                  value: _vm.validacionDatos,
                                  callback: function ($$v) {
                                    _vm.validacionDatos = $$v
                                  },
                                  expression: "validacionDatos",
                                },
                              },
                              [
                                _vm._v(
                                  " Acepto que la información proporcionada es correcta. "
                                ),
                              ]
                            ),
                            _c("span", { staticClass: "cardErrors" }, [
                              _vm._v(_vm._s(errors[0])),
                            ]),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    174822006
                  ),
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
      !_vm.notificacion.staff
        ? _c("div", { staticClass: "mt-4" }, [
            _c(
              "div",
              {},
              [
                _c(
                  "b-button",
                  {
                    staticClass: "btn-fill orange-btn bottom2",
                    attrs: {
                      type: "submit",
                      size: "m",
                      disabled:
                        !_vm.validacionDatos ||
                        (_vm.notificacion.id_estatus != 8 &&
                          !_vm.datosCompletos),
                    },
                  },
                  [_vm._v("Continuar")]
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm.notificacion.staff
        ? _c("div", { staticClass: "mt-4" }, [
            _c(
              "div",
              {},
              [
                _c(
                  "b-button",
                  {
                    staticClass: "btn-fill orange-btn bottom2",
                    attrs: {
                      type: "submit",
                      size: "m",
                      disabled: _vm.notificacion.validaSolicitantes == 1,
                    },
                  },
                  [_vm._v("Validar")]
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }