var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container", attrs: { id: "container-openpay" } },
    [
      _vm.loading
        ? _c("div", [
            _c(
              "div",
              { attrs: { id: "loader" } },
              [
                _c("font-awesome-icon", {
                  attrs: { icon: "spinner", spin: "" },
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
      _c("div", { staticClass: "bkng-tb-cntnt" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-12" }, [
            _c("h4", { staticClass: "cardD mt-3" }, [_vm._v("Saldo actual")]),
            _c("h3", { staticClass: "saldo" }, [
              _vm._v(_vm._s(_vm.formatedSaldo)),
            ]),
          ]),
        ]),
        _vm.suficiente
          ? _c("div", [
              _c("label", [
                _vm._v(
                  "Costo total del servicio " + _vm._s(_vm.formatedPrecioTotal)
                ),
              ]),
            ])
          : _vm._e(),
        _vm.suficiente
          ? _c(
              "div",
              [
                _c(
                  "b-button",
                  {
                    staticClass: "btn-fill orange-btn bottom2",
                    attrs: { size: "m" },
                    on: {
                      click: function ($event) {
                        return _vm.pagar()
                      },
                    },
                  },
                  [_vm._v("Pagar")]
                ),
              ],
              1
            )
          : _vm._e(),
        !_vm.suficiente
          ? _c("div", { staticClass: "mt-3" }, [
              _vm._v(
                " El saldo en el monedero no es suficiente para realizar esta compra por " +
                  _vm._s(_vm.formatedPrecioTotal) +
                  '. Por favor recargue el Monedero de Correduría Digital en el apartado de "Monedero" '
              ),
            ])
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }