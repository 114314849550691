var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm._m(0),
    _vm.datos.apoderado
      ? _c("table", { staticClass: "tabla" }, [
          _c("tr", { staticClass: "space", staticStyle: { height: "500px" } }),
          _c("tr", [
            _c("th", { staticClass: "section", attrs: { colspan: "10" } }, [
              _vm._v(" " + _vm._s(_vm.datos.compareciente_principal) + " "),
            ]),
          ]),
          _c("tr", { staticClass: "space", staticStyle: { height: "100px" } }),
          _vm._m(1),
          _c("tr", [
            _c("td", { staticClass: "space", staticStyle: { width: "20%" } }),
            _c(
              "td",
              {
                staticStyle: {
                  width: "60%",
                  "vertical-align": "top",
                  "font-size": "14px",
                  "text-align": "center",
                  "background-color": "white",
                },
              },
              [_vm._v(" Por: " + _vm._s(_vm.datos.apoderado) + " ")]
            ),
            _c("td", {
              staticClass: "space",
              staticStyle: { width: "20%" },
              attrs: { colspan: "2" },
            }),
          ]),
          _vm._m(2),
        ])
      : _c("table", { staticClass: "tabla" }, [
          _c("tr", { staticClass: "space", staticStyle: { height: "500px" } }),
          _vm._m(3),
          _c("tr", [
            _c("td", { staticClass: "space", staticStyle: { width: "20%" } }),
            _c(
              "td",
              {
                staticStyle: {
                  width: "60%",
                  "vertical-align": "top",
                  "font-size": "14px",
                  "text-align": "center",
                  "background-color": "#fff",
                },
              },
              [_vm._v(_vm._s(_vm.datos.compareciente_principal))]
            ),
            _c("td", {
              staticClass: "space",
              staticStyle: { width: "20%" },
              attrs: { colspan: "2" },
            }),
          ]),
        ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "table",
      { staticClass: "tabla", staticStyle: { "text-align": "justify" } },
      [
        _c("tr", [
          _c("td", [
            _c("p", [
              _vm._v(
                " Esta hoja de firmas de este documento es tan vinculante y legalmente efectiva como cualquier otra sección del mismo. Al firmar la hoja de firmas, las partes reconocen y aceptan que han leído y comprendido todos los términos y condiciones contenidos en este documento y sus anexos, y acuerdan estar legalmente obligados por ellos. "
              ),
            ]),
            _c("p", [
              _vm._v(
                " Por favor, revise cuidadosamente toda la documentación antes de firmar. Asegúrese de firmar en el espacio provisto al final de la hoja de firmas. Si representa a una persona moral, asegúrese de que su razón social y su posición dentro de la misma sea correcta, junto con el nombre legal completo de la misma. "
              ),
            ]),
          ]),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", { staticClass: "space", staticStyle: { width: "20%" } }),
      _c("td", {
        staticClass: "user",
        staticStyle: {
          width: "60%",
          "background-color": "white !important",
          "border-bottom": "solid 2px black",
        },
      }),
      _c("td", { staticClass: "space", staticStyle: { width: "20%" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", { staticClass: "space", staticStyle: { width: "20%" } }),
      _c(
        "td",
        {
          staticStyle: {
            width: "60%",
            "vertical-align": "top",
            "font-size": "14px",
            "text-align": "center",
            "background-color": "white",
          },
        },
        [_vm._v(" Cargo: Representante Legal ")]
      ),
      _c("td", {
        staticClass: "space",
        staticStyle: { width: "20%" },
        attrs: { colspan: "2" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", { staticClass: "space", staticStyle: { width: "20%" } }),
      _c("td", {
        staticClass: "user",
        staticStyle: {
          width: "60%",
          "background-color": "#fff!important",
          "border-bottom": "solid 2px #000",
        },
      }),
      _c("td", { staticClass: "space", staticStyle: { width: "20%" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }