var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-modal",
        {
          ref: "modal-confirm-eliminar-usuarios-secundarios",
          attrs: {
            "no-close-on-backdrop": "",
            centered: "",
            "hide-footer": "",
            "hide-header-close": "",
            id: "modal-confirm-eliminar-usuarios-secundarios",
          },
        },
        [
          _c("h4", { staticClass: "text-center" }, [
            _vm._v("¿Seguro que quiere eliminar los usuarios secundarios?"),
          ]),
          _c("h5", { staticClass: "text-center" }, [
            _vm._v(
              "Se eliminarán los datos de los usuarios secundarios al guardar los cambios."
            ),
          ]),
          _c(
            "b-button",
            {
              staticClass: "mt-3",
              attrs: { variant: "danger" },
              on: {
                click: function ($event) {
                  return _vm.mayDeleteUsuariosSecundarios(false)
                },
              },
            },
            [_vm._v("Cancelar")]
          ),
          _c(
            "b-button",
            {
              staticClass: "mt-3 float-right",
              attrs: { variant: "primary" },
              on: {
                click: function ($event) {
                  return _vm.mayDeleteUsuariosSecundarios(true)
                },
              },
            },
            [_vm._v("OK")]
          ),
        ],
        1
      ),
      _c("card", [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-md-4" },
            [
              _c("ValidationProvider", {
                attrs: {
                  name: "firstName",
                  rules: "required",
                  "custom-messages": { required: "El nombre es obligatorio" },
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ errors }) {
                      return [
                        _c("base-input", {
                          attrs: {
                            type: "text",
                            label: "Nombre(s)",
                            placeholder: "Nombre(s)",
                          },
                          model: {
                            value: _vm.user.firstName,
                            callback: function ($$v) {
                              _vm.$set(_vm.user, "firstName", $$v)
                            },
                            expression: "user.firstName",
                          },
                        }),
                        _c("span", { staticClass: "cardErrors" }, [
                          _vm._v(_vm._s(errors[0])),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-md-4" },
            [
              _c("ValidationProvider", {
                attrs: {
                  name: "lastNameP",
                  rules: "required",
                  "custom-messages": {
                    required: "El apellido paterno es obligatorio",
                  },
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ errors }) {
                      return [
                        _c("base-input", {
                          attrs: {
                            type: "text",
                            label: "Apellido Paterno",
                            placeholder: "Apellido Paterno",
                          },
                          model: {
                            value: _vm.user.lastNameP,
                            callback: function ($$v) {
                              _vm.$set(_vm.user, "lastNameP", $$v)
                            },
                            expression: "user.lastNameP",
                          },
                        }),
                        _c("span", { staticClass: "cardErrors" }, [
                          _vm._v(_vm._s(errors[0])),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-md-4" },
            [
              _c("base-input", {
                attrs: {
                  type: "text",
                  label: "Apellido Materno",
                  placeholder: "Apellido Materno",
                },
                model: {
                  value: _vm.user.lastNameM,
                  callback: function ($$v) {
                    _vm.$set(_vm.user, "lastNameM", $$v)
                  },
                  expression: "user.lastNameM",
                },
              }),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-md-6" },
            [
              _c("ValidationProvider", {
                attrs: {
                  name: "nombre_comercial",
                  rules: "required",
                  "custom-messages": {
                    required:
                      "El nombre comercial es obligatorio. Es su nombre completo si es persona Física.",
                  },
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ errors }) {
                      return [
                        _c("base-input", {
                          attrs: {
                            type: "text",
                            label: "Nombre comercial",
                            placeholder: "Nombre comercial",
                          },
                          model: {
                            value: _vm.user.nombre_comercial,
                            callback: function ($$v) {
                              _vm.$set(_vm.user, "nombre_comercial", $$v)
                            },
                            expression: "user.nombre_comercial",
                          },
                        }),
                        _c("span", { staticClass: "cardErrors" }, [
                          _vm._v(_vm._s(errors[0])),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-md-6" },
            [
              _c("ValidationProvider", {
                attrs: {
                  name: "email",
                  rules: "required|email",
                  "custom-messages": {
                    required: "El email es obligatorio",
                    email: "Debe ser un email válido",
                  },
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ errors }) {
                      return [
                        _c("base-input", {
                          attrs: {
                            type: "email",
                            label: "Email",
                            placeholder: "Email",
                          },
                          model: {
                            value: _vm.user.email,
                            callback: function ($$v) {
                              _vm.$set(_vm.user, "email", $$v)
                            },
                            expression: "user.email",
                          },
                        }),
                        _c("span", { staticClass: "cardErrors" }, [
                          _vm._v(_vm._s(errors[0])),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-md-4" },
            [
              _c("ValidationProvider", {
                attrs: {
                  name: "rol",
                  rules: "required",
                  "custom-messages": { required: "El rol es obligatorio" },
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ errors }) {
                      return [
                        _c("label", { attrs: { for: "" } }, [_vm._v("Rol")]),
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.user.rol,
                                expression: "user.rol",
                              },
                            ],
                            staticClass: "custom-select",
                            attrs: { disabled: "" },
                            on: {
                              change: function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  _vm.user,
                                  "rol",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              },
                            },
                          },
                          [
                            _c(
                              "option",
                              { attrs: { value: "Cliente Administrador" } },
                              [_vm._v(" Cliente Administrador ")]
                            ),
                            _c(
                              "option",
                              { attrs: { value: "Cliente Usuario" } },
                              [_vm._v("Cliente Usuario")]
                            ),
                          ]
                        ),
                        _c("span", { staticClass: "cardErrors" }, [
                          _vm._v(_vm._s(errors[0])),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-md-4" },
            [
              _c("ValidationProvider", {
                attrs: {
                  name: "rfc",
                  rules: "required|length:13",
                  "custom-messages": {
                    required: "El RFC es obligatorio",
                    length: "Debe tener 13 caracteres",
                  },
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ errors }) {
                      return [
                        _c("base-input", {
                          attrs: {
                            type: "text",
                            label: "RFC",
                            placeholder: "RFC",
                          },
                          model: {
                            value: _vm.user.rfc,
                            callback: function ($$v) {
                              _vm.$set(_vm.user, "rfc", $$v)
                            },
                            expression: "user.rfc",
                          },
                        }),
                        _c("span", { staticClass: "cardErrors" }, [
                          _vm._v(_vm._s(errors[0])),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          this.roleUsr === "Cliente Administrador"
            ? _c(
                "div",
                { staticClass: "col-md-4" },
                [
                  _c("ValidationProvider", {
                    attrs: {
                      name: "persona",
                      rules: "required",
                      "custom-messages": {
                        required: "La persona es obligatoria",
                      },
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function ({ errors }) {
                            return [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v("Agregar usuarios secundarios"),
                              ]),
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.user.persona,
                                      expression: "user.persona",
                                    },
                                  ],
                                  staticClass: "custom-select",
                                  on: {
                                    change: function ($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call(
                                          $event.target.options,
                                          function (o) {
                                            return o.selected
                                          }
                                        )
                                        .map(function (o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.$set(
                                        _vm.user,
                                        "persona",
                                        $event.target.multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("option", { attrs: { value: "Física" } }, [
                                    _vm._v("No"),
                                  ]),
                                  _c("option", { attrs: { value: "Moral" } }, [
                                    _vm._v("Si"),
                                  ]),
                                ]
                              ),
                              _c("span", { staticClass: "cardErrors" }, [
                                _vm._v(_vm._s(errors[0])),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1612878320
                    ),
                  }),
                ],
                1
              )
            : _vm._e(),
        ]),
      ]),
      _c("card", [
        _vm.user.persona === "Moral"
          ? _c(
              "div",
              [
                _c("h4", { staticClass: "cardD" }, [
                  this.roleUsr === "Cliente"
                    ? _c("div", [
                        _vm.second_users.length < 3 &&
                        _vm.user.rol === "Cliente Administrador" &&
                        _vm.user.persona === "Moral"
                          ? _c(
                              "div",
                              [
                                _vm._v(" Datos de usuarios "),
                                _c(
                                  "b-button",
                                  {
                                    staticClass: "btn-fill",
                                    attrs: { variant: "success", size: "sm" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.showFormDialog("x")
                                      },
                                    },
                                  },
                                  [_vm._v("+")]
                                ),
                              ],
                              1
                            )
                          : _c("div", [_vm._v("Datos de usuarios")]),
                      ])
                    : this.roleUsr === "Staff"
                    ? _c(
                        "div",
                        [
                          _vm._v(" Datos de usuarios "),
                          _c(
                            "b-button",
                            {
                              staticClass: "btn-fill",
                              attrs: { variant: "success", size: "sm" },
                              on: {
                                click: function ($event) {
                                  return _vm.showFormDialog("x")
                                },
                              },
                            },
                            [_vm._v("+")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
                _c("span", { staticClass: "warningData" }, [
                  _vm._v(
                    "Los usuarios deberan aceptar la invitacion antes de aparecer en el listado"
                  ),
                ]),
                _c("b-table", {
                  attrs: {
                    fields: _vm.fieldsSecondUsers,
                    items: _vm.second_users,
                    hover: "",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "cell(index)",
                        fn: function ({ index }) {
                          return [
                            _vm._v(" " + _vm._s(_vm.getIndex(index + 1)) + " "),
                          ]
                        },
                      },
                      {
                        key: "cell(acciones)",
                        fn: function ({ item }) {
                          return [
                            _c(
                              "b-button",
                              {
                                directives: [
                                  {
                                    name: "b-tooltip",
                                    rawName: "v-b-tooltip.hover",
                                    modifiers: { hover: true },
                                  },
                                ],
                                staticClass: "editBtn",
                                attrs: {
                                  variant: "warning",
                                  size: "sm",
                                  title: "Editar",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.editUsuarioSecundario(item)
                                  },
                                },
                              },
                              [_c("b-icon-pencil")],
                              1
                            ),
                            _c(
                              "b-button",
                              {
                                directives: [
                                  {
                                    name: "b-tooltip",
                                    rawName: "v-b-tooltip.hover",
                                    modifiers: { hover: true },
                                  },
                                ],
                                attrs: {
                                  variant: "danger",
                                  size: "sm",
                                  title: "Eliminar",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.predeleteUsuarioSecundario(
                                      item.id
                                    )
                                  },
                                },
                              },
                              [_c("b-icon-trash")],
                              1
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    1932336004
                  ),
                }),
              ],
              1
            )
          : _vm._e(),
      ]),
      _c(
        "div",
        [
          _c(
            "b-button",
            {
              staticClass: "btn-fill orange-btn bottom",
              attrs: { type: "submit", size: "lg" },
            },
            [_vm._v("Guardar")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }