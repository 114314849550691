var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content" },
    [
      _c(
        "b-breadcrumb",
        [
          _c("b-breadcrumb-item", { attrs: { to: { path: "/dashboard" } } }, [
            _vm._v(" Inicio "),
          ]),
          _c("b-breadcrumb-item", { attrs: { active: "" } }, [
            _vm._v("Información Formalizacón de Actas"),
          ]),
        ],
        1
      ),
      _c(
        "router-link",
        {
          staticClass: "route btn-fill orange-btn bottom2",
          attrs: {
            to: { path: "/dashboard/procesos/addformalizacion/" },
            tag: "b-button",
          },
        },
        [_vm._v(" Iniciar proceso ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }