var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("ValidationObserver", {
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ invalid }) {
              return [
                _c(
                  "form",
                  {
                    on: {
                      submit: function ($event) {
                        $event.preventDefault()
                        return _vm.onSubmit.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col-md-12" },
                        [
                          _c("ValidationProvider", {
                            attrs: {
                              name: "persona",
                              rules: "required",
                              "custom-messages": {
                                required: "La persona es obligatoria",
                              },
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function ({ errors }) {
                                    return [
                                      _c(
                                        "b-form-group",
                                        {
                                          attrs: {
                                            id: "input-group-1",
                                            label: "Persona:",
                                            "label-for": "input-1",
                                          },
                                        },
                                        [
                                          _c("b-form-select", {
                                            staticClass: "custom-select",
                                            attrs: {
                                              id: "input-1",
                                              options: _vm.optionsPersona,
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "first",
                                                  fn: function () {
                                                    return [
                                                      _c(
                                                        "b-form-select-option",
                                                        {
                                                          attrs: {
                                                            value: null,
                                                            disabled: "",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "-- Seleccione una opción --"
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  },
                                                  proxy: true,
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                            model: {
                                              value: _vm.tipoPersona,
                                              callback: function ($$v) {
                                                _vm.tipoPersona = $$v
                                              },
                                              expression: "tipoPersona",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "span",
                                        { staticClass: "cardErrors" },
                                        [_vm._v(_vm._s(errors[0]))]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm.tipoPersona === "Física"
                      ? _c("div", [
                          _c("div", { staticClass: "row mt-4" }, [
                            _c(
                              "div",
                              { staticClass: "col-md-4" },
                              [
                                _c("ValidationProvider", {
                                  attrs: {
                                    name: "nombre",
                                    rules: "required",
                                    "custom-messages": {
                                      required: "El nombre es obligatorio",
                                    },
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function ({ errors }) {
                                          return [
                                            _c(
                                              "b-form-group",
                                              {
                                                attrs: {
                                                  id: "input-group-2",
                                                  label: "Nombre:",
                                                  "label-for": "input-2",
                                                },
                                              },
                                              [
                                                _c("b-form-input", {
                                                  attrs: {
                                                    id: "input-2",
                                                    type: "text",
                                                    placeholder: "Nombre",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm
                                                        .solicitantePersonaFisica
                                                        .nombre,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.solicitantePersonaFisica,
                                                        "nombre",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "solicitantePersonaFisica.nombre",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "span",
                                              { staticClass: "cardErrors" },
                                              [_vm._v(_vm._s(errors[0]))]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "col-md-4" },
                              [
                                _c("ValidationProvider", {
                                  attrs: {
                                    name: "nombre",
                                    rules: "required",
                                    "custom-messages": {
                                      required:
                                        "El apellido paterno es obligatorio",
                                    },
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function ({ errors }) {
                                          return [
                                            _c(
                                              "b-form-group",
                                              {
                                                attrs: {
                                                  id: "input-group-3",
                                                  label: "Apellido paterno:",
                                                  "label-for": "input-3",
                                                },
                                              },
                                              [
                                                _c("b-form-input", {
                                                  attrs: {
                                                    id: "input-3",
                                                    type: "text",
                                                    placeholder: "Paterno",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm
                                                        .solicitantePersonaFisica
                                                        .paterno,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.solicitantePersonaFisica,
                                                        "paterno",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "solicitantePersonaFisica.paterno",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "span",
                                              { staticClass: "cardErrors" },
                                              [_vm._v(_vm._s(errors[0]))]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "col-md-4" },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      id: "input-group-4",
                                      label: "Apellido materno:",
                                      "label-for": "input-4",
                                    },
                                  },
                                  [
                                    _c("b-form-input", {
                                      attrs: {
                                        id: "input-4",
                                        type: "text",
                                        placeholder: "Materno",
                                      },
                                      model: {
                                        value:
                                          _vm.solicitantePersonaFisica.materno,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.solicitantePersonaFisica,
                                            "materno",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "solicitantePersonaFisica.materno",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                          _c("div", { staticClass: "row mt-4" }, [
                            _c(
                              "div",
                              { staticClass: "col-md-4" },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      id: "input-group-actua-derecho-propio",
                                      label: "Actúa por derecho propio:",
                                      "label-for": "input-actua-derecho-propio",
                                    },
                                  },
                                  [
                                    _c("b-form-checkbox", {
                                      attrs: {
                                        id: "input-actua-derecho-propio",
                                      },
                                      model: {
                                        value: _vm.actuaDerechoPropio,
                                        callback: function ($$v) {
                                          _vm.actuaDerechoPropio = $$v
                                        },
                                        expression: "actuaDerechoPropio",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                        ])
                      : _vm.tipoPersona === "Moral"
                      ? _c("div", { staticClass: "mt-4" }, [
                          _c("div", { staticClass: "row" }, [
                            _c(
                              "div",
                              { staticClass: "col-md-12" },
                              [
                                _c("ValidationProvider", {
                                  attrs: {
                                    name: "denominacion_social",
                                    rules: "required",
                                    "custom-messages": {
                                      required:
                                        "La denominacion social es obligatoria",
                                    },
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function ({ errors }) {
                                          return [
                                            _c(
                                              "b-form-group",
                                              {
                                                attrs: {
                                                  id: "input-group-5",
                                                  label:
                                                    _vm.denominacionSocialLabel,
                                                  "label-for": "input-5",
                                                },
                                              },
                                              [
                                                _c("b-form-input", {
                                                  attrs: {
                                                    id: "input-5",
                                                    type: "text",
                                                    placeholder:
                                                      "Denominación social",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm
                                                        .solicitantePersonaMoral
                                                        .denominacion_social,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.solicitantePersonaMoral,
                                                        "denominacion_social",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "solicitantePersonaMoral.denominacion_social",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "span",
                                              { staticClass: "cardErrors" },
                                              [_vm._v(_vm._s(errors[0]))]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                              ],
                              1
                            ),
                          ]),
                        ])
                      : _vm._e(),
                    _c("div", { staticClass: "row mt-4" }),
                    _c("div", { staticClass: "mt-4 text-center" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-fill float-right orange-btn",
                          attrs: { type: "submit", disabled: invalid },
                        },
                        [_vm._v(" Guardar ")]
                      ),
                    ]),
                  ]
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }