var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container" }, [
    _vm.loading
      ? _c("div", [
          _c(
            "div",
            { attrs: { id: "loader" } },
            [_c("font-awesome-icon", { attrs: { icon: "spinner", spin: "" } })],
            1
          ),
        ])
      : _vm._e(),
    _c("h4", { staticClass: "cardD" }, [
      _vm._v(" Entidad Federativa en la que será firmada la Constitutiva "),
    ]),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-md-6" }, [
        _c("label", [_vm._v("Entidad Federativa")]),
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.constitutiva.selectedEntidad,
                expression: "constitutiva.selectedEntidad",
              },
            ],
            staticClass: "custom-select",
            attrs: { disabled: _vm.constitutiva.disabled },
            on: {
              change: [
                function ($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function (o) {
                      return o.selected
                    })
                    .map(function (o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.$set(
                    _vm.constitutiva,
                    "selectedEntidad",
                    $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                  )
                },
                _vm.getMunicipios,
              ],
            },
          },
          _vm._l(_vm.constitutiva.optionsEntidades, function (option) {
            return _c("option", { key: option.value }, [
              _vm._v(" " + _vm._s(option.text) + " "),
            ])
          }),
          0
        ),
      ]),
      _vm.hay_municipios
        ? _c("div", { staticClass: "col-md-6" }, [
            _c("label", [_vm._v("Municipio")]),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.constitutiva.selectedMunicipio,
                    expression: "constitutiva.selectedMunicipio",
                  },
                ],
                staticClass: "custom-select",
                attrs: { disabled: _vm.constitutiva.disabled },
                on: {
                  change: [
                    function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.constitutiva,
                        "selectedMunicipio",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    },
                    function ($event) {
                      return _vm.selectCorreduria($event)
                    },
                  ],
                },
              },
              _vm._l(_vm.constitutiva.optionsMunicipios, function (option) {
                return _c("option", { key: option.value }, [
                  _vm._v(" " + _vm._s(option.text) + " "),
                ])
              }),
              0
            ),
          ])
        : _vm._e(),
    ]),
    _c("div", { staticClass: "row mt-5" }, [
      _c(
        "div",
        { staticClass: "col-8" },
        [
          _c("label", { attrs: { for: "" } }, [
            _vm._v("La firma será en la oficina del Corredor "),
          ]),
          _c("b-form-checkbox", {
            attrs: { disabled: _vm.constitutiva.disabled },
            model: {
              value: _vm.constitutiva.firma_oficina,
              callback: function ($$v) {
                _vm.$set(_vm.constitutiva, "firma_oficina", $$v)
              },
              expression: "constitutiva.firma_oficina",
            },
          }),
          _vm.constitutiva.firma_oficina === false
            ? _c("label", { staticClass: "mb-12 leyenda" }, [
                _vm._v(
                  " Aplicará un costo extra para gastos de movilidad en caso de recabar firmas. "
                ),
              ])
            : _vm._e(),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "mt-4" }, [
      _c(
        "div",
        {},
        [
          _c(
            "b-button",
            {
              staticClass: "btn-fill orange-btn bottom2",
              attrs: {
                type: "submit",
                size: "m",
                disabled: _vm.constitutiva.disabled,
              },
            },
            [_vm._v("Continuar")]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }