var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c(
        "b-modal",
        {
          ref: "documento-modal-preview",
          attrs: {
            "no-close-on-backdrop": "",
            centered: "",
            title: "",
            "hide-footer": "",
            id: "modaldf",
            size: "xl",
          },
        },
        [
          _c("ModalPreviewPDF", {
            attrs: { currentDocumento: _vm.currentDocumento },
          }),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          ref: "modal-regenerate",
          attrs: {
            "no-close-on-backdrop": "",
            centered: "",
            "hide-footer": "",
            id: "modal-regenerate",
          },
        },
        [
          _c("h4", { staticClass: "text-center" }, [
            _vm._v(
              " ¿Está seguro de regenerar el Acta de Proyecto? En caso de haber una existente, será eliminada. "
            ),
          ]),
          _c("h5", { staticClass: "text-center" }),
          _c(
            "b-button",
            {
              staticClass: "mt-3",
              attrs: { variant: "danger" },
              on: {
                click: function ($event) {
                  return _vm.$bvModal.hide("modal-regenerate")
                },
              },
            },
            [_vm._v("Cancelar")]
          ),
          _c(
            "b-button",
            {
              staticClass: "mt-3 float-right",
              attrs: { variant: "primary" },
              on: {
                click: function ($event) {
                  return _vm.generaActaProyecto()
                },
              },
            },
            [_vm._v("OK")]
          ),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          ref: "modal-regenerate-firmas",
          attrs: {
            "no-close-on-backdrop": "",
            centered: "",
            "hide-footer": "",
            id: "modal-regenerate-firmas",
          },
        },
        [
          _c("h4", { staticClass: "text-center" }, [
            _vm._v(
              " ¿Está seguro de regenerar la Hoja de Firmas? En caso de haber una existente, será eliminada. "
            ),
          ]),
          _c("h5", { staticClass: "text-center" }),
          _c(
            "b-button",
            {
              staticClass: "mt-3",
              attrs: { variant: "danger" },
              on: {
                click: function ($event) {
                  return _vm.$bvModal.hide("modal-regenerate-firmas")
                },
              },
            },
            [_vm._v("Cancelar")]
          ),
          _c(
            "b-button",
            {
              staticClass: "mt-3 float-right",
              attrs: { variant: "primary" },
              on: {
                click: function ($event) {
                  return _vm.generaHojaFirmas((_vm.primeraVez = false))
                },
              },
            },
            [_vm._v("OK")]
          ),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          ref: "entrega-documento-modal",
          attrs: {
            "no-close-on-backdrop": "",
            centered: "",
            title: "",
            "hide-footer": "",
            id: "modalC",
            size: "xl",
          },
        },
        [
          _c(
            "div",
            [
              _c("ValidationObserver", {
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ handleSubmit }) {
                      return [
                        _c(
                          "form",
                          {
                            on: {
                              submit: function ($event) {
                                $event.preventDefault()
                                return handleSubmit(_vm.postDocumentoModal)
                              },
                            },
                          },
                          [
                            _c("ModalEntregaDocumento", {
                              attrs: {
                                currentDocumento: _vm.currentDocumento,
                                ratificacion: _vm.ratificacion,
                              },
                              on: {
                                submit: function ($event) {
                                  $event.preventDefault()
                                },
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ]
      ),
      _c(
        "b-modal",
        {
          ref: "documento-modal-preview-word",
          attrs: {
            "no-close-on-backdrop": "",
            centered: "",
            title: "",
            "hide-footer": "",
            id: "modaldd",
            size: "xl",
          },
        },
        [
          _c("ModalPreviewDocx", {
            attrs: { currentDocumento: _vm.currentDocumento },
          }),
        ],
        1
      ),
      _c("h4", { staticClass: "cardD" }, [_vm._v("Actas de Proyecto")]),
      _c("b-table", {
        ref: "table",
        attrs: { hover: "", fields: _vm.fieldsCuadro, items: _vm.Actas },
        scopedSlots: _vm._u([
          {
            key: "cell(acciones)",
            fn: function ({ item }) {
              return [
                _vm.isDocumentoActaGenerada(item) ||
                _vm.DocumentType(item) == "word"
                  ? _c(
                      "b-button",
                      {
                        directives: [
                          {
                            name: "b-tooltip",
                            rawName: "v-b-tooltip.hover",
                            modifiers: { hover: true },
                          },
                        ],
                        staticClass: "editBtn",
                        attrs: {
                          variant: "success",
                          size: "sm",
                          title: "Descarga documento",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.descargaFile(item.documento)
                          },
                        },
                      },
                      [_c("i", { staticClass: "fa fa-download" })]
                    )
                  : _vm._e(),
                !_vm.isDocumentoActaGenerada(item) &&
                _vm.DocumentType(item) != "word" &&
                item.documento
                  ? _c(
                      "b-button",
                      {
                        directives: [
                          {
                            name: "b-tooltip",
                            rawName: "v-b-tooltip.hover",
                            modifiers: { hover: true },
                          },
                        ],
                        staticClass: "editBtn",
                        attrs: {
                          variant: "primary",
                          size: "sm",
                          title: "Vista previa",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.openPreviewModal(item)
                          },
                        },
                      },
                      [_c("b-icon-eye")],
                      1
                    )
                  : _vm._e(),
                [42, 70, 71].includes(parseInt(_vm.ratificacion.estatus_id)) &&
                parseInt(item.cat_documentos_id) != 70
                  ? _c(
                      "b-button",
                      {
                        directives: [
                          {
                            name: "b-tooltip",
                            rawName: "v-b-tooltip.hover",
                            modifiers: { hover: true },
                          },
                        ],
                        staticClass: "editBtn",
                        attrs: {
                          variant: "warning",
                          size: "sm",
                          title: "Editar",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.openEditDocumentoModal(item)
                          },
                        },
                      },
                      [_c("b-icon-upload")],
                      1
                    )
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
      _c("div", { staticClass: "mt-4" }, [
        _c(
          "div",
          {},
          [
            _c(
              "b-button",
              {
                staticClass: "btn-fill green-btn bottom1",
                attrs: { size: "m" },
                on: {
                  click: function ($event) {
                    return _vm.openModalRegenerateFirmas()
                  },
                },
              },
              [_vm._v("Regenerar Hoja de Firmas")]
            ),
          ],
          1
        ),
        _c(
          "div",
          {},
          [
            _c(
              "b-button",
              {
                staticClass: "btn-fill green-btn bottom1",
                attrs: { size: "m" },
                on: {
                  click: function ($event) {
                    return _vm.openModalRegenerate()
                  },
                },
              },
              [_vm._v("Regenerar Acta de Proyecto")]
            ),
          ],
          1
        ),
        _c(
          "div",
          {},
          [
            _c(
              "b-button",
              {
                staticClass: "btn-fill orange-btn bottom2",
                attrs: {
                  type: "submit",
                  size: "m",
                  disabled:
                    !_vm.documentosCompletos ||
                    ![42, 70, 71].includes(
                      parseInt(_vm.ratificacion.estatus_id)
                    ),
                },
              },
              [_vm._v("Continuar")]
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { ref: "hojaFirmas", attrs: { hidden: "" } },
        [
          _c("HojaFirmasTemplate", {
            key: _vm.hojaKey,
            attrs: { ratificacion: _vm.ratificacion },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }