var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.user === "Staff"
      ? _c("div", [
          _c(
            "div",
            { staticClass: "row flex align-items-center p-2 pointer" },
            [
              _c("div", { staticClass: "ml-3" }, [
                _c(
                  "div",
                  {
                    class: [
                      "circle",
                      _vm.cotejo.validaSolicitantes == 1 ? "completado" : "",
                    ],
                  },
                  [
                    _c("span", { staticClass: "number" }, [
                      _vm.cotejo.validaSolicitantes == 1
                        ? _c("i", { staticClass: "fa fa-check" })
                        : _c("i", { staticClass: "fa fa-minus" }),
                    ]),
                  ]
                ),
              ]),
              _c("div", { staticClass: "ml-3" }, [
                _c(
                  "a",
                  {
                    class:
                      _vm.selectedComponent === "ValidaComparecientes"
                        ? "active-menu"
                        : "text-menu",
                    on: {
                      click: function ($event) {
                        return _vm.selected("ValidaComparecientes")
                      },
                    },
                  },
                  [_vm._v(" Solicitantes ")]
                ),
              ]),
            ]
          ),
          _c(
            "div",
            { staticClass: "row flex align-items-center p-2 pointer" },
            [
              _c("div", { staticClass: "ml-3" }, [
                _c(
                  "div",
                  {
                    class: [
                      "circle",
                      _vm.cotejo.validaDocumentos == 1 ? "completado" : "",
                    ],
                  },
                  [
                    _c("span", { staticClass: "number" }, [
                      _vm.cotejo.validaDocumentos == 1
                        ? _c("i", { staticClass: "fa fa-check" })
                        : _c("i", { staticClass: "fa fa-minus" }),
                    ]),
                  ]
                ),
              ]),
              _c("div", { staticClass: "ml-3" }, [
                _c(
                  "a",
                  {
                    class:
                      _vm.selectedComponent === "ValidaDocumentos"
                        ? "active-menu"
                        : "text-menu",
                    on: {
                      click: function ($event) {
                        return _vm.selected("ValidaDocumentos")
                      },
                    },
                  },
                  [_vm._v(" Documentos ")]
                ),
              ]),
            ]
          ),
          _vm.cotejo.envio_domicilio === 1
            ? _c(
                "div",
                { staticClass: "row flex align-items-center p-2 pointer" },
                [
                  _c("div", { staticClass: "ml-3" }, [
                    _c(
                      "div",
                      {
                        class: [
                          "circle",
                          _vm.cotejo.validaDomicilio == 1 ? "completado" : "",
                        ],
                      },
                      [
                        _c("span", { staticClass: "number" }, [
                          _vm.cotejo.validaDomicilio == 1
                            ? _c("i", { staticClass: "fa fa-check" })
                            : _c("i", { staticClass: "fa fa-minus" }),
                        ]),
                      ]
                    ),
                  ]),
                  _c("div", { staticClass: "ml-3" }, [
                    _c(
                      "a",
                      {
                        class:
                          _vm.selectedComponent === "Domicilio"
                            ? "active-menu"
                            : "text-menu",
                        on: {
                          click: function ($event) {
                            return _vm.selected("Domicilio")
                          },
                        },
                      },
                      [_vm._v(" Domicilio ")]
                    ),
                  ]),
                ]
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "row flex align-items-center p-2 pointer" },
            [
              _c("div", { staticClass: "ml-3" }, [
                _c(
                  "div",
                  {
                    class: [
                      "circle",
                      _vm.cotejo.corredurias_id ? "completado" : "",
                    ],
                  },
                  [
                    _c("span", { staticClass: "number" }, [
                      _vm.cotejo.corredurias_id
                        ? _c("i", { staticClass: "fa fa-check" })
                        : _c("i", { staticClass: "fa fa-minus" }),
                    ]),
                  ]
                ),
              ]),
              _c("div", { staticClass: "ml-3" }, [
                _c(
                  "a",
                  {
                    class:
                      _vm.selectedComponent === "Correduria"
                        ? "active-menu"
                        : "text-menu",
                    on: {
                      click: function ($event) {
                        return _vm.selected("Correduria")
                      },
                    },
                  },
                  [_vm._v(" Asignar Correduria ")]
                ),
              ]),
            ]
          ),
          _c(
            "div",
            { staticClass: "row flex align-items-center p-2 pointer" },
            [
              _c("div", { staticClass: "ml-3" }, [
                _c(
                  "div",
                  {
                    class: [
                      "circle",
                      _vm.cotejo.validaDocumentos == 1 ? "completado" : "",
                    ],
                  },
                  [
                    _c("span", { staticClass: "number" }, [
                      _vm.cotejo.validaDocumentos == 1
                        ? _c("i", { staticClass: "fa fa-check" })
                        : _c("i", { staticClass: "fa fa-minus" }),
                    ]),
                  ]
                ),
              ]),
              _c("div", { staticClass: "ml-3" }, [
                _c(
                  "a",
                  {
                    class:
                      _vm.selectedComponent === "EstatusDocumentos"
                        ? "active-menu"
                        : "text-menu",
                    on: {
                      click: function ($event) {
                        return _vm.selected("EstatusDocumentos")
                      },
                    },
                  },
                  [_vm._v(" Estatus Documentos ")]
                ),
              ]),
            ]
          ),
          _vm.cotejo.pago_complementario
            ? _c(
                "div",
                { staticClass: "row flex align-items-center p-2 pointer" },
                [
                  _c("div", { staticClass: "ml-3" }, [
                    _c(
                      "div",
                      {
                        class: [
                          "circle",
                          [19, 20, 21, 74, 75, 76].includes(
                            this.cotejo.id_estatus
                          ) || this.cotejo.cotizaciones_id_complementario
                            ? "completado"
                            : "",
                        ],
                      },
                      [
                        _c("span", { staticClass: "number" }, [
                          [19, 20, 21, 74, 75, 76].includes(
                            this.cotejo.id_estatus
                          ) || this.cotejo.cotizaciones_id_complementario
                            ? _c("i", { staticClass: "fa fa-check" })
                            : _c("i", { staticClass: "fa fa-minus" }),
                        ]),
                      ]
                    ),
                  ]),
                  _c("div", { staticClass: "ml-3" }, [
                    _c(
                      "a",
                      {
                        class:
                          _vm.selectedComponent === "PagoComplementario"
                            ? "active-menu"
                            : "text-menu",
                        on: {
                          click: function ($event) {
                            return _vm.selected("PagoComplementario")
                          },
                        },
                      },
                      [_vm._v(" Pago Complementario ")]
                    ),
                  ]),
                ]
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "row flex align-items-center p-2 pointer" },
            [
              _c("div", { staticClass: "ml-3" }, [
                _c(
                  "div",
                  {
                    class: [
                      "circle",
                      [19, 20, 21, 75, 76].includes(this.cotejo.id_estatus)
                        ? "completado"
                        : "",
                    ],
                  },
                  [
                    _c("span", { staticClass: "number" }, [
                      [19, 20, 21, 75, 76].includes(this.cotejo.id_estatus)
                        ? _c("i", { staticClass: "fa fa-check" })
                        : _c("i", { staticClass: "fa fa-minus" }),
                    ]),
                  ]
                ),
              ]),
              _c("div", { staticClass: "ml-3" }, [
                _c(
                  "a",
                  {
                    class:
                      _vm.selectedComponent === "FAD"
                        ? "active-menu"
                        : "text-menu",
                    on: {
                      click: function ($event) {
                        return _vm.selected("FAD")
                      },
                    },
                  },
                  [_vm._v(" FAD ")]
                ),
              ]),
            ]
          ),
          _c(
            "div",
            { staticClass: "row flex align-items-center p-2 pointer" },
            [
              _c("div", { staticClass: "ml-3" }, [
                _c(
                  "div",
                  {
                    class: [
                      "circle",
                      [20, 21].includes(this.cotejo.id_estatus)
                        ? "completado"
                        : "",
                    ],
                  },
                  [
                    _c("span", { staticClass: "number" }, [
                      [20, 21].includes(this.cotejo.id_estatus)
                        ? _c("i", { staticClass: "fa fa-check" })
                        : _c("i", { staticClass: "fa fa-minus" }),
                    ]),
                  ]
                ),
              ]),
              _c("div", { staticClass: "ml-3" }, [
                _c(
                  "a",
                  {
                    class:
                      _vm.selectedComponent === "Actas"
                        ? "active-menu"
                        : "text-menu",
                    on: {
                      click: function ($event) {
                        return _vm.selected("Actas")
                      },
                    },
                  },
                  [_vm._v(" Acuse ")]
                ),
              ]),
            ]
          ),
          _c(
            "div",
            { staticClass: "row flex align-items-center p-2 pointer" },
            [
              _c("div", { staticClass: "ml-3" }, [
                _c(
                  "div",
                  {
                    class: [
                      "circle",
                      [20, 21].includes(_vm.cotejo.id_estatus)
                        ? "completado"
                        : "",
                    ],
                  },
                  [
                    _c("span", { staticClass: "number" }, [
                      [20, 21].includes(_vm.cotejo.id_estatus)
                        ? _c("i", { staticClass: "fa fa-check" })
                        : _c("i", { staticClass: "fa fa-minus" }),
                    ]),
                  ]
                ),
              ]),
              _c("div", { staticClass: "ml-3" }, [
                _c(
                  "a",
                  {
                    class:
                      _vm.selectedComponent === "Expediente"
                        ? "active-menu"
                        : "text-menu",
                    on: {
                      click: function ($event) {
                        return _vm.selected("Expediente")
                      },
                    },
                  },
                  [_vm._v(" Expediente ")]
                ),
              ]),
            ]
          ),
          _c(
            "div",
            { staticClass: "row flex align-items-center p-2 pointer" },
            [
              _c("div", { staticClass: "ml-3" }, [
                _c(
                  "div",
                  {
                    class: [
                      "circle",
                      _vm.cotejo.id_estatus == 21 ? "completado" : "",
                    ],
                  },
                  [
                    _c("span", { staticClass: "number" }, [
                      _vm.cotejo.id_estatus == 21
                        ? _c("i", { staticClass: "fa fa-check" })
                        : _c("i", { staticClass: "fa fa-minus" }),
                    ]),
                  ]
                ),
              ]),
              _c("div", { staticClass: "ml-3" }, [
                _c(
                  "a",
                  {
                    class:
                      _vm.selectedComponent === "Terminado"
                        ? "active-menu"
                        : "text-menu",
                    on: {
                      click: function ($event) {
                        return _vm.selected("Terminado")
                      },
                    },
                  },
                  [_vm._v(" Terminado ")]
                ),
              ]),
            ]
          ),
        ])
      : _vm.user === "Client"
      ? _c("div", [
          _vm._m(0),
          _vm._m(1),
          _vm._m(2),
          _vm._m(3),
          _c(
            "div",
            { staticClass: "row flex align-items-center p-2 pointer" },
            [
              _c("div", { staticClass: "ml-3" }, [
                _c(
                  "div",
                  {
                    class: [
                      "circle",
                      _vm.cotejo.currentComponentValues.Pago
                        ? "completado"
                        : "",
                    ],
                  },
                  [
                    _c("span", { staticClass: "number" }, [
                      _vm.cotejo.currentComponentValues.Pago
                        ? _c("i", { staticClass: "fa fa-check" })
                        : _c("i", { staticClass: "fa fa-minus" }),
                    ]),
                  ]
                ),
              ]),
              _c("div", { staticClass: "ml-3" }, [
                _c(
                  "a",
                  {
                    class:
                      _vm.selectedComponent === "Pago"
                        ? "active-menu"
                        : "text-menu",
                    on: {
                      click: function ($event) {
                        return _vm.selected("Pago")
                      },
                    },
                  },
                  [_vm._v(" Pago ")]
                ),
              ]),
            ]
          ),
          _vm._m(4),
          _c(
            "div",
            { staticClass: "row flex align-items-center p-2 pointer" },
            [
              _c("div", { staticClass: "ml-3" }, [
                _c(
                  "div",
                  {
                    class: [
                      "circle",
                      _vm.cotejo.currentComponentValues.Solicitantes == 1
                        ? "completado"
                        : "",
                    ],
                  },
                  [
                    _c("span", { staticClass: "number" }, [
                      _vm.cotejo.currentComponentValues.Solicitantes == 1
                        ? _c("i", { staticClass: "fa fa-check" })
                        : _c("i", { staticClass: "fa fa-minus" }),
                    ]),
                  ]
                ),
              ]),
              _c("div", { staticClass: "ml-3" }, [
                _c(
                  "a",
                  {
                    class:
                      _vm.selectedComponent === "Solicitantes"
                        ? "active-menu"
                        : "text-menu",
                    on: {
                      click: function ($event) {
                        return _vm.selected("Solicitantes")
                      },
                    },
                  },
                  [_vm._v(" Solicitante ")]
                ),
              ]),
            ]
          ),
          _vm.cotejo.envio_domicilio === 1
            ? _c(
                "div",
                { staticClass: "row flex align-items-center p-2 pointer" },
                [
                  _c("div", { staticClass: "ml-3" }, [
                    _c(
                      "div",
                      {
                        class: [
                          "circle",
                          _vm.cotejo.currentComponentValues.Domicilio
                            ? "completado"
                            : "",
                        ],
                      },
                      [
                        _c("span", { staticClass: "number" }, [
                          _vm.cotejo.currentComponentValues.Domicilio
                            ? _c("i", { staticClass: "fa fa-check" })
                            : _c("i", { staticClass: "fa fa-minus" }),
                        ]),
                      ]
                    ),
                  ]),
                  _c("div", { staticClass: "ml-3" }, [
                    _c(
                      "a",
                      {
                        class:
                          _vm.selectedComponent === "Domicilio"
                            ? "active-menu"
                            : "text-menu",
                        on: {
                          click: function ($event) {
                            return _vm.selected("Domicilio")
                          },
                        },
                      },
                      [_vm._v(" Domicilio de Entrega ")]
                    ),
                  ]),
                ]
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "row flex align-items-center p-2 pointer" },
            [
              _c("div", { staticClass: "ml-3" }, [
                _c(
                  "div",
                  {
                    class: [
                      "circle",
                      _vm.cotejo.id_estatus >= 16 ? "completado" : "",
                    ],
                  },
                  [
                    _c("span", { staticClass: "number" }, [
                      _vm.cotejo.id_estatus >= 16
                        ? _c("i", { staticClass: "fa fa-check" })
                        : _c("i", { staticClass: "fa fa-minus" }),
                    ]),
                  ]
                ),
              ]),
              _c("div", { staticClass: "ml-3" }, [
                _c(
                  "a",
                  {
                    class:
                      _vm.selectedComponent === "Documentos"
                        ? "active-menu"
                        : "text-menu",
                    on: {
                      click: function ($event) {
                        return _vm.selected("Documentos")
                      },
                    },
                  },
                  [_vm._v(" Documentos ")]
                ),
              ]),
            ]
          ),
          _vm.cotejo.pago_complementario
            ? _c(
                "div",
                { staticClass: "row flex align-items-center p-2 pointer" },
                [
                  _c("div", { staticClass: "ml-3" }, [
                    _c(
                      "div",
                      {
                        class: [
                          "circle",
                          _vm.cotejo.currentComponentValues.PagoComplementario
                            ? "completado"
                            : "",
                        ],
                      },
                      [
                        _c("span", { staticClass: "number" }, [
                          _vm.cotejo.currentComponentValues.PagoComplementario
                            ? _c("i", { staticClass: "fa fa-check" })
                            : _c("i", { staticClass: "fa fa-minus" }),
                        ]),
                      ]
                    ),
                  ]),
                  _c("div", { staticClass: "ml-3" }, [
                    _c(
                      "a",
                      {
                        class:
                          _vm.selectedComponent === "PagoComplementario"
                            ? "active-menu"
                            : "text-menu",
                        on: {
                          click: function ($event) {
                            return _vm.selected("PagoComplementario")
                          },
                        },
                      },
                      [_vm._v(" Pago Complementario ")]
                    ),
                  ]),
                ]
              )
            : _vm._e(),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12 text-center mb-3" }, [
        _c("label", [_vm._v("Entrega de información base")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "row flex align-items-center p-2 pointer" },
      [
        _c("div", { staticClass: "ml-3" }, [
          _c("div", { staticClass: "circle completado" }, [
            _c("span", { staticClass: "number" }, [
              _c("i", { staticClass: "fa fa-check" }),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "ml-3" }, [
          _c("span", { staticClass: "text-menu" }, [_vm._v(" Solicitante ")]),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "row flex align-items-center p-2 pointer" },
      [
        _c("div", { staticClass: "ml-3" }, [
          _c("div", { staticClass: "circle completado" }, [
            _c("span", { staticClass: "number" }, [
              _c("i", { staticClass: "fa fa-check" }),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "ml-3" }, [
          _c("span", { staticClass: "text-menu" }, [_vm._v(" Documentos ")]),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "row flex align-items-center p-2 pointer" },
      [
        _c("div", { staticClass: "ml-3" }, [
          _c("div", { staticClass: "circle completado" }, [
            _c("span", { staticClass: "number" }, [
              _c("i", { staticClass: "fa fa-check" }),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "ml-3" }, [
          _c("span", { staticClass: "text-menu" }, [_vm._v(" Cotización ")]),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12 text-center mb-3" }, [
        _c("label", [_vm._v("Complementa la información")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }