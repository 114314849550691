var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.user === "Client"
      ? _c("div", [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-12" },
              [
                _c(
                  "b-button",
                  { staticClass: "btn-fill blue-btn", attrs: { block: "" } },
                  [_vm._v(" Entrega de información base ")]
                ),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-1" }, [
              _c(
                "div",
                {
                  class: [
                    "circle",
                    _vm.ratificacion.currentComponentValues.Comparecientes
                      ? "completado"
                      : "",
                  ],
                },
                [
                  _c("span", { staticClass: "number" }, [
                    _vm.ratificacion.currentComponentValues.Comparecientes
                      ? _c("i", { staticClass: "fa fa-check" })
                      : _c("i", { staticClass: "fa fa-minus" }),
                  ]),
                ]
              ),
            ]),
            _c(
              "div",
              { staticClass: "col-10" },
              [
                _c(
                  "b-button",
                  {
                    staticClass: "btn-fill blue-btn child",
                    attrs: { block: "" },
                    on: {
                      click: function ($event) {
                        return _vm.selected("Comparecientes")
                      },
                    },
                  },
                  [_vm._v(" Comparecientes ")]
                ),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-1" }, [
              _c(
                "div",
                {
                  class: [
                    "circle",
                    _vm.ratificacion.currentComponentValues.Montos
                      ? "completado"
                      : "",
                  ],
                },
                [
                  _c("span", { staticClass: "number" }, [
                    _vm.ratificacion.currentComponentValues.Montos
                      ? _c("i", { staticClass: "fa fa-check" })
                      : _c("i", { staticClass: "fa fa-minus" }),
                  ]),
                ]
              ),
            ]),
            _c(
              "div",
              { staticClass: "col-10" },
              [
                _c(
                  "b-button",
                  {
                    staticClass: "btn-fill blue-btn child",
                    attrs: { block: "" },
                    on: {
                      click: function ($event) {
                        return _vm.selected("Montos")
                      },
                    },
                  },
                  [_vm._v(" Montos ")]
                ),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-1" }, [
              _c(
                "div",
                {
                  class: [
                    "circle",
                    _vm.ratificacion.currentComponentValues.Documentos
                      ? "completado"
                      : "",
                  ],
                },
                [
                  _c("span", { staticClass: "number" }, [
                    _vm.ratificacion.currentComponentValues.Documentos
                      ? _c("i", { staticClass: "fa fa-check" })
                      : _c("i", { staticClass: "fa fa-minus" }),
                  ]),
                ]
              ),
            ]),
            _c(
              "div",
              { staticClass: "col-10" },
              [
                _c(
                  "b-button",
                  {
                    staticClass: "btn-fill blue-btn child",
                    attrs: { block: "" },
                    on: {
                      click: function ($event) {
                        return _vm.selected("Documentos")
                      },
                    },
                  },
                  [_vm._v(" Documentos ")]
                ),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-1" }, [
              _c(
                "div",
                {
                  class: [
                    "circle",
                    _vm.ratificacion.currentComponentValues.Cotizacion
                      ? "completado"
                      : "",
                  ],
                },
                [
                  _c("span", { staticClass: "number" }, [
                    _vm.ratificacion.currentComponentValues.Cotizacion
                      ? _c("i", { staticClass: "fa fa-check" })
                      : _c("i", { staticClass: "fa fa-minus" }),
                  ]),
                ]
              ),
            ]),
            _c(
              "div",
              { staticClass: "col-10" },
              [
                _c(
                  "b-button",
                  {
                    staticClass: "btn-fill blue-btn",
                    attrs: { block: "" },
                    on: {
                      click: function ($event) {
                        return _vm.selected("Cotizacion")
                      },
                    },
                  },
                  [_vm._v(" Cotización ")]
                ),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-1" }, [
              _c(
                "div",
                {
                  class: [
                    "circle",
                    _vm.ratificacion.currentComponentValues.Pago
                      ? "completado"
                      : "",
                  ],
                },
                [
                  _c("span", { staticClass: "number" }, [
                    _vm.ratificacion.currentComponentValues.Pago
                      ? _c("i", { staticClass: "fa fa-check" })
                      : _c("i", { staticClass: "fa fa-minus" }),
                  ]),
                ]
              ),
            ]),
            _c(
              "div",
              { staticClass: "col-10" },
              [
                _c(
                  "b-button",
                  {
                    staticClass: "btn-fill blue-btn",
                    attrs: { block: "" },
                    on: {
                      click: function ($event) {
                        return _vm.selected("Pago")
                      },
                    },
                  },
                  [_vm._v(" Pago ")]
                ),
              ],
              1
            ),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }