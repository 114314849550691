<template>
  <div class="content">
    <!-- <div>
      <p>DEBUG: {{ config_.DEBUG }}</p>
      <p>BASE_URL: {{ config_.BASE_URL }}</p>

      <p>API_URL:{{ config_.API_URL }}</p>
      <p>API_KEY:{{ config_.API_KEY }}</p>
      <p>CD_OPENPAY_ID:{{ config_.CD_OPENPAY_ID }}</p>
      <p>CD_OPENPAY_PUBLIC:{{ config_.CD_OPENPAY_PUBLIC }}</p>
      <p>CD_OPENPAY_PRIVATE:{{ config_.CD_OPENPAY_PRIVATE }}</p>
      <p>CD_OPENPAY_URL:{{ config_.CD_OPENPAY_URL }}</p>
      <p>CD_OPENPAY_REDIRECT_BASE_URL:{{ config_.CD_OPENPAY_REDIRECT_BASE_URL }}</p>
      <p>CD_OPENPAY_IS_DEVELOPMENT:{{ config_.CD_OPENPAY_IS_DEVELOPMENT }}</p>
      <p>DOMAIN:{{ config_.DOMAIN }}</p>
      <p>GOOGLE_API_KEY:{{ config_.GOOGLE_API_KEY }}</p>
      <p>STG_BASE_URL:{{ config_.STG_BASE_URL }}</p>
      <p>STG_ACCESS_KEY:{{ config_.STG_ACCESS_KEY }}</p>

      <p>EXAMPLE_ENV_VARIABLE:{{ config_.EXAMPLE_ENV_VARIABLE }}</p>
      <p>URL_TEST:{{ config_.URL_TEST }}</p>
      <p>BUILD_MODE:{{ config_.BUILD_MODE }}</p>
    </div>

    <div>
      <img src="@/assets/img/openpay/Openpay.webp" alt="visa" height="64" />
      <b-img :src="require('@/assets/img/documentos_entregados.webp')" alt="Documentos entregados" fluid></b-img>
    </div> -->

    <div>
      <b-form-file v-model="file" @input="onInput" />
    </div>
  </div>
</template>

<script>
import config from "@/config/";

export default {
  name: "ExampleComponent",

  components: {
  },

  computed: {
  },

  watch: {
  },

  mounted() {
  },

  data() {
    return {
      config_: config,
      file: null,
    };
  },

  methods: {
    onInput(input) {
      console.log(input);
      console.log(this.file);

      let fr = new FileReader();


      fr.onload = function () {
        const base64String = fr.result.split(',')[1];
        console.log("Base64:", base64String);
      };

      fr.readAsDataURL(input);
    }
  }
}
</script>
