var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.user === "Staff"
      ? _c("div", [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-1" }, [
              _c(
                "div",
                {
                  class: [
                    "circle",
                    _vm.ratificacion.validaComparecientes == 1
                      ? "completado"
                      : "",
                  ],
                },
                [
                  _c("span", { staticClass: "number" }, [
                    _vm.ratificacion.validaComparecientes == 1
                      ? _c("i", { staticClass: "fa fa-check" })
                      : _c("i", { staticClass: "fa fa-minus" }),
                  ]),
                ]
              ),
            ]),
            _c(
              "div",
              { staticClass: "col-10" },
              [
                _c(
                  "b-button",
                  {
                    staticClass: "btn-fill blue-btn",
                    attrs: { block: "" },
                    on: {
                      click: function ($event) {
                        return _vm.selected("ValidaComparecientes")
                      },
                    },
                  },
                  [_vm._v(" Comparecientes ")]
                ),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-1" }, [
              _c(
                "div",
                {
                  class: [
                    "circle",
                    _vm.ratificacion.validaDocumentos == 1 ? "completado" : "",
                  ],
                },
                [
                  _c("span", { staticClass: "number" }, [
                    _vm.ratificacion.validaDocumentos == 1
                      ? _c("i", { staticClass: "fa fa-check" })
                      : _c("i", { staticClass: "fa fa-minus" }),
                  ]),
                ]
              ),
            ]),
            _c(
              "div",
              { staticClass: "col-10" },
              [
                _c(
                  "b-button",
                  {
                    staticClass: "btn-fill blue-btn",
                    attrs: { block: "" },
                    on: {
                      click: function ($event) {
                        return _vm.selected("ValidaDocumentos")
                      },
                    },
                  },
                  [_vm._v(" Documentos ")]
                ),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "row" }, [
            _vm.ratificacion.firma_fuera_oficina == 1
              ? _c("div", { staticClass: "col-1" }, [
                  _c(
                    "div",
                    {
                      class: [
                        "circle",
                        _vm.ratificacion.validaDomicilio == 1
                          ? "completado"
                          : "",
                      ],
                    },
                    [
                      _c("span", { staticClass: "number" }, [
                        _vm.ratificacion.validaDomicilio == 1
                          ? _c("i", { staticClass: "fa fa-check" })
                          : _c("i", { staticClass: "fa fa-minus" }),
                      ]),
                    ]
                  ),
                ])
              : _vm._e(),
            _vm.ratificacion.firma_fuera_oficina == 1
              ? _c(
                  "div",
                  { staticClass: "col-10" },
                  [
                    _c(
                      "b-button",
                      {
                        staticClass: "btn-fill blue-btn",
                        attrs: { block: "" },
                        on: {
                          click: function ($event) {
                            return _vm.selected("Domicilio")
                          },
                        },
                      },
                      [_vm._v(" Domicilio ")]
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ]),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-1" }, [
              _c(
                "div",
                {
                  class: [
                    "circle",
                    _vm.ratificacion.corredurias_id ? "completado" : "",
                  ],
                },
                [
                  _c("span", { staticClass: "number" }, [
                    _vm.ratificacion.corredurias_id
                      ? _c("i", { staticClass: "fa fa-check" })
                      : _c("i", { staticClass: "fa fa-minus" }),
                  ]),
                ]
              ),
            ]),
            _c(
              "div",
              { staticClass: "col-10" },
              [
                _c(
                  "b-button",
                  {
                    staticClass: "btn-fill blue-btn",
                    attrs: { block: "" },
                    on: {
                      click: function ($event) {
                        return _vm.selected("Correduria")
                      },
                    },
                  },
                  [_vm._v(" Asignar Correduria ")]
                ),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-1" }, [
              _c(
                "div",
                {
                  class: [
                    "circle",
                    _vm.ratificacion.validaDocumentos == 1 ? "completado" : "",
                  ],
                },
                [
                  _c("span", { staticClass: "number" }, [
                    _vm.ratificacion.validaDocumentos == 1
                      ? _c("i", { staticClass: "fa fa-check" })
                      : _c("i", { staticClass: "fa fa-minus" }),
                  ]),
                ]
              ),
            ]),
            _c(
              "div",
              { staticClass: "col-10" },
              [
                _c(
                  "b-button",
                  {
                    staticClass: "btn-fill blue-btn",
                    attrs: { block: "" },
                    on: {
                      click: function ($event) {
                        return _vm.selected("EstatusDocumentos")
                      },
                    },
                  },
                  [_vm._v(" Estatus Documentos ")]
                ),
              ],
              1
            ),
          ]),
          _vm.ratificacion.pago_complementario
            ? _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-1" }, [
                  _c(
                    "div",
                    {
                      class: [
                        "circle",
                        _vm.ratificacion.estatus_id >= 39 ||
                        this.ratificacion.cotizaciones_id_complementario
                          ? "completado"
                          : "",
                      ],
                    },
                    [
                      _c("span", { staticClass: "number" }, [
                        _vm.ratificacion.estatus_id >= 39 ||
                        this.ratificacion.cotizaciones_id_complementario
                          ? _c("i", { staticClass: "fa fa-check" })
                          : _c("i", { staticClass: "fa fa-minus" }),
                      ]),
                    ]
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "col-10" },
                  [
                    _c(
                      "b-button",
                      {
                        staticClass: "btn-fill blue-btn",
                        attrs: { block: "" },
                        on: {
                          click: function ($event) {
                            return _vm.selected("PagoComplementario")
                          },
                        },
                      },
                      [_vm._v(" Pago Complementario")]
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-1" }, [
              _c(
                "div",
                {
                  class: [
                    "circle",
                    _vm.ratificacion.estatus_id >= 40 ? "completado" : "",
                  ],
                },
                [
                  _c("span", { staticClass: "number" }, [
                    _vm.ratificacion.estatus_id >= 40
                      ? _c("i", { staticClass: "fa fa-check" })
                      : _c("i", { staticClass: "fa fa-minus" }),
                  ]),
                ]
              ),
            ]),
            _c(
              "div",
              { staticClass: "col-10" },
              [
                _c(
                  "b-button",
                  {
                    staticClass: "btn-fill blue-btn",
                    attrs: { block: "" },
                    on: {
                      click: function ($event) {
                        return _vm.selected("FAD")
                      },
                    },
                  },
                  [_vm._v(" FAD ")]
                ),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-1" }, [
              _c(
                "div",
                {
                  class: [
                    "circle",
                    [41, 42, 43, 56, 70].includes(_vm.ratificacion.estatus_id)
                      ? "completado"
                      : "",
                  ],
                },
                [
                  _c("span", { staticClass: "number" }, [
                    [41, 42, 43, 56, 70].includes(_vm.ratificacion.estatus_id)
                      ? _c("i", { staticClass: "fa fa-check" })
                      : _c("i", { staticClass: "fa fa-minus" }),
                  ]),
                ]
              ),
            ]),
            _c(
              "div",
              { staticClass: "col-10" },
              [
                _c(
                  "b-button",
                  {
                    staticClass: "btn-fill blue-btn",
                    attrs: { block: "" },
                    on: {
                      click: function ($event) {
                        return _vm.selected("Cita")
                      },
                    },
                  },
                  [_vm._v(" Cita ")]
                ),
              ],
              1
            ),
          ]),
          _vm.ratificacion.inscripcion_rug == 1
            ? _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-1" }, [
                  _c(
                    "div",
                    {
                      class: [
                        "circle",
                        [43, 56, 70].includes(_vm.ratificacion.estatus_id)
                          ? "completado"
                          : "",
                      ],
                    },
                    [
                      _c("span", { staticClass: "number" }, [
                        [43, 56, 70].includes(_vm.ratificacion.estatus_id)
                          ? _c("i", { staticClass: "fa fa-check" })
                          : _c("i", { staticClass: "fa fa-minus" }),
                      ]),
                    ]
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "col-10" },
                  [
                    _c(
                      "b-button",
                      {
                        staticClass: "btn-fill blue-btn",
                        attrs: { block: "" },
                        on: {
                          click: function ($event) {
                            return _vm.selected("RUG")
                          },
                        },
                      },
                      [_vm._v(" RUG ")]
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-1" }, [
              _c(
                "div",
                {
                  class: [
                    "circle",
                    _vm.ratificacion.validaDiligencia == 1 ? "completado" : "",
                  ],
                },
                [
                  _c("span", { staticClass: "number" }, [
                    _vm.ratificacion.validaDiligencia == 1
                      ? _c("i", { staticClass: "fa fa-check" })
                      : _c("i", { staticClass: "fa fa-minus" }),
                  ]),
                ]
              ),
            ]),
            _c(
              "div",
              { staticClass: "col-10" },
              [
                _c(
                  "b-button",
                  {
                    staticClass: "btn-fill blue-btn",
                    attrs: { block: "" },
                    on: {
                      click: function ($event) {
                        return _vm.selected("Preguntas")
                      },
                    },
                  },
                  [_vm._v(" Preguntas ")]
                ),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-1" }, [
              _c(
                "div",
                {
                  class: [
                    "circle",
                    [43, 56].includes(_vm.ratificacion.estatus_id)
                      ? "completado"
                      : "",
                  ],
                },
                [
                  _c("span", { staticClass: "number" }, [
                    [43, 56].includes(_vm.ratificacion.estatus_id)
                      ? _c("i", { staticClass: "fa fa-check" })
                      : _c("i", { staticClass: "fa fa-minus" }),
                  ]),
                ]
              ),
            ]),
            _c(
              "div",
              { staticClass: "col-10" },
              [
                _c(
                  "b-button",
                  {
                    staticClass: "btn-fill blue-btn",
                    attrs: { block: "" },
                    on: {
                      click: function ($event) {
                        return _vm.selected("Actas")
                      },
                    },
                  },
                  [_vm._v(" Actas ")]
                ),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-1" }, [
              _c(
                "div",
                {
                  class: [
                    "circle",
                    [43, 56].includes(_vm.ratificacion.estatus_id)
                      ? "completado"
                      : "",
                  ],
                },
                [
                  _c("span", { staticClass: "number" }, [
                    [43, 56].includes(_vm.ratificacion.estatus_id)
                      ? _c("i", { staticClass: "fa fa-check" })
                      : _c("i", { staticClass: "fa fa-minus" }),
                  ]),
                ]
              ),
            ]),
            _c(
              "div",
              { staticClass: "col-10" },
              [
                _c(
                  "b-button",
                  {
                    staticClass: "btn-fill blue-btn",
                    attrs: { block: "" },
                    on: {
                      click: function ($event) {
                        return _vm.selected("Expediente")
                      },
                    },
                  },
                  [_vm._v(" Expediente ")]
                ),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-1" }, [
              _c(
                "div",
                {
                  class: [
                    "circle",
                    _vm.ratificacion.estatus_id == 56 ? "completado" : "",
                  ],
                },
                [
                  _c("span", { staticClass: "number" }, [
                    _vm.ratificacion.estatus_id == 56
                      ? _c("i", { staticClass: "fa fa-check" })
                      : _c("i", { staticClass: "fa fa-minus" }),
                  ]),
                ]
              ),
            ]),
            _c(
              "div",
              { staticClass: "col-10" },
              [
                _c(
                  "b-button",
                  {
                    staticClass: "btn-fill blue-btn",
                    attrs: { block: "" },
                    on: {
                      click: function ($event) {
                        return _vm.selected("Terminado")
                      },
                    },
                  },
                  [_vm._v(" Terminado ")]
                ),
              ],
              1
            ),
          ]),
        ])
      : _vm._e(),
    _vm.user === "Client"
      ? _c("div", [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-1" }, [
              _c(
                "div",
                {
                  class: [
                    "circle",
                    _vm.ratificacion.currentComponentValues.Pago
                      ? "completado"
                      : "",
                  ],
                },
                [
                  _c("span", { staticClass: "number" }, [
                    _vm.ratificacion.currentComponentValues.Pago
                      ? _c("i", { staticClass: "fa fa-check" })
                      : _c("i", { staticClass: "fa fa-minus" }),
                  ]),
                ]
              ),
            ]),
            _c(
              "div",
              { staticClass: "col-10" },
              [
                _c(
                  "b-button",
                  {
                    staticClass: "btn-fill blue-btn child",
                    attrs: { block: "" },
                    on: {
                      click: function ($event) {
                        return _vm.selected("Pago")
                      },
                    },
                  },
                  [_vm._v(" Pago ")]
                ),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-1" }, [
              _c(
                "div",
                {
                  class: [
                    "circle",
                    _vm.ratificacion.currentComponentValues
                      .ComplementaInformacion
                      ? "completado"
                      : "",
                  ],
                },
                [
                  _c("span", { staticClass: "number" }, [
                    _vm.ratificacion.currentComponentValues
                      .ComplementaInformacion
                      ? _c("i", { staticClass: "fa fa-check" })
                      : _c("i", { staticClass: "fa fa-minus" }),
                  ]),
                ]
              ),
            ]),
            _c(
              "div",
              { staticClass: "col-10" },
              [
                _c(
                  "b-button",
                  {
                    staticClass: "btn-fill blue-btn child",
                    attrs: { block: "" },
                    on: {
                      click: function ($event) {
                        return _vm.selected("ComplementaInformacion")
                      },
                    },
                  },
                  [_vm._v(" Complementa Informacion ")]
                ),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-1" }, [
              _c(
                "div",
                {
                  class: [
                    "circle",
                    _vm.ratificacion.currentComponentValues.Documentos
                      ? "completado"
                      : "",
                  ],
                },
                [
                  _c("span", { staticClass: "number" }, [
                    _vm.ratificacion.currentComponentValues.Documentos
                      ? _c("i", { staticClass: "fa fa-check" })
                      : _c("i", { staticClass: "fa fa-minus" }),
                  ]),
                ]
              ),
            ]),
            _c(
              "div",
              { staticClass: "col-10" },
              [
                _c(
                  "b-button",
                  {
                    staticClass: "btn-fill blue-btn child",
                    attrs: { block: "" },
                    on: {
                      click: function ($event) {
                        return _vm.selected("Documentos")
                      },
                    },
                  },
                  [_vm._v(" Documentos ")]
                ),
              ],
              1
            ),
          ]),
          _vm.ratificacion.entrega_fuera_oficina === 1
            ? _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-1" }, [
                  _c(
                    "div",
                    {
                      class: [
                        "circle",
                        _vm.ratificacion.currentComponentValues.Domicilio
                          ? "completado"
                          : "",
                      ],
                    },
                    [
                      _c("span", { staticClass: "number" }, [
                        _vm.ratificacion.currentComponentValues.Domicilio
                          ? _c("i", { staticClass: "fa fa-check" })
                          : _c("i", { staticClass: "fa fa-minus" }),
                      ]),
                    ]
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "col-10" },
                  [
                    _c(
                      "b-button",
                      {
                        staticClass: "btn-fill blue-btn",
                        attrs: { block: "" },
                        on: {
                          click: function ($event) {
                            return _vm.selected("Domicilio")
                          },
                        },
                      },
                      [_vm._v(" Domicilio de Entrega ")]
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm.ratificacion.pago_complementario
            ? _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-1" }, [
                  _c(
                    "div",
                    {
                      class: [
                        "circle",
                        _vm.ratificacion.currentComponentValues
                          .PagoComplementario
                          ? "completado"
                          : "",
                      ],
                    },
                    [
                      _c("span", { staticClass: "number" }, [
                        _vm.ratificacion.currentComponentValues
                          .PagoComplementario
                          ? _c("i", { staticClass: "fa fa-check" })
                          : _c("i", { staticClass: "fa fa-minus" }),
                      ]),
                    ]
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "col-10" },
                  [
                    _c(
                      "b-button",
                      {
                        staticClass: "btn-fill blue-btn",
                        attrs: { block: "" },
                        on: {
                          click: function ($event) {
                            return _vm.selected("PagoComplementario")
                          },
                        },
                      },
                      [_vm._v(" Pago Complementario ")]
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }