import { render, staticRenderFns } from "./InfoBase.vue?vue&type=template&id=64ea79e2&scoped=true"
import script from "./InfoBase.vue?vue&type=script&lang=js"
export * from "./InfoBase.vue?vue&type=script&lang=js"
import style0 from "./InfoBase.vue?vue&type=style&index=0&id=64ea79e2&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64ea79e2",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/github/workspace/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('64ea79e2')) {
      api.createRecord('64ea79e2', component.options)
    } else {
      api.reload('64ea79e2', component.options)
    }
    module.hot.accept("./InfoBase.vue?vue&type=template&id=64ea79e2&scoped=true", function () {
      api.rerender('64ea79e2', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/clients/procesos/constitucion/infoBase/InfoBase.vue"
export default component.exports