var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "contact-us full-screen mt-4" }, [
    _vm.loading
      ? _c("div", [
          _c(
            "div",
            { attrs: { id: "loader" } },
            [_c("font-awesome-icon", { attrs: { icon: "spinner", spin: "" } })],
            1
          ),
        ])
      : _vm._e(),
    _c("div", { staticClass: "container" }, [
      _c("br"),
      _c(
        "h4",
        {
          staticClass: "card-title text-warning",
          attrs: { slot: "header" },
          slot: "header",
        },
        [_vm._v("Crear cuenta")]
      ),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-12" },
          [
            _c(
              "card",
              { attrs: { id: "card" } },
              [
                _c("ValidationObserver", {
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ handleSubmit }) {
                        return [
                          _c(
                            "form",
                            {
                              on: {
                                submit: function ($event) {
                                  $event.preventDefault()
                                  return handleSubmit(_vm.register)
                                },
                              },
                            },
                            [
                              _c("Form", {
                                attrs: { user: _vm.user },
                                on: {
                                  submit: function ($event) {
                                    $event.preventDefault()
                                  },
                                },
                              }),
                              _c(
                                "button",
                                {
                                  staticClass: "btn orange-btn float-right",
                                  class: _vm.loading ? "disabled" : "",
                                  attrs: { type: "submit" },
                                },
                                [_vm._v(" Continuar ")]
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }