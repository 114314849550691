import BaseApi from './base';

class CotizacionesApi extends BaseApi {
  constructor() {
    super('cotizaciones');
  }

  verificarCotizacionPagada(id) {
    return this.request.get(`cotizaciones/pagado/${id}`);
  }
}

export default new CotizacionesApi();
