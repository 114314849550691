var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container" }, [
    _c("h4", { staticClass: "cardD" }, [_vm._v("Complementa Información")]),
    _c(
      "div",
      [
        _c("b-table", {
          ref: "table",
          attrs: {
            hover: "",
            fields: _vm.fieldsCuadro,
            items: _vm.cotejo.solicitantes,
          },
          scopedSlots: _vm._u([
            {
              key: "cell(acciones)",
              fn: function ({ item }) {
                return [
                  _vm.cotejo.validaSolicitantes != 1
                    ? _c(
                        "b-button",
                        {
                          directives: [
                            {
                              name: "b-tooltip",
                              rawName: "v-b-tooltip.hover",
                              modifiers: { hover: true },
                            },
                          ],
                          staticClass: "editBtn",
                          attrs: {
                            variant: "warning",
                            size: "sm",
                            title: "Editar",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.editCompareciente(item)
                            },
                          },
                        },
                        [_c("b-icon-pencil")],
                        1
                      )
                    : _vm._e(),
                ]
              },
            },
          ]),
        }),
      ],
      1
    ),
    _c("div", { staticClass: "mt-4" }, [
      _c(
        "div",
        {},
        [
          _c(
            "b-button",
            {
              staticClass: "btn-fill orange-btn bottom2",
              attrs: {
                size: "m",
                disabled: _vm.cotejo.validaSolicitantes == 1,
              },
              on: {
                click: function ($event) {
                  return _vm.checkComparecientes()
                },
              },
            },
            [_vm._v("Continuar")]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }