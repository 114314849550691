var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-md-12" },
          [
            _c("ValidationProvider", {
              attrs: {
                name: "persona",
                rules: "required",
                "custom-messages": {
                  required: "La persona es obligatoria",
                },
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ errors }) {
                    return [
                      _c("label", { attrs: { for: "" } }, [_vm._v("Persona")]),
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.currentCompareciente.persona,
                              expression: "currentCompareciente.persona",
                            },
                          ],
                          staticClass: "custom-select",
                          attrs: { disabled: "" },
                          on: {
                            change: function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.currentCompareciente,
                                "persona",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                          },
                        },
                        _vm._l(_vm.optionsPersona, function (option) {
                          return _c(
                            "option",
                            {
                              key: option.value,
                              domProps: { value: option.value },
                            },
                            [_vm._v(" " + _vm._s(option.text) + " ")]
                          )
                        }),
                        0
                      ),
                      _c("span", { staticClass: "cardErrors" }, [
                        _vm._v(_vm._s(errors[0])),
                      ]),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
      ]),
      _vm.currentCompareciente.persona === "Moral"
        ? _c("div", { staticClass: "row mt-4" }, [
            _c(
              "div",
              { staticClass: "col-md-12" },
              [
                _c("ValidationProvider", {
                  attrs: {
                    name: "denominacion_social",
                    rules: "required",
                    "custom-messages": {
                      required: "La denominacion social es obligatoria",
                    },
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function ({ errors }) {
                          return [
                            _c("base-input", {
                              attrs: {
                                type: "text",
                                label:
                                  "Denominación social (Incluir tipo de sociedad)",
                                placeholder: "Denominacion social",
                                disabled: _vm.cotejo.validaSolicitantes == 1,
                              },
                              model: {
                                value:
                                  _vm.currentCompareciente.denominacion_social,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.currentCompareciente,
                                    "denominacion_social",
                                    $$v
                                  )
                                },
                                expression:
                                  "currentCompareciente.denominacion_social",
                              },
                            }),
                            _c("span", { staticClass: "cardErrors" }, [
                              _vm._v(_vm._s(errors[0])),
                            ]),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    1181057612
                  ),
                }),
              ],
              1
            ),
          ])
        : _vm.currentCompareciente.persona === "Física"
        ? _c("div", { staticClass: "row mt-4" }, [
            _c(
              "div",
              { staticClass: "col-md-4" },
              [
                _c("ValidationProvider", {
                  attrs: {
                    name: "nombre",
                    rules: "required",
                    "custom-messages": {
                      required: "El nombre es obligatorio",
                    },
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ errors }) {
                        return [
                          _c("base-input", {
                            attrs: {
                              type: "text",
                              label: "Nombre",
                              placeholder: "Nombre",
                              disabled: _vm.cotejo.validaSolicitantes == 1,
                            },
                            model: {
                              value: _vm.currentCompareciente.nombre,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.currentCompareciente,
                                  "nombre",
                                  $$v
                                )
                              },
                              expression: "currentCompareciente.nombre",
                            },
                          }),
                          _c("span", { staticClass: "cardErrors" }, [
                            _vm._v(_vm._s(errors[0])),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-md-4" },
              [
                _c("ValidationProvider", {
                  attrs: {
                    name: "nombre",
                    rules: "required",
                    "custom-messages": {
                      required: "El paterno es obligatorio",
                    },
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ errors }) {
                        return [
                          _c("base-input", {
                            attrs: {
                              type: "text",
                              label: "Paterno",
                              placeholder: "Paterno",
                              disabled: _vm.cotejo.validaSolicitantes == 1,
                            },
                            model: {
                              value: _vm.currentCompareciente.paterno,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.currentCompareciente,
                                  "paterno",
                                  $$v
                                )
                              },
                              expression: "currentCompareciente.paterno",
                            },
                          }),
                          _c("span", { staticClass: "cardErrors" }, [
                            _vm._v(_vm._s(errors[0])),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-md-4" },
              [
                _c("base-input", {
                  attrs: {
                    type: "text",
                    label: "Materno",
                    placeholder: "Materno",
                    disabled: _vm.cotejo.validaSolicitantes == 1,
                  },
                  model: {
                    value: _vm.currentCompareciente.materno,
                    callback: function ($$v) {
                      _vm.$set(_vm.currentCompareciente, "materno", $$v)
                    },
                    expression: "currentCompareciente.materno",
                  },
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm.currentCompareciente.persona === "Física"
        ? _c("div", { staticClass: "row mt-4" }, [
            _c(
              "div",
              { staticClass: "col-md-12" },
              [
                _c("ValidationProvider", {
                  attrs: {
                    name: "email",
                    rules: "required",
                    "custom-messages": {
                      required: "El email es obligatorio",
                    },
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function ({ errors }) {
                          return [
                            _c("base-input", {
                              attrs: {
                                type: "text",
                                label: "Correo electrónico",
                                placeholder: "correo@dominio.com",
                                disabled: _vm.cotejo.validaSolicitantes == 1,
                              },
                              model: {
                                value: _vm.currentCompareciente.email,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.currentCompareciente,
                                    "email",
                                    $$v
                                  )
                                },
                                expression: "currentCompareciente.email",
                              },
                            }),
                            _c("span", { staticClass: "cardErrors" }, [
                              _vm._v(_vm._s(errors[0])),
                            ]),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    250629559
                  ),
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
      _c(
        "b-modal",
        {
          ref: "telefono-modal",
          attrs: {
            "no-close-on-backdrop": "",
            centered: "",
            title: "",
            "hide-footer": "",
            id: "modalTR",
            size: "lg",
          },
        },
        [
          _c("ValidationObserver", {
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ handleSubmit }) {
                  return [
                    _c(
                      "form",
                      {
                        on: {
                          submit: function ($event) {
                            $event.preventDefault()
                            handleSubmit(_vm.editTelefono())
                          },
                        },
                      },
                      [
                        _c("ModalTelefono", {
                          attrs: {
                            currentTelefono: _vm.currentCompareciente.telefono,
                          },
                          on: {
                            submit: function ($event) {
                              $event.preventDefault()
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm.currentCompareciente.persona === "Física"
        ? _c(
            "h4",
            { staticClass: "cardD" },
            [
              _vm._v(" Teléfonos "),
              _c(
                "b-button",
                {
                  staticClass: "btn-fill",
                  attrs: { variant: "success", size: "sm" },
                  on: {
                    click: function ($event) {
                      return _vm.addTelefono()
                    },
                  },
                },
                [_vm._v("+")]
              ),
            ],
            1
          )
        : _vm._e(),
      _c("div", { staticClass: "row mt-4" }),
      _c("div", { staticClass: "mt-4 text-center" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-fill float-right orange-btn",
            attrs: {
              type: "submit",
              disabled: _vm.cotejo.validaSolicitantes == 1,
            },
          },
          [_vm._v(" Guardar ")]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }