var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c("h4", { staticClass: "cardD" }, [
        _vm._v("Cotización Copias Certificadas"),
      ]),
      _c("h4", { staticClass: "cardD mt-1" }, [
        _vm._v("Resumen de Documentos"),
      ]),
      _vm._l(_vm.documentos_base, function (doc) {
        return _c("div", { key: doc.id, staticClass: "row" }, [
          _c("label", { staticClass: "grey col-md-1" }, [
            _vm._v(_vm._s(doc.copias_certificadas)),
          ]),
          _c("label", { staticClass: "grey col-md-5" }, [
            _vm._v(_vm._s(doc.tipo)),
          ]),
          _c("label", { staticClass: "grey col-md-6" }, [
            _vm._v("$" + _vm._s(doc.totalDocumento)),
          ]),
        ])
      }),
      _c("div", { staticClass: "row mt-2" }, [
        _c("label", { staticClass: "grey col-md-6" }, [_vm._v(" Subtotal ")]),
        _c("label", { staticClass: "grey col-md-6" }, [
          _vm._v("$" + _vm._s(_vm.subtotal_documentos)),
        ]),
      ]),
      _vm.subtotal_extras > 0
        ? _c("h4", { staticClass: "cardD" }, [_vm._v("Extras")])
        : _vm._e(),
      _vm.urgencia && _vm.subtotal_extras > 0
        ? _c("div", { staticClass: "row" }, [
            _c("label", { staticClass: "grey col-md-1" }),
            _c("label", { staticClass: "grey col-md-5" }, [_vm._v("Urgencia")]),
            _c("label", { staticClass: "grey col-md-6" }, [
              _vm._v("$" + _vm._s(_vm.total_urgencia)),
            ]),
          ])
        : _vm._e(),
      _vm.envioDomicilio && _vm.subtotal_extras > 0
        ? _c("div", { staticClass: "row" }, [
            _c("label", { staticClass: "grey col-md-1" }, [_vm._v("1")]),
            _c("label", { staticClass: "grey col-md-5" }, [
              _vm._v("Envío a Domicilio"),
            ]),
            _c("label", { staticClass: "grey col-md-6" }, [_vm._v("$500")]),
          ])
        : _vm._e(),
      _vm.subtotal_extras > 0
        ? _c("div", { staticClass: "row mt-2" }, [
            _c("label", { staticClass: "grey col-md-6" }, [
              _vm._v(" Subtotal "),
            ]),
            _c("label", { staticClass: "grey col-md-6" }, [
              _vm._v("$" + _vm._s(_vm.subtotal_extras)),
            ]),
          ])
        : _vm._e(),
      _c("div", { staticClass: "row mt-1" }, [
        _vm._m(0),
        _c("div", { staticClass: "col-md-6" }, [
          _c("h4", { staticClass: "cardD" }, [
            _vm._v("$" + _vm._s(_vm.total_previo_descuento)),
          ]),
        ]),
      ]),
      _vm.subtotal_descuento > 0 ? _c("hr") : _vm._e(),
      _vm.subtotal_descuento > 0
        ? _c("h4", { staticClass: "cardD" }, [_vm._v("Descuentos")])
        : _vm._e(),
      _vm.descuento_documentos > 0
        ? _c("div", { staticClass: "row" }, [
            _c("label", { staticClass: "grey col-md-1" }, [
              _vm._v(_vm._s(_vm.documentos_base.length - 3)),
            ]),
            _c("label", { staticClass: "grey col-md-5" }, [
              _vm._v("Número de Documentos"),
            ]),
            _c("label", { staticClass: "grey col-md-6" }, [
              _vm._v("-$" + _vm._s(_vm.descuento_documentos)),
            ]),
          ])
        : _vm._e(),
      _vm.envioDomicilio && _vm.subtotal_descuento > 0
        ? _c("div", { staticClass: "row" }, [
            _c("label", { staticClass: "grey col-md-1" }, [_vm._v("1")]),
            _c("label", { staticClass: "grey col-md-5" }, [
              _vm._v("Envío a Domicilio"),
            ]),
            _c("label", { staticClass: "grey col-md-6" }, [_vm._v("-$500")]),
          ])
        : _vm._e(),
      _vm.subtotal_descuento > 0
        ? _c("div", { staticClass: "row mt-2" }, [
            _c("label", { staticClass: "grey col-md-6" }, [
              _vm._v(" Subtotal "),
            ]),
            _c("label", { staticClass: "grey col-md-6" }, [
              _vm._v("-$" + _vm._s(_vm.subtotal_descuento)),
            ]),
          ])
        : _vm._e(),
      _c("h4", { staticClass: "cardD mt-1" }, [_vm._v("Cupones")]),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-12" },
          [
            _c("base-input", {
              attrs: {
                type: "text",
                label: "Si cuenta con un cupón puede aplicarlo.",
                placeholder: "Cupón de descuento",
                disabled: _vm.cuponAplicado,
              },
              model: {
                value: _vm.nombreCuponDescuento,
                callback: function ($$v) {
                  _vm.nombreCuponDescuento = $$v
                },
                expression: "nombreCuponDescuento",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-12 d-flex" },
          [
            _c("br"),
            _c(
              "b-button",
              {
                staticClass: "orange-btn",
                attrs: { size: "m", disabled: _vm.cuponAplicado },
                on: {
                  click: function ($event) {
                    return _vm.validarCupon(_vm.nombreCuponDescuento)
                  },
                },
              },
              [_vm._v("Aplicar")]
            ),
          ],
          1
        ),
      ]),
      _vm.total_post_descuento != _vm.total_previo_descuento
        ? _c("div", { staticClass: "row mt-1" }, [
            _vm._m(1),
            _c("div", { staticClass: "col-md-6" }, [
              _c("h4", { staticClass: "cardD" }, [
                _vm._v("$" + _vm._s(_vm.total_post_descuento)),
              ]),
            ]),
          ])
        : _vm._e(),
      _c("hr"),
      _vm._m(2),
      _c("div", { staticClass: "row mt-4" }, [
        _c(
          "div",
          { staticClass: "col-md-12" },
          [
            _c("ValidationProvider", {
              attrs: {
                name: "validacionDatos",
                rules: { required: { allowFalse: false } },
                "custom-messages": {
                  required: "La confirmación es obligatoria",
                },
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ errors }) {
                    return [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "align-items": "center",
                          },
                        },
                        [
                          _c(
                            "b-form-checkbox",
                            {
                              staticClass: "orange",
                              model: {
                                value: _vm.validacionDatos,
                                callback: function ($$v) {
                                  _vm.validacionDatos = $$v
                                },
                                expression: "validacionDatos",
                              },
                            },
                            [
                              _vm._v(
                                " Confirmo que los documentos son mercantiles y la información proporcionada es correcta. "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c("span", { staticClass: "cardErrors" }, [
                        _vm._v(_vm._s(errors[0])),
                      ]),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "row mt-4" }, [
        _c(
          "div",
          { staticClass: "col-md-12" },
          [
            _c("ValidationProvider", {
              attrs: {
                name: "validacionTerminos",
                rules: { required: { allowFalse: false } },
                "custom-messages": {
                  required: "La confirmación es obligatoria",
                },
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ errors }) {
                    return [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "align-items": "center",
                          },
                        },
                        [
                          _c(
                            "b-form-checkbox",
                            {
                              staticClass: "orange",
                              model: {
                                value: _vm.validacionTerminos,
                                callback: function ($$v) {
                                  _vm.validacionTerminos = $$v
                                },
                                expression: "validacionTerminos",
                              },
                            },
                            [_vm._v(" Acepto los Términos y condiciones. ")]
                          ),
                        ],
                        1
                      ),
                      _c("span", { staticClass: "cardErrors" }, [
                        _vm._v(_vm._s(errors[0])),
                      ]),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "row mt-4" }, [
        _c(
          "div",
          { staticClass: "col-md-12" },
          [
            _c("ValidationProvider", {
              attrs: {
                name: "validacionCompleto",
                rules: { required: { allowFalse: false } },
                "custom-messages": {
                  required: "La confirmación es obligatoria",
                },
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ errors }) {
                    return [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "align-items": "center",
                          },
                        },
                        [
                          _c(
                            "b-form-checkbox",
                            {
                              staticClass: "orange",
                              model: {
                                value: _vm.validacionCompleto,
                                callback: function ($$v) {
                                  _vm.validacionCompleto = $$v
                                },
                                expression: "validacionCompleto",
                              },
                            },
                            [
                              _vm._v(
                                " Declaro, bajo protesta de decir la verdad que las páginas corresponden a la totalidad de las páginas que conforman el documento COMPLETO. "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c("span", { staticClass: "cardErrors" }, [
                        _vm._v(_vm._s(errors[0])),
                      ]),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "row mt-4" }, [
        _c(
          "div",
          { staticClass: "col-md-12" },
          [
            _c("ValidationProvider", {
              attrs: {
                name: "validacionPdf",
                rules: { required: { allowFalse: false } },
                "custom-messages": {
                  required: "La confirmación es obligatoria",
                },
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ errors }) {
                    return [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "align-items": "center",
                          },
                        },
                        [
                          _c(
                            "b-form-checkbox",
                            {
                              staticClass: "orange",
                              model: {
                                value: _vm.validacionPdf,
                                callback: function ($$v) {
                                  _vm.validacionPdf = $$v
                                },
                                expression: "validacionPdf",
                              },
                            },
                            [
                              _vm._v(
                                " Declaro, bajo protesta de decir la verdad que el PDF o Archivo que enviaré, corresponde al documento original que presentaré para cotejo de forma física en las oficinas que me sean indicadas. "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c("span", { staticClass: "cardErrors" }, [
                        _vm._v(_vm._s(errors[0])),
                      ]),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "mt-4" }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-12" },
            [
              _c(
                "b-button",
                {
                  staticClass: "btn-fill orange-btn bottom2",
                  attrs: { size: "m" },
                  on: {
                    click: function ($event) {
                      return _vm.submitCotizacion()
                    },
                  },
                },
                [_vm._v("Pagar")]
              ),
            ],
            1
          ),
        ]),
      ]),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-md-6" }, [
      _c("h4", { staticClass: "cardD" }, [_vm._v("Total")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-md-6" }, [
      _c("h4", { staticClass: "cardD" }, [_vm._v("Total")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row mt-4" }, [
      _c("div", { staticClass: "col-md-12" }, [
        _c(
          "p",
          { staticStyle: { "font-size": "13px", "text-align": "center" } },
          [
            _vm._v(
              " EN TODOS LOS CASOS ES NECESARIO LA PRESENTACIÓN DE LOS DOCUMENTOS ORIGINALES PARA SU COTEJO. "
            ),
          ]
        ),
        _c(
          "p",
          { staticStyle: { "font-size": "13px", "text-align": "center" } },
          [
            _vm._v(
              " NO SE ENTREGARÁ COTEJO ALGUNO SIN LA PRESENTACIÓN DE DOCUMENTOS ORIGINALES "
            ),
          ]
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }