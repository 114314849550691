var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container" }, [
    _c("h4", { staticClass: "cardD" }, [_vm._v("Asignar Correduría")]),
    _c("div", { staticClass: "container" }, [
      _vm.loading
        ? _c("div", [
            _c(
              "div",
              { attrs: { id: "loader" } },
              [
                _c("font-awesome-icon", {
                  attrs: { icon: "spinner", spin: "" },
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
    ]),
    _vm._m(0),
    _c("div", { staticClass: "row" }, [
      _vm._m(1),
      _c("div", { staticClass: "col-9" }, [
        _vm._v(" " + _vm._s(_vm.entidad) + " "),
      ]),
    ]),
    _c("div", { staticClass: "row mb-4" }, [
      _vm._m(2),
      _c("div", { staticClass: "col-9" }, [
        _vm._v(" " + _vm._s(_vm.municipio) + " "),
      ]),
    ]),
    _c("label", [_vm._v("Correduría")]),
    _c(
      "select",
      {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.notificacion.corredurias_id,
            expression: "notificacion.corredurias_id",
          },
        ],
        staticClass: "custom-select",
        on: {
          change: function ($event) {
            var $$selectedVal = Array.prototype.filter
              .call($event.target.options, function (o) {
                return o.selected
              })
              .map(function (o) {
                var val = "_value" in o ? o._value : o.value
                return val
              })
            _vm.$set(
              _vm.notificacion,
              "corredurias_id",
              $event.target.multiple ? $$selectedVal : $$selectedVal[0]
            )
          },
        },
      },
      _vm._l(_vm.options_correduria, function (option) {
        return _c(
          "option",
          { key: option.value, domProps: { value: option.value } },
          [_vm._v(" " + _vm._s(option.text) + " ")]
        )
      }),
      0
    ),
    _c("div", { staticClass: "mt-4" }, [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-12" },
          [
            _c(
              "b-button",
              {
                staticClass: "btn-fill orange-btn bottom2",
                attrs: { type: "submit", size: "m" },
              },
              [_vm._v("Asignar")]
            ),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "mt-5 mb-4" }, [
      _c("label", { attrs: { for: "" } }, [_vm._v("Ubicación del cliente")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-3" }, [
      _c("label", [_vm._v("Entidad federativa")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-3" }, [
      _c("label", [_vm._v("Municipio")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }