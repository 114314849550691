var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("horizontal-stepper", {
    staticClass: "txtSize",
    attrs: { steps: _vm.demoSteps },
    on: { "active-step": _vm.isStepActive },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }