var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-md-6" },
        [
          _c("ValidationProvider", {
            attrs: {
              name: "nombre",
              rules: "required",
              "custom-messages": { required: "El nombre es obligatorio" },
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ errors }) {
                  return [
                    _c("base-input", {
                      attrs: {
                        type: "text",
                        label: "Nombre",
                        placeholder: "Nombre",
                      },
                      model: {
                        value: _vm.currentCupon.nombre,
                        callback: function ($$v) {
                          _vm.$set(_vm.currentCupon, "nombre", $$v)
                        },
                        expression: "currentCupon.nombre",
                      },
                    }),
                    _c("span", { staticClass: "cardErrors" }, [
                      _vm._v(_vm._s(errors[0])),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-md-6" },
        [
          _c("ValidationProvider", {
            attrs: {
              name: "tipo",
              rules: "required",
              "custom-messages": {
                required: "El tipo es obligatorio",
              },
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ errors }) {
                  return [
                    _c(
                      "b-form-group",
                      {
                        attrs: {
                          id: "input-group-tipo-cupon",
                          label: "Tipo:",
                          "label-for": "input-tipo-cupon",
                        },
                      },
                      [
                        _c("b-form-select", {
                          attrs: {
                            id: "input-tipo-cupon",
                            options: _vm.options,
                          },
                          model: {
                            value: _vm.currentCupon.tipo,
                            callback: function ($$v) {
                              _vm.$set(_vm.currentCupon, "tipo", $$v)
                            },
                            expression: "currentCupon.tipo",
                          },
                        }),
                      ],
                      1
                    ),
                    _c("span", { staticClass: "cardErrors" }, [
                      _vm._v(_vm._s(errors[0])),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "row mt-4" }, [
      _c(
        "div",
        { staticClass: "col-md-6" },
        [
          _c("ValidationProvider", {
            attrs: {
              name: "descuento",
              rules: "required",
              "custom-messages": {
                required: "El descuento es obligatorio",
              },
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ errors }) {
                  return [
                    _c("base-input", {
                      attrs: {
                        type: "number",
                        label: "Descuento",
                        placeholder: "Descuento",
                        min: 0,
                      },
                      model: {
                        value: _vm.currentCupon.descuento,
                        callback: function ($$v) {
                          _vm.$set(_vm.currentCupon, "descuento", $$v)
                        },
                        expression: "currentCupon.descuento",
                      },
                    }),
                    _c("span", { staticClass: "cardErrors" }, [
                      _vm._v(_vm._s(errors[0])),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-6" },
        [
          _c("base-input", {
            attrs: {
              type: "number",
              label: "Número de aplicaciones máximas",
              placeholder: "Número de aplicaciones máximas",
              min: 0,
            },
            model: {
              value: _vm.currentCupon.limiteAplicaciones,
              callback: function ($$v) {
                _vm.$set(_vm.currentCupon, "limiteAplicaciones", $$v)
              },
              expression: "currentCupon.limiteAplicaciones",
            },
          }),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "row mt-4" }, [
      _c(
        "div",
        { staticClass: "col-md-6" },
        [
          _c("label", { attrs: { for: "fechaInicio" } }, [
            _vm._v("Fecha de inicio"),
          ]),
          _c("b-form-datepicker", {
            staticClass: "mb-2",
            attrs: { id: "fechaInicio", "reset-button": "" },
            model: {
              value: _vm.currentCupon.fechaInicio,
              callback: function ($$v) {
                _vm.$set(_vm.currentCupon, "fechaInicio", $$v)
              },
              expression: "currentCupon.fechaInicio",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-6" },
        [
          _c("label", { attrs: { for: "fechaFin" } }, [_vm._v("Fecha de fin")]),
          _c("b-form-datepicker", {
            staticClass: "mb-2",
            attrs: { id: "fechaFin", "reset-button": "" },
            model: {
              value: _vm.currentCupon.fechaFin,
              callback: function ($$v) {
                _vm.$set(_vm.currentCupon, "fechaFin", $$v)
              },
              expression: "currentCupon.fechaFin",
            },
          }),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "row mt-4" }, [
      _c(
        "div",
        { staticClass: "col-6" },
        [
          _c(
            "b-form-checkbox",
            {
              attrs: { name: "check-button", switch: "" },
              on: {
                click: function ($event) {
                  return _vm.habilitaChange($event)
                },
              },
              model: {
                value: _vm.habilitado,
                callback: function ($$v) {
                  _vm.habilitado = $$v
                },
                expression: "habilitado",
              },
            },
            [_vm._v(" Habilitado ")]
          ),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "text-center mt-3" }, [
      _c(
        "button",
        {
          staticClass: "btn btn-fill float-right orange-btn",
          attrs: { type: "submit" },
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.validateFields.apply(null, arguments)
            },
          },
        },
        [_vm._v(" Guardar ")]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }