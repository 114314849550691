var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container" }, [
    _c(
      "form",
      {
        on: {
          change: function ($event) {
            return _vm.getAddressInMap(null)
          },
          submit: function ($event) {
            $event.preventDefault()
            return _vm.sendAllChecksPassed()
          },
        },
      },
      [
        _c("ValidationObserver", {
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function ({ handleSubmit }) {
                return [
                  _c("ModalDomicilio", {
                    attrs: { currentDomicilio: _vm.currentDomicilio },
                    on: {
                      submit: function ($event) {
                        $event.preventDefault()
                        return handleSubmit.apply(null, arguments)
                      },
                    },
                  }),
                ]
              },
            },
          ]),
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }