<template>
  <div class="content">
    <div class="loader">
      <font-awesome-icon :icon="icon" :spin="icon === 'spinner'" />
      <p class="message">{{ message }}</p>
    </div>
    <!-- <div v-if="loading">
      <div class="loader">
        <font-awesome-icon icon="times" />
        <p class="message">Se está procesando la información de su pago, espere un momento.</p>
      </div>
    </div> -->
    <!-- <div v-if="loading">
      <div class="loader">
        <font-awesome-icon icon="check" />
        <p class="message">Se está procesando la información de su pago, espere un momento.</p>
      </div>
    </div> -->
  </div>
</template>

<script>
import { FuncionesApi } from "@/api/";

export default {

  components: {
  },

  computed: {
  },

  watch: {
  },

  mounted() {
    // this.icon = "times";
    // this.message = "Se está procesando la información de su pago, espere un momento.";

    const transactionId = this.$route.query.id;

    if (transactionId) {
      this.getTransactionStatus(transactionId);
      return;
    }

    this.delay(1000).then(() => {
      this.$router.push({ path: "/dashboard" });
    });
  },

  data() {
    return {
      loading: false,
      icon: "",
      message: "",
    };
  },

  methods: {
    delay(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },

    async getTransactionStatus(transactionId) {
      try {
        const p1 = this.delay(8000);
        this.icon = "spinner";
        this.message = "Se está procesando la información de su pago, espere un momento.";
        const p2 = FuncionesApi.getStatusCargo(transactionId);
        const p3 = await Promise.all([p1, p2]);
        const result = p3[1];

        if (result.status === 1) {
          this.icon = "check";
          this.message = "El pago fue realizado correctamente. Redirigiendo...";
          await this.delay(4000);
          const returnPath = this.$route.query.return_path;
          this.$router.push({ path: returnPath, query: { payment_status: 'successful' } });
        } else {
          this.icon = "times";
          this.message = "El pago fue rechazado, intente con otro medio de pago. Redirigiendo...";
          await this.delay(4000);
          this.$router.push({ path: returnPath });
        }
      } catch (error) {
        console.log(error);
        this.icon = "times";
        this.message = "Ocurrió un error al procesar la información del pago.";
        await this.delay(4000);
        this.$router.push({ path: "/dashboard" });
      }
    }
  },
};
</script>

<style scoped>


.loader {
  display: flex;
  flex-direction: column; /* Apilar elementos verticalmente */
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100vw;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.8); /* Fondo ligero */
  color: #464646;
  font-size: 5em; /* Ajuste de tamaño */
  gap: 20px; /* Espaciado entre elementos */
}

.message {
  font-size: 0.6em; /* Ajusta el tamaño de la leyenda */
  text-align: center;
  max-width: 80%;
}
</style>