var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c(
        "b-modal",
        {
          ref: "modal-confirm-mail",
          attrs: {
            "no-close-on-backdrop": "",
            centered: "",
            "hide-footer": "",
            id: "modal-confirm-mail",
          },
        },
        [
          _c("h4", { staticClass: "text-center" }, [
            _vm._v(
              " ¿Está seguro de validar la Liga de Pago? El cliente será notificado vía email. "
            ),
          ]),
          _c("h5", { staticClass: "text-center" }),
          _c(
            "b-button",
            {
              staticClass: "mt-3",
              attrs: { variant: "danger" },
              on: {
                click: function ($event) {
                  return _vm.$bvModal.hide("modal-confirm-mail")
                },
              },
            },
            [_vm._v("Cancelar")]
          ),
          _c(
            "b-button",
            {
              staticClass: "mt-3 float-right",
              attrs: { variant: "primary" },
              on: {
                click: function ($event) {
                  return _vm.processPago()
                },
              },
            },
            [_vm._v("OK")]
          ),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          ref: "modal-confirm-pagado",
          attrs: {
            "no-close-on-backdrop": "",
            centered: "",
            "hide-footer": "",
            id: "modal-confirm-pagado",
          },
        },
        [
          _c("h4", { staticClass: "text-center" }, [
            _vm._v(" ¿Está seguro de validar el pago? "),
          ]),
          _c("h5", { staticClass: "text-center" }),
          _c(
            "b-button",
            {
              staticClass: "mt-3",
              attrs: { variant: "danger" },
              on: {
                click: function ($event) {
                  return _vm.$bvModal.hide("modal-confirm-pagado")
                },
              },
            },
            [_vm._v("Cancelar")]
          ),
          _c(
            "b-button",
            {
              staticClass: "mt-3 float-right",
              attrs: { variant: "primary" },
              on: {
                click: function ($event) {
                  return _vm.processPagado()
                },
              },
            },
            [_vm._v("OK")]
          ),
        ],
        1
      ),
      _c("h4", { staticClass: "cardD" }, [_vm._v("Pago Complementario")]),
      _c(
        "div",
        { staticClass: "mt-4" },
        [
          _c("ValidationProvider", {
            attrs: {
              name: "liga_pago",
              rules: "required",
              "custom-messages": {
                required: "La liga de pago es requerida",
              },
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ errors }) {
                  return [
                    _c(
                      "b-form-group",
                      {
                        attrs: {
                          label: "Liga de pago Openpay",
                          "label-for": "input-link-pago-complementario",
                        },
                      },
                      [
                        _c(
                          "b-input-group",
                          { staticClass: "mt-3" },
                          [
                            _c(
                              "b-input-group-append",
                              [
                                _c(
                                  "b-button",
                                  {
                                    directives: [
                                      {
                                        name: "b-tooltip",
                                        rawName: "v-b-tooltip.hover",
                                        modifiers: { hover: true },
                                      },
                                    ],
                                    attrs: {
                                      variant: _vm.isTextCopied
                                        ? "success"
                                        : "secondary",
                                      squared: "",
                                      title: "Copiar",
                                    },
                                    on: { click: _vm.copyLinkToClipboard },
                                  },
                                  [
                                    _vm.isTextCopied
                                      ? _c("b-icon", {
                                          attrs: { icon: "check" },
                                        })
                                      : _c("b-icon", {
                                          attrs: { icon: "clipboard" },
                                        }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c("b-form-input", {
                              attrs: {
                                id: "input-link-pago-complementario",
                                type: "link",
                                disabled:
                                  _vm.ratificacion.estatus_id >= 38 &&
                                  !_vm.ratificacion.staff,
                                placeholder: "Link Openpay",
                              },
                              model: {
                                value:
                                  _vm.ratificacion.liga_pago_complementario,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.ratificacion,
                                    "liga_pago_complementario",
                                    $$v
                                  )
                                },
                                expression:
                                  "ratificacion.liga_pago_complementario",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("span", { staticClass: "cardErrors" }, [
                      _vm._v(_vm._s(errors[0])),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm.ratificacion.staff
        ? _c("div", { staticClass: "mt-4" }, [
            _c(
              "div",
              {},
              [
                _c(
                  "b-button",
                  {
                    staticClass: "btn-fill orange-btn bottom2",
                    attrs: {
                      size: "m",
                      disabled:
                        _vm.ratificacion.cotizaciones_id_complementario != null,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.openConfirmPagoModal()
                      },
                    },
                  },
                  [_vm._v("Validar")]
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm.ratificacion.staff
        ? _c("div", { staticClass: "mt-4" }, [
            _c(
              "div",
              {},
              [
                _c(
                  "b-button",
                  {
                    staticClass: "btn-fill green-btn bottom2",
                    attrs: {
                      size: "m",
                      disabled:
                        _vm.ratificacion.estatus_id != 38 ||
                        _vm.ratificacion.cotizaciones_id_complementario == null,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.openConfirmPagadoModal()
                      },
                    },
                  },
                  [_vm._v("Pagado")]
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }