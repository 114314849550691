var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c("h4", { staticClass: "cardD" }, [
        _vm._v("Datos generales de los Órganos Corporativos"),
      ]),
      _c(
        "b-modal",
        {
          ref: "modal-confirm-dialog",
          attrs: {
            "no-close-on-backdrop": "",
            centered: "",
            "hide-footer": "",
            id: "modal-confirm-dialog",
          },
        },
        [
          _c("h4", { staticClass: "text-center" }, [
            _vm._v(" ¿Seguro que quiere eliminar al administrador o gerente? "),
          ]),
          _c("h5", { staticClass: "text-center" }),
          _c(
            "b-button",
            {
              staticClass: "mt-3",
              attrs: { variant: "danger" },
              on: {
                click: function ($event) {
                  return _vm.$bvModal.hide("modal-confirm-dialog")
                },
              },
            },
            [_vm._v("Cancelar")]
          ),
          _c(
            "b-button",
            {
              staticClass: "mt-3 float-right",
              attrs: { variant: "primary" },
              on: { click: _vm.deleteAdministrador },
            },
            [_vm._v("OK")]
          ),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          ref: "administrador-modal",
          attrs: {
            "no-close-on-backdrop": "",
            centered: "",
            title: "",
            "hide-footer": "",
            id: "modalC",
            size: "xl",
          },
        },
        [
          _c("ValidationObserver", {
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ handleSubmit }) {
                  return [
                    _c(
                      "form",
                      {
                        on: {
                          submit: function ($event) {
                            $event.preventDefault()
                            return handleSubmit(_vm.putAdministrador)
                          },
                        },
                      },
                      [
                        _c("ModalOrganosAdministradores", {
                          attrs: {
                            currentAdministrador: _vm.currentAdministrador,
                            complete: false,
                          },
                          on: {
                            submit: function ($event) {
                              $event.preventDefault()
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          ref: "modal-confirm-dialog-vigilancia",
          attrs: {
            "no-close-on-backdrop": "",
            centered: "",
            "hide-footer": "",
            id: "modal-confirm-dialog-vigilancia",
          },
        },
        [
          _c("h4", { staticClass: "text-center" }, [
            _vm._v(" ¿Seguro que quiere eliminar al miembro de vigilancia? "),
          ]),
          _c("h5", { staticClass: "text-center" }),
          _c(
            "b-button",
            {
              staticClass: "mt-3",
              attrs: { variant: "danger" },
              on: {
                click: function ($event) {
                  return _vm.$bvModal.hide("modal-confirm-dialog-vigilancia")
                },
              },
            },
            [_vm._v("Cancelar")]
          ),
          _c(
            "b-button",
            {
              staticClass: "mt-3 float-right",
              attrs: { variant: "primary" },
              on: { click: _vm.deleteVigilancia },
            },
            [_vm._v("OK")]
          ),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          ref: "vigilancia-modal",
          attrs: {
            "no-close-on-backdrop": "",
            centered: "",
            title: "",
            "hide-footer": "",
            id: "modalV",
            size: "xl",
          },
        },
        [
          _c("ValidationObserver", {
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ handleSubmit }) {
                  return [
                    _c(
                      "form",
                      {
                        on: {
                          submit: function ($event) {
                            $event.preventDefault()
                            return handleSubmit(_vm.putVigilancia)
                          },
                        },
                      },
                      [
                        _c("ModalOrganosVigilancia", {
                          attrs: {
                            currentVigilancia: _vm.currentVigilancia,
                            complete: false,
                          },
                          on: {
                            submit: function ($event) {
                              $event.preventDefault()
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("div", { staticClass: "row mt-4" }, [
        _c(
          "div",
          { staticClass: "col-md-12" },
          [
            _c("ValidationProvider", {
              attrs: {
                name: "tipo_administracion",
                rules: "required",
                "custom-messages": {
                  required: "El tipo de administración es obligatorio",
                },
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ errors }) {
                    return [
                      _c("label", { attrs: { for: "" } }, [
                        _vm._v("Administración de la Sociedad"),
                      ]),
                      _vm.constitutiva.tipo_societario === "SA"
                        ? _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.constitutiva.tipo_administracion,
                                  expression:
                                    "constitutiva.tipo_administracion",
                                },
                              ],
                              staticClass: "custom-select",
                              attrs: {
                                disabled: _vm.constitutiva.disabledOrganos,
                              },
                              on: {
                                change: function ($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function (o) {
                                      return o.selected
                                    })
                                    .map(function (o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.$set(
                                    _vm.constitutiva,
                                    "tipo_administracion",
                                    $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  )
                                },
                              },
                            },
                            _vm._l(_vm.optionsSa, function (option) {
                              return _c("option", { key: option.value }, [
                                _vm._v(" " + _vm._s(option.text) + " "),
                              ])
                            }),
                            0
                          )
                        : _vm.constitutiva.tipo_societario === "SAPI"
                        ? _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.constitutiva.tipo_administracion,
                                  expression:
                                    "constitutiva.tipo_administracion",
                                },
                              ],
                              staticClass: "custom-select",
                              attrs: { disabled: _vm.constitutiva.disabled },
                              on: {
                                change: function ($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function (o) {
                                      return o.selected
                                    })
                                    .map(function (o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.$set(
                                    _vm.constitutiva,
                                    "tipo_administracion",
                                    $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  )
                                },
                              },
                            },
                            _vm._l(_vm.optionsSapi, function (option) {
                              return _c("option", { key: option.value }, [
                                _vm._v(" " + _vm._s(option.text) + " "),
                              ])
                            }),
                            0
                          )
                        : _vm.constitutiva.tipo_societario === "S de RL"
                        ? _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.constitutiva.tipo_administracion,
                                  expression:
                                    "constitutiva.tipo_administracion",
                                },
                              ],
                              staticClass: "custom-select",
                              attrs: {
                                disabled: _vm.constitutiva.disabledOrganos,
                              },
                              on: {
                                change: function ($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function (o) {
                                      return o.selected
                                    })
                                    .map(function (o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.$set(
                                    _vm.constitutiva,
                                    "tipo_administracion",
                                    $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  )
                                },
                              },
                            },
                            _vm._l(_vm.optionsSrl, function (option) {
                              return _c("option", { key: option.value }, [
                                _vm._v(" " + _vm._s(option.text) + " "),
                              ])
                            }),
                            0
                          )
                        : _vm._e(),
                      _c("span", { staticClass: "cardErrors" }, [
                        _vm._v(_vm._s(errors[0])),
                      ]),
                    ]
                  },
                },
              ]),
            }),
            _c("label", { staticClass: "mt-2 leyenda" }, [
              _vm._v(
                " Los administradores o miembros del consejo pueden o no ser accionistas de la sociedad. "
              ),
            ]),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-12 mt-4" },
          [
            _vm.constitutiva.tipo_societario === "SA" ||
            _vm.constitutiva.tipo_societario === "SAPI"
              ? _c("label", [_vm._v("Administradores")])
              : _vm._e(),
            _vm.constitutiva.tipo_societario === "S de RL"
              ? _c("label", [_vm._v("Gerentes")])
              : _vm._e(),
            _vm.constitutiva.roleStaff &&
            (_vm.constitutiva.tipo_administracion ===
              "Consejo de Administración" ||
              (_vm.constitutiva.tipo_administracion === "Administrador único" &&
                _vm.constitutiva.administradores.length === 0) ||
              _vm.constitutiva.tipo_administracion === "Consejo de Gerentes" ||
              (_vm.constitutiva.tipo_administracion === "Gerente General" &&
                _vm.constitutiva.administradores.length === 0))
              ? _c(
                  "b-button",
                  {
                    staticClass: "btn-fill spaceL",
                    attrs: {
                      disabled: _vm.constitutiva.disabledOrganos,
                      variant: "success",
                      size: "sm",
                    },
                    on: { click: _vm.addAdministrador },
                  },
                  [_vm._v("+")]
                )
              : _vm._e(),
            _c("b-table", {
              attrs: {
                hover: "",
                fields: _vm.fieldsCuadro,
                items: _vm.constitutiva.administradores,
              },
              scopedSlots: _vm._u([
                {
                  key: "cell(acciones)",
                  fn: function ({ item }) {
                    return [
                      _c(
                        "b-button",
                        {
                          directives: [
                            {
                              name: "b-tooltip",
                              rawName: "v-b-tooltip.hover",
                              modifiers: { hover: true },
                            },
                          ],
                          staticClass: "editBtn",
                          attrs: {
                            disabled: _vm.constitutiva.disabledOrganos,
                            variant: "warning",
                            size: "sm",
                            title: "Editar",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.editAdministrador(item)
                            },
                          },
                        },
                        [_c("b-icon-pencil")],
                        1
                      ),
                      _vm.constitutiva.roleStaff
                        ? _c(
                            "b-button",
                            {
                              directives: [
                                {
                                  name: "b-tooltip",
                                  rawName: "v-b-tooltip.hover",
                                  modifiers: { hover: true },
                                },
                              ],
                              attrs: {
                                disabled: _vm.constitutiva.disabledOrganos,
                                variant: "danger",
                                size: "sm",
                                title: "Eliminar",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.predeleteAdministrador(item.id)
                                },
                              },
                            },
                            [_c("b-icon-trash")],
                            1
                          )
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
      ]),
      _vm.constitutiva.tipo_administracion === "Consejo de Administración" ||
      _vm.constitutiva.tipo_administracion === "Consejo de Gerentes"
        ? _c("div", [
            _c("label", { staticClass: "mt-2 leyenda" }, [
              _vm._v(
                " Se recomienda tener un consejo con un número impar de miembros. "
              ),
            ]),
            _c("div", { staticClass: "row mt-4" }, [
              _c(
                "div",
                { staticClass: "col-6" },
                [
                  _c("label", { attrs: { for: "" } }, [
                    _vm._v("El presidente tiene voto de calidad"),
                  ]),
                  _c("b-form-checkbox", {
                    attrs: { disabled: _vm.constitutiva.disabledOrganos },
                    model: {
                      value: _vm.constitutiva.presidente_voto,
                      callback: function ($$v) {
                        _vm.$set(_vm.constitutiva, "presidente_voto", $$v)
                      },
                      expression: "constitutiva.presidente_voto",
                    },
                  }),
                ],
                1
              ),
            ]),
          ])
        : _vm._e(),
      _c("div", { staticClass: "row mt-5" }, [
        _c(
          "div",
          { staticClass: "col md-12" },
          [
            _c("label", { attrs: { for: "" } }, [_vm._v("Comisario")]),
            _vm.constitutiva.roleStaff && _vm.constitutiva.vigilancia.length < 1
              ? _c(
                  "b-button",
                  {
                    staticClass: "btn-fill spaceL",
                    attrs: {
                      disabled: _vm.constitutiva.disabledOrganos,
                      variant: "success",
                      size: "sm",
                    },
                    on: { click: _vm.addVigilancia },
                  },
                  [_vm._v(" + ")]
                )
              : _vm._e(),
            _c("div", [
              _vm.constitutiva.tipo_societario === "S de RL"
                ? _c("label", { staticClass: "mb-12 leyenda" }, [
                    _vm._v(" Campo opcional "),
                  ])
                : _vm._e(),
            ]),
            _c("b-table", {
              attrs: {
                hover: "",
                fields: _vm.fieldsCuadroVigilancia,
                items: _vm.constitutiva.vigilancia,
              },
              scopedSlots: _vm._u([
                {
                  key: "cell(acciones)",
                  fn: function ({ item }) {
                    return [
                      _c(
                        "b-button",
                        {
                          directives: [
                            {
                              name: "b-tooltip",
                              rawName: "v-b-tooltip.hover",
                              modifiers: { hover: true },
                            },
                          ],
                          staticClass: "editBtn",
                          attrs: {
                            disabled: _vm.constitutiva.disabledOrganos,
                            variant: "warning",
                            size: "sm",
                            title: "Editar",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.editVigilancia(item)
                            },
                          },
                        },
                        [_c("b-icon-pencil")],
                        1
                      ),
                      _vm.constitutiva.roleStaff
                        ? _c(
                            "b-button",
                            {
                              directives: [
                                {
                                  name: "b-tooltip",
                                  rawName: "v-b-tooltip.hover",
                                  modifiers: { hover: true },
                                },
                              ],
                              attrs: {
                                disabled: _vm.constitutiva.disabledOrganos,
                                variant: "danger",
                                size: "sm",
                                title: "Eliminar",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.predeleteVigilancia(item.id)
                                },
                              },
                            },
                            [_c("b-icon-trash")],
                            1
                          )
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            }),
            _vm.constitutiva.vigilancia.length > 1
              ? _c("label", { staticClass: "mt-2 leyenda" }, [
                  _vm._v(" La cotización básica incluye solo un comisario. "),
                ])
              : _vm._e(),
            _c("label", { staticClass: "mb-12 leyenda" }, [
              _vm._v(
                " El comisario u órgano de vigilancia no pueden ser familiares de los administradores ni apoderados de la sociedad. "
              ),
            ]),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "mt-4" }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-10" },
            [
              _c(
                "b-button",
                {
                  staticClass: "btn-fill orange-btn bottom2",
                  attrs: {
                    type: "submit",
                    size: "m",
                    disabled: _vm.constitutiva.disabledOrganos,
                  },
                },
                [_vm._v("Guardar")]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-2" },
            [
              _c(
                "b-button",
                {
                  staticClass: "btn-fill bottom2",
                  attrs: {
                    variant: "success",
                    size: "m",
                    disabled: _vm.constitutiva.disabledOrganos,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.validar()
                    },
                  },
                },
                [_vm._v("Validar")]
              ),
            ],
            1
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }