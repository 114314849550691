import { render, staticRenderFns } from "./Servicios.vue?vue&type=template&id=98323a40"
import script from "./Servicios.vue?vue&type=script&lang=js"
export * from "./Servicios.vue?vue&type=script&lang=js"
import style0 from "./Servicios.vue?vue&type=style&index=0&id=98323a40&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/github/workspace/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('98323a40')) {
      api.createRecord('98323a40', component.options)
    } else {
      api.reload('98323a40', component.options)
    }
    module.hot.accept("./Servicios.vue?vue&type=template&id=98323a40", function () {
      api.rerender('98323a40', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/landingPage/Servicios.vue"
export default component.exports