var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c(
        "b-modal",
        {
          ref: "confirmar-facturacion-modal",
          attrs: {
            "no-close-on-backdrop": "",
            id: "confirmar-facturacion-modal",
            centered: "",
            "hide-footer": "",
          },
        },
        [
          _c("h4", { staticClass: "text-center" }, [
            _vm._v("¿Desea facturar su pago?"),
          ]),
          _c("h5", { staticClass: "text-center" }),
          _c(
            "b-button",
            {
              staticClass: "mt-3",
              attrs: { variant: "danger" },
              on: {
                click: function ($event) {
                  return _vm.confirmGenerarFactura(false)
                },
              },
            },
            [_vm._v("No en este momento")]
          ),
          _c(
            "b-button",
            {
              staticClass: "mt-3 float-right",
              attrs: { variant: "primary" },
              on: {
                click: function ($event) {
                  return _vm.confirmGenerarFactura(true)
                },
              },
            },
            [_vm._v("Sí")]
          ),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          ref: "datos-fiscales-modal",
          attrs: {
            "no-close-on-backdrop": "",
            id: "datos-fiscales-modal",
            centered: "",
            "hide-footer": "",
            "hide-header": "",
            size: "lg",
          },
          on: {
            close: function ($event) {
              $event.preventDefault()
            },
          },
        },
        [
          _c("FacturacionComponent", {
            attrs: { datosFacturacion: _vm.datosFacturacion },
            on: {
              closedFacturacionEvent: function ($event) {
                return _vm.closedFacturacion($event)
              },
              canceledFacturacionEvent: function ($event) {
                return _vm.closedFacturacion($event)
              },
            },
          }),
        ],
        1
      ),
      _c("h4", { staticClass: "cardD" }, [_vm._v("Pago")]),
      _c(
        "b-tabs",
        { attrs: { fill: "" } },
        [
          _c(
            "b-tab",
            { attrs: { title: "Tarjeta de crédito o débito", active: "" } },
            [
              _c("Openpay", {
                attrs: {
                  cotizacion_id: _vm.cotejo.id_cotizaciones,
                  precio_total: _vm.cotejo.precio_total,
                },
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                  },
                  payCotizacion: function ($event) {
                    return _vm.payCotizacion($event)
                  },
                },
              }),
            ],
            1
          ),
          _c(
            "b-tab",
            { staticClass: "modalPay", attrs: { title: "SPEI/Transferencia" } },
            [
              _c("Spei", {
                attrs: {
                  cotizacion_id: _vm.cotejo.id_cotizaciones,
                  precio_total: _vm.cotejo.precio_total,
                  proceso_id: _vm.cotejo.id,
                  tipo: "cotejo",
                },
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                  },
                  payCotizacionSpei: function ($event) {
                    return _vm.payCotizacionSpei($event)
                  },
                },
              }),
            ],
            1
          ),
          _c(
            "b-tab",
            { staticClass: "modalPay", attrs: { title: "Monedero" } },
            [
              _c("Monedero", {
                attrs: {
                  cotizacion_id: _vm.cotejo.id_cotizaciones,
                  precio_total: _vm.cotejo.precio_total,
                  clientes_id: _vm.cotejo.id_clientes,
                  servicio: "Cotejo " + _vm.cotejo.id,
                },
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                  },
                  payCotizacion: function ($event) {
                    return _vm.payCotizacionMonedero($event)
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }