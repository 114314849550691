var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container" },
    [
      _vm.loading
        ? _c("div", [
            _c(
              "div",
              { attrs: { id: "loader" } },
              [
                _c("font-awesome-icon", {
                  attrs: { icon: "spinner", spin: "" },
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
      _c(
        "b-modal",
        {
          ref: "modal-confirm-dialog",
          attrs: {
            "no-close-on-backdrop": "",
            centered: "",
            "hide-footer": "",
            id: "modal-confirm-dialog",
          },
        },
        [
          _c("h4", { staticClass: "text-center" }, [
            _vm._v("¿Seguro que quiere eliminar al accionista?"),
          ]),
          _c("h5", { staticClass: "text-center" }),
          _c(
            "b-button",
            {
              staticClass: "mt-3",
              attrs: { variant: "danger" },
              on: {
                click: function ($event) {
                  return _vm.$bvModal.hide("modal-confirm-dialog")
                },
              },
            },
            [_vm._v("Cancelar")]
          ),
          _c(
            "b-button",
            {
              staticClass: "mt-3 float-right",
              attrs: { variant: "primary" },
              on: { click: _vm.deleteAccionista },
            },
            [_vm._v("OK")]
          ),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          ref: "accionista-modal",
          attrs: {
            "no-close-on-backdrop": "",
            centered: "",
            title: "",
            "hide-footer": "",
            id: "modalC",
            size: "xl",
          },
        },
        [
          _c("ValidationObserver", {
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ handleSubmit }) {
                  return [
                    _c(
                      "form",
                      {
                        on: {
                          submit: function ($event) {
                            $event.preventDefault()
                            return handleSubmit(_vm.putAccionista)
                          },
                        },
                      },
                      [
                        _c("ModalCapitalAccionista", {
                          attrs: { currentAccionista: _vm.currentAccionista },
                          on: {
                            submit: function ($event) {
                              $event.preventDefault()
                            },
                            calcular: _vm.calcularCapitales,
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("h4", { staticClass: "cardD" }, [
        _vm._v("Datos generales del Capital y de los Accionistas"),
      ]),
      _c("div", { staticClass: "row mt-4" }, [
        _c(
          "div",
          { staticClass: "col-6" },
          [
            _c("ValidationProvider", {
              attrs: {
                name: "capital_fijo",
                rules: "required",
                "custom-messages": {
                  required: "El capital fijo es obligatorio",
                },
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ errors }) {
                    return [
                      _c("base-input", {
                        attrs: {
                          disabled: _vm.constitutiva.disabledCapital,
                          type: "number",
                          label: "Monto de Capital Fijo",
                          placeholder: "$",
                        },
                        on: { keyup: _vm.calcularCapitales },
                        model: {
                          value: _vm.constitutiva.capital_fijo,
                          callback: function ($$v) {
                            _vm.$set(_vm.constitutiva, "capital_fijo", $$v)
                          },
                          expression: "constitutiva.capital_fijo",
                        },
                      }),
                      _c("span", { staticClass: "cardErrors" }, [
                        _vm._v(_vm._s(errors[0])),
                      ]),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "row mt-4" }, [
        _c("div", { staticClass: "col-12" }, [
          _c("label", { attrs: { for: "" } }, [
            _vm._v("Hay Personas Físicas extranjeras"),
          ]),
          _vm.constitutiva.roleStaff
            ? _c(
                "div",
                [
                  _c("b-form-checkbox", {
                    attrs: { disabled: _vm.constitutiva.disabledCapital },
                    model: {
                      value: _vm.constitutiva.capital_extranjero,
                      callback: function ($$v) {
                        _vm.$set(_vm.constitutiva, "capital_extranjero", $$v)
                      },
                      expression: "constitutiva.capital_extranjero",
                    },
                  }),
                ],
                1
              )
            : _c(
                "div",
                [
                  _c("b-form-checkbox", {
                    attrs: { disabled: _vm.constitutiva.disabled },
                    model: {
                      value: _vm.constitutiva.capital_extranjero,
                      callback: function ($$v) {
                        _vm.$set(_vm.constitutiva, "capital_extranjero", $$v)
                      },
                      expression: "constitutiva.capital_extranjero",
                    },
                  }),
                ],
                1
              ),
          _vm.constitutiva.capital_extranjero
            ? _c("label", { staticClass: "mt-2 leyenda" }, [
                _vm._v(" La cotización básica no incluye capital extranjero. "),
              ])
            : _vm._e(),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "mt-4" },
        [
          _c("label", { attrs: { for: "" } }, [_vm._v("Cuadro Accionario")]),
          _vm.constitutiva.roleStaff
            ? _c(
                "b-button",
                {
                  staticClass: "btn-fill spaceL",
                  attrs: {
                    disabled: _vm.constitutiva.disabledCapital,
                    variant: "success",
                    size: "sm",
                  },
                  on: { click: _vm.addAccionista },
                },
                [_vm._v("+")]
              )
            : _vm._e(),
        ],
        1
      ),
      _c("div", { staticClass: "mt-4" }, [
        _c("label", { attrs: { for: "" } }, [
          _vm._v("Seleccionar Comparecientes"),
        ]),
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.selectedCompareciente,
                expression: "selectedCompareciente",
              },
            ],
            staticClass: "custom-select",
            attrs: { disabled: _vm.constitutiva.disabled },
            on: {
              change: [
                function ($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function (o) {
                      return o.selected
                    })
                    .map(function (o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.selectedCompareciente = $event.target.multiple
                    ? $$selectedVal
                    : $$selectedVal[0]
                },
                _vm.selectCompareciente,
              ],
            },
          },
          _vm._l(_vm.options_comparecientes, function (option) {
            return _c(
              "option",
              { key: option.value, domProps: { value: option.value } },
              [_vm._v(" " + _vm._s(option.text) + " ")]
            )
          }),
          0
        ),
      ]),
      _c(
        "div",
        [
          _c("b-table", {
            attrs: {
              hover: "",
              fields: _vm.fieldsCuadro,
              items: _vm.constitutiva.accionistas,
            },
            scopedSlots: _vm._u([
              {
                key: "cell(acciones)",
                fn: function ({ item }) {
                  return [
                    _c(
                      "b-button",
                      {
                        directives: [
                          {
                            name: "b-tooltip",
                            rawName: "v-b-tooltip.hover",
                            modifiers: { hover: true },
                          },
                        ],
                        staticClass: "editBtn",
                        attrs: {
                          disabled: _vm.constitutiva.disabledCapital,
                          variant: "warning",
                          size: "sm",
                          title: "Editar",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.editAccionista(item)
                          },
                        },
                      },
                      [_c("b-icon-pencil")],
                      1
                    ),
                    _vm.constitutiva.roleStaff
                      ? _c(
                          "b-button",
                          {
                            directives: [
                              {
                                name: "b-tooltip",
                                rawName: "v-b-tooltip.hover",
                                modifiers: { hover: true },
                              },
                            ],
                            attrs: {
                              disabled: _vm.constitutiva.disabledCapital,
                              variant: "danger",
                              size: "sm",
                              title: "Eliminar",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.predeleteAccionista(item.index)
                              },
                            },
                          },
                          [_c("b-icon-trash")],
                          1
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm.totalPorcentajes != null
            ? _c("label", { attrs: { for: "" } }, [
                _vm._v("Total " + _vm._s(_vm.totalPorcentajes) + " %"),
              ])
            : _vm._e(),
          _c("p"),
          _c("ValidationProvider", {
            attrs: { name: "porcentaje_total", rules: "cien" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ errors }) {
                  return [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.totalPorcentajes,
                          expression: "totalPorcentajes",
                        },
                      ],
                      attrs: { type: "text", hidden: "" },
                      domProps: { value: _vm.totalPorcentajes },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.totalPorcentajes = $event.target.value
                        },
                      },
                    }),
                    _c("span", { staticClass: "cardErrors" }, [
                      _vm._v(_vm._s(errors[0])),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm.constitutiva.accionistas.length > 2
        ? _c("label", { staticClass: "mt-2 leyenda" }, [
            _vm._v(
              " La cotización básica incluye solo 2 accionistas personas físicas. "
            ),
          ])
        : _vm._e(),
      _vm.constitutiva.accionistas.length < 2
        ? _c("label", { staticClass: "mt-2 leyenda" }, [
            _vm._v(" Se deben agregar mínimo 2 accionistas. "),
          ])
        : _vm._e(),
      _vm.constitutiva.accionistas.length < 2
        ? _c("div", { staticClass: "mt-4" }, [
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-10" },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "btn-fill orange-btn bottom2",
                      attrs: { type: "submit", size: "m", disabled: "" },
                    },
                    [_vm._v("Guardar")]
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "col-2" },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "btn-fill bottom2",
                      attrs: { variant: "success", size: "m", disabled: "" },
                    },
                    [_vm._v("Validar")]
                  ),
                ],
                1
              ),
            ]),
          ])
        : _c("div", { staticClass: "mt-4" }, [
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-10" },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "btn-fill orange-btn bottom2",
                      attrs: {
                        type: "submit",
                        size: "m",
                        disabled: _vm.constitutiva.disabledCapital,
                      },
                    },
                    [_vm._v("Guardar")]
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "col-2" },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "btn-fill bottom2",
                      attrs: {
                        variant: "success",
                        size: "m",
                        disabled: _vm.constitutiva.disabledCapital,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.validar()
                        },
                      },
                    },
                    [_vm._v("Validar")]
                  ),
                ],
                1
              ),
            ]),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }