var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content" },
    [
      _c("div", { staticClass: "container" }, [
        _vm.loading
          ? _c("div", [
              _c(
                "div",
                { attrs: { id: "loader" } },
                [
                  _c("font-awesome-icon", {
                    attrs: { icon: "spinner", spin: "" },
                  }),
                ],
                1
              ),
            ])
          : _vm._e(),
      ]),
      _vm.rol === "Cliente"
        ? _c(
            "b-breadcrumb",
            [
              _c(
                "b-breadcrumb-item",
                { attrs: { to: { path: "/dashboard/comparecientes" } } },
                [_vm._v("Comparecientes")]
              ),
              _c("b-breadcrumb-item", { attrs: { active: "" } }, [
                _vm._v("Editar compareciente"),
              ]),
            ],
            1
          )
        : _vm._e(),
      _vm.rol === "Staff" && _vm.corredurias_id === 1
        ? _c(
            "b-breadcrumb",
            [
              _c(
                "b-breadcrumb-item",
                { attrs: { to: { path: "/dashboard/comparecientesStf" } } },
                [_vm._v("Comparecientes")]
              ),
              _c("b-breadcrumb-item", { attrs: { active: "" } }, [
                _vm._v("Editar compareciente"),
              ]),
            ],
            1
          )
        : _vm._e(),
      _c(
        "b-modal",
        {
          ref: "modal-confirm-dialog-dom",
          attrs: {
            "no-close-on-backdrop": "",
            centered: "",
            "hide-footer": "",
            id: "modal-confirm-dialog-dom",
          },
        },
        [
          _c("h4", { staticClass: "text-center" }, [
            _vm._v("¿Seguro que quiere eliminar el domicilio?"),
          ]),
          _c("h5", { staticClass: "text-center" }, [
            _vm._v("Se eliminarán todos los datos."),
          ]),
          _c(
            "b-button",
            {
              staticClass: "mt-3",
              attrs: { variant: "danger" },
              on: {
                click: function ($event) {
                  return _vm.$bvModal.hide("modal-confirm-dialog-dom")
                },
              },
            },
            [_vm._v("Cancelar")]
          ),
          _c(
            "b-button",
            {
              staticClass: "mt-3 float-right",
              attrs: { variant: "primary" },
              on: {
                click: function ($event) {
                  return _vm.deleteDomicilio()
                },
              },
            },
            [_vm._v("OK")]
          ),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          ref: "modal-confirm-dialog-tel",
          attrs: {
            "no-close-on-backdrop": "",
            centered: "",
            "hide-footer": "",
            id: "modal-confirm-dialog-tel",
          },
        },
        [
          _c("h4", { staticClass: "text-center" }, [
            _vm._v("¿Seguro que quiere eliminar el teléfono?"),
          ]),
          _c("h5", { staticClass: "text-center" }, [
            _vm._v("Se eliminarán todos los datos."),
          ]),
          _c(
            "b-button",
            {
              staticClass: "mt-3",
              attrs: { variant: "danger" },
              on: {
                click: function ($event) {
                  return _vm.$bvModal.hide("modal-confirm-dialog-tel")
                },
              },
            },
            [_vm._v("Cancelar")]
          ),
          _c(
            "b-button",
            {
              staticClass: "mt-3 float-right",
              attrs: { variant: "primary" },
              on: {
                click: function ($event) {
                  return _vm.deleteTelefono()
                },
              },
            },
            [_vm._v("OK")]
          ),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          ref: "modal-confirm-dialog-repre",
          attrs: {
            "no-close-on-backdrop": "",
            centered: "",
            "hide-footer": "",
            id: "modal-confirm-dialog-repre",
          },
        },
        [
          _c("h4", { staticClass: "text-center" }, [
            _vm._v("¿Seguro que quiere eliminar el representante?"),
          ]),
          _c("h5", { staticClass: "text-center" }, [
            _vm._v("Se eliminarán todos los datos."),
          ]),
          _c(
            "b-button",
            {
              staticClass: "mt-3",
              attrs: { variant: "danger" },
              on: {
                click: function ($event) {
                  return _vm.$bvModal.hide("modal-confirm-dialog-repre")
                },
              },
            },
            [_vm._v("Cancelar")]
          ),
          _c(
            "b-button",
            {
              staticClass: "mt-3 float-right",
              attrs: { variant: "primary" },
              on: {
                click: function ($event) {
                  return _vm.deleteRepresentante()
                },
              },
            },
            [_vm._v("OK")]
          ),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          ref: "modal-confirm-dialog-repre-dom",
          attrs: {
            "no-close-on-backdrop": "",
            centered: "",
            "hide-footer": "",
            id: "modal-confirm-dialog-repre-dom",
          },
        },
        [
          _c("h4", { staticClass: "text-center" }, [
            _vm._v("¿Seguro que quiere eliminar el domicilio?"),
          ]),
          _c("h5", { staticClass: "text-center" }, [
            _vm._v("Se eliminarán todos los datos."),
          ]),
          _c(
            "b-button",
            {
              staticClass: "mt-3",
              attrs: { variant: "danger" },
              on: {
                click: function ($event) {
                  return _vm.$bvModal.hide("modal-confirm-dialog-repre-dom")
                },
              },
            },
            [_vm._v("Cancelar")]
          ),
          _c(
            "b-button",
            {
              staticClass: "mt-3 float-right",
              attrs: { variant: "primary" },
              on: {
                click: function ($event) {
                  return _vm.deleteDomicilioRepresentante()
                },
              },
            },
            [_vm._v("OK")]
          ),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          ref: "modal-confirm-dialog-repre-tel",
          attrs: {
            "no-close-on-backdrop": "",
            centered: "",
            "hide-footer": "",
            id: "modal-confirm-dialog-repre-tel",
          },
        },
        [
          _c("h4", { staticClass: "text-center" }, [
            _vm._v("¿Seguro que quiere eliminar el teléfono?"),
          ]),
          _c("h5", { staticClass: "text-center" }, [
            _vm._v("Se eliminarán todos los datos."),
          ]),
          _c(
            "b-button",
            {
              staticClass: "mt-3",
              attrs: { variant: "danger" },
              on: {
                click: function ($event) {
                  return _vm.$bvModal.hide("modal-confirm-dialog-repre-tel")
                },
              },
            },
            [_vm._v("Cancelar")]
          ),
          _c(
            "b-button",
            {
              staticClass: "mt-3 float-right",
              attrs: { variant: "primary" },
              on: {
                click: function ($event) {
                  return _vm.deleteTelefonoRepresentante()
                },
              },
            },
            [_vm._v("OK")]
          ),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          ref: "domicilio-modal",
          attrs: {
            "no-close-on-backdrop": "",
            centered: "",
            title: "",
            "hide-footer": "",
            id: "modalD",
            size: "xl",
          },
        },
        [
          _c("ValidationObserver", {
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ handleSubmit }) {
                  return [
                    _c(
                      "form",
                      {
                        on: {
                          submit: function ($event) {
                            $event.preventDefault()
                            return handleSubmit(_vm.postDomicilio)
                          },
                          change: function ($event) {
                            return _vm.getAddressInMapCompar(null)
                          },
                        },
                      },
                      [
                        _c("ModalDomicilio", {
                          attrs: { currentDomicilio: _vm.currentDomicilio },
                          on: {
                            submit: function ($event) {
                              $event.preventDefault()
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          ref: "telefono-modal",
          attrs: {
            "no-close-on-backdrop": "",
            centered: "",
            title: "",
            "hide-footer": "",
            id: "modalT",
            size: "lg",
          },
        },
        [
          _c("ValidationObserver", {
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ handleSubmit }) {
                  return [
                    _c(
                      "form",
                      {
                        on: {
                          submit: function ($event) {
                            $event.preventDefault()
                            return handleSubmit(_vm.postTelefono)
                          },
                        },
                      },
                      [
                        _c("ModalTelefono", {
                          attrs: { currentTelefono: _vm.currentTelefono },
                          on: {
                            submit: function ($event) {
                              $event.preventDefault()
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          ref: "representante-modal",
          attrs: {
            "no-close-on-backdrop": "",
            title: "",
            "hide-footer": "",
            size: "xl",
            id: "modalR",
          },
        },
        [
          _c("ValidationObserver", {
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ handleSubmit }) {
                  return [
                    _c(
                      "form",
                      {
                        on: {
                          submit: function ($event) {
                            $event.preventDefault()
                            return handleSubmit(_vm.postRepresentante)
                          },
                        },
                      },
                      [
                        _c("ModalRepresentante", {
                          attrs: {
                            currentRepresentante: _vm.currentRepresentante,
                          },
                          on: {
                            submit: function ($event) {
                              $event.preventDefault()
                            },
                            state: _vm.mismaDataConstitutiva,
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _c(
            "b-card",
            [
              _c(
                "h4",
                { staticClass: "cardD" },
                [
                  _vm._v(" Domicilio "),
                  _vm.currentRepresentante.domicilios.length === 0
                    ? _c(
                        "b-button",
                        {
                          staticClass: "btn-fill",
                          attrs: { variant: "success", size: "sm" },
                          on: { click: _vm.addDomicilioRepresentante },
                        },
                        [_vm._v("+")]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c("b-table", {
                attrs: {
                  fields: _vm.fieldsDomicilios,
                  items: _vm.currentRepresentante.domicilios,
                  hover: "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "cell(index)",
                    fn: function ({ index }) {
                      return [
                        _vm._v(" " + _vm._s(_vm.getIndex(index + 1)) + " "),
                      ]
                    },
                  },
                  {
                    key: "cell(mapUrl)",
                    fn: function ({ item }) {
                      return [
                        _c("DrawGoogleMap", {
                          staticStyle: { width: "300px" },
                          attrs: {
                            lat: parseFloat(item.lat),
                            lng: parseFloat(item.lng),
                          },
                          on: { location: _vm.updateCoordinates },
                        }),
                      ]
                    },
                  },
                  {
                    key: "cell(acciones)",
                    fn: function ({ item }) {
                      return [
                        _c(
                          "b-button",
                          {
                            directives: [
                              {
                                name: "b-tooltip",
                                rawName: "v-b-tooltip.hover",
                                modifiers: { hover: true },
                              },
                            ],
                            staticClass: "editBtn",
                            attrs: {
                              variant: "warning",
                              size: "sm",
                              title: "Editar",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.editDomicilioRepresentante(item)
                              },
                            },
                          },
                          [_c("b-icon-pencil")],
                          1
                        ),
                        _c(
                          "b-button",
                          {
                            directives: [
                              {
                                name: "b-tooltip",
                                rawName: "v-b-tooltip.hover",
                                modifiers: { hover: true },
                              },
                            ],
                            attrs: {
                              variant: "danger",
                              size: "sm",
                              title: "Eliminar",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.predeleteDomicilioRepresentante(
                                  item.id
                                )
                              },
                            },
                          },
                          [_c("b-icon-trash")],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "b-card",
            [
              _c(
                "h4",
                { staticClass: "cardD" },
                [
                  _vm._v(" Teléfonos "),
                  _c(
                    "b-button",
                    {
                      staticClass: "btn-fill",
                      attrs: { variant: "success", size: "sm" },
                      on: { click: _vm.addTelefonoRepresentante },
                    },
                    [_vm._v("+")]
                  ),
                ],
                1
              ),
              _c("b-table", {
                attrs: {
                  fields: _vm.fieldsTelefonos,
                  items: _vm.currentRepresentante.telefonos,
                  hover: "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "cell(index)",
                    fn: function ({ index }) {
                      return [
                        _vm._v(" " + _vm._s(_vm.getIndex(index + 1)) + " "),
                      ]
                    },
                  },
                  {
                    key: "cell(acciones)",
                    fn: function ({ item }) {
                      return [
                        _c(
                          "b-button",
                          {
                            directives: [
                              {
                                name: "b-tooltip",
                                rawName: "v-b-tooltip.hover",
                                modifiers: { hover: true },
                              },
                            ],
                            staticClass: "editBtn",
                            attrs: {
                              variant: "warning",
                              size: "sm",
                              title: "Editar",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.editTelefonoRepresentante(item)
                              },
                            },
                          },
                          [_c("b-icon-pencil")],
                          1
                        ),
                        _c(
                          "b-button",
                          {
                            directives: [
                              {
                                name: "b-tooltip",
                                rawName: "v-b-tooltip.hover",
                                modifiers: { hover: true },
                              },
                            ],
                            attrs: {
                              variant: "danger",
                              size: "sm",
                              title: "Eliminar",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.predeleteTelefonoRepresentante(
                                  item.id
                                )
                              },
                            },
                          },
                          [_c("b-icon-trash")],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          ref: "domicilio-modal-representante",
          attrs: {
            "no-close-on-backdrop": "",
            centered: "",
            title: "",
            "hide-footer": "",
            id: "modalDR",
            size: "xl",
          },
        },
        [
          _c("ValidationObserver", {
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ handleSubmit }) {
                  return [
                    _c(
                      "form",
                      {
                        on: {
                          submit: function ($event) {
                            $event.preventDefault()
                            return handleSubmit(_vm.postDomicilioRepresentante)
                          },
                          change: function ($event) {
                            return _vm.getAddressInMapRepre(null)
                          },
                        },
                      },
                      [
                        _c("ModalDomicilio", {
                          attrs: { currentDomicilio: _vm.currentDomicilio },
                          on: {
                            submit: function ($event) {
                              $event.preventDefault()
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          ref: "telefono-modal-representante",
          attrs: {
            "no-close-on-backdrop": "",
            centered: "",
            title: "",
            "hide-footer": "",
            id: "modalTR",
            size: "lg",
          },
        },
        [
          _c("ValidationObserver", {
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ handleSubmit }) {
                  return [
                    _c(
                      "form",
                      {
                        on: {
                          submit: function ($event) {
                            $event.preventDefault()
                            return handleSubmit(_vm.postTelefonoRepresentante)
                          },
                        },
                      },
                      [
                        _c("ModalTelefono", {
                          attrs: { currentTelefono: _vm.currentTelefono },
                          on: {
                            submit: function ($event) {
                              $event.preventDefault()
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          ref: "modal-confirm-dialog-documento",
          attrs: {
            "no-close-on-backdrop": "",
            centered: "",
            "hide-footer": "",
            id: "modal-confirm-dialog-documento",
          },
        },
        [
          _c("h4", { staticClass: "text-center" }, [
            _vm._v("¿Seguro que quiere eliminar el documento?"),
          ]),
          _c("h5", { staticClass: "text-center" }),
          _c(
            "b-button",
            {
              staticClass: "mt-3",
              attrs: { variant: "danger" },
              on: {
                click: function ($event) {
                  return _vm.$bvModal.hide("modal-confirm-dialog-documento")
                },
              },
            },
            [_vm._v("Cancelar")]
          ),
          _c(
            "b-button",
            {
              staticClass: "mt-3 float-right",
              attrs: { variant: "primary" },
              on: { click: _vm.deleteDocumento },
            },
            [_vm._v("OK")]
          ),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          ref: "documento-modal",
          attrs: {
            "no-close-on-backdrop": "",
            centered: "",
            title: "",
            "hide-footer": "",
            id: "modalC",
            size: "xl",
          },
        },
        [
          _vm.user.persona === "Física"
            ? _c(
                "div",
                [
                  _c("ValidationObserver", {
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function ({ handleSubmit }) {
                            return [
                              _c(
                                "form",
                                {
                                  on: {
                                    submit: function ($event) {
                                      $event.preventDefault()
                                      return handleSubmit(_vm.postDocumento)
                                    },
                                  },
                                },
                                [
                                  _c("ModalDocumentos", {
                                    attrs: {
                                      currentDocumento: _vm.currentDocumento,
                                      options: _vm.optionsDocumentosFisica,
                                    },
                                    on: {
                                      submit: function ($event) {
                                        $event.preventDefault()
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2586598758
                    ),
                  }),
                ],
                1
              )
            : _c(
                "div",
                [
                  _c("ValidationObserver", {
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ handleSubmit }) {
                          return [
                            _c(
                              "form",
                              {
                                on: {
                                  submit: function ($event) {
                                    $event.preventDefault()
                                    return handleSubmit(_vm.postDocumento)
                                  },
                                },
                              },
                              [
                                _c("ModalDocumentos", {
                                  attrs: {
                                    currentDocumento: _vm.currentDocumento,
                                    options: _vm.optionsDocumentosMorales,
                                  },
                                  on: {
                                    submit: function ($event) {
                                      $event.preventDefault()
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
        ]
      ),
      _c(
        "b-modal",
        {
          ref: "documento-modal-preview-img",
          attrs: {
            "no-close-on-backdrop": "",
            centered: "",
            title: "",
            "hide-footer": "",
            id: "modaldi",
            size: "xl",
          },
        },
        [
          _c("ModalPreviewImg", {
            attrs: { currentDocumento: _vm.currentDocumento },
          }),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          ref: "documento-modal-preview-pdf",
          attrs: {
            "no-close-on-backdrop": "",
            centered: "",
            title: "",
            "hide-footer": "",
            id: "modaldf",
            size: "xl",
          },
        },
        [
          _c("ModalPreviewPDF", {
            attrs: { currentDocumento: _vm.currentDocumento },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "container" },
        [
          _c("b-card", { staticClass: "card" }, [
            _c("div", { staticClass: "mb-4 client" }, [
              _vm._v(
                " Compareciente del cliente: " + _vm._s(_vm.user.cliente) + " "
              ),
            ]),
            ![3].includes(_vm.proceso)
              ? _c("div", [
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col-md-6" },
                      [
                        _c("ValidationProvider", {
                          attrs: {
                            name: "persona",
                            rules: "required",
                            "custom-messages": {
                              required: "La persona es obligatoria",
                            },
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function ({ errors }) {
                                  return [
                                    _c("label", { attrs: { for: "" } }, [
                                      _vm._v("Persona"),
                                    ]),
                                    _c(
                                      "select",
                                      {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.user.persona,
                                            expression: "user.persona",
                                          },
                                        ],
                                        staticClass: "custom-select",
                                        attrs: { disabled: _vm.user.disabled },
                                        on: {
                                          change: function ($event) {
                                            var $$selectedVal =
                                              Array.prototype.filter
                                                .call(
                                                  $event.target.options,
                                                  function (o) {
                                                    return o.selected
                                                  }
                                                )
                                                .map(function (o) {
                                                  var val =
                                                    "_value" in o
                                                      ? o._value
                                                      : o.value
                                                  return val
                                                })
                                            _vm.$set(
                                              _vm.user,
                                              "persona",
                                              $event.target.multiple
                                                ? $$selectedVal
                                                : $$selectedVal[0]
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "option",
                                          { attrs: { value: "Física" } },
                                          [_vm._v("Física")]
                                        ),
                                        _c(
                                          "option",
                                          { attrs: { value: "Moral" } },
                                          [_vm._v("Moral")]
                                        ),
                                        _c(
                                          "option",
                                          { attrs: { value: "Fideicomiso" } },
                                          [_vm._v("Fideicomiso")]
                                        ),
                                      ]
                                    ),
                                    _c("span", { staticClass: "cardErrors" }, [
                                      _vm._v(_vm._s(errors[0])),
                                    ]),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            1173260911
                          ),
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("br"),
                ])
              : _vm._e(),
            _vm.user.persona === "Fideicomiso"
              ? _c("h4", { staticClass: "cardD" }, [
                  _vm._v(" Datos de identificación del Fiduciario "),
                ])
              : _c("h4", { staticClass: "cardD" }, [
                  _vm._v("Datos de identificación"),
                ]),
            _vm.user.persona === "Física"
              ? _c(
                  "div",
                  [
                    _c("ValidationObserver", {
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function ({ handleSubmit }) {
                              return [
                                _c(
                                  "form",
                                  {
                                    on: {
                                      submit: function ($event) {
                                        $event.preventDefault()
                                        return handleSubmit(
                                          _vm.editCompareciente
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("FormFisica", {
                                      attrs: {
                                        user: _vm.user,
                                        ratificacion: _vm.ratificacion,
                                        servicio: _vm.proceso,
                                      },
                                      on: {
                                        submit: function ($event) {
                                          $event.preventDefault()
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        4117518551
                      ),
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.user.persona === "Moral"
              ? _c(
                  "div",
                  [
                    _c("ValidationObserver", {
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function ({ handleSubmit }) {
                              return [
                                _c(
                                  "form",
                                  {
                                    on: {
                                      submit: function ($event) {
                                        $event.preventDefault()
                                        return handleSubmit(
                                          _vm.editCompareciente
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("FormMoral", {
                                      attrs: {
                                        user: _vm.user,
                                        ratificacion: _vm.ratificacion,
                                      },
                                      on: {
                                        submit: function ($event) {
                                          $event.preventDefault()
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        110767138
                      ),
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.user.persona === "Fideicomiso"
              ? _c(
                  "div",
                  [
                    _c("ValidationObserver", {
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function ({ handleSubmit }) {
                              return [
                                _c(
                                  "form",
                                  {
                                    on: {
                                      submit: function ($event) {
                                        $event.preventDefault()
                                        return handleSubmit(
                                          _vm.editCompareciente
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("FormFideicomiso", {
                                      attrs: { user: _vm.user },
                                      on: {
                                        submit: function ($event) {
                                          $event.preventDefault()
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        2183647322
                      ),
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ]),
          _vm.proceso !== 4
            ? _c(
                "b-card",
                [
                  _c(
                    "h4",
                    { staticClass: "cardD" },
                    [
                      _vm._v(" Domicilio "),
                      _vm.addDomicilioBtn
                        ? _c(
                            "b-button",
                            {
                              staticClass: "btn-fill",
                              attrs: {
                                variant: "success",
                                size: "sm",
                                disabled: _vm.user.disabled,
                              },
                              on: { click: _vm.addDomicilio },
                            },
                            [_vm._v("+")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c("b-table", {
                    attrs: {
                      fields: _vm.fieldsDomicilios,
                      items: _vm.domicilios,
                      hover: "",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "cell(index)",
                          fn: function ({ index }) {
                            return [
                              _vm._v(
                                " " + _vm._s(_vm.getIndex(index + 1)) + " "
                              ),
                            ]
                          },
                        },
                        {
                          key: "cell(mapUrl)",
                          fn: function ({ item }) {
                            return [
                              _c("DrawGoogleMap", {
                                staticStyle: { width: "300px" },
                                attrs: {
                                  lat: parseFloat(item.lat),
                                  lng: parseFloat(item.lng),
                                },
                                on: { location: _vm.updateCoordinates },
                              }),
                            ]
                          },
                        },
                        {
                          key: "cell(acciones)",
                          fn: function ({ item }) {
                            return [
                              _c(
                                "b-button",
                                {
                                  directives: [
                                    {
                                      name: "b-tooltip",
                                      rawName: "v-b-tooltip.hover",
                                      modifiers: { hover: true },
                                    },
                                  ],
                                  staticClass: "editBtn",
                                  attrs: {
                                    variant: "warning",
                                    size: "sm",
                                    title: "Editar",
                                    disabled: _vm.user.disabled,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.editDomicilio(item)
                                    },
                                  },
                                },
                                [_c("b-icon-pencil")],
                                1
                              ),
                              _c(
                                "b-button",
                                {
                                  directives: [
                                    {
                                      name: "b-tooltip",
                                      rawName: "v-b-tooltip.hover",
                                      modifiers: { hover: true },
                                    },
                                  ],
                                  attrs: {
                                    variant: "danger",
                                    disabled: _vm.user.disabled,
                                    size: "sm",
                                    title: "Eliminar",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.predeleteDomicilio(item.id)
                                    },
                                  },
                                },
                                [_c("b-icon-trash")],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3685294411
                    ),
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "b-card",
            [
              _c("h4", { staticClass: "cardD" }, [_vm._v("Datos de contacto")]),
              _c("span", { staticClass: "cardErrors mb-2" }, [
                _vm._v(
                  " Favor de ingresar un correo electrónico válido así como un número telefónico de celular ya que serán necesarios para el proceso de Firma Digital. "
                ),
              ]),
              _c(
                "div",
                [
                  _c("ValidationProvider", {
                    attrs: {
                      name: "email",
                      rules: "required|email",
                      "custom-messages": {
                        required: "El email es obligatorio",
                        email: "Debe ser un email válido",
                      },
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ errors }) {
                          return [
                            _c("base-input", {
                              attrs: {
                                disabled: _vm.user.disabled,
                                type: "email",
                                label: "Email",
                                placeholder: "Email",
                              },
                              model: {
                                value: _vm.user.email,
                                callback: function ($$v) {
                                  _vm.$set(_vm.user, "email", $$v)
                                },
                                expression: "user.email",
                              },
                            }),
                            _c("span", { staticClass: "cardErrors" }, [
                              _vm._v(_vm._s(errors[0])),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c(
                "h4",
                { staticClass: "cardD" },
                [
                  _vm._v(" Teléfonos "),
                  _c(
                    "b-button",
                    {
                      staticClass: "btn-fill",
                      attrs: {
                        variant: "success",
                        size: "sm",
                        disabled: _vm.user.disabled,
                      },
                      on: { click: _vm.addTelefono },
                    },
                    [_vm._v("+")]
                  ),
                ],
                1
              ),
              _c("b-table", {
                attrs: {
                  fields: _vm.fieldsTelefonos,
                  items: _vm.telefonos,
                  hover: "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "cell(index)",
                    fn: function ({ index }) {
                      return [
                        _vm._v(" " + _vm._s(_vm.getIndex(index + 1)) + " "),
                      ]
                    },
                  },
                  {
                    key: "cell(acciones)",
                    fn: function ({ item }) {
                      return [
                        _c(
                          "b-button",
                          {
                            directives: [
                              {
                                name: "b-tooltip",
                                rawName: "v-b-tooltip.hover",
                                modifiers: { hover: true },
                              },
                            ],
                            staticClass: "editBtn",
                            attrs: {
                              variant: "warning",
                              size: "sm",
                              title: "Editar",
                              disabled: _vm.user.disabled,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.editTelefono(item)
                              },
                            },
                          },
                          [_c("b-icon-pencil")],
                          1
                        ),
                        _c(
                          "b-button",
                          {
                            directives: [
                              {
                                name: "b-tooltip",
                                rawName: "v-b-tooltip.hover",
                                modifiers: { hover: true },
                              },
                            ],
                            attrs: {
                              variant: "danger",
                              disabled: _vm.user.disabled,
                              size: "sm",
                              title: "Eliminar",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.predeleteTelefono(item.id)
                              },
                            },
                          },
                          [_c("b-icon-trash")],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm.proceso !== 4
            ? _c(
                "b-card",
                [
                  _vm.user.persona === "Física"
                    ? _c(
                        "div",
                        [
                          _c(
                            "h4",
                            { staticClass: "cardD" },
                            [
                              _vm._v(" Documentos "),
                              _c(
                                "b-button",
                                {
                                  staticClass: "btn-fill",
                                  attrs: {
                                    variant: "success",
                                    size: "sm",
                                    disabled: _vm.user.disabled,
                                  },
                                  on: { click: _vm.addDocumento },
                                },
                                [_vm._v("+")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-button",
                            {
                              staticStyle: { "margin-left": "10px !important" },
                              attrs: {
                                id: "popover-3",
                                variant: "primary",
                                size: "sm",
                              },
                            },
                            [_vm._v(" ? ")]
                          ),
                          _c(
                            "b-popover",
                            {
                              attrs: {
                                target: "popover-3",
                                triggers: "hover",
                                placement: "top",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "title",
                                    fn: function () {
                                      return [_vm._v("Obligatorios")]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                false,
                                2909930991
                              ),
                            },
                            [
                              _c("ul", [
                                _c("li", [_vm._v("Identificación Oficial")]),
                                _c("li", [_vm._v("Comprobante de domicilio")]),
                                _c("li", [_vm._v("RFC o CIF")]),
                                _c("li", [_vm._v("CURP")]),
                              ]),
                            ]
                          ),
                        ],
                        1
                      )
                    : _c("div", [
                        _c(
                          "h4",
                          { staticClass: "cardD" },
                          [
                            _vm._v(" Documentos "),
                            _c(
                              "b-button",
                              {
                                staticClass: "btn-fill",
                                attrs: {
                                  variant: "success",
                                  size: "sm",
                                  disabled: _vm.user.disabled,
                                },
                                on: { click: _vm.addDocumento },
                              },
                              [_vm._v("+")]
                            ),
                          ],
                          1
                        ),
                      ]),
                  _c("b-table", {
                    attrs: {
                      hover: "",
                      fields: _vm.fieldsDocus,
                      items: _vm.user.documentos,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "cell(tipo)",
                          fn: function ({ item }) {
                            return [
                              item.tipo === "Otro"
                                ? _c("div", [
                                    _c(
                                      "div",
                                      {
                                        directives: [
                                          {
                                            name: "b-tooltip",
                                            rawName: "v-b-tooltip.hover",
                                            modifiers: { hover: true },
                                          },
                                        ],
                                        attrs: { title: item.descripcion },
                                      },
                                      [_vm._v(" " + _vm._s(item.tipo) + " ")]
                                    ),
                                  ])
                                : _c("div", [
                                    _vm._v(" " + _vm._s(item.tipo) + " "),
                                  ]),
                            ]
                          },
                        },
                        {
                          key: "cell(acciones)",
                          fn: function ({ item }) {
                            return [
                              _c(
                                "b-button",
                                {
                                  directives: [
                                    {
                                      name: "b-tooltip",
                                      rawName: "v-b-tooltip.hover",
                                      modifiers: { hover: true },
                                    },
                                  ],
                                  staticClass: "editBtn",
                                  attrs: {
                                    variant: "warning",
                                    size: "sm",
                                    title: "Editar",
                                    disabled: _vm.user.disabled,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.editDocumento(item)
                                    },
                                  },
                                },
                                [_c("b-icon-pencil")],
                                1
                              ),
                              _c(
                                "b-button",
                                {
                                  directives: [
                                    {
                                      name: "b-tooltip",
                                      rawName: "v-b-tooltip.hover",
                                      modifiers: { hover: true },
                                    },
                                  ],
                                  staticClass: "editBtn",
                                  attrs: {
                                    variant: "primary",
                                    size: "sm",
                                    title: "Vista previa",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.previewDocumento(item)
                                    },
                                  },
                                },
                                [_c("b-icon-eye")],
                                1
                              ),
                              _c(
                                "b-button",
                                {
                                  directives: [
                                    {
                                      name: "b-tooltip",
                                      rawName: "v-b-tooltip.hover",
                                      modifiers: { hover: true },
                                    },
                                  ],
                                  staticClass: "editBtn",
                                  attrs: {
                                    variant: "danger",
                                    size: "sm",
                                    title: "Eliminar",
                                    disabled: _vm.user.disabled,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.predeleteDocumento(item.id)
                                    },
                                  },
                                },
                                [_c("b-icon-trash")],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      4086020565
                    ),
                  }),
                ],
                1
              )
            : _vm._e(),
          _c("b-card", [
            _vm.user.persona === "Física"
              ? _c(
                  "div",
                  [
                    _c(
                      "b-form-group",
                      {
                        attrs: {
                          id: "input-group-actua-derecho-propio",
                          label: "Actúa por derecho propio:",
                          "label-for": "input-actua-derecho-propio",
                        },
                      },
                      [
                        _c("b-form-checkbox", {
                          attrs: { id: "input-actua-derecho-propio" },
                          model: {
                            value: _vm.user.actua_derecho_propio,
                            callback: function ($$v) {
                              _vm.$set(_vm.user, "actua_derecho_propio", $$v)
                            },
                            expression: "user.actua_derecho_propio",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            !_vm.user.actua_derecho_propio
              ? _c("div", [
                  _c(
                    "h4",
                    { staticClass: "cardD" },
                    [
                      _vm._v(" Apoderados o Representantes Legales "),
                      _c(
                        "b-button",
                        {
                          staticClass: "btn-fill",
                          attrs: {
                            variant: "success",
                            size: "sm",
                            disabled: _vm.user.disabled,
                          },
                          on: { click: _vm.addRepresentante },
                        },
                        [_vm._v("+")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "text-center" },
                    [
                      _c("b-table", {
                        attrs: {
                          fields: _vm.fields,
                          items: _vm.representantes,
                          hover: "",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "cell(index)",
                              fn: function ({ index }) {
                                return [
                                  _vm._v(
                                    " " + _vm._s(_vm.getIndex(index + 1)) + " "
                                  ),
                                ]
                              },
                            },
                            {
                              key: "cell(acciones)",
                              fn: function ({ item }) {
                                return [
                                  _c(
                                    "b-button",
                                    {
                                      directives: [
                                        {
                                          name: "b-tooltip",
                                          rawName: "v-b-tooltip.hover",
                                          modifiers: { hover: true },
                                        },
                                      ],
                                      staticClass: "editBtn",
                                      attrs: {
                                        variant: "warning",
                                        size: "sm",
                                        title: "Editar",
                                        disabled: _vm.user.disabled,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.editRepresentante(item)
                                        },
                                      },
                                    },
                                    [_c("b-icon-pencil")],
                                    1
                                  ),
                                  _c(
                                    "b-button",
                                    {
                                      directives: [
                                        {
                                          name: "b-tooltip",
                                          rawName: "v-b-tooltip.hover",
                                          modifiers: { hover: true },
                                        },
                                      ],
                                      attrs: {
                                        variant: "danger",
                                        size: "sm",
                                        title: "Eliminar",
                                        disabled: _vm.user.disabled,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.predeleteRepresentantes(
                                            item.id
                                          )
                                        },
                                      },
                                    },
                                    [_c("b-icon-trash")],
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1972168407
                        ),
                      }),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
          ]),
          _vm.proceso !== 4 && this.user.persona === "Física"
            ? _c(
                "b-card",
                [
                  _c("h4", { staticClass: "cardD" }, [
                    _vm._v("Redes Sociales"),
                  ]),
                  _c("RedesSociales", { attrs: { user: _vm.user } }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }