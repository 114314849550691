var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container", attrs: { id: "container-montos" } },
    [
      _c("h4", { staticClass: "cardD" }, [_vm._v("Montos")]),
      _c("div", { staticClass: "row mt-4" }, [
        _c(
          "div",
          { staticClass: "col-md-12" },
          [
            _c("ValidationProvider", {
              attrs: {
                name: "monto_global",
                rules: "required",
                "custom-messages": {
                  required: "El monto es obligatorio",
                },
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ errors }) {
                    return [
                      _c("label", { attrs: { for: "" } }, [
                        _vm._v(
                          "Monto Global de la Operación (Incluye todos los documentos)"
                        ),
                      ]),
                      _c("base-input", {
                        staticClass: "custom-number",
                        attrs: {
                          type: "number",
                          required: "",
                          placeholder: "0",
                          min: 0,
                          max: 75000000,
                        },
                        model: {
                          value: _vm.ratificacion.monto_global,
                          callback: function ($$v) {
                            _vm.$set(_vm.ratificacion, "monto_global", $$v)
                          },
                          expression: "ratificacion.monto_global",
                        },
                      }),
                      _c("span", { staticClass: "cardErrors" }, [
                        _vm._v(_vm._s(errors[0])),
                      ]),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "row mt-6" }, [
        _c(
          "div",
          { staticClass: "col-md-12" },
          [
            _c("ValidationProvider", {
              attrs: {
                name: "clausula_adeudo",
                rules: "required",
                "custom-messages": {
                  required: "La selección de cláusula de adeudo es obligatoria",
                },
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ errors }) {
                    return [
                      _c(
                        "b-form-checkbox",
                        {
                          attrs: { id: "chkClausulaAdeudo" },
                          model: {
                            value: _vm.clausula_adeudo,
                            callback: function ($$v) {
                              _vm.clausula_adeudo = $$v
                            },
                            expression: "clausula_adeudo",
                          },
                        },
                        [
                          _c("label", { attrs: { for: "chkClausulaAdeudo" } }, [
                            _vm._v(
                              "Hay cláusula expresa de reconocimiento de adeudo"
                            ),
                          ]),
                        ]
                      ),
                      _c("span", { staticClass: "cardErrors" }, [
                        _vm._v(_vm._s(errors[0])),
                      ]),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
      ]),
      _vm.clausula_adeudo
        ? _c("div", { staticClass: "row mt-6" }, [
            _c(
              "div",
              { staticClass: "col-md-12" },
              [
                _c("ValidationProvider", {
                  attrs: {
                    name: "adeudo",
                    rules: "required",
                    "custom-messages": {
                      required: "El monto es obligatorio",
                    },
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function ({ errors }) {
                          return [
                            _c("label", { attrs: { for: "" } }, [
                              _vm._v("Monto de adeudo"),
                            ]),
                            _c("base-input", {
                              staticClass: "custom-number",
                              attrs: {
                                type: "number",
                                required: "",
                                placeholder: "1",
                                min: 1,
                              },
                              model: {
                                value: _vm.ratificacion.adeudo,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ratificacion, "adeudo", $$v)
                                },
                                expression: "ratificacion.adeudo",
                              },
                            }),
                            _c("span", { staticClass: "cardErrors" }, [
                              _vm._v(_vm._s(errors[0])),
                            ]),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    1221431276
                  ),
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
      _c("div", { staticClass: "row mt-6" }, [
        _c(
          "div",
          { staticClass: "col-md-12" },
          [
            _c("ValidationProvider", {
              attrs: {
                name: "inscripcion_rug",
                rules: "required",
                "custom-messages": {
                  required: "La selección de inscripción es obligatoria",
                },
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ errors }) {
                    return [
                      _c(
                        "b-form-checkbox",
                        {
                          attrs: { id: "chkInscripcionRug" },
                          model: {
                            value: _vm.inscripcion_rug,
                            callback: function ($$v) {
                              _vm.inscripcion_rug = $$v
                            },
                            expression: "inscripcion_rug",
                          },
                        },
                        [
                          _c("label", { attrs: { for: "chkInscripcionRug" } }, [
                            _vm._v("Se inscribe en el RUG"),
                          ]),
                        ]
                      ),
                      _c("span", { staticClass: "cardErrors" }, [
                        _vm._v(_vm._s(errors[0])),
                      ]),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
      ]),
      _vm.inscripcion_rug
        ? _c("div", { staticClass: "row mt-6" }, [
            _c(
              "div",
              { staticClass: "col-md-12" },
              [
                _c("ValidationProvider", {
                  attrs: {
                    name: "monto_garantia",
                    rules: "required",
                    "custom-messages": {
                      required: "El monto es obligatorio",
                    },
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function ({ errors }) {
                          return [
                            _c("label", { attrs: { for: "" } }, [
                              _vm._v("Monto de Garantía"),
                            ]),
                            _c("base-input", {
                              staticClass: "custom-number",
                              attrs: {
                                type: "number",
                                required: "",
                                placeholder: "1",
                                min: 1,
                              },
                              model: {
                                value: _vm.ratificacion.monto_garantia,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.ratificacion,
                                    "monto_garantia",
                                    $$v
                                  )
                                },
                                expression: "ratificacion.monto_garantia",
                              },
                            }),
                            _c("span", { staticClass: "cardErrors" }, [
                              _vm._v(_vm._s(errors[0])),
                            ]),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    1643246656
                  ),
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
      _c("div", { staticClass: "mt-4" }, [
        _c(
          "div",
          {},
          [
            _c(
              "b-button",
              {
                staticClass: "btn-fill orange-btn bottom2",
                attrs: { size: "m" },
                on: {
                  click: function ($event) {
                    return _vm.checkMontos()
                  },
                },
              },
              [_vm._v("Continuar")]
            ),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }