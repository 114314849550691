var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c(
        "b-modal",
        {
          ref: "entrega-documento-modal",
          attrs: {
            "no-close-on-backdrop": "",
            centered: "",
            title: "",
            "hide-footer": "",
            id: "modalC",
            size: "xl",
          },
        },
        [
          _c(
            "div",
            [
              _c("ValidationObserver", {
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ handleSubmit }) {
                      return [
                        _c(
                          "form",
                          {
                            on: {
                              submit: function ($event) {
                                $event.preventDefault()
                                return handleSubmit(_vm.postDocumento)
                              },
                            },
                          },
                          [
                            _c("ModalEntregaDocumento", {
                              attrs: {
                                currentDocumento: _vm.currentDocumento,
                                notificacion: _vm.notificacion,
                              },
                              on: {
                                submit: function ($event) {
                                  $event.preventDefault()
                                },
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ]
      ),
      _c(
        "b-modal",
        {
          ref: "documento-modal-preview-img",
          attrs: {
            "no-close-on-backdrop": "",
            centered: "",
            title: "",
            "hide-footer": "",
            id: "modaldi",
            size: "xl",
          },
        },
        [
          _c("ModalPreviewImg", {
            attrs: { currentDocumento: _vm.currentDocumento },
          }),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          ref: "documento-modal-preview-pdf",
          attrs: {
            "no-close-on-backdrop": "",
            centered: "",
            title: "",
            "hide-footer": "",
            id: "modaldf",
            size: "xl",
          },
        },
        [
          _c("ModalPreviewPDF", {
            attrs: { currentDocumento: _vm.currentDocumento },
          }),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          ref: "documento-modal-preview-word",
          attrs: {
            "no-close-on-backdrop": "",
            centered: "",
            title: "",
            "hide-footer": "",
            id: "modaldd",
            size: "xl",
          },
        },
        [
          _c("ModalPreviewDocx", {
            attrs: { currentDocumento: _vm.currentDocumento },
          }),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          ref: "modal-confirm-regenerate",
          attrs: {
            "no-close-on-backdrop": "",
            centered: "",
            "hide-footer": "",
            id: "modal-confirm-regenerate",
          },
        },
        [
          _c("h4", { staticClass: "text-center" }, [
            _vm._v(
              " ¿Está seguro de regenerar el Acta de Diligencia? El Acta existente será eliminada. "
            ),
          ]),
          _c("h5", { staticClass: "text-center" }),
          _c(
            "b-button",
            {
              staticClass: "mt-3",
              attrs: { variant: "danger" },
              on: {
                click: function ($event) {
                  return _vm.$bvModal.hide("modal-confirm-regenerate")
                },
              },
            },
            [_vm._v("Cancelar")]
          ),
          _c(
            "b-button",
            {
              staticClass: "mt-3 float-right",
              attrs: { variant: "primary" },
              on: {
                click: function ($event) {
                  return _vm.regenerarCartaInstruccion()
                },
              },
            },
            [_vm._v("OK")]
          ),
        ],
        1
      ),
      _c("h4", { staticClass: "cardD" }, [_vm._v("Actas")]),
      _c(
        "div",
        [
          _c("b-table", {
            ref: "table",
            attrs: {
              hover: "",
              fields: _vm.fieldsDocumentos,
              items: _vm.notificacion.documentos,
            },
            scopedSlots: _vm._u([
              {
                key: "cell(tipo)",
                fn: function ({ item }) {
                  return [
                    item.tipo === "Otro"
                      ? _c("div", [
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "b-tooltip",
                                  rawName: "v-b-tooltip.hover",
                                  modifiers: { hover: true },
                                },
                              ],
                              attrs: { title: item.descripcion },
                            },
                            [_vm._v(" " + _vm._s(item.tipo) + " ")]
                          ),
                        ])
                      : _c("div", [_vm._v(" " + _vm._s(item.tipo) + " ")]),
                  ]
                },
              },
              {
                key: "cell(acciones)",
                fn: function ({ item }) {
                  return [
                    _vm.isDocumentoActaGenerada(item) ||
                    _vm.DocumentType(item) == "word"
                      ? _c(
                          "b-button",
                          {
                            directives: [
                              {
                                name: "b-tooltip",
                                rawName: "v-b-tooltip.hover",
                                modifiers: { hover: true },
                              },
                            ],
                            staticClass: "editBtn",
                            attrs: {
                              variant: "success",
                              size: "sm",
                              title: "Descarga documento",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.descargaFile(item.documento)
                              },
                            },
                          },
                          [_c("i", { staticClass: "fa fa-download" })]
                        )
                      : _vm._e(),
                    !_vm.isDocumentoActaGenerada(item) &&
                    _vm.DocumentType(item) != "word"
                      ? _c(
                          "b-button",
                          {
                            directives: [
                              {
                                name: "b-tooltip",
                                rawName: "v-b-tooltip.hover",
                                modifiers: { hover: true },
                              },
                            ],
                            staticClass: "editBtn",
                            attrs: {
                              variant: "primary",
                              size: "sm",
                              title: "Vista previa",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.previewDocumento(item)
                              },
                            },
                          },
                          [_c("b-icon-eye")],
                          1
                        )
                      : _vm._e(),
                    !_vm.isDocumentoActaGenerada(item) &&
                    ["11", "58"].includes(_vm.notificacion.id_estatus)
                      ? _c(
                          "b-button",
                          {
                            directives: [
                              {
                                name: "b-tooltip",
                                rawName: "v-b-tooltip.hover",
                                modifiers: { hover: true },
                              },
                            ],
                            staticClass: "editBtn",
                            attrs: {
                              variant: "warning",
                              size: "sm",
                              title: "Editar",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.openEditDocumentoModal(item)
                              },
                            },
                          },
                          [_c("b-icon-upload")],
                          1
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("div", { staticClass: "mt-4" }, [
        _c(
          "div",
          {},
          [
            _c(
              "b-button",
              {
                staticClass: "btn-fill green-btn bottom1",
                attrs: {
                  size: "m",
                  disabled: _vm.notificacion.id_estatus == 13,
                },
                on: {
                  click: function ($event) {
                    return _vm.openModalRegenerate()
                  },
                },
              },
              [_vm._v("Regenerar Acta Diligencia")]
            ),
          ],
          1
        ),
        _c(
          "div",
          {},
          [
            _c(
              "b-button",
              {
                staticClass: "btn-fill orange-btn bottom2",
                attrs: {
                  type: "submit",
                  size: "m",
                  disabled:
                    _vm.notificacion.id_estatus != "11" &&
                    _vm.notificacion.id_estatus != "58",
                },
              },
              [_vm._v("Continuar")]
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }