var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c(
        "b-modal",
        {
          ref: "documento-modal-preview",
          attrs: {
            "no-close-on-backdrop": "",
            centered: "",
            title: "",
            "hide-footer": "",
            id: "modaldf",
            size: "xl",
          },
        },
        [
          _c("ModalPreviewPDF", {
            attrs: { currentDocumento: _vm.currentDocumento },
          }),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          ref: "modal-regenerate",
          attrs: {
            "no-close-on-backdrop": "",
            centered: "",
            "hide-footer": "",
            id: "modal-regenerate",
          },
        },
        [
          _c("h4", { staticClass: "text-center" }, [
            _vm._v(
              " ¿Está seguro de regenerar los KYCs? Los existentes serán eliminados. "
            ),
          ]),
          _c("h5", { staticClass: "text-center" }),
          _c(
            "b-button",
            {
              staticClass: "mt-3",
              attrs: { variant: "danger" },
              on: {
                click: function ($event) {
                  return _vm.$bvModal.hide("modal-regenerate")
                },
              },
            },
            [_vm._v("Cancelar")]
          ),
          _c(
            "b-button",
            {
              staticClass: "mt-3 float-right",
              attrs: { variant: "primary" },
              on: {
                click: function ($event) {
                  return _vm.generaKycs()
                },
              },
            },
            [_vm._v("OK")]
          ),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          ref: "modal-confirm-fad",
          attrs: {
            "no-close-on-backdrop": "",
            centered: "",
            "hide-footer": "",
            id: "modal-confirm-fad",
          },
        },
        [
          _c("h4", { staticClass: "text-center" }, [
            _vm._v(
              " ¿Está seguro de enviar a FAD? El cliente será notificado. "
            ),
          ]),
          _c("h5", { staticClass: "text-center" }),
          _c(
            "b-button",
            {
              staticClass: "mt-3",
              attrs: { variant: "danger" },
              on: {
                click: function ($event) {
                  return _vm.$bvModal.hide("modal-confirm-fad")
                },
              },
            },
            [_vm._v("Cancelar")]
          ),
          _c(
            "b-button",
            {
              staticClass: "mt-3 float-right",
              attrs: { variant: "primary" },
              on: {
                click: function ($event) {
                  return _vm.sendToFad()
                },
              },
            },
            [_vm._v("OK")]
          ),
        ],
        1
      ),
      _c("h4", { staticClass: "cardD" }, [_vm._v("FAD Acta")]),
      _c("div", { staticClass: "mt-4" }, [
        _c(
          "div",
          {},
          [
            _c(
              "b-button",
              {
                staticClass: "btn-fill orange-btn bottom2",
                attrs: {
                  size: "m",
                  disabled: parseInt(_vm.ratificacion.estatus_id) != 43,
                },
                on: {
                  click: function ($event) {
                    return _vm.openSendToFadModal()
                  },
                },
              },
              [_vm._v(" Enviar a FAD ")]
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }