var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container" }, [
    _vm.loading
      ? _c("div", [
          _c(
            "div",
            { attrs: { id: "loader" } },
            [_c("font-awesome-icon", { attrs: { icon: "spinner", spin: "" } })],
            1
          ),
        ])
      : _vm._e(),
    _c("h4", { staticClass: "cardD" }, [
      _vm._v(" Entidad Federativa en la que será firmada la Póliza "),
    ]),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-md-6" }, [
        _c("label", [_vm._v("Entidad Federativa")]),
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.formalizacion.selectedEntidad,
                expression: "formalizacion.selectedEntidad",
              },
            ],
            staticClass: "custom-select",
            attrs: { disabled: _vm.formalizacion.disabledEntidad },
            on: {
              change: [
                function ($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function (o) {
                      return o.selected
                    })
                    .map(function (o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.$set(
                    _vm.formalizacion,
                    "selectedEntidad",
                    $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                  )
                },
                _vm.getMunicipios,
              ],
            },
          },
          _vm._l(_vm.formalizacion.optionsEntidades, function (option) {
            return _c("option", { key: option.value }, [
              _vm._v(" " + _vm._s(option.text) + " "),
            ])
          }),
          0
        ),
      ]),
      _vm.hay_municipios
        ? _c("div", { staticClass: "col-md-6" }, [
            _c("label", [_vm._v("Municipio")]),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.formalizacion.selectedMunicipio,
                    expression: "formalizacion.selectedMunicipio",
                  },
                ],
                staticClass: "custom-select",
                attrs: { disabled: _vm.formalizacion.disabledEntidad },
                on: {
                  change: [
                    function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.formalizacion,
                        "selectedMunicipio",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    },
                    function ($event) {
                      return _vm.selectCorreduria($event)
                    },
                  ],
                },
              },
              _vm._l(_vm.formalizacion.optionsMunicipios, function (option) {
                return _c("option", { key: option.value }, [
                  _vm._v(" " + _vm._s(option.text) + " "),
                ])
              }),
              0
            ),
          ])
        : _vm._e(),
    ]),
    _c("div", { staticClass: "mt-4" }, [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-10" },
          [
            _c(
              "b-button",
              {
                staticClass: "btn-fill orange-btn bottom2",
                attrs: {
                  type: "submit",
                  size: "m",
                  disabled: _vm.formalizacion.disabledEntidad,
                },
              },
              [_vm._v("Guardar")]
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-2" },
          [
            _c(
              "b-button",
              {
                staticClass: "btn-fill bottom2",
                attrs: {
                  variant: "success",
                  size: "m",
                  disabled: _vm.formalizacion.disabledEntidad,
                },
                on: {
                  click: function ($event) {
                    return _vm.validar()
                  },
                },
              },
              [_vm._v("Validar")]
            ),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }