var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c("h4", { staticClass: "cardD" }, [
        _vm._v("Representantes orgánicos y apoderados"),
      ]),
      _c(
        "b-modal",
        {
          ref: "modal-confirm-dialog",
          attrs: {
            "no-close-on-backdrop": "",
            centered: "",
            "hide-footer": "",
            id: "modal-confirm-dialog",
          },
        },
        [
          _c("h4", { staticClass: "text-center" }, [
            _vm._v(
              " ¿Seguro que quiere eliminar al representante o apoderado? "
            ),
          ]),
          _c("h5", { staticClass: "text-center" }),
          _c(
            "b-button",
            {
              staticClass: "mt-3",
              attrs: { variant: "danger" },
              on: {
                click: function ($event) {
                  return _vm.$bvModal.hide("modal-confirm-dialog")
                },
              },
            },
            [_vm._v("Cancelar")]
          ),
          _c(
            "b-button",
            {
              staticClass: "mt-3 float-right",
              attrs: { variant: "primary" },
              on: { click: _vm.deleteApoderado },
            },
            [_vm._v("OK")]
          ),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          ref: "apoderado-modal",
          attrs: {
            "no-close-on-backdrop": "",
            centered: "",
            title: "",
            "hide-footer": "",
            id: "modalC",
            size: "xl",
          },
        },
        [
          _c("ValidationObserver", {
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ handleSubmit }) {
                  return [
                    _c(
                      "form",
                      {
                        on: {
                          submit: function ($event) {
                            $event.preventDefault()
                            return handleSubmit(_vm.putApoderado)
                          },
                        },
                      },
                      [
                        _c("ModalApoderados", {
                          attrs: {
                            currentApoderado: _vm.currentApoderado,
                            options_facultades: _vm.options_facultades,
                            complete: false,
                          },
                          on: {
                            submit: function ($event) {
                              $event.preventDefault()
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("div", { staticClass: "row mt-5" }, [
        _c(
          "div",
          { staticClass: "col-12" },
          [
            _c("label", { attrs: { for: "" } }, [_vm._v("Apoderados")]),
            _vm.constitutiva.roleStaff
              ? _c(
                  "b-button",
                  {
                    staticClass: "btn-fill spaceL",
                    attrs: {
                      disabled: _vm.constitutiva.disabledRepresentantes,
                      variant: "success",
                      size: "sm",
                    },
                    on: { click: _vm.addApoderado },
                  },
                  [_vm._v(" + ")]
                )
              : _vm._e(),
            _c("b-table", {
              attrs: {
                hover: "",
                fields: _vm.fieldsCuadro,
                items: _vm.constitutiva.apoderados,
              },
              scopedSlots: _vm._u([
                {
                  key: "cell(acciones)",
                  fn: function ({ item }) {
                    return [
                      _c(
                        "b-button",
                        {
                          directives: [
                            {
                              name: "b-tooltip",
                              rawName: "v-b-tooltip.hover",
                              modifiers: { hover: true },
                            },
                          ],
                          staticClass: "editBtn",
                          attrs: {
                            disabled: _vm.constitutiva.disabledRepresentantes,
                            variant: "warning",
                            size: "sm",
                            title: "Editar",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.editApoderado(item)
                            },
                          },
                        },
                        [_c("b-icon-pencil")],
                        1
                      ),
                      _vm.constitutiva.roleStaff
                        ? _c(
                            "b-button",
                            {
                              directives: [
                                {
                                  name: "b-tooltip",
                                  rawName: "v-b-tooltip.hover",
                                  modifiers: { hover: true },
                                },
                              ],
                              attrs: {
                                disabled:
                                  _vm.constitutiva.disabledRepresentantes,
                                variant: "danger",
                                size: "sm",
                                title: "Eliminar",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.predeleteApoderado(item.id)
                                },
                              },
                            },
                            [_c("b-icon-trash")],
                            1
                          )
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            }),
            _vm.constitutiva.apoderados.length > 1
              ? _c("label", { staticClass: "mt-2 leyenda" }, [
                  _vm._v(" La cotización básica incluye solo apoderado. "),
                ])
              : _vm._e(),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "mt-4" }, [
        _c("label", [_vm._v("Dueño beneficiario")]),
        _c("label", { staticClass: "beneficiario" }, [
          _vm._v(
            " Conforme a lo establecido en la Ley Federal para la Prevención e Identificación de Operaciones con Recursos de Procedencia Ilícita, su Reglamento y Reglas de Carácter General, manifiesto, bajo protesta de decir verdad, que: "
          ),
        ]),
        _c("div", { staticClass: "row mt-2" }, [
          _c(
            "div",
            { staticClass: "col-1" },
            [
              _c("b-form-radio", {
                attrs: { name: "pld", value: "1" },
                on: {
                  change: function ($event) {
                    return _vm.selectBeneficiarioPLD(1)
                  },
                },
                model: {
                  value: _vm.constitutiva.beneficiario_pld,
                  callback: function ($$v) {
                    _vm.$set(_vm.constitutiva, "beneficiario_pld", $$v)
                  },
                  expression: "constitutiva.beneficiario_pld",
                },
              }),
            ],
            1
          ),
          _vm._m(0),
        ]),
        _c("div", { staticClass: "row mt-2" }, [
          _c(
            "div",
            { staticClass: "col-1" },
            [
              _c("b-form-radio", {
                attrs: { name: "pld", value: "2" },
                on: {
                  change: function ($event) {
                    return _vm.selectBeneficiarioPLD(2)
                  },
                },
                model: {
                  value: _vm.constitutiva.beneficiario_pld,
                  callback: function ($$v) {
                    _vm.$set(_vm.constitutiva, "beneficiario_pld", $$v)
                  },
                  expression: "constitutiva.beneficiario_pld",
                },
              }),
            ],
            1
          ),
          _vm._m(1),
        ]),
        _c("div", { staticClass: "row mt-2" }, [
          _c(
            "div",
            { staticClass: "col-1" },
            [
              _c("b-form-radio", {
                attrs: { name: "pld", value: "3" },
                on: {
                  change: function ($event) {
                    return _vm.selectBeneficiarioPLD(3)
                  },
                },
                model: {
                  value: _vm.constitutiva.beneficiario_pld,
                  callback: function ($$v) {
                    _vm.$set(_vm.constitutiva, "beneficiario_pld", $$v)
                  },
                  expression: "constitutiva.beneficiario_pld",
                },
              }),
            ],
            1
          ),
          _vm._m(2),
        ]),
        _vm.constitutiva.beneficiario_pld === 3
          ? _c("div", { staticClass: "row mt-4" }, [
              _c(
                "div",
                { staticClass: "col-12" },
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        id: "input-group-beneficiario",
                        label: "Nombre beneficiario:",
                        "label-for": "input-beneficiario",
                      },
                    },
                    [
                      _c("b-form-input", {
                        attrs: {
                          id: "input-beneficiario",
                          type: "text",
                          placeholder: "Nombre completo",
                        },
                        model: {
                          value: _vm.constitutiva.nombre_beneficiario_pld,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.constitutiva,
                              "nombre_beneficiario_pld",
                              $$v
                            )
                          },
                          expression: "constitutiva.nombre_beneficiario_pld",
                        },
                      }),
                      _c("span", { staticClass: "cardErrors" }, [
                        _vm._v(
                          "Para varios beneficiarios, incluirlos separándolos por comas."
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ])
          : _vm._e(),
        _c("label", { staticClass: "beneficiario" }, [
          _vm._v(
            " Conforme a lo establecido en el Código Fiscal de la Federación, manifiesto, bajo protesta de decir verdad, que: "
          ),
        ]),
        _c("div", { staticClass: "row mt-2" }, [
          _c(
            "div",
            { staticClass: "col-1" },
            [
              _c("b-form-radio", {
                attrs: { name: "cff", value: "4" },
                on: {
                  change: function ($event) {
                    return _vm.selectBeneficiarioCFF(4)
                  },
                },
                model: {
                  value: _vm.constitutiva.beneficiario_cff,
                  callback: function ($$v) {
                    _vm.$set(_vm.constitutiva, "beneficiario_cff", $$v)
                  },
                  expression: "constitutiva.beneficiario_cff",
                },
              }),
            ],
            1
          ),
          _vm._m(3),
        ]),
        _c("div", { staticClass: "row mt-2" }, [
          _c(
            "div",
            { staticClass: "col-1" },
            [
              _c("b-form-radio", {
                attrs: { name: "cff", value: "6" },
                on: {
                  change: function ($event) {
                    return _vm.selectBeneficiarioCFF(6)
                  },
                },
                model: {
                  value: _vm.constitutiva.beneficiario_cff,
                  callback: function ($$v) {
                    _vm.$set(_vm.constitutiva, "beneficiario_cff", $$v)
                  },
                  expression: "constitutiva.beneficiario_cff",
                },
              }),
            ],
            1
          ),
          _vm._m(4),
        ]),
        _c("div", { staticClass: "row mt-2" }, [
          _c(
            "div",
            { staticClass: "col-1" },
            [
              _c("b-form-radio", {
                attrs: { name: "cff", value: "5" },
                on: {
                  change: function ($event) {
                    return _vm.selectBeneficiarioCFF(5)
                  },
                },
                model: {
                  value: _vm.constitutiva.beneficiario_cff,
                  callback: function ($$v) {
                    _vm.$set(_vm.constitutiva, "beneficiario_cff", $$v)
                  },
                  expression: "constitutiva.beneficiario_cff",
                },
              }),
            ],
            1
          ),
          _vm._m(5),
        ]),
        _vm.constitutiva.beneficiario_cff === 5
          ? _c("div", { staticClass: "row mt-4" }, [
              _c(
                "div",
                { staticClass: "col-12" },
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        id: "input-group-beneficiario",
                        label: "Nombre beneficiario controlador:",
                        "label-for": "input-beneficiario",
                      },
                    },
                    [
                      _c("b-form-input", {
                        attrs: {
                          id: "input-beneficiario",
                          type: "text",
                          placeholder: "Nombre completo",
                        },
                        model: {
                          value: _vm.constitutiva.nombre_beneficiario,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.constitutiva,
                              "nombre_beneficiario",
                              $$v
                            )
                          },
                          expression: "constitutiva.nombre_beneficiario",
                        },
                      }),
                      _c("span", { staticClass: "cardErrors" }, [
                        _vm._v(
                          "Para varios beneficiarios controladores, incluirlos separándolos por comas."
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ])
          : _vm._e(),
      ]),
      _c("div", { staticClass: "mt-4" }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-10" },
            [
              _c(
                "b-button",
                {
                  staticClass: "btn-fill orange-btn bottom2",
                  attrs: {
                    type: "submit",
                    size: "m",
                    disabled: _vm.constitutiva.disabledRepresentantes,
                  },
                },
                [_vm._v("Guardar")]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-2" },
            [
              _c(
                "b-button",
                {
                  staticClass: "btn-fill bottom2",
                  attrs: {
                    variant: "success",
                    size: "m",
                    disabled: _vm.constitutiva.disabledRepresentantes,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.validar()
                    },
                  },
                },
                [_vm._v("Validar")]
              ),
            ],
            1
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-md-11" }, [
      _c("b", [_vm._v("NO")]),
      _vm._v(
        " tengo conocimiento del dueño beneficiario o beneficiario controlador, pues actúo por mi propio derecho. "
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-md-11" }, [
      _c("b", [_vm._v("NO")]),
      _vm._v(
        " tengo conocimiento del dueño beneficiario o beneficiario controlador, ya que actué por cuenta de mi representada, por lo que son sus accionistas quienes se benefician de las consecuencias jurídicas del otorgamiento del instrumento público correspondiente. "
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-md-11" }, [
      _c("b", [_vm._v("SI")]),
      _vm._v(
        " tengo conocimiento del dueño beneficiario o beneficiario controlador. "
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-md-11" }, [
      _vm._v(" Actúo por mi propio derecho, por lo que "),
      _c("b", [_vm._v("NO")]),
      _vm._v(" hay beneficiario controlador distinto al suscrito. "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-md-11" }, [
      _c("b", [_vm._v("NO")]),
      _vm._v(
        " tengo conocimiento del beneficiario controlador bajo los criterios establecidos en la regla 2.8.1.20. de la RMF. No obstante lo anterior, tengo conocimiento de la forma en la que está compuesta la administración de mi representada, por lo que haré entrega de la información y documentación que me sea solicitada. "
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-md-11" }, [
      _c("b", [_vm._v("SI")]),
      _vm._v(
        " tengo conocimiento del o los beneficiarios controladores, por lo que haré entrega de la información y documentación que me sea solicitada. "
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }