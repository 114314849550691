// API
import BaseApi from './base';

class ComparecientesApi extends BaseApi {
  constructor() {
    super('comparecientes');
  }

  myList(clientes_id, params = {}) {
    return this.request.get(`/comparecientes/my/${clientes_id}`, {
      params: {
        ...params,
      },
    });
  }

  compPorComercial(nombre_comercial, params = {}) {
    return this.request.get(`/comparecientes/comercial/${nombre_comercial}`, {
      params: {
        ...params,
      },
    });
  }

  combo(params = {}) {
    return this.request.get(`/comparecientes/combo/select`, {
      params: {
        ...params,
      },
    });
  }

  clonaCompareciente(comparecientes_id, params = {}) {
    return this.request.get(`/comparecientes/clon/${comparecientes_id}`, {
      params: {
        ...params,
      },
    });
  }

  validacion(data) {
    return this.request.post(`/comparecientes/validacion`, data);
  }

  partialEdit(id, data) {
    return this.request.patch(`/comparecientes/${id}`, data);
  }
}

export default new ComparecientesApi();
