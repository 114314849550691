var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container" }, [
    _c(
      "div",
      { staticClass: "content" },
      [
        _c("h1", { staticClass: "number" }, [_vm._v("404")]),
        _c("p", { staticClass: "text" }, [
          _vm._v("Oops! La página que estás buscando no fue encontrada."),
        ]),
        _c("router-link", { staticClass: "text", attrs: { to: "/" } }, [
          _vm._v("Volver al inicio"),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }