var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("ValidationObserver", {
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ invalid }) {
              return [
                _c(
                  "form",
                  {
                    on: {
                      submit: function ($event) {
                        $event.preventDefault()
                        return _vm.onSubmit.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col-md-12" },
                        [
                          _c("ValidationProvider", {
                            attrs: {
                              name: "persona",
                              rules: "required",
                              "custom-messages": {
                                required: "La persona es obligatoria",
                              },
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function ({ errors }) {
                                    return [
                                      _c(
                                        "b-form-group",
                                        {
                                          attrs: {
                                            id: "input-group-1",
                                            label: "Persona:",
                                            "label-for": "input-1",
                                          },
                                        },
                                        [
                                          _c("b-form-select", {
                                            staticClass: "custom-select",
                                            attrs: {
                                              id: "input-1",
                                              options: _vm.optionsPersona,
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "first",
                                                  fn: function () {
                                                    return [
                                                      _c(
                                                        "b-form-select-option",
                                                        {
                                                          attrs: {
                                                            value: null,
                                                            disabled: "",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "-- Seleccione una opción --"
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  },
                                                  proxy: true,
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                            model: {
                                              value: _vm.tipoPersona,
                                              callback: function ($$v) {
                                                _vm.tipoPersona = $$v
                                              },
                                              expression: "tipoPersona",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "span",
                                        { staticClass: "cardErrors" },
                                        [_vm._v(_vm._s(errors[0]))]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm.tipoPersona === "Física"
                      ? _c("div", [
                          _c("div", { staticClass: "row mt-4" }, [
                            _c(
                              "div",
                              { staticClass: "col-md-4" },
                              [
                                _c("ValidationProvider", {
                                  attrs: {
                                    name: "nombre",
                                    rules: "required",
                                    "custom-messages": {
                                      required: "El nombre es obligatorio",
                                    },
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function ({ errors }) {
                                          return [
                                            _c(
                                              "b-form-group",
                                              {
                                                attrs: {
                                                  id: "input-group-2",
                                                  label: "Nombre:",
                                                  "label-for": "input-2",
                                                },
                                              },
                                              [
                                                _c("b-form-input", {
                                                  attrs: {
                                                    id: "input-2",
                                                    type: "text",
                                                    placeholder: "Nombre",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm
                                                        .solicitantePersonaFisica
                                                        .nombre,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.solicitantePersonaFisica,
                                                        "nombre",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "solicitantePersonaFisica.nombre",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "span",
                                              { staticClass: "cardErrors" },
                                              [_vm._v(_vm._s(errors[0]))]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "col-md-4" },
                              [
                                _c("ValidationProvider", {
                                  attrs: {
                                    name: "nombre",
                                    rules: "required",
                                    "custom-messages": {
                                      required:
                                        "El apellido paterno es obligatorio",
                                    },
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function ({ errors }) {
                                          return [
                                            _c(
                                              "b-form-group",
                                              {
                                                attrs: {
                                                  id: "input-group-3",
                                                  label: "Apellido paterno:",
                                                  "label-for": "input-3",
                                                },
                                              },
                                              [
                                                _c("b-form-input", {
                                                  attrs: {
                                                    id: "input-3",
                                                    type: "text",
                                                    placeholder: "Paterno",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm
                                                        .solicitantePersonaFisica
                                                        .paterno,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.solicitantePersonaFisica,
                                                        "paterno",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "solicitantePersonaFisica.paterno",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "span",
                                              { staticClass: "cardErrors" },
                                              [_vm._v(_vm._s(errors[0]))]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "col-md-4" },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      id: "input-group-4",
                                      label: "Apellido materno:",
                                      "label-for": "input-4",
                                    },
                                  },
                                  [
                                    _c("b-form-input", {
                                      attrs: {
                                        id: "input-4",
                                        type: "text",
                                        placeholder: "Materno",
                                      },
                                      model: {
                                        value:
                                          _vm.solicitantePersonaFisica.materno,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.solicitantePersonaFisica,
                                            "materno",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "solicitantePersonaFisica.materno",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                          _c("div", { staticClass: "row mt-4" }, [
                            _c(
                              "div",
                              { staticClass: "col-md-4" },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      id: "input-group-5",
                                      label: "Actúa por su propio derecho:",
                                      "label-for": "input-5",
                                    },
                                  },
                                  [
                                    _c(
                                      "b-form-radio-group",
                                      {
                                        attrs: {
                                          id: "input-5",
                                          checked:
                                            _vm.solicitantePersonaFisica
                                              .principal,
                                        },
                                        model: {
                                          value:
                                            _vm.solicitantePersonaFisica
                                              .principal,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.solicitantePersonaFisica,
                                              "principal",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "solicitantePersonaFisica.principal",
                                        },
                                      },
                                      [
                                        _c(
                                          "b-form-radio",
                                          { attrs: { value: true } },
                                          [_vm._v("Si")]
                                        ),
                                        _c(
                                          "b-form-radio",
                                          { attrs: { value: false } },
                                          [_vm._v("No")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                        ])
                      : _vm.tipoPersona === "Moral"
                      ? _c(
                          "div",
                          { staticClass: "mt-4" },
                          [
                            _c("div", { staticClass: "row" }, [
                              _c(
                                "div",
                                { staticClass: "col-md-12" },
                                [
                                  _c("ValidationProvider", {
                                    attrs: {
                                      name: "denominacion_social",
                                      rules: "required",
                                      "custom-messages": {
                                        required:
                                          "La denominacion social es obligatoria",
                                      },
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function ({ errors }) {
                                            return [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    id: "input-group-6",
                                                    label:
                                                      "Denominación social (Incluir tipo de sociedad):",
                                                    "label-for": "input-6",
                                                  },
                                                },
                                                [
                                                  _c("b-form-input", {
                                                    attrs: {
                                                      id: "input-6",
                                                      type: "text",
                                                      placeholder:
                                                        "Denominación social",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm
                                                          .solicitantePersonaMoral
                                                          .denominacion_social,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.solicitantePersonaMoral,
                                                          "denominacion_social",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "solicitantePersonaMoral.denominacion_social",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "span",
                                                { staticClass: "cardErrors" },
                                                [_vm._v(_vm._s(errors[0]))]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _c("div", { staticClass: "row" }, [
                              _c(
                                "label",
                                {
                                  staticClass:
                                    "col-md-12 fs-6 text-black-50 text-uppercase",
                                },
                                [_vm._v("Representante(s)")]
                              ),
                            ]),
                            _vm._l(
                              _vm.solicitantePersonaMoral.representantes,
                              function (representante, index) {
                                return _c("div", { key: index }, [
                                  _c("div", { staticClass: "row" }, [
                                    _c(
                                      "div",
                                      { staticClass: "col-md-4" },
                                      [
                                        _c("ValidationProvider", {
                                          attrs: {
                                            name: "nombre",
                                            rules: "required",
                                            "custom-messages": {
                                              required:
                                                "El nombre es obligatorio",
                                            },
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function ({ errors }) {
                                                  return [
                                                    _c(
                                                      "b-form-group",
                                                      {
                                                        attrs: {
                                                          id:
                                                            "input-group-representante-nombre-" +
                                                            index,
                                                          label: "Nombre:",
                                                          "label-for":
                                                            "input-representante-nombre-" +
                                                            index,
                                                        },
                                                      },
                                                      [
                                                        _c("b-form-input", {
                                                          attrs: {
                                                            id:
                                                              "input-representante-nombre-" +
                                                              index,
                                                            type: "text",
                                                            placeholder:
                                                              "Nombre",
                                                          },
                                                          model: {
                                                            value:
                                                              representante.nombre,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                representante,
                                                                "nombre",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "representante.nombre",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "cardErrors",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(errors[0])
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "col-md-4" },
                                      [
                                        _c("ValidationProvider", {
                                          attrs: {
                                            name: "nombre",
                                            rules: "required",
                                            "custom-messages": {
                                              required:
                                                "El apellido paterno es obligatorio",
                                            },
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function ({ errors }) {
                                                  return [
                                                    _c(
                                                      "b-form-group",
                                                      {
                                                        attrs: {
                                                          id:
                                                            "input-group-representante-paterno-" +
                                                            index,
                                                          label:
                                                            "Apellido paterno:",
                                                          "label-for":
                                                            "input-representante-materno-" +
                                                            index,
                                                        },
                                                      },
                                                      [
                                                        _c("b-form-input", {
                                                          attrs: {
                                                            id:
                                                              "input-representante-materno-" +
                                                              index,
                                                            type: "text",
                                                            placeholder:
                                                              "Paterno",
                                                          },
                                                          model: {
                                                            value:
                                                              representante.paterno,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                representante,
                                                                "paterno",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "representante.paterno",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "cardErrors",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(errors[0])
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "col-md-4" },
                                      [
                                        _c(
                                          "b-form-group",
                                          {
                                            attrs: {
                                              id:
                                                "input-group-representante-materno-" +
                                                index,
                                              label: "Apellido materno:",
                                              "label-for":
                                                "input-representante-materno-" +
                                                index,
                                            },
                                          },
                                          [
                                            _c("b-form-input", {
                                              attrs: {
                                                id:
                                                  "input-representante-materno-" +
                                                  index,
                                                type: "text",
                                                placeholder: "Materno",
                                              },
                                              model: {
                                                value: representante.materno,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    representante,
                                                    "materno",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "representante.materno",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]),
                                ])
                              }
                            ),
                            _c(
                              "div",
                              { staticClass: "row" },
                              [
                                _vm.canRemoveRepresentante
                                  ? _c(
                                      "b-button",
                                      {
                                        staticClass: "btn-fill spaceL",
                                        attrs: {
                                          variant: "danger",
                                          size: "sm",
                                        },
                                        on: { click: _vm.removeRepresentante },
                                      },
                                      [_vm._v(" Remover representante ")]
                                    )
                                  : _vm._e(),
                                _vm.canAddRepresentante
                                  ? _c(
                                      "b-button",
                                      {
                                        staticClass: "btn-fill spaceL",
                                        attrs: {
                                          variant: "secondary",
                                          size: "sm",
                                        },
                                        on: { click: _vm.addRepresentante },
                                      },
                                      [_vm._v(" Agregar otro representante ")]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          2
                        )
                      : _vm._e(),
                    _c("div", { staticClass: "row mt-4" }),
                    _c("div", { staticClass: "mt-4 text-center" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-fill float-right orange-btn",
                          attrs: { type: "submit", disabled: invalid },
                        },
                        [_vm._v(" Guardar ")]
                      ),
                    ]),
                  ]
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }