var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c("h4", { staticClass: "cardD" }, [_vm._v("Denominación social")]),
      _c("div", { staticClass: "container" }, [
        _vm.loading
          ? _c("div", [
              _c(
                "div",
                { attrs: { id: "loader" } },
                [
                  _c("font-awesome-icon", {
                    attrs: { icon: "spinner", spin: "" },
                  }),
                ],
                1
              ),
            ])
          : _vm._e(),
      ]),
      _c(
        "b-modal",
        {
          ref: "documento-modal-preview-img",
          attrs: {
            "no-close-on-backdrop": "",
            centered: "",
            title: "",
            "hide-footer": "",
            id: "modaldi",
            size: "xl",
          },
        },
        [
          _c("ModalPreviewImg", {
            attrs: { currentDocumento: _vm.currentDocumento },
          }),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          ref: "documento-modal-preview-pdf",
          attrs: {
            "no-close-on-backdrop": "",
            centered: "",
            title: "",
            "hide-footer": "",
            id: "modaldf",
            size: "xl",
          },
        },
        [
          _c("ModalPreviewPDF", {
            attrs: { currentDocumento: _vm.currentDocumento },
          }),
        ],
        1
      ),
      _c("h4", { staticClass: "cardD" }, [
        _vm._v(" Carga del documento de Denominación social "),
      ]),
      _c("div", { staticClass: "row mb-4" }, [
        _c(
          "div",
          { staticClass: "col-7" },
          [
            _c("ValidationProvider", {
              attrs: {
                name: "numero_permiso",
                rules: "required",
                "custom-messages": {
                  required: "La clave Única de Documento es obligatoria",
                },
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ errors }) {
                    return [
                      _c("base-input", {
                        attrs: {
                          disabled: _vm.constitutiva.disabledDenominacion,
                          type: "text",
                          label: "Clave Única de Documento",
                          placeholder: "A",
                        },
                        model: {
                          value: _vm.constitutiva.numero_permiso,
                          callback: function ($$v) {
                            _vm.$set(_vm.constitutiva, "numero_permiso", $$v)
                          },
                          expression: "constitutiva.numero_permiso",
                        },
                      }),
                      _c("span", { staticClass: "cardErrors" }, [
                        _vm._v(_vm._s(errors[0])),
                      ]),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-5" },
          [
            _c("ValidationProvider", {
              attrs: {
                name: "fecha_autoriza_se",
                rules: "required",
                "custom-messages": {
                  required: "La fecha de autorización es obligatoria",
                },
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ errors }) {
                    return [
                      _c("base-input", {
                        attrs: {
                          disabled: _vm.constitutiva.disabledDenominacion,
                          type: "date",
                          label: "Fecha de autorización",
                          placeholder: "Fecha de autorización",
                        },
                        model: {
                          value: _vm.constitutiva.fecha_autoriza_se,
                          callback: function ($$v) {
                            _vm.$set(_vm.constitutiva, "fecha_autoriza_se", $$v)
                          },
                          expression: "constitutiva.fecha_autoriza_se",
                        },
                      }),
                      _c("span", { staticClass: "cardErrors" }, [
                        _vm._v(_vm._s(errors[0])),
                      ]),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-10" },
          [
            _c("ValidationProvider", {
              attrs: {
                name: "documento",
                rules: "required",
                "custom-messages": { required: "El documento es obligatorio" },
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ errors }) {
                    return [
                      _c("b-form-input", {
                        attrs: { hidden: "" },
                        model: {
                          value: _vm.currentDocumento.nombreFile,
                          callback: function ($$v) {
                            _vm.$set(_vm.currentDocumento, "nombreFile", $$v)
                          },
                          expression: "currentDocumento.nombreFile",
                        },
                      }),
                      _c("span", { staticClass: "cardErrors" }, [
                        _vm._v(_vm._s(errors[0])),
                      ]),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" " + _vm._s(_vm.currentDocumento.nombreFile) + " "),
            _c("b-form-file", {
              attrs: {
                "browse-text": "Seleccionar",
                "drop-placeholder": "Cargar aquí",
                placeholder: "Da click aquí o arrastra el archivo",
              },
              on: { input: _vm.postDocumento },
              model: {
                value: _vm.currentDocumento.file,
                callback: function ($$v) {
                  _vm.$set(_vm.currentDocumento, "file", $$v)
                },
                expression: "currentDocumento.file",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-2" },
          [
            _c(
              "b-button",
              {
                directives: [
                  {
                    name: "b-tooltip",
                    rawName: "v-b-tooltip.hover",
                    modifiers: { hover: true },
                  },
                ],
                staticClass: "editBtn",
                attrs: {
                  variant: "primary",
                  size: "sm",
                  title: "Vista previa",
                },
                on: {
                  click: function ($event) {
                    return _vm.previewDocumento()
                  },
                },
              },
              [_c("b-icon-eye")],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._m(0),
      _c("div", { staticClass: "row mt-2" }, [
        _c("div", { staticClass: "col-md-10" }, [
          _vm._v(
            " " + _vm._s(_vm.constitutiva.denominaciones_sociales_1) + " "
          ),
        ]),
        _c(
          "div",
          { staticClass: "col-2" },
          [
            _c("b-form-radio", {
              attrs: { name: "radios", value: "1" },
              on: {
                change: function ($event) {
                  return _vm.selectDenom(1)
                },
              },
              model: {
                value: _vm.selected1,
                callback: function ($$v) {
                  _vm.selected1 = $$v
                },
                expression: "selected1",
              },
            }),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "row mt-2" }, [
        _c("div", { staticClass: "col-md-10" }, [
          _vm._v(
            " " + _vm._s(_vm.constitutiva.denominaciones_sociales_2) + " "
          ),
        ]),
        _c(
          "div",
          { staticClass: "col-2" },
          [
            _c("b-form-radio", {
              attrs: { name: "radios", value: "2" },
              on: {
                change: function ($event) {
                  return _vm.selectDenom(2)
                },
              },
              model: {
                value: _vm.selected2,
                callback: function ($$v) {
                  _vm.selected2 = $$v
                },
                expression: "selected2",
              },
            }),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "row mt-2" }, [
        _c("div", { staticClass: "col-md-10" }, [
          _vm._v(
            " " + _vm._s(_vm.constitutiva.denominaciones_sociales_3) + " "
          ),
        ]),
        _c(
          "div",
          { staticClass: "col-2" },
          [
            _c("b-form-radio", {
              attrs: { name: "radios", value: "3" },
              on: {
                change: function ($event) {
                  return _vm.selectDenom(3)
                },
              },
              model: {
                value: _vm.selected3,
                callback: function ($$v) {
                  _vm.selected3 = $$v
                },
                expression: "selected3",
              },
            }),
          ],
          1
        ),
      ]),
      _vm.constitutiva.autoriza_cd_denominacion === 1 ||
      _vm.constitutiva.autoriza_cd_denominacion === true
        ? _c("div", { staticClass: "row mt-2" }, [
            _c(
              "div",
              { staticClass: "col-10" },
              [
                _c("base-input", {
                  staticClass: "mt-2",
                  attrs: {
                    disabled: _vm.constitutiva.disabledDenominacion,
                    type: "text",
                    label: "",
                    placeholder: "Denominación social autorizada",
                  },
                  on: { keyup: _vm.updateOtro },
                  model: {
                    value: _vm.constitutiva.denom_otra,
                    callback: function ($$v) {
                      _vm.$set(_vm.constitutiva, "denom_otra", $$v)
                    },
                    expression: "constitutiva.denom_otra",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-2" },
              [
                _c("b-form-radio", {
                  attrs: { name: "radios", value: "4" },
                  on: {
                    change: function ($event) {
                      return _vm.selectDenom(4)
                    },
                  },
                  model: {
                    value: _vm.selected4,
                    callback: function ($$v) {
                      _vm.selected4 = $$v
                    },
                    expression: "selected4",
                  },
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
      _c("div", { staticClass: "mt-4" }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-12" },
            [
              _c(
                "b-button",
                {
                  staticClass: "btn-fill orange-btn bottom2",
                  attrs: {
                    type: "submit",
                    size: "m",
                    disabled: _vm.constitutiva.disabledDenominacion,
                  },
                },
                [_vm._v("Guardar")]
              ),
            ],
            1
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "mt-5" }, [
      _c("label", { attrs: { for: "" } }, [
        _vm._v("Denominación social autorizada"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }