var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-md-12" },
        [
          _c("ValidationProvider", {
            attrs: {
              name: "tipo",
              rules: "required",
              "custom-messages": { required: "El tipo es obligatorio" },
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ errors }) {
                  return [
                    _c("label", { attrs: { for: "" } }, [_vm._v("Tipo")]),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.currentTelefono.tipo,
                            expression: "currentTelefono.tipo",
                          },
                        ],
                        staticClass: "custom-select",
                        on: {
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.currentTelefono,
                              "tipo",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                        },
                      },
                      [
                        _c("option", { attrs: { value: "Teléfono" } }, [
                          _vm._v("Fijo"),
                        ]),
                        _c("option", { attrs: { value: "Celular" } }, [
                          _vm._v("Celular"),
                        ]),
                      ]
                    ),
                    _c("span", { staticClass: "cardErrors" }, [
                      _vm._v(_vm._s(errors[0])),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-md-12 mt-2" },
        [
          _c("ValidationProvider", {
            attrs: {
              name: "clave",
              rules: "required",
              "custom-messages": { required: "La clave es obligatoria" },
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ errors }) {
                  return [
                    _c("label", [_vm._v("Clave de país")]),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.claveCompleta,
                            expression: "claveCompleta",
                          },
                        ],
                        staticClass: "custom-select",
                        on: {
                          change: [
                            function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.claveCompleta = $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            },
                            function ($event) {
                              return _vm.setClave($event)
                            },
                          ],
                        },
                      },
                      _vm._l(_vm.optionsClaves, function (option) {
                        return _c("option", { key: option.value }, [
                          _vm._v(" " + _vm._s(option.text) + " "),
                        ])
                      }),
                      0
                    ),
                    _c("span", { staticClass: "cardErrors" }, [
                      _vm._v(_vm._s(errors[0])),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-md-12" },
        [
          _c("ValidationProvider", {
            attrs: {
              name: "numero",
              rules: { required: true, length: 10, regex: /^\d{10}$/ },
              "custom-messages": {
                required: "El número es obligatorio",
                length: "El número debe tener 10 dígitos",
                regex: "El número no tiene el formato correcto",
              },
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ errors }) {
                  return [
                    _c("base-input", {
                      attrs: {
                        type: "tel",
                        label: "Número",
                        placeholder: "Número",
                      },
                      model: {
                        value: _vm.currentTelefono.numero,
                        callback: function ($$v) {
                          _vm.$set(_vm.currentTelefono, "numero", $$v)
                        },
                        expression: "currentTelefono.numero",
                      },
                    }),
                    _c("span", { staticClass: "cardErrors" }, [
                      _vm._v(_vm._s(errors[0])),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-md-12" },
        [
          _c("base-input", {
            attrs: {
              type: "extension",
              label: "Extensión",
              placeholder: "Extensión",
            },
            model: {
              value: _vm.currentTelefono.extension,
              callback: function ($$v) {
                _vm.$set(_vm.currentTelefono, "extension", $$v)
              },
              expression: "currentTelefono.extension",
            },
          }),
        ],
        1
      ),
    ]),
    !_vm.disableSaveButton
      ? _c("div", { staticClass: "text-center" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-fill float-right orange-btn",
              attrs: { type: "submit" },
            },
            [_vm._v(" Guardar ")]
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }