var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "content" }, [
    _c("div", { staticClass: "container-fluid mt-5 mb-5" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-md-1" }),
        _vm._m(0),
        _c("div", { staticClass: "col-md-1" }),
        _c("div", { staticClass: "col-md-1" }),
        _c("div", { staticClass: "col-md-4" }, [
          _c("div", { staticClass: "mt-3" }),
          _c("hr"),
          _c(
            "p",
            [
              _c("font-awesome-icon", { attrs: { icon: "envelope" } }),
              _vm._v(" info@correduriadigital.mx "),
            ],
            1
          ),
          _c(
            "p",
            [
              _c("font-awesome-icon", { attrs: { icon: "building" } }),
              _vm._v(
                " Av. Paseo de las Palmas, Oficina 204, Lomas de Chapultepec, CDMX, México "
              ),
            ],
            1
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-md-4" }, [
      _c("h3", { staticClass: "text-warning" }, [
        _vm._v("Para mayor información contáctanos"),
      ]),
      _c("h5", [_vm._v("Te apoyaremos de la mejor manera")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }