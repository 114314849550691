var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content" },
    [
      _c(
        "b-breadcrumb",
        [
          _c("b-breadcrumb-item", { attrs: { to: { path: "/dashboard" } } }, [
            _vm._v("Inicio"),
          ]),
          _c("b-breadcrumb-item", { attrs: { active: "" } }, [
            _vm._v("Nueva Constitución de Sociedad"),
          ]),
        ],
        1
      ),
      _c("div", { staticClass: "container" }, [
        _vm.loading
          ? _c("div", [
              _c(
                "div",
                { attrs: { id: "loader" } },
                [
                  _c("font-awesome-icon", {
                    attrs: { icon: "spinner", spin: "" },
                  }),
                ],
                1
              ),
            ])
          : _vm._e(),
      ]),
      _c(
        "b-modal",
        {
          ref: "modal-confirm-dialog-route",
          attrs: {
            "no-close-on-backdrop": "",
            centered: "",
            "hide-footer": "",
            id: "modal-confirm-dialog-route",
          },
        },
        [
          _c("h4", { staticClass: "text-center" }, [
            _vm._v(" ¿Seguro que quiere salir de la página? "),
          ]),
          _c("h5", { staticClass: "text-center" }),
          _c(
            "b-button",
            {
              staticClass: "mt-3",
              attrs: { variant: "danger" },
              on: {
                click: function ($event) {
                  return _vm.$bvModal.hide("modal-confirm-dialog-route")
                },
              },
            },
            [_vm._v("Cancelar")]
          ),
          _c(
            "b-button",
            {
              staticClass: "mt-3 float-right",
              attrs: { variant: "primary" },
              on: {
                click: function ($event) {
                  return _vm.beforeLeave(true)
                },
              },
            },
            [_vm._v("OK")]
          ),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          ref: "modal-confirm-dialog",
          attrs: {
            "no-close-on-backdrop": "",
            centered: "",
            "hide-footer": "",
            id: "modal-confirm-dialog",
          },
        },
        [
          _c("h4", { staticClass: "text-center" }, [
            _vm._v(" ¿Seguro que quiere salir sin guardar los cambios? "),
          ]),
          _c("h5", { staticClass: "text-center" }),
          _c(
            "b-button",
            {
              staticClass: "mt-3",
              attrs: { variant: "danger" },
              on: {
                click: function ($event) {
                  return _vm.$bvModal.hide("modal-confirm-dialog")
                },
              },
            },
            [_vm._v("Cancelar")]
          ),
          _c(
            "b-button",
            {
              staticClass: "mt-3 float-right",
              attrs: { variant: "primary" },
              on: {
                click: function ($event) {
                  return _vm.selected("go")
                },
              },
            },
            [_vm._v("OK")]
          ),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          ref: "pago-modal",
          attrs: {
            "no-close-on-backdrop": "",
            centered: "",
            title: "",
            "hide-footer": "",
            id: "modalC",
            size: "xl",
          },
        },
        [
          _c("ValidationObserver", {
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ handleSubmit }) {
                  return [
                    _c(
                      "form",
                      {
                        on: {
                          submit: function ($event) {
                            $event.preventDefault()
                            return handleSubmit(_vm.saveConstitucion)
                          },
                        },
                      },
                      [
                        _c("Openpay", {
                          attrs: {
                            cotizacion_id: _vm.constitutiva.cotizacion.id,
                            precio_total: _vm.constitutiva.precio_total,
                          },
                          on: {
                            submit: function ($event) {
                              $event.preventDefault()
                            },
                            payCotizacion: function ($event) {
                              return _vm.payCotizacion($event)
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            "no-close-on-backdrop": "",
            id: "modal-help-constitutiva-2",
            size: "xl",
            centered: "",
            "hide-footer": "",
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "b-carousel",
                {
                  attrs: {
                    id: "carousel-constitutiva-1",
                    interval: 0,
                    controls: "",
                    indicators: "",
                    background: "#ababab",
                  },
                },
                [
                  _vm.currentComponentBody === "DatosGenerales"
                    ? _c(
                        "div",
                        [
                          _c("b-carousel-slide", {
                            attrs: {
                              "img-src": require("@/assets/img/constitutiva/help/1_1.webp"),
                            },
                          }),
                          _c("b-carousel-slide", {
                            attrs: {
                              "img-src": require("@/assets/img/constitutiva/help/1_2.webp"),
                            },
                          }),
                          _c("b-carousel-slide", {
                            attrs: {
                              "img-src": require("@/assets/img/constitutiva/help/1_3.webp"),
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.currentComponentBody === "DatosCapital"
                    ? _c(
                        "div",
                        [
                          _c("b-carousel-slide", {
                            attrs: {
                              "img-src": require("@/assets/img/constitutiva/help/2_1.webp"),
                            },
                          }),
                          _c("b-carousel-slide", {
                            attrs: {
                              "img-src": require("@/assets/img/constitutiva/help/2_2.webp"),
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.currentComponentBody === "DatosOrganos"
                    ? _c(
                        "div",
                        [
                          _c("b-carousel-slide", {
                            attrs: {
                              "img-src": require("@/assets/img/constitutiva/help/3_1.webp"),
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.currentComponentBody === "RepresentantesYApoderados"
                    ? _c(
                        "div",
                        [
                          _c("b-carousel-slide", {
                            attrs: {
                              "img-src": require("@/assets/img/constitutiva/help/4_1.webp"),
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "div",
        { staticClass: "container-fluid" },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { staticClass: "col-3" },
                [
                  _c(
                    "b-card",
                    [
                      _c("TreeM", {
                        attrs: {
                          constitutiva: _vm.constitutiva,
                          "selected-component": _vm.currentComponentBody,
                          user: "Client",
                        },
                        on: { node: _vm.selected },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _vm.currentComponentBody === "DatosGenerales"
                        ? _c("b-img", {
                            attrs: {
                              src: require("@/assets/img/constitutiva/help/1.webp"),
                              "fluid-grow": "",
                              alt: "Descripción de los datos generales",
                            },
                          })
                        : _vm._e(),
                      _vm.currentComponentBody === "DatosGenerales"
                        ? _c(
                            "b-button",
                            {
                              staticClass: "orange-btn",
                              attrs: { block: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.$bvModal.show(
                                    "modal-help-constitutiva-2"
                                  )
                                },
                              },
                            },
                            [_vm._v(" Más información ")]
                          )
                        : _vm._e(),
                      _vm.currentComponentBody === "DatosCapital"
                        ? _c("b-img", {
                            attrs: {
                              src: require("@/assets/img/constitutiva/help/2.webp"),
                              "fluid-grow": "",
                              alt: "Descripción de los datos del capital",
                            },
                          })
                        : _vm._e(),
                      _vm.currentComponentBody === "DatosCapital"
                        ? _c(
                            "b-button",
                            {
                              staticClass: "orange-btn",
                              attrs: { block: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.$bvModal.show(
                                    "modal-help-constitutiva-2"
                                  )
                                },
                              },
                            },
                            [_vm._v(" Más información ")]
                          )
                        : _vm._e(),
                      _vm.currentComponentBody === "DatosOrganos"
                        ? _c("b-img", {
                            attrs: {
                              src: require("@/assets/img/constitutiva/help/3.webp"),
                              "fluid-grow": "",
                              alt: "Descripción de los órganos corporativos",
                            },
                          })
                        : _vm._e(),
                      _vm.currentComponentBody === "DatosOrganos"
                        ? _c(
                            "b-button",
                            {
                              staticClass: "orange-btn",
                              attrs: { block: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.$bvModal.show(
                                    "modal-help-constitutiva-2"
                                  )
                                },
                              },
                            },
                            [_vm._v(" Más información ")]
                          )
                        : _vm._e(),
                      _vm.currentComponentBody === "RepresentantesYApoderados"
                        ? _c("b-img", {
                            attrs: {
                              src: require("@/assets/img/constitutiva/help/4.webp"),
                              "fluid-grow": "",
                              alt: "Descripción de los representantes y apoderados",
                            },
                          })
                        : _vm._e(),
                      _vm.currentComponentBody === "RepresentantesYApoderados"
                        ? _c(
                            "b-button",
                            {
                              staticClass: "orange-btn",
                              attrs: { block: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.$bvModal.show(
                                    "modal-help-constitutiva-2"
                                  )
                                },
                              },
                            },
                            [_vm._v(" Más información ")]
                          )
                        : _vm._e(),
                      _vm.currentComponentBody === "CapitulosEspeciales"
                        ? _c("b-img", {
                            attrs: {
                              src: require("@/assets/img/constitutiva/help/5.webp"),
                              "fluid-grow": "",
                              alt: "Descripción de los capítulos especiales",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-col",
                { staticClass: "col-9" },
                [
                  _c(
                    "card",
                    [
                      _c(
                        "b-row",
                        [
                          _c(_vm.currentComponentInfoHead, {
                            tag: "component",
                            staticClass: "tab",
                          }),
                        ],
                        1
                      ),
                      _c(
                        "b-row",
                        [
                          _c("b-col", { staticClass: "col-12" }, [
                            _vm.currentComponentBody === "DatosGenerales"
                              ? _c(
                                  "div",
                                  [
                                    _c("ValidationObserver", {
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function ({ handleSubmit }) {
                                              return [
                                                _c(
                                                  "form",
                                                  {
                                                    on: {
                                                      submit: function (
                                                        $event
                                                      ) {
                                                        $event.preventDefault()
                                                        return handleSubmit(
                                                          _vm.saveConstitucion
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("DatosGenerales", {
                                                      attrs: {
                                                        constitutiva:
                                                          _vm.constitutiva,
                                                        optionsObjetoSocial:
                                                          _vm.optionsObjetoSocial,
                                                      },
                                                      on: {
                                                        submit: function (
                                                          $event
                                                        ) {
                                                          $event.preventDefault()
                                                        },
                                                        node: _vm.selected,
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        1063959191
                                      ),
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.currentComponentBody === "DatosCapital"
                              ? _c(
                                  "div",
                                  [
                                    _c("ValidationObserver", {
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function ({ handleSubmit }) {
                                              return [
                                                _c(
                                                  "form",
                                                  {
                                                    on: {
                                                      submit: function (
                                                        $event
                                                      ) {
                                                        $event.preventDefault()
                                                        return handleSubmit(
                                                          _vm.saveConstitucion
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("DatosCapital", {
                                                      attrs: {
                                                        constitutiva:
                                                          _vm.constitutiva,
                                                      },
                                                      on: {
                                                        submit: function (
                                                          $event
                                                        ) {
                                                          $event.preventDefault()
                                                        },
                                                        node: _vm.selected,
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        3480607779
                                      ),
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.currentComponentBody === "DatosOrganos"
                              ? _c(
                                  "div",
                                  [
                                    _c("ValidationObserver", {
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function ({ handleSubmit }) {
                                              return [
                                                _c(
                                                  "form",
                                                  {
                                                    on: {
                                                      submit: function (
                                                        $event
                                                      ) {
                                                        $event.preventDefault()
                                                        return handleSubmit(
                                                          _vm.saveConstitucion
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("DatosOrganos", {
                                                      attrs: {
                                                        constitutiva:
                                                          _vm.constitutiva,
                                                      },
                                                      on: {
                                                        submit: function (
                                                          $event
                                                        ) {
                                                          $event.preventDefault()
                                                        },
                                                        node: _vm.selected,
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        2125463272
                                      ),
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.currentComponentBody ===
                            "RepresentantesYApoderados"
                              ? _c(
                                  "div",
                                  [
                                    _c("ValidationObserver", {
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function ({ handleSubmit }) {
                                              return [
                                                _c(
                                                  "form",
                                                  {
                                                    on: {
                                                      submit: function (
                                                        $event
                                                      ) {
                                                        $event.preventDefault()
                                                        return handleSubmit(
                                                          _vm.saveConstitucion
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "RepresentantesYApoderados",
                                                      {
                                                        attrs: {
                                                          constitutiva:
                                                            _vm.constitutiva,
                                                          cat_facultades:
                                                            _vm.cat_facultades,
                                                        },
                                                        on: {
                                                          submit: function (
                                                            $event
                                                          ) {
                                                            $event.preventDefault()
                                                          },
                                                          node: _vm.selected,
                                                        },
                                                      }
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        1985097350
                                      ),
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.currentComponentBody === "EntidadFederativa"
                              ? _c(
                                  "div",
                                  [
                                    _c("ValidationObserver", {
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function ({ handleSubmit }) {
                                              return [
                                                _c(
                                                  "form",
                                                  {
                                                    on: {
                                                      submit: function (
                                                        $event
                                                      ) {
                                                        $event.preventDefault()
                                                        return handleSubmit(
                                                          _vm.saveConstitucion
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("EntidadFederativa", {
                                                      attrs: {
                                                        constitutiva:
                                                          _vm.constitutiva,
                                                        catCorredurias:
                                                          _vm.catCorredurias,
                                                      },
                                                      on: {
                                                        submit: function (
                                                          $event
                                                        ) {
                                                          $event.preventDefault()
                                                        },
                                                        node: _vm.selected,
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        396195158
                                      ),
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.currentComponentBody === "CapitulosEspeciales"
                              ? _c(
                                  "div",
                                  [
                                    _c("ValidationObserver", {
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function ({ handleSubmit }) {
                                              return [
                                                _c(
                                                  "form",
                                                  {
                                                    on: {
                                                      submit: function (
                                                        $event
                                                      ) {
                                                        $event.preventDefault()
                                                        return handleSubmit(
                                                          _vm.saveConstitucion
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("CapitulosEspeciales", {
                                                      attrs: {
                                                        constitutiva:
                                                          _vm.constitutiva,
                                                      },
                                                      on: {
                                                        submit: function (
                                                          $event
                                                        ) {
                                                          $event.preventDefault()
                                                        },
                                                        node: _vm.selected,
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        2691222100
                                      ),
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.currentComponentBody === "Cotizacion"
                              ? _c(
                                  "div",
                                  [
                                    _c("ValidationObserver", {
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function ({ handleSubmit }) {
                                              return [
                                                _c(
                                                  "form",
                                                  {
                                                    on: {
                                                      submit: function (
                                                        $event
                                                      ) {
                                                        $event.preventDefault()
                                                        return handleSubmit(
                                                          _vm.pagar
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("Cotizacion", {
                                                      attrs: {
                                                        constitutiva:
                                                          _vm.constitutiva,
                                                        cat_gastos:
                                                          _vm.cat_gastos,
                                                      },
                                                      on: {
                                                        submit: function (
                                                          $event
                                                        ) {
                                                          $event.preventDefault()
                                                        },
                                                        node: _vm.selected,
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        3966530265
                                      ),
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.currentComponentBody ===
                            "ComplementaInformacion"
                              ? _c(
                                  "div",
                                  [
                                    _c("ValidationObserver", {
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function ({ handleSubmit }) {
                                              return [
                                                _c(
                                                  "form",
                                                  {
                                                    on: {
                                                      submit: function (
                                                        $event
                                                      ) {
                                                        $event.preventDefault()
                                                        return handleSubmit(
                                                          _vm.saveConstitucion
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "ComplementaInformacion",
                                                      {
                                                        attrs: {
                                                          constitutiva:
                                                            _vm.constitutiva,
                                                          cat_facultades:
                                                            _vm.cat_facultades,
                                                        },
                                                        on: {
                                                          submit: function (
                                                            $event
                                                          ) {
                                                            $event.preventDefault()
                                                          },
                                                          node: _vm.selected,
                                                        },
                                                      }
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        2619834698
                                      ),
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.currentComponentBody === "FirmaDigital"
                              ? _c(
                                  "div",
                                  [
                                    _c("ValidationObserver", {
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function ({ handleSubmit }) {
                                              return [
                                                _c(
                                                  "form",
                                                  {
                                                    on: {
                                                      submit: function (
                                                        $event
                                                      ) {
                                                        $event.preventDefault()
                                                        return handleSubmit(
                                                          _vm.saveConstitucion
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("FirmaDigital", {
                                                      attrs: {
                                                        constitutiva:
                                                          _vm.constitutiva,
                                                        cat_facultades:
                                                          _vm.cat_facultades,
                                                      },
                                                      on: {
                                                        submit: function (
                                                          $event
                                                        ) {
                                                          $event.preventDefault()
                                                        },
                                                        node: _vm.selected,
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        4177227505
                                      ),
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.currentComponentBody === "EntregaDocumentos"
                              ? _c(
                                  "div",
                                  [
                                    _c("ValidationObserver", {
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function ({ handleSubmit }) {
                                              return [
                                                _c(
                                                  "form",
                                                  {
                                                    on: {
                                                      submit: function (
                                                        $event
                                                      ) {
                                                        $event.preventDefault()
                                                        return handleSubmit(
                                                          _vm.saveConstitucion
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("EntregaDocumentos", {
                                                      attrs: {
                                                        constitutiva:
                                                          _vm.constitutiva,
                                                      },
                                                      on: {
                                                        submit: function (
                                                          $event
                                                        ) {
                                                          $event.preventDefault()
                                                        },
                                                        node: _vm.selected,
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        1708602513
                                      ),
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.currentComponentBody === "CitaFirma"
                              ? _c(
                                  "div",
                                  [
                                    _c("ValidationObserver", {
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function ({ handleSubmit }) {
                                              return [
                                                _c(
                                                  "form",
                                                  {
                                                    on: {
                                                      submit: function (
                                                        $event
                                                      ) {
                                                        $event.preventDefault()
                                                        return handleSubmit(
                                                          _vm.saveConstitucion
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("CitaFirma", {
                                                      attrs: {
                                                        constitutiva:
                                                          _vm.constitutiva,
                                                      },
                                                      on: {
                                                        submit: function (
                                                          $event
                                                        ) {
                                                          $event.preventDefault()
                                                        },
                                                        node: _vm.selected,
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        3052870658
                                      ),
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.currentComponentBody === "DocListo"
                              ? _c(
                                  "div",
                                  [
                                    _c("DocListo", {
                                      attrs: { constitutiva: _vm.constitutiva },
                                      on: {
                                        submit: function ($event) {
                                          $event.preventDefault()
                                        },
                                        node: _vm.selected,
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }