var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content" },
    [
      _c(
        "b-breadcrumb",
        [
          _c("b-breadcrumb-item", { attrs: { to: { path: "/dashboard" } } }, [
            _vm._v("Inicio"),
          ]),
          _c("b-breadcrumb-item", { attrs: { active: "" } }, [
            _vm._v("Editar Notificación"),
          ]),
        ],
        1
      ),
      _c("div", { staticClass: "container" }, [
        _vm.loading
          ? _c("div", [
              _c(
                "div",
                { attrs: { id: "loader" } },
                [
                  _c("font-awesome-icon", {
                    attrs: { icon: "spinner", spin: "" },
                  }),
                ],
                1
              ),
            ])
          : _vm._e(),
      ]),
      _c(
        "b-modal",
        {
          ref: "modal-confirm-dialog-route",
          attrs: {
            "no-close-on-backdrop": "",
            centered: "",
            "hide-footer": "",
            id: "modal-confirm-dialog-route",
          },
        },
        [
          _c("h4", { staticClass: "text-center" }, [
            _vm._v(" ¿Seguro que quiere salir de la página? "),
          ]),
          _c("h5", { staticClass: "text-center" }),
          _c(
            "b-button",
            {
              staticClass: "mt-3",
              attrs: { variant: "danger" },
              on: {
                click: function ($event) {
                  return _vm.$bvModal.hide("modal-confirm-dialog-route")
                },
              },
            },
            [_vm._v("Cancelar")]
          ),
          _c(
            "b-button",
            {
              staticClass: "mt-3 float-right",
              attrs: { variant: "primary" },
              on: {
                click: function ($event) {
                  return _vm.beforeLeave(true)
                },
              },
            },
            [_vm._v("OK")]
          ),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          ref: "modal-confirm-dialog",
          attrs: {
            "no-close-on-backdrop": "",
            centered: "",
            "hide-footer": "",
            id: "modal-confirm-dialog",
          },
        },
        [
          _c("h4", { staticClass: "text-center" }, [
            _vm._v(" ¿Seguro que quiere salir sin guardar los cambios? "),
          ]),
          _c("h5", { staticClass: "text-center" }),
          _c(
            "b-button",
            {
              staticClass: "mt-3",
              attrs: { variant: "danger" },
              on: {
                click: function ($event) {
                  return _vm.$bvModal.hide("modal-confirm-dialog")
                },
              },
            },
            [_vm._v("Cancelar")]
          ),
          _c(
            "b-button",
            { staticClass: "mt-3 float-right", attrs: { variant: "primary" } },
            [_vm._v("OK")]
          ),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          ref: "modal-confirm-validate",
          attrs: {
            "no-close-on-backdrop": "",
            centered: "",
            "hide-footer": "",
            id: "modal-confirm-validate",
          },
        },
        [
          _c("h4", { staticClass: "text-center" }, [
            _vm._v(
              " ¿Está seguro de validar? Los datos no podrán ser modificados posteriormente. "
            ),
          ]),
          _c("h5", { staticClass: "text-center" }),
          _c(
            "b-button",
            {
              staticClass: "mt-3",
              attrs: { variant: "danger" },
              on: {
                click: function ($event) {
                  return _vm.$bvModal.hide("modal-confirm-validate")
                },
              },
            },
            [_vm._v("Cancelar")]
          ),
          _c(
            "b-button",
            {
              staticClass: "mt-3 float-right",
              attrs: { variant: "primary" },
              on: {
                click: function ($event) {
                  return _vm.postMethod()
                },
              },
            },
            [_vm._v("OK")]
          ),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          ref: "modal-confirm-mail",
          attrs: {
            "no-close-on-backdrop": "",
            centered: "",
            "hide-footer": "",
            id: "modal-confirm-mail",
          },
        },
        [
          _c("h4", { staticClass: "text-center" }, [
            _vm._v(
              " ¿Está seguro de validar la Diligencia? El cliente será notificado vía email. "
            ),
          ]),
          _c("h5", { staticClass: "text-center" }),
          _c(
            "b-button",
            {
              staticClass: "mt-3",
              attrs: { variant: "danger" },
              on: {
                click: function ($event) {
                  return _vm.$bvModal.hide("modal-confirm-mail")
                },
              },
            },
            [_vm._v("Cancelar")]
          ),
          _c(
            "b-button",
            {
              staticClass: "mt-3 float-right",
              attrs: { variant: "primary" },
              on: {
                click: function ($event) {
                  return _vm.postMethod()
                },
              },
            },
            [_vm._v("OK")]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "container-fluid" },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { staticClass: "col-3" },
                [
                  _c(
                    "b-card",
                    [
                      _c("TreeEditNotificaciones", {
                        attrs: {
                          notificacion: _vm.notificacion,
                          user: "Staff",
                          selectedComponent: _vm.currentComponentBody,
                        },
                        on: { node: _vm.selected },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-col",
                { staticClass: "col-9" },
                [
                  _vm.currentComponentBody === "Ubicacion"
                    ? _c("card", [
                        _c(
                          "div",
                          { staticClass: "container" },
                          [
                            _c("ValidationObserver", {
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function ({ handleSubmit }) {
                                      return [
                                        _c(
                                          "form",
                                          {
                                            on: {
                                              submit: function ($event) {
                                                $event.preventDefault()
                                                handleSubmit(
                                                  _vm.setModalValidate(
                                                    "Domicilio"
                                                  )
                                                )
                                              },
                                              change: function ($event) {
                                                return _vm.getAddressInMap(null)
                                              },
                                            },
                                          },
                                          [
                                            _c("ModalDomicilio", {
                                              attrs: {
                                                currentDomicilio:
                                                  _vm.notificacion.domicilio,
                                              },
                                              on: {
                                                submit: function ($event) {
                                                  $event.preventDefault()
                                                },
                                                node: _vm.selected,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                3845297807
                              ),
                            }),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                  _vm.currentComponentBody === "Solicitantes"
                    ? _c(
                        "card",
                        [
                          _c("ValidationObserver", {
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function ({ handleSubmit }) {
                                    return [
                                      _c(
                                        "form",
                                        {
                                          on: {
                                            submit: function ($event) {
                                              $event.preventDefault()
                                              handleSubmit(
                                                _vm.setModalValidate(
                                                  "Solicitantes"
                                                )
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("Solicitantes", {
                                            attrs: {
                                              notificacion: _vm.notificacion,
                                              "is-staff": true,
                                            },
                                            on: {
                                              submit: function ($event) {
                                                $event.preventDefault()
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              3768932513
                            ),
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.currentComponentBody === "Notificados"
                    ? _c(
                        "card",
                        [
                          _c("ValidationObserver", {
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function ({ handleSubmit }) {
                                    return [
                                      _c(
                                        "form",
                                        {
                                          on: {
                                            submit: function ($event) {
                                              $event.preventDefault()
                                              handleSubmit(
                                                _vm.setModalValidate(
                                                  "Notificados"
                                                )
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("Notificado", {
                                            attrs: {
                                              notificacion: _vm.notificacion,
                                              notificado: _vm.notificado,
                                            },
                                            on: {
                                              submit: function ($event) {
                                                $event.preventDefault()
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              1899018613
                            ),
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.currentComponentBody === "Documentos"
                    ? _c(
                        "card",
                        [
                          _c("ValidationObserver", {
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function ({ handleSubmit }) {
                                    return [
                                      _c(
                                        "form",
                                        {
                                          on: {
                                            submit: function ($event) {
                                              $event.preventDefault()
                                              handleSubmit(
                                                _vm.setModalValidate(
                                                  "Documentos"
                                                )
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("EntregaDocumentos", {
                                            attrs: {
                                              notificacion: _vm.notificacion,
                                            },
                                            on: {
                                              submit: function ($event) {
                                                $event.preventDefault()
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              3955538878
                            ),
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.currentComponentBody === "EnvioDomicilio"
                    ? _c("card", [
                        _c(
                          "div",
                          { staticClass: "container" },
                          [
                            _c("ValidationObserver", {
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function ({ handleSubmit }) {
                                      return [
                                        _c(
                                          "form",
                                          {
                                            on: {
                                              submit: function ($event) {
                                                $event.preventDefault()
                                                handleSubmit(
                                                  _vm.setModalValidate(
                                                    "EnvioDomicilio"
                                                  )
                                                )
                                              },
                                              change: function ($event) {
                                                return _vm.getAddressInMap(null)
                                              },
                                            },
                                          },
                                          [
                                            _c("ModalDomicilio", {
                                              attrs: {
                                                currentDomicilio:
                                                  _vm.notificacion
                                                    .domicilioEnvio,
                                              },
                                              on: {
                                                submit: function ($event) {
                                                  $event.preventDefault()
                                                },
                                                node: _vm.selected,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                1955803343
                              ),
                            }),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                  _vm.currentComponentBody === "Correduria"
                    ? _c("card", [
                        _c(
                          "div",
                          { staticClass: "container" },
                          [
                            _c("ValidationObserver", {
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function ({ handleSubmit }) {
                                      return [
                                        _c(
                                          "form",
                                          {
                                            on: {
                                              submit: function ($event) {
                                                $event.preventDefault()
                                                handleSubmit(
                                                  _vm.setModalValidate(
                                                    "Correduria"
                                                  )
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("Correduria", {
                                              attrs: {
                                                notificacion: _vm.notificacion,
                                              },
                                              on: {
                                                submit: function ($event) {
                                                  $event.preventDefault()
                                                },
                                                node: _vm.selected,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                3873901263
                              ),
                            }),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                  _vm.currentComponentBody === "Fad"
                    ? _c(
                        "card",
                        [
                          _c("ValidationObserver", {
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function ({ handleSubmit }) {
                                    return [
                                      _c(
                                        "form",
                                        {
                                          on: {
                                            submit: function ($event) {
                                              $event.preventDefault()
                                              handleSubmit(
                                                _vm.setModalValidate("Fad")
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("Fad", {
                                            attrs: {
                                              notificacion: _vm.notificacion,
                                            },
                                            on: {
                                              submit: function ($event) {
                                                $event.preventDefault()
                                              },
                                              node: _vm.selected,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              1735019567
                            ),
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.currentComponentBody === "Diligencia"
                    ? _c(
                        "card",
                        [
                          _c("ValidationObserver", {
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function ({ handleSubmit }) {
                                    return [
                                      _c(
                                        "form",
                                        {
                                          on: {
                                            submit: function ($event) {
                                              $event.preventDefault()
                                              handleSubmit(
                                                _vm.setModalValidate(
                                                  "Diligencia"
                                                )
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("Diligencia", {
                                            attrs: {
                                              notificacion: _vm.notificacion,
                                            },
                                            on: {
                                              proceedToSubmit: function (
                                                $event
                                              ) {
                                                return _vm.setModalValidate(
                                                  "Diligencia"
                                                )
                                              },
                                              node: _vm.selected,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              3409475628
                            ),
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.currentComponentBody === "Actas"
                    ? _c(
                        "card",
                        [
                          _c("ValidationObserver", {
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function ({ handleSubmit }) {
                                    return [
                                      _c(
                                        "form",
                                        {
                                          on: {
                                            submit: function ($event) {
                                              $event.preventDefault()
                                              handleSubmit(
                                                _vm.setModalValidate("Actas")
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("Actas", {
                                            attrs: {
                                              notificacion: _vm.notificacion,
                                            },
                                            on: {
                                              submit: function ($event) {
                                                $event.preventDefault()
                                              },
                                              node: _vm.selected,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              1658335695
                            ),
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.currentComponentBody === "Expediente"
                    ? _c(
                        "card",
                        [
                          _c("ValidationObserver", {
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function ({ handleSubmit }) {
                                    return [
                                      _c(
                                        "form",
                                        {
                                          on: {
                                            submit: function ($event) {
                                              $event.preventDefault()
                                              handleSubmit(
                                                _vm.setModalValidate(
                                                  "Expediente"
                                                )
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("Expediente", {
                                            attrs: {
                                              notificacion: _vm.notificacion,
                                            },
                                            on: {
                                              submit: function ($event) {
                                                $event.preventDefault()
                                              },
                                              node: _vm.selected,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              2706908751
                            ),
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.currentComponentBody === "Terminado"
                    ? _c(
                        "card",
                        [
                          _c("ValidationObserver", {
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function ({ handleSubmit }) {
                                    return [
                                      _c(
                                        "form",
                                        {
                                          on: {
                                            submit: function ($event) {
                                              $event.preventDefault()
                                              handleSubmit(
                                                _vm.setModalValidate(
                                                  "Terminado"
                                                )
                                              )
                                            },
                                            change: function ($event) {
                                              return _vm.getAddressInMap(null)
                                            },
                                          },
                                        },
                                        [
                                          _c("Terminado", {
                                            attrs: {
                                              notificacion: _vm.notificacion,
                                            },
                                            on: {
                                              submit: function ($event) {
                                                $event.preventDefault()
                                              },
                                              node: _vm.selected,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              3119597081
                            ),
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }