var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "contact-us full-screen" },
    [
      _c("div", { staticClass: "container" }, [
        _vm.loading
          ? _c("div", [
              _c(
                "div",
                { attrs: { id: "loader" } },
                [
                  _c("font-awesome-icon", {
                    attrs: { icon: "spinner", spin: "" },
                  }),
                ],
                1
              ),
            ])
          : _vm._e(),
      ]),
      _c("TopNavbar"),
      _c("div", { staticClass: "wrapper wrapper-full-page section content" }, [
        _c("div", { staticClass: "container" }, [
          _c(
            "h4",
            {
              staticClass: "card-title",
              attrs: { slot: "header" },
              slot: "header",
            },
            [_vm._v("Verificación de cuenta")]
          ),
          _c("div", { staticClass: "center" }, [
            _vm._v(
              " Para poder acceder a su cuenta es necesario que la verifique. Por favor haga clic en el botón de Verificar, que está a continuación. "
            ),
          ]),
          _c("div", { staticClass: "center" }, [
            _c(
              "button",
              {
                staticClass: "btn orange-btn",
                on: {
                  click: function ($event) {
                    return _vm.verificar()
                  },
                },
              },
              [_vm._v(" Verificar cuenta ")]
            ),
          ]),
        ]),
      ]),
      _c("Footer"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }