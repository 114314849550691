var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm._m(0),
    _c(
      "table",
      {
        staticClass: "tabla",
        staticStyle: { "margin-top": "5px", "border-spacing": "0 15px" },
      },
      [
        _c("tr", [
          _c("td", [
            _vm._v(
              " El día " +
                _vm._s(_vm.fecha) +
                ", los suscritos confirmamos que hemos contratado los servicios de Correduria Digital, S. de R.L. de C.V. (“Correduría Digital”), a efecto de que dicha entidad realice las gestiones necesarias, con el Corredor Público de su elección en la plaza que al efecto se designe (el “Corredor Público Designado”), a fin de que las personas que firmamos de forma digital el presente documento, llevemos a cabo la firma del acta de ratificación de firmas " +
                _vm._s(_vm.del_delos) +
                " documento" +
                _vm._s(_vm.s) +
                " a que se refiere dicho proyecto de acta, en términos similares al documento que antecede a la presente hoja de firmas. "
            ),
          ]),
        ]),
        _c("tr", [
          _c("td", [
            _vm._v(
              " Declaramos a Correduría Digital y al Corredor Público Designado, bajo protesta de decir verdad, que los suscritos somos los únicos responsables del contenido " +
                _vm._s(_vm.del_delos) +
                " documento" +
                _vm._s(_vm.s) +
                " que presentamos para su ratificación, mismo" +
                _vm._s(_vm.s) +
                " que " +
                _vm._s(_vm.fue_ron) +
                " elaborado" +
                _vm._s(_vm.s) +
                " y celebrado" +
                _vm._s(_vm.s) +
                " con anterioridad a la presente solicitud, sin que Correduría Digital ni el Corredor Público Designado intervinieran de manera alguna en su elaboración. "
            ),
          ]),
        ]),
        _c("tr", [
          _c("td", [
            _vm._v(
              " Entendemos que el servicio contratado a Correduría Digital no incluye asesoría legal alguna respecto del contenido " +
                _vm._s(_vm.del_delos) +
                " documento" +
                _vm._s(_vm.s) +
                " que hemos presentado para su ratificación, ni certifica o califica sobre la pertinencia, alcance o utilidad de dicho" +
                _vm._s(_vm.s) +
                " documento" +
                _vm._s(_vm.s) +
                ". "
            ),
          ]),
        ]),
        _vm._m(1),
        _vm._m(2),
      ]
    ),
    _vm._m(3),
    _c(
      "table",
      { staticClass: "tabla" },
      _vm._l(_vm.signerPairs, function (pair, index) {
        return _c("tbody", { key: index }, [
          _c("tr", [
            _c(
              "th",
              { staticClass: "section firma", attrs: { colspan: "3" } },
              [
                _vm._v(
                  " " +
                    _vm._s(
                      !pair[0].derecho_propio
                        ? pair[0].nombreCompleto
                        : pair[0].moral_representada
                    ) +
                    " "
                ),
              ]
            ),
            pair[1]
              ? _c(
                  "th",
                  { staticClass: "section firma", attrs: { colspan: "3" } },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          !pair[1].derecho_propio
                            ? pair[1].nombreCompleto
                            : pair[1].moral_representada
                        ) +
                        " "
                    ),
                  ]
                )
              : _vm._e(),
          ]),
          _c("tr", [
            _c("td", { staticStyle: { width: "10%" } }),
            _c("td", {
              staticStyle: {
                width: "30%",
                "background-color": "white !important",
                "border-bottom": "solid 2px black",
              },
            }),
            _c("td", { staticStyle: { width: "10%" } }),
            pair[1] ? _c("td", { staticStyle: { width: "10%" } }) : _vm._e(),
            pair[1]
              ? _c("td", {
                  staticStyle: {
                    width: "30%",
                    "background-color": "white !important",
                    "border-bottom": "solid 2px black",
                  },
                })
              : _vm._e(),
            pair[1] ? _c("td", { staticStyle: { width: "10%" } }) : _vm._e(),
          ]),
          _c("tr", [
            _c("td", { staticStyle: { width: "10%" } }),
            _c(
              "td",
              {
                staticStyle: {
                  width: "30%",
                  "vertical-align": "top",
                  "font-size": "14px",
                  "text-align": "center",
                  "background-color": "white",
                },
              },
              [
                _vm._v(
                  " " +
                    _vm._s(
                      !pair[0].derecho_propio
                        ? ""
                        : `Por: ${pair[0].nombreCompleto}`
                    ) +
                    " "
                ),
              ]
            ),
            _c("td", { staticStyle: { width: "10%" } }),
            pair[1] ? _c("td", { staticStyle: { width: "10%" } }) : _vm._e(),
            pair[1]
              ? _c(
                  "td",
                  {
                    staticStyle: {
                      width: "30%",
                      "vertical-align": "top",
                      "font-size": "14px",
                      "text-align": "center",
                      "background-color": "white",
                    },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          !pair[1].derecho_propio
                            ? ""
                            : `Por: ${pair[1].nombreCompleto}`
                        ) +
                        " "
                    ),
                  ]
                )
              : _vm._e(),
            pair[1] ? _c("td", { staticStyle: { width: "10%" } }) : _vm._e(),
          ]),
          _c("tr", [
            _c("td", { staticStyle: { width: "10%" } }),
            _c(
              "td",
              {
                staticStyle: {
                  width: "30%",
                  "vertical-align": "top",
                  "font-size": "14px",
                  "text-align": "center",
                  "background-color": "white",
                },
              },
              [
                _vm._v(
                  " " +
                    _vm._s(
                      !pair[0].derecho_propio
                        ? ""
                        : `Cargo: Representante Legal`
                    ) +
                    " "
                ),
              ]
            ),
            _c("td", { staticStyle: { width: "10%" } }),
            pair[1] ? _c("td", { staticStyle: { width: "10%" } }) : _vm._e(),
            pair[1]
              ? _c(
                  "td",
                  {
                    staticStyle: {
                      width: "30%",
                      "vertical-align": "top",
                      "font-size": "14px",
                      "text-align": "center",
                      "background-color": "white",
                    },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          !pair[1].derecho_propio
                            ? ""
                            : `Cargo: Representante Legal`
                        ) +
                        " "
                    ),
                  ]
                )
              : _vm._e(),
            pair[1] ? _c("td", { staticStyle: { width: "10%" } }) : _vm._e(),
          ]),
        ])
      }),
      0
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("table", { staticClass: "tabla" }, [
      _c("tr", { staticClass: "title" }, [
        _c("td", [
          _vm._v(" HOJA DE FIRMAS CORRESPONDIENTE AL PROYECTO"),
          _c("br"),
          _vm._v("DE ACTA DE RATIFICACION DE FIRMAS "),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", [
        _vm._v(
          " Asimismo, por medio de la presente confirmamos que toda la información y documentación que ha sido entregada a través de medios digitales, y aquella que se entrega de forma física a Correduría Digital y al Corredor Público Designado, es verídica, auténtica y exacta, y la misma corresponde a la realidad. "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", [
        _vm._v(
          " En el mismo sentido, declaramos conocer y aceptar el aviso de privacidad que ha sido publicado en la plataforma y sitio de Internet visitado, así como los términos y condiciones aplicables a la prestación de los servicios solicitados. "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("table", { staticClass: "tabla" }, [
      _c("tr", { staticStyle: { height: "30px" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }