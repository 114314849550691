<template>
  <footer class="footer">
    <div class="copyright version">
      Versión: {{ version }}
    </div>
    <div class="container-fluid">
      <div class="copyright">
        &copy; {{ new Date().getFullYear() }} Correduría Digital
      </div>
      <div class="copyright">
        <a :href="urlTerminosYCondiciones" target="_blank">Términos y Condiciones</a>
      </div>
      <div class="copyright">
        <a :href="urlAvisoDePrivacidad" target="_blank">Aviso de Privacidad</a>
      </div>
    </div>
  </footer>
</template>

<script>
import { MetaInfoApi } from '@/api';

import config from '@/config';

export default {
  name: "ContentFooter",

  data: () => ({
    version: "",
    urlAvisoDePrivacidad: config.STG_BASE_URL + "/assets/documents/aviso_de_privacidad_cd_v2.pdf" + config.STG_ACCESS_KEY,
    urlTerminosYCondiciones: config.STG_BASE_URL + "/assets/documents/terminos_y_condiciones_cd_v3.pdf" + config.STG_ACCESS_KEY,
  }),

  created() {
    // this.version = "na";
    // return;
    MetaInfoApi.getVersion()
    .then((response) => {
      this.version = response.frontend_version;
    }).catch((error) => {
      this.version = "N/A";
      console.error(error);
    });
  },
};
</script>

<style>
.version {
  font-size: 11px !important;
  float: left !important;
}
</style>
