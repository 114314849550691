var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "wrapper" },
    [
      _c(
        "side-bar",
        [
          _c("mobile-menu", { attrs: { slot: "content" }, slot: "content" }),
          _c("sidebar-link", { attrs: { to: "/dashboard" } }, [
            _c("i", { staticClass: "nc-icon nc-chart-pie-35" }),
            _c("p", [_vm._v("Inicio")]),
          ]),
          _c("sidebar-link", { attrs: { to: "/dashboard/clienttasks" } }, [
            _c("i", { staticClass: "nc-icon nc-notes" }),
            _c("p", [_vm._v("Mis procesos")]),
          ]),
          _c("sidebar-link", { attrs: { to: "/dashboard/clientaccount" } }, [
            _c("i", { staticClass: "nc-icon nc-circle-09" }),
            _c("p", [_vm._v("Mi cuenta")]),
          ]),
          _c("sidebar-link", { attrs: { to: "/dashboard/comparecientes" } }, [
            _c("i", { staticClass: "nc-icon nc-bullet-list-67" }),
            _c("p", [_vm._v("Mis comparecientes")]),
          ]),
          _vm.rol === "Cliente Administrador"
            ? _c("sidebar-link", { attrs: { to: "/dashboard/monedero" } }, [
                _c("i", { staticClass: "nc-icon nc-credit-card" }),
                _c("p", [_vm._v("Monedero")]),
              ])
            : _vm._e(),
          _c("sidebar-link", { attrs: { to: "/dashboard/videoteca" } }, [
            _c("i", { staticClass: "nc-icon nc-button-play" }),
            _c("span", [_vm._v("Videoteca")]),
          ]),
          _c("hr"),
          _c("div", [
            _c("li", { staticClass: "nav-item" }, [
              _c(
                "a",
                {
                  staticClass: "nav-link",
                  attrs: { href: "#" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.logout.apply(null, arguments)
                    },
                  },
                },
                [_vm._v(" Cerrar sesión ")]
              ),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "main-panel" },
        [
          _c("dashboard-content", { on: { click: _vm.toggleSidebar } }),
          _c("content-footer"),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }