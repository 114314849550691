import { render, staticRenderFns } from "./DatosGenerales.vue?vue&type=template&id=7dd30d1e&scoped=true"
import script from "./DatosGenerales.vue?vue&type=script&lang=js"
export * from "./DatosGenerales.vue?vue&type=script&lang=js"
import style0 from "./DatosGenerales.vue?vue&type=style&index=0&id=7dd30d1e&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7dd30d1e",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/github/workspace/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('7dd30d1e')) {
      api.createRecord('7dd30d1e', component.options)
    } else {
      api.reload('7dd30d1e', component.options)
    }
    module.hot.accept("./DatosGenerales.vue?vue&type=template&id=7dd30d1e&scoped=true", function () {
      api.rerender('7dd30d1e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/clients/procesos/constitucion/infoBase/DatosGenerales.vue"
export default component.exports