var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "gmap-map",
        {
          ref: "mymap",
          style: `height: ${_vm.heightInPx}px;`,
          attrs: { center: _vm.center, zoom: 17 },
        },
        [
          _c("gmap-marker", {
            ref: "mymarker",
            attrs: { draggable: true, position: _vm.center },
            on: { dragend: _vm.updateCoordinates },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }